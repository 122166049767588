import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import correctIcon from '../../assets/images/check-icon.png'
import wrongIcon from '../../assets/images/cross-icon.png'
import jsonToken from 'jsonwebtoken';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export function BarChart(props) {
  const [labels,setLabels] = useState(props.data.labels)
  const [dataValues, setDataValues] = useState([])

  const correctAnswerIcon = new Image();
  correctAnswerIcon.src = correctIcon;

  const wrongAnswerIcon = new Image();
  wrongAnswerIcon.src = wrongIcon;

  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  
  const imageOnTop = {
    id: 'imageOnTop',
    beforeDatasetsDraw(Chart, args, plugin){
      const {ctx} = Chart;

      if(dataValues.length > 0){
        const length = dataValues.length;
        
        for(let j = 0; j < length; j++){
          for(let i = 0; i < dataValues[j].yData.length; i++){
            const xPos = Chart.getDatasetMeta(j).data[i].x;
            const yPos = Chart.getDatasetMeta(j).data[i].y;
            const minusYPos = Chart.getDatasetMeta(j).data[i].base;
            const color = dataValues[j].color[i];
            const position = dataValues[j].yData[i];

            if(length <= 2){
              if(color == 'red'){
                if(position < 0){
                  ctx.drawImage(wrongAnswerIcon, xPos - 9, minusYPos-20, 18, 18)
                }
                else{
                  ctx.drawImage(wrongAnswerIcon, xPos - 9, yPos - 20, 18, 18)
                }
              }
              else{
                if(position < 0){
                  ctx.drawImage(correctAnswerIcon, xPos - 10, minusYPos-20, 20, 20)
                }
                else{
                  ctx.drawImage(correctAnswerIcon, xPos - 10, yPos - 24, 20, 20)
                }
              }
            }
            else{
              if(color == 'red'){
                if(position < 0){
                  ctx.drawImage(wrongAnswerIcon, xPos - 5, minusYPos-15, 10, 10)
                }
                else{
                  ctx.drawImage(wrongAnswerIcon, xPos - 5, yPos - 15, 10, 10)
                }
              }
              else{
                if(position < 0){
                  ctx.drawImage(correctAnswerIcon, xPos - 5, minusYPos-15, 11, 11)
                }
                else{
                  ctx.drawImage(correctAnswerIcon, xPos - 5, yPos - 15, 11, 11)
                }
              }
            }
          }
        }
      }

    }
  }

    const  options = {
      maintainAspectRatio: false,
      responsive:true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            grace: '10%'
          }
        }
    }

   
    useEffect(()=>{
      
      if(props?.data?.datasets !== undefined || props?.data?.datasets !== null){
        let lengthCount = [];
        let labelArray = [];
        props?.data?.datasets.map((x)=>{
          if(x?.yData !== undefined){
            lengthCount.push(x.yData.length)
          }
        })
       const maxValue =  Math.max(...lengthCount)

       for(let i=1;i<=maxValue;i++){
          labelArray.push(i)
        }

        if(props.data.labels.length <= labelArray.length ){
          setLabels(labelArray)
        }

        const payload = []
        props.data.datasets.map(x=>{
          if(x !== undefined ){
            // check duplicate values before pushing to the payload.
            const isDuplicate = payload.some(item => item.label === x.label);
            if(!isDuplicate){
              payload.push(x)
            }
          }
        })

        setDataValues(payload)

      }
    },[props.data])

    const values = {
      labels :labels,
      datasets:dataValues.filter((ele, ind) => ind === dataValues.findIndex( elem => elem.label === ele.label  )).map((item, i) =>{
          return {
              label:item.label  === "undefined" || item.label === null ? "" : item.label,
              borderColor: item.label  === "undefined" || item.label === null ? "transparent" :item.color,
              backgroundColor: item.borderColor || item.color,
              borderWidth:"3",
              data:item.label  === "undefined" ? []: item.yData,
          }
      })
    }

  return (
    <Bar 
      key={JSON.stringify(props.data)} 
      options={options} data={values} 
      responsive="true"  
      height={200} 
      plugins={[imageOnTop]} 
      redraw={true} 
    />
    );
}

 