import React, { useEffect } from 'react'
import ReactApexCharts from 'react-apexcharts'

export default function LessonChart({LessonData, selection, totalStudentAndLessonData}) {
    
    const activeLessonCount = LessonData.map(item => item.y.activeLessonCount);
    const totalLessonCount = LessonData.map(item => item.y.totalLessonCount);
    const dates = LessonData.map(item => item.x + "Z");


    let state = {
        series: [
            {
                name: `Active Count : ${totalStudentAndLessonData?.totalActiveLessonCount || 0}`,
                data: activeLessonCount
            },
            {
                name: `New Count : ${totalStudentAndLessonData?.totalLessonCount || 0}`,
                data: totalLessonCount
            }
        ],
        options: {
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                        tools: {
                            selection:false,
                            zoom: true,
                        },
                        autoSelected: "pan",
                        export: {
                            svg: {
                                filename: `${selection} - Lesson`
                            },
                            png: {
                                filename: `${selection} - Lesson`
                            },
                            csv: {
                                filename: `${selection} - Lesson`,
                                headerCategory: "Date",
                                // dateFormatter(timestamp) {
                                //     return new Date(timestamp).toDateString()
                                //   }
                            }
                        },
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: dates,
                // tooltip: {
                //     enabled: false
                //   }
            },
            tooltip: {
                x: {
                    show: false,
                    format: 'dd/MM/yy'
                },
                y: {
                    formatter: function(value) {
                        return parseInt(value);
                    }
                },
                
                custom: function({series, dataPointIndex, w}) {
                    if(series[0].length > 1 && series[1].length > 1){
                        return '<div>' +
                        '<span> Active Count : <b> ' + series[0][dataPointIndex] + '</b></span> </br>' +
                        '<span> New Count : <b>' + series[1][dataPointIndex] + '</b></span>' +
                        '</div>'
                    }else if(series[0].length > 1){
                        return '<div>' +
                        '<span> Active Count : <b> ' + series[0][dataPointIndex] + '</b></span> </br>' +
                        '</div>'
                    }else if(series[1].length > 1){
                        return '<div>' +
                        '<span> New Count : <b>' + series[1][dataPointIndex] + '</b></span>' +
                        '</div>'
                    }else{
                        return "";
                    }
                    
                }
            },
            colors: ['#00AEEF', '#716E6E'],
            title: {
                text: [
                    `Lessons`,
                ],
                align: 'left',
                floating: true,
                style: {
                    fontWeight:  'Bold',
                    fontFamily:  'CarmenSans-Regular',
                    color:  '#5C5C5C'
                  },
            },
            subtitle: {
                text: `${selection}`,
                align: 'center',
                margin: 27, 
                style: {
                    fontWeight:  'normal',
                    fontFamily:  'CarmenSans-Regular',
                    color:  '#5C5C5C'
                  },
            },
        },
    };
    return (
        <div className='welcome-container'>
            <div id="chart">
                <ReactApexCharts options={state.options} series={state.series} type="area" height={350} />
            </div>
            <div id="html-dist"></div>
        </div>
    )
}
