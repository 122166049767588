import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, HashRouter } from "react-router-dom";
import Home from "./pages/Home/home";
import LayoutComponent from "./components/Layout/Layout";
import LabComponent from "./components/Layout/LabLayout";
import Login from "./pages/login/Login";

import LessonLab from "./pages/LessonLab/index";
import ClassIndex from "./pages/Class/index";
import Class from "./pages/Class/class";
import Lesson from "./pages/Lesson/lesson";
import ViewLesson from "./pages/Teacher/Home/Assign/ViewLesson";
import StudentPage from "./pages/Teacher/Home/Grading/ClassName/StudentPage";

import AuthorizeRoute from "./auth/authorizeRoute";
import Unauthorized from "./auth/unauthorized";
import SchoolAdminAccount from "./pages/Admin/MyAccount/SchoolAdmin";
import ImportTeacher from "./pages/Admin/MyAccount/Teachers/Import";
import ImportStudent from "./pages/Admin/MyAccount/Students/Import";
import Error from "./pages/Error/Error";
import { ToastContainer } from "react-toastify";
import { LoginCheckWithPages } from "./components/LoginCheck/LoginCheck";

import "./styles/app.scss";

const App = (props) => {
  LoginCheckWithPages();

  return (
    <div>
      <ToastContainer />
      <Router>
        <HashRouter>
          <Switch>

            <Route path="/" exact render={() => <Redirect to="/home" />} />
            <Route path="/login" exact component={Login} />
            <AuthorizeRoute roles="*" path='/home'>
              <Home />
            </AuthorizeRoute>
            <Route path="/admin" exact render={() => <Redirect to="/admin/dashboard" />} />
            <Route path="/engage" component={LayoutComponent} />
            <Route path="/LessonLab" component={LabComponent} />
            <Route path="/ClassIndex" component={LayoutComponent} />
            <Route path="/LessonIndex" component={LayoutComponent} />
            <Route path="/AdminClass" component={LayoutComponent} />
            <Route path="/AdminLesson" component={LayoutComponent} />
            <Route path="/MyAccount" component={LayoutComponent} />
            <Route path="/Teacher" component={LayoutComponent} />
            <Route path="/Instructor/:instructorId/Classes/" component={LayoutComponent} />
            <Route path="/Instructor/:instructorId/Classes/:classId/Lesson/" component={LayoutComponent} />
            <Route path="/AssignLesson" component={LayoutComponent} />
            <Route
              path="/Instructor/:instructorId/Classes/:classId/Student"
              component={LayoutComponent}
            />
            <Route path="/Teacher/Home/ViewLesson" component={LayoutComponent} />
            <Route path="/ClassName" component={LayoutComponent} />
            <Route path="/ChosenStudentName" component={LayoutComponent} />
            <Route path="/Lesson/:id" exact component={LayoutComponent} />
            <Route path="/Teacher/ClassDetails/:lessonId" component={LayoutComponent} />
            {/* <Route path="/Teacher/ClassDetails" component={LabComponent} /> */}
            <Route path="/login" exact component={Login} />
            <Route path="/LessonLab" exact component={LessonLab} />
            <Route path="/ClassIndex" exact component={ClassIndex} />
            <Route path="/Class" exact component={Class} />
            <Route path="/Lesson" exact component={Lesson} />
            <Route path="/Teacher/Home/Student-Page" component={StudentPage} />
            <Route path="/Teacher/Home/ViewLesson" component={ViewLesson} />
            <Route path="/Unauthorized" component={Unauthorized} />
            <Route path="/SchoolAdminAccount" component={SchoolAdminAccount} />
            <Route path="/evaluate-student" component={LayoutComponent} />
            <Route path="/evaluate-teacher" component={LayoutComponent} />
            <Route path="/test" component={LayoutComponent} />
            <Route path="/ImportStudent" component={LayoutComponent} />
            <Route path="/ImportTeacher" component={LayoutComponent} />
            <Route path="/error" component={Error} />
            <Route path="/Organization" component={LayoutComponent} />
            <Route path="/Instructor" component={LayoutComponent} />
          </Switch>
        </HashRouter>
      </Router>
    </div>
  );
};

export default App;
