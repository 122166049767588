import { useEffect, useRef, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Radio, Input, Form, Modal, Button, Tabs, Spin, Breadcrumb } from "antd";
import { Tour as Tour5 } from 'antd-v5';
import "antd/dist/antd.css";
import jsonToken from 'jsonwebtoken';

import RestService from "../../../api/RestService";
import AnswerDisplay from "../AnswerDisplay";
import FloatingButton from "../../../components/FloatingButton";
import CalculateMarks from "../calculateMarks";
import InstructorComment from "../components/InstructorComment/InstructorComment";
import LessonService from "../../../api/LessonService";

function Engage(props) {
  const { TabPane } = Tabs;

  
  const [payload, setPayload] = useState([]);
  const [containedPayload, setContainedPayload] = useState({});
  const [loading, setLoading] = useState(true);
  const [classLevelName, setClassLevelName] = useState(localStorage.getItem("className"));
  const [lessonName, setLessonName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teacherComment, setTeacherComment] = useState("");
  const [teacherCommentStats, setTeacherCommentStats] = useState(false);
  const TSdate = Date.now();
  const dateFormat = new Date(TSdate);
  const MonthYear = dateFormat.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
  const HrsMinSec = dateFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });

  const selectedClassID = localStorage.getItem("selectedClassID");
  const selectedLessonID = localStorage.getItem("selectedLessonID");

  const handleOk1 = () => {
    UploadDataResponse()
    setIsModalOpen(false)
  };


  const handleCancel1 = () => {
    setReviewStatus(true)
    window.scrollTo(0, 0)
    setIsModalOpen(false)
  };

  useEffect(() => {
    if (props.complted === true || props.disabled !== undefined && props.disabled === false) {
      setReviewStatus(false)

    } else {
      setReviewStatus(true)

    }
  }, [props])

  const [initPayload, setInitPayload] = useState({
    description: "",
    guideline: "",
    engageStepQuestionAnswers: [],
    engageStepQuestions: [],
    videoUrl: "",
    id: "",
    lesson_id: "",
  });

  const [reviewStatus, setReviewStatus] = useState(props.disabled ?? true);
  const [lesson, setLesson] = useState(props.engageId);

  let user = "";
  let userId = "";
  let firstName = "";
  let sendUserIdValue = ""
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;
    firstName = accessToken.firstName;
    userId = accessToken.userId;

    sendUserIdValue = accessToken.userId
  }

  function convert(data) {
    return Array.isArray(data)
      ? data.reduce((obj, el, i) => (el + 1 && (obj[i] = convert(el)), obj), {})
      : data;
  }

  useEffect(() => {
    setLoading(true)
    RestService.getEngageStepInfoById(lesson)
      .then((response) => {
        setInitPayload(response?.data)
        setLessonName(response?.data?.lessonName);
        setLoading(false);
        setOpen(props.firstTime);
        scrollToElement();
      })
  }, [lesson])
  

  useEffect(() => {
    setLoading(true)
    RestService.getEngageStepByIdAndUserName(user, lesson)
      .then((response) => {
        if (response) {
          if (response?.data[0]?.comment !== null && response?.data[0]?.comment !== undefined && response?.data[0]?.comment !== "") {
            setTeacherCommentStats(true)
            setTeacherComment(response?.data[0]?.comment)
            scrollToElement();
          }
          setContainedPayload(convert(response?.data[0]?.response_json?.answers))
        }
        setLoading(false)
      })

  }, [props, reviewStatus])

  const scrollToElement = () => {
    setTimeout(function () {
      const url = window.location.href;
      if (url.includes("scroll")) {
        const splitted_data = url.split("#");
        const scroll_command = splitted_data[splitted_data.length - 1];
        if ("scroll-to-comment" === scroll_command) {
          document.getElementById('teacher-evaluates-answer-comment').scrollIntoView({
            behavior: 'smooth'
          });
          let new_url = url.replace("#" + scroll_command, "");
          window.history.pushState(null, null, new_url);
        }
      }
    }, 1400);
  };

  const sendPayload = (value) => {
    setPayload(value);
  };
  const { TextArea } = Input;

  const NextFun = () => {
    window.scrollTo(0,0);
    props.submitTrigger("2")
  };

  const submitFun = async () => {
    setIsModalOpen(true);
  }

  const UploadDataResponse = async () => {
    setLoading(true)
    const name = CalculateMarks(Object.values(payload), initPayload.engageStepQuestionAnswers, initPayload.engageStepQuestions.length, props.marks)
    const submitPayload = {
      username: user,
      userId: userId,
      status: 2,
      response_json: {
        answers: Object.values(payload)
      },
      last_update_date: new Date().toISOString().slice(0, 10),
      engage_step_id: lesson,
      marks: name,
    }
 
    await RestService.postEngageStepUserResponses(submitPayload, selectedClassID, selectedLessonID)
      .then((response) => {
        if (response.status === 200) {
          props.activatingRS();
          const end = new Date();
          props.getEndDuration(end);
          window.scrollTo(0, 0);
          props.submitTrigger("1");
        }
        setLoading(false)

      })
  }

  const [header, setHeader] = useState({
    h1: `Smart Science`,
    h2: `<strong>
          Correct answers are marked in <span className="text-success">green</span>. Wrong answers are marked in <span className="text-danger">red</span>.<br></br>
          The worked-out correct answer follows each question.
        </strong>`,
    h3: `LAB DESCRIPTION `,
    h4: `LAB GUIDELINE `,
    h5: `Questions`,
    h6: `Review`,
    h7: `Proceed To Explore`,
    h8: `Your answers will be graded. Do you wish to continue? `,
    ok: `OK`,
    cancel: `Cancel`,

    classLevelName: localStorage.getItem("className"),
  });

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const [open, setOpen] = useState(false);
  const steps = [
    {
      title: 'Lab Description',
      description: 'Read the lab description and follow the lab guideline.',
      target: () => ref1.current,
    },
    {
      title: 'Question ',
      description: 'Select the correct answer. You can change your choice after 5 seconds if a wrong answer is selected.',
      target: () => ref2.current,
    },
    {
      title: 'Review and Proceed ',
      description: 'Click on the review button to proceed.',
      target: () => ref3.current,
    },
  ];

  // useEffect(()=>{
  //   if(open){
  //     window.scrollTo(0,0);
  //   }
  // },[open])
  
  return (
    <Spin tip="Loading..." spinning={loading} size="large">
      <div className="content engage-page tab-style scroll-tab">
      {/* <Button type="primary" onClick={() => setOpen(true)}>
        Begin Tour
      </Button> */}
        <div className="card-chart card border-0">
          <div className="card-body">
            <Modal title="" visible={isModalOpen} onOk={handleOk1} onCancel={handleCancel1}
              okText={header.ok}
              cancelText={header.cancel}>
              <p>{header.h8}</p>
            </Modal>
            <Row className="pt-2">
              <Col xs="12">
                <Card className="bg-engage border-0 rounded-0 guidelines-description-table">
                  <CardBody className="ml-5 mt-5 mb-5 description-images-60">
                    <h2><center><span class="notranslate">Smart Science </span><sup>&reg;</sup></center></h2>

                    <p align="center" className="tf15" family="CarmenSans-Regular">

                    </p>
                    <p align="center" className="tf15" family="CarmenSans-Regular">
                      {`${firstName} - ${header.classLevelName} - ${lessonName}`}
                    </p>
                    <p align="center" className="tf15" family="CarmenSans-Regular">
                      <span class="notranslate"> {`${MonthYear} - ${HrsMinSec} `}</span>
                    </p>
                    <center>
                      <br />
                      <p>
                        <div className="f18" dangerouslySetInnerHTML={{ __html: header?.h2 }}></div>
                        <br /><br />
                      </p>
                    </center>
                    {
                      initPayload?.description !== undefined && initPayload?.description !== null &&
                      initPayload?.description !== "" &&
                      <>
                        <h5 ref={ref1}> {header.h3} </h5>
                        <hr className="hr-dark" /> 
                        <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div>
                      </>
                    }<br />
                    {
                      initPayload?.guideline !== undefined && initPayload?.guideline !== null &&
                      initPayload?.guideline !== "" &&
                      <>
                        <br />
                        <h5> {header.h4} </h5>
                        <hr className="hr-dark" />
                        <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.guideline }}></div>

                      </>

                    }

                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <Row className="">
              <Col xs="12">
                <Card className="bg-engage border-0 rounded-0">
                  <CardBody className="ml-5 mt-5 mb-5">
                    <h5 ref={ref2}> {header.h5}</h5>
                    <hr className="hr-dark" />
                    <Row>
                      <Col xs="12" >
                        {/* type should be coming form admin side to make this dynamic entry */}
                        <AnswerDisplay data={
                          {
                            type: 1,
                            question: initPayload?.engageStepQuestions,
                            answers: initPayload?.engageStepQuestionAnswers,
                            preData: containedPayload,
                            className: "",
                            getData: sendPayload,
                            reviewStatus: reviewStatus,
                            subtype: 0
                          }
                        } /> 

                        {/* include this for type 2 and 4 */}
                        {/* <AnswerDisplay data={{
                          type: 4,
                          payload: initPayload?.explainStepQuestions,
                          changeHandler: changeHandler,
                          containedPayload: containedPayload

                        }} /> */}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {
              teacherCommentStats && <InstructorComment  from={"Engage"} data={teacherComment} />
            }

            <Row>
            <div ref={ref3} className={props.firstTime ? 'pull-right tdivsm' : 'pull-right tdiv'}>
              <FloatingButton tourStatus={(n)=>{setOpen(n); window.scrollTo(0,0)}} name={reviewStatus ? header.h6 : header.h7} onClick={reviewStatus ? submitFun : NextFun} status={true}/>
            </div>
            </Row>
          </div>
        </div>
        <Tour5 disabledInteraction={true} open={open} onClose={() => {
          try{
            LessonService.updateUserRoutes(sendUserIdValue,'engage', 1);
            let userRoutes = JSON.parse(localStorage.getItem('userRoutes'));
            userRoutes.userRoute.engage = 1;
            localStorage.setItem('userRoutes', JSON.stringify(userRoutes));
          }catch(err){
            console.log(err)
          }
          setOpen(false)}} steps={steps} />
      </div>
    </Spin>
  );
}

export default Engage;
