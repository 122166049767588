export default function validate(obj) {
    let errors = {};  
    let values = {...obj};
    let moreBlank =/^(\w+\s)*\w+$/g ;
    let specialChar = /^((?![-_!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+).)*$/gm;
  
    Object.keys(values).map(k => values[k] = trimObjectStrings(k));
    var description = values.description;
    var div1 = document.createElement("div");
    div1.innerHTML = description;
    var desc = div1.textContent || div1.innerText || "";
    var richTextChecker1 = values.description.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;| /g, '').trim();




    // var explain = values.questionExplanation ;
    // var div2= document.createElement("div");
    // div2.innerHTML = explain;
    // var Explanation = div2.textContent || div2.innerText || "";


  
    // if (!values.firstName || values.firstName == '') {
    //   errors.firstName = 'First Name is required';
    // }else if(values.firstName && values.firstName != '' && values.firstName.length > 50){
    //   // errors.firstName = 'First Name must contain less than 50 characters';
    //   errors.firstName = 'First Name must be less than 50 characters';
    // }else if (!values.firstName.match(specialChar)) {
    //   errors.firstName = 'First Name can not have special characters';
    // }
  
    // if (!values.lastName || values.lastName == '') {
    //   errors.lastName = 'Last Name is required';
    // }else if(values.lastName && values.lastName != '' && values.lastName.length > 50){
    //   // errors.lastName = 'Last Name must contain less than 50 characters';
    //   errors.lastName = 'Last Name must be less than 50 characters';
    // }  else if (!values.lastName.match(specialChar)) {
    //   errors.lastName = 'Last Name can not have special characters';
    // }
  
    // var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  
    // if (!values.email || values.email == '') {
    //   errors.email = 'Email  is required';
    // } else if (!values.email.match(mailformat)) {
    //   errors.email = 'Email  is invalid';
    // }
  
    if (values.description === undefined || desc === undefined|| values.description.length == 0 || richTextChecker1 === "") {
      errors.description = 'Question is required';
    }
  
    if (!values.question_type_id || values.question_type_id < 0) {
      errors.question_type_id = 'Question Type is required';
    }


    // if (!Explanation || Explanation == '') {
    //     errors.questionExplanation = 'Question Explanation is required';
    //   }else if(Explanation && Explanation != '' && Explanation.length > 50){
    //     errors.questionExplanation = 'Question Explanation must be less than 50 characters';
    //   }
  
    // if (!values.organization_id || values.organization_id < 0) {
    //   errors.organization_id = 'School is required';
    // }
  
    // if(values.password && values.password != '' && values.password.length < 4){
    //   errors.password = 'Password must contain at least 4 characters';
    // }else if(values.password && values.password != '' && values.password.length >= 15){
    //   errors.password = 'Password must contain less than 15 characters';
    // }
  
    // if (values.staffId !== null) {    
    //   if (values.staffId?.length > 50) {
    //     errors.staffId = 'Staff Id must be less than 50 characters';
    //   }
    // }
  
    
   
    //   if (!values.password || values.password == "") {
    //     errors.password = 'Password is required';
    //   } else if (values.password.length < 4) {
    //     errors.password = 'Password must contain at least 4 characters';
    //   } else if (values.password.length > 15) {
    //     errors.password = 'Password must contain less than 15 characters';
    //   }else if(values.password.split(" ").length > 1){
    //     errors.password = 'Password  cannot contain blank spaces';
    //   }
    
    //   if (!values.confirmPassword || values.confirmPassword == "") {
    //     errors.confirmPassword = 'Confirm Password is required';
    //   } else if (values.confirmPassword.length < 4) {
    //     errors.confirmPassword = 'Confirm Password must contain at least 4 characters';
    //   } else if (values.confirmPassword.length > 15) {
    //     errors.confirmPassword = 'Confirm Password must contain less than 15 characters';
    //   }else if(values.confirmPassword.split(" ").length > 1){
    //     errors.confirmPassword = 'Confirm Password  cannot contain blank spaces';
    //   }
    
    //   if (values.confirmPassword != values.password) {
    //     errors.confirmPassword = 'Passwords must match';
    //   }
     
  
    // if (values.confirmPassword != values.password) {
    //   errors.confirmPassword = 'Passwords must match';
    // }
  
    function trimObjectStrings(key){
      if(typeof values[key] == 'string' && !key.toLowerCase().includes("password")){
        return values[key].trim();
      }
      return values[key];
    }
  
    return errors;
  };