import React from 'react'
import { Row, Col } from 'antd'
import img1 from '../../assets/images/error-display.png'
import { Alert, Spin, Modal, Button } from 'antd';
import logo from "../../assets/images/logo-light.svg";
import { ArrowLeftOutlined, CustomerServiceOutlined } from '@ant-design/icons';




function Error() {
    return (
        <>
            <section className="vh-100 error-bg">
                <div className="container-fluid h-custom">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-md-9 col-lg-6 col-xl-5 tab-hide">

                            <img
                                className="d-block login-bg"
                                src={img1}
                                alt="First slide"
                            />
                        </div>
                        <div className="col-md-8 col-lg-6 col-xl-4">

                                <div className="center mt-4">
                                    <img src={logo} alt="logo" style={{width:"50%"}} />
                                </div>
                                {/* <!-- Username input --> */}
                                <div className='text-center'>
                                    <p className='error-code'>404</p>
                                </div>
                                <div className='text-center'>
                                    <p className='error-message'>Officia pariatur aliqua proident voluptate velit veniam sint velit cillum mollit aute </p>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        <center>
                                        <Button icon={<ArrowLeftOutlined className='ant-icon-up' />} className="mx-1 my-1 px-4 btn-back" size='large'>Go Back</Button>
                                        <Button icon={<CustomerServiceOutlined className='ant-icon-up' />} className="mx-1 my-1 px-4 btn-contact" size='large'>Contact Us</Button>
                                        </center>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Error