import { useEffect, useState } from "react";
import { Link, useParams} from "react-router-dom";
import { useHistory } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getAllClassesToLoggedUser,getAllAssignedAndUnassignedLessonsToClass, getLessonsByCurriculumId,assignAndUnassignMultipleLessonToClass } from "../../../api/TeacherService";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  CardBody,
  CardTitle,
  Row,
  Col,
  Progress,
  CardImgOverlay,
} from "reactstrap";
import Lesson from "./lesson";
import "antd/dist/antd.css";
import { Select, Space, Alert, Button, Spin, notification, Input } from 'antd';
// import { Button } from "react-bootstrap";



function LessonIndex(props) {


  const [classLevelData, setClassLevelData] = useState([]);
  const [viewVersion, setViewVersion] = useState(0)
  const [data, setData] = useState({ data: "" });
  const [loading, setLoading] = useState(true);
  const [assignList, setAssignList] = useState([]);
  const [unassignList, setUnassignList] = useState([]);
  const [classId,setClassId] = useState();
  const [disable, setDisable] = useState(false);

  const [visibilityClass, setVisibilityClass] = useState(false);
  const [visibilityStatus, setVisibilityStatus] = useState(false);

  const [viewOption, setViewOption] = useState(0)
  let history = useHistory();
  const value = (window.location.href).split("/");

  let { id } = useParams();

  const listingFunction = (value0, value, av, uav) => {
    if(value0 === 1){
      if(value === 0){
        const setTrueValue = av.map(item => {
            return {
                ...item,
                status_true: true,
            };
          });
          const setFalseValue = uav.map(item => {
            return {
                ...item,
                status_true: false,
            };
          });
  
          const FinalArray = [...setTrueValue, ...setFalseValue];
  
          setData({data:FinalArray, status:200})
  
      }else if(value === 1){
        const setTrueValue = av.map(item => {
            return {
                ...item,
                status_true: true,
            };
          });
  
          setData({data:setTrueValue, status:200})
  
      }else{
        const setFalseValue = uav.map(item => {
            return {
                ...item,
                status_true: false,
            };
          });
  
          setData({data:setFalseValue, status:200})
      }
    }
  }

  useEffect(()=>{
    listingFunction(viewVersion, viewOption, assignList, unassignList)
  },[viewOption])


  const getInitialData = () => {
    getLessonsByCurriculumId(id).then((response) => {
      setData(response);
      setViewVersion(0)
      getAllClassesToLoggedUser(id).then((response) => {
        if(response){
          let list = [{
            value:-1,
            label:"Select a Class"
          }]
          response.data.map(x=>{
            list.push({
              value: x.id,
              label: x.name,
            })
          })
          setClassLevelData(list);
          setLoading(false);

        }
      })
    })
  }

  useEffect(()=>{
    getInitialData();
  },[])

  useEffect(() => {
    window.localStorage.setItem('lessonID', JSON.stringify(parseInt(value[value.length - 1])));
  }, [value])

  const onChange = (value) => {
    setLoading(true);
    if(value === -1){
      getInitialData();
      setViewOption(0);
      setViewVersion(0)
    }else{
      setClassId(value);
      getAllAssignedAndUnassignedLessonsToClass(id,value).then((response) => {
        if(response){
          setViewOption(0);
          listingFunction(1,0, response.data.assignedLessons, response.data.unAssignedLessons);
          setAssignList(response.data.assignedLessons);
          setUnassignList(response.data.unAssignedLessons);
          setLoading(false);
          setViewVersion(1)
        }
      });
    }
  };

  const onChange1 = (value) => {
    setViewOption(value);
  };

  const changeFunction = (status, id) => {
    if (status === true){
      const item = unassignList.find((element) => element.id === id);
      if(item){
        const RemovedList = unassignList.filter((element) => element.id !== id);
        setAssignList((prevModules) => [...prevModules, item]);
        setUnassignList(RemovedList)
      }

    }else{
      const item = assignList.find((element) => element.id === id);
      if(item){
        const RemovedList = assignList.filter((element) => element.id !== id);
        setUnassignList((prevModules) => [...prevModules, item]);
        setAssignList(RemovedList)
      }
    }
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: msg,
      className: 'new-notification-icon',
      duration: 2,
      placement: 'top'
    });
  };

  const onSubmitFunction = () =>{
    setLoading(true)
    const payload = []
    assignList.map(x=>{
      payload.push({
        "lessonId":x.id,
        "classId":classId,
        "curriculumId":x.curriculamId
    })
    })
    assignAndUnassignMultipleLessonToClass(payload, classId).then((res) => {
      if(res.status === 200){
        openNotificationWithIcon('success', "Status updated successfully")
      }else{
        openNotificationWithIcon('error', res.message)
      }
      setLoading(false)
      onChange(classId)
    

    }).catch((err)=>{
      //error
      openNotificationWithIcon('error')
      setLoading(false)
    })
  }

  const onResetFunction = () => {
    setViewOption(0);
    onChange(classId);
  }

  const disableStatus = (value) => {
    setDisable(value);
  }

  const handleMouseLeaveClass = () => {
    setVisibilityClass(false);
  };

  const handleSelectClickClass = () => {
    setVisibilityClass(!visibilityClass);
  };

  const handleMouseLeaveStatus = () => {
    setVisibilityStatus(false);
  };

  const handleSelectClickStatus = () => {
    setVisibilityStatus(!visibilityStatus);
  };
 
  return (
    <>
      <div className="container">
        <div className="content">
          <Row>
            <Col className="text-center lesson-select">
              <div className="mb-5">
                <h3 className="text-dark">Choose your lesson</h3>
                <Col span={8}>

                  <Link disabled={disable} className="back-button " onClick={() => {
                    if(disable === false){
                      history.push({ pathname: `/Teacher/PlanClass/` });
                    }
                  }
                  } >
                    <ArrowLeftOutlined className=" mr-2 back-btn-hover" />
                    Go Back
                  </Link>
                </Col>
              </div>
              <div>
                <Row className="mx-1">
                <Col md={6} sm={12} >
                    <Select
                      showSearch
                      placeholder="Select a Class"
                      optionFilterProp="label"
                      onChange={onChange}
                      options={classLevelData}
                      disabled={disable}
                      style={{
                        width: "100%",
                        marginBottom:3
                      }}
                      // open={visibilityClass}
                      // onMouseLeave={handleMouseLeaveClass}
                      // onClick={handleSelectClickClass}
                      // onFocus={() => setVisibilityClass(true)}
                      // onBlur={() => setVisibilityClass(false)}
                    />
                  </Col>
                  <Col md={6} sm={12} >
                    <Select
                      disabled={viewVersion !== 1 || disable}
                      placeholder="Select Status"
                      optionFilterProp="children"
                      onChange={onChange1}
                      value={viewOption}
                      options={[
                        {
                          value: 0,
                          label: 'All',
                        },
                        {
                          value: 1,
                          label: 'Assign',
                        },
                        {
                          value: 2,
                          label: 'Unassign',
                        },

                      ]}
                      style={{
                        width: "100%",
                        marginBottom:3
                      }}
                      // open={visibilityStatus}
                      // onMouseLeave={handleMouseLeaveStatus}
                      // onClick={handleSelectClickStatus}
                      // onFocus={() => setVisibilityStatus(true)}
                      // onBlur={() => setVisibilityStatus(false)}
                    />
                  </Col>
                </Row>
              </div>

              <br />
              <hr />
            </Col>
          </Row>
          <Spin tip="Loading..." spinning={loading} size="large">
            <Lesson  getDisableValue={disableStatus} data={data} updateChanges={onSubmitFunction} resetChanges={onResetFunction} changeStats={changeFunction} assignOption={viewVersion} viewOption={viewOption}/>
          </Spin>
          
        </div>
      </div>
    </>
  );
}

export default LessonIndex;
