export const PointerModule = [
    {"value": "Star" , "name": "Star"},
    {"value": "horizontalLine" , "name": "Horizontal Line"},
    {"value": "verticalLine" , "name": "Vertical Line"},
    {"value": "dot" , "name": "Dot"},
    {"value": "circleLine" , "name": "Circle"},
]
 
export const DirectionModule = [
    {"value": "0" , "name": "Special Case"},
    {"value": "1" , "name": "Horizontal"},
    {"value": "2" , "name": "Vertical"},
]