
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import Config from '../api/Config';



export const getAccoutList = createAsyncThunk("post/getAccoutList", async () => {
    let token = localStorage.getItem("access_token");
    const response = await axios.get(Config.APIURL +`admin/account/getAccountsByUserId`, {
    // const response = await axios.get(Config.APIURL +`admin/account/getAccountAdminsByAccountId/1`, {
        headers:{
            "Authorization": `Bearer ${token}`
        }
    })
    return response.data
    
})

const initialState = {
    accounts: [],
    accountList:[],

}

const accountSlice = createSlice({
    name: "accounts",
    initialState,

    reducers: {
        addaccount: (state, action) => {
            state.accounts = (action.payload);
           
        },
        // addToTable: (state, action) => {
        //     state.studentTable.push(action.payload)
        // },

        // editStudent: (state, action) => { 
        //     state.students = action.payload 
        // }

    },
    extraReducers: (builder) => {
        builder.addCase(getAccoutList.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAccoutList.fulfilled, (state, action) => {
            state.loading = false;
            state.accountList = [action.payload];
            state.error = ''
        })
        builder.addCase(getAccoutList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})

export const AccountReducer = accountSlice.reducer;

export const { addaccount, } = accountSlice.actions;