import axios from "axios";
import Config from "./Config";


class DashboardService {
  //getDashboardSummaryToLoggedUser 
  getDashboardSummaryToLoggedUser(oid, aid) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + `admin/dashboard/getDashboardSummaryToLoggedUser?organizationId=${aid || ""}&accountId=${oid || ""}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  //getAllClassesToLoggedUser
  getAllClassesToLoggedUser() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/dashboard/getAllClassesToLoggedUser', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  //getStudentsGraphDataToLoggedUser
  getStudentsGraphDataToLoggedUser(classId,dateRange) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + `admin/dashboard/getStudentsGraphDataToUserRole?classId=${classId}&dateRange=${dateRange}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  //getLessonsGraphDataToLoggedUserRole
  getLessonsGraphDataToLoggedUserRole(classId,dateRange) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + `admin/dashboard/getLessonsGraphDataToLoggedUserRole?classId=${classId}&dateRange=${dateRange}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  // getLessonsAndStudentCountDataToLoggedUser
  getLessonsAndStudentCountDataToLoggedUser(classId,dateRange) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + `admin/dashboard/getLessonsAndStudentCountDataToLoggedUser?classId=${classId}&dateRange=${dateRange}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }
}

export default new DashboardService();

