import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
    Card,
    CardText,
    CardImg,
    Row,
    Col,
    CardImgOverlay,
    Button,
    Badge,
    FormGroup,
    Label,
    FormFeedback
} from "reactstrap";
import {
    message,
    Steps,
    Form,
    Input,
    Space,
    Select,
    Checkbox,
    Radio,
    Divider,
    notification
} from "antd";
import "antd/dist/antd.css";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { Editor } from "react-draft-wysiwyg";

const { TextArea } = Input;
const options1 = ['Experiment Type ', 'Unit Type', 'Unit'];

const { Option } = Select;


const children = [];

function AddExpTypes(props) {


    const onFinish = (values) => {
        let arr = values.types.filter(x => x.dropdown.length > 0 && x.option.length > 0 && x.dropdown !== undefined && x.option !== undefined && x.dropdown.trim().length !== 0 && x.option.trim().length !== 0);
        // values.types.map((item,index)=>{
        //     if(item.dropdown !== undefined || item.option !== undefined || item.dropdown?.length ===0  || item.option?.length ===0){
        //     }
        //     else{
        //         arr.push(item)

        //     }

        // console.log("chk",)
        // })
        if (arr.length > 0) {
            let obj = { ["types"]: arr }
            props.onFinish(obj);
            form.resetFields()
            setDisabled(true)
            showNotification("success");
        }
        else {
            showNotification("error");
        }

    };

    const showNotification = (type) => {
        if(type === "success"){
            props.onChildrenDrawerClose()
            notification.open({
                placement: 'top',
                duration: '2',
                description: [
                    <li>
                    <CheckCircleOutlined className="text-success ant-icon-up" />
                        <span className="ml-4">Sub Experiment updated successfully</span>
                    </li>
                ]
            })
        }
        else {
            notification.open({
                placement: 'top',
                duration: '2',
                description: [
                    <li>
                        <CloseCircleOutlined className="text-success ant-icon-up" />
                        <span className="ml-4">Please Add Experiment Sub Types</span>
                    </li>
                ]
            })
        }
    }
    const [disabled, setDisabled] = useState(false);
    const onChange = (values) => {

        setDisabled(false)
    };

    const [form] = Form.useForm();

    useEffect(() => {
        if (props.types !== undefined) {
            form.setFieldsValue({ types: props.types })
        }
        setDisabled(true)
        // else{
        //     form.resetFields();
        //     setDisabled(true)

        // }
    }, [props])

    const [selectedItems, setSelectedItems] = useState([]);
    const filteredOptions1 = options1.filter((o) => !selectedItems.includes(o));

    return (

        <Form name="dynamic_form_nest_item" onFinish={onFinish} form={form} autoComplete="off" onChange={onChange}>
            <Form.List name="types">
                {(fields, { add, remove }) => (
                    <>
                                {
                                    props.view === false && <div className='text-right'>
                                        <Badge color="dark" className='p-2' onClick={() => add()} style={{position:"absolute", right:24, top:15}} >
                                            Add New
                                        </Badge>
                                    </div>
                                }

                        {fields.map(({ key, name, ...restField }) => (
                            <>
                                <Form.Item {...restField}
                                    name={[name, 'dropdown']} label="Sub Experiment "
                                    rules={[{ required: true, message: "Sub Experiment is required" }]}
                                >

                                    <Input />

                                </Form.Item>
                                <Form.Item {...restField}
                                    name={[name, 'option']} label="Sub Option "
                                    rules={[{ required: true, message: "Sub Option is required" }]}
                                >

                                    <Input />


                                </Form.Item>
                                {/* {props.view === false &&
                                            <div className='text-right'>
                                            <Badge color="danger" className='p-2' onClick={() => {
                                                remove(name)
                                                setDisabled(false)
                                            }} >
                                                Remove This
                                            </Badge>
                                        </div>
                                        } */}


                            </>


                        ))}
                    </>
                )}
            </Form.List>
            <Row>
                <Col xs='12'>
                    <button disabled={disabled} class="btn btn-success btn-sm btn-block my-2" type="primary" htmlType="submit" block  >
                        Add  to List
                    </button>
                </Col>
            </Row>
        </Form>

    );
}

export default AddExpTypes;
