export const UserData = [
    {
        id: 1,
        distance: 513,
        time: 0,
    },
    {
        id: 2,
        distance: 466,
        time: 2,
    },
    {
        id: 3,
        distance: 414,
        time: 4,
    },
    {
        id: 4,
        distance: 367,
        time: 6,
    },
    {
        id: 5,
        distance: 315,
        time: 8,
    },
    {
        id: 6,
        distance: 261,
        time: 10,
    },
    {
        id: 7,
        distance: 212,
        time: 12,
    },
    {
        id: 8,
        distance: 153,
        time: 14,
    },
    {
        id: 9,
        distance: 94,
        time: 16,
    },
    {
        id: 10,
        distance: 41,
        time: 18,
    },
];