import { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Table,
    CardSubtitle,
    CardText,
    CardImg,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";
import { Radio, Input, Form, Modal, Button, Tabs, Select, Image } from "antd";
import "antd/dist/antd.css";
import img1 from "../../../assets/images/img1.jpg";

import video from "../../../assets/images/video.png"

import LineChart from "../../../components/Charts/LineChart";
import { UserData } from "../../../components/Charts/Data";

import LabDescription from "../LabDescription";

function Question(props) {



    return (
        <>
        <div className="container-fluid">
                <Card className="m-0">
                    <CardBody>
                        <Row>
                            <Col xs="12">
                                <Image
                                    src={img1}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
        </div>
        </>
    );
}

export default Question;
