
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Config from '../api/Config';

export const getStudents = createAsyncThunk("edit/student", async (dataId) => {
    let token = localStorage.getItem("access_token");
    const response = await axios.get(Config.APIURL +`admin/student/searchStudentById/${dataId}`, {
        headers:{
            "Authorization": `Bearer ${token}`
        }
    })
    return response.data
    
})



const initialState = {
    editstudentData2: [],
    studentTable: [],
    students2: [],
}

const studentSlice = createSlice({
    name: "students",
    initialState,
    
    reducers: {
        getStudent: (state, action) => {
            state.editstudentData2 = (action.payload);
        
        },
        addToTable: (state, action) => {
            state.studentTable.push(action.payload)
        },

        editStudent: (state, action) => { 
            state.students2 = action.payload 
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getStudents.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getStudents.fulfilled, (state, action) => {
            state.loading = false;
            state.editstudentData = action.payload;
            state.error = ''
        })
        builder.addCase(getStudents.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})

export const studentreducer = studentSlice.reducer;

export const { addStudent, editStudent, addToTable } = studentSlice.actions;