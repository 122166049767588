import { useState, useEffect } from "react";
import { Card, CardText, FormFeedback, Row, Col, Input, Button } from "reactstrap";
import { Alert, notification, Spin, Select } from 'antd';
import SchoolService from "../../../../api/SchoolService"
import useCustFormValidator from "../../../../hooks/useCustFormValidator";
import validate from './schoolFormValidationRules';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";
import jsonToken from 'jsonwebtoken';

function SchoolCreate(props) {
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  var intiFormData = {
    "name": "",
    "description": "",
    "account_id": -1
  };

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });
  const [defaultSelectedAccount, setDefaultSelectedAccount] = useState(props.accountId);

  const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(saveSchool, validate, intiFormData);

  useEffect(() => {
    loadAccounts();

    //Set selected account default value selected on handleChange
    if (defaultSelectedAccount.value === undefined) {
      handleChange(null, { key: defaultSelectedAccount }, "account_id");
    }
  }, []);

  useEffect(() => {
    if (values.account_id !== -1) {
      setDefaultSelectedAccount({ value: values.account_id, label: accounts.filter(x => x.id == values.account_id).map(x => x.name)[0] });
    } else {
      setDefaultSelectedAccount(props.accountId);
    }
  }, [values]);

  function loadAccounts() {
    setLoading(true);
    SchoolService.getAccountsForUser()
      .then((response) => {
        if (response?.data?.accountList) {
          setAccounts(response.data.accountList);
        }
      }).catch((err) => {
        console.log(err.message);
      }).finally(() => {
        setLoading(false);
      });
  }

  function saveSchool() {
    setLoading(true);
    let submitPayload = {
      id: 0,
      description: values.description,
      account_id: values.account_id,
      name: values.name,
      status: 1,


    }
    SchoolService.saveNewSchool(submitPayload)
      .then((response) => {
        if (response.status == 200) {
          let messages = [{ type: 'success', text: response.message }]
          notification.open({
            placement: 'top',
            duration: "2",
            description: [
              messages.map((item) => {
                if (item.type === 'success') {
                  return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'error') {
                  return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'warning') {
                  return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
              })
            ],

            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
          clearForm2();
        }
        else {
          let messages = [{ type: 'error', text: response.message }]
          notification.open({
            placement: 'top',
            duration: "2",
            description: [
              messages.map((item) => {
                if (item.type === 'success') {
                  return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'error') {
                  return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'warning') {
                  return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
              })
            ],

            onClick: () => {
              console.log('Notification Clicked!');
            },
          });

        }
      }).catch((err) => {
        let messages = [{ type: 'error', text: err.message }]
        notification.open({
          placement: 'top',
          duration: "2",
          description: [
            messages.map((item) => {
              if (item.type === 'success') {
                return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
              }
              if (item.type === 'error') {
                return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
              }
              if (item.type === 'warning') {
                return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
              }
            })
          ],

          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      }).finally(() => {
        setLoading(false);
      });
  }

  function clearForm() {
    reset();
    hideAlert();
    setFormData(intiFormData);
    props.setFlag(false);
  }

  function setInitialValues() {
    if (accessToken.roles[0] === "ACCOUNT_ADMIN") {
      var newDataset = intiFormData;
      newDataset.account_id = values.account_id;
      setFormData(newDataset)
    } else {
      setFormData(intiFormData);
    }
  }

  function clearForm2() {
    reset();
    setInitialValues();
    setTimeout(function () {
      hideAlert();
    }, 2000);
  }

  function hideAlert() {
    var alert = { showAlert: false, message: "", type: "success" }
    setAlertData(alert);
  }

  return (
    <>
      <div>
        <Row>
          <Col md="3"></Col>
          <Col lg='6' className="py-2 text-center">
            {alertData.showAlert ?
              <Alert message={alertData.message} type={alertData.type}
                action={
                  <a type="text" onClick={hideAlert}>
                    x
                  </a>
                } /> : ""}
          </Col>
        </Row>
        <Row>
          <Col md="3"></Col>
          <Col md="6">
            <Spin tip="Loading..." spinning={loading} size="large">
              <div className="form-outline mb-3">
                <label className="form-label" htmlFor="form3Example4">
                  <span className="required-field">*</span>School Name
                </label>
                <Input
                  type="text"
                  id="form3Example4"
                  className="form-control form-control-lg"
                  autocomplete="off"
                  placeholder=""
                  value={values.name}
                  name="name"
                  onChange={(e) => { handleChange(e) }}
                  invalid={!!errors?.name}
                />
                <FormFeedback>{errors?.name}</FormFeedback>
              </div>
              <div className="form-outline mb-3">
                <label className="form-label" htmlFor="form3Example4">
                  <span className="required-field">*</span>Account
                </label>
                <Select
                  showSearch
                  placeholder="Select an account"
                  optionFilterProp="children"
                  size="large"
                  status={errors?.account_id ? "error" : ""}
                  className="search-drop"
                  disabled={disableEnableDropdown("Account", props.user_roll)}
                  value={defaultSelectedAccount}
                  onChange={(value, event) => handleChange(value, event, "account_id")}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <option key="-1" value="-1">Please Select an Account</option>
                  {
                    accounts.filter(item => item.name !== null).map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                  }
                </Select>
                <FormFeedback className="override-FormFeedback">{errors?.account_id}</FormFeedback>
              </div>
              <div className="form-outline mb-3">
                <label className="form-label" htmlFor="form3Example4">
                  <span className="required-field">*</span>Description
                </label>
                <Input
                  class="form-control"
                  id="textarea"
                  type="textarea"
                  value={values.description}
                  name="description"
                  onChange={(e) => { handleChange(e) }}
                  invalid={!!errors?.description}
                ></Input>
                <FormFeedback>{errors?.description}</FormFeedback>
              </div>

              <div className="center">
                <Button onClick={clearForm} color="warning" className="mr-2 fixed-width-115">Cancel</Button>
                <Button onClick={handleSubmit} color="success" className="fixed-width-115">Save</Button>
              </div>
            </Spin>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SchoolCreate;
