import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Row, Upload, Col, Spin, notification } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Config from '../../../../api/Config';
import { CheckCircleOutlined } from "@ant-design/icons";

const AddExplorerMultipleImagesUpload = (props) => {
  let imageMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg'];

  const showNotificationOnTop = (type, message) => {
    notification.open({
      placement: 'top',
      duration: '2',
      description: [
        type === 'success' ?
        <li>
          <CheckCircleOutlined className="text-success ant-icon-up" />
          <span className="ml-4">{message}</span>
        </li>
        : 
        <li>
          <CheckCircleOutlined className="text-danger ant-icon-up" />
          <span className="ml-4">{message}</span>
        </li>
      ]
    })
  }

  const uploadImage = async options => {
    const { onSuccess, onError, file, onProgress } = options;
    const isImage = imageMimeTypes.includes(file.type);
      if (!isImage) {
        showNotificationOnTop('error', 'Only image files are allowed!');
        const error = new Error("Wrong file type");
        onError({ error });
        return false;
      }else{
        const token = localStorage.getItem("access_token");
        const fmData = new FormData();
        const config = {
          headers: { "Authorization": `Bearer ${token}` },
        };
        fmData.append("file", file);
        fmData.append("uId", file.uid);

        try {
          const res = await axios.post(
            `${Config.APIURL}admin/lesson/uploadFile`,
            fmData,
            config
          );
          onSuccess("Ok");
          props.uploadFileData(res.data.data.fileUrl,res?.data?.data?.lessonCode,file.uid)
        } catch (err) {
          new Error("Some error");
          onError({ err });
        }
    }
  }



  const onRemove = async (file) =>{
    const token = localStorage.getItem("access_token");
    const fmData = new FormData();
    const config = {
      headers: { "Authorization": `Bearer ${token}` },
    };
      if(file.originFileObj){
        fmData.append("fileName", `${file.originFileObj.uid}_SSA_${file.originFileObj.name}`);
      }else{
        fmData.append("fileName", `${file.uid}_SSA_${file.name}`);
      }
    try {
      const res = await axios.post(
        `${Config.APIURL}admin/lesson/deleteFile`,
        fmData,
        config
      );
      props.deletedFile(res.data.data.fileUrl)
    } catch (err) {
      console.log("Eroor: ", err);
    }

  }


  const dataValues = {
    multiple: true,
    listType: 'text',
    showUploadList: { showRemoveIcon: true },
    defaultFileList: props.existingData !== undefined && props.existingData.length !== 0 && props.existingData[0] !== undefined ? Object.values(props.existingData) : [],
    accept: imageMimeTypes.join(','), // accept only image files
    customRequest:uploadImage,
    classNames: 'custom-image-uploader',
    onRemove: onRemove,
  };

  return (
  
    <>
    {props.type === "image" && (
      <div className='upload'>
      <Upload.Dragger
      disabled={props?.disable === true}
      {...dataValues}
      >
        <Button className='upload-btn' block icon={<UploadOutlined className='ant-icon-up' />}>Drag and Drop or Click to Upload</Button>
      </Upload.Dragger>
      </div>
    )}
      
  
    </>
  );
}


export default AddExplorerMultipleImagesUpload;