import { useState, useEffect } from "react";
import { Card, CardText, FormFeedback, Row, Col, Input, Button, FormGroup } from "reactstrap";
import { Alert, notification, Spin, Select } from 'antd';
import AccountAdminService from "../../../../api/AccountAdminService"
import useCustFormValidator from "../../../../hooks/useCustFormValidator";
import validate from './editFormValidation';
import SchoolService from "../../../../api/SchoolService";
import jsonToken from 'jsonwebtoken'
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";

function AdminCreate(props) {
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  var obj = {
    age: null,
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    username: "",
    organization_id: -1,
    confirmPassword: '',
    status: 1,
    roleId: 3,
    accountId: -1
  }

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });
  const [showPass, setShowPass] = useState(false);
  const [schools, setSchools] = useState([]);
  const { handleChange, handleSubmit, setFormData, reset, values, errors } = useCustFormValidator(onSave, validate, obj);
  const [edited, setEdited] = useState(false);
  const [uneditedObj, setUneditedObj] = useState({});
  const [accountChange, setAccountChange] = useState(false);
  const [defaultSelectedAccount, setDefaultSelectedAccount] = useState({});

  useEffect(() => {
    loadAccounts()
  }, []);

  useEffect(() => {
    if (accessToken.roles[0] === "ACCOUNT_ADMIN") {
      if (accounts.length === 1) {
        setFormData({ ...values, accountId: accounts[0].id })
      }
    }
  }, [accounts])

  useEffect(() => {
    getAdminFromId();
  }, [props.selectedId]);

  function loadAccounts() {
    setLoading(true);
    AccountAdminService.getAccountsForUser()
      .then((response) => {
        if (response?.data?.accountList) {
          setAccounts(response.data.accountList);
        }
      }).catch((err) => {
        console.log(err.message);
      }).finally(() => {
        setLoading(false);

      });
  }

  function getAdminFromId() {
    if (!props.selectedId || props.selectedId <= 0) {
      return
    }
    AccountAdminService.getSchAdminById(props.selectedId)
      .then((response) => {
        if (response?.data) {
          var data = response.data;
          data.password = '';
          data.confirmPassword = '';
          setFormData(data);
          setUneditedObj(data);
        }
      }).catch((err) => {
        console.log(err.message);
      }).finally(() => {
    setLoading(false);

      });
  }

  function onShowPassChange(e) {
    if (!e.target.checked) {
      setFormData({ ...values, password: '', confirmPassword: '', showPass: false })
    } else {
      setFormData({ ...values, showPass: true })
    }
    setShowPass(e.target.checked)
  }

  function onSave() {
    delete values.confirmPassword;

    let payload = values;
    payload.id = props.selectedId;
    payload.age = '';
    payload.status = 1;
    payload.roleId = 3;
    payload.schoolId = values.organization_id;

    setLoading(true);

    AccountAdminService.updateSchAdmin(payload)
      .then((response) => {
        if (response?.status === 200) {
          reset();
          setEdited(false)
          let messages = [{ type: 'success', text: response.message }]
          notification.open({
            placement: 'top',
            duration: "2",
            description: [
              messages.map((item) => {
                if (item.type === 'success') {
                  return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'error') {
                  return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'warning') {
                  return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
              })
            ],

            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
          getAdminFromId();
        }
        if (response?.status === 500) {
          let messages = [{ type: 'error', text: response.message }]
          notification.open({
            placement: 'top',
            duration: "2",
            description: [
              messages.map((item) => {
                if (item.type === 'success') {
                  return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'error') {
                  return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'warning') {
                  return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
              })
            ],

            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        }
      }).catch((err) => {
        let messages = [{ type: 'error', text: err.message }]
        notification.open({
          placement: 'top',
          duration: "2",
          description: [
            messages.map((item) => {
              if (item.type === 'success') {
                return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
              }
              if (item.type === 'error') {
                return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
              }
              if (item.type === 'warning') {
                return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
              }
            })
          ],

          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      }).finally(() => {
         setLoading(false);
      });
  }

  function hideAlert() {
    var alert = { showAlert: false, message: "", type: "success" }
    setAlertData(alert);
  }

  useEffect(() => {
    setSchools([])
    setLoading(true);

    if (values.accountId) {
      SchoolService.getSchoolsForAccount(values.accountId)
        .then(res => {
          if (res.status === 200) {
            setSchools(res.data)
          }
        })
        .finally(() => {
    setLoading(false);

        })
    }
  }, [values.accountId])

  useEffect(() => {
    setEdited(JSON.stringify(values) !== JSON.stringify(uneditedObj))
    if (values.accountId !== -1) {
      setDefaultSelectedAccount({ value: values.accountId, label: accounts.filter(x => x.id == values.accountId).map(x => x.name)[0] });
    } else {
      setDefaultSelectedAccount({});
    }
  }, [values]);

  function onEmailChange(e) {
    e.persist();
    setFormData({ ...values, username: e.target.value, email: e.target.value })
  }

  function onAccountChange(value, event) {
    setAccountChange(true);
    setFormData({ ...values, accountId: value, organization_id: -1 });
  }

  function onChangeSchool(value) {
    setFormData({ ...values, organization_id: value })
  }

  return (
    <>
      <div>
        <Spin tip="Loading..." spinning={loading} size="large">
          <Row>
            <Col lg='12' className="py-2 text-center">
              {alertData.showAlert ?
                <Alert message={alertData.message} type={alertData.type}
                  action={
                    <a type="text" onClick={hideAlert}>
                      x
                    </a>
                  } /> : ""}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                  <label className="form-label" htmlFor="form3Example1">
                    <span className="required-field">*</span>Account
                  </label>
                  <Select
                  getPopupContainer={trigger => trigger.parentElement}
                    showSearch
                    placeholder="Select an account"
                    optionFilterProp="children"
                    size="large"
                    className="search-drop"
                    value={defaultSelectedAccount}
                    disabled={disableEnableDropdown("Account", props.user_roll)}
                    onChange={(value, event) => onAccountChange(value, event)}
                    invalid={!!errors?.accountId}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    <option key="-1" value="-1">Please Select an Account</option>
                    {
                      accounts.filter(item => item.name !== null).map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                    }
                  </Select>
                  <FormFeedback>{errors?.accountId}</FormFeedback>
                </div>
                <div class="form-group col-md-6 col-sm-12 override-FormFeedback">
                  <label className="form-label" htmlFor="form3Example1">
                    <span className="required-field">*</span>School
                  </label>
                  <Select
                  getPopupContainer={trigger => trigger.parentElement}
                    showSearch
                    placeholder="Select a School"
                    optionFilterProp="children"
                    size="large"
                    className="search-drop"
                    value={values.organization_id == -1 ? "Please Select a School" : values.schoolId}
                    disabled={schools.length === 0}
                    onChange={(value, event) => {
                      onChangeSchool(value);
                    }}
                    invalid={!!errors?.organization_id}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    <option selected key="-1" value="-1">Please Select a School</option>
                    {
                      schools?.map((obj) =>
                        <option key={obj.id} value={obj.id}>{obj.name}</option>
                      )
                    }
                  </Select>

                  <FormFeedback>{errors?.organization_id}</FormFeedback>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example2">
                    <span className="required-field">*</span>First Name
                  </label>
                  <Input
                    type="text"
                    id="form3Example2"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.firstName}
                    name="firstName"
                    onChange={handleChange}
                    invalid={!!errors?.firstName}
                  />
                  <FormFeedback>{errors?.firstName}</FormFeedback>
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example3">
                    <span className="required-field">*</span>Last Name
                  </label>
                  <Input
                    type="text"
                    id="form3Example3"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.lastName}
                    name="lastName"
                    onChange={handleChange}
                    invalid={!!errors?.lastName}
                  />
                  <FormFeedback>{errors?.lastName}</FormFeedback>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example4">
                    <span className="required-field">*</span>Email
                  </label>
                  <input type="text" id="hiddenField" style={{display: "none"}} autoComplete="email" />
                  <Input
                    type="text"
                    id="form3Example4"
                    className="form-control form-control-lg"
                    placeholder=""
                    value={values.email}
                    name="email"
                    autoComplete="new_mail"
                    onChange={(e) => { onEmailChange(e) }}
                    invalid={!!errors?.email}
                  />
                  <FormFeedback>{errors?.email}</FormFeedback>
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example5">
                    <span className="required-field">*</span>Username
                  </label>
                  <input type="text" id="hiddenField" style={{display: "none"}} autoComplete="username" />
                  <Input
                    type="text"
                    id="form3Example5"
                    className="form-control form-control-lg"
                    placeholder=""
                    value={values.username}
                    name="username"
                    autoComplete="off"
                    onChange={handleChange}
                    invalid={!!errors?.username}
                    disabled={true}
                  />
                  <FormFeedback>{errors?.username}</FormFeedback>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <FormGroup check inline >
                    <Input type="checkbox"
                      checked={values.showPass}
                      onChange={onShowPassChange}
                    />
                    <label check className="pt-6">
                      Change Password
                    </label>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row" hidden={!values.showPass}>
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example6">
                    <span className="required-field">*</span>Password
                  </label>
                  <input type="password" id="hiddenPasswordField" style={{display: "none"}} autoComplete="current-password" />
                  <Input
                    type="password"
                    id="form3Example6"
                    className="form-control form-control-lg"
                    placeholder=""
                    value={values.password}
                    name="password"
                    autoComplete="off"
                    onChange={handleChange}
                    invalid={!!errors?.password}
                  />
                  <FormFeedback>{errors?.password}</FormFeedback>
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example7">
                    <span className="required-field">*</span>Confirm Password
                  </label>
                  <Input
                    type="password"
                    id="form3Example7"
                    className="form-control form-control-lg"
                    autoComplete="off"
                    placeholder=""
                    value={values.confirmPassword}
                    name="confirmPassword"
                    onChange={handleChange}
                    invalid={!!errors?.confirmPassword}
                  />
                  <FormFeedback>{errors?.confirmPassword}</FormFeedback>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <div className="center mt-4">
                <Button color="warning" className="mr-15" onClick={props.onClose}>Cancel</Button>
                <Button color="success" onClick={() => {
                  handleSubmit()
                }} disabled={!edited}>Save</Button>
              </div>
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
}

export default AdminCreate;
