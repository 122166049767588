import { useEffect, useState } from "react";
import { Card, Row, Col, CardBody } from "reactstrap";
import { Button } from "react-bootstrap";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import Create from "./Create";
import View from "./view";
import jsonToken from 'jsonwebtoken'
import SchoolService from "../../../../api/SchoolService";

function Term(props) {
  const [flag, setFlag] = useState(false);
  const [key, setKey] = useState(1);
  const [accountId, setAccountId] = useState({ value: -1, label: "Please Select an Account" });
  const [organizationId, setorganizationId] = useState({ value: -1, label: "Please Select a School" });
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));

  useEffect(() => {
    setFlag(false)
    loadAccounts();
  }, [props])

  useEffect(() => {
    if (props.activeTab == props.tabId) {
      var tempKey = key + 1;
      setKey(tempKey)
    }
  }, [props.activeTab])

  //Need to Refactor This function
  function loadAccounts() {
    SchoolService.getAccountsForUser()
      .then((response) => {
        if (response?.data?.accountList?.length == 1) {
          setAccountId(response.data.accountList[0].id)
        }
      }).catch((err) => {
        console.log(err.message);
      })
  }

  return (
    <>
      <div className="container-fluid admin-tabs">
        <Row>
          <Col md="12">
            <Card className="border-0">
              <CardBody className="e">
                <Row>
                  <Col md="6">
                    <h5>Term</h5>
                  </Col>
                  <Col md="6">
                    <div className="pull-right">
                      <Button
                        type="primary"
                        className="mr10 mb20"
                        onClick={() => setFlag(!flag)}
                        size="sm"
                      >
                        {flag ? "View" : "New"}
                      </Button>
                      <Button type="primary" className="mb20 hidden" size="sm">
                        Import
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {flag ? <Create a={flag} setFlag={setFlag} user_roll={accessToken.roles[0]} accountId={accountId} organizationId={organizationId} /> : <View user_roll={accessToken.roles[0]} key={key} h={flag} activeTab={props.activeTab} tabId={props.tabId} />}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Term;
