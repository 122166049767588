import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Tabs, Input, Table, Select, Spin, Popconfirm, notification } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined, CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  Row,
  Col,
  CardBody,
  Button,
} from "reactstrap";
import LessonService from "../../../../api/LessonService";

const dataSource = [
  {
    key: "1",
    name: "Units",
  },

  {
    key: "2",
    name: "Error",
  },
  {
    key: "23",
    name: "Units",
  },
];


const { Option } = Select;
const onChange = (value) => {
  console.log(`selected ${value}`);
};

const onSearch = (value) => {
  console.log("search:", value);
};


function TermCreate(props) {
  const [data, setData] = useState(dataSource);
  const [input, setInput] = useState("");
  const [searchData, setSearchData] = useState([])
  const [loading, setLoading] = useState(false)



  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      render: (text, record) =>
        <div className="button-set">
          <span className="btn btn-sm btn-info float-left" onClick={() => { props.onEdit(record.id, record) }} >Edit</span>
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={(e) => { deleteRecord(record) }}
            okText="Yes"
            cancelText="No"
          >
            <span className='btn btn-sm btn-danger float-left ml-1' size="sm" >
              Delete
            </span >
          </Popconfirm>
        </div>
      ,
      className: "action"
    },
  ];
  useEffect(() => {
    loadExperimentTypes();
  }, [])

  const loadExperimentTypes = () => {
    setLoading(true)
    LessonService.getAllExperimentsTypes().then(res => {
      setData(res.data)
      setLoading(false)
    })
  }

  const deleteRecord = (record) => {
    setLoading(true);
    LessonService.deleteExperimentType(record.id).then((response) => {
      notification.open({
        placement: 'top',
        duration: "2",
        description: [<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{response.message}</span></li>],
      });
      loadExperimentTypes();
    }).catch((err) => {
      notification.open({
        placement: 'top',
        duration: "5",
        description: [<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{err.message}</span></li>],
      });
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (input.length > 0) {
      // console.log("data",data.filter( x => x.name.toLowerCase().includes(input.toLowerCase()) ),input,data.map(x => x.name.toLowerCase()))

      let resources = data.filter(x => x.name.toLowerCase().includes(input.toLowerCase()))
      let res = data.map(x => {
        return {
          key: x.key,
          name: x.name.toLowerCase()
        }
      }).filter(x => x.name.toLowerCase() === input.toLowerCase()).map(x => {
        return {
          key: x.key,
          name: x.name[0].toUpperCase() + x.name.substring(1, x.name.length + 1)
        }
      })
      //console.log("res",resources,input)
      setSearchData(resources)

    }
    else {
      setSearchData([])
    }
  }, [input])

  return (
    <Spin tip="Loading..." spinning={loading} size="large">
      <div>
        <div className="my-5">
          <Row>
            <Col lg='3'></Col>
            <Col lg="6" sm='12'>
              <Input
                size="large"
                placeholder="Search Name"
                prefix={<SearchOutlined className="ml-3 mr-3" />}
                onChange={(e) => {

                  setInput(e.target.value)




                }}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col md="12">
            <Table
              dataSource={input.length > 0 ? searchData : data}
              pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
              columns={columns}
              className="thead-dark admin-table"
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default TermCreate;
