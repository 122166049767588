import React, { useEffect, useState } from 'react'
import './style.scss'
import RestService from '../../api/RestService';
import SubSidebarNotificationSuperAdmin from './subSideNotificationSuperAdmin';
import { CloseOutlined } from '@ant-design/icons';

function SidebarNotificationSuperAdmin(props) {
    
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);

    const getNotification = async () => {
        await RestService.getSuperAdminNotificationDetails().then((res)=>{
            setData(res.data)

            let count = 0;
            count += res?.data?.successMessages?.length || 0;
            count += res?.data?.errorMessages?.length || 0;
            props.notificationCount(count);
            setLoader(false)
        })


    }
    const removeNotification =  async (ClickedData,keyValue) => {
        setLoader(true)
        let classId;
        let notificationId;

        if(keyValue === "classId"){
            notificationId=null;
            classId=ClickedData[keyValue];
        }else{
            notificationId=ClickedData[keyValue];
            classId=null;
        }

        await RestService.readSuperAdminNotificationDetails(notificationId, classId).then((res)=>{
            getNotification(res);
        })
    }

    const removeAllNotification = async (value, key) => {
        let stringValues ="";
        let classId2;
        let notificationId2;
        value.map((x, index)=>{
            if(index ===0){
                stringValues += `${x[key]}`;
            }else{
                stringValues += `,${x[key]}`;
            }
        });

        if(key === "classId"){
            notificationId2=null;
            classId2=stringValues;
        }else{
            notificationId2=stringValues;
            classId2=null;
        }

        await RestService.readSuperAdminNotificationDetails(notificationId2, classId2).then((res)=>{
            getNotification(res);
        })
    }
    useEffect(()=>{
        getNotification();
    },[props.isMenuOpen]);


    return (
        <>
            {data && Object.values(data).map((x, index) => {
                return (
                    <>
                        {data[Object.keys(data)[index]].length > 0 &&
                            <div className="my-3">
                                <br/>
                                <div className='notification-container-hover text-right' >
                                    <CloseOutlined onClick={()=>{ 
                                        const key = Object.keys(data)[index] === "successMessages" ? "classId" : "notificationId"
                                        removeAllNotification(x, key )
                                        }} />
                                </div>
                                <h6 className='text-white'>{Object.keys(data)[index] === "successMessages" ? "Success" : "Failed"}</h6>
                                {x.map((y)=>{
                                    return <SubSidebarNotificationSuperAdmin loader={loader} onClickValues={removeNotification} data={y} keyValue={Object.keys(data)[index] === "successMessages" ? "classId" : "notificationId"}/>   
                                })}
                            </div>
                        }
                    </>
                )
            })}
        </>
    )
}

export default SidebarNotificationSuperAdmin