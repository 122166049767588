import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from "react-router-dom";
// reactstrap components
import { Card, CardText, CardImg, Row, Col, CardBody } from "reactstrap";
import { Button } from "react-bootstrap";
import { Tabs, Input, Table, Space, Drawer } from "antd";
import "antd/dist/antd.css";
import Create from "./Create";
import View from "./view";
import ImportStudent from "./Import";
import jsonToken from 'jsonwebtoken';
import { disableEnableButtons } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableButtons";

function handleDownload() {
  const csvData = "First Name,Last Name,Username,Email,Password,School,Account,Class code";
  // const csvData = "first Name,Last Name,Username,Email,Password,School,Account";
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = "CSV_FileFormate.csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function Students(props) {
  const [flag, setFlag] = useState(false);
  const [key, setKey] = useState(1);
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));

  const onChange = (key) => {
    console.log(key);
  };
  useEffect(() => {
    setFlag(false)
  }, [props])

  useEffect(() => {
    if (props.activeTab == props.tabId) {
      var tempKey = key + 1;
      setKey(tempKey)
    }
  }, [props.activeTab])

  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="container-fluid admin-tabs">
        <Row>
          <Col md="12">
            <Card className="border-0">
              <CardBody>
                <Row>
                  <Col md="6">
                    <h5>Student</h5>
                  </Col>
                  <Col md="6">
                    <div className="pull-right">
                      <Button
                        type="primary"
                        className="mr10 mb20"
                        onClick={() => setFlag(!flag)}
                        size="sm"
                      >
                        {flag ? "View" : "New"}
                      </Button>
                      <Button hidden={disableEnableButtons("Import",accessToken.roles[0])} type="primary" className="mb20" size="sm" onClick={showDrawer}>
                        Import
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {flag ? <Create a={flag} setFlag={setFlag} user_roll={accessToken.roles[0]} /> : <View user_roll={accessToken.roles[0]} key={key} h={flag} activeTab={props.activeTab} tabId={props.tabId} />}
                  </Col>
                </Row>
              </CardBody>
              <Drawer
                className="import-drawer"
                title="Import Student List"
                placement="right"
                onClose={onClose}
                destroyOnClose={true}
                open={open}
                width={378}
              extra={
                <Space>
                  <Button type="primary" onClick={handleDownload}>
                    Sample CSV
                  </Button>
                </Space>
              }
              >
                <ImportStudent data={1}/>
              </Drawer>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Students;
