import { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Table,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Radio, Input, Form, Modal, Button, Tabs } from "antd";
import "antd/dist/antd.css";

import ExtendChild from "../LessonLab/Extend";

function Extend(props) {
  const { TabPane } = Tabs;
  const setChildData = (data) =>{
    props.childData(data)
  }
if(props?.data.levelData !== undefined){
  if(props?.data?.levelData.length > 0){
    return (
      <>
        <div className="content extend-page tab-style level-tab">
        <div className="p2 border-0">
        <center>
          <h4 className="h4-center">
              <span>Levels</span>
            </h4>
            </center>

  
            <Tabs defaultActiveKey="0">
              {
                props.data.levelData.map((x, index)=>{  
                  const payload = {
                    levelId: x.id,
                    CurriculumId: props.data.CurriculumId,
                    userId: props.data.userId,
                    lessonId: props.data.lessonId,
                  }
                  return(
                    <TabPane tab={x.name} key={index}>
                      <  ExtendChild data = {payload} complted = {true} subChildCall={setChildData}/>
                    </TabPane>
                  )
                })
              }
            </Tabs>
          </div>
        </div>
      </>
    );
  }else{
    return (
      <>
      <div className="content extend-page tab-style level-tab">
        <div className="p2 border-0">
        <center>
          <h4 className="h4-center">
            <span>Data not retrieved </span>
          </h4>
          </center>
        </div>
      </div>
      </>
    )
  }
}
}

export default Extend;
