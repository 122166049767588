import { useState, useEffect } from "react";
import { Spin, Input, Table, Select, Drawer } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import { Row, Col } from "reactstrap";
import Edit from "./Edit";
import SchoolService from "../../../../api/SchoolService"
import jsonToken from 'jsonwebtoken'
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";
function SchoolCreate(props) {

  const columns = [
    {
      title: "School Name",
      dataIndex: "name",
      key: "name",
      className: "fixed-size",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      render: (text, record) => <span className="btn btn-sm btn-info" onClick={(e) => { showEditDrawer(record); }}>Edit</span>,
      className: "action"
    },
  ];

  const [size, setSize] = useState();
  const [visible, setVisible] = useState(false);
  const [editKey, setEditKay] = useState(1);

  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [schools, setSchools] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [accountId, setAccountId] = useState(null);
  const [selectedSchoolEdit, setSelectedSchoolEdit] = useState(null);
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchSchoolText, setSearchSchoolText] = useState("");

  useEffect(() => {
    if (props.activeTab == props.tabId) {
      loadAccounts()
      if (accountId && accountId > 0) {
        loadSchools()
      }
    }
  }, [props.activeTab])

  useEffect(() => {
    if (accessToken.roles[0] === "ACCOUNT_ADMIN") {
      if (accounts && accounts.length === 1) {
        setAccountId(accounts[0].id);
      }
    }
  }, [accounts])

  useEffect(() => {
    loadSchools()
  }, [accountId]);

  function loadAccounts() {
    setLoading(true);
    SchoolService.getAccountsForUser()
      .then((response) => {
        if (response?.data?.accountList) {
          setAccounts(response.data.accountList);
        }
        if (response?.data?.accountList?.length == 1) {
          setAccountId(response.data.accountList[0].id)
        }
      }).catch((err) => {
        console.log(err.message);
      }).finally(() => {
        setLoading(false);
      });
  }

  function loadSchools(page = currentPage, pageSize = defaultPageSize, search = searchTerm) {
    setSchools([]);
    setTableData([]);
    if(accountId){
      setLoading(true);
      SchoolService.getSchoolsForAccountPaged(accountId, page, pageSize, search)
        .then((response) => {
          if (response?.data) {  
            setSchools(response.data.items);
            setTableData(response.data.items);
            setTotal(response.data.totalItems);
            setCurrentPage(response.data.page);
          }
        }).catch((err) => {
          console.log(err.message);
        }).finally(() => { 
          setLoading(false);
        });
    }
  }

  function onSearchByName(e) {
    setCurrentPage(1);
    const currValue = e.target.value;
    setSearchTerm(currValue);
    setCurrentPage(1);
    loadSchools(1, defaultPageSize, currValue);
    setSearchSchoolText(currValue);
  }

  const onClose = () => {
    setSelectedSchoolEdit(null);
    loadSchools()
    setVisible(false);
    setEditKay(prev => prev + 1);
  };

  const showEditDrawer = (record) => {
    setSelectedSchoolEdit(record);
    setSize("large");
    setVisible(true);
  };

  const onAccountChange = (value) => {
    setSearchSchoolText("");
    setSearchTerm("");
    setCurrentPage(1);
    setAccountId(value)
  };

  return (
    <>
      <div>
          <div className="my-5">
            <Row>
              <Col md="2">

              </Col>
              <Col md="4">
                <Select
                  showSearch
                  placeholder="Search by Account"
                  optionFilterProp="children"
                  size="large"
                  disabled={disableEnableDropdown("Account", props.user_roll)}
                  value={accountId}
                  className="search-drop"
                  onChange={onAccountChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {
                    accounts.length > 0 && (
                      <>
                        <option key="-1" value="-1" >Please Select an Account</option>
                        {
                          accounts.filter(item => item.name !== null).map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                        }
                      </>
                    )
                  }
                </Select>
              </Col>

              <Col md="4">
                <Input
                  size="large"
                  placeholder="Search"
                  prefix={<SearchOutlined className="ml-3 mr-3" />}
                  onChange={onSearchByName}
                  value={searchSchoolText}
                />
              </Col>
            </Row>
          </div>

          <Row>
            <Col md="12">
              <Table
                dataSource={tableData}
                //pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                pagination={{
                  current: currentPage,
                  position: ["bottomCenter"],
                  total: total, // Total number of items in the API response (you may need to fetch this dynamically)
                  pageSize: defaultPageSize, // Number of items to display per page
                  onChange: (page, pageSize) => loadSchools(page, pageSize), // Callback function to handle page changes
                  showSizeChanger: false
                }}
                loading={loading}
                columns={columns}
                className="thead-dark admin-table"
              />
            </Col>
          </Row>
          <Drawer
            className="medium"
            title={`Edit School`}
            placement="right"
            size={size}
            onClose={onClose}
            visible={visible}
          >
            <Edit key={editKey} user_roll={props.user_roll} selectedSchoolName={selectedSchoolEdit?.name} onClose={onClose} selectedSchoolId={selectedSchoolEdit?.id} visible={visible} />
          </Drawer>
      </div>
    </>
  );
}

export default SchoolCreate;
