import React, { useEffect, useState } from 'react'
import { Row, Col, Badge, CardBody } from 'reactstrap'
import { Table, Space, Modal, Form, Input, Select, message } from 'antd'

import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import LessonService from '../../../../api/LessonService';
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";

const { TextArea } = Input;
const options1 = ['Experiment Type ', 'Unit Type', 'Unit'];

const { Option } = Select;


const Source1 = [
    {
        key: "1",
        id: "1",
        question:
            "Describe your approach in reaching a conclusion List any sources you used Show any formulas you used Explain the steps you took",
        action: "",
    },
    {
        key: "2",
        id: "2",
        question:
            "Describe the data you obtained. Show all or your data or information. Explain any sources or error or bias. Indicate units for all numeric data.",
        action: "",
    },
    {
        key: "3",
        id: "3",
        question:
            "Explain what your data mean. Write at least one conclusion based on your data. Explain why you chose your conclusion(s). List some other conclusions and explain why you rejected them. How can you test your final conclusion(s) further?",
        action: "",
    },
];



function AddExpQuestions(props) {

    const [form] = Form.useForm();
    const [experiment_id, setExperiment_id] = useState(0);
    const [qData,setQdata] = useState(props.questions);
    const [editorState, setEditorstate] = useState();
    const [selectedItems, setSelectedItems] = useState([]);
    const filteredOptions7 = options1.filter((o) => !selectedItems.includes(o));
    const filteredOptions8 = options1.filter((o) => !selectedItems.includes(o));
    const dataSet1 = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
        },
        {
            title: " Question",
            dataIndex: "description",
            key: "question",
        },
    
        {
            title: " Action",
            dataIndex: "action",
            key: "action",
            render: (_, record) => (
                <Space size="middle">
                    {record.action}
                    <Badge className="bg-success" size="sm" onClick={()=> onEdit(record)}   >
                        <small>Edit</small>
                    </Badge>
                    <Badge className="bg-warning" size="sm">
                        <small>View</small>
                    </Badge>
                    <Badge className="bg-danger" size="sm">
                        <small>Delete</small>
                    </Badge>
                </Space>
            ),
        },
    ];

    useEffect(() =>{
        if(props.questions !== undefined){
            setQdata(props.questions)
        }
        else 
    {
            setQdata([])
            onReset()
         
           
    }
        
    },[props.questions])
    
    useEffect(()=>{
        onReset()
    },[props])
    
    const onEdit= (data) =>{
       
           let res= props.answers?.find(x => x.question_id === data.id)
            form.setFieldsValue({
                
                qtype:options1.find((x,i) => i === parseInt(data.type)-1) ,
                answer_type:options1.find((x,i) => i === parseInt(res.answer_type)-1),
                answer1:res.answer1,
                answer2:res.answer2,
                descriptionquestion:convertToRaw(ContentState.createFromBlockArray(convertFromHTML(data.description.trim() )))

            });
            setEditorstate(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML( data.description.trim() ))))

       
           
    }
    const onChangeQuestion = (editorState) => {
        setEditorstate(editorState)
    }

    const onFinish =(values) =>{
        console.log("Table Values",values)

        let data = {
            id:props.questions.id !== undefined ? props.questions.id : 0,
            "description":draftToHtml(convertToRaw(editorState.getCurrentContent())),
          "type": options1.indexOf(values.qtype)+1,
          "explore_step_exp_id":props.experiment_id,
          "exploreStepId" : props.explore_id
        }

        LessonService.addExploreQuestions({
            id:props.experiment_id,
    "exploreStepQuestion": [
        data
    ]
        }).then(res =>{
            if(res.status !== 500){
                message.success(res.message)
            }else{
                message.error(res.message)
            }

        })

        let data2 = {
            explore_step_exp_id:props.experiment_id,
            exploreStepQuestionAnswers:[
                {
                    answer1:values.answer1,
                    answer2:values.answer2,
                    answerNumber:1,
                    answer_type:options1.indexOf(values.answer_type)+1,
                    question_id:props.questions.id !== undefined ? props.questions.id : 0,
                    exploreStepId:props.explore_id,
                    exploreStepExpId:props.experiment_id
                }
            ]
        }

        LessonService.addExploreAnswers(data2).then(res =>{
            if(res.status !== 500){
                message.success(res.message)
            }else{
                message.error(res.message)
            }

        })

        
    }
    const onReset =()=>{
        form.resetFields()
        setEditorstate()
    }

    const onSubmitQuestions = () =>{
        
    }

    return (
        <>
            <Row className='question-headder-style'>
                <Col xs='6' className='' >
                    <b>Add Questions</b>
                </Col>
            </Row>
            <Form form={form} onFinish ={onFinish} onReset={onReset} >
                <Form.Item name="descriptionquestion" label="Question" rules={[{ required: true, message: 'Missing Question' }]} >
                    {/* <Editor
                        editorClassName="demo-editor"
                        editorState={editorState}  onEditorStateChange ={onChangeQuestion} 
                    /> */}
                     <SunEditor
       
       showToolbar={true}
       setDefaultStyle="height: 150px"
       setOptions={{
           "katex": "window.katex",
           "imageUploadSizeLimit": "5000000",
         buttonList: [
           [
            "undo",
            "redo",
            //"font",
            "fontSize",
            "formatBlock",
            "paragraphStyle",
            "blockquote",
            "bold",
            "underline",
            "italic",
            "strike",
            "hiliteColor",
            "subscript",
            "superscript",
            "math",
            "removeFormat",
            "horizontalRule",
            // "lineHeight",
            //"strike",
            "list",
            "outdent",
            "indent",
            "align",
            "table",
            "image",
            "codeView",
           // "preview",
           // "print",
            //"save",
            //"template",
             ],
         ]
       }}
     />
                <FormFeedback> </FormFeedback>
                </Form.Item>
              
                <Row>
                    <Col xs='12'>
                        <Form.Item name="qtype" label="Question Type" rules={[{ required: true, message: 'Missing Question Type' }]} >
                            <Select
                                mode=""
                                placeholder="Click here to Question type"
                                value={selectedItems}
                                onChange={setSelectedItems}
                                style={{
                                    width: '100%',
                                }}
                            >
                                {filteredOptions8.map((item) => (
                                    <Select.Option key={item} value={item}>
                                        {item}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col xs='12'>
                        <Form.Item name="answer_type" label="Answer Type" rules={[{ required: true, message: 'Missing Answer Type' }]} >
                            <Select
                                mode=""
                                placeholder="Click here to answer type"
                                value={selectedItems}
                                onChange={setSelectedItems}
                                style={{
                                    width: '100%',
                                }}
                            >
                                {filteredOptions7.map((item) => (
                                    <Select.Option key={item} value={item}>
                                        {item}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs='12'>
                        <Form.Item name="answer" label="Correct Answer (only for string answers)">
                            <Input placeholder="Add the answer" disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs='6'>
                        <Form.Item name="answer1" label="Correct Answer-Start from" rules={[{ required: true, message: 'Missing Correct Answer-Start from' }]} >
                            <Input placeholder="Answer range start from" />
                        </Form.Item>
                    </Col>
                    <Col xs='6'>
                        <Form.Item name="answer2" label="Correct Answer-End from" rules={[{ required: true, message: 'Missing Correct Answer-End from' }]}>
                            <Input placeholder="Answer range end from" />
                        </Form.Item>
                    </Col>
                </Row>


                <Row>
                    <Col xs='6'>
                        <button class="btn btn-success btn-sm btn-block my-2" type="primary" htmlType="submit" block  >
                            Add to list
                        </button>
                    </Col>
                    <Col xs='6'>
                        <button class="btn btn-success btn-sm btn-block my-2" type="danger"  block  onClick={onReset} >
                            Reset 
                        </button>
                    </Col>
                </Row>
            </Form>

            <Row>
                <Col xs='12'>
                    <Table dataSource={qData} columns={dataSet1} pagination={false} bordered className="thead-dark" />
                </Col>
            </Row>
            <Row>
                  
                        <button class="btn btn-success btn-sm btn-block my-2" type="primary"  block  onClick={onSubmitQuestions} >
                            Save Questions
                        </button>
                 
                   
                </Row>
        </>
    )
}

export default AddExpQuestions