import axios from "axios";
import Config from "./Config";

class StudentServiceNew {
  
  getCreateClassInitData() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/class/getCreationData', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
        const error = err.response?.data
        return error;
      })
  }

  getAccountsForUser() {
  

    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/account/getAccountsByUserId', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {console.log("error", err.response?.data ? err.response?.data : err)
      const error = err.response?.data
      return error;
        // if (err.response?.data == 403){
        //   window.location.href = "/login"; 
        // }
        // return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getSchoolsForAccount(accountId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/school/schoolsByAccount/' + accountId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {console.log("error", err.response?.data ? err.response?.data : err)
      const error = err.response?.data
      return error;
        // return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getClassesForSchool(schoolId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/class/getClassByOrganizationId/' + schoolId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {console.log("error", err.response?.data ? err.response?.data : err)
      const error = err.response?.data
      return error;
        // return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getStudentById(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/student/searchStudentById/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {console.log("error", err.response?.data ? err.response?.data : err)
      const error = err.response?.data
      return error;
        // return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getClassStudents(classId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/class/getAssignStudents/' + classId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {console.log("error", err.response?.data ? err.response?.data : err)
      const error = err.response?.data
      return error;
        // return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  createNewStudent(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/student/addNewStudent', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
        const error = err.response?.data ? err.response?.data : err
        return error;
      })
  }

  updateStudent(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/student/updateStudent', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {console.log("error", err.response?.data ? err.response?.data : err)
      
       return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  assignStudentsToClass(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/class/studentsAssignClasses', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {console.log("error", err.response?.data ? err.response?.data : err)
      const error = err.response?.data
      return error;
        // return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
}

export default new StudentServiceNew();