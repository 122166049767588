import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts


// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  Row,
  Col,
  CardImgOverlay,
} from "reactstrap";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import Class from "./class"


function ClassIndex(props) {
  const { TabPane } = Tabs;

  useEffect(() => {
    localStorage.removeItem("selectedLessonID");
    localStorage.removeItem("selectedClassID");
  }, [])
  
  return (
    <>
    <div className="container">
      <div className="content">
        <Row>
          <Col className="text-center">
            <div className="mb-5">
              <h3 className="text-dark">Choose your class</h3>
            </div>
            <hr />
            <br/>
          </Col>
        </Row>
       <Class/>
      </div>
      </div>
    </>
  );
}

export default ClassIndex;
