export default function validate(obj) {
    let errors = {};  
    let values = {...obj};
    let moreBlank =/^(\w+\s)*\w+$/g ;
    let specialChar = /^((?![-_!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+).)*$/gm;
  
    Object.keys(values).map(k => values[k] = trimObjectStrings(k));
  
  
  
    if (!values.name || values.name == '') {
      errors.name = 'Category Name is required';
    }else if (values.name.length > 50) {
      errors.name = 'Category Name must be less than 50 characters';
    }
    // else if (!values.name.match(specialChar)) {
    //   errors.name = 'Category Name can not have special characters';
    // }
    
    function trimObjectStrings(key){
      if(typeof values[key] == 'string' && !key.toLowerCase().includes("password")){
        return values[key].trim();
      }
      return values[key];
    }
  
    return errors;
  };