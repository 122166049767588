import React, { useEffect, useState } from 'react';
import { Steps, Popover } from "antd";
import "antd/dist/antd.css";
import BasicInfo from './BasicInfo';
import QuestionList from './QuestionList';
import EngageList from './EngageList';
import Finish from './Finish';
import { Button } from 'reactstrap';
import { EngageContext } from './EngageContext';

const { Step } = Steps;
const customDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        step {index + 1} | status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);

function Engage(props) {

  const [disableContinueButton, setDisableContinueButton] = useState(true)
  const [current, setCurrent] = useState(0);
  const [currentClassLevel, setCurrentClassLevel] = useState(1);
  const [engage_id, setEngage_id] = useState(0);
  const [engage, setEngage] = useState(false)
  const [type, setType] = useState(1);
  const [filterdValues, setFilterdValues] = useState({})
  const [added_class_level, setAddedClassLevel] = useState(0);
  const [previouseClassLevel, setPreviouseClassLevel] = useState(null);
  const [disableState, setDisableState] = useState({
    1:true,
    2:true,
    3:true,
    4:true
  })
  const setPreviouseClassLevelFC = (value) => {
    setPreviouseClassLevel(value)
  }
  const onChangex = () => {
    setCurrent(current - 1);
  };

  const onChangeY = () => {
    setCurrent(current + 1);
  };

  const changeTab = (data, filterdValues = []) => {
    setCurrent(current + 1)
    setFilterdValues(filterdValues);
    setCurrentClassLevel(data[0])
  }

  const handleButtonClick = () => {
    setCurrent(current + 1)
  }

  useEffect(() => {
    if (added_class_level > 0) {
      setCurrentClassLevel(added_class_level);
    }
  }, [added_class_level]);
  

  const steps = [
    {
      title: 'Engage List',
      content: <EngageList setPreviouseClassLevelFC={(data) => setPreviouseClassLevelFC(data)} changeTab={changeTab} loading={props.loading} setLoading={props.setLoading} setType={(data) => setType
        (data)} />,
      disabled: disableState[1],

    },
    {
      title: 'Basic Setup',
      content: <BasicInfo setPreviouseClassLevelFC={(data) => setPreviouseClassLevelFC(data)} previouseClassLevel={previouseClassLevel} setAddedClassLevel={setAddedClassLevel} type={type} filterdValues={filterdValues} currentClassLevel={currentClassLevel} createEngageStep={(data) => setEngage_id(data)} GoToQuestionTab={() => setCurrent(current + 1)} back={onChangex} />,
      disabled: disableState[2],
    },
    {
      title: 'Question List',
      content: <QuestionList
        type={type}
        setDisableContinueButton={setDisableContinueButton}
        currentClassLevel={currentClassLevel}
        engage_id={engage_id}
        handleButtonClick={handleButtonClick}
        back={
          <button className='btn btn-info'
            style={{ padding: "14px 31px" }}
            onClick={() => {
              onChangex()
            }
            }>
            Back
          </button>
        }
        continue={
          <button
            className='btn btn-info'
            style={{ padding: "14px 31px" }}
            disabled={disableContinueButton}
            onClick={() => {
              onChangeY()
            }}
          >
            Continue
          </button>
        }
      />,
      disabled: disableState[3],

    },
    {
      title: 'Finish',
      content: <Finish engage_id={engage_id} onChangex={onChangex} />,
      disabled: disableState[4],
        },
  ];

  useEffect(()=>{
    switch (current){
      case 0 :
          setDisableState({
            1:true,
            2:true,
            3:true,
            4:true
          });
        break;
      case 1 : 
          setDisableState({
            1:false,
            2:true,
            3:true,
            4:true
          });
        break;
      case 2 : 
          setDisableState({
            1:false,
            2:false,
            3:true,
            4:true
          });
        break;
      case 3 : 
          setDisableState({
            1:false,
            2:false,
            3:false,
            4:true
          });
        break;

    }
  },[current])

  return (
    <EngageContext.Provider value={{
      value: engage,
      setValue: setEngage
    }}>
      <div className='container my-3'>
        <Steps onChange={e=>{
                 setCurrent(e)
              }}
              current={current} progressDot={customDot}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} disabled={item.disabled} />
          ))}
        </Steps>
        <div className=" mt-3 ">{steps[current].content}</div>

      </div>
      <div className='step-button'>
        <Button className='btn btn-primary btn-lg mr10'>Previous</Button>
        <Button className='btn btn-primary btn-lg'>Next</Button>
      </div>
    </EngageContext.Provider>
  );
}

export default Engage;
