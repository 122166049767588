import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);





export function NormalLineChart(props) {
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' ,
          },
          title: {
            display: true,
            text: props.name,
          },
        },
      };
    const labels = Array.from(Array(props?.length),(x,index) => index*670);

const data = {
  labels,
  datasets: props.data.datasets.filter((ele, ind) => ind === props.data.datasets.findIndex( elem => elem.label === ele.label  )).map((item, i) =>{
    return {
        label:item.label  === "undefined" || item.label === null ? "" : item.label,
        backgroundColor: item.label  === "undefined" || item.label === null ? "transparent" :item.color,
        data:item.label  === "undefined" ? []: item.yData
    }
}),
};
  return <Line options={options} data={data} />;
}
