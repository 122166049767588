// -- React and related libs
import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router";
import { Button, notification } from 'antd';

// -- Third Party Libs
import PropTypes from "prop-types";

import { Modal, Spin } from 'antd';



// -- Custom Components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
// import LeftSidebar from "../Sidebar/LeftSidebar";
import Footer from "../Footer/Footer";
import Dashboard from "../../pages/dashboard/Dashboard";
import LessonLab from "../../pages/LessonLab/index";
import ClassIndex from "../../pages/Class/index";
import LessonIndex from "../../pages/Lesson/index";
import AdminClass from "../../pages/Admin/AssginClass";
import AdminLesson from "../../pages/Admin/AssginLesson/index";
import MyAccount from "../../pages/Admin/MyAccount";
import AssignLesson from "../../pages/Admin/AssginLesson";
import Teacher from "../../pages/Teacher/Home";
import Home from "../../pages/Teacher/Home";
import ClassName from "../../pages/Teacher/Home/Grading/ClassName";
import ChosenStudentName from "../../pages/Teacher/Home/Grading/ClassName/ChosenStudentName";
import ViewLesson from "../../pages/Teacher/Home/Assign/ViewLesson";
import ClassDetails from "../../pages/Teacher/ClassDetails/index";

import EvaluateTeacher from "../../pages/Teacher/Evaluation/EvaluateTeacher";
import EvaluateStudent from "../../pages/Teacher/Evaluation/EvaluateStudent";

import PlanLesson from "../../pages/Teacher/SelectLesson";
import PlanClass from "../../pages/Teacher/SelectClass";
import Test from "../../pages/test/Test";

//import TeacherAssign from "../../pages/Teacher/TeacherAssign";

// import Experiment from "../../pages/Teacher/Experiment";
// import Top from "../../pages/Teacher/Experiment/TeacherExplore/Top";

import StudentPage from "../../pages/Teacher/Home/Grading/ClassName/StudentPage";

import AuthorizeRoute from "../../auth/authorizeRoute";
import CreateSchool from "../../pages/Admin/MyAccount/Schools/Create"
import jsonToken from 'jsonwebtoken'
import ImportTeacher from "../../pages/Admin/MyAccount/Teachers/Import";
import ImportStudent from "../../pages/Admin/MyAccount/Students/Import";
// -- Component Styles
import s from "./Layout.module.scss";
import Lesson from "../../pages/Lesson";
import { FormFeedback, FormGroup, Input } from "reactstrap";
import AccountAdminService from "../../api/AccountAdminService";
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Organization from "../../pages/Teacher/Home/Organization";
import Instructor from "../../pages/Teacher/Home/Instructor";
import { LoginCheck } from "../LoginCheck/LoginCheck";


const Layout = (props) => {
  const url = (window.location.href).split("#");
  const [buttonEn, setButtonEn] = useState(false)
  const [count, setCount] = useState(0)
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [invalidpassword, setInvalidpassword] = useState(false)
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (window.location.href === `${url[0]}#/ClassIndex`) {
      setButtonEn(true)
    } else {
      setButtonEn(false)
    }
    document.body.classList.add('admin-nav');
  }, [url]);

  useEffect(() => {
    //Disabled Default password change for now
    // let token = localStorage.getItem("access_token");
    // let accessToken = jsonToken.decode(token);
    // if (accessToken.status === 2) {
    //   showModal();
    //   return true;
    // }
  }, []);

  const navigateFunction = () => {
    localStorage.removeItem("className");
    window.localStorage.removeItem("lessonID");
    localStorage.setItem('targetLanguage', "en");
    window.open(`${url[0]}#/home`, "_self");
  }
  const navigateFunction2 = () => {
    localStorage.removeItem("curriculumName");
    localStorage.removeItem("curriculumLevel");
    window.open(`${url[0]}#/Teacher/PlanClass`, "_self");
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const changeHandler = (e) => {
    if (e.target.name === "setPassword") {
      setPassword(e.target.value);
    } else if (e.target.name === "setPasswordConfirm") {
      setPasswordConfirm(e.target.value);
    }
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (e) => {
    if (password !== passwordConfirm) {
      setInvalidpassword(true);
      setError("Password & Confirm Password do not match")
    } else if (password === "") {
      setInvalidpassword(true);
      setError("Password cannot be empty")
    } else if (password.length < 4 || password.length > 15) {
      setInvalidpassword(true);
      setError("Password must be at 4-15 characters in length")
    } else {
      setInvalidpassword(false);
      setError("")
      let payload = {
        password: password
      }
      setIsModalOpen(false);
      setLoading(true);
      AccountAdminService.updateDefaultStudentPassword(payload).then((response) => {
        if (response.status == 200) {
          notification.open({
            placement: 'top',
            duration: "2",
            description: [
              <li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{response.data.message}</span></li>
            ],
          });
          setTimeout(function () { logout(); }, 2000);
        }
      }).catch((err) => {
        notification.open({
          placement: 'top',
          duration: "2",
          description: <li><InfoCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{err.message}</span></li>,
        });
        setIsModalOpen(true);
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  function logout() {
    localStorage.removeItem('access_token');
    sessionStorage.removeItem('loggedin');
    localStorage.removeItem('labelLegends');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('targetLanguage');
    localStorage.removeItem('notificationCount');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('className');
    localStorage.removeItem('lessonID');
    localStorage.removeItem("curriculumName");
    localStorage.removeItem("curriculumLevel");
    localStorage.removeItem('userRoutes');

    sessionStorage.clear();
    history.push(`/login`)
    window.location.reload();
  }
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  if(accessToken===null){
    LoginCheck();
  }
  return (
    <Spin tip="Loading..." spinning={loading} size="large">
      <div className={s.root}>
        <Sidebar notificationCount={(data) => {
          setCount(data)
        }} />

        <div className={s.wrap}>
          <Header role={accessToken.roles[0]} count={count} />
          {accessToken.roles[0] === "STUDENT" &&
            <div className="home-icon" hidden={buttonEn}><i class="fa fa-home " onClick={navigateFunction} aria-hidden="true"></i></div>
          }
          {/* change the role for the demo - tandin requested  */}
          {/* {accessToken.roles[0] === "SUPER_ADMIN" && 
            <>
                <div className="home-icon" hidden={buttonEn}><i class="fa fa-home " onClick={navigateFunction2} aria-hidden="true"></i></div>
            </>
          } 
          { accessToken.roles[0] === "TEACHER" && 
            <>
                <div className="home-icon" hidden={buttonEn}><i class="fa fa-home " onClick={navigateFunction2} aria-hidden="true"></i></div>
            </>
          }  */}
          <main className={s.content}>
            <div className="">
              {/* <LeftSidebar/> */}

              <Switch>


                <AuthorizeRoute roles="*" path='/dashboard'>
                  <Dashboard />
                </AuthorizeRoute>
                <AuthorizeRoute roles="STUDENT,TEACHER" path='/LessonLab'>
                  <LessonLab />
                </AuthorizeRoute>
                <AuthorizeRoute roles="STUDENT,TEACHER" path='/Lesson/:id'>
                  <Lesson />
                </AuthorizeRoute>
                <AuthorizeRoute roles="STUDENT,TEACHER" path='/ClassIndex'>
                  <ClassIndex />
                </AuthorizeRoute>
                <AuthorizeRoute roles="STUDENT,TEACHER" path='/LessonIndex'>
                  <LessonIndex />
                </AuthorizeRoute>
                <AuthorizeRoute roles="SUPER_ADMIN,SCHOOL_ADMIN,ACCOUNT_ADMIN" path='/AdminClass'>
                  <AdminClass />
                </AuthorizeRoute>
                <AuthorizeRoute roles="SUPER_ADMIN,SCHOOL_ADMIN,ACCOUNT_ADMIN" path='/AdminLesson'>
                  <AdminLesson />
                </AuthorizeRoute>
                <AuthorizeRoute roles="SUPER_ADMIN,SCHOOL_ADMIN,ACCOUNT_ADMIN,TEACHER" path='/MyAccount'>
                  <MyAccount />
                </AuthorizeRoute>
                <AuthorizeRoute roles="SUPER_ADMIN,SCHOOL_ADMIN,ACCOUNT_ADMIN,TEACHER" path='/AssignLesson'>
                  <AssignLesson />
                </AuthorizeRoute>
                <AuthorizeRoute roles="SUPER_ADMIN,SCHOOL_ADMIN,ACCOUNT_ADMIN,TEACHER" path='/Teacher/ClassDetails/:lessonId'>
                  <ClassDetails />
                </AuthorizeRoute>
                <AuthorizeRoute roles="STUDENT,TEACHER" exact path='/Teacher'>
                  <Home />
                </AuthorizeRoute>
                <AuthorizeRoute roles="TEACHER,SUPER_ADMIN" exact path='/Instructor/:instructorId/Classes/:id'>
                  <Home />
                </AuthorizeRoute>
                <AuthorizeRoute roles="TEACHER,SUPER_ADMIN" exact path='/Instructor/:instructorId/Classes/:classId/Lesson/:className/:id/:level'>
                  <ClassName />
                </AuthorizeRoute>
                <AuthorizeRoute roles="TEACHER,SUPER_ADMIN" exact path='/Instructor/:instructorId/Classes/:classId/Student/:className/:id/:lessId/:level'>
                  <ChosenStudentName />
                </AuthorizeRoute>
                {/* <AuthorizeRoute roles="TEACHER" exact path='/Teacher/Home/ViewLesson'>
                <ViewLesson />
              </AuthorizeRoute> */}
                <AuthorizeRoute roles="SUPER_ADMIN,TEACHER" exact path='/Teacher/Plan/:id/:lessonId'>
                  <ClassDetails />
                </AuthorizeRoute>
                <AuthorizeRoute roles="SUPER_ADMIN,TEACHER" exact path='/Teacher/PlanLesson/:id'>
                  <PlanLesson />
                </AuthorizeRoute>
                <AuthorizeRoute roles="SUPER_ADMIN,TEACHER" exact path='/Teacher/PlanClass'>
                  <PlanClass />
                </AuthorizeRoute>

                <Route path="/Teacher/Home/ViewLesson" exact component={ViewLesson} />

                <Route path="/Instructor/Student-Answer/:className/:id/:studentLevel/:lessonID" exact component={StudentPage} />
                <Route path="/MyAccount" exact component={MyAccount} />
                <Route path="/MyAccount/Schools/Create" exact component={CreateSchool} />
                <AuthorizeRoute roles="SUPER_ADMIN,TEACHER" exact path='/evaluate-teacher'>
                  <EvaluateTeacher />
                </AuthorizeRoute>
                <AuthorizeRoute roles="SUPER_ADMIN,TEACHER" exact path='/evaluate-student/:id'>
                  <EvaluateStudent />
                </AuthorizeRoute>
                <AuthorizeRoute roles="SUPER_ADMIN" exact path='/ImportTeacher'>
                  <ImportTeacher />
                </AuthorizeRoute>
                <AuthorizeRoute roles="SUPER_ADMIN" exact path='/ImportStudent'>
                  <ImportStudent />
                </AuthorizeRoute>
                <AuthorizeRoute roles="SUPER_ADMIN,TEACHER" exact path='/test'>
                  <Test />
                </AuthorizeRoute>
                <AuthorizeRoute roles="SUPER_ADMIN,TEACHER" exact path='/Organization'>
                  <Organization />
                </AuthorizeRoute>
                <AuthorizeRoute roles="SUPER_ADMIN,TEACHER" exact path='/Instructor/:id'>
                  <Instructor />
                </AuthorizeRoute>
                
              </Switch>
            </div>

            <Modal title="Initial Login | Change Password"
              className="change-pw-button"
              open={isModalOpen}
              onOk={handleOk}
              closable={false}
              footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                  Change Password
                </Button>
              ]}
              okText="Change Password"
              visible={isModalOpen}>
              <div className="form-outline mb-3">
                <label className="form-label" htmlFor="form3Example4">
                  <span className="required-field">*</span>
                  New Password
                </label>
                <FormGroup>
                  <Input
                    type="password"
                    id="form3Example4"
                    name="setPassword"
                    className="form-control form-control-lg"
                    autoComplete="off"
                    placeholder=""
                    invalid={invalidpassword}
                    onChange={changeHandler}
                  />
                </FormGroup>
              </div>

              <div className="form-outline mb-3">
                <label className="form-label" htmlFor="form3Example4">
                  <span className="required-field">*</span>
                  Confirm Password
                </label>
                <FormGroup>
                  <Input
                    type="password"
                    id="form3Example4"
                    name="setPasswordConfirm"
                    className="form-control form-control-lg"
                    autoComplete="off"
                    placeholder=""
                    invalid={invalidpassword}
                    onChange={changeHandler}
                  />
                  <FormFeedback>{error}</FormFeedback>
                </FormGroup>
              </div>

            </Modal>
          </main>
          {/* <Footer /> */}
        </div>
      </div>
    </Spin>
  );
}





export default Layout;
