import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from "react-router-dom";
// reactstrap components
import { Card, CardText, CardImg, Row, Col, CardBody } from "reactstrap";
import { Button } from "react-bootstrap";
import { Tabs, Input, Table, Space } from "antd";
import "antd/dist/antd.css";
import Create from "./Create";
import View from "./view";

function ExperimentType(props) {
  const [flag, setFlag] = useState(false);
  const [editData,setEditData] = useState();
  const { TabPane } = Tabs;

  const onChange = (key) => {
    console.log(key);
  };
  const onEdit = (data,record)=>{
    setEditData(record)
    setFlag(!flag)
  }
  useEffect(() =>{
    setFlag(false)
  },[props])
  return (
    <>
      <div className="container-fluid admin-tabs">
        <Row>
          <Col md="12">
            <Card className="border-0">
              <CardBody className="e">
                <Row>
                  <Col md="6">
                    <h5>Experiment Type</h5>
                  </Col>
                  <Col md="6">
                    <div className="pull-right">
                      <Button
                        type="primary"
                        className="mr10 mb20"
                        onClick={() => {
                          setFlag(!flag)
                          if(flag){
                            setEditData(undefined)
                          }
                        }}
                        size="sm"
                      >
                        {flag ? "View" : "New"}
                      </Button>
                      <Button type="primary" className="mb20 hidden" size="sm">
                        Import
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {flag ? <Create a={flag} setFlag={setFlag} editData={editData}/> : <View onEdit={onEdit} h={flag} />}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ExperimentType;
