import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  CardBody,
  CardTitle,
  Row,
  Col,
  Progress,
  CardImgOverlay,
} from "reactstrap";
import { Spin, Tabs } from "antd";
import "antd/dist/antd.css";
import { Link, useParams } from 'react-router-dom';
import RestService from "../../api/RestService";


function Lesson(props) {
  const [data, setData] = useState({data:[]});
  const [header, setHeader] = useState({
    h1:"Introductory ",
    h2:"SCORE",
  });
  const [loadingLessonPage, setLoadingLessonPage] = useState(false);

  let { id } = useParams();
  useEffect( ()=>{
    setLoadingLessonPage(true)
    RestService.getLessonsByClass(id)
   .then((response) => {
      setData(response)
      setLoadingLessonPage(false)
     })
   }, []);
   
   const display = data.data.map((x)=>{
      if(data.status === 200){
        return(
          <Col md="3">
          <Link 
            to={`/LessonLab/${x.engageStepId}/${x.evaluateStepId}/${x.explainStepId}/${x.exploreStepId}/${x.extendStepId}`}
            onClick={() => localStorage.setItem("selectedLessonID", x.lessonId)}
          >
            <div class="lesson-image score-hover">
                 <img src={x.image} alt="Avatar" height="1000px" class="imageLessonStyle" />
              <div class="overlay">
                <div className="center score">
                <span className="text text-success f14">{header.h2}</span>
              <span className="text text-success f14"> {x.lessonScore}</span>
              </div>
                <p className="g-card-top">
                  {header.h1}
                 
                </p>
                <p className="g-card-title ">
                  {x.heading}
                 
                </p>
              </div>
            </div>
          </Link>
          </Col>
        )
      }
    },[data]); 
  return (
    <>
    <div>
      <Spin tip="Loading..." spinning={loadingLessonPage} size="large">
    <div className="container">
      <div className="content">
        
        <Row>
          {display}
        </Row>
      </div>
      </div>
      </Spin>
      </div>
    </>
  );
}

export default Lesson;