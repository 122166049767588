import React, { useState, useEffect, useContext } from 'react';
import {
    CardBody,
    Row,
    Col,
    Badge
} from "reactstrap";
import { Button, Space, Table, Spin } from "antd";
import "antd/dist/antd.css";
import LessonService from '../../../../api/LessonService';
import { CommonContext } from '../components/CommonContext';
import { DisableContext } from '../components/DisableContext';

function ExplainList(props) {
    const [options2, setOption2] = useState([{
        value: 'Beginner',
        id: 1,

    },
    {
        value: 'Basic',
        id: 2,

    },
    {
        value: 'Middle',
        id: 3,

    },
    {
        value: 'Advanced',
        id: 4,

    },
    {
        value: 'Honors',
        id: 5,

    },
    ])

    const clickEdit = (data) => {
        var selected_option = options2.filter(x => { return x.id == data })[0];
        var temp = filterOptions;
        temp.push(selected_option);
        temp.sort(function (a, b) {
            return a.id - b.id;
        });
        var edit_options = temp;
        props.changeTab([data], edit_options);
    }

    const dataSet2 = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: ' Level',
            dataIndex: 'leval',
            key: 'leval',
            render: (_, record) => (
                <Space size="middle">
                    {record.action}
                    <Badge className='bg-success' size="sm">
                        <small>{record.leval}</small>
                    </Badge >
                </Space>
            ),
        },
        {
            title: ' Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {record.action}
                    <Badge className='bg-warning' size="sm" onClick={() => {
                        clickEdit(record.id)
                        props.setType(2)
                    }} >
                        <small>View</small>
                    </Badge >
                    <Badge className='bg-primary' size="sm" onClick={() => {
                        clickEdit(record.id)
                        props.setPreviouseClassLevelFC(record.id)
                        props.setType(1)
                    }}>
                        <small>Edit</small>
                    </Badge >
                </Space>
            ),
        },
    ];
    const [selectedItems, setSelectedItems] = useState("");
    const [dataSet, setDataset] = useState([])
    const [initData, setInitData] = useState([])
    const [filterOptions, setFilterOptions] = useState(options2)
    const { disablevalue, setDisableValue } = useContext(DisableContext)
    const { value, setValue } = useContext(CommonContext);
    const [disabled, setDisabled] = useState(false);
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (selectedItems != -1) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [selectedItems])

    useEffect(() => {
        const data = []
        dataSet?.map((xi) => {
            const res = options2?.filter(x => x.value === xi.leval[0])
            data.push(res[0]?.value)
        })
        const filterlist = options2?.filter(x => !data.includes(x.value))
        setFilterOptions(filterlist)
    }, [dataSet])

    useEffect(() => {
        if (value != undefined) {
            setLoader(true)
            LessonService.getExplainAllSelectedClassLevel(value.lessonId).then(
                res => {
                    setInitData(res.data)
                    setLoader(false)
                }
            )
        }
    }, [value])

    useEffect(() => {
        setDataset([])
        initData?.map(item => {
            setDataset(prev => (
                [...prev, {
                    key: item.classLevel,
                    id: item.classLevel,
                    leval: options2.filter(i => i.id === item.classLevel).map(i => { return i.value }),
                    action: ''
                }]
            ))
        })
    }, [initData])

    useEffect(() => {
        if (dataSet?.length > 0) {
            setDisableValue({
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
            })
        }
        else {
            setDisableValue({
                1: false,
                2: false,
                3: false,
                4: true,
                5: false,
            })
        }
    }, [dataSet])

    const onClick = () => {
        props.setPreviouseClassLevelFC(null)
        if (dataSet.findIndex(o => o.id === selectedItems) > 0) {
            props.changeTab(dataSet.filter(i => i.id === selectedItems).map(i => { return i.id }), filterOptions);
        }
        else {
            setDataset(prev => (
                [...prev, {
                    key: selectedItems,
                    id: selectedItems,
                    leval: options2.filter(i => i.id === selectedItems).map(i => { return i.value }),
                    action: ''
                }]
            ))
            props.changeTab(options2.filter(i => i.id === selectedItems).map(i => { return i.id }), filterOptions);
        }
        props.setType(1)
    }
    return (
        <Spin tip="Loading..." spinning={false} size="large">
            <div className="container" >
                <Row className='mt-2'>
                    <Col xs='12' >
                        <div className='center-btn'>
                            <button disabled={filterOptions.length == 0 ? true : loader ? true : false} class="btn btn-info" style={{ padding: "8px 32px" }} onClick={onClick} >
                                Create New Explain
                            </button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <CardBody>
                            <Row>
                                <Col xs='12' className="mb-5">
                                    <Table loading={{indicator: <Spin size='large' className='spinner-new' tip='Loading...' />, spinning: loader}} dataSource={dataSet?.filter(x => x.leval !== undefined)} columns={dataSet2} pagination={false} bordered className="thead-dark space-py-20" />
                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                </Row>

            </div>
        </Spin>
    );
}

export default ExplainList;
