import React from 'react'

import { CardBody, Badge } from "reactstrap";
import { Spin, Table, Col, Row, Space, Button, Select, Drawer, Modal } from "antd";
import "antd/dist/antd.css";

import { useEffect, useState } from "react";
import jsonToken from 'jsonwebtoken';
import ChildDrawer from './components/ChildDrawer';

import { useParams } from 'react-router';
import RestService from '../../../api/RestService';
import { Option } from 'antd/lib/mentions';



function EvaluateStudent() {

  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [lesson, setLesson] = useState([])
  const [classes, setClasses] = useState([])
  const [Teachers, setTeachers] = useState([])
  const [Teacher_Id, setTeacher_Id] = useState()
  const [Class_Id, setClass_Id] = useState(0)
  const [dataSourc, setDataSourc] = useState([]);
  const [lesson_Id, setLessonId] = useState(0);
  const [ReviewStudentName, setReviewStudentName] = useState("");
  const [expName, setExpName] = useState("");
  const [dt, setdt] = useState(0)
  const [stepofEach, setStepofeach] = useState();
  const [explorestepId, setExploreStepId] = useState()
  const [loading, setLoading] = useState(false);
  const [stepName, setStepName] = useState("")
  const [data, setData] = useState();
  const [recId, setRecId] = useState(0);
  const [marks, setMarks] = useState(0)
  const [lastData, setLastData] = useState([]);
  const [usernamest,setUsernamest] = useState("")

  let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;

  }

  useEffect(() => {
    console.log("Data", dataSourc)
  }, [dataSourc])

  const showDrawer = (record, data, marks) => {
    setOpen(true);
    setRecId(record.id)
    console.log("True");
    if (marks !== null) {
      setMarks(marks)
    }
    else {
      setMarks(0)
    }
    setReviewStudentName(record.student)
    setUsernamest(record.studentrusername)
    setExpName(record.lesson)
    if (data === 1) {
      setStepofeach(record?.engage_step_id)
      setStepName("engageStep")
    }
    else if (data === 2) {
      setStepofeach(record?.evaluate_step_id)
      console.log("test", record?.evaluate_step_id)
      setStepName("evaluateStep")
    }
    else if (data === 3) {
      setStepofeach(record?.explain_step_id)
      setStepName("explainStep")
    }
    else if (data === 4) {
      setStepofeach(record?.extend_step_id)
      setStepName("extendStep")
    }
    setdt(data)
    setExploreStepId(record?.explore_step_id)

  };

  const onClose = () => {
    loadData();
    setOpen(false);
  };

  console.log("dte", lastData)
  const dataSource = [
    {
      key: "1",
      instructor: "John Doe",
      class: "Class 01",
      student: "Student 01",
      lesson: "Lesson 01",
      avg_intro: "30",
      avg_quiz: "50",
      avg_rpt: "",
      avg_ext: ""
    },
  ];

  const columns = [

    {
      title: "Instructor",
      dataIndex: "instructor",
      key: "instructor",
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
    },
    {
      title: "Student",
      dataIndex: "student",
      key: "student",
    },
    {
      title: "Lesson",
      dataIndex: "lesson",
      key: "lesson",
    },
    {
      title: "Engage",
      render: (text, record) => {
        return <button
          disabled={record?.engage_step_id === null}
          className="btn btn-sm btn-engage btn-fixed-1"
          onClick={() => { showDrawer(record, 1, record.engage_step_marks) }}>
          {record.engage_step_marks !== null && record.engage_step_marks !== undefined ? record.engage_step_marks : "Review"}</button>
      },
      className: "avg_intro",
    },
    {
      title: "Evaluate",
      render: (text, record) =>
        <button disabled={record?.evaluate_step_id === null}
          className="btn btn-sm btn-evaluate btn-fixed-1"
          onClick={() => { showDrawer(record, 2, record.evaluate_step_marks) }}>
          {record.evaluate_step_marks !== null && record.evaluate_step_marks !== undefined ? record.evaluate_step_marks : "Review"}
        </button>,
      className: "avg_quiz",
    },
    {
      title: "Explain",
      render: (text, record) =>
        <button disabled={record?.explain_step_id === null}
          className="btn btn-sm btn-explain btn-fixed-1"
          onClick={() => { showDrawer(record, 3, record.explain_step_marks) }}>
          {record.explain_step_marks !== null && record.explain_step_marks !== undefined ? record.explain_step_marks : "Review"}
        </button>,
      className: "rpt"
    },
    {
      title: "Extend",
      render: (text, record) =>
        <button disabled={record?.extend_step_id === null}
          className="btn btn-sm btn-extend btn-fixed-1"
          onClick={() => { showDrawer(record, 4, record.extend_step_marks) }}>
          {record.extend_step_marks !== null && record.extend_step_marks !== undefined ? record.extend_step_marks : "Review"}
        </button >,
      className: "ext"
    },
  ];


  useEffect(() => {
    console.log("Loading", id)
    if (id <= 0) {
      return;
    }

    setLoading(true);
    RestService.getTeachers(id).then(res => {
      setTeachers(res.data?.map(x => {
        return {
          id: x.id,
          name: x.username
        }
      }))
    }).finally(() => {
      setLoading(false);
    });
  }, [])


  useEffect(() => {
    loadData();
  }, [Teachers, Teacher_Id, Class_Id, lesson_Id])

  useEffect(() => {
    if (Class_Id !== 0) {
      RestService.getLessonsByClassId(Class_Id).then(res => {
        console.log("Res", res);
        let data = res.data;
        if (data && data.length > 1) {
          data.sort((a, b) => a.heading.localeCompare(b.heading))
        }
        setLesson(data?.map(lesson => {
          return {
            id: lesson.lessonId,
            name: lesson.heading
          }
        }))
      })
    }
  }, [Class_Id])

  const onTeacherChange = (e) => {
    console.log(e)
    setTeacher_Id(e)
  }

  const onClassChange = (e) => {
    console.log(e)
    setClass_Id(e)
  }

  const onLessonChange = (e) => {
    console.log(e)
    setLessonId(e)
  }

  function  loadData() {
    if (Teachers.length > 0 || Teacher_Id !== 0) {
      setLoading(true);
      RestService.getClassesByTeacherId(Teachers?.find(x => x.name === user)?.id ? Teachers?.find(x => x.name === user)?.id : Teacher_Id).then(res => {
        console.log("Success", res)

        let data = res.data;
        if (data && data.length > 1) {
          data.sort((a, b) => a.name.localeCompare(b.name))
        }

        setClasses(data?.map(a => {
          return {
            id: a.id,
            name: a.name
          }
        }))
      })

      RestService.getEvaluatedataforStudents(Teachers?.find(x => x.name === user)?.id ? Teachers?.find(x => x.name === user)?.id : Teacher_Id, Class_Id, lesson_Id)
        .then(res => {
          console.log("Res", res)
          setDataSourc(res?.data?.map((x, i) => {
            return {
              id: i,
              instructor: x.instructorName,
              class: x.classes,
              student: x.studentNameFirstName + ' '+ x.studentNameLastName,
              studentrusername:x.studentUserName,
              lesson: x.lesson,
              engage_step_id: x.engage_step_id,
              engage_step_marks: x.engage_step_marks,
              evaluate_step_id: x.evaluate_step_id,
              evaluate_step_marks: x.evaluate_step_marks,
              explain_step_id: x.explain_step_id,
              explain_step_marks: x.explain_step_marks,
              extend_step_id: x.extend_step_id,
              extend_step_marks: x.extend_step_marks,
              explore_step_id: x.explore_step_id,
            }
          }))
          setLastData(res?.data?.map((x, i) => {
            return {
              id: i,
              instructor: x.instructorName,
              class: x.classes,
              student: x.studentNameFirstName + ' '+ x.studentNameLastName,
              studentrusername:x.studentUserName,
              lesson: x.lesson,
              engage_step_id: x.engage_step_id,
              engage_step_marks: x.engage_step_marks,
              evaluate_step_id: x.evaluate_step_id,
              evaluate_step_marks: x.evaluate_step_marks,
              explain_step_id: x.explain_step_id,
              explain_step_marks: x.explain_step_marks,
              extend_step_id: x.extend_step_id,
              extend_step_marks: x.extend_step_marks,
              explore_step_id: x.explore_step_id,
            }
          }))

        }).finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <>
      <div className="container-fluid">
        <Spin tip="Loading..." spinning={loading} size="large">
          <Row>
            <Col span={12} offset={6}>
              <center>
                <h3>Teacher</h3>
              </center>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <CardBody className="mt-3 mb-3">
                <Select
                  showSearch
                  placeholder="Select Instructor"
                  optionFilterProp="children"
                  disabled={Teachers?.find(x => x.name === user)?.id !== undefined || Teachers?.length === 0}

                  value={Teachers?.find(x => x.name === user)?.id ? Teachers?.find(x => x.name === user)?.id : Teacher_Id}
                  onChange={onTeacherChange}
                  size="large" m
                  className="search-drop" >
                  {
                    Teachers?.map(x => {
                      return <Option value={x.id} key={x.id}   >{x.name}</Option>
                    })
                  }
                </Select>
              </CardBody>
            </Col>
            <Col span={8}>
              <CardBody className="mt-3 mb-3">
                <Select
                  showSearch
                  placeholder="Select Class"
                  optionFilterProp="children"
                  size="large"
                  disabled={classes?.length === 0}
                  onChange={onClassChange}
                  className="search-drop" >
                  {
                    classes?.map(x => {
                      return <Option value={x.id} key={x.id}   >{x.name}</Option>
                    })
                  }
                </Select>
              </CardBody>
            </Col>
            <Col span={8}>
              <CardBody className="mt-3 mb-3">
                <Select
                  showSearch
                  placeholder="Select Lesson" s
                  optionFilterProp="children"
                  size="large"
                  disabled={lesson?.length === 0}
                  onChange={onLessonChange}
                  className="search-drop" >

                  {
                    lesson?.map(x => {
                      return <Option value={x.id} key={x.id}   >{x.name}</Option>
                    })
                  }
                </Select>
              </CardBody>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                dataSource={lastData}
                pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']  }}
                columns={columns}
                className="thead-dark admin-table"
              />
            </Col>
          </Row>
        </Spin>
      </div>
      <Drawer
      className='ev-draw'
        destroyOnClose={true}
        title={`${ReviewStudentName} | ${expName}`}
        placement="right" onClose={() => { onClose() }}
        visible={open}>
        <ChildDrawer marks={marks} usernamest={usernamest} step={stepofEach} dt={dt} username={ReviewStudentName} explorestepId={explorestepId} onClose={() => {
          onClose()
        }} />
      </Drawer>
    </>
  )
}

export default EvaluateStudent