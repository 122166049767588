import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


export function MultipleBarDynamic2(props) {
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const customValue = props?.chartData?.datasets[0]?.yData[context.dataIndex]; 
            return `${customValue}`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
        beginAtZero: true,
        ticks: {
          display: false,
        }
      },
    },
  };

  useEffect(() => {
    if (props?.chartData?.type === 1) {
      setLabels(props?.chartData?.labels);
      let colorRange = [];
      let lengthArray = 0;
      if (props?.chartData?.datasets[0]?.xyData) {
        props?.chartData?.datasets[0]?.xyData.map((x) => {
          colorRange.push(x[0]);
        });

        lengthArray = props?.chartData?.datasets[0]?.yData.length;
      }

      const dataV = new Array(lengthArray).fill(1);
      setDatasets([
        {
          label: props?.chartData?.datasets[0]?.label,
          data: dataV,
          backgroundColor: colorRange,
          borderColor: props?.chartData?.datasets[0]?.borderColor,
          borderWidth: 2,
        },
      ]);
    }
  }, [props]);

  const data = {
    labels,
    datasets,
  };

  return <Bar key={JSON.stringify(props.chartData)} options={options} data={data} />;
}
