import {

  Card,

  CardBody,

  Row,
  Col,
  Button,

} from "reactstrap";
import { UserData } from '../../../../components/Charts/Data'
import LineRegressionChart from '../../../../components/Charts/LineRegressionChart'
import jsonToken from 'jsonwebtoken'
import { useEffect, useState } from "react";
import RestService from '../../../../api/RestService';
import AnswerDisplay from '../../../LessonLab/AnswerDisplay';
import ChartValidations from '../../../LessonLab/Explore/childComponent/ChartValidations'
import CoordinatesValues from '../../../LessonLab/Explore/childComponent/coordinatesValues'
import { Radio, Input, Form, Modal, Tabs, Checkbox, Image, Spin } from "antd";
import "antd/dist/antd.css";

function Finish(props) {


  const [counter, setCounter] = useState(0);
  const [reviewStatus, setReviewStatus] = useState(true);
  const [payload, setPayload] = useState([]);
  const [containedPayload, setContainedPayload] = useState({});
  const [cellId, setCellId] = useState([]);
  const [selectOption, setSelectOption] = useState([]);
  const [allGraphValue, setAllGraphValue] = useState("");
  const [checked, setChecked] = useState(false);
  const [lesson, setLesson] = useState(parseInt(props.evaluate_id));
  const [loading, setLoading] = useState(true);
  const [datasets, setDatasets] = useState([
    {
      label: "",
      yData: [],
      xData: [],
      color: ""
    }
  ]);
  const [initPayload, setInitPayload] = useState({
    description: "",
    evaluateStepQuestionAnswers: [],
    evaluateStepQuestions: [],
    id: "",
    lesson_id: "",
    exploreStepResponses: "",
  });

  let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;

  }
  console.log("test", props)
  const [responseData, setResponseData] = useState({
    borderColor: "",
    borderWidth: "",
    labels: "",
    xData: [],
    yData: [],
    xyData: [],
    categoryData: [],
    cod: "",
    speed: "",
  })
  const [responseMixData, setResponseMixData] = useState([]);
  const [graphMixStatus, setGraphMixStatus] = useState(false);


  useEffect(() => {


    if (datasets && datasets.length > 0) {
      datasets.map(x => {
        if (x.yData.length == 0) {
          setDatasets(datasets.filter(x => x.yData.length > 0))
        }
      })
    }
  }, [datasets])
  useEffect(() => {
    setLoading(true)
    RestService.getEvaluateStepInfosById(lesson)
      .then((response) => {

        if (counter === 0) {
          setInitPayload(response?.data)
          setLoading(false)
          setCounter(1);
        } else {

          if (initPayload.evaluateStepQuestionAnswers !== undefined && initPayload.evaluateStepQuestions !== undefined) {
            const secondSendPayload = {
              description: initPayload.description,
              evaluateStepQuestionAnswers: initPayload.evaluateStepQuestionAnswers,
              evaluateStepQuestions: initPayload.evaluateStepQuestions,
              exploreStepResponses: response.data.exploreStepResponses,
              id: initPayload.id,
              lesson_id: initPayload.lesson_id,
            }
            setInitPayload(secondSendPayload)
          }
          setLoading(false)
        }


      })
  }, [props])

  function convert(data) {
    return Array.isArray(data)
      ? data.reduce((obj, el, i) => (el + 1 && (obj[i] = convert(el)), obj), {})
      : data;
  }

  useEffect(() => {

    setLoading(true)
    RestService.getEvaluateStepByIdAndUserName(user, lesson)
      .then((response) => {
        if (response) {
          setContainedPayload(convert(response?.data[0]?.response_json.answers))
          setLoading(false)
        }
      })
  }, [])

  const { TabPane } = Tabs;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const sendPayload = (value) => {
    setPayload(value);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const { TextArea } = Input;
  const [value, setValue] = useState(1);

  const ReviewFun = () => {
    setReviewStatus(false)
    window.scrollTo(0, 0)
  };

  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.distance),
    datasets: [
      {
        data: UserData.map((data) => data.time),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
        options: {
          plugins: {
            title: {
              display: true,
              text: "",
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "Distance",
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Time",
              },
            },
          },
        },
        label: "",
      },
    ],
  });

  useEffect(() => {

    setResponseMixData([]);
    setResponseData({
      borderColor: "",
      borderWidth: "",
      labels: "",
      xData: [],
      yData: [],
      xyData: [],
      categoryData: [],
      cod: "",
      speed: "",
      userMainHypothesis: "",
    });
    if (Object.keys(cellId).length > 0 && Object.keys(cellId).length === selectOption.length) {
      setChecked(true)
    }
    if (initPayload?.exploreStepResponses[0]?.response_jason?.answers !== null && initPayload?.exploreStepResponses[0]?.response_jason?.answers !== undefined) {
      if (cellId.length !== 0 && cellId !== undefined && cellId !== null) {
        const filteredList = Object.keys(initPayload?.exploreStepResponses[0]?.response_jason?.answers)
          .filter(key => Object.values(cellId).includes(key))
          .reduce((obj, key) => {
            obj[key] = initPayload?.exploreStepResponses[0]?.response_jason?.answers[key];
            return obj;
          }, {});
        if (Object.values(filteredList).length <= 1) {
          setGraphMixStatus(false)
          if (Object.values(filteredList)[0] !== undefined && Object.keys(filteredList)[0] !== null) {
            const responsePayload = {
              borderColor: Object.values(filteredList)[0].borderColor,
              borderWidth: Object.values(filteredList)[0].borderWidth,
              labels: Object.values(filteredList)[0].label,
              xData: Object.values(filteredList)[0].xData,
              yData: Object.values(filteredList)[0].yData,
              xyData: Object.values(filteredList)[0].xyData,
              categoryData: Object.values(filteredList)[0].categoryData,
              cod: Object.values(filteredList)[0].cod,
              speed: Object.values(filteredList)[0].speed,
            }

            // setDatasets([{
            //   label:Object.values(filteredList)[0].label,
            //   yData:Object.values(filteredList)[0].yData,
            //   xData:Object.values(filteredList)[0].xData
            // }])
            setResponseData(responsePayload);
          }

        } else {
          setResponseMixData([]);
          setGraphMixStatus(true);
          for (let k = 0; k < Object.values(filteredList).length; k++) {
            if (Object.values(filteredList)[k] !== undefined && Object.keys(filteredList)[k] !== null) {
              const responsePayload = {
                borderColor: Object.values(filteredList)[k].borderColor,
                borderWidth: Object.values(filteredList)[k].borderWidth,
                labels: Object.values(filteredList)[k].label,
                xData: Object.values(filteredList)[k].xData,
                yData: Object.values(filteredList)[k].yData,
                xyData: Object.values(filteredList)[k].xyData,
                categoryData: Object.values(filteredList)[k].categoryData,
                cod: Object.values(filteredList)[k].cod,
                speed: Object.values(filteredList)[k].speed,
              }
              setResponseMixData((preData) => [...preData, responsePayload]);
              // setDatasets([{
              //   label:Object.values(filteredList)[k].label,
              //   yData:Object.values(filteredList)[k].yData,
              //   xData:Object.values(filteredList)[k].xData
              // }])
            }

          }
        }




      }
      if (selectOption.length === 0) {
        let arr = []
        Object.keys(initPayload?.exploreStepResponses[0]?.response_jason?.answers).map(x => {

          arr.push({
            name: x,
            isChecked: false
          })

        })

        setSelectOption(arr)

      }


    }

  }, [cellId, initPayload]);

  const graphdata1 = {
    labels: responseData.categoryData,
    type: responseData.type || 1,
    datasets: [
      {
        label: responseData.labels,
        xData: responseData.xData,
        yData: responseData.yData,
        xyData: responseData.xyData,
        borderColor: responseData.borderColor,
        borderWidth: responseData.borderWidth,
      },
    ],
  };

  const dataFromGraph = (fitValue, speed) => {
  };

  const tablePayload = {
    type: graphMixStatus,
    xData: lesson === 3 ? responseData.xData : responseData.yData,
    yData: lesson === 3 ? responseData.yData : responseData.xData,
    speed: responseData.speed,
    cod: responseData.cod,
  };

  const viewData = datasets.map(i => {

    return {
      type: 2,
      xData: i.yData.length > 0 ? i.yData : [],
      yData: i.yData.length > 0 ? i.xData : [],
    }
  })


  const secondLessonTablePayload = {
    labels: [1, 2, 3, 4, 5],
    type: 1,
    datasets: datasets

  }
  const thirdLessonTablePayload = {
    labels: responseData.xData,
    type: 3,
    datasets: datasets.map(i => {
      return {
        label: i.label,
        yData: i.yData
      }
    })
  }

  const onChange = (e) => {

    setValue(e.target.value);
  };

  const submitFun = async () => {
    // @todo username should be changed

    const submitPayload = {
      username: user,
      userId: initPayload.id,
      status: 2,
      response_json: {
        answers: Object.values(payload)
      },
      last_update_date: new Date().toISOString().slice(0, 10),
      evaluate_step_id: 1,
    }

    // @todo should succesfully completed out here
    await RestService.postEvaluateStepUserResponses(submitPayload)
      .then((response) => {
        if (response.status === 200) {
          window.scrollTo(0, 0)
          props.submitTrigger("4");
        }
      })
  }
  const changeHandler = (event) => {
    const check = event.target.checked;
    const name = event.target.name;

    if (check === true) {
      setChecked(false)

      if (lesson === 3) {
        if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {

          if (datasets.findIndex(x => x.label === name) !== -1) {

            datasets.filter(x => x.label === name)
            setDatasets(datasets)
          }
          else {


            setDatasets(prev => (
              [
                ...prev,
                {
                  label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label,
                  yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData,

                  xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData
                }
              ]
            ))
          }

        }
      }
      else {
        if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {

          if (datasets.findIndex(x => x.label === name) !== -1) {

            datasets.filter(x => x.label === name)
            setDatasets(datasets)
          }
          else {


            setDatasets(prev => (
              [
                ...prev,
                {
                  label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label,
                  yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(i => {
                    return i.value
                  }),
                  color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(i => {
                    return i.color
                  }),
                  xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData
                }
              ]
            ))
          }

        }
      }
      selectOption.map(x => {
        if (x.name === name) {
          x.isChecked = true
        }
      })


      setSelectOption(selectOption)
      setCellId(prev => ({
        ...prev,
        [name]: name
      }))
    } else {
      setDatasets(datasets.filter(x => x.label !== name))
      selectOption.map(x => {
        if (x.name === name) {
          x.isChecked = false
        }
      })

      setSelectOption(selectOption)
      setChecked(false)
      delete cellId[name];
      setCellId(prev => ({
        ...prev,
      }))
    }
  }

  const changeHandler3 = (event) => {
    const check = event.target.checked;
    const name = event.target.name;


    if (check === true) {
      selectOption.map(x => {
        x.isChecked = true
      });
      setSelectOption(selectOption)
      setChecked(true)
      name.map((x) => {
        if (lesson === 3) {
          if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label === x) !== -1) {


            }
            else {

              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label,
                    yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData,

                    xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].xData
                  }
                ]
              ))


            }
          }
        }
        else {
          if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label === x) !== -1) {


            }
            else {

              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label,
                    yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
                      return i.value
                    }),
                    color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
                      return i.color
                    }),
                    xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].xData
                  }
                ]
              ))


            }
          }
        }
        setCellId(prev => ({
          ...prev,
          [x]: x
        }))
      })
    } else {
      setDatasets([{
        label: null,
        yData: [],
        xData: [],
      }])
      selectOption.map(x => {
        x.isChecked = false
      });
      setSelectOption(selectOption)
      setChecked(false)
      setGraphMixStatus(false)
      setCellId("");
    }
  }

  useEffect(() => {
    const createPayloadForAll = []
    selectOption.map((x) => {
      createPayloadForAll.push(x.name)
    });
    setAllGraphValue(createPayloadForAll)
  }, [selectOption]);



  const display = selectOption.map((x) => {
    const styleValue = `${x.name}-color`;
    return (
      <div className="chk-div">
        <Checkbox checked={x.isChecked} className="bold" name={x.name} onChange={changeHandler}>{x.name}</Checkbox>
        <div className={styleValue}></div>
      </div>
    )
  })

  return (
    <Spin tip="Loading..." spinning={loading} size="large">

      <div className="content evaluate-page tab-style finish">
        <div className="card-chart p2 card">
          <div className="card-body bg-evaluate-100">
            <Row className="">
              <Col xs="12" className="">
                <div dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div>
              </Col>
            </Row>

            <Row className="pt-4">
              <Col md="1"></Col>
              <Col md="5">
                <Row>
                  <Col md="5">
                    <CoordinatesValues data={lesson !== 2 ? graphMixStatus ? responseMixData : tablePayload : lesson === 2 && viewData} />
                  </Col>

                  <Col md="1"></Col>
                  {/* {
                  lesson === 2 &&
                  <Col xs="5">
                    <Col md="12">
                    <div className="filters">
                            <div className="chk-all">
                              <Checkbox checked = {checked} name={allGraphValue} onChange={changeHandler3}>
                                <span className="all"><b>All</b></span>
                              </Checkbox>
                            </div>
                      {display}
                          </div>
                        </Col>
                    <Col md="12">
                      <div className="chart">
                        <ChartValidations type={dataSendToMap.map(x => {
                          if (x.name === selectOption) {
                            return x.chart_type
                          }
                        }
                        ).filter(element => {
                          return element !== undefined;
                        })} lesson={lesson} heading={dataSendToMap.map(x => {
                          if (x.name === selectOption && x.description) {
                            return x.description
                          }
                        }
                        ).filter(element => {
                          return element !== undefined;
                        })

                        } data={
                          secondLessonTablePayload
                        } />
                      </div>
                    </Col>
                  </Col>
                } */}
                  <Col md="5">
                    <div className="filters">
                      <div className="chk-all">
                        <Checkbox checked={checked} name={allGraphValue} onChange={changeHandler3}>
                          <span className="all"><b>All</b></span>
                        </Checkbox>
                      </div>
                      {display}

                      {/* <div className="chk-all">
                          <Checkbox><span className="all">Show All</span></Checkbox>
                          </div>
                      <br />
                     
                      <div className="chk-div">
                        <Checkbox className="bold">Walk</Checkbox>
                        <div className="walk-color"></div>
                      </div>
                      <div className="chk-div">
                        <Checkbox className="bold">Jog</Checkbox>
                        <div className="jog-color"></div>
                      </div>
                      <div className="chk-div">
                        <Checkbox className="bold">Run</Checkbox>
                        <div className="run-color"></div>
                      </div>
                      <div className="chk-div">
                        <Checkbox className="bold">Skate</Checkbox>
                        <div className="skate-color"></div>
                      </div>
                      <div className="chk-div">
                        <Checkbox className="bold">Bike</Checkbox>
                        <div className="bike-color"></div>
                      </div> */}
                    </div>
                  </Col>
                </Row>
              </Col>

              {/* <Col xs="5">
                    <Col md="12">
                      <div className="chart">
                        
                      </div>
                    </Col>
                  </Col> */}

              <Col md="5">
                {
                  lesson == 2 &&
                  <ChartValidations lesson={lesson} heading={["Table"]} type={["Bar"]} data={
                    secondLessonTablePayload
                  } />
                }
                {
                  lesson == 3 &&
                  <ChartValidations lesson={lesson} heading={["Shadows"]} type={["curve"]} data={
                    thirdLessonTablePayload
                  } />
                }
                {
                  lesson !== 2 && lesson !== 3 &&
                  <LineRegressionChart chartData={graphMixStatus ? responseMixData : graphdata1} dataFromGraph={dataFromGraph} />
                }

              </Col>
            </Row>

            <br /><br /><br />
            <Row className="pt-4">
              <Col xs="12">
                <Card className="bg-evaluate bg-evaluate-100 border-0 rounded-0">
                  <CardBody className="ml-5 mt-5 mb-5">
                    <h5> Questions</h5>
                    <hr className="black" />
                    <Row>
                      <Col xs="12">
                        {
                          initPayload !== undefined &&
                          <AnswerDisplay data={
                            {
                              type: 1,
                              question: initPayload.evaluateStepQuestions,
                              answers: initPayload.evaluateStepQuestionAnswers,
                              preData: containedPayload,
                              getData: sendPayload
                            }
                          } />
                        }
                        {/* <Answers question={initPayload.evaluateStepQuestions} answers={initPayload.evaluateStepQuestionAnswers} preData={containedPayload} getData={sendPayload}/> */}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Row className="button-center p2">
              <button onClick={() =>  { window.scrollTo(0, 0)
  props.submitTrigger("2")}} type="primary" class="btn btn-primary btn-lg mt-50">
                Proceed to Explore
              </button> &nbsp;
              {reviewStatus ? (
                <button onClick={ReviewFun} type="primary" class="btn btn-primary btn-lg mt-50">
                Review
              </button>
              ) : (
                <button onClick={submitFun} type="primary" class="btn btn-primary btn-lg mt-50">
                Submit Answers
              </button>
              )}
            </Row> */}

          </div>
        </div>

        {/* <div className="card-chart p2 card">
          <div className="card-body">
            <Row className="pt-4">
              <Col xs="12">
                <Card className="bg-evaluate border-0 rounded-0">
                  <CardBody className="ml-5 mt-5 mb-5 p-25">
                    <p className="pl2">
                      Your answer is marked in:{" "}
                      <span className="text-success strong">green</span> if it
                      is right or{" "}
                      <span className="text-danger strong">red</span> if it is
                      wrong.
                    </p>
                    <br />
                    <div className="white-card pt-4">
                      <Row>
                        <Col md="6">
                          <p className="pt-4">
                            You took data for a person at different constant
                            speeds. You plotted this data on a graph: distance
                            (y-axis) against time (x-axis). It plotted distance
                            against time. The resulting graphs looked something
                            like this: You found out that steeper lines mean
                            faster motion.
                          </p>
                        </Col>
                        <Col md="6">
                          <Image alt="Q1" src={up} className="p-3" />
                        </Col>
                      </Row>
                    </div>
                    <h5 className="pt-4"> Questions</h5>
                    <hr className="black" />
                    <Row>
                      <Col xs="12">
                        <AnswersReview />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
          </div>
        </div> */}
      </div>
      <br />
      <div>
        <Row>
          <Col md="6">
            <Button
              type="primary"
              className="mb20 btn btn-info"
              style={{padding: "14px 31px"}}
              onClick={() =>{
                props.onChangex()
              }}
            >
              {"Back"}
            </Button>
          </Col>
          <Col>

          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default Finish;
