import { InboxOutlined, CheckCircleOutlined, InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { message, Upload, Row, Col, notification } from 'antd';
import { Button } from 'reactstrap';
import Config from '../../../api/Config';
import axios from 'axios';
import { useState } from 'react';
const { Dragger } = Upload;

const DragAndDropUploader = (props) => {
    const uploadCsv = async options => {
            const { onSuccess, onError, file, onProgress } = options;

            const isImage = imageMimeTypes.includes(file.type);
            if (!isImage) {
                message.error('Only csv files are allowed!');
                const error = new Error("Wrong file type");
                onError({ error });
                return false;
            }else{
                const token = localStorage.getItem("access_token");
                const fmData = new FormData();
                const config = {
                    headers: { "Authorization": `Bearer ${token}` },
                };
                fmData.append("file", file);
                fmData.append("roleId", props.data);
                try {
                    if(props.data == 3){
                        await axios.post(
                            `${Config.APIURL}admin/class/csvImport`,
                            fmData,
                            config
                        ).then((response) => {
                            if (response.data.status === 200) {
                                notification.open({
                                    placement: 'top',
                                    duration: "2",
                                    description: <li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{response.data.message}</span></li>,
                                });
                            } else {
                                notification.open({
                                    placement: 'top',
                                    duration: "2",
                                    description: <li><CloseCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{response.data.message}</span></li>,
                                });
                            }
                        });
                        onSuccess("Ok");
                    }
                    else{
                        await axios.post(
                            `${Config.APIURL}admin/lesson/csvImport`,
                            fmData,
                            config
                        ).then((response) => {
                            if (response.data.status === 200) {
                                notification.open({
                                    placement: 'top',
                                    duration: "2",
                                    description: <li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{response.data.message}</span></li>,
                                });
                            } else {
                                notification.open({
                                    placement: 'top',
                                    duration: "2",
                                    description: <li><CloseCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{response.data.message}</span></li>,
                                });
                            }
                        });
                        onSuccess("Ok");
                    }
                } catch (err) {
                    const data = JSON.parse(err.request.response )
                    const error = new Error("Wrong file formate");
                    onError({ err });
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: <li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{data.message}</span></li>,
                    });
                }
            }
    }

    const imageMimeTypes = ["text/csv"]
    const dataValues = {
        multiple:true,
        listType: 'text',
        showUploadList: { showRemoveIcon: false },
        accept: imageMimeTypes.join(','), // accept only image files
        // onDrop(e) {
        //   const { files } = e.dataTransfer;
        //   const isAllImages = Array.from(files).every(file =>
        //         imageMimeTypes.includes(file.type)
        //   );
        //   if (!isAllImages) {
        //       message.error('Only csv files are allowed!');
        //       e.preventDefault();
        //     }
        // },
        // beforeUpload(file) {
        //   const isImage = imageMimeTypes.includes(file.type);
        //   if (!isImage) {
        //       message.error('Only csv files are allowed!');
        //       return false;
        //   }
        //   return true;
        // },
        customRequest:uploadCsv,
      };
    return (
        <>
             <Dragger
              {...dataValues}
              >
                <p className="ant-upload-drag-icon text-dark">
                    <InboxOutlined style={{ color: "#505050" }} />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                {/* <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                    band files
                </p> */}
            </Dragger>
        </>
    );
}
export default DragAndDropUploader;