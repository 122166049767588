import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    value: 0,
    
}

const counterSlice = createSlice({
    name: "counter",
    initialState,

    reducers: {
        incremented(state){
            state.value++; 
        }


    },

})

export const GetCounterReducer = counterSlice.reducer;

export const { incremented, } = counterSlice.actions;