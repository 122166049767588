import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from "react-router-dom";
// reactstrap components
import { Card, CardText, CardImg, Row, Col, CardBody, Input, FormGroup, FormFeedback } from "reactstrap";
import { Button } from "react-bootstrap";
import { Tabs, Table, Space, Breadcrumb, notification, Spin } from "antd";
import "antd/dist/antd.css";
import validate from "../Teachers/editFormValidaiton";
import useCustFormValidator from "../../../../hooks/useCustFormValidator";
import jsonToken from 'jsonwebtoken'
import { getTeacherbyId, updateTeacher2 } from "../../../../api/TeacherService";
import StudentService from "../../../../api/StudentServiceNew";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";

function Profile() {

    let props = jsonToken.decode(localStorage.getItem("access_token"));
    var obj = {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        username: "",
        organization_id: -1,
        confirmPassword: '',
        status: 1,
        roleId: 2,
        staffId: "",
        accountId: props.accval
    }
    const [pwdStatus, setPwdStatus] = useState("false");
    const [loading, setLoading] = useState(false);
    const [edited, setEdited] = useState(false);
    const [uneditedObj, setUneditedObj] = useState(obj);
    const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });

    const [accountId, setAccountId] = useState(null);

    const [accounts, setAccounts] = useState([]);
    const [schools, setSchools] = useState([]);

    const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(onSave, validate, { obj });

    useEffect(() => {
        loadTeacher();
        loadAccounts();

    }, []);

    useEffect(() => {
        loadSchools(accountId)
    }, [accountId]);



    function loadAccounts() {
        setLoading(true);
        StudentService.getAccountsForUser()
            .then((response) => {
                if (response?.data?.accountList) {
                    setAccounts(response.data.accountList);
                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    function loadSchools() {
        if(accountId){
            setLoading(true);
            StudentService.getSchoolsForAccount(accountId)
                .then((response) => {
                    if (response?.data) {
                        setSchools(response.data);
                    }
                }).catch((err) => {
                    console.log(err.message);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    function loadTeacher() {
        setLoading(true);
        getTeacherbyId(props.userId)
            .then((response) => {
                if (response?.data) {
                    var data = response.data;
                    data.password = '';
                    data.confirmPassword = '';
                    setAccountId(response?.data?.organization_id)
                    setUneditedObj(data);
                    setFormData(data);
                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    function onSave() {
        setLoading(true);
        delete values.confirmPassword;
        let submitPayload = {
            id: props.userId,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            username: values.username,
            organization_id: values.organization_id,
            confirmPassword: values.confirmPassword,
            status: 1,
            roleId: 2,
            staffId: values.staffId,
            accountId: values.accountId

        }
        updateTeacher2(submitPayload)
            .then((response) => {
                if (response.status == 200) {
                    let messages = [{ type: 'success', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    loadTeacher();
                }
                else {
                    let messages = [{ type: 'error', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    loadTeacher();
                    loadAccounts();
                }

            }).catch((err) => {
                let messages = [{ type: 'error', text: err.message }]
                notification.open({
                    placement: 'top',
                    duration: "2",
                    description: [
                        messages.map((item) => {
                            if (item.type === 'success') {
                                return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'error') {
                                return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'warning') {
                                return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                        })
                    ],

                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });
            }).finally(() => {
                setLoading(false);
            });
    }

    function hideAlert() {
        var alert = { showAlert: false, message: "", type: "success" }
        setAlertData(alert);
    }
    function onEmailChange(e) {
        e.persist();
        setFormData({ ...values, email: e.target.value })
    }
    function onShowPassChange(e) {
        if (e.target.value === "true") {
            setFormData({ ...values, password: '', confirmPassword: '', showPass: true })
            setPwdStatus("true")
        } else {
            setFormData({ ...values, showPass: false })
            setPwdStatus("false")

        }
    }
    useEffect(() => {
        setEdited(JSON.stringify(values) != JSON.stringify(uneditedObj))
    }, [values]);
    const { TabPane } = Tabs;
    return (
        <>
            <Spin tip="Loading..." spinning={loading} size="large">
                <div className="container-fluid admin-tabs">
                    <Row>
                        <Col md="12">
                            <Card className="border-0">
                                <CardBody className="e">
                                    <Row>
                                        <Col md="6">
                                            <h5>Profile</h5>
                                        </Col>
                                    </Row>
                                    <div className="mt-5">
                                        <Row>
                                            <Col md="12">
                                                <div className="form-row">
                                                    <div className="form-group col-lg-6 col-sm-12">
                                                        <label className="form-label" htmlFor="form3Example4">
                                                            <span className="required-field">*</span>First Name
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="form3Example4"
                                                            className="form-control form-control-lg"
                                                            autocomplete="off"
                                                            placeholder=""

                                                            value={values.firstName}
                                                            onChange={handleChange}
                                                            name="firstName"
                                                            invalid={!!errors?.firstName}
                                                        />
                                                        <FormFeedback>{errors?.firstName}</FormFeedback>
                                                    </div>
                                                    <div className="form-group col-lg-6 col-sm-12">
                                                        <label className="form-label" htmlFor="form3Example4">
                                                            <span className="required-field">*</span>Last Name
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="form3Example4"
                                                            className="form-control form-control-lg"
                                                            autocomplete="off"
                                                            placeholder=""
                                                            name="lastName"
                                                            value={values.lastName}
                                                            onChange={handleChange}
                                                            invalid={!!errors?.lastName}
                                                        />
                                                        <FormFeedback>{errors?.lastName}</FormFeedback>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-lg-6 col-sm-12">
                                                        <label className="form-label" htmlFor="form3Example4" >
                                                            Email
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="form3Example4"
                                                            className="form-control form-control-lg"
                                                            placeholder=""
                                                            name="email"
                                                            autoComplete="new_mail"
                                                            value={values.email}
                                                            onChange={(e) => { onEmailChange(e) }}
                                                            invalid={!!errors?.email}
                                                        />
                                                        <FormFeedback>{errors?.email}</FormFeedback>
                                                    </div>


                                                    <div className="form-group col-lg-6 col-sm-12">
                                                        <label className="form-label" htmlFor="form3Example4" >
                                                            <span className="required-field">*</span>Username
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="form3Example4"
                                                            className="form-control form-control-lg"
                                                            placeholder=""
                                                            name="userName"
                                                            autoComplete="off"
                                                            value={values.username}
                                                            invalid={!!errors?.username2}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-lg-6 col-sm-12">
                                                        <label className="form-label" htmlFor="form3Example4" >
                                                            <span className="required-field">*</span>Account
                                                        </label>
                                                        <Input type="select"
                                                            id="selectbasic"
                                                            className="form-control form-control-lg"
                                                            placeholder="Select an Account"
                                                            value={values.accountId}
                                                            name="accountId"
                                                            disabled={true}
                                                            invalid={!!errors?.accountId}
                                                        >
                                                            <option key="-1" value="-1">Please Select an Account</option>
                                                            {
                                                                accounts.filter(item => item.name !== null).map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                                            }
                                                        </Input>
                                                    </div>
                                                    <div className="form-group col-lg-6 col-sm-12">
                                                        <label className="form-label" htmlFor="form3Example4">
                                                            <span className="required-field">*</span>School
                                                        </label>
                                                        <Input type="select"
                                                            id="selectbasic"
                                                            className="form-control form-control-lg"
                                                            placeholder="Select an School"
                                                            value={values.organization_id}
                                                            name="organization_id"
                                                            onChange={(e) => { handleChange(e) }}
                                                            invalid={!!errors?.organization_id}
                                                            disabled={true}
                                                        >
                                                            {
                                                                <option disabled={values.organization_id === -1} key="-1" value="-1">Please Select a School</option>
                                                            }
                                                            {
                                                                schools?.length > 0 && schools.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                                            }                                                    </Input>
                                                    </div>


                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-lg-6 col-sm-12">
                                                        <label className="form-label"  >
                                                            Staff ID
                                                        </label>

                                                        <Input
                                                            type="text"
                                                            id="form3Example4"
                                                            className="form-control form-control-lg"
                                                            autocomplete="off"
                                                            placeholder=""
                                                            name="staffId"
                                                            onChange={handleChange}
                                                            value={values.staffId}
                                                            invalid={!!errors?.staffId}
                                                        />
                                                        <FormFeedback>{errors?.staffId}</FormFeedback>
                                                    </div>

                                                    <div className="form-group col-lg-6 col-sm-12">
                                                        <label className="form-label"  >
                                                            Do You Need To Change Password
                                                        </label>
                                                        <Input type="select"
                                                            id="selectbasic"
                                                            className="form-control form-control-lg"
                                                            placeholder="Select an Account"
                                                            name="accountId"
                                                            onChange={onShowPassChange}
                                                        >
                                                            <option key="0" value="false">No</option>
                                                            <option key="1" value="true">Yes</option>
                                                        </Input>
                                                    </div>

                                                </div>
                                                {pwdStatus === "true" && (
                                                    <div className="form-row">
                                                        <div className="form-group col-lg-6 col-sm-12">
                                                            <label className="form-label" >
                                                                <span className="required-field">*</span>Password
                                                            </label>
                                                            <Input
                                                                type="password"
                                                                id="form3Example4"
                                                                className="form-control form-control-lg"
                                                                autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" aria-autocomplete="none"
                                                                placeholder=""
                                                                name="password"
                                                                value={values.password}
                                                                onChange={handleChange}
                                                                invalid={!!errors?.password}
                                                            />
                                                            <FormFeedback>{errors?.password}</FormFeedback>
                                                        </div>
                                                        <div className="form-group col-lg-6 col-sm-12">
                                                            <label className="form-label" >
                                                                <span className="required-field">*</span>Confirm Password
                                                            </label>
                                                            <Input
                                                                type="password"
                                                                id="form3Example4"
                                                                className="form-control form-control-lg"
                                                                autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" aria-autocomplete="none"
                                                                placeholder=""
                                                                name="confirmPassword"
                                                                value={values.confirmPassword}
                                                                onChange={handleChange}
                                                                invalid={!!errors?.confirmPassword}
                                                            />
                                                            <FormFeedback>{errors?.confirmPassword}</FormFeedback>
                                                        </div>

                                                    </div>
                                                )}

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md='12'>
                                                <div className="center mt-4">
                                                    <Button
                                                        onClick={handleSubmit}
                                                        color="success"
                                                        disabled={!edited}
                                                    >Update Details</Button>
                                                </div>
                                                <div className="center mt-4">
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Spin>
        </>
    );
}

export default Profile;
