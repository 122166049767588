import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from "react-router-dom";
// reactstrap components
import { Card, CardText, CardImg, Row, Col, CardBody } from "reactstrap";
import { Button } from "react-bootstrap";
import { Tabs, Input, Table, Space } from "antd";
import "antd/dist/antd.css";
import AdminCreate from "./Create";
import AdminView from "./view";


function AdminAccount(props) {
  const [flag, setFlag] = useState(false);
  const [key, setKey] = useState(1);
  const { TabPane } = Tabs;
  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    if (props.activeTab == props.tabId) {
      var tempKey = key + 1;
      setKey(tempKey)
    }
  }, [props.activeTab])

  useEffect(() =>{
    setFlag(false)
  },[props])
  return (
    <>
      <div className="container-fluid admin-tabs">
        <Row>
          <Col md="12">
            <Card className="border-0">
              <CardBody className="e">
                <Row>
                  <Col md="6">
                    <h5>Account Admin</h5>
                  </Col>
                  <Col md="6">
                    <div className="pull-right">
                      <Button
                        type="primary"
                        className="mr10 mb20"
                        onClick={() => setFlag(!flag)}
                        size="sm"
                      >
                        {flag ? "View" : "New"}
                      </Button>
                      <Button type="primary" className="mb20 hidden" size="sm">
                        Import
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {flag ? <AdminCreate a={flag} setFlag={setFlag}/> : <AdminView key={key} h={flag} activeTab={props.activeTab} tabId={props.tabId} />}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AdminAccount;
