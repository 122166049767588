import { useContext, useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Table,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import { Radio, Input, Form, Modal, Tabs, Spin } from "antd";
import "antd/dist/antd.css";
import RestService from "../../../../api/RestService";

import AnswerDisplay from '../../../LessonLab/AnswerDisplay/index'
import jsonToken from 'jsonwebtoken'
import { CommonContext } from "../components/CommonContext";


function Finish(props) {
  const { TabPane } = Tabs;
  const {value,setValue} = useContext(CommonContext);
  const [payload, setPayload] = useState([]);
  const [containedPayload, setContainedPayload] = useState({});
  const [loading,setLoading] = useState(true);

  const [initPayload, setInitPayload] = useState({
    description:"",
    engageStepQuestionAnswers:[],
    engageStepQuestions:[],
    videoUrl:"",
    id:"",
    lesson_id:"",
  });

  const [reviewStatus,setReviewStatus] = useState(true);
  const [lesson,setLesson] = useState(props.engage_id
    );
  let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;
    
  }

  function convert(data) {
    return Array.isArray(data)
        ? data.reduce( (obj, el, i) => (el+1 && (obj[i] = convert(el)), obj), {} )
        : data;
  }

  useEffect( ()=>{
    setLoading(true)
    RestService.getEngageStepInfoById(lesson)
    .then((response) => {
        
       if( response !== undefined){
        setInitPayload(response?.data)
       }
      setLoading(false)
    })
  }, [lesson])





//   useEffect( ()=>{
//    setLoading(true)
//     RestService.getEngageStepByIdAndUserName(user,lesson)
//     .then((response) => {
//       if(response){
//         setContainedPayload(convert(response?.data[0]?.response_json.answers))
//         setLoading(false)
//       }
//       })
//     }, [])


  const [isModalVisible, setIsModalVisible] = useState(false);

  const sendPayload = (value) => {
    setPayload(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const { TextArea } = Input;
//   const [value, setValue] = useState(1);

  const onChange = (e) => {
   
    setValue(e.target.value);
  };

  const ReviewFun = () => {
    setReviewStatus(false)
    window.scrollTo(0, 0)
  };
  const submitFun = async() => {
    // @todo username should be changed

    const submitPayload = {
    username:user,
    userId:initPayload.id,
    status:2,
    response_json:{
      answers:Object.values(payload)
    },
    last_update_date:new Date().toISOString().slice(0, 10),
    engage_step_id:1,
    }


    // @todo should succesfully completed out here
    await RestService.postEngageStepUserResponses(submitPayload)
    .then((response) =>{
      if(response.status === 200) {
        window.scrollTo(0, 0)
        props.submitTrigger("2");
      }
    })
  }
  return (
    <Spin tip="Loading..." spinning={loading} size="large">
      <div className="content engage-page tab-style finish">
        <div className="card-chart p2 card border-0 ">
          <div className="card-body bg-engage-100">
            {/* <Row className="">
              <Col xs="12" className="">
                
              </Col>
            </Row> */}
            <Row className="pt-4">
              <Col xs="12">
                <Card className="bg-engage border-0 rounded-0 bg-engage-100">
                  <CardBody className="ml-5 mt-5 mb-5 description-images-60">
                  <h5> LAB DESCRIPTION </h5>
                    <hr className="hr-dark" />
                    <div dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div>

                    {
                      initPayload?.guideline !== undefined && (
                        <>
                            <br/>
                            <h5> LAB GUIDELINE </h5>
                            <hr className="hr-dark" />
                            <div dangerouslySetInnerHTML={{ __html: initPayload?.guideline }}></div>

                        </>
                      )
                    }
                   {/* <div class="flab" dangerouslySetInnerHTML={ { __html: initPayload?.description }  }></div> */}
                    {/* <h5> Lab Description</h5>
                    <p className="mt-2 mb-2">
                      Graphs provide a quick way to view lots of data at once
                    </p>
                    <Row>
                      <Col xs="3">
                        <p className="mt-2 mb-2 graph-description">
                          The <b>x-axis</b> goes across the graph.
                        </p>
                        <Card className="border-0 rounded-0  bg-transparent">
                          <CardImg
                            alt="X-Axis Image"
                            src={x_axis}
                            className="graph p-3"
                          />
                        </Card>
                      </Col>
                      <Col xs="3">
                        <p className="mt-2 mb-2 graph-description">
                          The <b>y-axis</b> goes across the graph.
                        </p>
                        <Card className="border-0 rounded-0  bg-transparent">
                          <CardImg
                            alt="Y-Axis Image"
                            src={y_axis}
                            className="graph p-3"
                          />
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="7">
                        <p>
                          The data you collect show up on graphs as marks. Each
                          mark represents a value on the y-axis (directly across
                          from the mark) and a value on the x-axis (directly
                          under the mark). Slope is just word for how steep a
                          line is. Steeper slopes go up faster. They have larger
                          slopes.
                        </p>
                        <br />
                        <p>
                          In this experiment, you take data by clicking on a
                          moving person. The distance moved shows on the y-axis
                          (up ↑). The time of measurement shows on the x-axis
                          (across →).
                        </p>
                      </Col>
                      <Col xs="3">
                        <Card className="border-0 rounded-0  bg-transparent">
                          <CardImg
                            alt="Graph"
                            src={graph}
                            className="graph p-3"
                          />
                        </Card>
                      </Col>
                    </Row> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <Row className="">
              <Col xs="12">
                <Card className="bg-engage border-0 rounded-0 bg-engage-100">
                  <CardBody className="ml-5 mt-5 mb-5">
                    <h5> Questions</h5>
                    <hr className="hr-dark" />
                    <Row>
                      <Col xs="12">
                         <AnswerDisplay  data ={
                          {
                            type :1,
                            question:initPayload.engageStepQuestions,
                            answers:initPayload.engageStepQuestionAnswers,
                            preData:containedPayload,
                            getData:sendPayload,
                            className:"",
                            reviewStatus:false,
                            subtype:0
                          }
                         } />
                        {/* <Answers question={initPayload.engageStepQuestions} answers={initPayload.engageStepQuestionAnswers} preData={containedPayload} getData={sendPayload}/> */}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Row className="button-center p2">
              {reviewStatus ? (
                <button onClick={ReviewFun} type="primary" class="btn btn-primary btn-lg mt-50">
                Review
              </button>
              ) : (
                <button onClick={submitFun} type="primary" class="btn btn-primary btn-lg mt-50">
                Proceed to Explore
              </button>
              )}
            </Row> */}
            {/* <Row>
              <div className="unmarked-answer px-4 pt-1">
                  <label className="custom-radio-btn">
                    <Input type="radio" name="sample" checked />
                    <span className="checkmark"></span>
                  </label>
                <span className="p-proprty pl-1">Wrong Answer</span>
              </div>
            </Row>
            <br/>
            <Row>
              <div className="wrong-answer px-4 pt-1">
                  <label className="result-radio-btn">
                    <Input type="radio" name="sample" checked />
                    <span className="checkmark">X</span>
                  </label>
                <span className="p-proprty pl-1">Wrong Answer</span>
              </div>
            </Row>
            <br />
            <Row>
            <div className="success-answer px-4 pt-1">
              <label className="result-radio-btn ">
                <Input type="radio" name="sample2" Checked />
                <span className="checkmark">✓</span>
              </label>
              <span className="p-proprty pl-1">Wrong Answer</span>
              </div>
            </Row> */}
          </div>
        </div>
      </div>
      <br />
      <div>
        <Row>
          <Col md="6">
            <Button
              type="primary"
              className="mb20 btn btn-info"
              style={{padding: "14px 31px"}}
              onClick={() =>{
                props.onChangex()
              }}
            >
              {"Back"}
            </Button>
          </Col>
          <Col>

          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default Finish;
