import { useState } from "react";
import { Button } from "reactstrap";


function PdfDownload(props) {

  function downloadPdf() {
    if (props.from === "student") {

      if (props.headingData.btnStatus === "Extend") {
        generatePDF2(props.headingData.btnStatus, props.data.body, props.data.bodyCount)
      } else {
        generatePDF(props.headingData.btnStatus, props.data.body, props.data.bodyCount, props.data.graph ? props.data.graph : null, props.data.label)
      }
    } else {
      if (props.headingData.btnStatus === "Extend") {
        generatePDF4(props.headingData.btnStatus, props.data.body, props.data.bodyCount)
      } else {
        generatePDF3(props.headingData.btnStatus, props.data.body, props.data.bodyCount, props.data.graph ? props.data.graph : null, props.data.label)
      }
    }

  }

  //student explain
  const generatePDF = (cameFrom, body, bodyCount, graph, label) => {
    let bodyPayload = [];
    for (let i = 0; i <= bodyCount; i++) {
      let divElement = document.getElementById(i + body);
      if (divElement) {
        let QuestionAnswer = []
        let divContent = divElement.cloneNode(true);
        let textareaElement = divContent.querySelector('textarea');

        // Remove textarea element from the cloned div
        if (textareaElement) {
          textareaElement.remove();
        }

        let divTextContent = divContent.textContent.trim();
        const header = divTextContent.split(":");

        QuestionAnswer.push(header[0])
        QuestionAnswer.push(header[1])

        let textareaValue = textareaElement ? textareaElement.value : null;
        if (textareaValue !== null) {
          QuestionAnswer.push(textareaValue)
        }

        bodyPayload.push({ [`${i}`]: QuestionAnswer })
      }
    }
    const headerContent1 = document.getElementById('header1info');
    const headerContent2 = document.getElementById('header2info');
    const headerContent3 = document.getElementById('header3info');
    const headerContent4 = document.getElementById('header4info');

    const descriptionContent1 = document.getElementById(`description1${cameFrom}`);
    const descriptionContent2 = document.getElementById(`description2${cameFrom}`);

    const guidelineContent1 = document.getElementById(`guideline1${cameFrom}`);
    const guidelineContent2 = document.getElementById(`guideline2${cameFrom}`);

    const hypoContent1 = document.getElementById('hypo1');
    const hypoContent2 = document.getElementById('hypo2');

    const InstructorHeaderContent = document.getElementById(`InstructorHeader${cameFrom}`);
    const InstructorContentContent = document.getElementById(`InstructorContent${cameFrom}`);

    const GraphWordTextContent = document.getElementById('GraphWordText');

    // image
    const container = document.getElementById(graph);
    // Find the canvas element within the container div
    const canvas = container.querySelector('canvas');

    let dataURL = ""
    // Convert the canvas content to a data URL
    if (canvas) {
      dataURL = canvas.toDataURL();
    }

    const legends = props.data.legends;

    // Prepare the PDF content
    const content = `
      <html>
        <head>
        <title>${`${props.headingData.lessonName}-${props.headingData.btnStatus}`}</title>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9" crossorigin="anonymous">
         <style>


         p {
           color: #474747;
           max-width: 100%; /* Set your desired maximum width */
           word-wrap: break-word; /* or use 'overflow-wrap: break-word;' for better browser support */
         }
         
         .title {
           font-size: 24px;
           font-weight: 700;
           color: #252424;
         }
         
         .subtitle {
           font-size: 16px;
           font-weight: 600;
           color: #252424;
         }
         
         .question-title {
           font-weight: 600;
         }
         
         .date {
           color: #252424;
         }
         
         .divider {
           border-top: 1px solid #252424;
           margin: 20px 0;
         }
         
         .divider-md {
           border-top: 1px solid #252424;
           margin: 20px 0 5px 0;
         }
         
         .ordered-list-style {
           list-style-position: inside;
           margin: 0 !important;
           padding: 0 !important;
         }
         
         .unordered-list-style {
           margin-top: 40px;
         }
         
         .divider-light {
           border-top: 1px solid #252424;
           margin: 10px 0;
           opacity: 0.5;
         }
         
         .pdf-answer {
           border-left: 5px solid #474747;
           padding-left: 15px;
           text-align: justify;
         }
         
         .pdf-comment {
           border: 5px solid #474747;
           padding-left: 15px;
           text-align: justify;
         }
         
         .pdf-marks {
           margin: 20px 0;
         }
         
         .answer-text,
         .teacher-comment {
           font-size: 17px;
           font-weight: 900;
           padding-bottom: 5px;
           text-align: justify;
         }
         /* Styling for a button similar to Bootstrap's primary button */
           .custom-button {
             display: inline-block;
             font-weight: 400;
             text-align: center;
             vertical-align: middle;
             user-select: none;
             background-color: #000000;
             border: 1px solid transparent;
             padding: 0.375rem 2rem;
             font-size: 1rem;
             line-height: 1.5;
             border-radius: 0.25rem;
             transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
               box-shadow 0.15s ease-in-out;
             color: #fff;
             cursor: pointer;
           }

           .custom-button:hover {
             background-color: #0056b3;
             border-color: #0056b3;
           }

           .custom-button:focus {
             box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
             outline: 0;
           }

           /* Optional: Add more styles for different states (active, disabled, etc.) */
           .custom-button:active {
             background-color: #004499;
             border-color: #003366;
           }

           .custom-button:disabled {
             background-color: #e9ecef;
             border-color: #e9ecef;
             color: #6c757d;
             cursor: not-allowed;
           }
           .float-right-bottom {
             position: fixed;
             top: 20px;
             right: 20px;
             z-index: 99999999999999999999999
           }
           .distance {
             writing-mode: vertical-lr;
             position: absolute;
             height: 300px;
             text-align: center;
             left: -10px;
             transform: rotate(180deg);
           }

           .graph-labels{
            padding: 3px 6px; 
            margin:6px 6px !important;
            border-radius: 5px; 
            margin: 0 10px; /* Adds left and right margin to separate divs */
            display: inline-block; /* Display divs in the same row */
            // inline-block !important; 
            -webkit-print-color-adjust: exact !important;
           }
           .se-table-layout-fixed tbody, td, tfoot, th, thead, tr{
            border-width:1px !important;
          }
          .se-table-layout-fixed{
           width:100% !important;
          }
          .se-table-layout-fixed td {
           padding: 5px !important;
         }
         .se-table-layout-fixed tr th {
           background-color: #54585d !important; 
           color: #ffffff !important;
           font-weight: bold !important;
           font-size: 13px !important;
           border: 2px solid #979a9e !important;
           padding: 15px;
         }

           @media print {
             @page {
               margin-top: 30px;
           }
             body {
                 padding-top: 36px;
                 padding-bottom: 36px ;
             }
         }
         </style>
        </head>
        <body>
        <!-- <button onclick="window.print()" class="custom-button float-right-bottom">Save PDF </button> -->
        <center>
            <span class="title"> ${headerContent1.innerHTML}</span> <br />
            <span class="subtitle"> ${headerContent2.innerHTML} </span>
            <br />
            <span class="date"> ${headerContent3.innerHTML} </span> <br />
            <span class="subtitle">
              ${headerContent4.innerHTML}
            </span>
          </center>
          <hr class="divider" />
          ${canvas ? `
          <div>
            <p>
              <center>
              ${GraphWordTextContent.innerHTML}  ${label.y ? label.y : ""} ${label.y ? "-" : ""} ${label.x ? label.x : ""} 
              </center>
              </p>
              <div>

              ${legends && legends.map((item, id) => {
      const text = item.name;
      const cssText = `background-color: ${item.color}; color: black;`;
      return (`
                    <div style='${cssText}' class="graph-labels" key=${id}>${text}</div>
                  `)
    }).join('')}

              </div>
              <br>
              <p class="distance">
                ${label.y ? label.y : ""}
              </p>
              <img src=${dataURL} alt="Canvas Image" style="margin-left:30px">
              <p>
              <center> 
              ${label.x ? label.x : ""}
              </center>
            </p>
          </div>
        ` : ""}
          <p>
            <span class="title">${descriptionContent1 ? descriptionContent1.innerHTML : ""}</span>
            <br />
            <hr class="divider" />
            ${descriptionContent2 ? descriptionContent2.innerHTML : ""}
          </p>

          <p>
          <span class="title">${guidelineContent1 ? guidelineContent1.innerHTML : ""}</span>
          <br />
          <hr class="divider-md" />
          ${guidelineContent2 ? guidelineContent2.innerHTML : ""}
          </p>
          
          <p>
          <span class="title">${hypoContent1.innerHTML}</span>
          <br />
          <hr class="divider-md" />
          ${hypoContent2.innerHTML}
          </p>

          <p>
          ${bodyPayload.map((item, index) => {
      const va = item[index];

      return (`
                    <p class="question-title">${va[0] && va[0]}</p>
                    <ul>
                      ${va[1] ? va[1].split(/[?.]/).filter(q => q.trim() !== '').map(q => `
                        <li>${q.trim()}?</li>
                      `).join('') : ''}
                    </ul>
                    <div class="pdf-answer">
                    <p >
                      ${va[2] ? va[2] : 'Not answered'}
                    </p>
                  </div>
              `)
    })
      }
          </p>
          ${InstructorHeaderContent ? `<div class="pdf-comment">
            <p><b>
            ${InstructorHeaderContent.innerHTML}
            </b>
            </p>
              <p>
                ${InstructorContentContent.innerHTML}
              </p>
            </div>`
        : ""
      }
          




          
        </body>
      </html>
    `;

    // Create a new window for the PDF
    const pdfWindow = window.open('___', '_blank', 'toolbar=no,location=no,status=no,menubar=no');
    pdfWindow.document.open();
    pdfWindow.document.write(content);
    pdfWindow.document.close();
    // Automatically trigger the print dialog
    pdfWindow.onload = function () {
      pdfWindow.print();
    };

    // Close the page if the print dialog is closed (Cancel button is clicked)
    pdfWindow.addEventListener('afterprint', function () {
      pdfWindow.close();
    });
  };

  //student extend
  const generatePDF2 = (cameFrom, body, bodyCount) => {
    let bodyPayload = [];
    for (let i = 0; i <= bodyCount; i++) {
      let divElement = document.getElementById(i + body);
      if (divElement) {
        let QuestionAnswer = []
        let divContent = divElement.cloneNode(true);
        let textareaElement = divContent.querySelector('textarea');

        // Remove textarea element from the cloned div
        if (textareaElement) {
          textareaElement.remove();
        }

        let divTextContent = divContent.textContent.trim();
        const header = divTextContent.split(":");

        QuestionAnswer.push(header[0])
        QuestionAnswer.push(header[1])


        let textareaValue = textareaElement ? textareaElement.value : null;
        if (textareaValue !== null) {
          QuestionAnswer.push(textareaValue)
        }

        bodyPayload.push({ [`${i}`]: QuestionAnswer })
      }
    }

    const headerContent1 = document.getElementById('header1info');
    const headerContent2 = document.getElementById('header2info');
    const headerContent3 = document.getElementById('header3info');
    const headerContent4 = document.getElementById('header4info');

    const descriptionContent1 = document.getElementById(`description1${cameFrom}`);
    const descriptionContent2 = document.getElementById(`description2${cameFrom}`);

    const guidelineContent1 = document.getElementById(`guideline1${cameFrom}`);
    const guidelineContent2 = document.getElementById(`guideline2${cameFrom}`);

    const InstructorHeaderContent = document.getElementById(`InstructorHeader${cameFrom}`);
    const InstructorContentContent = document.getElementById(`InstructorContent${cameFrom}`);

    // Prepare the PDF content
    const content = `
      <html>
        <head>
        <title>${`${props.headingData.lessonName}-${props.headingData.btnStatus}`}</title>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9" crossorigin="anonymous">
         <style>


         p {
           color: #474747;
           max-width: 100%; /* Set your desired maximum width */
           word-wrap: break-word; /* or use 'overflow-wrap: break-word;' for better browser support */
         }
         
         .title {
           font-size: 24px;
           font-weight: 700;
           color: #252424;
         }
         
         .subtitle {
           font-size: 16px;
           font-weight: 600;
           color: #252424;
         }
         
         .question-title {
           font-weight: 600;
         }
         
         .date {
           color: #252424;
         }
         
         .divider {
           border-top: 1px solid #252424;
           margin: 20px 0;
         }
         
         .divider-md {
           border-top: 1px solid #252424;
           margin: 20px 0 5px 0;
         }
         
         .ordered-list-style {
           list-style-position: inside;
           margin: 0 !important;
           padding: 0 !important;
         }
         
         .unordered-list-style {
           margin-top: 40px;
         }
         
         .divider-light {
           border-top: 1px solid #252424;
           margin: 10px 0;
           opacity: 0.5;
         }
         
         .pdf-answer {
           border-left: 5px solid #474747;
           padding-left: 15px;
           text-align: justify;
         }
         
         .pdf-comment {
           border: 5px solid #474747;
           padding-left: 15px;
           text-align: justify;
         }
         
         .pdf-marks {
           margin: 20px 0;
         }
         
         .answer-text,
         .teacher-comment {
           font-size: 17px;
           font-weight: 900;
           padding-bottom: 5px;
           text-align: justify;
         }
         /* Styling for a button similar to Bootstrap's primary button */
           .custom-button {
             display: inline-block;
             font-weight: 400;
             text-align: center;
             vertical-align: middle;
             user-select: none;
             background-color: #000000;
             border: 1px solid transparent;
             padding: 0.375rem 2rem;
             font-size: 1rem;
             line-height: 1.5;
             border-radius: 0.25rem;
             transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
               box-shadow 0.15s ease-in-out;
             color: #fff;
             cursor: pointer;
           }

           .custom-button:hover {
             background-color: #0056b3;
             border-color: #0056b3;
           }

           .custom-button:focus {
             box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
             outline: 0;
           }

           /* Optional: Add more styles for different states (active, disabled, etc.) */
           .custom-button:active {
             background-color: #004499;
             border-color: #003366;
           }

           .custom-button:disabled {
             background-color: #e9ecef;
             border-color: #e9ecef;
             color: #6c757d;
             cursor: not-allowed;
           }
           .float-right-bottom {
             position: fixed;
             top: 20px;
             right: 20px;
             z-index: 99999999999999999999999
           }
           .distance {
             writing-mode: vertical-lr;
             position: absolute;
             height: 300px;
             text-align: center;
             left: -10px;
             transform: rotate(180deg);
           }
           .se-table-layout-fixed tbody, td, tfoot, th, thead, tr{
            border-width:1px !important;
          }
          .se-table-layout-fixed{
           width:100% !important;
          }
          .se-table-layout-fixed td {
           padding: 5px !important;
         }
         .se-table-layout-fixed tr th {
           background-color: #54585d !important; 
           color: #ffffff !important;
           font-weight: bold !important;
           font-size: 13px !important;
           border: 2px solid #979a9e !important;
           padding: 15px;
         }
           @media print {
             @page {
               margin-top: 30px;
           }
             body {
                 padding-top: 36px;
                 padding-bottom: 36px ;
             }
         }
         </style>
        </head>
        <body>
        <!-- <button onclick="window.print()" class="custom-button float-right-bottom">Save PDF </button> -->
        <center>
            <span class="title"> ${headerContent1.innerHTML}</span> <br />
            <span class="subtitle"> ${headerContent2.innerHTML} </span>
            <br />
            <span class="date"> ${headerContent3.innerHTML} </span> <br />
            <span class="subtitle">
              ${headerContent4.innerHTML}
            </span>
          </center>
          <p>
            <span class="title">${descriptionContent1 ? descriptionContent1.innerHTML : ""}</span>
            <br />
            <hr class="divider" />
            ${descriptionContent2 ? descriptionContent2.innerHTML : ""}
          </p>

          <p>
          <span class="title">${guidelineContent1 ? guidelineContent1.innerHTML : ""}</span>
          <br />
          <hr class="divider-md" />
          ${guidelineContent2 ? guidelineContent2.innerHTML : ""}
          </p>

          <p>
          ${bodyPayload.map((item, index) => {
      const va = item[index];
      return (`
                    <p class="question-title">${va[0] && va[0]}</p>
                    <ul>
                      ${va[1] ? va[1].split(/[?.]/).filter(q => q.trim() !== '').map(q => `
                        <li>${q.trim()}?</li>
                      `).join('') : ''}
                    </ul>
                    <div class="pdf-answer">
                    <p >
                      ${va[2] ? va[2] : 'Not answered'}
                    </p>
                  </div>
              `)
    })
      }
          </p>
          <br>

          ${InstructorHeaderContent ? `<div class="pdf-comment">
            <p><b>${InstructorHeaderContent.innerHTML}</b></p>
              <p>
                ${InstructorContentContent.innerHTML}
              </p>
            </div>`
        : ""
      }
          
          
        </body>
      </html>
    `;


    // Create a new window for the PDF
    const pdfWindow = window.open('___', '_blank', 'toolbar=no,location=no,status=no,menubar=no');
    pdfWindow.document.open();
    pdfWindow.document.write(content);
    pdfWindow.document.close();

    // Automatically trigger the print dialog
    pdfWindow.onload = function () {
      pdfWindow.print();
    };

    // Close the page if the print dialog is closed (Cancel button is clicked)
    pdfWindow.addEventListener('afterprint', function () {
      pdfWindow.close();
    });
  };

  //teacher explain
  const generatePDF3 = (cameFrom, body, bodyCount, graph, label) => {
    let bodyPayload = [];
    for (let i = 0; i <= bodyCount; i++) {
      let divElement = document.getElementById(i + body);
      if (divElement) {
        let QuestionAnswer = []
        let divContent = divElement.cloneNode(true);
        let textareaElement = divContent.querySelector('textarea');

        // Remove textarea element from the cloned div
        if (textareaElement) {
          textareaElement.remove();
        }

        let divTextContent = divContent.textContent.trim();
        const header = divTextContent.split(":");

        QuestionAnswer.push(header[0])
        QuestionAnswer.push(header[1])



        let textareaValue = textareaElement ? textareaElement.value : null;
        if (textareaValue !== null) {
          QuestionAnswer.push(textareaValue)
        }

        bodyPayload.push({ [`${i}`]: QuestionAnswer })
      }
    }
    const headerContent1 = document.getElementById('header1info');
    const headerContent2 = document.getElementById('header2info');
    const headerContent4 = document.getElementById('header4info');

    const descriptionContent1 = document.getElementById(`description1${cameFrom}`);
    const descriptionContent2 = document.getElementById(`description2${cameFrom}`);

    const guidelineContent1 = document.getElementById(`guideline1${cameFrom}`);
    const guidelineContent2 = document.getElementById(`guideline2${cameFrom}`);

    const hypoContent1 = document.getElementById('hypo1');
    const hypoContent2 = document.getElementById('hypo2');

    const scoreContent = document.getElementById(`score${cameFrom}`);

    const InstructorHeaderContent = document.getElementById(`InstructorHeader${cameFrom}`);
    const InstructorContentContent = document.getElementById(`InstructorContent${cameFrom}`);

    const GraphWordTextContent = document.getElementById('GraphWordText');
    const xLabel = document.getElementById('xLabelWord');
    const labelX = xLabel.textContent || xLabel.innerText;
    const yLabel = document.getElementById('yLabelWord');
    const labelY = yLabel.textContent || yLabel.innerText;


    // image
    const container = document.getElementById(graph);
    // Find the canvas element within the container div
    const canvas = container.querySelector('canvas');

    let dataURL = ""
    // Convert the canvas content to a data URL
    if (canvas) {
      dataURL = canvas.toDataURL();
    }

    const legends = JSON.parse(localStorage.getItem('labelLegends'));

    // Prepare the PDF content
    const content = `
      <html>
        <head>
        <title>${`${props.headingData.lessonName}-${props.headingData.btnStatus}`}</title>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9" crossorigin="anonymous">
         <style>


         p {
           color: #474747;
           max-width: 100%; /* Set your desired maximum width */
           word-wrap: break-word; /* or use 'overflow-wrap: break-word;' for better browser support */
         }
         
         .title {
           font-size: 24px;
           font-weight: 700;
           color: #252424;
         }
         
         .subtitle {
           font-size: 16px;
           font-weight: 600;
           color: #252424;
         }
         
         .question-title {
           font-weight: 600;
         }
         
         .date {
           color: #252424;
         }
         
         .divider {
           border-top: 1px solid #252424;
           margin: 20px 0;
         }
         
         .divider-md {
           border-top: 1px solid #252424;
           margin: 20px 0 5px 0;
         }
         
         .ordered-list-style {
           list-style-position: inside;
           margin: 0 !important;
           padding: 0 !important;
         }
         
         .unordered-list-style {
           margin-top: 40px;
         }
         
         .divider-light {
           border-top: 1px solid #252424;
           margin: 10px 0;
           opacity: 0.5;
         }
         
         .pdf-answer {
           border-left: 5px solid #474747;
           padding-left: 15px;
           text-align: justify;
         }
         
         .pdf-comment {
           border: 5px solid #474747;
           padding-left: 15px;
           text-align: justify;
         }
         
         .pdf-marks {
           margin: 20px 0;
         }
         
         .answer-text,
         .teacher-comment {
           font-size: 17px;
           font-weight: 900;
           padding-bottom: 5px;
           text-align: justify;
         }
         /* Styling for a button similar to Bootstrap's primary button */
           .custom-button {
             display: inline-block;
             font-weight: 400;
             text-align: center;
             vertical-align: middle;
             user-select: none;
             background-color: #000000;
             border: 1px solid transparent;
             padding: 0.375rem 2rem;
             font-size: 1rem;
             line-height: 1.5;
             border-radius: 0.25rem;
             transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
               box-shadow 0.15s ease-in-out;
             color: #fff;
             cursor: pointer;
           }

           .custom-button:hover {
             background-color: #0056b3;
             border-color: #0056b3;
           }

           .custom-button:focus {
             box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
             outline: 0;
           }

           /* Optional: Add more styles for different states (active, disabled, etc.) */
           .custom-button:active {
             background-color: #004499;
             border-color: #003366;
           }

           .custom-button:disabled {
             background-color: #e9ecef;
             border-color: #e9ecef;
             color: #6c757d;
             cursor: not-allowed;
           }
           .float-right-bottom {
             position: fixed;
             top: 20px;
             right: 20px;
             z-index: 99999999999999999999999
           }
           .distance {
             writing-mode: vertical-lr;
             position: absolute;
             height: 300px;
             text-align: center;
             left: -10px;
             transform: rotate(180deg);
           }

           .graph-labels{
            padding: 3px 6px; 
            margin:6px 6px !important;
            border-radius: 5px; 
            margin: 0 10px; /* Adds left and right margin to separate divs */
            display: inline-block; /* Display divs in the same row */
            // inline-block !important; 
            -webkit-print-color-adjust: exact !important;
           }
           .se-table-layout-fixed tbody, td, tfoot, th, thead, tr{
            border-width:1px !important;
          }
          .se-table-layout-fixed{
           width:100% !important;
          }
          .se-table-layout-fixed td {
           padding: 5px !important;
         }
         .se-table-layout-fixed tr th {
           background-color: #54585d !important; 
           color: #ffffff !important;
           font-weight: bold !important;
           font-size: 13px !important;
           border: 2px solid #979a9e !important;
           padding: 15px;
         }

           @media print {
             @page {
               margin-top: 30px;
           }
             body {
                 padding-top: 36px;
                 padding-bottom: 36px ;
             }
         }
         </style>
        </head>
        <body>
        <!-- <button onclick="window.print()" class="custom-button float-right-bottom">Save PDF </button> -->
        <center>
            <span class="title"> ${headerContent1.innerHTML}</span> <br />
            <span class="subtitle"> ${headerContent2.innerHTML} </span>
            <br />
            <span class="subtitle">
              ${headerContent4.innerHTML}
            </span>
          </center>
          <hr class="divider" />
          ${canvas ? `
          <p>
        <center>
          ${GraphWordTextContent.innerHTML}  ${labelY ? labelY : ""} ${labelY ? "-" : ""} ${labelX ? labelX : ""} 
        </center>
          </p>
          <div>

              ${legends && legends.map((item, id) => {
      const text = item.name;
      const cssText = `background-color: ${item.color}; color: black;`;
      return (`
                    <div style='${cssText}' class="graph-labels" key=${id}>${text}</div>
                  `)
    }).join('')}

          </div>
          <br>
          <p class="distance">
            ${labelY ? labelY : ""}
          </p>
          <img src=${dataURL} alt="Canvas Image" style="margin-left:30px">
          <p>
          <center>
            ${labelX ? labelX : ""}
            </center>
          </p>
          </div>
        ` : ""}
          <p>
            <span class="title">${descriptionContent1 ? descriptionContent1.innerHTML : ""}</span>
            <br />
            <hr class="divider" />
            ${descriptionContent2 ? descriptionContent2.innerHTML : ""}
          </p>

          <p>
          <span class="title">${guidelineContent1 ? guidelineContent1.innerHTML : ""}</span>
          <br />
          <hr class="divider-md" />
          ${guidelineContent2 ? guidelineContent2.innerHTML : ""}
          </p>
          
          <p>
          <span class="title">${hypoContent1.innerHTML}</span>
          <br />
          <hr class="divider-md" />
          ${hypoContent2.innerHTML}
          </p>

          <p>
          ${bodyPayload.map((item, index) => {
      const va = item[index];

      return (`
                    <p class="question-title">${va[0] && va[0]}</p>
                    <ul>
                      ${va[1] ? va[1].split(/[?.]/).filter(q => q.trim() !== '').map(q => `
                        <li>${q.trim()}?</li>
                      `).join('') : ''}
                    </ul>
                    <div class="pdf-answer">
                    <p >
                      ${va[2] ? va[2] : 'Not answered'}
                    </p>
                  </div>
              `)
    })
      }
          </p>
          <br />
          <div class="pdf-comment">
            <p><b>
            Score
            </b>
            </p>
              <p>
                ${scoreContent.value}
              </p>
          </div>
            <br />

          ${InstructorHeaderContent ? `<div class="pdf-comment">
            <p>
            <b>
            ${InstructorHeaderContent.innerHTML}
            </b>
            </p>
              <p>
                ${InstructorContentContent.innerHTML !== "" ? InstructorContentContent.innerHTML : "Not yet given"}
              </p>
            </div>`
        : ""
      }
          

          <br>


          
        </body>
      </html>
    `;


    // Create a new window for the PDF
    const pdfWindow = window.open('___', '_blank', 'toolbar=no,location=no,status=no,menubar=no');
    pdfWindow.document.open();
    pdfWindow.document.write(content);
    pdfWindow.document.close();
    // Automatically trigger the print dialog
    pdfWindow.onload = function () {
      pdfWindow.print();
    };

    // Close the page if the print dialog is closed (Cancel button is clicked)
    pdfWindow.addEventListener('afterprint', function () {
      pdfWindow.close();
    });
  };

  // teacher extend
  const generatePDF4 = (cameFrom, body, bodyCount) => {
    let bodyPayload = [];
    for (let i = 0; i <= bodyCount; i++) {
      let divElement = document.getElementById(i + body);
      if (divElement) {
        let QuestionAnswer = []
        let divContent = divElement.cloneNode(true);
        let textareaElement = divContent.querySelector('textarea');

        // Remove textarea element from the cloned div
        if (textareaElement) {
          textareaElement.remove();
        }

        let divTextContent = divContent.textContent.trim();
        const header = divTextContent.split(":");

        QuestionAnswer.push(header[0])
        QuestionAnswer.push(header[1])


        let textareaValue = textareaElement ? textareaElement.value : null;
        if (textareaValue !== null) {
          QuestionAnswer.push(textareaValue)
        }

        bodyPayload.push({ [`${i}`]: QuestionAnswer })
      }
    }

    const headerContent1 = document.getElementById('header1info');
    const headerContent2 = document.getElementById('header2info');
    const headerContent4 = document.getElementById('header4info');

    const descriptionContent1 = document.getElementById(`description1${cameFrom}`);
    const descriptionContent2 = document.getElementById(`description2${cameFrom}`);

    const guidelineContent1 = document.getElementById(`guideline1${cameFrom}`);
    const guidelineContent2 = document.getElementById(`guideline2${cameFrom}`);

    const scoreContent = document.getElementById(`score${cameFrom}`);

    const InstructorHeaderContent = document.getElementById(`InstructorHeader${cameFrom}`);
    const InstructorContentContent = document.getElementById(`InstructorContent${cameFrom}`);

    // Prepare the PDF content
    const content = `
      <html>
        <head>
        <title>${`${props.headingData.lessonName}-${props.headingData.btnStatus}`}</title>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9" crossorigin="anonymous">
         <style>


         p {
           color: #474747;
           max-width: 100%; /* Set your desired maximum width */
           word-wrap: break-word; /* or use 'overflow-wrap: break-word;' for better browser support */
         }
         
         .title {
           font-size: 24px;
           font-weight: 700;
           color: #252424;
         }
         
         .subtitle {
           font-size: 16px;
           font-weight: 600;
           color: #252424;
         }
         
         .question-title {
           font-weight: 600;
         }
         
         .date {
           color: #252424;
         }
         
         .divider {
           border-top: 1px solid #252424;
           margin: 20px 0;
         }
         
         .divider-md {
           border-top: 1px solid #252424;
           margin: 20px 0 5px 0;
         }
         
         .ordered-list-style {
           list-style-position: inside;
           margin: 0 !important;
           padding: 0 !important;
         }
         
         .unordered-list-style {
           margin-top: 40px;
         }
         
         .divider-light {
           border-top: 1px solid #252424;
           margin: 10px 0;
           opacity: 0.5;
         }
         
         .pdf-answer {
           border-left: 5px solid #474747;
           padding-left: 15px;
           text-align: justify;
         }
         
         .pdf-comment {
           border: 5px solid #474747;
           padding-left: 15px;
           text-align: justify;
         }
         
         .pdf-marks {
           margin: 20px 0;
         }
         
         .answer-text,
         .teacher-comment {
           font-size: 17px;
           font-weight: 900;
           padding-bottom: 5px;
           text-align: justify;
         }
         /* Styling for a button similar to Bootstrap's primary button */
           .custom-button {
             display: inline-block;
             font-weight: 400;
             text-align: center;
             vertical-align: middle;
             user-select: none;
             background-color: #000000;
             border: 1px solid transparent;
             padding: 0.375rem 2rem;
             font-size: 1rem;
             line-height: 1.5;
             border-radius: 0.25rem;
             transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
               box-shadow 0.15s ease-in-out;
             color: #fff;
             cursor: pointer;
           }

           .custom-button:hover {
             background-color: #0056b3;
             border-color: #0056b3;
           }

           .custom-button:focus {
             box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
             outline: 0;
           }

           /* Optional: Add more styles for different states (active, disabled, etc.) */
           .custom-button:active {
             background-color: #004499;
             border-color: #003366;
           }

           .custom-button:disabled {
             background-color: #e9ecef;
             border-color: #e9ecef;
             color: #6c757d;
             cursor: not-allowed;
           }
           .float-right-bottom {
             position: fixed;
             top: 20px;
             right: 20px;
             z-index: 99999999999999999999999
           }
           .distance {
             writing-mode: vertical-lr;
             position: absolute;
             height: 300px;
             text-align: center;
             left: -10px;
             transform: rotate(180deg);
           }
           .se-table-layout-fixed tbody, td, tfoot, th, thead, tr{
            border-width:1px !important;
          }
          .se-table-layout-fixed{
           width:100% !important;
          }
          .se-table-layout-fixed td {
           padding: 5px !important;
         }
         .se-table-layout-fixed tr th {
           background-color: #54585d !important; 
           color: #ffffff !important;
           font-weight: bold !important;
           font-size: 13px !important;
           border: 2px solid #979a9e !important;
           padding: 15px;
         }
           @media print {
             @page {
               margin-top: 30px;
           }
             body {
                 padding-top: 36px;
                 padding-bottom: 36px ;
             }
         }
         </style>
        </head>
        <body>
        <!-- <button onclick="window.print()" class="custom-button float-right-bottom">Save PDF </button> -->
        <center>
            <span class="title"> ${headerContent1.innerHTML}</span> <br />
            <span class="subtitle"> ${headerContent2.innerHTML} </span>
            <br />
            <span class="subtitle">
              ${headerContent4.innerHTML}
            </span>
          </center>
          <p>
            <span class="title">${descriptionContent1 ? descriptionContent1.innerHTML : ""}</span>
            <br />
            <hr class="divider" />
            ${descriptionContent2 ? descriptionContent2.innerHTML : ""}
          </p>

          <p>
          <span class="title">${guidelineContent1 ? guidelineContent1.innerHTML : ""}</span>
          <br />
          <hr class="divider-md" />
          ${guidelineContent2 ? guidelineContent2.innerHTML : ""}
          </p>

          <p>
          ${bodyPayload.map((item, index) => {
      const va = item[index];
      return (`
                    <p class="question-title">${va[0] && va[0]}</p>
                    <ul>
                      ${va[1] ? va[1].split(/[?.]/).filter(q => q.trim() !== '').map(q => `
                        <li>${q.trim()}?</li>
                      `).join('') : ''}
                    </ul>
                    <div class="pdf-answer">
                    <p >
                      ${va[2] ? va[2] : 'Not answered'}
                    </p>
                  </div>
              `)
    })
      }
          </p>

          <div class="pdf-comment">
          <b>
            <p>Score</p>
            </b>
              <p>
                ${scoreContent.value}
              </p>
          </div>
          <br>
          ${InstructorHeaderContent ? `<div class="pdf-comment">
            <p>
            <b>
            ${InstructorHeaderContent.innerHTML}
            </b>
            </p>
              <p>
                ${InstructorContentContent.innerHTML !== "" ? InstructorContentContent.innerHTML : "Not yet given"}
              </p>
            </div>`
        : ""
      }
          
          
        </body>
      </html>
    `;


    // Create a new window for the PDF
    const pdfWindow = window.open('___', '_blank', 'toolbar=no,location=no,status=no,menubar=no');
    pdfWindow.document.open();
    pdfWindow.document.write(content);
    pdfWindow.document.close();
    
    
    // Automatically trigger the print dialog
    pdfWindow.onload = function () {
      pdfWindow.print();
    };

    // Close the page if the print dialog is closed (Cancel button is clicked)
    pdfWindow.addEventListener('afterprint', function () {
      pdfWindow.close();
    });

    

  };


  return (
    <div><Button color="primary" className="mb-251 ml80" onClick={downloadPdf}><i className="fa fa-download"></i> <span className="pdf-text">Generate PDF</span></Button>
    </div>
  );
}

export default PdfDownload;




