import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  CardSubtitle,
  CardText,
  CardImg,
  UncontrolledTooltip,
} from "reactstrap";
import { Radio, Input, Form, Modal, Button, Tabs, Table, Row, Col, Space, Spin } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
// import img1 from "../../../assets/images/img1.jpg";

import { columns } from './data'

import moment, { isDate } from "moment";
import RestService from "../../../../api/RestService";
import { useHistory } from "react-router";
import jsonToken from 'jsonwebtoken'

function Organization(props) {
  let history = useHistory();

  const [dataSource, setDataSource] = useState([])
  const [searchResult, setsearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [searchValue, setSetSearchValue] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);

  useEffect(() => {
    setLoading(true);
    RestService.getSchoolData().then(res => {
      if (res.data?.length > 0) {
        setDataSource(res.data?.map((x, i) => {
          return {
            key: i,
            organization: x.organizationName,
            org_id: x.organizationId,
            instructors: x.instructorsCount,
            class: x.classesCount,
            student: x.studentsCount,
            lessons: x.lessonsCount,
            angIntro: x.engageAvg.toFixed(2),
            angQuiz: x.evaluateAvg.toFixed(2),
            angRpt: x.explainAvg.toFixed(2),
            angExt: x.extendAvg.toFixed(2),
            role: accessToken.roles[0]
          }
        }))
      } else {
        setDataSource([{
          key: "1",
          organization: res.data?.organizationName,
          org_id: res.data?.organizationId,
          instructor: res.data?.instructorsCount,
          class: res.data?.classesCount,
          student: res.data?.studentsCount,
          lessons: res.data?.lessonsCount,
          angIntro: res.data?.engageAvg.toFixed(2),
          angQuiz: res.data?.evaluateAvg.toFixed(2),
          angRpt: res.data?.explainAvg.toFixed(2),
          angExt: res.data?.extendAvg.toFixed(2),
          role: accessToken.roles[0]
        }])
      }
      setLoading(false)
    })

    // loadData();
  }, [])

  // function loadData(page = currentPage, pageSize = defaultPageSize) {
  //   setLoading(true);
  //   setDefaultPageSize(pageSize);
  //   RestService.getTeacherEvaluateDataPaged(page, pageSize)
  //     .then((response) => {
  //       if (response?.data) {
  //         if (response.data?.items.length > 0) {
  //           setDataSource(response.data?.items.map((x, i) => {
  //             return {
  //               key: i,
  //               organization: x.organizationName,
  //               org_id: x.organizationId,
  //               instructors: x.instructorsCount,
  //               class: x.classesCount,
  //               student: x.studentsCount,
  //               lessons: x.lessonsCount,
  //               angIntro: x.engageAvg.toFixed(2),
  //               angQuiz: x.evaluateAvg.toFixed(2),
  //               angRpt: x.explainAvg.toFixed(2),
  //               angExt: x.extendAvg.toFixed(2),
  //               role: accessToken.roles[0]
  //             }
  //           }))
  //           setTotal(response.data.totalItems);
  //           setCurrentPage(response.data.page);
  //         } else {
  //           setDataSource([{
  //             key: "1",
  //             organization: response.data?.items.organizationName,
  //             org_id: response.data?.items.organizationId,
  //             instructor: response.data?.items.instructorsCount,
  //             class: response.data?.items.classesCount,
  //             student: response.data?.items.studentsCount,
  //             lessons: response.data?.items.lessonsCount,
  //             angIntro: response.data?.items.engageAvg.toFixed(2),
  //             angQuiz: response.data?.items.evaluateAvg.toFixed(2),
  //             angRpt: response.data?.items.explainAvg.toFixed(2),
  //             angExt: response.data?.items.extendAvg.toFixed(2),
  //             role: accessToken.roles[0]
  //           }])
  //         }

  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }

  // useEffect(()=>{
  //   if(searchValue){
  //     const filteredData = dataSource.filter(item => item.organization.toLowerCase().includes(searchValue.toLowerCase()));
  //     setDataFiltered(filteredData)
  //   }else{
  //     setDataFiltered(dataSource)
  //   }
  // },[dataSource, searchValue]);

  return (
    <>
      <Spin tip="Loading..." spinning={loading} size="large">
        <div className="container-fluid">
          <Row>
            <Col span={12} offset={6}>
              <center>
                <h3>My Schools</h3>
              </center>
            </Col>
          </Row>
          <Row className="py-3 px-5">
            <Col span={8}>
              <Button icon={<ArrowLeftOutlined />} onClick={(e) => {
                e.preventDefault()
                history.push("/MyAccount")
              }} className='border-0'></Button>
              Go Back
            </Col>
            <Col span={8}>
              {/* <Input size="large" placeholder=" Search by Student Name" prefix={<SearchOutlined className="ml-3 mr-3" />} /> */}
                  <Input
                    size="large"
                    placeholder="Search"
                    onChange={(e) => { 
                      setSetSearchValue(e.target.value);
                      if (e.target.value !== "" && e.target.value.length > 0) {
                        const filteredData = dataSource.filter(entry =>
                          entry.organization.toString().toLowerCase().includes(e.target.value.toLowerCase())
                          )
                        setsearchResult(filteredData);
                      } else {
                        setsearchResult(dataSource)
                      }
                      }}
                    prefix={<SearchOutlined className="mx-3" />}
                  />
            </Col>
          </Row>
          <div className="px-5">
            <Row className="py-3">
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={searchValue?.length < 1 || searchValue?.length === undefined ? dataSource : searchResult}
                  pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                  // pagination={{
                  //   current: currentPage,
                  //   position: ["bottomCenter"],
                  //   total: total, // Total number of items in the API response (you may need to fetch this dynamically)
                  //   pageSize: defaultPageSize, // Number of items to display per page - by default
                  //   onChange: (page, pageSize) => loadData(page, pageSize), // Callback function to handle page changes
                  //   pageSizeOptions: ["10", "20", "30"], // Pagination options
                  //   showSizeChanger: true
                  // }}
                  className='thead-dark rl-button'
                />
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </>
  );
}

export default Organization;


