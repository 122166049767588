export const LoginCheckWithPages = (redirect = true) => {
    var url = window.location.href;
    if (url.includes("login")) {
        //LoginCheck(false);
        // window.history.pushState(null, null, window.location.href);
        // window.onpopstate = function (event) {
        //     window.history.go(1);
        // };
        LGclearStorage(false);
    } else {
        LoginCheck(redirect);
        var i = setInterval(function () {
            LoginCheck(redirect);
        }, 1000)
    }
}

export const LoginCheck = (redirect = true) => {
    let loggedin = sessionStorage.getItem('loggedin');
    let access_token = localStorage.getItem("access_token");
    const expirationTime = localStorage.getItem('expirationTime');
    if (expirationTime) {
        const currentTime = Date.now();
        if (currentTime > Number(expirationTime)) {
            LGclearStorage(redirect);
        }
    }
    if (!access_token || !expirationTime || loggedin === "false" || loggedin === false) {
        LGclearStorage(redirect);
    }
}

export const LGclearStorage = (redirect = true) => {
    sessionStorage.removeItem('loggedin');
    localStorage.removeItem('access_token');
    localStorage.removeItem('labelLegends');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('targetLanguage');
    localStorage.removeItem('notificationCount');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('className');
    localStorage.removeItem('userRoutes');
    localStorage.removeItem('lessonID');
    if (redirect) {
        loginRedirect();
    }
}

const loginRedirect = () => {
    const url = (window.location.href).split("#");
    const newWindow = window.open(`${url[0]}#/login`, "_self");
    newWindow.location.reload();
}
