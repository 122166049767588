import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  CardSubtitle,
  CardText,
  CardImg,
  UncontrolledTooltip,
} from "reactstrap";
import { Radio, Input, Form, Modal, Button, Tabs, Table, Row, Col, Space } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
// import img1 from "../../../assets/images/img1.jpg";

// import { columns } from './data'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GetClassesByOrganizationId } from "../../../../../api/TeacherService";
import moment, { isDate } from "moment";
import jsonToken from 'jsonwebtoken';

function ClassName(props) {
  const { TabPane } = Tabs;
  let history = useHistory();
  let Stname = useHistory();
  const location = useLocation();
  const [dataSource, setDataSource] = useState([]);
  const [searchResult, setSearchResult] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const {instructorId,classId,className,id,level} = useParams();

  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));

  async function ConsumeApi() {
    const responceData = await GetClassesByOrganizationId(id);

    const responce = await responceData.data;
    if (responce !== undefined || responce !== null) {

      setDataSource(responce);
      //  console.log(responce);
    } else {
      console.log("variable is NOT undefined or null");
    }

  }
  
  useEffect(() => {
    ConsumeApi();
  }, []);

  const getSearchTerm = (e) => {
    const currValue = e.target.value;
    setSearchTerm(currValue);
    if (currValue.length > 0) {
      const filteredData = dataSource.filter(entry =>
        entry.studentName.toString().toLowerCase().includes(currValue.toLowerCase()))
      setSearchResult(filteredData);
    } else {
      setSearchResult(dataSource)
    }
  }
  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      key: 'name',
      sorter: (a, b) => a.studentName.localeCompare(b.studentName),
      render: (_, record) => (
        <Space size="middle">
          {record.studentName} <Button
            type="danger" size='small'
            onClick={(e) => {
              e.preventDefault() 
              localStorage.setItem("EvaluationStudentStatus", record.studentSource)
              localStorage.setItem("EvaluationLMSState", record.lmsState)
              localStorage.setItem("studentName", record.studentName)
              history.push(`/Instructor/${instructorId}/Classes/${classId}/Student/${className}/${record.studentId}/${id}/${level}`)
            }}
          >Grade</Button>
        </Space>
      ),
    },
    {
      title: 'Student Average',
      dataIndex: 'studentAvg',
      key: 'studentAvg',
      sorter: (a, b) =>  a.studentAvg - b.studentAvg,
      render: (_, record) => (
        <Space size="middle">
          <small>{record.studentAvg !== null && record.studentAvg.toFixed(2)}</small>
        </Space>
      ),
    },
    {
      title: 'No. of Lessons',
      dataIndex: 'studentLessonsCount',
      key: 'noLesson',
      sorter: (a, b) => a.studentLessonsCount - b.studentLessonsCount,
    },
    {
      title: 'Date Created',
      dataIndex: 'dateCreated',
      key: 'created_date',
      sorter: (a, b) => {
        if (a.dateCreated === null || b.dateCreated === null) {
          return a.dateCreated === null ? 1 : -1; // null values will be pushed to the bottom
        }
        return a.dateCreated.localeCompare(b.dateCreated);
      },
      render: (_, record) => (
        
        <Space size="middle">
          <small>{record.dateCreated !== null && moment(record.dateCreated).utc().format('YYYY-MM-DD | HH:m:s ')}</small>
        </Space>
      ),

    },
    {
      title: 'Last Activity',
      dataIndex: 'lastActivityDate',
      key: 'start_date',
      sorter: (a, b) => {
        if (a.lastActivityDate === null || b.lastActivityDate === null) {
          return a.lastActivityDate === null ? 1 : -1; // null values will be pushed to the bottom
        }
        return a.lastActivityDate.localeCompare(b.lastActivityDate);
      },
      render: (_, record) => (
        
        <Space size="middle">
          
          <small>{record.lastActivityDate !== null && moment(record.lastActivityDate).utc().format('YYYY-MM-DD | HH:m:s ')}</small>
        </Space>
      ),

    },
  ];

  return (
    <>
      <div className="container-fluid">
        <Row>
          <Col span={12} offset={6}>
            <center>
              <h3>{decodeURIComponent(className)}</h3>
            </center>
          </Col>
        </Row>
        <Row className="py-3 px-5">
          {accessToken.roles[0] === "TEACHER" ? 
          (<Col span={8}>
            <Button icon={<ArrowLeftOutlined />} onClick={(e) => {
              e.preventDefault()
              history.push(`/Instructor/1/Classes/${accessToken.userId}`)
            }} className='border-0'></Button>
            Go Back
          </Col>) 
            : 
          (<Col span={8}>
            <Button icon={<ArrowLeftOutlined />} onClick={(e) => {
              e.preventDefault()
              history.push(`/Instructor/${instructorId}/Classes/${classId}`)
            }} className='border-0'></Button>
            Go Back
          </Col>)}
          <Col span={8}>
            <Input size="large" placeholder="Search" onChange={getSearchTerm} prefix={<SearchOutlined className="ml-3 mr-3" />} />

          </Col>
        </Row>
        <div className="px-5">
          <Row className="py-3">
            <Col span={24}>
              <Table
                dataSource={searchTerm?.length <1 || searchTerm?.length === undefined ? dataSource : searchResult}
                // dataSource={dataSource} 
                columns={columns}
                pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']  }}
                className='thead-dark rl-button'
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ClassName;


