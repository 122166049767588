import { useState, useEffect } from "react";
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import { Col } from "reactstrap";
import jsonToken from 'jsonwebtoken'

import School from "./Schools";
import Teacher from "./Teachers";
import CreateLesson from "../CreateLesson";
import Students from "../MyAccount/Students";
import Classes from "../MyAccount/Classes";
import Terms from "../MyAccount/Term";
import Account from "../MyAccount/Account";
import Curriculum from "../MyAccount/Curriculum"
import ExperimentType from "./ExperimentType";
import AdminAccount from "./AdminAccount";
import SchoolAdminAccount from "./SchoolAdmin";
import Profile from "./Profile";
import Dashboard from "./Dashboard/Dashboard";

function MyAccount(props) {

  const [activeTab, setActiveTab] = useState();

  // console.log("yo")
  // let actoken = localStorage.getItem("access_token");
  // console.log('actoken',actoken)
  // if(actoken === null){
  //   sessionStorage.setItem("loggedin", false);
  //   const url = (window.location.href).split("#");
  //   window.open(`${url[0]}#/login`, "_self");
  //   document.location.reload(true);
  // }

  useEffect(() => {
    // setDefaultTab();
    setActiveTab("vertical-tab-twelwe")
    const classExists = document.querySelector('.admin') !== null;
    if (!classExists) return
    document.body.classList.add('admin-nav');
    document.body.classList.add('no-link');
  }, []);

  function isSuperAdmin() {
    return hasRole('SUPER_ADMIN');
  }

  function isAccountAdmin() {
    if (isSuperAdmin()) {
      return true;
    }
    return hasRole('ACCOUNT_ADMIN');
  }

  function isSchoolAdmin() {
    if (isSuperAdmin() || isAccountAdmin()) {
      return true;
    }
    return hasRole('SCHOOL_ADMIN');
  }

  function isTeacher() {
    if (isSuperAdmin() || isAccountAdmin() || isSchoolAdmin()) {
      return true;
    }
    return hasRole('TEACHER');
  }

  // function setDefaultTab() {
  //   if (isSuperAdmin()) {
  //     setActiveTab("vertical-tab-twelwe")
  //   } else if (isAccountAdmin()) {
  //     setActiveTab("vertical-tab-twelwe")
  //   } else if (isSchoolAdmin()) {
  //     setActiveTab("vertical-tab-twelwe")
  //   } else {
  //     setActiveTab("vertical-tab-twelwe")
  //   }
  // }

  function hasRole(role) {
    let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
    let index = accessToken.roles.findIndex(element => {
      return element.toLowerCase() === role.toLowerCase();
    });
    return index > -1;
  }

  function ExpandSession() {
    const currentTime = Date.now();
    localStorage.setItem("expirationTime", currentTime + 28800000);
  }


  return (
    <>
      <div className="admin overflow-hidden-x">
        <Col lg="12" className="m-0 p-0">
          <div className="tab-style ">
            <Tabs
              defaultTab={activeTab}
              vertical

              className="vertical-tabs"
              onChange={(tabId) => { setActiveTab(tabId);ExpandSession() }}
            >
              <TabList className="tablist-fixed">
                {isTeacher() && <Tab tabFor="vertical-tab-twelwe"> Dashboard</Tab>}
                {isSuperAdmin() && <Tab tabFor="vertical-tab-zero"> Account</Tab>}
                {isSuperAdmin() && <Tab tabFor="vertical-tab-one">Account Admin</Tab>}
                {isSuperAdmin() && <Tab tabFor="vertical-tab-six">Curriculum</Tab>}
                {isAccountAdmin() && <Tab tabFor="vertical-tab-two">School</Tab>}
                {isAccountAdmin() && <Tab tabFor="vertical-tab-ten">School Admin</Tab>}
                {isSuperAdmin() && <Tab tabFor="vertical-tab-three">Term</Tab>}
                {isSchoolAdmin() && <Tab tabFor="vertical-tab-five">Teacher</Tab>}
                {isTeacher() && <Tab tabFor="vertical-tab-nine">Student</Tab>}
                {isTeacher() && <Tab tabFor="vertical-tab-four">Class</Tab>}
                {isSuperAdmin() && <Tab tabFor="vertical-tab-seven">Lesson</Tab>}
                {hasRole('TEACHER') && <Tab tabFor="vertical-tab-eleven">Profile</Tab>}
                {/* {isSuperAdmin() && !hasRole('ACCOUNT_ADMIN') && <Tab tabFor="vertical-tab-eight">Experiment Type</Tab>} */}
              </TabList>
              {isTeacher() && <TabPanel tabId="vertical-tab-twelwe">
                <Dashboard activeTab={activeTab} isActive={activeTab === 'vertical-tab-twelwe'} />
              </TabPanel>}
              {isSuperAdmin() && <TabPanel tabId="vertical-tab-zero">
                <Account activeTab={activeTab} />
              </TabPanel>}
              {isSuperAdmin() && <TabPanel tabId="vertical-tab-one">
                <AdminAccount activeTab={activeTab} tabId="vertical-tab-one" />
              </TabPanel>}
              {isAccountAdmin() && <TabPanel tabId="vertical-tab-ten">
                <SchoolAdminAccount activeTab={activeTab} tabId="vertical-tab-ten" />
              </TabPanel>}
              {isSuperAdmin() && <TabPanel tabId="vertical-tab-six">
                <Curriculum activeTab={activeTab} tabId="vertical-tab-six" />
              </TabPanel>}
              {isAccountAdmin() && <TabPanel tabId="vertical-tab-two">
                <School activeTab={activeTab} tabId="vertical-tab-two" />
              </TabPanel>}
              {isSuperAdmin() && <TabPanel tabId="vertical-tab-three">
                <Terms activeTab={activeTab} tabId="vertical-tab-three" />
              </TabPanel>}
              {isSchoolAdmin() && <TabPanel tabId="vertical-tab-five">
                <Teacher activeTab={activeTab} tabId="vertical-tab-five" />
              </TabPanel>}
              {isTeacher() && <TabPanel tabId="vertical-tab-four">
                <Classes activeTab={activeTab} tabId="vertical-tab-four" />
              </TabPanel>}
              {isSuperAdmin() && <TabPanel tabId="vertical-tab-seven">
                <CreateLesson />
              </TabPanel>}
              {isSuperAdmin() && <TabPanel tabId="vertical-tab-eight">
                {
                  !hasRole('ACCOUNT_ADMIN') && <ExperimentType />
                }
              </TabPanel>}
              {isTeacher() && <TabPanel tabId="vertical-tab-nine">
                <Students activeTab={activeTab} tabId="vertical-tab-nine" />
              </TabPanel>}
              {isTeacher() && <TabPanel tabId="vertical-tab-eleven">
                <Profile activeTab={activeTab} tabId="vertical-tab-eleven" />
              </TabPanel>}
            </Tabs>
          </div>
        </Col>
      </div>

    </>
  );
}

export default MyAccount;
