import { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Row,
  Col,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import "antd/dist/antd.css";
//import Bootbox from "bootbox-react";
import { Input, Form, Tabs, Select, Image, Checkbox, Spin, Radio, Carousel, message, notification } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import * as htmlToImage from 'html-to-image';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import MapChild from "./childComponent/map";
import ReactJsAlert from "reactjs-alert";
import RestService from "../../../api/RestService";
import MathCalculationService from "../../../components/Calculation/MathCalculation";
import jsonToken from 'jsonwebtoken'
import ChartValidations from "./childComponent/ChartValidations";
import AnswerDisplay from "../AnswerDisplay";
import CordinateDisplayBox from "./childComponent/CordinatedBoard";
import FloatingButton from "../../../components/FloatingButton";
import { useDispatch } from "react-redux";
import { addToTable } from "../../../reducers/graphDataSlice";
import InstructorComment from "../components/InstructorComment/InstructorComment";
import Config from "../../../api/Config";
import WhiteScreen from "../../../assets/images/white-screen.png"
function ExploreMain(props) {

  const dispatch = useDispatch();

  const [initPayload, setInitPayload] = useState(
    {
      exploreStepExperiment: [],
      hypothesis: [],
      exploreStepInfoInstructions: [],
      exploreStepQuestionAnswers: [],
      exploreStepQuestions: [],
      hypotheisOptions: [],
      description: [],
      id: "",
      lesson_id: "",
      lessonCode: "",
      chartType: "",
      guideline: "",
    });


  const [dataTableValue, setDataTableValue] = useState(
    [{
      value: "",
      si: ""
    }]
  )

  let ctx = null;
  const [initialValue, setInitialValue] = useState([]);
  const [initialValueY, setInitialValueY] = useState(0);
  const [initialValueX, setInitialValueX] = useState(0);
  const [loadingText, setLoadingText] = useState("Loading...");
  const [experimentColor, setExperimentColor] = useState();
  const [speedDataFromGraph, setSpeedDataFromGraph] = useState();
  const [codDataFromGraph, setCodDataFromGraph] = useState();
  const [xData, setXData] = useState([]);
  const [xyData, setXYData] = useState([]);
  const [yData, setYData] = useState([]);
  const [yLabel, setYLabel] = useState();
  const [yLabelUnit, setYLabelUnit] = useState();
  const [xLabelUnit, setXLabelUnit] = useState();
  const [xLabel, setXLabel] = useState();
  const [clickCount, setClickCount] = useState(0);
  const [messureMentValues, setMessureMentValues] = useState([])
  const [instructionCount, setInstructionCount] = useState(0);
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertStatus2, setAlertStatus2] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  // const [chartTog, setChartTog] = useState()
  const [videoEnd, setDestatus] = useState(false);
  const [lessonStats, setLessonStats] = useState(false);
  // const [coordinatesDataY, setCoordinateDataY] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [disableState, setDisableState] = useState(true);
  const [userMainHypothesis, setUserMainHypothesis] = useState("");
  const [userIHypothesis, setUserIHypothesis] = useState("");
  const [instructions, setInstructions] = useState("Instructions");
  const [selectValue, setSelectValue] = useState("");
  const [mappingId, setMappingId] = useState("");
  const [id, setId] = useState("");
  const [visualName, setVisualName] = useState("");
  const [intervalGraph, setIntervalGraph] = useState("");
  const [selectImg, setSelectImg] = useState([]);
  const [mainPayload, setMainPayload] = useState({});
  const [containedPayload, setContainedPayload] = useState([]);
  const [selectionOption, setSelectionOption] = useState([]);
  const [cellId, setCellId] = useState("");
  const [hypoState, setHypoState] = useState(0);
  const [clickState, setClickState] = useState(2);
  const [creatingTrigger, setCreatingTrigger] = useState(0);
  const { TextArea } = Input;
  const [allGraphValue, setAllGraphValue] = useState("");
  const [checked, setChecked] = useState(false)
  const [lesson, setLesson] = useState(initPayload?.lesson_id);
  const [selectedLesson, setSelectedLesson] = useState(0);
  const [descriptionValue, setDescriptionValue] = useState({});
  const [clickCount2, setClickCount2] = useState(0);
  const [canvasScale, setCanvasScale] = useState(1);
  const [folderNameValue, setFolderNameValue] = useState("");
  const [srcName, setSrcName] = useState("");
  const [BarValueList, setBarValue] = useState([]);
  const [nameForValidation, setNameForValidation] = useState("");
  const [switcher, setSwitcher] = useState(0);
  const [imgExist, setImgExist] = useState(0);
  const [screenShot, setScreenShot] = useState([]);
  const [imgState, setImgState] = useState(0);

  const [bluredLayer, setBluredLayer] = useState("");

  useEffect(() => {
    drawingCanvas(nameForValidation)
  }, [imgState])
  // use to store xValues or yValues for incremental purpose
  const [storeValue, setStoreValue] = useState(0);
  const [datasets, setDatasets] = useState([
    {
      label: selectValue,
      label2: selectValue,
      yData: [],
      color: "red"
    }
  ]);

  const secondLessonTablePayload = {
    labels: selectImg.map((item, i) => i + 1),
    type: clickState,
    datasets: (clickState !== 0 && clickCount > 0) ? datasets.map((x, id) => {
      if (id !== 0) {
        return x
      }
    }) : datasets
  }

  const contentStyle = {
    height: '400px',
    width: '540px'
  };

  useEffect(() => {

    if (visualName[1] !== undefined) {
      setDescriptionValue({ "value": visualName[0] + visualName[1], "display": `${visualName[0]} :  ${visualName[1]}` })

    } else {
      setDescriptionValue({ "value": visualName[0], "display": `${visualName[0]}` })

    }
  }, [visualName])


  useEffect(() => {
    initPayload?.exploreStepExperiment?.map((x, id) => {
      if (id === 0) {
        setExColor(x.name[0]?.colors);
        getSelectValue(x.description?.folderName);
        getId(x.id);
        getVisualName(x.description?.visualName);
        getMappingId(x.description?.id);
        setFolderNameValue(x.description?.folderName);
      }
    });
    if (initPayload?.chartType[mappingId]?.calculationMethod === "Line" || initPayload?.chartType[mappingId]?.calculationMethod === "splitLine" || initPayload?.chartType[mappingId]?.calculationMethod === "Simple Line" || initPayload?.chartType[mappingId]?.calculationMethod === "Line Radial Defined" || initPayload?.chartType[mappingId]?.calculationMethod === "Line Force Defined") {
      setLessonStats(true)
    }
    else {
      setLessonStats(false);
    }

  }, [initPayload, mappingId])

  useEffect(() => {
    setLoading(true)
    RestService.getExploreStepInfoById(props.exploreId)
      .then((response) => {
        if (response !== undefined) {
          setInitPayload(response?.data);
          if (response?.data?.chartType?.dataTableValue !== undefined) {
            setDataTableValue(response?.data?.chartType?.dataTableValue)
          }
          dispatch(addToTable(response?.data));
          setCanvasScale(parseFloat(response?.data?.chartType?.canvasScale ?? 1.0))
          setLoading(false)
        }

        //updateRequest
      })

  }, [])
  useEffect(() => {

    if (initPayload?.chartType[mappingId]?.calculationMethod !== "curve" && initPayload?.chartType[mappingId]?.calculationMethod !== "Bar YesNo") {
      setDatasets([
        {
          label: descriptionValue.display,
          displayLabel: descriptionValue.display,
          yData: messureMentValues.map(x => {
            return x.value
          }),
          color: messureMentValues.map(x => {
            return x.color
          })

        }
      ])
    }

    if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar YesNo") {
      setDatasets([{
        label2: selectValue,
        label: descriptionValue.display,
        yData: messureMentValues.map(x => {
          return x
        })
      }])
    }

  }, [messureMentValues, mappingId])

  useEffect(() => {

    if (initPayload?.chartType[mappingId]?.calculationMethod === "curve") {

      setDatasets([
        {
          label: selectValue,
          yData: xData
        }
      ])
    }
  }, [initPayload, xData, mappingId])
  const [secondResponseData, setSecondResponseData] = useState({
    borderColor: "",
    borderWidth: "",
    labels: "",
    xData: [],
    yData: [],
    xyData: [],
    categoryData: [],
    cod: "",
    speed: "",
  });
  const [responseMixData, setResponseMixData] = useState([]);
  const [graphMixStatus, setGraphMixStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [speed2, setSpeed2] = useState();
  const [changeValidator, setChangeValidator] = useState(0);

  const [teacherComment, setTeacherComment] = useState("");
  const [teacherCommentStats, setTeacherCommentStats] = useState(false);

  let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;

  }

  useEffect(() => {
    setMessureMentValues([])
    initPayload?.exploreStepExperiment.map((x) => {
      if (x.id === id) {
        setIntervalGraph(x.chart_interval);
        setSelectImg(x.images.split(","));
        let xValue = " ";
        let yValue = " ";
        if (x.x !== null) {
          if (x.x.SI.length > 0) {
            xValue = `(${x.x.SI})`;
          }
          setXLabel(`${x.x.Value} ${xValue}`)

          setXLabelUnit(x.x.SI)
        }
        if (x.y !== null) {
          if (x.y.SI.length > 0) {
            yValue = `(${x.y.SI})`;
          }

          setYLabel(`${x.y.Value} ${yValue}`)
          setYLabelUnit(x.y.SI)
        }



      }
    })

  }, [selectValue, mappingId])
  useEffect(() => {
    setMessureMentValues([]);
  }, [selectValue]);



  useEffect(() => {
    setLoading(true)
    RestService.getExploreStepUserStatusByIdAnduserName(props.user, props.exploreId)
      .then((response) => {
        if (response !== undefined) {
          if (response?.data[0]?.comment !== null && response?.data[0]?.comment !== undefined && response?.data[0]?.comment !== "") {
            setTeacherCommentStats(true)
            setTeacherComment(response?.data[0]?.comment)
          }
          if (response?.status === 200 && response) {
            response?.data.map((x) => {
              setContainedPayload({
                id: x?.id,
                explore_step_id: x?.explore_step_id,
                response_jason: JSON.parse(x?.response_jason),
              })


            })
            setCreatingTrigger(0)

          }
          setLoading(false)
          setLoading2(false)
          // setBluredLayer(" ")

        } else {
          setLoading(false)
          setLoading2(true)
          // message.warning('Student has not answer yet');
          // trigger this
          // notification.warning({
          //   message: `Notification`,
          //   description:
          //     'Student has not answer yet',
          //   placement:'bottom',
          //   duration: 0,
          // });
          // setBluredLayer("blured-content")
          // setLoadingText("Student has not answer yet")
        }
      })
  }, [props, creatingTrigger])

  useEffect(() => {
    setResponseMixData([]);
    setSecondResponseData({
      borderColor: "",
      borderWidth: "",
      labels: "",
      xData: [],
      yData: [],
      xyData: [],
      categoryData: [],
      cod: "",
      speed: "",
      userMainHypothesis: "",
    });

    if (containedPayload?.response_jason?.answers !== undefined) {
      if (cellId.length !== 0 && cellId !== undefined && cellId !== null) {
        const filteredList = Object.keys(containedPayload?.response_jason?.answers)
          .filter(key => Object.values(cellId).includes(key))
          .reduce((obj, key) => {
            obj[key] = containedPayload?.response_jason?.answers[key];
            return obj;
          }, {});

        if (Object.values(filteredList).length <= 1) {
          setGraphMixStatus(false)
          if (Object.values(filteredList)[0] !== undefined && Object.keys(filteredList)[0] !== null) {
            const responsePayload = {
              borderColor: Object.values(filteredList)[0].borderColor,
              borderWidth: Object.values(filteredList)[0].borderWidth,
              labels: Object.values(filteredList)[0].displayLabel,
              xData: Object.values(filteredList)[0].xData,
              yData: Object.values(filteredList)[0].yData,
              xyData: Object.values(filteredList)[0].xyData,
              categoryData: Object.values(filteredList)[0].categoryData,
              cod: Object.values(filteredList)[0].cod,
              speed: Object.values(filteredList)[0].speed,
            }
            setSecondResponseData(responsePayload);
          }

        } else {
          setResponseMixData([]);
          setGraphMixStatus(true);
          for (let k = 0; k < Object.values(filteredList).length; k++) {
            if (Object.values(filteredList)[k] !== undefined && Object.keys(filteredList)[k] !== null) {
              const responsePayload = {
                borderColor: Object.values(filteredList)[k].borderColor,
                borderWidth: Object.values(filteredList)[k].borderWidth,
                labels: Object.values(filteredList)[k].displayLabel,
                xData: Object.values(filteredList)[k].xData,
                yData: Object.values(filteredList)[k].yData,
                xyData: Object.values(filteredList)[k].xyData,
                categoryData: Object.values(filteredList)[k].categoryData,
                cod: Object.values(filteredList)[k].cod,
                speed: Object.values(filteredList)[k].speed,
                yLabel: yLabelUnit,
                lesson: lesson
              }
              setResponseMixData((preData) => [...preData, responsePayload]);
            }

          }

        }

      }

      Object.values(containedPayload?.response_jason?.answers).map((x) => {


        if (Object.keys(x.userMainHypothesis).length > 0) {
          setUserMainHypothesis(x.userMainHypothesis);
          setHypoState(1);
        }
      });


    }
    // containedPayload?.response_jason?.answers

  }, [containedPayload, cellId])


  const drawingCanvas = (name) => {
    const outputEntry = initPayload?.exploreStepExperiment.find(obj => obj.name[0].value === name)

    if (initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type7" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type10") {
      let canvas = document.getElementById("experimentcanvas");
      ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, 540, 400);

      const result = containedPayload?.response_jason?.answers && containedPayload?.response_jason?.answers[name].screenShot[imgState];
      if (result) {
        if (initPayload?.chartType[mappingId]?.calculationMethod === "Line Type11") {
          drawX3(result.x, result.y, "white")
        } else {
          drawX(result.x, result.y, outputEntry.id, outputEntry.description?.id, name)
        }
      }
    }
  }

  const graphdata1 = {
    labels: secondResponseData.categoryData,
    lesson: lesson,
    type: 1,
    datasets: [
      {
        yLabel: yLabelUnit,
        label: secondResponseData.labels,
        xData: secondResponseData.xData,
        yData: secondResponseData.yData,
        xyData: secondResponseData.xyData,
        borderColor: secondResponseData.borderColor,
        borderWidth: secondResponseData.borderWidth,
      },
    ],
  };
  const tablePayload2 = {
    type: graphMixStatus,
    labels: secondResponseData.labels,
    xData: initPayload?.chartType[mappingId]?.calculationMethod !== "curve" ? secondResponseData.yData : secondResponseData.xData,
    yData: initPayload?.chartType[mappingId]?.calculationMethod !== "curve" ? secondResponseData.xData : secondResponseData.yData,
    speed: secondResponseData.speed,
    cod: secondResponseData.cod,
  };


  useEffect(() => {


    if (datasets && datasets.length > 0) {
      datasets.map(x => {
        if (x.yData.length == 0) {
          setDatasets(datasets.filter(x => x.yData.length > 0))
        }
      })
    }
  }, [datasets])

  useEffect(() => {
    setMainPayload({});

    const data = containedPayload?.response_jason?.answers;
    if (data !== undefined && data !== null) {

      const selectval = Object.keys(data);
      const slength = selectval.length;
      const value = Object.values(data);

      for (let i = 0; i < slength; i++) {
        if (selectval[i] !== undefined && selectval[i] !== null) {


          setMainPayload(prev => ({
            ...prev,
            [selectval[i]]: {
              userMainHypothesis: value[i]?.userMainHypothesis,
              label: selectval[i],
              displayLabel: value[i]?.displayLabel,
              xData: value[i]?.xData,
              yData: value[i]?.yData,
              xyData: value[i]?.xyData,
              categoryData: value[i]?.categoryData,
              speed: value[i]?.speed,
              speed2: value[i]?.speed2,
              cod: value[i]?.cod,
              borderColor: value[i]?.borderColor,
              borderWidth: value[i]?.borderWidth,
              mappingId: parseInt(mappingId),
              screenShot: Object.values(screenShot),
            }
          }))
        }

      }

      let arr = []
      Object.keys(data).map(x => {
        if (data[x].displayLabel !== undefined) {
          arr.push({
            name: data[x].label,
            displayName: data[x].displayLabel,
            isChecked: false,
            color: data[x].borderColor
          })
        }

      })
      if (changeValidator === 0) {
        setChangeValidator(1)
        setSelectionOption(arr)
      }



    }
  }, [containedPayload?.response_jason?.answers, selectValue])


  const drawX = (x, y, immediateID, immediateMappingID, name) => {
    // console.log(id)
    ctx.strokeStyle = `${dataSendToMap.find(x => x.id === immediateID).chart_type[immediateMappingID].PointerType.color}`
    ctx.beginPath();


    // if(dataSendToMap.find(x => x.id === id).chart_type === "Line Temperature Defined" || dataSendToMap.find(x => x.id === id).chart_type === "Line Force Defined"){
    if (dataSendToMap.find(x => x.id === immediateID).chart_type[immediateMappingID].PointerType.Type === "horizontalLine") {
      ctx.lineWidth = dataSendToMap.find(x => x.id === immediateID).chart_type[immediateMappingID].PointerType.width;

      ctx.moveTo(x - 3, y);
      ctx.lineTo(x + 3, y);
      ctx.stroke();

      // ctx.moveTo(x + 2, y - 2);
      // ctx.lineTo(x - 2, y + 2);
      // ctx.stroke();
    }
    // dataSendToMap.find(x => x.id === id).chart_type ==="Line Radial Defined"
    else if (dataSendToMap.find(x => x.id === immediateID).chart_type[immediateMappingID].PointerType.Type === "dot") {
      ctx.lineWidth = dataSendToMap.find(x => x.id === immediateID).chart_type[immediateMappingID].PointerType.width;
      ctx.moveTo(x - 0.5, y + 0.5);
      ctx.lineTo(x + 0.5, y - 0.5);
      ctx.stroke();
    } else if (dataSendToMap.find(x => x.id === immediateID).chart_type[immediateMappingID].PointerType.Type === "verticalLine") {
      ctx.lineWidth = dataSendToMap.find(x => x.id === immediateID).chart_type[immediateMappingID].PointerType.width;

      ctx.moveTo(x, y - 3);
      ctx.lineTo(x, y + 3);
      ctx.stroke();
    }
    else {
      ctx.lineWidth = 1;

      ctx.moveTo(x - 2, y - 2);
      ctx.lineTo(x + 2, y + 2);
      ctx.stroke();

      ctx.moveTo(x + 2, y - 2);
      ctx.lineTo(x - 2, y + 2);
      ctx.stroke();
    }

    if (initPayload?.chartType[mappingId]?.calculationMethod === "Line Type12") {
      const points = Object.values(containedPayload.response_jason.answers[name].screenShot);
      ctx.strokeStyle = `${dataSendToMap.find(x => x.id === id).chart_type[mappingId].PointerType.color}`;
      ctx.beginPath();
      ctx.lineWidth = 1;

      // Draw the first point
      const firstPoint = points[0];
      ctx.moveTo(firstPoint.x, firstPoint.y);

      // Draw lines between the rest of the points
      for (let i = 1; i < points.length; i++) {
        const point = points[i];
        ctx.lineTo(point.x, point.y);
      }
      ctx.stroke();
    }
  };


  useEffect(() => {
    // selectedLesson, setSelectedLesson

    const findIndex = initPayload?.exploreStepExperiment.findIndex(x => x.id === id)
    if (findIndex !== -1) {
      setSelectedLesson(findIndex)
    } else {
      setSelectedLesson(0)
    }
  }, [selectValue])


  const drawX3 = (x, y, color) => {
    setScreenShot(prev => ({
      ...prev,
      [clickCount]: { x: x, y: y }
    }))

    ctx.strokeStyle = color;

    ctx.lineWidth = 3;
    ctx.beginPath();
    ctx.arc(x, y, 15, 0, 2 * Math.PI);
    ctx.stroke();

  }

  const onCanvasClick = (e) => {
    setClickState(0);
    // setDatasets([{
    //   label: null,
    //   yData: [],
    //   xData:[]
    // }])
    selectionOption.map(x => {
      x.isChecked = false
    });
    setSelectionOption(selectionOption)
    setChecked(false)
    setGraphMixStatus(false)
    setCellId("");
    let images = selectImg;
    if (dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod !== "Bar YesNo") {
      setClickCount(clickCount + 1);
    }

    if (clickCount < images.length) {
      let canvas = document.getElementById("experimentcanvas");
      ctx = canvas.getContext("2d");
      let mouseX = e.nativeEvent.offsetX;
      let mouseY = e.nativeEvent.offsetY;
      // draw the XY


      switch (dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod) {
        case "Bar YesNo":
          if (mouseX > 400) {
            drawX(mouseX, mouseY);
          }
          break;
        default:
          drawX(mouseX, mouseY);
          break;
      }

      // @todo should be later set to DB

      // value1 is the 0 pointing position, and value 2 is the zero position
      const GasVolPressure = [
        {
          exp: "Air",
          value1: {
            x: 229,
            y: 133,
          },
          value2: {
            x: 284,
            y: 154,
          },
          range: [170, 380, 530, 702, 853, 941, 4700, 5865],
          xValue: 65
        },
        {
          exp: "CO2",
          value1: {
            x: 241,
            y: 137,
          },
          value2: {
            x: 291,
            y: 155,
          },
          range: [150, 343, 520, 687, 854, 990, 4700, 5865],
          xValue: 65
        },
        {
          exp: "Helium",
          value1: {
            x: 222,
            y: 134,
          },
          value2: {
            x: 276,
            y: 156,
          },
          range: [157, 340, 512, 652, 827, 943, 4700, 5865],
          xValue: 65
        },
        {
          exp: "Propane",
          value1: {
            x: 209,
            y: 134,
          },
          value2: {
            x: 263,
            y: 154,
          },
          range: [168, 354, 555, 698, 835, 1006, 4700, 5865],
          xValue: 65
        },
      ];

      const volGraph = [
        {
          exp: "10 cc",
          value1: {
            x: 282,
            y: 363,
          },
          value2: {
            x: 277,
            y: 318,
          },
          range: 2
        },
        {
          exp: "25 cc",
          value1: {
            x: 274,
            y: 348,
          },
          value2: {
            x: 271,
            y: 266,
          },
          range: 10
        },
        {
          exp: "50 cc",
          value1: {
            x: 284,
            y: 363,
          },
          value2: {
            x: 273,
            y: 308,
          },
          range: 10
        },
        {
          exp: "100 cc",
          value1: {
            x: 273,
            y: 360,
          },
          value2: {
            x: 275,
            y: 309,
          },
          range: 20
        },
      ];

      const PhaseChangeIdea = [
        {
          exp: "Water",
          value1: {
            x: 302,
            y: 263,
          },
          value2: {
            x: 302,
            y: 244,
          },
          range: 10
        },
        {
          exp: "SaltWater",
          value1: {
            x: 304,
            y: 232
          },
          value2: {
            x: 304,
            y: 214,
          },
          range: 10
        },
        {
          exp: "Alcohol",
          value1: {
            x: 305,
            y: 249,
          },
          value2: {
            x: 305,
            y: 231,
          },
          range: 10
        },
      ];


      const AtWoodMachine = [
        {
          exp: "260g0g",
          value1: {
            x: 296,
            y: 160,
          }
        },
        {
          exp: "260g10g",
          value1: {
            x: 298,
            y: 132,
          }
        },
        {
          exp: "260g20g",
          value1: {
            x: 293,
            y: 131,
          }
        },

        {
          exp: "260g30g",
          value1: {
            x: 292,
            y: 127,
          }
        },
        {
          exp: "260g40g",
          value1: {
            x: 297,
            y: 128,
          }
        },
        {
          exp: "380g0g",
          value1: {
            x: 293,
            y: 138,
          }
        },
        {
          exp: "380g10g",
          value1: {
            x: 299,
            y: 118,
          }
        },
        {
          exp: "380g20g",
          value1: {
            x: 298,
            y: 119,
          }
        },
        {
          exp: "380g30g",
          value1: {
            x: 299,
            y: 123,
          }
        },
        {
          exp: "380g40g",
          value1: {
            x: 299,
            y: 115,
          }
        },
        {
          exp: "500g0g",
          value1: {
            x: 291,
            y: 175,
          }
        },
        {
          exp: "500g10g",
          value1: {
            x: 291,
            y: 115,
          }
        },
        {
          exp: "500g20g",
          value1: {
            x: 294,
            y: 115,
          }
        },
        {
          exp: "500g30g",
          value1: {
            x: 301,
            y: 114,
          }
        },
        {
          exp: "500g40g",
          value1: {
            x: 296,
            y: 114,
          }
        },
      ];
      const fixedValueChemical = [
        {
          exp: "heat",
          value: {
            y: 334,
          }
        },
        {
          exp: "light",
          value: {
            y: 267,
          }
        },
        {
          exp: "color",
          value: {
            y: 199,
          }
        },
        {
          exp: "solid",
          value: {
            y: 132,
          }
        },
        {
          exp: "liquid",
          value: {
            y: 66,
          }
        },
        {
          exp: "gas",
          value: {
            y: 0,
          }
        },

      ]

      switch (dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod) {

        case "Line Type2":
          const value6 = MathCalculationService.LineOfBestFitXForward(mouseX, initialValueX, intervalGraph);


          if (clickCount === 0) {
            setInitialValueX(value6.initialValueX)
          }


          setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

          setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
          setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, parseFloat((value6.value / 10) * canvasScale)]]);
          setXData((prevArray) => [...prevArray, (value6.value / 10) * canvasScale]);
          break;
        case "Simple Line":

          //@to-do set two initial values x  and y zero => (238, 138) > 238 will be zero referencing for x-axis and 138 for y axis
          //result should set in the categoryData, xData, yData, xyData
          const value = MathCalculationService.simpleLine(mouseX / 10, mouseY / 10, initialValueY, initialValueX, intervalGraph);

          const result = (parseFloat(value.value) + parseFloat(storeValue)).toFixed(2);

          setInitialValueY(value.initialValueY)
          setInitialValueX(value.initialValueX)


          setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

          setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
          setStoreValue(result)

          setXData((prevArray) => [...prevArray, (result) * canvasScale]);
          setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, parseFloat((result) * canvasScale)]]);


          break;
        // GasVolPressure 
        case "Line Radial Defined":
          const iniX = GasVolPressure.find(x => x.exp === selectValue)?.value1?.x;
          const iniY = GasVolPressure.find(x => x.exp === selectValue)?.value1?.y;
          const zeroX = GasVolPressure.find(x => x.exp === selectValue)?.value2?.x;
          const zeroY = GasVolPressure.find(x => x.exp === selectValue)?.value2?.y;
          let xValue = GasVolPressure.find(x => x.exp === selectValue)?.xValue;

          const value5 = MathCalculationService.LineRadialDefined(iniX, iniY, mouseY, mouseX, zeroX, zeroY);

          setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

          setYData((prevArray) => [...prevArray, (clickCount * intervalGraph) + xValue]);
          setXData((prevArray) => [...prevArray, value5.value]);
          setXYData((prevArray) => [...prevArray, [(clickCount * intervalGraph) + xValue, value5.value]]);
          break;

        // AtWoodMachine
        case "Line Force Defined":

          const value2 = MathCalculationService.LineForceDefined(initialValueY, mouseY);
          if (clickCount === 0) {
            setInitialValueY(mouseY);
            setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

            setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
            setXData((prevArray) => [...prevArray, "0"]);
            setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, "0"]]);
          } else {
            setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

            setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
            setXData((prevArray) => [...prevArray, (value2.value) * canvasScale]);
            setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, (value2.value) * canvasScale]]);
          }

          break;

        // PhaseChangeIdea
        case "Line Temperature Defined":
          const initialYDifference = Math.abs(((PhaseChangeIdea.find(x => x.exp === selectValue)?.value1?.y - PhaseChangeIdea.find(x => x.exp === selectValue)?.value2?.y)));
          const initialYValue2 = PhaseChangeIdea.find(x => x.exp === selectValue)?.value1?.y;
          const range = PhaseChangeIdea.find(x => x.exp === selectValue)?.range;

          const value3 = MathCalculationService.LineTemperatureDefined(initialYDifference, initialYValue2, mouseY, range);

          setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

          setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
          setXData((prevArray) => [...prevArray, (value3.value) * canvasScale]);
          setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, (value3.value) * canvasScale]]);

          break;

        case "Line Volume Defined":


          const value4 = MathCalculationService.simpleVolumeLine(mouseX / 10, mouseY / 10, initialValueY, initialValueX, intervalGraph);
          if (clickCount === 0) {
            setInitialValueY(value4.initialValueY)
            setInitialValueX(value4.initialValueX)
          }


          setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

          setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
          setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, parseFloat((value4.value) * canvasScale)]]);
          setXData((prevArray) => [...prevArray, (value4.value) * canvasScale]);
          break;

        case "Bar YesNo":
          setYData([1, 2, 3, 4, 5, 6]);

          setCategoryData([1, 2, 3, 4, 5, 6]);

          let barValue;

          if (mouseX > 400) {
            if (mouseY > 0 && mouseY <= 66) {
              barValue = { 0: "gas" }
            } else if (mouseY > 66 && mouseY <= 132) {
              barValue = { 1: "liquid" }
            } else if (mouseY > 132 && mouseY <= 199) {
              barValue = { 2: "solid" }
            } else if (mouseY > 199 && mouseY <= 267) {
              barValue = { 3: "color" }
            } else if (mouseY > 267 && mouseY < 334) {
              barValue = { 4: "light" }
            } else {
              barValue = { 5: "heat" }
            }
            setXData(prev => ({
              ...prev,
              [Object.keys(barValue)]: Object.values(barValue)
            }));

            setMessureMentValues(prev => [
              ...prev,
              { [parseInt(Object.keys(barValue)) + 1]: 1 }
            ]);
          }
          break;

        case "Line Type3":

          setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
          setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
          if (switcher === 0) {
            setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, clickCount2 + 1]]);
            setXData((prevArray) => [...prevArray, clickCount2 + 1]);
            setClickCount2(clickCount2 + 1)
            setSwitcher(1)
          } else {
            setXYData(prevArray => {
              const newArray = prevArray.map(element => {
                if (element[1] === clickCount2) {
                  return [element[0], clickCount2 + 1];
                } else {
                  return element;
                }
              });
              return [...newArray, [clickCount * intervalGraph, clickCount2 + 1]];
            });
            setXData(prevArray => {
              const newArray = prevArray.map(element => {
                if (element === clickCount2) {
                  return clickCount2 + 1;
                } else {
                  return element;
                }
              });
              return [...newArray, clickCount2 + 1];
            });
            setClickCount2(clickCount2 + 1)
          }
          break;
        default:
          if (lessonStats) {
            if (clickCount === 0) {

              setInitialValue(mouseX);
              setInitialValueY(+initialValueY + + intervalGraph);
              setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
              const xValue = mouseX - mouseX;

              setXData((prevArray) => [...prevArray, xValue * canvasScale]);
              setXYData((prevArray) => [...prevArray, [initialValueY, xValue * canvasScale]]);
              setYData((prevArray) => [...prevArray, initialValueY]);
              setInitialValueY(+initialValueY + + intervalGraph);

            } else {

              setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
              let xValue;
              if (dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod === "splitLine") {
                if (selectValue === "SlowFast") {
                  xValue = Math.abs((initialValue - mouseX) * 8);
                } else if (selectValue === "FastSlow") {
                  xValue = Math.abs((initialValue - mouseX) * 7);
                } else if (selectValue === "TurnAround") {
                  xValue = Math.abs((initialValue - mouseX) * 10);
                } else {
                  xValue = Math.abs((initialValue - mouseX) * 9);
                }
              } else {
                xValue = Math.abs((initialValue - mouseX) / 100);
              }

              setXData((prevArray) => [...prevArray, xValue * canvasScale]);
              setXYData((prevArray) => [...prevArray, [initialValueY, xValue * canvasScale]]);
              setYData((prevArray) => [...prevArray, initialValueY]);
              setInitialValueY(+initialValueY + + parseFloat(intervalGraph));
              if (clickCount === images.length - 1) {
                setAlertStatus({ status: true });
                // submitFun();

              }
            }
          }
      }


      //@to get later from the DB
      const fixedValueCurveGraph = [
        {
          exp: "June",
          value: {
            x: 296,
            y: 270,
          }
        },
        {
          exp: "July",
          value: {
            x: 291,
            y: 273,
          }
        },
        {
          exp: "August",
          value: {
            x: 298,
            y: 268,
          }
        },
        {
          exp: "September",
          value: {
            x: 292,
            y: 264,
          }
        },
        {
          exp: "October",
          value: {
            x: 293,
            y: 269,
          }
        },
        {
          exp: "November",
          value: {
            x: 204,
            y: 265,
          }
        },
        {
          exp: "December",
          value: {
            x: 192,
            y: 268,
          }
        },
      ]



      if (initPayload?.chartType[mappingId]?.calculationMethod === "curve") {
        const yDistance = Math.abs(((fixedValueCurveGraph.find(x => x.exp === selectValue)?.value?.x - mouseY) / 10).toFixed(2));
        const xDistance = Math.abs(((fixedValueCurveGraph.find(x => x.exp === selectValue)?.value?.y - mouseX) / 10).toFixed(2));
        const Xvalue = Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2)).toFixed(2)

        setXData(prev => [...prev, Xvalue])
        if (yData.length === 0) {
          setYData([parseFloat(initPayload?.exploreStepExperiment[selectedLesson]?.x?.InitialValue)])
        }
        else {
          const val = yData[yData.length - 1] + parseFloat(initPayload?.exploreStepExperiment[selectedLesson]?.chart_interval)
          setYData(prev => [...prev, val])
        }
      }
    } else {
      // submitFun();
      setAlertStatus2({ status: true });

    }
  };

  const resetValue = () => {
    setClickCount(0);
    setClickCount2(0);
    setInitialValueY(0);
    setStoreValue(0);
    setInitialValue([]);
    setCategoryData([]);
    setYData([]);
    setXData([]);
    setXYData([]);
    setMessureMentValues([]);


    if (initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type11" && initPayload?.chartType[mappingId]?.calculationMethod !== "Bar" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type7" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type10") {
      let canvas = document.getElementById("experimentcanvas");

      if (canvas != null) {
        ctx = canvas.getContext("2d")
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    }
  };
  const colorForLessonOne = [{
    exp: 1,
    color: "#9F80E1"
  },
  {
    exp: 2,
    color: "#9CC9E8"
  },
  {
    exp: 3,
    color: "#9F8AA7"
  },
  {
    exp: 4,
    color: "#80A6AB"
  },
  {
    exp: 5,
    color: "#6D7E26"
  },
  {
    exp: 6,
    color: "#B9EEA6"
  },
  {
    exp: 7,
    color: "#FF94DA"
  },
  {
    exp: 8,
    color: "#B1FFE1"
  },
  {
    exp: 9,
    color: "#F4FFA5"
  },
  {
    exp: 10,
    color: "#8FB3D6"
  },
  {
    exp: 11,
    color: "#F5B080"
  },
  {
    exp: 12,
    color: "#DF8080"
  }

  ]
  const BowienGraph = [{
    exp: 1,
    color: "#9CBDEF"
  },
  {
    exp: 2,
    color: "#BB81CC"
  },
  {
    exp: 3,
    color: "#86B389"
  },
  {
    exp: 4,
    color: "#FFB6A6"
  },
  {
    exp: 5,
    color: "#B3877D"
  },
  {
    exp: 6,
    color: "#3e68a7"
  },
  {
    exp: 7,
    color: "#86b389"
  },
  {
    exp: 8,
    color: "#ffb6a6"
  },
  {
    exp: 9,
    color: "#b3877d"
  },
  {
    exp: 10,
    color: "#77d5d8"
  },
  {
    exp: 11,
    color: "#a9b55a"
  },
  {
    exp: 12,
    color: "#bb81cc"
  }

  ]
  const dataSendToMap = initPayload?.exploreStepExperiment;
  const currentIndex = initPayload?.exploreStepExperiment.findIndex(x => x.id === id)
  const selectedOne = initPayload?.exploreStepExperiment.filter(x => x.id === id)
  const selectedExperimentQuestions = selectedOne?.length > 0 ? initPayload?.exploreStepQuestions.filter(x => x.explore_step_exp_id == selectedOne[0].id) : []

  //payload for LineChart

  const payload = {
    labels: categoryData,
    lesson: lesson,
    type: 1,
    datasets: [
      {
        yLabel: yLabelUnit,
        xLabel: xLabelUnit,
        // Labels: xLabel,
        label: selectValue,
        xData: yData,
        yData: initPayload?.chartType[mappingId]?.calculationMethod === "curve" ? xData.map(i => {
          return i?.value
        }) : xData,
        xyData: xyData,
        borderColor: clickState === 0 ? "black" : mainPayload[selectValue]?.borderColor,
        borderWidth: 2,
      },
    ],
  };

  const viewData = datasets.map((i, index) => {
    return {
      type: 2,
      xData: i.yData.length > 0 ? i.yData.map((item, i) => i + 1) : [],
      yData: i.yData.length > 0 ? i.yData : [],
      // labels: i.label !== null && i.label.length > 0 ? i.label : i.displayLabel,
      labels: i.label,
    }

  })

  let tablePayload = {
    type: graphMixStatus,
    labels: descriptionValue.display,
    xData: yData,
    yData: xData,
    speed: speedDataFromGraph,
    speed2: speed2,
    cod: codDataFromGraph,
  };
  // console.log(tablePayload)
  const thirdLessonTablePayload = {
    labels: [yData],
    type: 3,
    yLabel: yLabelUnit,
    datasets: datasets.map(i => {
      return {
        label: i.label,
        yData: i.yData,
        borderColor: i.color,
      }
    })
  }

  const thirdLessonTablePayloadMixData = {
    labels:
      datasets.map(i => {
        return i.xData
      }),
    type: 3,
    yLabel: yLabelUnit,
    datasets: datasets.map(i => {
      return {
        label: i.label,
        yData: i.yData,
        borderColor: i.color,
      }
    })
  }

  const dataFromGraph = (fitValue, speed, speed2) => {

    setSpeedDataFromGraph(speed);

    setCodDataFromGraph(fitValue);

    setSpeed2(speed2)

  };

  const junkDataFromGraph = (fitValue, speed) => {
  };


  const getSelectValue = (data) => {
    setSelectValue(data);
  }

  const getMappingId = (data) => {
    setMappingId(data);
  }

  const getId = (data) => {
    setId(data)
  }

  const getVisualName = (data) => {
    setVisualName(data)
  }


  const handleChange = (e) => {
    const { checked, value } = e.target;
    const { data } = userMainHypothesis;
    if (checked) {
      setUserMainHypothesis(value);
      setHypoState(1)
    }
  };

  const handleChange2 = (e) => {
    const { value } = e.target;
    setUserMainHypothesis(value);
    setDisableState(false);

    if (value === "") {
      setDisableState(true);
    }
  };

  const handleConfirm = () => {
    setShowConfirm(false);

    return setUserMainHypothesis({
      data: [userIHypothesis],
    });
  };

  const handleCancel = () => {
    return setShowConfirm(false);
  };

  useEffect(() => {
    if (selectValue !== undefined && selectValue !== "") {
      if (initPayload?.chartType[mappingId]?.calculationMethod !== "Bar" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type5" && initPayload?.chartType[mappingId]?.calculationMethod !== "Bar YesNo") {

        setMainPayload(prev => ({
          ...prev,
          [selectValue]: {
            userMainHypothesis: userMainHypothesis,
            label: selectValue,
            displayLabel: descriptionValue.display,
            xData: yData,
            yData: xData,
            xyData: xyData,
            categoryData: categoryData,
            speed: speedDataFromGraph,
            cod: codDataFromGraph,
            speed2: speed2,
            borderColor: experimentColor,
            borderWidth: 2,
            mappingId: parseInt(mappingId),
            screenShot: Object.values(screenShot),
          }
        }))

      }
      else if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar YesNo" && messureMentValues.length > 0) {

        setMainPayload(prev => ({
          ...prev,
          [selectValue]: {
            userMainHypothesis: userMainHypothesis,
            label: selectValue,
            displayLabel: descriptionValue.display,
            xData: yData,
            yData: messureMentValues,
            xyData: xyData,
            categoryData: categoryData,
            speed: speedDataFromGraph,
            cod: codDataFromGraph,
            speed2: speed2,
            borderColor: experimentColor,
            borderWidth: 2,
            mappingId: parseInt(mappingId),
            screenShot: Object.values(screenShot),
          }
        }))

      }
      else if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar" || initPayload?.chartType[mappingId]?.calculationMethod === "Line Type5") {
        setMainPayload(prev => ({
          ...prev,
          [selectValue]: {
            userMainHypothesis: userMainHypothesis,
            label: selectValue,
            displayLabel: descriptionValue.display,
            xData: selectImg.map((item, i) => i + 1),
            yData: messureMentValues,
            borderColor: experimentColor,
            borderWidth: 2,
            mappingId: parseInt(mappingId),
            screenShot: Object.values(screenShot),
          }
        }))
      }
    }


  }, [selectValue, userMainHypothesis, yData, xData, speedDataFromGraph, codDataFromGraph, messureMentValues, speed2, mappingId]);

  const submitFun = async () => {
    setLoading(true)

    if (mainPayload[Object.keys(mainPayload).find(i => i === selectValue)].yData.length !== undefined) {
      if (mainPayload[Object.keys(mainPayload).find(i => i === selectValue)].yData.length > 0) {
        if (!(selectionOption.findIndex(x => x.name === selectValue) !== -1)) {
          setSelectionOption(prev => [...prev, {
            name: selectValue,
            isChecked: false

          }])
        }
        const sendPayload = {
          userName: user,
          userId: initPayload.id,
          status: 2,
          response_jason: {
            answers: mainPayload,
            hypothesis: userMainHypothesis,
          },
          last_update_date: new Date().toISOString().slice(0, 10),
          explore_step_id: props.exploreId,
        }


        await RestService.postExploreStepStatus(sendPayload)
          .then((response) => {
            if (response?.status === 200) {
              const end = new Date();
              props.getEndDuration(end);
              setCreatingTrigger(1);
              setLoading(false)

            }
            setLoading(false)

          })
      }
    } else {

      if (Object.values(mainPayload[Object.keys(mainPayload).find(i => i === selectValue)].yData).length > 0) {
        if (!(selectionOption.findIndex(x => x.name === selectValue) !== -1)) {
          setSelectionOption(prev => [...prev, {
            name: selectValue,
            isChecked: false

          }])
        }
        const sendPayload = {
          userName: user,
          userId: initPayload.id,
          status: 2,
          response_jason: {
            answers: mainPayload,
            hypothesis: userMainHypothesis,
          },
          last_update_date: new Date().toISOString().slice(0, 10),
          explore_step_id: props.exploreId,
        }



        await RestService.postExploreStepStatus(sendPayload)
          .then((response) => {

            if (response.status === 200) {

              const end = new Date();
              props.getEndDuration(end);
              setCreatingTrigger(1);
              setLoading(false);
            }
            setLoading(false)

          })
      }
      setLoading(false)
    }


  }
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(true);
  const togglePopup = () => {
    if (disableState) {

    }
    else {
      setModal2(!modal2)
    }

  };

  function renderHypothesis(data) {

    if (hypoState === 0) {
      let dropDown =
        data === undefined
          ? null
          : data?.hypotheisOptions.length > 0 ? (
            data?.hypotheisOptions?.map((x, id) => {

              if (data?.hypothesis[0]?.type === 1) {
                return (<div>
                  <strong className="">
                    <div className="form-check m-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="hypothesis"
                        value={x.option}
                        id={id}
                        onChange={handleChange}
                      />
                      &nbsp;
                      {x.option}
                    </div>
                  </strong>
                </div>
                );
              } else if (data?.hypothesis[0]?.type === 2) {
                return (

                  <div>

                    <strong className="">
                      <div className="form-text m-3">
                        <Modal isOpen={modal2} toggle={togglePopup}>
                          <ModalHeader toggle={togglePopup} className="close-hide"></ModalHeader>
                          <ModalBody>
                            <center>{initPayload?.hypothesis[0]?.description}</center>
                            <div className="popup-text">
                              <TextArea rows={5} onChange={handleChange2} />
                            </div>
                          </ModalBody>
                          <ModalFooter className="center">
                            <Button
                              color="success"
                              onClick={() => {
                                setHypoState(1)
                                setModal2(false)
                              }}
                              disabled={disableState}
                            >
                              Submit
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </strong>
                  </div>
                );
              }
            })
          ) : (
            <div>

              <strong className="">
                <div className="form-text m-3">
                  <Modal isOpen={modal2} toggle={togglePopup}>
                    <ModalHeader toggle={togglePopup} className="close-hide"></ModalHeader>
                    <ModalBody>
                      <center>{initPayload?.hypothesis[0]?.description}</center>
                      <div className="popup-text">
                        <TextArea rows={5} onChange={handleChange2} />
                      </div>
                    </ModalBody>
                    <ModalFooter className="center">
                      <Button
                        color="success"
                        onClick={() => {
                          setHypoState(1)
                          setModal2(false)
                        }}
                        disabled={disableState}
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </strong>
            </div>
          )






      return (
        <Row>
          <Col md="8">
            <h3 className="">{initPayload?.hypothesis[0]?.description}</h3>
            <br />
            {(() => {
              if (initPayload?.hypothesis[0]?.type === 1) {
                return (
                  <p className="">Pick a hypothesis below</p>
                )
              } else if (initPayload?.hypothesis[0]?.type === 2) {
                return (
                  <p className="">
                    <Badge
                      className="btn btn-dark border-0 round-0 hype-button"
                      onClick={togglePopup}
                    >
                      Write Hypothesis
                    </Badge></p>
                )
              }
            })}
            {dropDown}


            <br />
          </Col>
          <Col></Col>
        </Row>
      );

    } else {
      return (
        <Row>
          <Col md="8" className="hypo">
            <h3 className="">{initPayload?.hypothesis[0]?.description}</h3>
            <br />
            <Row>
              <Col md="12">
                <fieldset>
                  <legend><strong>Hypothesis </strong> </legend>
                  <p className="f15 hype-text">
                    {userMainHypothesis}
                  </p>
                </fieldset>
              </Col>
            </Row>

            <br />
          </Col>

          <Col></Col>
        </Row>
      )

    }

  }
  useEffect(() => {
    initPayload?.exploreStepExperiment?.map((x) => {
      if (x?.name[0]?.value === nameForValidation) {
        const img = x.images.split(",")
        if (imgExist !== 0) {
          setSrcName(`${img[imgState]}`);
        }
      }
    })
  }, [imgExist, imgState])

  const changeHandler = (event) => {
    setDatasets([{
      label: null,
      yData: [],
      xData: []
    }])
    selectionOption.map(x => {
      x.isChecked = false
    });
    setClickState(2)
    setSelectionOption(selectionOption)
    setChecked(false)
    setGraphMixStatus(false)
    setCellId("");

    setClickState(1);
    const check = event.target.checked;
    const name = event.target.name;
    if (check === true) {
      setChecked(false)
      setImgState(0)
      setImgExist(imgExist + 1);
      setNameForValidation(name);
      drawingCanvas(name);
      selectionOption.map(x => {
        if (x.name === name) {
          x.isChecked = true
        } else {
          x.isChecked = false
        }
      })

      // console.log(initPayload?.chartType[mappingId]?.calculationMethod)
      if (initPayload?.chartType[mappingId]?.calculationMethod === "curve") {
        if (containedPayload?.response_jason?.answers[name].label === name) {

          if (datasets.findIndex(x => x.label2 === name) !== -1) {

            if (clickState !== 0) {

              let res = datasets.filter(x => x.label2 === name)
              setDatasets(res)

            } else {
              setDatasets([{
                label: containedPayload?.response_jason?.answers[name].displayLabel,
                label2: containedPayload?.response_jason?.answers[name].label,
                yData: containedPayload?.response_jason?.answers[name].yData,
                xData: containedPayload?.response_jason?.answers[name].xData,
                color: containedPayload?.response_jason?.answers[name].borderColor,

              }])
            }

          }
          else {
            setDatasets(prev => (
              [
                ...prev,
                {
                  label: containedPayload?.response_jason?.answers[name].displayLabel,
                  label2: containedPayload?.response_jason?.answers[name].label,
                  yData: containedPayload?.response_jason?.answers[name].yData,
                  xData: containedPayload?.response_jason?.answers[name].xData,
                  color: containedPayload?.response_jason?.answers[name].borderColor,

                }
              ]
            ))
          }

        }
      }
      if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar YesNo") {
        if (containedPayload?.response_jason?.answers[name].label === name) {
          if (datasets.findIndex(x => x.label2 === name) !== -1) {
            const sameNamePayload = [{
              label: containedPayload?.response_jason?.answers[name].displayLabel,
              label2: containedPayload?.response_jason?.answers[name].label,
              yData: containedPayload?.response_jason?.answers[name].yData,
              color: containedPayload?.response_jason?.answers[name].yData.map(item => {
                return item?.color
              }),
              borderColor: containedPayload?.response_jason?.answers[name].borderColor,
            }]
            setDatasets(sameNamePayload)
          }
          else {
            setDatasets(prev => (
              [
                ...prev,
                {
                  label: containedPayload?.response_jason?.answers[name].displayLabel,
                  label2: containedPayload?.response_jason?.answers[name].label,
                  yData: containedPayload?.response_jason?.answers[name].yData,
                  color: containedPayload?.response_jason?.answers[name].yData.map(item => {
                    return item?.color
                  }),
                  borderColor: containedPayload?.response_jason?.answers[name].borderColor,
                }
              ]
            ))
          }

        }
      }
      else {
        if (containedPayload?.response_jason?.answers[name].label === name && initPayload?.chartType[mappingId]?.calculationMethod !== "Bar YesNo" && initPayload?.chartType[mappingId]?.calculationMethod !== "curve") {
          if (datasets.findIndex(x => x.label2 === name) !== -1) {

            if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar" || initPayload?.chartType[mappingId]?.calculationMethod === "Line Type5") {
              const sameNamePayload = [{
                label: containedPayload?.response_jason?.answers[name].displayLabel,
                label2: containedPayload?.response_jason?.answers[name].label,
                yData: containedPayload?.response_jason?.answers[name].yData.map(item => {
                  return item?.value
                }),
                color: containedPayload?.response_jason?.answers[name].yData.map(item => {
                  return item?.color
                }),
                borderColor: containedPayload?.response_jason?.answers[name].borderColor,
              }]
              setDatasets(sameNamePayload)
            } else {
              datasets.filter(x => x.label2 === name)
              setDatasets(datasets)
            }

          }
          else {
            setDatasets(prev => (
              [
                ...prev,
                {
                  label: containedPayload?.response_jason?.answers[name].displayLabel,
                  label2: containedPayload?.response_jason?.answers[name].label,
                  yData: containedPayload?.response_jason?.answers[name].yData.map(item => {
                    return item?.value
                  }),
                  color: containedPayload?.response_jason?.answers[name].yData.map(item => {
                    return item?.color
                  }),
                  borderColor: containedPayload?.response_jason?.answers[name].borderColor,
                }
              ]
            ))
          }

        }
      }
      setSelectionOption(selectionOption)
      setCellId(prev => ({
        ...prev,
        [name]: name
      }))
    }
  }
  const changeHandler3 = (event) => {
    const check = event.target.checked;
    const name = event.target.name;
    setDatasets([{
      label: null,
      yData: [],
      xData: []
    }])
    if (check === true) {
      setImgState(0)
      setImgExist(0);
      let canvas = document.getElementById("experimentcanvas");
      ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, 540, 400)
      selectionOption.map(x => {
        x.isChecked = false
      });
      setClickState(1)
      setSelectionOption(selectionOption)
      setChecked(true)
      name.map((x) => {
        if (initPayload?.chartType[mappingId]?.calculationMethod === "curve") {

          if (containedPayload?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label2 === x) !== -1) {

            }
            else {
              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: containedPayload?.response_jason?.answers[x].displayLabel,
                    label2: containedPayload?.response_jason?.answers[x].label,
                    yData: containedPayload?.response_jason?.answers[x].yData,
                    xData: containedPayload?.response_jason?.answers[x].xData,
                    color: containedPayload?.response_jason?.answers[x].borderColor,


                  }
                ]
              ))



            }

          }
        } else if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar" || initPayload?.chartType[mappingId]?.calculationMethod === "Line Type5") {
          if (containedPayload?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label2 === x) !== -1) {

            }
            else {
              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: containedPayload?.response_jason?.answers[x].displayLabel,
                    label2: containedPayload?.response_jason?.answers[x].label,
                    yData: containedPayload?.response_jason?.answers[x].yData.map(i => {
                      return i?.value
                    }),
                    xData: containedPayload?.response_jason?.answers[x].xData,
                    borderColor: containedPayload?.response_jason?.answers[x].borderColor,
                    color: containedPayload?.response_jason?.answers[x].yData.map(i => {
                      return i?.color
                    })


                  }
                ]
              ))



            }

          }
        } else if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar YesNo") {

          if (containedPayload?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label2 === x) !== -1) {

            }
            else {
              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: containedPayload?.response_jason?.answers[x].displayLabel,
                    label2: containedPayload?.response_jason?.answers[x].label,
                    yData: containedPayload?.response_jason?.answers[x].yData.map(i => {
                      return i
                    }),
                    xData: containedPayload?.response_jason?.answers[x].xData,
                    borderColor: containedPayload?.response_jason?.answers[x].borderColor,
                    color: containedPayload?.response_jason?.answers[x].yData.map(i => {
                      return i?.color
                    })


                  }
                ]
              ))



            }

          }
        }
        else {
          if (containedPayload?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label2 === x) !== -1) {


            }
            else {
              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: containedPayload?.response_jason?.answers[x].displayLabel,
                    label2: containedPayload?.response_jason?.answers[x].label,
                    yData: containedPayload?.response_jason?.answers[x].yData.map(i => {
                      return i?.value
                    }),
                    color: containedPayload?.response_jason?.answers[x].yData.map(i => {
                      return i?.color
                    }),
                    borderColor: containedPayload?.response_jason?.response_jason?.answers[x].borderColor,


                  }
                ]
              ))



            }

          }
        }

        setCellId(prev => ({
          ...prev,
          [x]: x
        }))
      })
    }
  }

  const setExColor = (color) => {
    setExperimentColor(color)
  }
  useEffect(() => {
    const createPayloadForAll = []
    selectionOption.map((x) => {
      createPayloadForAll.push(x.name)
    });

    setAllGraphValue(createPayloadForAll)
  }, [selectionOption]);


  const optionsDisplay = selectionOption?.filter(x => x.name !== "undefined").map((x, i) => {
    const styleValue = `ex`;
    return (
      <div className="chk-div">
        <Radio checked={x.isChecked} className="bold" name={x.name} onChange={changeHandler}> <span className={styleValue.toLowerCase()} style={{ backgroundColor: x.color }}  >{x.displayName} </span></Radio >

      </div>
    )
  })

  const onNextValue = () => {
    // setDestatus(true)
    setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

    setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
    setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, clickCount2]]);
    setXData((prevArray) => [...prevArray, clickCount2]);
    setClickCount(clickCount + 1)
    setSwitcher(0)

  }
  var hyp = renderHypothesis(initPayload);
  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };
  const antIcon = <ExclamationCircleOutlined style={{ fontSize: 36 }} />;
  return (
    <Spin tip="Loading..." spinning={loading} size="large">
      <div className="page-with-sub-spinners">
        {/* <Spin tip={loadingText} spinning={loading2} size="large" indicator={antIcon}> */}

        <div className="content explore-page tab-style scroll-tab">
          <div className={`${bluredLayer}`} />
          {/* </div> */}
          <Row>
            <Col md="12">
              {
                initPayload?.description !== "" && initPayload?.description !== null &&
                initPayload?.description !== undefined &&
                <>
                  <CardBody className="mt-10">
                    <h5> LAB DESCRIPTION </h5>
                    <hr className="hr-dark" />
                    <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div>
                  </CardBody>
                </>
              }
            </Col>
          </Row>
          <Row>
            <Col md="12">
              {
                initPayload?.guideline !== "" && initPayload?.guideline !== null &&
                initPayload?.guideline !== undefined &&
                <>
                  <CardBody className="mt-20">

                    <h5> LAB GUIDELINE </h5>
                    <hr className="hr-dark" />
                    <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.guideline }}></div>
                  </CardBody>
                </>

              }

            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="card-chart">
                <CardBody>
                  <Row>
                    <Col md="8" className="hypo">
                      <h3 className="">{initPayload?.hypothesis[0]?.description}</h3>
                      <br />
                      <Row>
                        <Col md="12">
                          <fieldset>
                            <legend><strong>Hypothesis </strong> </legend>
                            <p className="f15 hype-text">
                              {userMainHypothesis}
                            </p>
                          </fieldset>
                        </Col>
                      </Row>
                      <br />
                    </Col>
                  </Row>
                  <Row className={hypoState === 0 ? "blur" : ""}>
                    <Col lg={initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type11" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type7" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type10" ? "4" : "5"} md='12' sm='12'>

                      <Col md="12">
                        <div className="filters">

                          {initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type11" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type6" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type7" &&
                            <div className="chk-div">
                              <Radio disabled={clickState === 0} checked={checked} className="bold" name={allGraphValue} onChange={changeHandler3}>
                                <span className="all"><b>All</b></span>
                              </Radio>
                            </div>
                          }
                          {optionsDisplay}
                        </div>
                      </Col>

                      <Col md="12">
                        <div className="chart">
                          {dataSendToMap.find(x => x.id === id)?.y !== undefined && dataSendToMap.find(x => x.id === id).y !== null &&
                            <>
                              {dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType !== "Line Type10" &&
                                dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType !== "Line Type6" &&
                                dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType !== "Line Type11" &&
                                dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType !== "Line Type12" && (
                                  <>
                                    {yData.length !== 0 || viewData.length !== 0 ? (
                                      <div className="distance">
                                        {clickState !== 0 ? yLabel : clickCount !== 0 ? yLabel : ""}</div>
                                    ) : ""}
                                  </>
                                )}
                              {dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType === "Line Type6" && (
                                <>
                                  {yData.length !== 0 || viewData.length !== 0 ? (
                                    <div className="distance-star-path">
                                      {yLabel}</div>
                                  ) : ""}
                                </>
                              )}

                              {dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType === "Line Type10" && (
                                <>
                                  {yData.length !== 0 || viewData.length !== 0 ? (
                                    <div className="distance">
                                      {yLabel}</div>
                                  ) : ""}
                                </>
                              )}
                              {dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType === "Line Type12" && (
                                <>
                                  {yData.length !== 0 || viewData.length !== 0 ? (
                                    <div className="distance-star-path">
                                      {yLabel}</div>
                                  ) : ""}
                                </>
                              )}
                              {dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType === "Line Type11" && (
                                <>
                                  {yData.length !== 0 || viewData.length !== 0 ? (
                                    <div className="distance">
                                      {yLabel}</div>
                                  ) : ""}
                                </>
                              )}
                              {/* putting new condition constrain as clickState and clickCount isn't a functional for line Type10 */}
                            </>
                          }
                          <ChartValidations
                            type={dataSendToMap?.find(x => x.id === id)?.chart_type[mappingId].graphType
                            }
                            lesson={lesson}
                            chartType={initPayload?.chartType[mappingId]?.calculationMethod}
                            heading={
                              dataSendToMap?.find(x => x.id === id)?.description

                            }
                            data={initPayload?.chartType[mappingId]?.calculationMethod !== "curve" ?
                              secondLessonTablePayload : clickState === 0 ? thirdLessonTablePayload : thirdLessonTablePayloadMixData
                            }
                            chartData={clickState === 0 ? payload : (graphMixStatus ? responseMixData : graphdata1)}
                            dataFromGraph={clickState === 0 ? dataFromGraph : junkDataFromGraph}
                          />
                        </div>
                        <br />
                        <Row>
                          <Col md="12">
                            {
                              initPayload?.chartType[mappingId]?.calculationMethod !== "Bar YesNo" ? <center>
                                {yData.length !== 0 || viewData.length !== 0 ? (
                                  <div className={initPayload?.chartType[mappingId]?.calculationMethod === "Line Type6" ? "time-bublegraph" : initPayload?.chartType[mappingId]?.calculationMethod === "Line Type12" ? "time-bublegraph" : "time"}>
                                    {clickState !== 0 ? xLabel : clickCount !== 0 ? xLabel : ""}</div>
                                ) : ""}
                                <br />
                              </center> : ""
                            }
                          </Col>
                          <Col md="12">


                          </Col>
                          <Col md="1"></Col>

                        </Row>
                      </Col>
                    </Col>
                    {/* <Col lg='6' md='8' sm='8' className="zoom"> */}
                    {/* </Col> */}
                    {initPayload?.chartType[mappingId]?.calculationMethod !== "Bar YesNo" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type7" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type5" ? (
                      <Col lg="" md='' xl="">
                        <CordinateDisplayBox
                          dataTableValue={dataTableValue}
                          chartData={{ xLabel, yLabel, xLabelUnit, yLabelUnit }}
                          data={{
                            clickState: clickState,
                            lesson: initPayload?.lessonCode,
                            chartType: initPayload?.chartType[mappingId]?.calculationMethod,
                            data: initPayload?.chartType[mappingId]?.calculationMethod !== "Bar" ? clickState === 0 ? tablePayload : (graphMixStatus ? responseMixData : tablePayload2) : initPayload?.chartType[mappingId]?.calculationMethod === "Bar" && viewData
                          }} />
                      </Col>
                    ) : ("")}
                    <Col lg="6" md='12' xl="5">
                      <div className="explore-main-container">
                        <div className="explore-measure-img-container" height={400} width={540} >
                          {imgExist !== 0 && initPayload?.chartType[mappingId]?.graphType !== "Line Type7" && initPayload?.chartType[mappingId]?.graphType !== "Line Type10" && initPayload?.chartType[mappingId]?.graphType !== "Bar" && imgExist !== 0 &&
                            <img id="canvasimage" height={400} width={540} className="explore-measure-img" src={srcName} alt="" />
                          }
                          {
                            <Carousel
                              style={contentStyle}
                              afterChange={onChange}
                              className="explore-main-container ant-carousel-style"
                              height={400} width={540}
                              arrows nextArrow={<ArrowRightOutlined />}
                              prevArrow={<ArrowLeftOutlined />}>
                              <div>
                                <canvas id="experimentcanvas" height={400} width={540} className="explore-measure-img-canvas-new"></canvas>
                              </div>
                            </Carousel>
                          }
                          {
                            imgExist !== 0 && initPayload?.chartType[mappingId]?.graphType !== "Line Type7" && initPayload?.chartType[mappingId]?.graphType !== "Line Type10" && initPayload?.chartType[mappingId]?.graphType !== "Bar" && imgExist !== 0 &&
                            <div>
                              <Row>
                                <Col md="6">
                                  <Button
                                    size="sm"
                                    disabled={imgState < 1}
                                    onClick={() => {
                                      setImgState(x => x - 1)
                                    }}
                                  >
                                    <ArrowLeftOutlined className="ant-icon-up" />
                                  </Button>
                                </Col>
                                <Col md="6" style={{ textAlign: "right" }}>
                                  <Button
                                    size="sm"
                                    disabled={containedPayload?.response_jason?.answers && imgState + 1 > containedPayload?.response_jason?.answers[nameForValidation].screenShot.length - 1}
                                    onClick={() => {
                                      setImgState(x => x + 1)
                                    }}
                                  >
                                    <ArrowRightOutlined className="ant-icon-up" />

                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          }
                        </div>
                        <div>
                        </div>
                      </div>
                    </Col>

                  </Row>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </div>
        {/* </Spin> */}
      </div>
    </Spin>
  );
}

export default ExploreMain;