import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes

// reactstrap components
import { CardBody, Row, Col, Badge } from "reactstrap";
import { Button, Drawer, message, Popconfirm, Space, Table, notification, Spin } from "antd";
import "antd/dist/antd.css";
import { CheckCircleOutlined } from '@ant-design/icons';

import { Source1, dataSet1 } from "./data/data";
import Section from "./section";
import LessonService from "../../../../api/LessonService";
import { ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState } from "draft-js";
function cancel(e) {
    
    
}
function Extend(props) {
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState();
  const [tableData,setTableData] =useState([])
  const [editQuestion,setEditQuestion] = useState();
  const [view,setView] = useState(false);
  const [loading, setLoading] = useState(false)
  const showLargeDrawer = () => {
    setSize("large");
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setTableData([])
    setLoading(true)
    LessonService.getAllExtendQuestions(props.extend_id).then(res =>{
      console.log("QList",res.data)
      setTableData(res.data.map( i =>{
        var html = i.description;
        var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";
        return {
            id:i.id,
            description:text,
            ansType: options6.find((x,it) => it === i.question_type_id-1)
        }
    }) ?? [])
    }).finally(()=>{
      setLoading(false)
    })
  };
  useEffect(() =>{
    console.log("size",props.extend_id)
    setTableData([]);
    setLoading(true)
    LessonService.getAllExtendQuestions(props.extend_id).then(res =>{
        console.log("QList",res.data)
        setTableData(res.data.map( i =>{
          var html = i.description;
          var div = document.createElement("div");
          div.innerHTML = html;
          var text = div.textContent || div.innerText || "";
          return {
              id:i.id,
              description:text,
              ansType: options6.find((x,it) => it === i.question_type_id-1)
          }
      }) ?? [])
    }).finally(()=>{
    setLoading(false)
    })
},[props.extend_id])
const options6 = [ 'Short Answer'];
const dataSet1 = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: " Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: " Answer Type",
    dataIndex: "ansType",
    key: "ansType",
  },

  {
    title: " Action",
    dataIndex: "action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        {record.action}
        <Badge className="bg-warning" size="sm" onClick={()=>{
            onEditClick(record.id);
            setView(true)
            
          }}>
          <small>View</small>
        </Badge>
        {
          props.type !==2  && <><Badge className="bg-primary" size="sm" onClick={()=>{
            onEditClick(record.id);
            setView(false)
            
          }}>
  <small>Edit</small>
</Badge>

<Popconfirm
    title="Are you sure to delete this?"
    onConfirm={(e) =>onDelete(record.id)}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  ><Badge  className='bg-danger' size="sm" >
  <small>Remove</small>
  </Badge ></Popconfirm></>
        }
                  
      </Space>
    ),
  },
];
const showNotificationOnTop = (type, message) => {
  notification.open({
    placement: 'top',
    duration: '2',
    description: [
      type === 'success' ?
      <li>
        <CheckCircleOutlined className="text-success ant-icon-up" />
        <span className="ml-4">{message}</span>
      </li>
      : 
      <li>
        <CheckCircleOutlined className="text-danger ant-icon-up" />
        <span className="ml-4">{message}</span>
      </li>
    ]
  })
}
const onDelete =(data) =>{
  setTableData([]);
  setLoading(true)
  LessonService.deleteExtendQuestion(data).then(res =>{
  if(res.status === 200){
    showNotificationOnTop('success', res.message);
    LessonService.getAllExtendQuestions(props.extend_id).then(res =>{
      console.log("QList",res.data)
      setTableData(res.data.map( i =>{
        var html = i.description;
        var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";
        return {
            id:i.id,
            description:text,
            ansType: options6.find((x,it) => it === i.question_type_id-1)
        }
    }) ?? [])
  })
  }
  else{
    showNotificationOnTop('error', res.message);
  }
  }).finally(()=>{
    setLoading(false)
  })
 
}
const onEditClick= (data) =>{
  console.log(data)
  LessonService.getExtendSpecificQuestion(data).then(res =>{
      console.log(res)
      setEditQuestion({
          id:res.data.id,
          description:res.data.description ,
          extend_step_id:res.data.extend_step_id,
          question_type_id:res.data.question_type_id,
          class_level_id:res.data.class_level_id,
          image:res.data.image
      })
      if(Object.keys(res.data).length > 0){
          showLargeDrawer();
      }

  })
 
  
}
  return (
    <>
      <div className="container">
      {props.type !==2 && (
        <Row>
          <Col xs="12" className="pt-2">
            <div className="text-center">
              <button class="btn btn-info" style={{ padding: "8px 32px" }} onClick={() =>{
                                            setEditQuestion(undefined);
                                            setView(false)
                               
                                    showLargeDrawer()
                                
                            }}>
                Add a Section
              </button>
            </div>
          </Col>
        </Row>
      )}
        
        <Row>
          <Col xs="12">
            <CardBody>
              <Row>
                <Col xs="12" className="mb-5">
                  <Table
                    loading={{indicator: <Spin size='large' className='spinner-new' tip='Loading...' />, spinning: loading}}
                    dataSource={tableData}
                    columns={dataSet1}
                    pagination={false}
                    bordered
                    className="thead-dark space-py-20"
                  />
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>
        <Row>
          <Col>
          {props.back}
          </Col>
         
        </Row>
        <Drawer
          title={`Add a Section`}
          placement="right"
          size={size}
          onClose={onClose}
          destroyOnClose={true}
          visible={visible}
        >
          <Section onClose={onClose} view ={view} editQuestion={editQuestion ?? undefined}  currentClassLevel={props.currentClassLevel} extend_id={props.extend_id} />
        </Drawer>
      </div>
    </>
  );
}

export default Extend;
