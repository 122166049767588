
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Config from '../api/Config';

export const getSchools = createAsyncThunk("get/schools", async (accountId) => {
    let token = localStorage.getItem("access_token");
    const response = await axios.get(Config.APIURL +`admin/school/schoolsByAccount/${accountId}`, {
        headers:{
            "Authorization": `Bearer ${token}`
        }
    })
    return response.data
    
})



const initialState = {
    schoolsData: [],
   
}

const schoolSlice = createSlice({
    name: "schools",
    initialState,
    
    reducers: {
        getStudent: (state, action) => {
            state.schoolsData = (action.payload);
        
        },
       

    },
    extraReducers: (builder) => {
        builder.addCase(getSchools.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getSchools.fulfilled, (state, action) => {
            state.loading = false;
            state.schoolsData = action.payload;
            state.error = ''
        })
        builder.addCase(getSchools.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})

export const schoolReducer = schoolSlice.reducer;

export const {  } = schoolSlice.actions;