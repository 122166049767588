

const initialState = {
    token: null,
    username: null,
    password: null,
    loading: false,
    error: null,

};

const authReducer = (state = initialState, action) => {
    switch(action.type) {
        default:
            return state;

    }
};

export default authReducer;
