import React, { useState } from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import regression from 'regression';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);


function SimpleLineChart({ chartData, dataFromGraph }) {



    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        if(context.dataset.pointRadius !== 0){
                            console.log(context.parsed)
                            return `(${(context.parsed.x).toFixed(1)} , ${context.parsed.y})`;
                        }
                    },
                    title: function () {
                    return null;
                    },
                },
                },
        },
        maintainAspectRatio: false,
        responsive:true,

    };
    
    if (chartData?.type === 1) {
        const result = regression.linear(chartData?.datasets[0]?.xyData);
        let regressionData2 = [];
        

        const fitValue = (result?.r2) * 100;
        const speed = chartData?.datasets[0]?.yData[(chartData?.labels?.length) - 1] / chartData?.datasets[0]?.xData[(chartData?.labels?.length) - 1];
        
        dataFromGraph(fitValue, speed.toFixed(3),undefined);
        
         
            for (let i = chartData?.labels?.length-1; i >= 0; i--) {
                let xVal = chartData?.datasets[0]?.xData[i];
                let yVal = chartData?.datasets[0]?.yData[i];;
    
                regressionData2.push({ x: xVal, y: yVal })
            } 
 
        const data2 = {
            datasets: [
                {
                    type: 'scatter',
                    pointStyle: "star",
                    borderColor: '"black"',
                    borderWidth: 1,
                    data: chartData?.datasets[0]?.xyData,
                },
                {
                    type: 'line',
                    pointRadius: 0,
                    pointHoverRadius: 0, // Added property to hide tooltips

                    data: regressionData2,
                    borderColor: chartData?.datasets[0]?.borderColor,
                    borderWidth: chartData?.datasets[0]?.borderWidth,
                }
            ],
        };
        return (
            <>
                <Chart type='bar' data={data2} options={options} />
            </>
        );
    } else {

        const mixDataValue = [];

        chartData.map((x) => {
            // const result = regression.linear(x.xyData);
            let regressionData3 = [];

                for (let i = x.categoryData.length-1; i >= 0; i--) {
                    let xVal = x.xData[i];
                    let yVal = x.yData[i];
                    regressionData3.push({ x: xVal, y: yVal })
                }  

            const payload1 = {
                type: 'scatter',
                pointStyle: "star",
                borderColor: '"black"',
                borderWidth: 1,
                data: x.xyData,
            }

            const payload3 = {
                type: 'line',
                pointRadius: 0,
                pointHoverRadius: 0, // Added property to hide tooltips

                data: regressionData3,
                borderColor: x.borderColor,
                borderWidth: x.borderWidth,
            }


            mixDataValue.push(payload1)
            mixDataValue.push(payload3)

           
        });
        const data = {
            datasets: mixDataValue,
        };


        return (
            <>
                <Chart type='bar' data={data} options={options}  />
            </>
        );
    }




}

export default SimpleLineChart;