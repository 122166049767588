
import axios from "axios";
import Config from "./Config";

class SchoolService {
  getAccountsForUser() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/account/getAccountsByUserId', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // bypassed this promised as it was not getting signed-out;
        if(err.response.status === 403){
          localStorage.removeItem('access_token');
          localStorage.removeItem('userRoutes');
          sessionStorage.setItem("loggedin", false);
          localStorage.removeItem('refresh_token'); 
          localStorage.removeItem('labelLegends');
          localStorage.removeItem('expirationTime');
          const url = (window.location.href).split("#");
          window.open(`${url[0]}#/login`, "_self");
          document.location.reload(true);
          
        }else{
          return Promise.reject(err.response?.data ? err.response?.data : err);
        }
        
      })
  }

  getSchoolsForAccount(accountId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/school/schoolsByAccount/' + accountId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  getSchoolsForAccountPaged(accountId, page, size, search = "") {
    const token = localStorage.getItem("access_token");
    const encodedSearch = encodeURIComponent(search);

    return axios.get(Config.APIURL + 'admin/school/schoolsByAccount_paged/' + accountId + `?page=${page}&size=${size}&search=${encodedSearch}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getSchoolById(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/school/schoolsById/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  saveNewSchool(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/school/addNewSchool', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  updateSchool(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/school/updateSchool', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  getAllSchool() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/school/getAllSchools', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

}
export default new SchoolService();