import { Space, Image, Button } from 'antd';
import thumb from '../../../../../../assets/images/thumb-sm.png'
const dataSource = [
  {
    key: '1',
    userName: 'Introductory Mesurnment',
    totalScore: '42',
    noOfRetakes: '02',
    totalDuration: '25;00min',
    image:'',
  },
  {
  key: '2',
  userName: 'Introductory Mesurnment',
  totalScore: '42',
  noOfRetakes: '02',
  totalDuration: '25;00min',
  image:'',
},
];



export { dataSource }