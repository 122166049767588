import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  CardBody,
  CardTitle,
  Row,
  Col,
  Progress,
  CardImgOverlay,
} from "reactstrap";
import { Tabs, Spin, Button,Tooltip, Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import "antd/dist/antd.css";
import graph from "../../../assets/images/graph-image.png";
import { Link, useParams } from 'react-router-dom';
import { getLessonsByCurriculumId } from "../../../api/TeacherService";
import Config from "../../../api/Config";
import { Switch, } from 'antd';

function Lesson(props) {
  const [alertMessage, setAlertMessage] = useState("d-none"); //  new-alert | d-none
  const [toggleBtnStatus, setToggleBtnStatus] = useState([]);
  const [changedData, setChangedData] = useState([]);
  const [content, setContent] = useState('');
  useEffect(()=>{
    const payload = [];
    const payload2 = []
    props?.data?.data && props.data.data.map((x)=>{
      payload.push(x.status_true)
    })

    props?.data?.data && props.data.data.map((x)=>{
      payload2.push({status: false, name:x.heading})
    })
    setChangedData(payload2);
    setToggleBtnStatus(payload);
  },[props.data])

  let { id } = useParams();

  useEffect(()=>{
    props.getDisableValue(alertMessage !== "d-none")
  },[alertMessage])

  useEffect(()=>{
    const payloadRawData = changedData.filter(function(element){
      return element.status === true;
    })

    if(payloadRawData.length > 0){
      const data = (
        <div style={{display: 'flex', flexWrap: 'wrap', minWidth:"356px", maxWidth:"540px"}}>
  
          {payloadRawData.map((x,index)=>{
              return (<p style={{ 
                flexBasis:payloadRawData.length==1 ?  '100%' : 'calc(50% - 10px)',
                margin: '5px'
                }}><b style={{color:"#1890FF"}}>{index+1} |&nbsp;</b> {x.name}</p>)
          })}

          
        </div>
      )
  
  
      setContent(data)
    }
    else{
      setAlertMessage("d-none")
    }
    
   
  },[changedData])
  
  const display = Object.values(props.data.data).map((x,index) => {
    if (props.data.status === 200) {
      return (
        <>
          <Col lg="3" md="4" sm="6" >
            <Link 
            onClick={() => {
              if (alertMessage !== "d-none") {
                return;
              }
              localStorage.setItem("curriculumLevel", x.heading);
            }}

              to={alertMessage === "d-none"  && `/Teacher/Plan/${id}/${x.id}`}>
              <div class="lesson-image">
                <img src={`${x.image}`} alt="Avatar" height="1000px" class="imageLessonStyle" />
                <div class="overlay">
                  <p className="g-card-top">
                    Introductory{" "}
                  </p>
                  <p className="g-card-title">
                    {x.heading}
                  </p>
                </div>
              </div>
            </Link>
            {props.assignOption === 1 && (
              <div className="cl-toggle">
                <Switch  onChange={(status)=>{
                  setToggleBtnStatus(prevArray=>{
                    const newArray = [...prevArray];
                     newArray[index] = status;
                     return newArray
                  })
                  setChangedData(prevArray=>{
                    const newArray = [...prevArray];
                     newArray[index] = {"status":!newArray[index].status, "name": newArray[index].name};
                     return newArray
                  })
                  props.changeStats(status, parseInt(x.id));
                  setAlertMessage("new-alert")
                }} checked={toggleBtnStatus[index]} />
              </div>
            ) }

            <div className={alertMessage}>
            <div >
              <p>Do you want to change the status of this  
              <Popover content={content} title={<b style={{color:"#1890FF"}}>Lesson List</b>}>
              <span style={{color:"#0f8bfe"}}> {changedData.filter(function(element){
                      return element.status === true;
                  }).length} {changedData.filter(function(element){
                      return element.status === true;
                  }).length <= 1 ? "Lesson" : "Lessons"} ?
              </span>
              </Popover> 
              </p>
            </div>

            <div className="alert-btn-container">
              <Button type="primary" className="mx-2" onClick={()=>{
                setAlertMessage("d-none")
                props.updateChanges();
              }}>
                Yes
              </Button>
              <Button danger ghost className="mx-2 mr-5" onClick={()=>{
                setAlertMessage("d-none")
                props.resetChanges();
              }}>
                No
              </Button>
            </div>

          </div>
          </Col>

        </>
      )

    }
  }, [props.data]);
  return (
    <>
      <div className="container">
          <div className="content mb-5">

            <Row>
              {props.data.data.length > 0 ?
                display
                :
                (
                  <Col md="12">
                    <div>
                      <center>
                      <h5>
                        No Lessons assigned to this curriculum
                      </h5>
                      </center>
                    </div>
                  </Col>
                )
              }
            </Row>
          </div>
      </div>
    </>
  );
}

export default Lesson;