import React, { useEffect, useState } from 'react'
import './style.scss'
import RestService from '../../api/RestService';
import SubSidebarNotification from './subSideNotification';

function SidebarNotification(props) {

    const [header, setHeader] = useState({
        h1: `You have a new comment from Engage`,
        h2: `You have a new comment from Explain`,
        h3: `You have a new comment from Evaluate`,
        h4: `You have a new comment from Extend`,
        h5: `You have a new comment from Explore`
      });

      const [definedComments, setDefinedComments] = useState([]);
    const [LessonNames, setLessonNames] = useState([{}])
    const [structureData, setStructureData] = useState([{}])
    const [data, setData] = useState({})
    useEffect( ()=>{
         RestService.getAllStepStudentNotificationDetails().then((response) => {
           if(response?.status === 200){
            setData(response.data)
           }
         })

         setDefinedComments([
            {
                value:"engageStep",
                data:header.h1
            },
            {
                value:"explainStep",
                data:header.h2
            },
            {
                value:"evaluateStep",
                data:header.h3
            },
            {
                value:"extendStep",
                data:header.h4
            },
            {
                value:"exploreStep",
                data:header.h5
            },
        ])

       }, [header]);
    
    useEffect( ()=>{
        const lessonIdentify = [];
        const dataList = [];
        
        Object.entries(data).map((x)=>{
            Object.values(x[1]).map((y)=>{
                if(!lessonIdentify.find(obj => obj.id === y.lessonId)){
                    lessonIdentify.push({lessonName:y.lessonName, id:y.lessonId})
                }
            })
            Object.values(x[1]).map((y)=>{
                dataList.push({
                    id:y.lessonId,
                    classId:y.clssId,
                    className:y.className,
                    stepId:Object.values(y)[0],
                    step:x[0],
                    data:definedComments.find(obj => obj.value === x[0]).data,
                })
            })
        })
        setLessonNames(lessonIdentify);
        setStructureData(dataList);
        localStorage.setItem("notificationCount",dataList.length)

        props.notificationCount(dataList.length)
      }, [data]);
      
      
    return (
        <>
        {LessonNames.map((x) => {
            return(
            <div className="my-3">
                {/* this is the lesson name */}
                <br/>
                <h6 className='text-white'>{x.lessonName}</h6>
                {/* this would be showing the notification */}
                
                {
                    structureData.map((y) => {
                        if(y.id === x.id){
                               return(
                                <>
                                    <SubSidebarNotification data={y} />
                                </>
                               )
                        }
                    })
                }
               
            </div>
            )
      })}
        </>
    )
}

export default SidebarNotification