export default function validate(obj) {
  let errors = {};
  let values = { ...obj };
  let specialChar = /^((?![-_!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+).)*$/gm;


  if(values.selectOption.length <= 0){
    errors.selectOption = 'Curriculum is required';
  }

  // if (!values.LessonNameField || values.LessonNameField == '' || !values.LessonNameField.replace(/\s/g, '').length) {
  //   errors.LessonNameField = 'Lesson Title is required';
  // } else if (values.LessonNameField.length > 50) {
  //   errors.LessonNameField = 'Lesson Title  must be less than 50 characters';
  // } else if (!values.LessonNameField.match(specialChar)) {
  //   errors.LessonNameField = 'Lesson Title can not have special characters';
  // }

  // if (!values.selectOption || values.selectOption == '') {
  //   errors.selectOption = 'Curriculum is required';
  // }
  return errors;
};