import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap'

import welcomeImage from "../../../../assets/images/Learn.png"
import School from './../Schools/index';
import WelcomeData from './Components/WelcomeData';
import StatCard from './Components/StatCard';
import { DatePicker, Select } from 'antd';
import StudentChart from './Components/ApexCharts/StudentChart';
import LessonChart from './Components/ApexCharts/LessonChart';
import DashboardService from '../../../../api/DashboardService';
import jsonToken from 'jsonwebtoken'
import SchoolService from '../../../../api/SchoolService';

const { RangePicker } = DatePicker;


export default function Dashboard({activeTab, isActive}) {

    let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
    const [data, setData] = useState({
        TotalLessons: {
            value: 0,
            totalValue: 0,
            spike: 0
        },
        TotalClasses: {
            value: 0,
            totalValue: 0,
            spike: 0
        },
        TotalStudents: {
            value: 0,
            totalValue: 0,
            spike: 0
        },
    });
    const [classList, setClassList] = useState([]);
    const [schoolDisable, serSchoolDisable] = useState(true);
    const [accountDisable, setAccountDisable] = useState(false);
    const [studentData, setStudentData] = useState([]);
    const [lessonData, setLessonData] = useState([]);
    const [totalStudentAndLessonData, setTotalStudentAndLessonData] = useState([]);
    const [filter1, setFilter1] = useState("")
    const [filter2, setFilter2] = useState("");
    const [classValue, setClassValue] = useState({
        value: -1,
        label: "All",
    });

    //for download files
    const [tittle, setTittle] = useState("");
    const [tittleSelected, setTittleSelected] = useState("All");
    const [tittleDate, setTittleDate] = useState("");
    //for Disable trigger
    const [datesDisable, setDatesDisable] = useState(null);
    const [datesValue, setDatesValue] = useState(null);

    const [accountData, setAccountData] = useState([]);
    const [accountId, setAccountId] = useState(null);
    const [schoolData, setSchoolData] = useState([]);
    const [schoolId, setSchoolId] = useState(null);




    function loadAccounts() {
        setAccountData([]);
        setSchoolData([]);
        serSchoolDisable(true);
        setSchoolId(null);
        SchoolService.getAccountsForUser()
            .then((response) => {
                if (response?.data) {
                    const data = response.data.accountList.map((value) => {
                        return { value: value.id, label: value.name };
                    })

                    data.unshift({ value: null, label: "All" });

                    setAccountData(data)

                    if (accessToken.roles[0] !== "SUPER_ADMIN") {
                        if (data && data.length <= 2) {
                            setAccountId(data[1].value);
                            loadSchools(data[1].value);
                            setAccountDisable(true);
                        }
                    }
                }
            }).catch((err) => {
                console.log(err.message);
            })
    }

    function loadSchools(accountId) {
        if (accountId) {
            setSchoolData([]);
            setSchoolId(null);
            SchoolService.getSchoolsForAccount(accountId)
                .then((response) => {
                  if (response?.data) {  
                    const data = response.data.map((value) => {
                        return { value: value.id, label: value.name };
                      })
                      data.unshift({ value: -1, label: `All` });
                    setSchoolData(data);
                    setSchoolId(-1);
                    serSchoolDisable(false);
                  }
                }).catch((err) => {
                    console.log(err.message);
                })
        }
    }

    // Function to format date in D/M/YYYY format
    function formatDate(d) {
        return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
    }

    // Function to find label by value
    function findLabelByValue(value) {
        const item = classList.find(item => item.value === value);
        return item ? item.label : 'Undefined';
    }

    // Get today's date
    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);


    // Calculate the difference in milliseconds
    const differenceInMilliseconds = today - oneMonthAgo;

    // Convert milliseconds to days (1000 milliseconds = 1 second, 60 seconds = 1 minute, 60 minutes = 1 hour, 24 hours = 1 day)
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    // Get the date 30 days ago
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - differenceInDays);


     const getDashboardSummaryToLoggedUser = (aId, sId) => {
        DashboardService.getDashboardSummaryToLoggedUser(aId, sId)
        .then((response) => {
            if(response?.status === 200){
                setData({
                    TotalStudents: {
                        value: response.data.studentCountDto.activeStudentsCount || 0,
                        totalValue: response.data.studentCountDto.totalStudentCount || 0,
                        spike: response.data.studentCountDto.lastMonthCount !== 0 ? Math.round(((response.data.studentCountDto.lastMonthCount - response.data.studentCountDto.monthBeforeLastCount) / response.data.studentCountDto.lastMonthCount)*100) : parseInt(-response.data.studentCountDto.monthBeforeLastCount*100)
                    },
                    TotalLessons: {
                        value: response.data.lessonCountDto.activeLessonsCount || 0,
                        totalValue: response.data.lessonCountDto.totalLessonCount || 0,
                        spike: response.data.lessonCountDto.lastMonthCount !== 0 ? Math.round(((response.data.lessonCountDto.lastMonthCount - response.data.lessonCountDto.monthBeforeLastCount) / response.data.lessonCountDto.lastMonthCount)*100) : parseInt(-response.data.lessonCountDto.monthBeforeLastCount*100)
                    },
                    TotalClasses: {
                        value: response.data.classCountDto.activeClassesCount || 0,
                        totalValue: response.data.classCountDto.totalClassesCount || 0,
                        spike: response.data.classCountDto.lastMonthCount !== 0 ? Math.round(((response.data.classCountDto.lastMonthCount - response.data.classCountDto.monthBeforeLastCount) / response.data.classCountDto.lastMonthCount)*100) : parseInt(-response.data.classCountDto.monthBeforeLastCount*100)
                    }
                });
            }
        })
     }

    useEffect(()=>{
        
        getDashboardSummaryToLoggedUser(null,null);
        if(accessToken.roles[0] !== "TEACHER"){
            loadAccounts();
        }
        DashboardService.getAllClassesToLoggedUser().then((response) => {
            if (response?.status === 200) {
                const dataResult = response.data.map(item => ({
                    value: item.classId,
                    label: item.className,
                }))
                dataResult.unshift({
                    value: -1,
                    label: "All",
                })
                setClassList(dataResult)
            }
        })
 
        // remove this to useEffect to date
        DashboardService.getStudentsGraphDataToLoggedUser("", "").then((response) => {
            if (response?.status === 200) {
                setStudentData(response.data)
            }
        })

        DashboardService.getLessonsAndStudentCountDataToLoggedUser("", "").then((response) => {
            if (response?.status === 200) {
                setTotalStudentAndLessonData(response.data)
            }
        })



        DashboardService.getLessonsGraphDataToLoggedUserRole("", "").then((response) => {
            if (response?.status === 200) {
                setLessonData(response.data)
            }
        })

        // Format dates and output them in the desired format
        setTittleDate(`(${formatDate(thirtyDaysAgo)} to ${formatDate(today)})`)
    },[]);

    useEffect(()=>{
        if(isActive){
            serSchoolDisable(true);
            setSchoolData([]);
            setSchoolId(null);
            setAccountId(null);
            
            setFilter1("");
            setTittleSelected("All")
            setClassValue(-1)



            getDashboardSummaryToLoggedUser(null,null);
            if(accessToken.roles[0] !== "TEACHER"){
                loadAccounts();
            }
            DashboardService.getAllClassesToLoggedUser().then((response) => {
                if(response?.status === 200){
                    const dataResult = response.data.map(item => ({
                        value: item.classId,
                        label: item.className,
                      }))
                      dataResult.unshift({
                        value: -1,
                        label: "All",
                      })
                    setClassList(dataResult)
                }
            })
        }

    },[activeTab])

    useEffect(() => {
        setTittle(`${tittleSelected} ${tittleDate}`)
    }, [tittleDate, tittleSelected])

    useEffect(() => {
        DashboardService.getStudentsGraphDataToLoggedUser(filter1, filter2).then((response) => {
            if (response.status === 200) {
                setStudentData(response.data)
            }
        })

        DashboardService.getLessonsAndStudentCountDataToLoggedUser(filter1, filter2).then((response) => {
            if (response?.status === 200) {
                setTotalStudentAndLessonData(response.data)
            }
        })

        DashboardService.getLessonsGraphDataToLoggedUserRole(filter1, filter2).then((response) => {
            if (response.status === 200) {
                setLessonData(response.data)
            }
        })
    }, [filter2, filter1])

    const onChange = (value) => {
        // console.log(`selected ${value}`);
        setClassValue(value)
        if (value === -1) {
            setFilter1("");
            setTittleSelected("All")
        } else {
            setFilter1(value);
            setTittleSelected(findLabelByValue(value))
        }
    };

    const onChange2 = (value) => {
        if(value){
            getDashboardSummaryToLoggedUser(value,null);
            loadSchools(value);
            setAccountId(value)
        }else{
            getDashboardSummaryToLoggedUser(null,null);
            serSchoolDisable(true);
            setSchoolData([]);
            setSchoolId(null);
            setAccountId(null);
        }
    }
    const onChange3 = (value) => {
        const schoolValue = value === -1 ? null : value 
        getDashboardSummaryToLoggedUser(accountId,schoolValue);
        setSchoolId(value)
    }
     // Function to be called when the range changes
    const handleRangeChange = (dates,dateStrings) => {
        // `dates` is an array of moment objects for the start and end dates
        // `dateStrings` is an array of the dates in string format
        setDatesValue(dates)
        if (dates) {
            setFilter2(`${dateStrings[0]}%2C${dateStrings[1]}`)
            setTittleDate(`(${dateStrings[0]} to ${dateStrings[1]})`);

        } else {
            setFilter2("");
            setTittleDate(`(${formatDate(thirtyDaysAgo)} to ${formatDate(today)})`);
        }

        // Here you can handle the change, for example, update the state or make an API call
    }

    const disabledDate = (current) => {
        if (!datesDisable) {
            return false;
        }
        const tooLate = datesDisable[0] && current.diff(datesDisable[0], 'days') < 3;
        const tooEarly = datesDisable[1] && datesDisable[1].diff(current, 'days') < 3;
        return !!tooEarly || !!tooLate;
    };

    const onOpenChange = (open) => {
        if (open) {
            setDatesDisable([null, null]);
        } else {
            setDatesDisable(null);
        }
    };



    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <div className="container-fluid admin-tabs">
            <Row>
                <Col md="12">
                    <Card className="border-0">
                        <CardBody className="e">
                            <WelcomeData user={accessToken?.firstName ? `${accessToken?.firstName} ${accessToken?.lastName ? accessToken?.lastName : ""}` : "User"} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                {accessToken.roles[0] !== "TEACHER" ? (
                    <>
                        <Col md={6} sm={12}>
                            <Card className="border-0">
                                <CardBody className="e dashboard-title">
                                    <h6>Account and School</h6>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} sm={12}>

                            <Card className="border-0">
                                <CardBody className="e dashboard-title">
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-end" }}>
                                        <div className='mr-2'>
                                            <Select
                                                showSearch
                                                disabled={accountDisable}
                                                placeholder="Select an Account"
                                                optionFilterProp="children"
                                                onChange={onChange2}
                                                style={{
                                                    minWidth: 320
                                                }}
                                                value={accountId}
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={accountData}
                                            // allowClear={true}
                                            />
                                        </div>
                                        <div>
                                            <Select
                                                showSearch
                                                disabled={schoolDisable}
                                                placeholder={schoolDisable ? "Select an Account First" : "Select a School"}
                                                optionFilterProp="children"
                                                onChange={onChange3}
                                                value={schoolId}
                                                style={{
                                                    minWidth: 320
                                                }}
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={schoolData}
                                            />

                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                    </>
                ) : (<></>)}
            </Row>
            {/* Total Students : Total Classes : Total Lessons
            pass exact names for the title to function icons correctly  */}
            <Row>
                <Col lg={4} md={4} sm={12} xs={12}>
                    <StatCard title="Total Lessons" value={data["TotalLessons"].value} totalValue={data["TotalLessons"].totalValue} compired={data["TotalLessons"].spike} />
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                    <StatCard title="Total Classes" value={data["TotalClasses"].value} totalValue={data["TotalClasses"].totalValue} compired={data["TotalClasses"].spike} />
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                    <StatCard title="Total Students" value={data["TotalStudents"].value} totalValue={data["TotalStudents"].totalValue} compired={data["TotalStudents"].spike} />
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Card className="border-0">
                        <CardBody className="e dashboard-title">
                            <h6>Classes</h6>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} sm={12}>
                    <Card className="border-0">
                        <CardBody className="e dashboard-title">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-end" }}>
                                <div className='mr-2'>
                                    <Select
                                        showSearch
                                        placeholder="Select Class"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        // onSearch={onSearch}
                                        filterOption={filterOption}
                                        options={classList}
                                        style={{
                                            minWidth: 320
                                        }}
                                        value={classValue}
                                    />
                                </div>
                                <div>
                                    <RangePicker
                                        style={{
                                            minWidth: 320
                                        }}
                                        value={datesDisable || datesValue}
                                        onChange={handleRangeChange}
                                        disabledDate={disabledDate}
                                        onOpenChange={onOpenChange}
                                        onCalendarChange={(val) => setDatesDisable(val)}
                                        placeholder={['Start Date', 'End Date']}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <Card className="border-0">
                        <CardBody className="e">
                            <StudentChart StudentData={studentData} selection={tittle} totalStudentAndLessonData={totalStudentAndLessonData} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <Card className="border-0">
                        <CardBody className="e">
                            <LessonChart LessonData={lessonData} selection={tittle} totalStudentAndLessonData={totalStudentAndLessonData} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
