import { useEffect, useRef, useState } from "react";
// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Breadcrumb, Radio, Input, Form, Modal, Button, Tabs, Checkbox, Image, Spin } from "antd";
import "antd/dist/antd.css";
import jsonToken from 'jsonwebtoken';
import RestService from "../../../api/RestService";
import AnswerDisplay from "../AnswerDisplay";
import FloatingButton from "../../../components/FloatingButton";
import InstructorComment from "../components/InstructorComment/InstructorComment";
import Pdf from "../../../components/Pdf";
import { Tour as Tour5 } from 'antd-v5';
import LessonService from "../../../api/LessonService";


function Extend(props) {
  const [payload, setPayload] = useState();

  const [initPayload, setInitPayload] = useState({
    description: "",
    extendStepQuestionAnswers: [],
    extendStepQuestions: [],
    id: "",
    lesson_id: "",
    guideline: "",
  });

  const [containedPayload, setContainedPayload] = useState({});
  const [reviewStatus, setReviewStatus] = useState(props.disabled ?? true);
  const [lesson, setLesson] = useState(parseInt(props.extendId));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");
  const [lessonName, setLessonName] = useState("");
  const [loading, setLoading] = useState(true);
  const [freezingStatus, setFreezingStatus] = useState(false)
  const [teacherComment, setTeacherComment] = useState("");
  const [teacherCommentStats, setTeacherCommentStats] = useState(false);
  function convert(data) {
    return Array.isArray(data)
      ? data.reduce((obj, el, i) => (el + 1 && (obj[i] = convert(el)), obj), {})
      : data;
  }

  let user = "";
  let firstName = "";
  let sendUserIdValue = ""

  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;
    firstName = accessToken.firstName;
    sendUserIdValue = accessToken.userId

  }

  const ref1 = useRef(null);
  const [open, setOpen] = useState(false);
  const steps = [
    {
      title: 'Question',
      description: 'Write the answers in the given areas to gain extra credit.',
      target: () => ref1.current,
    },
  ];


  // useEffect(()=>{
  //   if(open){
  //     window.scrollTo(0,0);
  //   }
  // },[open])

  useEffect(() => {
    setLoading(true)
    RestService.getExtendInfosById(lesson)
      .then((response) => {
        setInitPayload(response?.data);
        setLoading(false)
        setOpen(props.firstTime);
        setLessonName(response?.data?.lessonName);
      })
  }, [])

  useEffect(() => {
    setLoading(true)
    RestService.getExtendStepByIdAndUserName(user, lesson)
      .then((response) => {
        if (response) {
          if (response?.data[0]?.evaluated !== 0) {
            setFreezingStatus(false) //change this to true when freezing feature comes back
          } else {
            setFreezingStatus(false)
          }
          if (response?.data[0]?.comment !== null && response?.data[0]?.comment !== undefined && response?.data[0]?.comment !== "") {
            setTeacherCommentStats(true)
            setTeacherComment(response?.data[0]?.comment)
          }
          setContainedPayload(response?.data[0]?.response_json?.answers);
          setPayload(convert(response?.data[0]?.response_json?.answers ?? []))

          setLoading(false)
        } else {
          setPayload(convert([]))
        }
      })
  }, [])

  const { TabPane } = Tabs;

  const [classLessonName, setClassLessonName] = useState(localStorage.getItem("className"));
  const { TextArea } = Input;

  const ReviewFun = () => {
    setReviewStatus(false)
    window.scrollTo(0, 0)
  };
  const changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const count = parseInt(name) + 1;
    let index = null;
    if (Object.values(payload)?.findIndex(x => x.question_id === count) !== -1) {
      Object.values(payload)[Object.values(payload)?.findIndex(x => x.question_id === count)].answer = value
    }
    else {
      setPayload(prev => ({
        ...prev,
        [name]: { question_id: count, answer: value }
      }));
    }
    // for (const [key, value] of Object.entries(payload)) {
    //   if(value.question_id == count){
    //     index = key;
    //     break;
    //   }
    // }
    // if(index != null && payload !== undefined && payload !== null){
    //   let changed_payload = payload;
    //   changed_payload[index] = { question_id: count, answer: value };
    //   setPayload(changed_payload);
    // }else{

    // }

  };

  const submitFun = async () => {
    if (payload === undefined) {
      setValidationMsg("Answer at least one question to proceed")
      setIsModalOpen(true);
    }
    else if (initPayload.extendStepQuestions.length > Object.keys(payload).length) {
      setValidationMsg("Did you finish all parts of your extra-credit report? You can review any part of your work on the lesson by navigating through the tabs")
      setIsModalOpen(true);

    } else {
      setLoading(true)
      const submitPayload = {
        username: user,
        userId: initPayload.id,
        status: 2,
        response_json: {
          answers: Object.values(payload)
        },
        last_update_date: new Date().toISOString().slice(0, 10),
        extend_step_id: lesson,
      }


      await RestService.postExtendStepUserResponses(submitPayload)
        .then((response) => {

          if (response.status === 200) {

            const end = new Date();
            props.getEndDuration(end);
            const url = (window.location.href).split("#");
            window.open(url[0], "_self")
          }
          setLoading(false)


        })
    }
  }

  const submitFun2 = async () => {
    const url = (window.location.href).split("#");
    window.open(url[0], "_self")
  }

  const handleOk1 = async () => {
    setReviewStatus(false);
    setIsModalOpen(false);
    if (payload !== undefined) {
      setLoading(true)

      const submitPayload = {
        username: user,
        userId: initPayload.id,
        status: 2,
        response_json: {
          answers: Object.values(payload)
        },
        last_update_date: new Date().toISOString().slice(0, 10),
        extend_step_id: lesson,
      }
      await RestService.postExtendStepUserResponses(submitPayload)
        .then((response) => {

          if (response.status === 200) {
            const end = new Date();
            props.getEndDuration(end);
            const url = (window.location.href).split("#");
            window.open(url[0], "_self")
          }
          setLoading(false)
        })
    }
  };

  const handleCancel1 = () => {

    setReviewStatus(true);
    window.scrollTo(0, 0);
    setIsModalOpen(false);
  };
  const TSdate = Date.now();
  const dateFormat = new Date(TSdate);
  const MonthYear = dateFormat.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
  const HrsMinSec = dateFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });

  const uploadBtnFun = (x) => {
    setLoading(x)
  }

  const [header, setHeader] = useState({
    h1: `Review`,
    h2: `<p>
      Answer all questions. Use any information in the graph window.<br />
      Click <b>Submit</b> when you finish.
      </p>`,
    h3: `LAB DESCRIPTION `,
    h4: `LAB GUIDELINE `,
    h5: `Hypothesis`,
    h6: `Review`,
    h7: `Exit`,
    h8: `Your answer will be graded. Do you wish to continue? `,
    h9: `Submit Answers `,
    h10: `Questions`,
    username: user,
    classLevelName: localStorage.getItem("className"),
    ok: `OK`,
    cancel: `Cancel`,
  });

  return (
    <Spin tip="Loading..." spinning={loading} size="large">
      <div className="content extend-page tab-style scroll-tab">
        <div className="card-chart p-3 card card-grey no-border">
          <div className="card-body">
            <Modal title="" visible={isModalOpen} onOk={handleOk1} onCancel={handleCancel1}
              okText={header.ok}
              cancelText={header.cancel}>
              <p>{validationMsg} </p>
            </Modal>

           <div id="headerDescriptionExtend">
              <h2><center><span class="notranslate" id="header1info">Smart Science <sup>&reg;</sup>{ header?.h1 }</span></center></h2>
              <p align="center" className="tf15" id="header2info" family="CarmenSans-Regular">
                {`${firstName} - ${header.classLevelName} - ${lessonName}`}
              </p>
              <p align="center" className="tf15" id="header3info" family="CarmenSans-Regular">
               <span class="notranslate">{`${MonthYear}, ${HrsMinSec} `}</span>
              </p>
              <center><br />

                <div className="f18 middle" id="header4info" dangerouslySetInnerHTML={{ __html: header?.h2 }}></div>
              </center>
           </div>
          </div>
          <div className="card-body" >
            <div id="descriptionExtend">
                {
                  initPayload?.description !== "" &&
                  initPayload?.description !== null &&
                  initPayload?.description !== undefined &&
                  <>
                    <h5 id="description1Extend"> {header?.h3} </h5>
                    <hr className="hr-dark" />
                    <div  id="lab-description" >
                    <div id="description2Extend" className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div>
                    </div>
                  </>
                }<br />
            </div>
            <div id="guidelineExtend" >
                {
                  initPayload?.guideline !== "" &&
                  initPayload?.guideline !== null &&
                  initPayload?.guideline !== undefined &&
                  <>
                    <br />
                    <h5 id="guideline1Extend"> {header?.h4} </h5>
                    <hr className="hr-dark" />
                    <div id="lab-guideline">
                    <div  className="f18 guidelines-description-table" id="guideline2Extend" dangerouslySetInnerHTML={{ __html: initPayload?.guideline }}></div>

                    </div>

                  </>

                }<br />
            </div>
          </div>
        </div>

        <div className="card-chart  card card-grey">
          <div className="card-body" id="bodyDescriptionExtend">
            <Row className="">
              <Col xs="12">
                <Card className="bg-evaluate border-0 rounded-0 no-border guidelines-description-table">
                  <CardBody className="">
                    <AnswerDisplay refPass={ref1} data={{
                      from:"Extend",
                      type: 2,
                      payload: initPayload?.extendStepQuestions,
                      changeHandler: changeHandler,
                      containedPayload: containedPayload,
                      // freezingStatus: freezingStatus
                    }} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {
            teacherCommentStats && <InstructorComment from={"Extend"} data={teacherComment} />
          }
        </div>
        <div className="card-chart  card card-grey">
          <div className="card-body" >
            <Row>
              <FloatingButton tourStatus={(n)=>{setOpen(n); window.scrollTo(0,0)}} name={!props.complted && reviewStatus ? header?.h6 : freezingStatus ? header?.h7 : header?.h9} onClick={!props.complted && reviewStatus ? ReviewFun : freezingStatus ? submitFun2 : submitFun} status={true}/>
            </Row>
            <Row className="pt-4">
              <Col md="12" >
                <Pdf
                  data={{
                    body: "bodyDescriptionExtend",
                    bodyCount: initPayload?.extendStepQuestions.length,
                    comment: teacherComment
                  }}
                  from="student"
                  uploadBtnFun={uploadBtnFun}
                  headingData={{
                    lessonName: lessonName,
                    btnStatus: "Extend",
                    status: (initPayload?.description !== "" &&
                      initPayload?.description !== null &&
                      initPayload?.description !== undefined) || (initPayload?.guideline !== "" &&
                        initPayload?.guideline !== null &&
                        initPayload?.guideline !== undefined)
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Tour5 disabledInteraction={true}  open={open} onClose={() => {
         try{
            LessonService.updateUserRoutes(sendUserIdValue,'extend', 1)
            
            let userRoutes = JSON.parse(localStorage.getItem('userRoutes'));
            userRoutes.userRoute.extend = 1;
            localStorage.setItem('userRoutes', JSON.stringify(userRoutes));
          }catch(err){
            console.log(err)
          }
        setOpen(false)}} steps={steps} />
      <div id="temp-content"></div>
    </Spin>
  );
}

export default Extend;
