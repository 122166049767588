import React from 'react'
import MultipleMessages from '../dashboard/components/MultipleMessages'
import AddExplorerImagesUpload from '../Admin/CreateLesson/components/AddExplorerImagesUpload'
function Test() {
    const messages=[
        { type: 'success', text: 'Success message' },
      { type: 'warning', text: 'Warning message' },
      { type: 'error', text: 'Error message' }
      ]
  return (
    <>
        <AddExplorerImagesUpload />

    </>
  )
}

export default Test