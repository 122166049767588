import { useState, useEffect, useRef } from "react";
import { Input, FormFeedback, Row, Col, Button } from "reactstrap";
import { Alert, notification, Select, Spin, Transfer } from 'antd';
import "antd/dist/antd.css";
import moment from 'moment';
import jsonToken from 'jsonwebtoken'
import ClassService from "../../../../api/ClassService"
import { getTeacherList } from "../../../../api/TeacherService";
import useCustFormValidator from "../../../../hooks/useCustFormValidator";
import validate from './classFormValidationRules';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";
import { getTermByTermID } from "../../../../api/TermService";

function ClassesCreate(props) {
    let accessToken = jsonToken.decode(localStorage.getItem("access_token"));

    const [initData, setInitData] = useState({ classLevels: [], curriculams: [], terms: [] });
    const [loading, setLoading] = useState(false);
    const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });

    const [accountId, setAccountId] = useState(null);
    const [schoolId, setSchoolId] = useState(null);

    const [accounts, setAccounts] = useState([]);
    const [schools, setSchools] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [resetAccount, setResetAccount] = useState(false);
    const [selectedTermId, setSelectedTermId] = useState(null)

    let startDateRef = useRef();
    let endDateRef = useRef();

    let intiFormData = {
        accountId: -1,
        organizationId: -1,
        description: "",
        name: "",
        "startDate": moment().format('yyyy-MM-DD'),
        "endDate": moment().add(1, 'years').format('yyyy-MM-DD'),
        "status": 1,
        "quizGrade": 0,
        "classLevelId": -1,
        "hypothesisType": -1,
        "curriculamId": -1,
        "teacherId": -1,
        "termId": -1,
        // "classCode": "",
    }
    const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(saveClass, validate, intiFormData);

    useEffect(() => {
        getInitData();
    }, []);

    useEffect(() => {
        if (selectedTermId >= 0) {
            getTermByID(selectedTermId)
        }
        else {
            startDateRef.current.disabled = false;
            endDateRef.current.disabled = false;
            setFormData({ ...values, startDate: intiFormData.startDate, endDate: intiFormData.endDate })
        }
    }, [selectedTermId])

    const getTermByID = async (id) => {
        setLoading(true);
        if (id) {
            await getTermByTermID(id)
                .then((response) => {
                    if (response) {
                        let data = response.data;
                        setFormData({ ...values, startDate: data.started_date, endDate: data.end_date })
                        startDateRef.current.disabled = true;
                        endDateRef.current.disabled = true;
                    }
                })
                .catch((error) => {
                    console.log("error :", error.message)
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        // if (!values.organizationId || values.organizationId <= 0)
        //     return
        loadTeachers();
    }, [schoolId]);

    function loadTeachers() {
        if (!accountId || accountId <= 0) {
            setSchools([]);
            return;
        }
        setLoading(true);
        getTeacherList(values.organizationId)
            .then((response) => {
                if (response.data) {
                    setTeachers(response.data);
                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        presecAccount();
    }, [accounts])

    useEffect(() => {
        presetSchool();
    }, [schools])

    useEffect(() => {
        if (resetAccount) {
            presecAccount();
            presetSchool();
            preselectTeacher()
            setResetAccount(false);
        }
    }, [resetAccount])

    useEffect(() => {
        preselectTeacher();
    }, [teachers])

    function presecAccount() {
        if (accessToken.roles[0] === "SCHOOL_ADMIN" || accessToken.roles[0] === "TEACHER" || accessToken.roles[0] === "ACCOUNT_ADMIN") {
            if (accounts.length === 1) {
                setAccountId(accounts[0].id)
                setFormData({ ...values, accountId: accounts[0].id });
            }
        } else {
            if (accounts.length > 1) {
                setAccountId(-1)
                setFormData({ ...values, accountId: -1 });
            }
        }
    }

    function presetSchool() {
        if (accessToken.roles[0] === "SCHOOL_ADMIN" || accessToken.roles[0] === "TEACHER") {
            if (schools.length === 1) {
                setFormData({ ...values, organizationId: schools[0].id });
                setSchoolId(schools[0].id);
            }
        } else {
            if (schools.length >= 1) {
                setFormData({ ...values, organizationId: -1 });
                setSchoolId(-1);
            }
        }
    }

    function preselectTeacher() {
        if (accessToken.roles[0] === "TEACHER") {
            if (teachers.length > 0) {
                setFormData({ ...values, teacherId: accessToken.userId });
                // let teacher = teachers.find(i => i.id === accessToken.userId)
                // setTeachers([teacher]);
            }
        } else {
            if (teachers.length < 0) {
                setFormData({ ...values, teacherId: -1 });
            }
        }
    }

    function getInitData() {
        setLoading(true);
        ClassService.getCreateClassInitData()
            .then((response) => {
                if (response.data) {
                    setInitData(response.data);
                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
                loadAccounts();
            });
    }

    function loadAccounts() {
        setLoading(true);
        ClassService.getAccountsForUser()
            .then((response) => {
                if (response?.data?.accountList) {
                    setAccounts(response.data.accountList);
                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        function loadSchools() {
            if (!accountId || accountId <= 0) {
                setSchools([]);
                setTeachers([]);
                return;
            }
            setLoading(true);
            ClassService.getSchoolsForAccount(accountId)
                .then((response) => {
                    if (response?.data) {
                        setSchools(response.data);
                    }
                }).catch((err) => {
                    console.log(err.message);
                }).finally(() => {
                    setLoading(false);
                });
        }
        loadSchools()
    }, [accountId])

    function hideAlert() {
        var alert = { showAlert: false, message: "", type: "success" }
        setAlertData(alert);
    }

    function saveClass() {
        setLoading(true);
        let submitPayload = {}
        let hypothesisTypeValue;
        if (values.hypothesisType == -1) {
            hypothesisTypeValue = 1;
        } else if (values.hypothesisType == 1) {
            hypothesisTypeValue = 1;
        }
        else {
            hypothesisTypeValue = 2;
        }
        submitPayload = {
            id: 0,
            "accountId": accountId,
            "organizationId": schoolId,
            "description": values.description,
            "name": values.name,
            "startDate": values.startDate,
            "endDate": values.endDate,
            "status": 1,
            "quizGrade": 100,
            "classLevelId": values.classLevelId,
            "hypothesisType": 1,
            "curriculamId": values.curriculamId,
            "teacherId": values.teacherId,
            "classCode": values.classCode,
            "termId": values.termId,
        }
        ClassService.postNewClass(submitPayload)
            .then((response) => {
                if (response.status == 200) {
                    let messages = [{ type: 'success', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    clearForm2();
                    setResetAccount(true);
                    setSelectedTermId(-1);
                } else if (response.status == 500) {
                    let messages = [{ type: 'error', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    setFormData(values)

                }
            }).catch((error) => {
                let messages = [{ type: 'error', text: error.message }]
                notification.open({
                    placement: 'top',
                    duration: "2",
                    description: [
                        messages.map((item) => {
                            if (item.type === 'success') {
                                return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'error') {
                                return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'warning') {
                                return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                        })
                    ],

                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });
                setFormData(values)

            }).finally(() => {
                setLoading(false);
            });
    }

    function clearForm() {
        setFormData(intiFormData)
        reset();
        setResetAccount(true);
        hideAlert();
        props.setFlag(false)
    }

    function clearForm2() {
        let account_id;
        let organization_id;
        let teacher_id;
        if (accessToken.roles[0] === "TEACHER") {
            account_id = values.accountId;
            organization_id = values.organizationId;
            teacher_id = values.teacherId;
        } else if (accessToken.roles[0] === "SCHOOL_ADMIN") {
            account_id = values.accountId;
            organization_id = values.organizationId;
            teacher_id = -1;
        } else if (accessToken.roles[0] === "ACCOUNT_ADMIN") {
            account_id = values.accountId;
            organization_id = -1;
            teacher_id = -1;
        } else {
            account_id = -1;
            organization_id = -1;
            teacher_id = -1;
        }

        let newintiFormData = {
            accountId: account_id,
            organizationId: organization_id,
            teacherId: teacher_id,
            description: "",
            name: "",
            "startDate": moment().format('yyyy-MM-DD'),
            "endDate": moment().add(1, 'years').format('yyyy-MM-DD'),
            "status": 1,
            "quizGrade": 0,
            "classLevelId": -1,
            "hypothesisType": -1,
            "curriculamId": -1,
            "classCode": "",
            "termId": -1,
        }

        setFormData(newintiFormData);
        // reset();
        // setResetAccount(true);
        setTimeout(function () {
            hideAlert();
        }, 2000);
    }

    function preventDecimal(e) {
        if (e.key === '.' || e.key === 'e') {
            e.preventDefault();
        }
    }

    function onAccountChange(value, options) {
        setFormData({ ...values, accountId: value, organizationId: -1, teacherId: -1 });
        setAccountId(value);
    }

    function onSchoolChange(value) {
        setFormData({ ...values, organizationId: value, teacherId: -1 });
        setSchoolId(value);
    }

    function onTeacherChange(value) {
        setFormData({ ...values, teacherId: value })
    }

    function onClassLevelChange(value) {
        setFormData({ ...values, classLevelId: value })
    }

    function onHypothesisTypeChange(value) {
        setFormData({ ...values, hypothesisType: value })
    }

    function onCurriculumChange(value) {
        setFormData({ ...values, curriculamId: value })
    }
    function onTermChange(value) {
        setFormData({ ...values, termId: value })
        setSelectedTermId(value)
    }

    const filterTeacherValues = (input, option) => {
        if (option.children.length === 3) {
            let tempString = option.children.join("");
            return tempString.toLowerCase().includes(input.toLowerCase());
        }
    }

    return (
        <>
            <div >
                <Spin tip="Loading..." spinning={loading} size="large">
                    <Row>
                        <Col md="3"></Col>
                        <Col lg='6' className="py-2 text-center">
                            {alertData.showAlert ?
                                <Alert message={alertData.message} type={alertData.type}
                                    action={
                                        <a type="text" onClick={hideAlert}>
                                            x
                                        </a>
                                    } /> : ""}
                        </Col>
                    </Row>
                    <Row>

                        <Col md="12">
                            <div className="form-row">
                                <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>Account
                                    </label>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Please Select an Account"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={accountId === -1 ? "Please Select an Account" : accountId}
                                        disabled={disableEnableDropdown("Account", props.user_roll)}
                                        onChange={(value, event) => onAccountChange(value, event)}
                                        invalid={!!errors?.accountId}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {
                                            accessToken.roles[0] === "SCHOOL_ADMIN" || accessToken.roles[0] === "TEACHER"
                                                ?
                                                "" :
                                                <option key="-1" value="-1">Please Select an Account</option>
                                        }
                                        {
                                            accounts.filter(item => item.name !== null).map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                        }
                                    </Select>
                                    <FormFeedback>{errors?.accountId}</FormFeedback>
                                </div>
                                <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>School
                                    </label>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Select a School"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={values.organizationId == -1 ? "Please Select a School" : values.organizationId}
                                        disabled={disableEnableDropdown("School", props.user_roll)}
                                        onChange={(value, event) => {
                                            onSchoolChange(value);
                                        }}
                                        invalid={!!errors?.organizationId}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {
                                            accessToken.roles[0] === "TEACHER"
                                                ?
                                                "" :
                                                <option key="-1" value="-1">Please Select a School</option>
                                        }
                                        {
                                            schools.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                        }
                                    </Select>
                                    <FormFeedback>{errors?.organizationId}</FormFeedback>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>Teacher
                                    </label>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Please Select a Teacher"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={values.teacherId == -1 ? "Please Select a Teacher" : values.teacherId}
                                        disabled={disableEnableDropdown("Teacher", props.user_roll)}
                                        onChange={(value, event) => {
                                            onTeacherChange(value);
                                        }}
                                        invalid={!!errors?.teacherId}
                                        filterOption={(input, option) =>
                                            filterTeacherValues(input, option)
                                        }
                                    >
                                        {
                                            accessToken.roles[0] === "TEACHER"
                                                ?
                                                "" :
                                                <option key="-1" value="-1">Please Select a Teacher</option>
                                        }
                                        {
                                            teachers.map((obj) => <option key={obj.id} value={obj.id}>{obj.firstName} {obj.lastName}</option>)
                                        }
                                    </Select>
                                    <FormFeedback>{errors?.teacherId}</FormFeedback>
                                </div>
                                <div className="form-group col-lg-6 col-sm-12">
                                    <label className="form-label" htmlFor="form3Example4">Description</label>
                                    <Input
                                        type="text"
                                        id="form3Example4"
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        placeholder=""
                                        value={values.description}
                                        name="description"
                                        onChange={(e) => { handleChange(e) }}
                                        invalid={!!errors?.description}
                                    />
                                    <FormFeedback>{errors?.description}</FormFeedback>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-lg-6 col-sm-12">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>Class Name/Number
                                    </label>
                                    <Input
                                        type="text"
                                        id="form3Example4"
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        placeholder=""
                                        value={values.name}
                                        name="name"
                                        onChange={(e) => { handleChange(e) }}
                                        invalid={!!errors?.name}
                                    />
                                    <FormFeedback>{errors?.name}</FormFeedback>
                                </div>
                                <div className="form-group col-lg-6 col-sm-12">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>Class Code
                                    </label>
                                    <Input
                                        type="text"
                                        id="form3Example4"
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        placeholder=""
                                        value={values.classCode}
                                        name="classCode"
                                        onChange={(e) => { handleChange(e) }}
                                        invalid={!!errors?.classCode}
                                    />
                                    <FormFeedback>{errors?.classCode}</FormFeedback>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-lg-6 col-sm-12 override-FormFeedback">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>Class Level
                                    </label>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Please Select a Class Level"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={values.classLevelId == -1 ? "Please Select a Class Level" : values.classLevelId}
                                        onChange={(value, event) => {
                                            onClassLevelChange(value);
                                        }}
                                        invalid={!!errors?.teacherId}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <option key="-1" value="-1">Please Select a Class Level</option>
                                        {
                                            initData.classLevels.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                        }
                                    </Select>
                                    <FormFeedback>{errors?.classLevelId}</FormFeedback>

                                </div>
                                {/* <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                    <label className="form-label" htmlFor="form3Example4">
                                        Hypothesis Type
                                    </label>
                                    <Select
                                    getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Please Select a Hypothesis Type"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={values.hypothesisType == -1 ? "Please Select a Hypothesis Type" : values.hypothesisType}
                                        onChange={(value, event) => {
                                            onHypothesisTypeChange(value);
                                        }}
                                        invalid={!!errors?.hypothesisType}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <option key="-1" value="-1">Please Select a Hypothesis Type</option>
                                        <option value="2">Open Ended</option>
                                        <option value="1">Single Choice</option>
                                    </Select>
                                    <FormFeedback>{errors?.hypothesisType}</FormFeedback>
                                </div> */}
                                {/* </div>
                            <div className="form-row"> */}
                                <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>Course Curriculum
                                    </label>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Please Select a Curriculum"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={values.curriculamId == -1 ? "Please Select a Curriculum" : values.curriculamId}
                                        onChange={(value, event) => {
                                            onCurriculumChange(value);
                                        }}
                                        invalid={!!errors?.Curriculum}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <option key="-1" value="-1">Please Select a Curriculum</option>
                                        {
                                            initData.curriculams.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                        }
                                    </Select>
                                    <FormFeedback>{errors?.curriculamId}</FormFeedback>
                                </div>
                                {/* <div className="form-group col-lg-6 col-sm-12">
                                    <label className="form-label" htmlFor="form3Example4">
                                        Preferred Quiz Grade
                                    </label>
                                    <Input
                                        type="number"
                                        id="form3Example4"
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        placeholder=""
                                        value={values.quizGrade}
                                        name="quizGrade"
                                        onChange={(e) => { handleChange(e) }}
                                        invalid={!!errors?.quizGrade}
                                        onKeyDown={preventDecimal}
                                    />
                                    <FormFeedback>{errors?.quizGrade}</FormFeedback>

                                </div> */}
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6 col-sm-12">
                                    <label className="form-label" htmlFor="form3Example4">
                                        Term
                                    </label>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Please Select a Term"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={values.termId == -1 ? "Please Select a Term" : values.termId}
                                        onChange={(value, event) => {
                                            onTermChange(value);
                                        }}
                                        // invalid={!!errors?.Curriculum}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <option key="-1" value="-1">Please Select a Term</option>
                                        {
                                            initData.terms.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                        }
                                    </Select>
                                    {/* <FormFeedback>{errors?.curriculamId}</FormFeedback> */}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-lg-6 col-sm-12">
                                    <label className="form-label" htmlFor="startDate">
                                        <span className="required-field">*</span>Start Date
                                    </label>
                                    <Input
                                        innerRef={startDateRef}
                                        type="date"
                                        id="startDate"
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        placeholder=""
                                        value={values.startDate}
                                        name="startDate"
                                        onChange={(e) => { handleChange(e) }}
                                        invalid={!!errors?.startDate}
                                    />
                                    <FormFeedback>{errors?.startDate}</FormFeedback>
                                </div>
                                <div className="form-group col-lg-6 col-sm-12">
                                    <label className="form-label" htmlFor="endDate">
                                        <span className="required-field">*</span>End Date
                                    </label>
                                    <Input
                                        innerRef={endDateRef}
                                        type="date"
                                        id="endDate"
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        placeholder=""
                                        value={values.endDate}
                                        name="endDate"
                                        onChange={(e) => { handleChange(e) }}
                                        invalid={!!errors?.endDate}
                                    />
                                    <FormFeedback>{errors?.endDate}</FormFeedback>
                                </div>
                            </div>
                            <div className="center mt-4">
                                <Button onClick={clearForm} color="warning" className="mr-2 fixed-width-115">Cancel</Button>
                                <Button onClick={handleSubmit} color="success" className="fixed-width-115">Save</Button>
                            </div>
                            <div className="center mt-4">

                            </div>
                        </Col>
                    </Row>
                </Spin>
            </div>
        </>
    );
}

export default ClassesCreate;
