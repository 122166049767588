// reactstrap components
import { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import "antd/dist/antd.css";
import { Link, useHistory } from "react-router-dom";
import { Switch, Route, useParams } from "react-router-dom";
import { SearchOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Radio, Input, Form, Modal, Button, Tabs, Table, Image } from "antd";
import { getAllLevelsByCurriculumId, getDescriptionByLessonId } from "../../../api/TeacherService";

import Engage from "./Engage/index";
import Explore from "./Explore/index";
import Evaluate from "./Evaluate/index";
import Explain from "./Explain/index";
import Extend from "./Extend/index";
import Footer from "../../../components/Footer/Footer";
import jsonToken from 'jsonwebtoken'
import img1 from '../../../assets/images/thumb-sm.png'

function ClassDetails(props) {
  let { id, lessonId } = useParams();
  const { TabPane } = Tabs;
  let history = useHistory();
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  let curriculumName = localStorage.getItem("curriculumName");
  let curriculumLevel = localStorage.getItem("curriculumLevel");
  
  
  //start
  const [activeTab, setActiveTab] = useState("1");
  const [data, setData] = useState({data:""});
  const [lessonDescription, setLessonDescription ] = useState({});
  const [payload, setPayLoad] = useState({});
  const [headerData, setHeaderData] = useState({
    classLevelName:"",
    lessonName:"",
    curriculumName:"",
  });
  useEffect( ()=>{
    setHeaderData(existingValues => ({
      ...existingValues,
      curriculumName : curriculumName
    }))
   }, [curriculumName]);

   useEffect( ()=>{
    setHeaderData(existingValues => ({
      ...existingValues,
      lessonName : curriculumLevel
    }))
   }, [curriculumLevel]);
  //emd

  useEffect( ()=>{
    getAllLevelsByCurriculumId(id, lessonId).then((response) => {
      setData(response)
     })

     getDescriptionByLessonId(lessonId).then((response) => {
      setLessonDescription(response?.data[0])
     })
   }, []);

   const stopAllMedia = () => {
    // Stop all video elements
    const videos = document.querySelectorAll('video');
    videos.forEach((video) => video.pause());
  
    // Stop all audio elements
    const audios = document.querySelectorAll('audio');
    audios.forEach((audio) => audio.pause());
  
    // Stop all YouTube iframes
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach((iframe) => {
      if (iframe.src.includes('youtube.com')) {
        const src = iframe.src;
        iframe.src = src; // Reloading the iframe to stop the video
      }
    });
  };


   useEffect(()=>{
      setPayLoad({
        CurriculumId:id,
        userId: accessToken.userId,
        lessonId: lessonId,
        levelData: data?.data,
      })
   },[data])
  const callback = (key) => {
    updateTabBackground(key);
    setActiveTab(key);
    stopAllMedia();
  };

  useEffect(() => {
    updateTabBackground();

  }, []);
  const dataFromChild = (data) => {
    // setHeaderData(existingValues => ({
    //   ...existingValues,
    //   classLevelName : data.classLevelName,
    //   lessonName : data.lessonName,
    // }))
  }

  const updateTabBackground = (key) => {
    const tabs = document.querySelectorAll(".ant-tabs-tab");
    const tabback = document.querySelectorAll(".ant-tabs-nav-list");
    const pageback = document.querySelectorAll(".page-back");

    if (key == 1) {
      tabs[key - 1].classList.add("engage-tab");
    }
    setTimeout(() => {
      if (key == 1) {
        tabs[key - 1].classList.add("engage-tab");
      } else if (key == 2) {
        tabs[key - 1].classList.add("explore-tab");
      } else if (key == 3) {
        tabs[key - 1].classList.add("evaluate-tab");
      } else if (key == 4) {
        tabs[key - 1].classList.add("explain-tab");
      } else if (key == 5) {
        tabs[key - 1].classList.add("expand-tab");
      }
    }, 100);
  };
  const submitTrigger = (key) => {
    callback(key);
  };
  return (
    <>
      <div>
        <div className="container-fluid p25r teacher-details">
          <Row>
            <Col xs="12" className="space-py-20">
              <div className="text-center">
                <center>
                  <p className="sub-title">{headerData.curriculumName}</p>
                  <h3 class="">{headerData.lessonName}</h3>
                </center>
                <Link className="back-button " onClick={()=> 
               { 
                localStorage.removeItem("curriculumLevel");
                history.push({pathname: `/Teacher/PlanLesson/${id}`,});}
                } >
                   <ArrowLeftOutlined className=" mr-2 back-btn-hover" />
            Go Back
                </Link>
                <hr class="hr-dark" />
              </div>
            </Col>
          </Row>
        </div>
        {/* for the lesson description */}
        <div className="container tab-style mb-5 guidelines-description-table">
          <h4 dangerouslySetInnerHTML={{ __html: lessonDescription?.labDescription }}></h4>
        </div>
        <div className="t-tabs teacher-detail">
          <Row>
            <Col md="12" className="no-padding">
              <Tabs
                activeKey={activeTab}
                defaultActiveKey="1"
                onChange={callback}
                className="lesson-tab"
              >
                <TabPane tab="Engage" key="1" className="engage active">
                  <Engage className="engage" submitTrigger={submitTrigger} data={payload} childData = {dataFromChild}/>
                </TabPane>
                <TabPane tab="Explore" key="2" className="explore">
                  <Explore submitTrigger={submitTrigger} data={payload}/>
                </TabPane>
                <TabPane tab="Evaluate" key="3" className="evaluate">
                  <Evaluate  submitTrigger={submitTrigger} data={payload} childData = {dataFromChild}/>
                </TabPane>
                <TabPane tab="Explain" key="4" className="explain">
                  <Explain submitTrigger={submitTrigger} data={payload} childData = {dataFromChild}/>
                </TabPane>
                <TabPane tab="Extend" key="5" className="expand">
                  <Extend submitTrigger={submitTrigger} data={payload} childData = {dataFromChild}/>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ClassDetails;