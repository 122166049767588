import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  CardBody,
  CardTitle,
  Row,
  Col,
  Progress,
  CardImgOverlay,
} from "reactstrap";
import Lesson from "./lesson";
import { Tabs, Breadcrumb } from "antd";
import "antd/dist/antd.css";


function LessonIndex(props) {
 const value = (window.location.href).split("/")

 useEffect(()=>{
      window.localStorage.setItem('lessonID', JSON.stringify(parseInt(value[value.length-1])));
 },[value])
 

  return (
    <>
    <div className="container">
      <div className="content">
        <Row>
          <Col className="text-center">
            <div className="mb-5">
              <h3 className="text-dark">Choose your lesson</h3>
            </div>
            <hr />
            <br />
          </Col>
        </Row>
      <Lesson/>
      </div>
      </div>
    </>
  );
}

export default LessonIndex;
