import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { Steps, Input, Popover } from "antd";
import "antd/dist/antd.css";
import Explain from './Explain';
import ExplainList from './ExplainList';
import BasicInfo from './BasicInfo';

const { Step } = Steps;
const customDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        step {index + 1} | status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);

function ExplainIndex() {
  const [current, setCurrent] = useState(0);
  const [currentClassLevel, setCurrentClassLevel] = useState(1);
  const [explain_id, setExplain_id] = useState(0);
  const [type, setType] = useState(1);
  const [filterdValues, setFilterdValues] = useState({})
  const [added_class_level, setAddedClassLevel] = useState(0);
  const [previouseClassLevel, setPreviouseClassLevel] = useState(null);
  const setPreviouseClassLevelFC = (value) => {
    setPreviouseClassLevel(value)
  }

  const [disableState, setDisableState] = useState({
    1:true,
    2:true,
    3:true
  })
  const onChangex = () => {
    setCurrent(current - 1);
  };
  const changeTab = (data, filterdValues = []) => {
    setCurrent(current + 1)
    setFilterdValues(filterdValues);
    setCurrentClassLevel(data[0])
  }

  useEffect(() => {
    if (added_class_level > 0) {
      setCurrentClassLevel(added_class_level);
    }
  }, [added_class_level]);

  const steps = [
    {
      title: 'Explain List',
      content: <ExplainList setPreviouseClassLevelFC={(data) => setPreviouseClassLevelFC(data)} changeTab={changeTab} setType={(data) => setType
        (data)} />,
        
      disabled: disableState[1],
    },
    {
      title: 'Basic Setup',
      content: <BasicInfo setPreviouseClassLevelFC={(data) => setPreviouseClassLevelFC(data)} previouseClassLevel={previouseClassLevel} setAddedClassLevel={setAddedClassLevel} filterdValues={filterdValues} type={type} currentClassLevel={currentClassLevel} createExplaineStep={(data) => setExplain_id(data)} GoToQuestionTab={() => setCurrent(current + 1)} back={onChangex} />,
      disabled: disableState[2],
    },
    {
      title: 'Section',
      content: <Explain type={type} currentClassLevel={currentClassLevel} explain_id={explain_id} back={<button className='btn btn-info' style={{padding: "14px 31px"}}  onClick={() => {
        onChangex()
      }}>Back</button>} />,
      disabled: disableState[3],
    },
  ];

  useEffect(()=>{
    switch (current){
      case 0 :
          setDisableState({
            1:true,
            2:true,
            3:true,
          });
        break;
      case 1 : 
          setDisableState({
            1:false,
            2:true,
            3:true,
          });
        break;
      case 2 : 
          setDisableState({
            1:false,
            2:false,
            3:true,
          });
        break;
      case 3 : 
          setDisableState({
            1:false,
            2:false,
            3:false,
          });
        break;

    }
  },[current])
  return (
    <>
      <div className='container my-3'>
        <Steps onChange={e=>{
                 setCurrent(e)
              }} current={current} progressDot={customDot}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} disabled={item.disabled}/>
          ))}
        </Steps>
        <div className=" mt-3 ">{steps[current].content}</div>

      </div>
      <div className='step-button'>
        <Button className='btn btn-primary btn-lg mr10'>Previous</Button>
        <Button className='btn btn-primary btn-lg'>Next</Button>
      </div>
    </>
  );
}

export default ExplainIndex;
