import React, { useEffect } from 'react'
import CoordinatesValues from '../coordinatesValues'

export default function CordinateDisplayBox(props) {
  // console.log(props)
 
   
  return (
    <CoordinatesValues dataTableValue={props.dataTableValue} chartType={props.data.chartType } lesson={props.data.lesson} clickState={props.data.clickState} data={props.data.data} chartData={props.chartData} />
  )
}
