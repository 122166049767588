import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: ' Bar Chart',
    },
  },
};

const labels = ['Gas', 'Liquid', 'Solid', 'Color', 'Light', 'Heat'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Mesurement',
      data: [5],
      backgroundColor: 'red',
    },

   
  ],
};


export function BarChartWithYesNo(props) {
  console.log(props)

  let colors = ["#A56ABE","#5599C8","#F4D03E","#58D68D","#5C6D7D","#EC7064"]

    const  options = {
      maintainAspectRatio: false,
      responsive:true,
  plugins: {
    legend: {
      display: false,
    },
    // title: {
    //   display: true,
    //   text: props.name,
    // },
  },
      scales: {
        // to remove the labels
        x: {

          // to remove the x-axis grid
          grid: {
            drawBorder: true,
            display: false,
          },
        },
        // to remove the y-axis labels
        y: {
          ticks: {
            display: false,
            beginAtZero: true,
          },
          // to remove the y-axis grid
          grid: {
            drawBorder: true,
            display: false,
          },
        },
      },
    }
  
    const values = {
      labels : labels,
      datasets:props.data.datasets.filter((ele, ind) => ind === props.data.datasets.findIndex( elem => elem.label === ele.label  )).map((item, i) =>{

        const data= item.yData.map((value,i) =>  Object.keys(value)
        ).map( i => parseInt(i [0]))
        
        let res =      props.data.labels.map( (item,ind) => {
          if(!(data?.findIndex(x => x === item) > -1) ){
            return 0
          }
          else{
            
            return 1
          }
        } )
          return {
              label:item.label  === "undefined" || item.label === null ? "" : item.label,
              borderColor: colors.map( i => i),
              backgroundColor: item.borderColor || colors.map( i => i),
              borderWidth:"3",
              data:res
          }
      })
    }

   // console.log(values)
  return <Bar options={options} data={values} responsive="true"  height={200}/>;
}

 