import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { Row, Col, Badge } from "reactstrap";

function ExpDrawerSub(props) {
  const [answers, setAnswers] = useState([]);


  function handleAnswerChange(index, fieldName, value) {
    // Clone the answers array to avoid mutating state directly
    const newAnswers = [...answers];
    // Update the answer object for the specified index
    newAnswers[index] = {
      ...newAnswers[index],
      [fieldName]: value,
    };
    // Update the state with the new answers array
    setAnswers(newAnswers);
  }

  useEffect(()=>{
    props.getValuesFromQuestionAnswer(answers);
  },[answers]);

  useEffect(()=>{
    setAnswers(Object.values(props.pastAnswers));
  },[props.pastAnswers]);



  if (props?.length[0]?.url?.length !== undefined) {



    return props.length[0].url.map((url, index) => (
      <React.Fragment key={index}>
        <Row>
          <Col md="6">
            <Form.Item name={`answer1-${index}`} label="Correct Answer-Start from">
              <Input
                type="number"
                placeholder="Answer range start from"
                value={answers[index]?.answer1}
                defaultValue={0}
                onChange={(e) => handleAnswerChange(index, 'answer1', e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col md="6">
            <Form.Item name={`answer2-${index}`} label="Correct Answer-End from">
              <Input
                type="number"
                placeholder="Answer range end from"
                value={answers[index]?.answer2}
                defaultValue={0}
                onChange={(e) => handleAnswerChange(index, 'answer2', e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    ));
  }
  return null;
}

export default ExpDrawerSub;
