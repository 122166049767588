
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Config from '../api/Config';



export const postSchoolAdmin = createAsyncThunk("", async () => {
    let token = localStorage.getItem("access_token");
    const response = await axios.get(Config.APIURL +`admin/school/updateSchoolAdmin`, {
        headers:{
            "Authorization": `Bearer ${token}`
        }
    })
    return response.data
    
})
 
const initialState = {
    
    editschoolAdminsTb: [],
  
}

const schoolAdminSlice = createSlice({
    name: "schoolAdmin",
    initialState,

    reducers: {
        addschoolAdmin: (state, action) => {
            state.schoolAdmin = (action.payload);

        },
        addToTable: (state, action) => {
            state.schoolAdminTable.push(action.payload)
        },

        editschoolAdmins: (state, action) => {
            state.editschoolAdminsTb = (action.payload) 
            
            // const schoolAdmin = action.payload.filter((x) => x.id === state);
        }

    },
    // extraReducers: (builder) => {
    //     builder.addCase(getSchoolAdmin.pending, (state) => {
    //         state.loading = true;
    //     })
    //     builder.addCase(getSchoolAdmin.fulfilled, (state, action) => {
    //         state.loading = false;
    //         state.schoolAdmins = [action.payload];
    //         state.error = ''
    //     })
    //     builder.addCase(getSchoolAdmin.rejected, (state, action) => {
    //         state.loading = false;
    //         state.error = action.error.message;
    //     })
        // [getSchoolAdmin.pending]: (state, action) => {
        //     state.loading = true;

        // },
        // [getSchoolAdmin.fulfilled]: (state, action) => {
        //     state.loading = false;
        //     state.schoolAdmin = [action.payload];

        // },
        // [getSchoolAdmin.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.error = action.payload;

        // }
    // }
})

export const schoolAdminReducer = schoolAdminSlice.reducer;

export const { addschoolAdmin, addToTable, editschoolAdmins } = schoolAdminSlice.actions;