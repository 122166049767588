import { useEffect, useState } from "react";
import jsonToken from 'jsonwebtoken'
import ChartValidations from "../Explore/childComponent/ChartValidations"
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody, 
  Row,
  Col,
} from "reactstrap";
import { Breadcrumb, Radio, Input, Form, Modal, Button, Tabs, Checkbox, Image, Spin, notification } from "antd";
import "antd/dist/antd.css";
import RestService from "../../../api/RestService";
import CoordinatesValues from "../Explore/childComponent/coordinatesValues";
import AnswerDisplay from "../AnswerDisplay";
import FloatingButton from "../../../components/FloatingButton";
import { useSelector } from "react-redux";
import CalculateMarks from "../calculateMarks";
import InstructorComment from "../components/InstructorComment/InstructorComment";
import { ExclamationCircleOutlined } from '@ant-design/icons';

function Evaluate(props) {
  const [bluredLayer, setBluredLayer] = useState("");
  const [selectValue, setSelectValue] = useState()
  const [counter, setCounter] = useState(0);
  const [reviewStatus, setReviewStatus] = useState(props.disabled ?? true);
  const [payload, setPayload] = useState([]);
  const [containedPayload, setContainedPayload] = useState({});
  const [cellId, setCellId] = useState([]);
  const [selectOption, setSelectOption] = useState([]);
  const [allGraphValue, setAllGraphValue] = useState("");
  const [loadingText, setLoadingText] = useState("Loading...");

  const [checked, setChecked] = useState(false);
  const [clickState, setClickState] = useState(2);

  const [xLabel, setXLabel] = useState();
  const [yLabel, setYLabel] = useState();
  const [xgrLabel, setXgrLabel] = useState();
  const [ygrLabel, setYgrLabel] = useState();
  const [lavelData, setLavelData] = useState();

  const [lesson, setLesson] = useState(parseInt(props.evaluateId));
  const [lesson2, setLesson2] = useState(parseInt(props.exploreId));
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [datasets, setDatasets] = useState([
    {
      label: "",
      yData: [],
      xData: [],
      color: ""
    }
  ]);
  const [classLessonName, setClassLessonName] = useState(localStorage.getItem("className"));
  const [lessonName,setLessonName] = useState("");
  const [mappingId, setMappingId] = useState("");

  const TSdate = Date.now();
  const dateFormat = new Date(TSdate);
  const MonthYear = dateFormat.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
  const HrsMinSec = dateFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
  
  const [teacherComment, setTeacherComment] = useState("");
  const [teacherCommentStats, setTeacherCommentStats] = useState(false);
  const [initPayload, setInitPayload] = useState({
    description: "",
    evaluateStepQuestionAnswers: [],
    evaluateStepQuestions: [],
    id: "",
    lesson_id: "",
    exploreStepResponses: "",
    experiments:[],
    lessonCode:"",
    chartType: "",
    guideline:"",
  });
  const [dataTableValue, setDataTableValue]  = useState(
    [{
      value: "",
      si: ""
    }]
  )
  let user = "";
  let userId = "";

  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;
    userId = accessToken.userId;


  }

  const [responseData, setResponseData] = useState({
    borderColor: "",
    borderWidth: "",
    labels: "",
    xData: [],
    yData: [],
    xyData: [],
    categoryData: [],
    cod: "",
    speed: "",
    speed2: "",
  })
  const [responseMixData, setResponseMixData] = useState([]);
  const [graphMixStatus, setGraphMixStatus] = useState(false);
  const [definedHypothesis, setDefinedHypothesis] = useState("");

  // const Label = useSelector(state => state?.graph?.GraphDataTable)

  
  useEffect(() => {

    const dataSendToMap = initPayload?.experiments;
    setLavelData(dataSendToMap)
    dataSendToMap?.map(x => {
      if (x.name[0].value === selectValue) {

        let xValue = " ";
        let yValue = " ";
       if(x.x  && x.y ) {
        if(x.x.SI !== ""){
          xValue = `(${x.x.SI})`;
        }
        if(x.y.SI !== ""){
          yValue = `(${x.y.SI})`;
        }


        setXLabel(`${x.x.Value} ${xValue}`)
        setYLabel(`${x.y.Value} ${yValue}`)
        setXgrLabel(x.x?.SI)
        setYgrLabel(x.y?.SI)
       }
      }
    })

  }, [selectValue])


  useEffect(() => {
    if (props.complted === true || props.disabled !== undefined && props.disabled=== false) {
      setReviewStatus(false)

    } else {
      setReviewStatus(true)

    }

    
  }, [props])
  useEffect(() => {


    if (datasets && datasets.length > 0) {
      datasets.map(x => {
        if (x.yData.length == 0) {
          setDatasets(datasets.filter(x => x.yData.length > 0))
        }
      })
    }
  }, [datasets])
  useEffect(() => {
    setLoading(true)
    setLoading2(true)

      RestService.getEvaluateStepInfoByEvaluateStepId(props.evaluateId, props.user, props.exploreId)
      .then((response) => {
        if(response){
          if (counter === 0) {
            setInitPayload(response?.data)
            if(response?.data?.chartType?.dataTableValue !== undefined){
              setDataTableValue(response?.data?.chartType?.dataTableValue)
            }
            setLoading(false)
            setLoading2(false)
            setCounter(1);
            setLessonName(response?.data?.lessonName);
  
  
          } else {
            const secondSendPayload = {
              description: initPayload?.description,
              evaluateStepQuestionAnswers: initPayload?.evaluateStepQuestionAnswers,
              evaluateStepQuestions: initPayload?.evaluateStepQuestions,
              exploreStepResponses: response?.data?.exploreStepResponses,
              experiments:response?.data?.experiments,
              id: initPayload?.id,
              lesson_id: initPayload?.lesson_id,
              lessonCode: initPayload?.lessonCode,
              chartType:response?.data?.chartType,
              guideline:initPayload?.guideline
            }
  
            setInitPayload(secondSendPayload)
            if(response?.data?.chartType?.dataTableValue !== undefined){
              setDataTableValue(response?.data?.chartType?.dataTableValue)
            }
            setLoading(false)
            setLoading2(false)
            // setBluredLayer(" ")
          }
        }else{
          setLoading(false)
          setLoading2(true)
    
          // setLoadingText("Student has not answer yet")
          // notification.warning({
          //   message: `Notification`,
          //   description:
          //     'Student has not answer yet',
          //   placement:'bottom',
          //   duration: 0,
          // });
          // setBluredLayer("blured-content")
        }


      })
    
  }, [props.user,props.evaluateId,props.exploreId, reviewStatus])

  // console.log(initPayload)

  function convert(data) {
    return Array.isArray(data)
      ? data.reduce((obj, el, i) => (el + 1 && (obj[i] = convert(el)), obj), {})
      : data;
  }

  useEffect(() => {

    setLoading(true)
    if(props.user !== undefined && props.evaluateId !== undefined && props.evaluateId !== null ){
      RestService.getEvaluateStepByIdAndUserName( props.user, props.evaluateId)
      .then((response) => {
        if (response) {
          if(response?.data[0]?.comment !== null && response?.data[0]?.comment !== undefined && response?.data[0]?.comment !== ""){
            setTeacherCommentStats(true)
            setTeacherComment(response?.data[0]?.comment)
          }
          setContainedPayload(convert(response?.data[0]?.response_json?.answers))
          setLoading(false)
        }
      })
    }
  }, [props.user,props.evaluateId, reviewStatus])

  const { TabPane } = Tabs;

  const sendPayload = (value) => {
    setPayload(value);
  };

  const { TextArea } = Input;

  useEffect(() => {
    setDefinedHypothesis(initPayload?.exploreStepResponses[0]?.response_jason?.hypothesis)
    setResponseMixData([]);
    setResponseData({
      borderColor: "",
      label: selectValue,
      borderWidth: "",
      labels: "",
      xData: [],
      yData: [],
      xyData: [],
      categoryData: [],
      cod: "",
      speed: "",
      speed2: "",
      userMainHypothesis: "",
    });
    if (Object.keys(cellId).length > 0 && Object.keys(cellId).length === selectOption.length) {
      setChecked(true)
    }
    // console.log(initPayload?.exploreStepResponses[0])
    if (initPayload?.exploreStepResponses[0]?.response_jason?.answers !== null && initPayload?.exploreStepResponses[0]?.response_jason?.answers !== undefined) {
      if (cellId.length !== 0 && cellId !== undefined && cellId !== null) {
        const filteredList = Object.keys(initPayload?.exploreStepResponses[0]?.response_jason?.answers)
          .filter(key => Object.values(cellId).includes(key))
          .reduce((obj, key) => {
            obj[key] = initPayload?.exploreStepResponses[0]?.response_jason?.answers[key];
            return obj;
          }, {});
        if (Object.values(filteredList).length <= 1) {
          setGraphMixStatus(false)

          if (Object.values(filteredList)[0] !== undefined && Object.keys(filteredList)[0] !== null) {
            const responsePayload = {
              borderColor: Object.values(filteredList)[0].borderColor,
              borderWidth: Object.values(filteredList)[0].borderWidth,
              labels: Object.values(filteredList)[0].label,
              xgrLabel: xgrLabel,
              ygrLabel: ygrLabel,
              xData: Object.values(filteredList)[0].xData,
              yData: Object.values(filteredList)[0].yData,
              xyData: Object.values(filteredList)[0].xyData,
              categoryData: Object.values(filteredList)[0].categoryData,
              cod: Object.values(filteredList)[0].cod,
              speed: Object.values(filteredList)[0].speed,
              speed2: Object.values(filteredList)[0].speed2,
            }
            setResponseData(responsePayload);
          }

        } else {
          setResponseMixData([]);
          setGraphMixStatus(true);
          for (let k = 0; k < Object.values(filteredList).length; k++) {
            if (Object.values(filteredList)[k] !== undefined && Object.keys(filteredList)[k] !== null) {
              const responsePayload = {
                borderColor: Object.values(filteredList)[k].borderColor,
                borderWidth: Object.values(filteredList)[k].borderWidth,
                labels: Object.values(filteredList)[k].displayLabel,
                xgrLabel: xgrLabel,
                ygrLabel: ygrLabel,
                xData: Object.values(filteredList)[k].xData,
                yData: Object.values(filteredList)[k].yData,
                xyData: Object.values(filteredList)[k].xyData,
                categoryData: Object.values(filteredList)[k].categoryData,
                cod: Object.values(filteredList)[k].cod,
                speed: Object.values(filteredList)[k].speed,
                speed2: Object.values(filteredList)[k].speed2,
                lesson: lesson2
              }
              setResponseMixData((preData) => [...preData, responsePayload]);
              
            }

          }
        }




      }

      let arr = [];
      if (selectOption.length !== Object.keys(initPayload?.exploreStepResponses[0].response_jason?.answers).length) {
        Object.keys(initPayload?.exploreStepResponses[0].response_jason?.answers).map(x => {
          arr.push({
            name: x,
            isChecked: false,
            color: initPayload?.exploreStepResponses[0].response_jason?.answers[x].borderColor,
            displayLabel: initPayload?.exploreStepResponses[0].response_jason?.answers[x].displayLabel,
            mappingId: initPayload?.exploreStepResponses[0].response_jason?.answers[x].mappingId,
          })


        })
        setSelectOption(arr)
      }




    }
  }, [cellId, initPayload]);

  useEffect(() => {
    if (initPayload?.exploreStepResponses[0]?.response_jason?.answers !== null && initPayload?.exploreStepResponses[0]?.response_jason?.answers !== undefined) {

    if (selectOption.length !== Object.keys(initPayload?.exploreStepResponses[0]?.response_jason?.answers).length) {
      Object.keys(initPayload?.exploreStepResponses[0]?.response_jason?.answers).map(x => {
        if(initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].mappingId !== undefined && initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].mappingId !== "" ){
          setMappingId(initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].mappingId)
        }
        
      })}

    }
  },[initPayload])
  const graphdata1 = {
    labels: responseData.categoryData,
    label: selectValue,
    lesson: initPayload?.lesson_id,
    type: responseData.type || 1,
    chartType:initPayload?.chartType[mappingId]?.graphType || "",
    datasets: [
      {
        label: responseData.labels,
        xgrLabel: xgrLabel,
        ygrLabel: ygrLabel,
        xData: responseData.xData,
        yData: responseData.yData,
        xyData: responseData.xyData,
        borderColor: responseData.borderColor,
        borderWidth: responseData.borderWidth,
      },
    ],
  };

  const dataFromGraph = (fitValue, speed) => {};

  const tablePayload = {
    type: graphMixStatus,
    labels: initPayload?.exploreStepResponses[0]?.response_jason.answers[selectValue]?.displayLabel,
    chartType:initPayload?.chartType[mappingId]?.graphType,
    xData: initPayload.chartType[mappingId]?.calculationMethod === "curve" ? responseData.xData : responseData.yData,
    yData: initPayload.chartType[mappingId]?.calculationMethod === "curve"  ? responseData.yData : responseData.xData,
    speed: responseData.speed,
    speed2: responseData.speed2,
    cod: responseData.cod,
  };
  
  const viewData = datasets.map(i => {
    return {
      labels: i.label,
      type: 2,
      xData: i.yData.length > 0 ? i.xData : [],
      yData: i.yData.length > 0 ? i.yData : [],
    }
  })


  const secondLessonTablePayload = {
    
    labels: datasets[0]?.xData,
    type: 1,
    datasets: datasets

  }

  const thirdLessonTablePayload = {
    labels: (initPayload?.chartType[mappingId]?.calculationMethod ==="Bar YesNo" || initPayload?.chartType[mappingId]?.calculationMethod === "Bar Click2Count") ?datasets.map(i =>  i.xData
    )[0] : datasets.map(i => {
      return i.xData
    }),
    type: 3,
    yLabel: ygrLabel,
    datasets: datasets.map(i => {
      return {
        label: i.label,
        yData: i.yData,
        borderColor: initPayload?.chartType[mappingId]?.calculationMethod ==="Bar YesNo" ? i.borderColor:i.color,
      }
    })
  }


  

  const submitFun = async () => {
    setLoading(true);
    const name = CalculateMarks(Object.values(payload), initPayload.evaluateStepQuestionAnswers, initPayload.evaluateStepQuestions.length,  props.marks)
    const submitPayload = {

      username: user,
      userId: userId,
      status: 2,
      response_json: {
        answers: Object.values(payload)
      },
      last_update_date: new Date().toISOString().slice(0, 10),
      evaluate_step_id: lesson,
      marks: name,
    }



    await RestService.postEvaluateStepUserResponses(submitPayload)
      .then((response) => {
        if (response.status === 200) {
          props.activatingRS();
          const end = new Date();
          props.getEndDuration(end);
          window.scrollTo(0, 0)
          props.submitTrigger("3");
        }
        setLoading(false)

      })
  }

  
  const changeHandlerRadio = (event) => {

    const name = event.target.value;
  
    // console.log(name)
    setSelectValue(name);

      setChecked(false)
      selectOption.map(x => {
        if (x.name === name) {
          x.isChecked = true
        }
      })
      setClickState(1)

      setSelectOption(selectOption)

      if (initPayload?.chartType[mappingId]?.calculationMethod ==="curve") {

        
        if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {

          if (datasets.findIndex(x => x.label2 === name) !== -1) {

            datasets.filter(x => x.label2 === name)
            setDatasets(datasets)
          }
          else {
            setDatasets(prev => (
              [
                ...prev,
                {
                  label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].displayLabel,
                  label2: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label,
                  yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData,
                  color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].borderColor,
                  xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData
                }
              ]
            ))
          }

        }
      }else if(initPayload?.chartType[mappingId]?.calculationMethod === "Bar YesNo") {
        if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {
            
          if (datasets.findIndex(x => x.label2 === name) !== -1) {

            datasets.filter(x => x.label2 === name)
            setDatasets(datasets)
          }
          else {
            setDatasets(prev => (
              [
                ...prev,
                {
                  label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].displayLabel,
                  label2: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label,

                  yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData,
                  color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(item => {
                    return item?.color
                  }),
                  borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].borderColor,

                  xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData
                }
              ]
            ))
          }

        }
      }
      else {
        if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {

          if (datasets.findIndex(x => x.label2 === name) !== -1) {

            datasets.filter(x => x.label2 === name)
            setDatasets(datasets)
          }
          else {


            setDatasets(prev => (
              [
                ...prev,
                {
                  label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].displayLabel,
                  label2: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label,

                  yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(i => {
                    return i?.value
                  }),
                  color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(i => {
                    return i?.color
                  }),
                  xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData,
                  borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].borderColor
                }
              ]
            ))
          }

        }
      }

      setCellId(prev => ({
        [name]: name
      }))
     
  }

  const changeHandler = (event) => {

    const check = event.target.checked;
    const name = event.target.name;
  

    if (check === true) {
    setSelectValue(name);

      setChecked(false)
      selectOption.map(x => {
        if (x.name === name) {
          x.isChecked = true
        }
      })
      setClickState(1)

      setSelectOption(selectOption)

      if (initPayload?.chartType[mappingId]?.calculationMethod ==="curve") {

        
        if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {

          if (datasets.findIndex(x => x.label2 === name) !== -1) {

            datasets.filter(x => x.label2 === name)
            setDatasets(datasets)
          }
          else {
            setDatasets(prev => (
              [
                ...prev,
                {
                  label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].displayLabel,
                  label2: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label,
                  yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData,
                  color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].borderColor,
                  xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData
                }
              ]
            ))
          }

        }
      }else if(initPayload?.chartType[mappingId]?.calculationMethod === "Bar YesNo") {
        if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {
            
          if (datasets.findIndex(x => x.label2 === name) !== -1) {

            datasets.filter(x => x.label2 === name)
            setDatasets(datasets)
          }
          else {
            setDatasets(prev => (
              [
                ...prev,
                {
                  label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].displayLabel,
                  label2: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label,

                  yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData,
                  color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(item => {
                    return item?.color
                  }),
                  borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].borderColor,

                  xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData
                }
              ]
            ))
          }

        }
      }
      else {
        if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {

          if (datasets.findIndex(x => x.label2 === name) !== -1) {

            datasets.filter(x => x.label2 === name)
            setDatasets(datasets)
          }
          else {


            setDatasets(prev => (
              [
                ...prev,
                {
                  label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].displayLabel,
                  label2: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label,

                  yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(i => {
                    return i?.value
                  }),
                  color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(i => {
                    return i?.color
                  }),
                  xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData,
                  borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].borderColor
                }
              ]
            ))
          }

        }
      }

      setCellId(prev => ({
        ...prev,
        [name]: name
      }))
    } else {
      setDatasets(datasets.filter(x => x.label2 !== name))

      selectOption.map(x => {
        if (x.name === name) {
          x.isChecked = false
        }
      })
      if(selectOption.findIndex( x => x.isChecked === true) > -1){
        setClickState(1)
      }
      else{
        setClickState(2) 
      }
      setSelectOption(selectOption)
      setChecked(false)
      delete cellId[name];
      setSelectValue(Object.values(cellId)[0]);
      setCellId(prev => ({
        ...prev,
      }))
    }
  }


  const changeHandler3 = (event,type="default") => {
    let check;
    let name;
    if(type === "default"){
      check = event.target.checked;
      name = event.target.name;
    }else{
      name = [];
      check = true;
      selectOption.forEach(function (element) {
        name.push(element.name);
      });
      if(selectOption.length === 0){
        return false;
      }
    }


    if (check === true) {
      selectOption.map(x => {
        x.isChecked = true
      });
      setClickState(2)
      setSelectOption(selectOption)
      setSelectValue(selectOption[0].name);
      setChecked(true)
      name.map((x) => {
        if (initPayload?.chartType[mappingId]?.calculationMethod === "curve" ) {
          if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label2 === x) !== -1) {


            }
            else {

              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].displayLabel,
                    label2: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label,

                    yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData,
                    color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].borderColor,
                    xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].xData
                  }
                ]
              ))


            }
          }
        }else if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar YesNo" ) {
          if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label2 === x) !== -1) {


            }
            else {
              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].displayLabel,
                    label2: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label,

                    yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
                      return i
                    }),
                    color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
                      return i?.color
                    }),
                    xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].xData,
                    borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].borderColor,

                  }
                ]
              ))


            }
          }
        }
        else {
          if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label2 === x) !== -1) {


            }
            else {

              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].displayLabel,
                    label2: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label,

                    yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
                      return i?.value
                    }),
                    color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
                      return i?.color
                    }),
                    xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].xData,
                    borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].borderColor,

                  }
                ]
              ))


            }
          }
        }
        setCellId(prev => ({
          ...prev,
          [x]: x
        }))
      })
    } else {
      setDatasets([{
        label: null,
        yData: [],
        xData: [] 
        
      }])
      selectOption.map(x => {
        x.isChecked = false
      });
      
      setSelectOption(selectOption)
      setChecked(false)
      setGraphMixStatus(false)
      setCellId("");
    }
  }
  

  useEffect(() => {
    const createPayloadForAll = [];
    selectOption.map((x) => {
      createPayloadForAll.push(x.name)
    });
    setAllGraphValue(createPayloadForAll)
    if(initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type11" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type6" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type7"){
      changeHandler3(null,"forceSelect")
    }
  }, [selectOption]);

  const display = selectOption.map((x,i) => {
    const styleValue = `ex`;
    return (
      <div className="chk-div">
        <Checkbox checked={x.isChecked} className="bold pr-3" name={x.name} onChange={changeHandler}></Checkbox>
        <div className={styleValue} style={{backgroundColor:x.color}}>{x.displayLabel}</div>
      </div>
    )
  })

  const optionsDisplay2 = selectOption.map((x,i) => {
    const styleValue = `ex`;
    return (
      <div className="">
         <Radio value={x.name} onChange={changeHandlerRadio}>
         <div className={styleValue} style={{backgroundColor:x.color}}>{x.displayLabel} </div>
         </Radio>
      </div>
    )
  })
  
  const antIcon = <ExclamationCircleOutlined style={{ fontSize: 36 }} />;
  return (
<Spin tip="Loading..." spinning={loading} size="large">
        <div className="page-with-sub-spinners">
        {/* <Spin tip={loadingText} spinning={loading2} size="large" indicator={antIcon}> */}
      <div className="content evaluate-page tab-style scroll-tab">
      <div className={`${bluredLayer}`} />

        <div className="card-chart  card">
          <div className="card-body">
            <h2><center>Smart Science <sup>&reg;</sup>Review</center></h2>
            <p align="center" className="tf15" family="CarmenSans-Regular">

            </p>
            <p align="center" className="tf15" family="CarmenSans-Regular">
              {`${props.user?? user} - ${classLessonName} - ${lessonName}`}
            </p>
            {/* <p align="center" className="tf15" family="CarmenSans-Regular">
              {`${MonthYear}, ${HrsMinSec} `}
            </p> */}
            <center><br />
              <p>
                Answer all questions. Use any information in the graph window.<br />
                Click <b>Submit</b> when you finish.
              </p>
            </center>
            {!reviewStatus && initPayload?.description !== null && initPayload?.description !== undefined && (<Row className="">
              <Col xs="12" className="">
                <br/>
              <div className="eve-text px-4 py-4">  <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div> </div>
              </Col>
            </Row>)}
            {
              initPayload?.guideline !== "" &&  initPayload?.guideline !== null &&
              initPayload?.guideline !== undefined &&
                <>
                    <br/>
                    <h5> LAB GUIDELINE </h5>
                    <hr className="hr-dark" />
                    <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.guideline }}></div>
<br/>
                </>
              
            }
            <br/>
            <div class="lab-hypothesis ex-tab active hypoex">
                <Row>
            <Col md="12">
            <fieldset>
<legend><strong>Hypothesis </strong> </legend>
<p className="f15 hype-text">
{definedHypothesis}
            </p>
</fieldset>
</Col>
</Row>

                  
                </div>
                <br />

            <Row className="pt-4">
              
              <Col md="6">
                <Row>
                 

                 

                  <Col md="6">
                    <div className="filters">
                    {initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type11" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type6" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type7" && 

                      <div className="chk-all">
                        <Checkbox  checked={checked} name={allGraphValue} onChange={changeHandler3}>
                          <span className="all pl-2"><b>All</b></span>
                        </Checkbox>
                      </div>
                    }  
                    {initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type11" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type6" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type7" ? display : 

                    (
                      <div className="row">
                      <Radio.Group >
                        {optionsDisplay2}
                      </Radio.Group>
                        </div>
                      )
                      }

                    </div>
                  </Col>
                  <Col md="5">
                    {
                      initPayload?.chartType[mappingId]?.calculationMethod ==="Bar YesNo" ||  initPayload?.chartType[mappingId]?.calculationMethod ==="Line Type7" || initPayload?.chartType[mappingId]?.calculationMethod ==="Line Type5" ? "": datasets.length > 0 && <CoordinatesValues dataTableValue = {dataTableValue} chartData={{ xLabel, yLabel,xLabelUnit:xgrLabel,yLabelUnit:ygrLabel }} clickState = {clickState} lesson = {initPayload.lessonCode} chartType= {initPayload?.chartType[mappingId]?.calculationMethod} data={initPayload?.chartType[mappingId]?.calculationMethod !== "Bar" ? graphMixStatus ? responseMixData : tablePayload : initPayload?.chartType[mappingId]?.calculationMethod === "Bar" && viewData} />
                    }
                    
                  </Col>
                </Row>
              </Col>



              <Col md="5">
              {datasets.length > 0 && 
              <>
              
                <div className="distance-evaluate">{initPayload?.chartType[mappingId]?.graphType !== "Line Type10" &&
                      initPayload?.chartType[mappingId]?.graphType !== "Line Type6" &&
                      initPayload?.chartType[mappingId]?.graphType !== "Line Type11" &&
                      initPayload?.chartType[mappingId]?.graphType !== "Line Type12" && (
                        <>
                          {selectValue !== undefined && lavelData !== undefined? (
                            <div className="distance-evaluate">
                              {yLabel}</div>
                          ) : ""}
                        </>
                      )}
                    {initPayload?.chartType[mappingId]?.graphType === "Line Type6" && (
                      <>
                        {selectValue !== undefined && lavelData !== undefined ? (
                          <div className="distance-earthquack-evaluate-tab">
                            {yLabel}</div>
                        ) : ""}
                      </>
                    )}

                    {initPayload?.chartType[mappingId]?.graphType === "Line Type10" && (
                      <>
                        {selectValue !== undefined && lavelData !== undefined ? (
                          <div className="distance-evaluate">
                            {yLabel}</div>
                        ) : ""}
                      </>
                    )}
                    {initPayload?.chartType[mappingId]?.graphType === "Line Type12" && (
                      <>
                        {selectValue !== undefined && lavelData !== undefined? (
                          <div className="distance-earthquack-evaluate-tab">
                            {yLabel}</div>
                        ) : ""}
                      </>
                    )}
                    {initPayload?.chartType[mappingId]?.graphType === "Line Type11" && (
                      <>
                        {selectValue !== undefined && lavelData !== undefined ? (
                          <div className="distance-evaluate">
                            {yLabel}</div>
                        ) : ""}
                      </>
                    )}
                </div>
               
               <div className="chart">
                <ChartValidations lesson={initPayload.lesson_id} heading={selectValue} type={initPayload.experiments.length > 0 && initPayload.experiments?.find(x => x.name[0].value  === selectValue)?.chart_type[mappingId]?.graphType} data={initPayload?.chartType[mappingId]?.calculationMethod === "Bar" || initPayload?.chartType[mappingId]?.calculationMethod === "Line Type5" ? secondLessonTablePayload:thirdLessonTablePayload} chartData={graphMixStatus ? responseMixData : graphdata1}
                    dataFromGraph={dataFromGraph} />
                
                    </div>

                 <Row>
                  <Col md="12" >

                    <center>
                    <br />
                      <div className={initPayload?.chartType[mappingId]?.calculationMethod === "Line Type6" ? "time-bublegraph" : initPayload?.chartType[mappingId]?.calculationMethod === "Line Type12" ? "time-bublegraph" : "time"}>{
                        selectValue !== undefined && lavelData !== undefined ?
                          xLabel
                          : ""
                      }</div>
                      <br />
                    </center>
                  </Col>
                </Row>
              </>
                }
              </Col>
            </Row>

            <br /><br /><br />
            <Row className="pt-4">
              <Col xs="12">
                <Card className="bg-evaluate border-0 rounded-0 guidelines-description-table">
                  <CardBody className="ml-5 mt-5 mb-5">
                    <h5> Questions</h5>
                    <hr className="black" />
                    <Row>
                      <Col xs="12">
                        {/* add question typw 2 n 4 also */}
                        <AnswerDisplay data={
                          {
                            type: 1,
                            question: initPayload.evaluateStepQuestions,
                            answers: initPayload.evaluateStepQuestionAnswers,
                            preData: containedPayload,
                            getData: sendPayload,
                            reviewStatus: reviewStatus,
                            subtype: 1
                          }
                        } />

                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* {
              teacherCommentStats && <InstructorComment  data = {teacherComment}/>
            } */}
            <Row>
             
              <FloatingButton name2={!props.complted && 'Submit Answers'} back={!props.complted && submitFun} name={'Proceed to Explain'} onClick={() => {
                window.scrollTo(0, 0)
                props.submitTrigger("4")
              }} />
            </Row>
            

            {!reviewStatus &&  initPayload?.description !== null && initPayload?.description !== undefined && (<Row className="">
              <Col xs="12" className="">
                <br/>
              <div className="eve-text px-4 py-4 guidelines-description-table">  <div dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div> </div>
              </Col>
            </Row>)}

          </div>
        </div>


      </div>
    {/* </Spin>  */}
         </div>
    </Spin>

  );
}

export default Evaluate;
