import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from "react-router-dom";

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle, 
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Table,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Breadcrumb, Radio, Input, Form, Modal, Button, Tabs, Checkbox, Image, Spin } from "antd";
import "antd/dist/antd.css";
import jsonToken from 'jsonwebtoken';
import AnswerDisplay from "../AnswerDisplay";
import { getExtendStepByLessonIdAndLevel } from "../../../../../api/TeacherService";

function ExtendChild(props) {
    const [payload, setPayload] = useState();

    const [initPayload, setInitPayload] = useState({
      description:"",
      extendStepQuestionAnswers:[],
      extendStepQuestions:[],
      id:"",
      lesson_id:"",
    guideline:"",
    });

    const [containedPayload, setContainedPayload] = useState({});
    const [reviewStatus,setReviewStatus] = useState( props.disabled ?? true);
    const [lesson,setLesson] = useState(parseInt(props.extendId));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [validationMsg, setValidationMsg] = useState("");
    const [lessonName,setLessonName] = useState("");
    const [loading,setLoading] = useState(true);
    function convert(data) {
      return Array.isArray(data)
          ? data.reduce( (obj, el, i) => (el+1 && (obj[i] = convert(el)), obj), {} )
          : data;
    }

    let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;
    
  }


    
    useEffect( ()=>{
     setLoading(true)
     getExtendStepByLessonIdAndLevel(parseInt(props.data.lessonId), props.data.levelId)
    .then((response) => {
      if(response !== undefined){
        setInitPayload(response.data);
        setLessonName(response?.data?.lessonName);
        setClassLessonName(response?.data?.className);
      }else{
        
      }
      setLoading(false)
    })
    }, [])


  const [classLessonName, setClassLessonName] = useState("");
  
   
  useEffect(()=>{
    const childPayload ={
      classLevelName : classLessonName,
      lessonName : lessonName
    }
    props.subChildCall(childPayload)
  },[classLessonName, lessonName])

  return (
    <Spin tip="Loading..." spinning={loading} size="large">
      <div className="content extend-page tab-style ">
        <div className="card-chart p-3 card card-grey no-border">
          <div className="card-body">
          <h2><center>Smart Science<sup>&reg;</sup></center></h2>
           
            <center>
              <p>
                Answer all questions. Use any information in the graph window.<br />
                Click <b>Submit</b> when you finish.
              </p>
            </center>
            {
              initPayload?.description !== "" &&
                  <>
                  <h5> LAB DESCRIPTION </h5>
                    <hr className="hr-dark" />
                    <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div>
                  </>
            }<br/>
            {
              initPayload?.guideline !== "" && 
                <>
                    <br/>
                    <h5> LAB GUIDELINE </h5>
                    <hr className="hr-dark" />
                    <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.guideline }}></div>

                </>
              
            }
          </div>
        </div>

        <div className="card-chart  card card-grey">
          <div className="card-body">
            <Row className="">
              <Col xs="12">
                <Card className="bg-evaluate border-0 rounded-0 no-border guidelines-description-table">
                  <CardBody className="">

                    
                    <AnswerDisplay data = {{
                      type:2,
                      payload:initPayload?.extendStepQuestions,

                    }} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default ExtendChild;
