import React from "react";
import {
  Row,
} from "reactstrap";
import "antd/dist/antd.css";
import { Chart as ChartJS, registerables } from 'chart.js';

import { Line } from "react-chartjs-2";
ChartJS.register(...registerables);

function LineChart({ chartData }) {
  return (
    <>
    {/* <Row>
            Result
        </Row> */}
        {/* <Row>
            <div className="numeric-value-holder">
            <span className="numeric-values" > Numeric Value </span>
            </div>
        </Row> */}
        <div className="chart">
        <Line data={chartData} responsive="true" height={200}/>
        </div>
    </>
  );
}

export default LineChart;