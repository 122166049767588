import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import logo from "../../assets/images/logo.svg";
import { Button } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import img1 from "../../assets/images/login-bg1.png";
import img2 from "../../assets/images/login-bg2.png";
import clever from "../../assets/images/clever-tp.png";
import okta from "../../assets/images/Okta-tp.png";
import { useHistory } from 'react-router-dom';
import { Alert, Spin, Modal, Col, Row, Badge, notification } from 'antd';
import { LoginPost } from "../../api/LoginService";
import classLink from '../../assets/images/classlink-tp.png'
import Config from "../../api/Config";
import { LGclearStorage, LoginCheckWithPages } from "../../components/LoginCheck/LoginCheck";
import jsonToken from 'jsonwebtoken'

const Login = (props) => {
  const history = useHistory();

  const [loginData, setLoginData] = useState(
    {
      username: '',
      password: ''
    }
  );
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState({
    showAlert: false,
    message: ""
  });

  const openNotification = (message) => {
    notification.error({
      message: message,
      description: "Contact your school administration to gain access.",
      placement:'top',
    });
  };

  useEffect(() => {
    // Get the query parameters from the URL
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Get the values of refreshToken and accessToken from the query parameters
    const refreshToken = urlParams.get('refreshToken');
    const accessToken = urlParams.get('accessToken');
    const receivedError = urlParams.get('error');
    const receivedMessage = urlParams.get('message');

    if (receivedError === "false") {
        localStorage.setItem('refresh_token', refreshToken);
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('userRoutes', JSON.stringify(jsonToken.decode(accessToken).userRoutes));
        sessionStorage.setItem("loggedin", "true");
        const currentTime = Date.now();
        localStorage.setItem("expirationTime", currentTime + 28800000);
        // try out
        const urlWithoutParams = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, urlWithoutParams);
        history.push('Home');
    }else if(receivedError === "true"){
      setAlertData({
        showAlert: true,
        message: receivedMessage
      });
      sessionStorage.setItem("loggedin", "false");

      function setTimeOutFunction() {
         const urlWithoutParams = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, urlWithoutParams);
      }
      // Call the function after a 2-second delay
      setTimeout(setTimeOutFunction, 100);

       
    }
    LoginCheckWithPages(false)
  }, [])
  function onUserNameChange(e) {
    e.persist();
    setLoginData((loginData) => ({
      ...loginData,
      username: e.target.value,
    }));
  }

  function onPasswordChange(e) {
    e.persist();
    setLoginData((loginData) => ({
      ...loginData,
      password: e.target.value,
    }));
  }

  function onLoginClick(e) {
    e.preventDefault()
    if(loginData.username === ""){
      setAlertData({
        showAlert: true,
        message: "Error: " + "Please enter a User ID or Username"
      });
      return
    }else if(loginData.password === ""){
      setAlertData({
        showAlert: true,
        message: "Error: " + "Please enter a password"
      });
      return
    }
    setLoading(true);
    LoginPost(loginData)
      .then((data) => {
        setLoading(false);
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem('userRoutes', JSON.stringify(jsonToken.decode(data.access_token).userRoutes));
        localStorage.setItem("refresh_token", data.refresh_token);
        sessionStorage.setItem("loggedin", "true");
        history.push('Home');
        const currentTime = Date.now();
        //28800000
        localStorage.setItem("expirationTime", currentTime + 28800000);
      })
      .catch((e) => {
        if(e.status === 401){
          setAlertData({
            showAlert: true,
            message: "Error: " + e.message
          });
        }else{
          openNotification(e.message)
          setAlertData({
            showAlert: true,
            message: "Error: " + e.message
          });
        }
        
        setLoading(false);
      });
  }

  const [isModalOpen, setIsModalOpen] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const authentication1 = () => {
    window.open(Config.AUTH1)
    window.close();
  }

  const authentication2 = () => {
    window.open("https://dev-49092175.okta.com/oauth2/default/v1/authorize?client_id=0oa9n5p1iav9JNleF5d7&response_type=code&scope=openid&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&state=296bc9a0-a2a2-4a57-be1a-d0e2fd9bb601");
    window.close();
  }

  const authentication3 = () => {
    window.open(Config.AUTH2)
    window.close();
  }


  return (

    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5 tab-hide">
            <Carousel fade>
              <Carousel.Item>
                <img
                  className="d-block login-bg"
                  src={img1}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block login-bg"
                  src={img2}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block login-bg"
                  src={img1}
                  alt="First slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4">
            <Spin tip="Loading..." spinning={loading} size="large">
              <form className="login-form">
                <div className="center mt-5">
                  <img src={logo} alt="logo" />
                </div>
                <div className="row mb-15">
                  <div className="col-md-12">
                    <center>
                      {alertData.showAlert ? <Alert message={alertData.message} type="error" /> : ""}
                    </center>
                  </div>
                </div>
                {/* <!-- Username input --> */}
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="form3Example3">
                    User ID or Username
                  </label>
                  <input
                    type="text"
                    id="form3Example3"
                    className="form-control form-control-lg"
                    placeholder=""
                    onChange={onUserNameChange}
                  />
                </div>

                {/* <!-- Password input --> */}
                <div className="form-outline mb-3">
                  <label className="form-label" htmlFor="form3Example4">
                    Password
                  </label>
                  <input
                    type="password"
                    id="form3Example4"
                    className="form-control form-control-lg"
                    autoComplete="off"
                    placeholder=""
                    onChange={onPasswordChange}
                  />
                </div>

                <div className="">
                  {/* <!-- Checkbox --> */}
                  <div className="form-check mb-0 pull-right">
                    <label className="form-check-label" htmlFor="form2Example3">
                      Remember me
                    </label>
                    <input
                      className="form-check-input me-2 remember"
                      type="checkbox"
                      value=""
                      autoComplete="off"
                      id="form2Example3"
                    />
                  </div>
                </div>

                <div className="row mt-3hr">
                  <div className="col-md-12">
                    <center>
                      <Button type="primary w-100" size="lg" className="btn-block" onClick={onLoginClick}>
                        Sign in
                      </Button>

                    </center>
                  </div>

                  <div className="col-md-12">
                    <div class="ruler-container">
                      <div class="ruler text-center">
                        <span class="ruler-text">OR</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <center>
                      <Button onClick={authentication1} type="blue w-100" size="lg" className="btn-block btn-clever sso-logo-btn">
                        <img src={clever} alt="Logo" className="sso-logo-img" />
                        <div className="text">Login with Clever</div>
                      </Button>
                    </center>
                  </div>

                  <div className="col-md-12">
                    <center>
                      <Button onClick={authentication2} type="blue w-100" size="lg" className="btn mt-10 btn-block  btn-otave sso-logo-btn">
                        <img src={okta} alt="Logo" className="sso-logo-img" />
                        <div className="text">Login with Okta</div>
                      </Button>
                    </center>
                  </div>
                  <div className="col-md-12">
                    <center>
                    {/* <Badge.Ribbon text="New" color="volcano" > */}
                      <Button onClick={authentication3} type="blue w-100" size="lg" className="btn mt-10 btn-block  btn-classlink sso-logo-btn">
                        <img src={classLink} alt="Logo" className="sso-logo-img" />
                        <div className="text">Login with ClassLink</div>
                      </Button>
                    {/* </Badge.Ribbon> */}
                    </center>
                  </div>
                </div>

              </form>
            </Spin>
          </div>
        </div>
      </div>
    </section>
  );
};

Login.propTypes = {
  //dispatch: PropTypes.func.isRequired,
};

export default Login;
