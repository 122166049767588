import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import RestService from '../../api/RestService'
import './style.scss'
import jsonToken from 'jsonwebtoken';
import { CloseOutlined } from '@ant-design/icons';
import { Spin } from 'antd';


function SubSidebarNotification(props) {
    const currentURl = window.location.href;
    const neededUrl = (currentURl.split("#"))[0];
    const [loading, setLoading] = useState(false);

    let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;

  }
const navigationLink = () =>{
    console.log();
    setLoading(true)

    localStorage.setItem("className", props.data.className);
    localStorage.setItem("lessonID", props.data.classId);
    localStorage.setItem("specific",props.data.step)

    const dataPayload = `userName=${user}&${props.data.step}Id=${props.data.stepId}&stepType=${props.data.step}`

    // getting the restLessonIds from the class ID
    RestService.getLessonsByClass(props.data.classId)
   .then((response) => {
        if(response.status === 200){
            const objectVal = Object.values(response.data).find(obj => obj.lessonId === props.data.id);
            RestService.getUpdateStudentNotificationStatus(dataPayload).then((response2)=>{
                if(response2.status === 200){
                    let url = `${neededUrl}#/LessonLab/${objectVal.engageStepId}/${objectVal.evaluateStepId}/${objectVal.explainStepId}/${objectVal.exploreStepId}/${objectVal.extendStepId}`;
                    window.location.href = url;
                    window.location.reload();
                    setLoading(false)
                }
            })
        }else{
            setLoading(false)
        }
     })
     
}
const removeLink = () =>{
    const dataPayload = `userName=${user}&${props.data.step}Id=${props.data.stepId}&stepType=${props.data.step}`
    RestService.getUpdateStudentNotificationStatus(dataPayload);
     
}
        return(<>
            <div className="notification-container unvisited">
                {/* <div className='notification-container-hover text-right' >
                   <CloseOutlined onclick={removeLink} />
                 </div> */}
                <div className='pr-2 click' onClick={navigationLink}>
                <Spin tip="Loading..." spinning={loading} size="large">
                    {props.data.data}
                </Spin>
                </div>
            </div>
        </>)
       
}

export default SubSidebarNotification