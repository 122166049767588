import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import './style.scss'


function InstructorComment(props) {
  return (
    <>
                <Row>
              <Col md="12" className="py-3 full-width">
                <div id={"teacher-evaluates-answer-comment"} className="teacher-evaluates-answer px-4 py-4">
                <h5 id={`InstructorHeader${props.from}`}> Instructor Comment </h5>
                <hr />
                  <p id={`InstructorContent${props.from}`}>
                  {props?.data}
                  </p>
                </div>
              </Col>
            </Row>
    </>
  )
}

export default InstructorComment