import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Table,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import {
  Radio,
  Form,
  Modal,
  Button,
  Tabs,
  Select,
  Image,
  List,
  Typography,
  Collapse,
} from "antd";
import "antd/dist/antd.css";

function Answers(props) {
  const [dataPayload, setDataPayload] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  
  useEffect(() => {
    let arr = []
    props?.question?.map((x) => {
      const ansData = props?.answers?.reduce(function (acc, value) {
        if (value.question_id === x.id) {
          acc.push(value)
        }
        return acc;
      }, []);

      const payload = {
        id: x.id,
        question: x.description,
        answers: ansData,
        image: x.image,
        className: props.className
      }
      arr.push(payload)
      
    })
    setDataPayload(arr)
  }, [props.question, props.answers, props.className])

  useEffect(() => {

    if (props?.preData !== undefined && props.preData !== null) {
      const newData = Object.values(props?.preData);
      newData.map((x) => {

        if (x.question_id !== "NaN" && x.question_id !== "undefined" && x.question_id !== null) {
          setSelectedValue(prev => ({
            ...prev,
            [x.question_id]: { question_id: parseInt(x.question_id), answer: x.answer }
          }));
        }

      })
    }

  }, [props.preData])



  const handleChange = (event) => {
   
    if(!props.reviewStatus){

    }
    else{
      const name = event.target.name ;
    const value = event.target.value;

    setSelectedValue(prev => ({
      ...prev,
      [name]: { question_id: parseInt(name), answer: value }
    }));
    }

  };


  props.getData(selectedValue);


  const display = (value) => props.answers.map((x) => {
    if (x.question_id === value) {
      if (x.answerNumber === parseInt(selectedValue[value]?.answer)) {

        if (x.is_correct_answer === 1) {
          return (
            <span className="success-answer px-4 py-4" dangerouslySetInnerHTML={{ __html: x.description }}></span>
          )
        } else {
          return (
            <span className="wrong-answer px-4 py-4" dangerouslySetInnerHTML={{ __html: x.description }}></span>
          )
        }

      } else {
        return (
          <>
          </>
        )
      }
    }
  });

  const display2 = (value) => props.answers.map((x) => {


    if (x.question_id === value) {
      
      if (x.answerNumber === parseInt(selectedValue[value]?.answer)) {

        const filteredData = props.question.find(x => x.id === value)
        if (x.is_correct_answer === 1) {
          return (
            <span className="success-answer px-4 py-4" dangerouslySetInnerHTML={{ __html: filteredData.question_explanation }}></span>
          )
        } else {
          return (
            <span className="wrong-answer px-4 py-4" dangerouslySetInnerHTML={{ __html: filteredData.question_explanation}}></span>
          )
        }

      } else {
        const  intResult = Object.keys(selectedValue).map(x => { 
          return parseInt(x); 
        });
        const found = intResult.includes(value);
        if(!found){
          if(!questionCount.includes(value)){
            const filteredData = props.question.find(x => x.id === value)
            questionCount.push(value)
            return (
              <span className="answer-info px-4 py-4" dangerouslySetInnerHTML={{ __html: filteredData.question_explanation  }}></span>
            )
          }
        }
      }
    }
  });

  
  let questionCount = []

  const display3= (value) => props.answers.map((x)=>{
    if (x.question_id === value) {
      if (x.answerNumber === parseInt(selectedValue[value]?.answer)) {
      const filteredData = props.question.find(x => x.id === value)
        if (x.is_correct_answer === 1) {
          return (
            <span className="success-answer px-4 py-4" dangerouslySetInnerHTML={{ __html: filteredData.questionExplanation }}></span>
          )
        } else {
          return (
            <span className="wrong-answer px-4 py-4" dangerouslySetInnerHTML={{ __html: filteredData.questionExplanation }}></span>
          )
        }

      } else {
        const  intResult = Object.keys(selectedValue).map(x => { 
          return parseInt(x); 
        });
        const found = intResult.includes(value);
        if(!found){
          if(!questionCount.includes(value)){
            const filteredData = props.question.find(x => x.id === value)
            questionCount.push(value)
            return (
              <span className="answer-info px-4 py-4" dangerouslySetInnerHTML={{ __html: filteredData.questionExplanation }}></span>
            )
          }
        }
      }
    }
  });

  const answerDisplay = (item, answers, x) => {
    if( answers.answerNumber===parseInt(props?.preData[x]?.answer)){
      if( answers.is_correct_answer === 1){
        return(
          <>
            <div className="green" dangerouslySetInnerHTML={{ __html: answers.answer }}></div>
          </>
        )}
        else{
          return(
            <>
              <div className="red" dangerouslySetInnerHTML={{ __html: answers.answer }}></div>
            </>
          )
        }
    }else{
      if( answers.is_correct_answer === 1){
      return(
        <>
          <div className="green" dangerouslySetInnerHTML={{ __html: answers.answer }}></div>
        </>
      )}
      else{
        return(
          <>
            <div className="initial" dangerouslySetInnerHTML={{ __html: answers.answer }}></div>
          </>
        )
      }
    }
  };

  const checkPayload = (item, answers, x) => {
    if (item.id === props?.preData[x]?.question_id && answers.answerNumber === parseInt(props?.preData[x]?.answer)) {
      return (
        <>
          <Input type="radio" name={item.id} value={answers.answerNumber} defaultChecked disabled={!props.reviewStatus}/>
          <span className="checkmark">
            {(answers.is_correct_answer === 0) ? (
              <span class="notranslate">X</span>
            ) : (
              <>✓</>
            )}
          </span>
        </>
      )
    } else{
         return(
            <>
            <Input type="radio" name={item.id} value={answers.answerNumber} disabled={!props.reviewStatus}/>
            <span className="checkmark">
              {(answers.is_correct_answer===0)?(
                <span class="notranslate">X</span>
              ):(
                <>✓</>
              )}
            </span>
            </>
          )

    }
  }

  const checkPayload2 = (item, answers, x) =>{

    if(item.id===props?.preData[x]?.question_id && answers.answerNumber===parseInt(props?.preData[x]?.answer)){
      
      return(
        <>
          <Input type="radio" name={item.id} value={answers.answerNumber} defaultChecked disabled={!props.reviewStatus}/>
          <span className="checkmark">
            {(answers.is_correct_answer===0)?(
              <span class="notranslate">X</span>
              ):(
              <>✓</>
            )}
          </span>
        </>
      )
    }else{
         return(
            <>
            <Input type="radio" name={item.id} value={answers.answerNumber} disabled={!props.reviewStatus}/>
            <span className="checkmark">
              {(answers.is_correct_answer===0)?(
                <>✓</>
              ):(
                <>✓</>
              )}
            </span>
            </>
          )

    }
  }


  return (
    <Row className="space-my-20 space-mx-20">
      
      <List
        dataSource={dataPayload}
        renderItem={(item, x) => (
          <List.Item key={x}>
            <Row className="">
              {/* Question  */}
              <Col lg='12'>
                <Row>
                <Col lg='9' className="order2">
                    <div className="display-flex">
                    <span className="p-proprty pl-1 bullet-number">{x + 1}.</span>
                    <span className="p-proprty pl-1" dangerouslySetInnerHTML={{ __html: item.question }}></span>
                    </div>
                    <div className="mt-3">
                      <List
                        dataSource={item.answers}
                        renderItem={(answers, z) => (
                          <List.Item key={z}>
                            <FormGroup tag="fieldset">
                              <div className="unmarked-answer px-4 pt-1">
                                <label className="result-radio-btn" onChange={handleChange}>
                                  {props?.subtype === 0 ? checkPayload(item, answers, x):checkPayload2(item, answers, x)}
                                </label>
                                <span className="p-proprty pl-1">
                                  {props.reviewStatus === true ? (
                                    <div dangerouslySetInnerHTML={{ __html: answers.answer }}></div>
                                  ):answerDisplay(item, answers, x)}
                                </span>
                              </div>
                            </FormGroup>
                          </List.Item>
                        )}
                      />
                    </div>
                    {props.reviewStatus === true ? props.subtype === 0 ? display(item.id) : "" :  props.subtype === 0 ? display2(item.id) : display3(item.id)}
                  </Col>
                  <Col lg="3">
                    <Card className="border-0 rounded-0  bg-transparent">
                      <img src={item.image !== null ? item.image : null} className="w-80 qimage" alt="" />
                    </Card>
                  </Col>
                </Row>
                <hr className="hr-dark mt-5" />
              </Col>
            </Row>

          </List.Item>
        )}
      />
    </Row>
  );
}

export default Answers;
