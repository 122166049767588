import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from 'react-router-dom'

// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  Row,
  Col,
  CardImgOverlay,
} from "reactstrap";
import { Spin, Tabs } from "antd";
import "antd/dist/antd.css";
import CardImgs from "../../assets/images/CardImg.png"
import RestService from "../../api/RestService";

function Class(props) {
  const [data, setData] = useState({data:[]});
  const [loading, setLoading] = useState(false);
  useEffect( ()=>{
    setLoading(true)
    RestService.getMyClasses()
   .then((response) => {
      setData(response)
      setLoading(false)
     })
   }, []);
 

   const display = data.data.map((x)=>{
           if(data.status === 200){
            return(
            <Col lg='3' md='6'>
            <Link onClick={()=>{
              localStorage.setItem("className", x.name);
              localStorage.setItem("selectedClassID", x.id);
            }}
            value={x.id} to={`/Lesson/${x.id}`}>
              <div className="course-card">
                <Card className="border-0 rounded-0  ">
                  <CardImg
                    alt="Class Card"
                    src={CardImgs}
                    width="100%"
                  />
                  <div className="">
                    <CardText className="overlayer-center w-80 overlayer-bg-dark overlayer-space-20 text-white layer-1">
                    <h4 className="text-white">{x.name}</h4>
                    </CardText>
                    <CardImgOverlay className="overlayer-center w-100 h-100 overlayer-bg-dark layer-2 ">
                      <CardText className="overlayer-center w-100 text-white">
                      <h4 className="text-white">{x.name}</h4>
                        <hr className="hr-white" />
                        {/* change to number of  lessons in that class*/}
                        <small className="text-white">{x.description}</small>
                      </CardText>
                    </CardImgOverlay>
                  </div>
                </Card>
              </div>
              </Link>
            </Col>
            )
        }
   },[data]);
  return (
    <>
    <div>
      <Spin tip="Loading..." spinning={loading} size="large">
    <div className="container">
      <div className="content">
       
        <Row>
          {display}
          
          {/*
           <Col md='3'>
            <div className="course-card">
              <Card className="border-0 rounded-0  ">
                <CardImg
                  alt="Class Card"
                  src={CardImgs2}
                  width="100%"
                />
                <div className="">
                  <CardText className="overlayer-center w-80 overlayer-bg-dark overlayer-space-20 text-white layer-1">
                  <h4 className="text-white"> Anatomy and Physiology</h4>
                  </CardText>

                  <CardImgOverlay className="overlayer-center w-100 h-100 overlayer-bg-dark layer-2 ">
                    <CardText className="overlayer-center w-100 text-white">
                     <h4 className="text-white"> Anatomy and Physiology</h4>
                      <hr className="hr-white" />
                      <small className="text-white">56 Lessons</small>
                    </CardText>
                  </CardImgOverlay>
                </div>
              </Card>
            </div>
          </Col>
          
          <Col md='3'>
            <div className="course-card">
              <Card className="border-0 class-card" style={{backgroundColor:"#9A58BC"}}>
               
                <div className="">
                  <CardText className="overlayer-center w-80 overlayer-bg-dark overlayer-space-20 text-white layer-1">
                  <h4 className="text-white"> Anatomy and Physiology</h4>
                  </CardText>

                  <CardImgOverlay className="overlayer-center w-100 h-100 overlayer-bg-dark layer-2 ">
                    <CardText className="overlayer-center w-100 text-white">
                     <h4 className="text-white"> Anatomy and Physiology</h4>
                      <hr className="hr-white" />
                      <small className="text-white">56 Lessons</small>
                    </CardText>
                  </CardImgOverlay>
                </div>
              </Card>
            </div>
          </Col>
          
          <Col md='3'>
            <div className="course-card">
              <Card className="border-0 class-card" style={{backgroundColor:"#1BA083"}}>
                
                <div className="">
                  <CardText className="overlayer-center w-80 overlayer-bg-dark overlayer-space-20 text-white layer-1">
                  <h4 className="text-white"> AP Physics</h4>
                  </CardText>

                  <CardImgOverlay className="overlayer-center w-100 h-100 overlayer-bg-dark layer-2 ">
                    <CardText className="overlayer-center w-100 text-white">
                     <h4 className="text-white"> AP Physics</h4>
                      <hr className="hr-white" />
                      <small className="text-white">56 Lessons</small>
                    </CardText>
                  </CardImgOverlay>
                </div>
              </Card>
            </div>
          </Col> */}
        </Row>
      </div>
      </div>
      </Spin>
      </div>
    </>
  );
}

export default Class;