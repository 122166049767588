import axios from "axios";
import Config from "./Config";

class LessonService {
  getLesson(page, size, search) {
    const token = localStorage.getItem("access_token");
    const encodedSearch = encodeURIComponent(search);

    return axios.get(Config.APIURL + `lesson/getAllLessons_paged?page=${page}&size=${size}&search=${encodedSearch}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  getCurriculum() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'curriculum/getAllCurriculums', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  getCurriculumById(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/getAllUnassignCurriculamForLesson/' + id , { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  addLesson(lessonInfo) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/addNewLesson', lessonInfo, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {


        return res;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  };
  addExperimentType(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/explore/addUpdateExperimentType', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;

        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  };
  updateLesson(editInfor) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/updateLesson', editInfor, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return res;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  getAllStepsCount(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/getAllStepCount/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  getAllSelectedClassLevel(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/engage/getStepsByLessonId/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  getExploreAllSelectedClassLevel(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/explore/getStepsByLessonId/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }


  getEvaluateAllSelectedClassLevel(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/evaluate/getStepsByLessonId/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  getExplainAllSelectedClassLevel(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/explain/getStepsByLessonId/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  getExtendAllSelectedClassLevel(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/extend/getStepsByLessonId/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  addEngageBasicInfo(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/engage/addUpdateStep', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  addExploreBasicInfo(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/explore/addUpdateStep', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  addEvaluateBasicInfo(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/evaluate/addUpdateStep', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  addExplainBasicInfo(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/explain/addUpdateStep', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  addExtendBasicInfo(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/extend/addUpdateStep', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }

  getEngageBasicInfo(lesson, classLevel) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/engage/getStepByLevel/' + lesson + "/" + classLevel, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  getExploreBasicInfo(lesson, classLevel) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/explore/getStepByLevel/' + lesson + "/" + classLevel, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  getEvaluateBasicInfo(lesson, classLevel) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/evaluate/getStepByLevel/' + lesson + "/" + classLevel, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  getExtendBasicInfo(lesson, classLevel) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/extend/getStepByLevel/' + lesson + "/" + classLevel, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  getExplainBasicInfo(lesson, classLevel) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/explain/getStepByLevel/' + lesson + "/" + classLevel, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }

  addStepQuestions(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/engage/addUpdateStepQuestion', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  addExploreHypothesis(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/explore/addUpdateHypothesis', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return ("Server Error: ", err);
      })
  }
  addExploreExperiment(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/explore/addExperiment', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  addExploreQuestions(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/explore/addUpdateStepQuestion', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  addExploreInstructions(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/explore/addUpdateInstruction', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  addExploreHypothesisOptions(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/explore/addUpdateHypothesisOption', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  addExploreQuestionAnswers(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/explore/addStepQuestionAnswers', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  addEvaluateStepQuestions(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/evaluate/addUpdateStepQuestion', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  addExplainStepQuestions(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/explain/addUpdateStepQuestion', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {

        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  addExtendStepQuestions(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/extend/addUpdateStepQuestion', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  getAllExperiments(explore_id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/explore/getExperimentByExploreId/' + explore_id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  getSpecificExperiment(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/explore/getExperimentByExperimentId/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getHypothesisOfExperiment(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/explore/getHypothesisByExpId/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getAllQuestions(engageStepId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/engage/getStepQuestions/' + engageStepId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  getSpecificQuestion(questionId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/engage/getStepQuestionById/' + questionId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  getEvaluateAllQuestions(engageStepId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/evaluate/getStepQuestions/' + engageStepId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  getEvaluateSpecificQuestion(questionId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/evaluate/getStepQuestionById/' + questionId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  getExplainSpecificQuestion(questionId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/explain/getStepQuestionById/' + questionId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  getExtendSpecificQuestion(questionId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/extend/getStepQuestionById/' + questionId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getAllExplainQuestions(explain_id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/explain/getStepQuestions/' + explain_id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  getAllExtendQuestions(extend_id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/extend/getStepQuestions/' + extend_id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  addQuestionAnswers(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/engage/addStepQuestionAnswers', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  addEvaluateQuestionAnswers(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/evaluate/addStepQuestionAnswers', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  deleteQuestion(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/engage/deleteStepQuestion/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  deleteEvaluateQuestion(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/evaluate/deleteStepQuestion/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  deleteExplainQuestion(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/explain/deleteStepQuestion/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  deleteExtendQuestion(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/extend/deleteStepQuestion/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  deleteExperiment(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/explore/deleteExperiment/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  deleteExperimentType(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/explore/deleteExperimentTypes/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getLessonsByCurriculumId(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/lesson/getLessonsByCurricuram/' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getAllExperimentsTypes() {
    const token = localStorage.getItem("access_token");

    return axios.get(Config.APIURL + 'admin/lesson/explore/getAllExperimentTypes', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }


  updateLessonThumbnail(lessonID, data) {
    const token = localStorage.getItem("access_token");

    return axios.post(Config.APIURL + 'admin/lesson/updateLessonThumbnail/'+lessonID,data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  updatImage(questionID, data) {
    const token = localStorage.getItem("access_token");

    return axios.post(Config.APIURL + 'admin/lesson/updateImage/'+questionID,data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  updatVideo(experiment_id, data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/explore/updateExploreExperimentVideo/'+experiment_id,data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  updatExploreImage(experiment_id, data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/lesson/explore/updateExploreExperimentImages/'+experiment_id,data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  updateUserRoutes(userId, stepName, status) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'user/updateUserRoutes/'+userId+'?status='+status+'&stepName='+stepName, {},{ headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

}

export default new LessonService
