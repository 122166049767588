/*
********* 🧒🏻 Development *********
*/
// const Config = {
//   LOGINURL: "http://192.168.202.136:8080/SmartScience-LMS/login",
//   APIURL: "http://192.168.202.136:8080/SmartScience-LMS/api/",
//   ASSETURL: "http://192.168.202.136:85/smartscience/assets/LESCODE/",
//   AUTH1: "https://clever.com/oauth/authorize?response_type=code&client_id=2c612b37139d9decb7a7&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&scope=read%3Aall%20write%3Aall",
//   AUTH2: "https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Fapi%2Fclasslink%2Fredirect&client_id=c1687959969354f6aae4f53ac0e0f926a9023bb54b09&response_type=code",
// };

/*
********* 👉 QA *********
*/
// const Config = {
//   LOGINURL: "http://192.168.202.136:8180/SmartScience-LMS/login",
//   APIURL: "http://192.168.202.136:8180/SmartScience-LMS/api/",
//   ASSETURL: "http://192.168.202.136:85/smartscience/assets/LESCODE/",
//   AUTH1: "https://clever.com/oauth/authorize?response_type=code&client_id=2c612b37139d9decb7a7&redirect_uri=https%3A%2F%2Ftest.smartsciencetest.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&scope=read%3Aall%20write%3Aall",
//   AUTH2: "https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Ftest.smartsciencetest.com%2FSmartScience-LMS%2Fapi%2Fclasslink%2Fredirect&client_id=c1690257137522c184ab0b736dfaa2edecd5dc4c5d28&response_type=code",
// };


/* you can hide this :)
//********* 🗑️ Demo 2 *********
// const Config = {
//   LOGINURL: "https://smartsciencetest.com/SmartScience-LMS/login", 
//   APIURL: "https://smartsciencetest.com/SmartScience-LMS/api/",
//   ASSETURL: "https://smartsciencetest.com:8443/smartscience/assets/LESCODE/",
//   AUTH1: "https://clever.com/oauth/authorize?response_type=code&client_id=2c612b37139d9decb7a7&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&scope=read%3Aall%20write%3Aall",
//   AUTH2: "https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Fapi%2Fclasslink%2Fredirect&client_id=c1687959969354f6aae4f53ac0e0f926a9023bb54b09&response_type=code",
// };

//*********🗑️ Demo 1 *********
// const Config = {
//   LOGINURL: "https://smartsciencedemo.com/SmartScience-LMS/login",
//   APIURL: "https://smartsciencedemo.com/SmartScience-LMS/api/",
//   ASSETURL: "https://smartsciencedemo.com:8443/smartscience/assets/LESCODE/",
//   AUTH1: "https://clever.com/oauth/authorize?response_type=code&client_id=2c612b37139d9decb7a7&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&scope=read%3Aall%20write%3Aall",
//   AUTH2: "https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Fapi%2Fclasslink%2Fredirect&client_id=c1687959969354f6aae4f53ac0e0f926a9023bb54b09&response_type=code",
// };

//*********🗑️ Linux *********
// const Config = {
//   LOGINURL: "http://34.173.156.212:8081/login",
//   APIURL: "http://34.173.156.212:8081/api/",
//   ASSETURL: "http://34.173.156.212:8082/smartscience/assets/LESCODE/",
//   AUTH1: "https://clever.com/oauth/authorize?response_type=code&client_id=2c612b37139d9decb7a7&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&scope=read%3Aall%20write%3Aall",
//   AUTH2: "https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Fapi%2Fclasslink%2Fredirect&client_id=c1687959969354f6aae4f53ac0e0f926a9023bb54b09&response_type=code",
// };

//*********🗑️ Linux SSL *********
// const Config = {
//   LOGINURL: "https://ssl.smartsciencetest.com:8081/login",
//   APIURL: "https://ssl.smartsciencetest.com:8081/api/",
//   ASSETURL: "https://ssl.smartsciencetest.com:8082/smartscience/assets/LESCODE/",
//   AUTH1: "https://clever.com/oauth/authorize?response_type=code&client_id=2c612b37139d9decb7a7&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&scope=read%3Aall%20write%3Aall",
//   AUTH2: "https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Fapi%2Fclasslink%2Fredirect&client_id=c1687959969354f6aae4f53ac0e0f926a9023bb54b09&response_type=code",
// };


//🗑️ PG
// const Config = {
//   LOGINURL: "http://192.168.202.135:8080/SmartScience-LMS/login",
//   APIURL: "http://192.168.202.135:8080/SmartScience-LMS/api/",
//   ASSETURL: "http://192.168.202.136:85/smartscience/assets/LESCODE/",
//   AUTH1: "https://clever.com/oauth/authorize?response_type=code&client_id=2c612b37139d9decb7a7&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&scope=read%3Aall%20write%3Aall",
//   AUTH2: "https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Fapi%2Fclasslink%2Fredirect&client_id=c1687959969354f6aae4f53ac0e0f926a9023bb54b09&response_type=code",
// };
//🗑️ PG 2
// const Config = {
//   LOGINURL: "http://192.168.202.135:8080/SmartScience-LMS/login",
//   APIURL: "http://192.168.202.135:8080/SmartScience-LMS/api/",
//   ASSETURL: "http://192.168.202.135:85/smartscience/assets/LESCODE/",
//   AUTH1: "https://clever.com/oauth/authorize?response_type=code&client_id=2c612b37139d9decb7a7&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&scope=read%3Aall%20write%3Aall",
//   AUTH2: "https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Fapi%2Fclasslink%2Fredirect&client_id=c1687959969354f6aae4f53ac0e0f926a9023bb54b09&response_type=code",
// };
*/

/*
********* 👉 Linux test smartScience *********
*/
// test
//const Config = {
  //LOGINURL: "https://test.smartsciencetest.com/SmartScience-LMS/login",
  //APIURL: "https://test.smartsciencetest.com/SmartScience-LMS/api/",
  //ASSETURL: "https://test.smartsciencetest.com:8443/smartscience/assets/LESCODE/",
  //AUTH1: "https://clever.com/oauth/authorize?response_type=code&client_id=2c612b37139d9decb7a7&redirect_uri=https%3A%2F%2Ftest.smartsciencetest.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&scope=read%3Aall%20write%3Aall",
  //AUTH2: "https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Ftest.smartsciencetest.com%2FSmartScience-LMS%2Fapi%2Fclasslink%2Fredirect&client_id=c1690257137522c184ab0b736dfaa2edecd5dc4c5d28&response_type=code",
//};
// const Config = {
//   LOGINURL: "https://test.smartsciencetest.com/SmartScience-LMS/login",
//   APIURL: "https://test.smartsciencetest.com/SmartScience-LMS/api/",
//   ASSETURL: "https://test.smartsciencetest.com:8443/smartscience/assets/LESCODE/",
//   AUTH1: "https://clever.com/oauth/authorize?response_type=code&client_id=2c612b37139d9decb7a7&redirect_uri=https%3A%2F%2Ftest.smartsciencetest.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&scope=read%3Aall%20write%3Aall",
//   AUTH2: "https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Ftest.smartsciencetest.com%2FSmartScience-LMS%2Fapi%2Fclasslink%2Fredirect&client_id=c1690257137522c184ab0b736dfaa2edecd5dc4c5d28&response_type=code",
// };

// local
const Config = {
  LOGINURL: "/SmartScience-LMS/login",
  APIURL: "/SmartScience-LMS/api/",
  ASSETURL: "https://test.smartsciencetest.com:8443/smartscience/assets/LESCODE/",
  AUTH1: "https://clever.com/oauth/authorize?response_type=code&client_id=2c612b37139d9decb7a7&redirect_uri=https%3A%2F%2Fsmartsciencelab.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&scope=read%3Aall%20write%3Aall",
  AUTH2: "https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Fsmartsciencelab.com%2FSmartScience-LMS%2Fapi%2Fclasslink%2Fredirect&client_id=c1693538733497102bea03fbd3a38523bccdf70cf1d2&response_type=code",
};

/*
********* ⚠️ Linux demo smartScience *********
*/
//const Config = {
  //LOGINURL: "https://test.smartsciencedemo.com/SmartScience-LMS/login",
  //APIURL: "https://test.smartsciencedemo.com/SmartScience-LMS/api/",
  //ASSETURL: "https://test.smartsciencedemo.com:8443/smartscience/assets/LESCODE/",
  //AUTH1: "https://clever.com/oauth/authorize?response_type=code&client_id=2c612b37139d9decb7a7&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&scope=read%3Aall%20write%3Aall",
  //AUTH2: "https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Ftest.smartsciencedemo.com%2FSmartScience-LMS%2Fapi%2Fclasslink%2Fredirect&client_id=c1687959969354f6aae4f53ac0e0f926a9023bb54b09&response_type=code",
//};


/*
********* 🔥 live Server ********* 
*/
// const Config = {
//   LOGINURL: "https://smartsciencelab.com/SmartScience-LMS/login",
//   APIURL: "https://smartsciencelab.com/SmartScience-LMS/api/",
//   ASSETURL: "https://smartsciencelab.com:8443/smartscience/assets/",
//   AUTH1: "https://clever.com/oauth/authorize?response_type=code&client_id=2c612b37139d9decb7a7&redirect_uri=https%3A%2F%2Fsmartsciencelab.com%2FSmartScience-LMS%2Foauth%2Fcallbacks&scope=read%3Aall%20write%3Aall",
//   AUTH2: "https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Fsmartsciencelab.com%2FSmartScience-LMS%2Fapi%2Fclasslink%2Fredirect&client_id=c1693538733497102bea03fbd3a38523bccdf70cf1d2&response_type=code",
// };

export default Config;
