import moment from 'moment';

export default function validate(obj) {
  let errors = {};
  let values = {...obj};
  // let moreBlank =/^(\w+\s)*\w+$/g;
  // let moreBlank =/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/g ;
  let moreBlank =/\s{2,}/g ;
  let specialChar = /^((?![-_!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+).)*$/gm;

  // let moreBlank =/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9_\w.+][^ ...]+$/g ;
  // let moreBlank =/^([\w+]\s)*\w+$/g ;
  
  
  Object.keys(values).map(k => values[k] = trimObjectStrings(k));

  if (!values.curriculamId || values.curriculamId < 0) {
    errors.curriculamId = 'Curriculum is required';
  }

  if (!values.accountId || values.accountId < 0) {
    errors.accountId = 'Account is required';
  }

  if (!values.organizationId || values.organizationId < 0) {
    errors.organizationId = 'School is required';
  }

  if (!values.teacherId || values.teacherId < 0) {
    errors.teacherId = 'Teacher is required';
  }

  if (!values.name || !values.name.length) {
    errors.name = 'Class Name/Number is required';    
  } else if (values.name.length > 100) {
    errors.name = 'Class Name/Number must be less than 100 characters';
  }

  if (!values.classCode || !values.classCode.length) {
    errors.classCode = 'Class Code is required';    
  } else if (values.classCode.length > 50) {
    errors.classCode = 'Class Code must be less than 50 characters';
  }

  if(values.description !== undefined){
    if (values.description.length > 250) {
      errors.description = 'Description must be less than 250 characters';
    }
  }

  if (!values.classLevelId || values.classLevelId < 0) {
    errors.classLevelId = 'Class Level is required';
  }


  // if (isNaN(values.quizGrade)) {
  //   errors.quizGrade = 'Quiz grade is required';
  // } else if (isNaN(values.quizGrade)) {
  //   errors.quizGrade = 'Quiz grade is invalid';
  // } else if (values.quizGrade < 0) {
  //   errors.quizGrade = 'Quiz Grade cannot have minus values';
  // } else if (values.quizGrade > 100) {
  //   errors.quizGrade = 'Quiz Grade must be less than 100';
  // }

  if (values.curveFit < 0) {
    errors.curveFit = 'Curve fit is required';
  }

  if (!values.startDate) {
    errors.startDate = 'Start Date is required';
  } else if (!moment(values.startDate).isValid()) {
    errors.startDate = 'Invalid start date';
  }

  if (!values.endDate) {
    errors.endDate = 'End Date is required';
  } else if (!moment(values.endDate).isValid()) {
    errors.endDate = 'Invalid end date';
  } else if ((moment(values.startDate)).diff(moment(values.endDate), 'days') >= 0) {
    errors.endDate = 'End Date must be greater than Start Date';
  }  

  function trimObjectStrings(key){
    if(typeof values[key] == 'string' && !key.toLowerCase().includes("password")){
      return values[key].trim();
    }
    return values[key];
  }

  return errors;
};