// -- React and related libs
import React, { useState,useEffect } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router";

// -- Third Party Libs
import PropTypes from "prop-types";
import {
  Navbar,
  TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col
} from "reactstrap";

// -- Custom Components
import LabHeader from "../Header/LabHeader";
import Sidebar from "../Sidebar/Sidebar";
import LeftSidebar from "../Sidebar/LeftSidebar";
import Footer from "../Footer/Footer";
import Dashboard from "../../pages/dashboard/Dashboard";
import LessonLab from "../../pages/LessonLab/index";
import jsonToken from 'jsonwebtoken'

// -- Component Styles
import s from "./Layout.module.scss";
import Cookies from "js-cookie";

const Layout = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const url = (window.location.href).split("#");
  const [count,setCount] = useState(0)
  const navigateFunction1 = () =>{
    const url = (window.location.href).split("#");
    // Cookies.remove('googtrans')
    window.open(`${url[0]}#/home`,"_self");
    // localStorage.setItem('targetLanguage', "en");
    document.location.reload(true);
  }

  const navigateFunction2 = () =>{
    const labId =  window.localStorage.getItem('lessonID');
    // Cookies.remove('googtrans')
    window.open(`${url[0]}#/Lesson/${labId}`,"_self");
    // localStorage.setItem('targetLanguage', "en");
    document.location.reload(true);
  }
  useEffect(() => {
    
    document.body.classList.remove('admin-nav');
  }, );
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  return (
    <div className={s.root}>

        <Sidebar notificationCount={(data) =>{
        setCount(data)
      }} />
       
      <div className={s.wrap}>
        <LabHeader role={accessToken.roles[0]} count ={count} />
        <div className="home-icon"><i class="fa fa-home " onClick={navigateFunction1}  aria-hidden="true"></i></div>
        

        <div className="back-icon"><i class="fa fa-chevron-left fa-2x  "  onClick={() =>{navigateFunction2()}}  aria-hidden="true"></i></div>
        
          <div className="">
         
          <Switch>
            <Route path="/" exact render={() => <Redirect to="/dashboard"/>} />
            <Route path="/dashboard" exact component={Dashboard}/>
            <Route path="/LessonLab/:engageStepId/:evaluateStepId/:explainStepId/:exploreStepId/:extendStepId" exact component={LessonLab} />
           
          </Switch>
          </div>
      
        
      </div>

    </div>
  );
}





export default Layout;
