import { useState, useEffect } from "react";
import { Spin, Input, Table, Select, Drawer } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import { Row, Col } from "reactstrap";
import Edit from "./edit";
import AccountAdminService from "../../../../api/AccountAdminService"

function AdminView(props) {

  const [size, setSize] = useState();
  const [visible, setVisible] = useState(false);
  const [editKey, setEditKay] = useState(1);

  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [accountId, setAccountId] = useState(null);

  const [selectedEdit, setSelectedEdit] = useState(null);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermText, setSearchTermText] = useState("");

  const columns = [
    {
      title: "Account Admin Name",
      dataIndex: "firstName",
      key: "firstName",
      className: "fixed-size",
      render: (value, row, index) => {
        return row.firstName + " " + row.lastName;
      }
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Action",
      render: (text, record) => <span className="btn btn-sm btn-info" onClick={(e) => { showEditDrawer(record); }}>Edit</span>,
      className: "action"
    },
  ];

  useEffect(() => {
    if (props.activeTab == props.tabId) {
      loadAccounts()
      if (accountId && accountId > 0) {
        loadAdmins()
      }
    }
  }, [props.activeTab])

  useEffect(() => {
    loadAdmins()
  }, [accountId]);

  function loadAccounts() {
    setLoading(true);
    AccountAdminService.getAccountsForUser()
      .then((response) => {
        if (response?.data?.accountList) {
          setAccounts(response.data.accountList);
        }
        if (response?.data?.accountList?.length == 1) {
          setAccountId(response.data.accountList[0].id)
        }
      }).catch((err) => {
        console.log(err.message);
      }).finally(() => {
        setLoading(false);
      });
  }

  function loadAdmins(page = currentPage, pageSize = defaultPageSize, search = searchTerm) {
    setAdmins([]);
    setTableData([]);
    // if (!accountId || accountId <= 0) {
    //   return;
    // }
    setLoading(true);
    AccountAdminService.getAdminsByAccountId(accountId, page, pageSize, search)
      .then((response) => {
        if (response?.data) {
          setAdmins(response.data.items);
          setTableData(response.data.items);
          setTotal(response.data.totalItems);
          setCurrentPage(response.data.page);
        }
        else{
          setLoading(false)
        }
      }).catch((err) => {
        console.log("Error ", err);
      }).finally(() => {
        setLoading(false);
      });
  }

  function onSearchByName(e) {
    setCurrentPage(1);
    const currValue = e.target.value;
    setSearchTerm(currValue);
    setCurrentPage(1);
    loadAdmins(1, defaultPageSize, currValue)
    setSearchTermText(currValue)
  }

  const onClose = () => {
    setSelectedEdit(null);
    loadAdmins()
    setVisible(false);
    setEditKay(prev => prev + 1);
  };

  const showEditDrawer = (record) => {
    setSelectedEdit(record);
    setSize("large");
    setVisible(true);
  };

  const onAccountChange = (value) => {
    setSearchTermText("");
    setSearchTerm("");
    setCurrentPage(1);
    setAccountId(value);
  };

  return (
    <>
      <div>
          <div className="my-5">
            <Row>
              <Col md="2">

              </Col>
              <Col md="4">
                <Select
                getPopupContainer={trigger => trigger.parentElement}
                  showSearch
                  placeholder="Search by Account"
                  optionFilterProp="children"
                  size="large"
                  className="search-drop"
                  onChange={onAccountChange}
                  filterOption={(input, option) =>

                    option.children.toLowerCase().includes(input.toLowerCase())


                  }
                >
                  {
                    accounts.length > 0 && (
                      <>
                        <option key="-1" value="-1">Please Select an Account</option>
                        {
                          accounts.filter(item => item.name !== null).map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                        }
                      </>
                    )
                  }
                </Select>
              </Col>

              <Col md="4">
                <Input
                  size="large"
                  placeholder="Search"
                  prefix={<SearchOutlined className="ml-3 mr-3" />}
                  onChange={onSearchByName}
                  value={searchTermText}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col md="12">
              <Table
                loading={loading}
                dataSource={tableData}
                //pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                pagination={{
                  current: currentPage,
                  position: ["bottomCenter"],
                  total: total, // Total number of items in the API response (you may need to fetch this dynamically)
                  pageSize: defaultPageSize, // Number of items to display per page
                  onChange: (page, pageSize) => loadAdmins(page, pageSize), // Callback function to handle page changes
                  showSizeChanger: false
                }}
                columns={columns}
                className="thead-dark admin-table"
              />
            </Col>
          </Row>
          <Drawer
            className="medium"
            title={`Edit Account Admin`}
            placement="right"
            size={size}
            onClose={onClose}
            visible={visible}
          >
            <Edit key={editKey} selectedName={selectedEdit?.name} onClose={onClose} selectedId={selectedEdit?.id} />
          </Drawer>
      </div>
    </>
  );
}

export default AdminView;
