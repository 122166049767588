import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Tabs, Input, Table, Space, Drawer, Spin } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  Row,
  Col,
  CardBody,
  Button,
} from "reactstrap";

import { GetAccountDataPaged } from "../../../../api/AccountService.js"
import Edit from "./Edit.js";
import { addaccount, getAccoutList } from "../../../../reducers/accountSlice.js";
import { useDispatch, useSelector } from "react-redux";
import jsonToken from 'jsonwebtoken'



function AccountCreate(props) {

  const columns = [
    {
      title: "Account Name",
      dataIndex: "name",
      key: "name",
      className: "fixed-size"
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      render: (text, record) => <span className="btn btn-sm btn-info" onClick={() => showDrawer(record)}>Edit</span>,
      className: "action"
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAccountEdit, setSelectedAccountEdit] = useState();
  const [accountData, setAccountData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [isopen, setIsOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const [size, setSize] = useState();
  const dispatch = useDispatch();

  useEffect(()=>{
    // if(!isopen){
      Allaccount();
    // }
  },[isopen])

  

  const Allaccount = (page = currentPage, pageSize = defaultPageSize, search = searchTerm) => {
    setLoading(true);
    setTotal(0);
    GetAccountDataPaged(page, pageSize, search).then(res => {
      setAccountData(res.data?.items);
      setTotal(res.data.totalItems);
      setCurrentPage(res.data.page);
      setLoading(false);

    }).catch(err => {
      setAccountData([])
      setLoading(false);
    })
  }

  // console.log("accountData ", accountData)
  // console.log("selectedAccountEdit ", selectedAccountEdit)


  const showDrawer = (record) => {
    setSelectedAccountEdit(record);
    setIsOpen(true);
    setSize("large");
    let payload = {
      id: record.id,
      name: record.name,
      description: record.description,
      // organizations: record.organizations,
    }
    //dispatch(addaccount(payload))

  };
  const onClose = () => {
    setIsOpen(false);
  };

  const searchTable = (searchtext) => {
    setSearchTerm(searchtext)
    setCurrentPage(1);
    Allaccount(1, defaultPageSize, searchtext);
  }

  // console.log("addaccount slise", addaccount)

  return (
    <>
      <div>
          <Row>
            <Col md="3"></Col>
            <Col lg="6" sm='12'>
              <CardBody className="my-3">
                <Input
                  size="large"
                  placeholder="Search"
                  onChange={(e) => searchTable(e.target.value)}
                  prefix={<SearchOutlined className="mx-3" />}
                />
              </CardBody>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Table
                dataSource={accountData}
                //pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                pagination={{
                  current: currentPage,
                  position: ["bottomCenter"],
                  total: total, // Total number of items in the API response (you may need to fetch this dynamically)
                  pageSize: defaultPageSize, // Number of items to display per page
                  onChange: (page, pageSize) => Allaccount(page, pageSize), // Callback function to handle page changes
                  showSizeChanger: false
                }}
                columns={columns}
                loading={!isopen && loading}
                className="thead-dark admin-table"
              />
            </Col>
          </Row>

          <Drawer
            className="medium"
            title={`Edit Account`}
            placement="right"
            size={size}
            destroyOnClose={true}
            onClose={onClose}
            visible={isopen}
          >
            <Edit
              onClose={onClose}
              selectedAccountOrg={selectedAccountEdit?.organizations}
              selectedAccountDes={selectedAccountEdit?.description}
              selectedAccountName={selectedAccountEdit?.name}
              selectedAccountId={selectedAccountEdit?.id}
              selectedStData={selectedAccountEdit}
              loader={loading}
            />
          </Drawer>
      </div>
    </>
  );
}

export default AccountCreate;
