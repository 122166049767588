
import { List } from 'antd'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap';



export default function SecondTypeQuestion(props) {
  const [value, setValue] = useState([]);

  function updateAnswer(questionId, newAnswer) {
    setValue(prevData => {
      const updatedData = prevData.map(item =>
        item.question_id === questionId ? { ...item, answer: newAnswer } : item
      );

      if (!updatedData.some(item => item.question_id === questionId)) {
        updatedData.push({ question_id: questionId, answer: newAnswer });
      }

      return updatedData;
    });
  }

  useEffect(()=>{
    setValue(props.containedPayload.length > 0 ? props.containedPayload : [] )
  },[props.containedPayload])

  const preChangeHandler = (event) =>{
    const name = event.target.name;
    const value = event.target.value;
    const count = parseInt(name) + 1;
    updateAnswer(count,value)
    props.changeHandler(event)

  }

  return (
    <> <div>
    <List
      dataSource={props.data}
      renderItem={(item, x) => (
        <div id={`${x}bodyDescription${props.from}`}>
        <List.Item key={x}>
          {item.image.length > 2 ? (
            <div className="w-100">
                  <Row>
                  <Col xs="6" className="pt-4">
                    <div dangerouslySetInnerHTML={{ __html: item?.description }}></div>

                  </Col>
                  <Col xs="6" className="pt-4">
                  <img src={item?.image} alt="" />
                  </Col>
                </Row>
            <Row>
              <Col xs="12" className="pt-4">        
              <textarea
                  className="form-control tarea"
                  defaultValue={ value
                    .filter(obj => obj.question_id === item.id)
                    .map(obj => obj.answer)}       
                      onChange={preChangeHandler}
                  rows={8}
                  id="textarea"
                name={item.id-1}
                disabled={true}
                ></textarea>
            </Col>    </Row>
          </div>
          ) : (
          <div className="col-md-12">
              <div dangerouslySetInnerHTML={{ __html: item?.description }}></div>
              <Row>
                <Col xs="12">        
                <textarea
                    className="form-control tarea mb-3"
                    value={value
                      .filter(obj => obj.question_id === item.id)
                      .map(obj => obj.answer)}       
                    onChange={preChangeHandler}
                    rows={8}
                    id="textarea"
                  name={item.id-1}
                  disabled={true}
                  ></textarea>
              </Col>    </Row>
            </div>
          )}
       
          
        </List.Item>
        </div> 
      )}
    />

    
  </div>
    </>
  )
}
