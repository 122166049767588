// -- React and related libs
// import ReactDOM, { createRoot }  from 'react-dom/client';
// import { render } from 'react-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider as ConfigProvider5 } from 'antd-v5';

// -- Redux
// import { createStore, applyMiddleware } from "redux";
// import ReduxThunk from "redux-thunk";
// import reducers from './reducers';
import { Provider } from 'react-redux';
import { legacy_createStore as createStore } from 'redux'
import rootReducer from './reducers';

// -- App
import App from './App';
import '../src/styles/app.scss'
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { store } from './reducers/store'

// -- Data Store
// const store = createStore(
//   reducers,
//   applyMiddleware(ReduxThunk)
// );

// const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const root = ReactDOM.createRoot(document.getElementById('root'));


// -- Rendering Application
root.render(
  <Provider store={store}>
    <ConfigProvider5 prefixCls="ant5">
      <App />
    </ConfigProvider5>
  </Provider>,
);



