import { Modal } from "antd";

 /*modals*/
 class outputModals  {
    success = (data) => {
        Modal.success({
          content: data,
        });
        
      };
     error = (data) => {
        Modal.error({
          title: 'Error',
          content: data,
        });
      };




 
 }
 export default new outputModals
   /*modals*/
