import React from 'react'

import { CardBody, Badge } from "reactstrap";
import { Input, Table, Col, Row, Space, Button, Select } from "antd";
import "antd/dist/antd.css";

import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import RestService from '../../../api/RestService';

const dataSource = [
  {
    key: "1",
    org_name: "NYC",
    org_id:"",
    instructor:"John Doe",
    class :"Class 01",
    student:"Student 01",
    lesson:"Lesson 01",
    avg_intro:"n/a",
    avg_quiz:"n/a",
    avg_rpt:"n/a",
    avg_ext:"n/a",
    action:""
  },
];
const columns = [
  {
    title: "Organization Name",
    dataIndex: "org_name",
    key: "org_name",
  },
  {
    title: "Instructor",
    dataIndex: "instructor",
    key: "instructor",
    render: (_, record) => (
      
      record.instructor
      
    ),
  },
  {
    title: "Class",
    dataIndex: "class",
    key: "class",
  },
  {
    title: "Student",
    dataIndex: "student",
    key: "student",
  },
  {
    title: "Lesson",
    dataIndex: "lesson",
    key: "lesson",
  },
  {
    title: " Avg Engage",
    dataIndex: "avg_intro",
    key: "avg_intro",
  },
  {
    title: "Avg Evaluate",
    dataIndex: "avg_quiz",
    key: "avg_quiz",
  },
  {
    title: "Avg Explain",
    dataIndex: "avg_rpt",
    key: "avg_rpt",
  },
  {
    title: "Avg Extend",
    dataIndex: "avg_ext",
    key: "avg_ext",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (text, record) => 
      <a href={'#/evaluate-student/'+record?.org_id} className="btn btn-sm btn-primary"  >View</a>
    ,
    fixed:"right"
  },
];

function EvaluateTeacher() {

  const [tableData,setTableData] = useState([

  ])



  useEffect(() =>{
    RestService.getTeacherEvaluateData().then(res =>{
      console.log("Eval",res)
      if(res.data?.length > 0){

        setTableData(res.data?.map((x,i) =>{
          return {
        
            key: i,
            org_name: x.organizationName ,
            org_id:x.organizationId,
            instructor:x.instructorsCount ,
            class :x.classesCount,
            student:x.studentsCount ,
            lesson:x.lessonsCount,
            avg_intro:x.engageAvg.toFixed(2),
            avg_quiz:x.evaluateAvg.toFixed(2),
            avg_rpt:x.explainAvg.toFixed(2),
            avg_ext:x.extendAvg.toFixed(2)
          }
        }))
       
      }
      else{
        setTableData([{
        
          key: "1",
          org_name: res.data?.organizationName ,
          org_id:res.data?.organizationId,
          instructor:res.data?.instructorsCount ,
          class :res.data?.classesCount,
          student:res.data?.studentsCount ,
          lesson:res.data?.lessonsCount,
          avg_intro:res.data?.engageAvg.toFixed(2),
          avg_quiz:res.data?.evaluateAvg.toFixed(2),
          avg_rpt:res.data?.explainAvg.toFixed(2),
          avg_ext:res.data?.extendAvg.toFixed(2)
        }])
      }
      
    })
  },[])

  return (
    <>
      <div className="container-fluid">

        <Row>
          <Col span={12} offset={6}>
            <center>
              <h3>Organization</h3>
            </center>
          </Col>
        </Row>
        {/* <Row>
          <Col span={8} offset={8}>
            <CardBody className="ml-3 mt-3 mb-3">
            <Select
                    showSearch
                    placeholder="Select Instructor"
                    optionFilterProp="children"
                    size="large"
                    className="search-drop" >
                  </Select>
            </CardBody>
          </Col>
        </Row> */}
        <br/>
        <Row>
          <Col span={24}>
          <Table
              dataSource={tableData}
              pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']  }}
              columns={columns}
              className="thead-dark admin-table"
            />
          </Col>
        </Row>

      </div>
    </>
  )
}

export default EvaluateTeacher