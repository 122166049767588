import { useEffect, useState } from "react";
import { Tabs, Input, Table, Select, Tag, Drawer, Spin } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import { Card, CardText, CardImg, Row, Col, CardBody, Button } from "reactstrap";
import Create from "./Create"
import { useDispatch, useSelector } from "react-redux";
import { getSchoolData, getTeacherListPaged } from "../../../../api/TeacherService";
import { getTeachersPaged, addAccountVal } from "../../../../reducers/teacherSlice";
import Edit from "./Edit";
import { GetAccountData } from "../../../../api/AccountService";
import jsonToken from 'jsonwebtoken'
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";
const { Option } = Select;



function TeacherView(props) {

  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));

  const columns = [
    {
      title: "Teacher Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (value, row, index) => {
        return row.firstName + " " + row.lastName;
      }
    },
    {
      title: "School",
      dataIndex: "organizationName",
      key: "organizationName",
    },
    {
      title: "Classes",
      dataIndex: "className",
      key: "className",
      render: (value, row, index) => {
        return row.className.map((item, index, array) => (
          <>
            {item.className}
            {index !== array.length - 1 && <span>,&nbsp;&nbsp;</span>}
          </>
        ));
      }
    },

    {
      title: "Action",
      render: (text, record) => <span className="btn btn-sm btn-info" onClick={() => { showDrawer2(record) }}>Edit</span>,
      className: "action"
    }
  ];

  const [schoolData, setSchoolData] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [accountData, setAccountData] = useState([]);

  const [selectedTeacher, setSelectedTeacher] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [loading, setLoading] = useState(false);

  const [visibleDrawer2, setVisibleDrawer2] = useState(false);
  const [size, setSize] = useState();

  const [accVal, setAccVal] = useState();
  const [schoolVal, setSchoolVal] = useState();

  const [disableSchool, setDisableSchool] = useState(false);

  const dispatch = useDispatch()

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [searchTeacherText, setSearchTeacherText] = useState("");

  useEffect(() => {
    const accountData = async () => {
      setLoading(true)

      const response = await GetAccountData();
      const data = await response?.data;
      setAccountData(data?.accountList);

      setLoading(false);
      setSchoolData();
    }
    accountData();
  }, [])

  useEffect(() => {
    const schoolList = async () => {
      if (accVal !== undefined || accVal !== 0) {
        setLoading(true)
        const response = await getSchoolData(accVal);
        if(response !== undefined){
        const data = await response?.data;
        setSchoolData(data);
        setLoading(false)
      }

      } else {
        console.log("error")
      }
    }
    schoolList();
  }, [accVal])

  useEffect(() => {
    if(schoolVal && schoolVal !== undefined){
      getTeachersFil()
    }
    else{
      setTeachers([]);
    }
  }, [schoolVal, visibleDrawer2])

  const getTeachersFil = async (page = currentPage, pageSize = defaultPageSize, search = searchTerm) => {
    if (schoolVal !== 0 || schoolVal !== undefined) {
      setLoading(true)
      try{
        const response = await getTeacherListPaged(schoolVal, page, pageSize, search);
        if(response?.data){
          const data = await response?.data.items;
          setTeachers(await data);
          setTotal(await response?.data.totalItems);
          setCurrentPage(await response?.data.page);
          setLoading(false)
        }
      }catch{  
        setLoading(false)
      }
    } else {
      setLoading(false)
      console.log("error")
    }
  }

  useEffect(() => {
    if (accessToken.roles[0] === "SCHOOL_ADMIN" || accessToken.roles[0] === "ACCOUNT_ADMIN") {
      if (accountData && accountData.length === 1) {
        setAccVal(accountData[0].id);
      }
    }
  }, [accountData])

  useEffect(() => {
    if (accessToken.roles[0] === "SCHOOL_ADMIN") {
      if (schoolData && schoolData.length === 1) {
        setSchoolVal(schoolData[0].id)
        setDisableSchool(true);
      }
    }
  }, [schoolData])

  const onChangeAccount = (value) => {
    setCurrentPage(1);
    setSchoolVal();
    setAccVal(value);
    setSearchTeacherText("");
    setTotal(0)
    dispatch(addAccountVal(value))
  }

  const onChangeSchool = (value) => {
    setCurrentPage(1);
    setSchoolVal(value);
    dispatch(getTeachersPaged({ value, currentPage, defaultPageSize }))
    setSearchTerm("");
    setSearchTeacherText("");
  }

  const showDrawer2 = (value) => {
    setSelectedTeacher(value)
    setVisibleDrawer2(true);
  };
  const onCloseDrawer2 = () => {
    setVisibleDrawer2(false);
  }

  const searchTable = (searchtext) => {
    setCurrentPage(1);
    setSearchTerm(searchtext);
    setSearchTeacherText(searchtext)
    setCurrentPage(1);
    getTeachersFil(1, defaultPageSize, searchtext);
  }

  return (
    <>
      <div>
          <div className="my-5">
            <Row>
              <Col md="4">
                <Select
                  showSearch
                  placeholder="Search by Account"
                  optionFilterProp="children"
                  size="large"
                  className="search-drop"
                  onChange={onChangeAccount}
                  value={accVal}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={disableEnableDropdown("Account", props.user_roll)}
                >
                  <option key="-1" value="-1">Please Select an Account</option>
                  {
                    accountData ?
                      accountData.filter(item => item.name !== null).map((obj, index) => {
                        return (
                          <Option key={index.id} value={obj.id}>{obj.name}</Option>
                        )
                      })
                      : "No Accounts"
                  }
                </Select>
              </Col>
              <Col md="4">
                <Select
                  showSearch
                  placeholder="Search by School"
                  optionFilterProp="children"
                  size="large"
                  className="search-drop"
                  onChange={onChangeSchool}
                  value={schoolVal !== "-1" ? schoolVal : null}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={disableEnableDropdown("School", props.user_roll)}
                >
                  {accVal !== undefined && accVal !== null && accVal > 0 ?
                    <option selected key="-1" value="-1">Please Select a School</option>
                    : <option key="-1" value="-1">Select an Account First</option>}
                  {
                    schoolData && schoolData !== undefined ?
                      schoolData.map((obj, index) => {
                        return (
                          <Option key={index.id} value={obj.id}>{obj.name}</Option>
                        )
                      })
                      : "No Accounts"
                  }
                </Select>
              </Col>
              <Col md="4">
                <Input
                  size="large"
                  placeholder="Search"
                  onChange={(e) => searchTable(e.target.value)}
                  prefix={<SearchOutlined className="ml-3 mr-3" />}
                  value={searchTeacherText}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col md="12">
              <Table
                dataSource={teachers}
                loading={loading}
                //pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                pagination={{
                  current: currentPage,
                  position: ["bottomCenter"],
                  total: total, // Total number of items in the API response (you may need to fetch this dynamically)
                  pageSize: defaultPageSize, // Number of items to display per page
                  onChange: (page, pageSize) => getTeachersFil(page, pageSize), // Callback function to handle page changes
                  showSizeChanger: false
                }}
                columns={columns}
                className="thead-dark admin-table"
              />
            </Col>
          </Row>
        <Drawer
          className="medium"
          title={`Edit Teacher`}
          placement="right"
          size={size}
          destroyOnClose={true}
          onClose={onCloseDrawer2}
          visible={visibleDrawer2}
        >
          <Edit
            accval={accVal}
            schoolVal={schoolVal}
            selectedName={selectedTeacher?.name}
            onClose={onCloseDrawer2}
            selectedId={selectedTeacher?.id}
            selectData={selectedTeacher}
            user_roll={props.user_roll}
          />
        </Drawer>
      </div>
    </>
  );
}

export default TeacherView;
