import Config from "./Config";

export const LoginPost = (body) => {
    var formBody = [];
    for (var property in body) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return new Promise((resolve, reject) => {
        let request = new XMLHttpRequest();
        request.open("POST", Config.LOGINURL);

        request.setRequestHeader("Content-type", "application/x-www-form-urlencoded; charset=utf-8");
        request.setRequestHeader('Access-Control-Allow-Methods', '*');
        request.setRequestHeader('Accept-Language', 'en-US');
        request.onload = () => {
            if (request.response === 'Token Time Exceed') {
                //window.logout.logout();
            }
            else {

                if(request.status >=400){
                    const res = JSON.parse(request.response);
                    reject({
                        status:request.status,
                        message:res.message
                    });
                }
                else if (request.status >= 200 && request.status < 300) {
                    resolve(JSON.parse(request.response));
                }                 
                else {
                    reject({
                        status:request.status,
                        message:request.statusText
                    });
                }
            }
        };
        request.onerror = () => {
            reject("Server error");
        };
        request.send(formBody);
    });
};