import { useState, useEffect } from "react";
import { Card, CardText, FormFeedback, Row, Col, Input, Button, FormGroup } from "reactstrap";
import { Alert, notification, Spin, Select } from 'antd';
import { updateTeacher2, getTeacherbyId } from "../../../../api/TeacherService";
import jsonToken from 'jsonwebtoken'
import useCustFormValidator from "../../../../hooks/useCustFormValidator";
import validate from './editFormValidaiton';
import StudentService from "../../../../api/StudentServiceNew"
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";

function TeacherEdit(props) {
    var obj = {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        username: "",
        organization_id: -1,
        confirmPassword: '',
        status: 1,
        roleId: 2,
        staffId: "",
        accountId: props.accval
    }
    const [loading, setLoading] = useState(false);
    const [edited, setEdited] = useState(false);
    const [disabled_school, setDisabledSchool] = useState(false);
    const [uneditedObj, setUneditedObj] = useState(obj);
    const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });

    const [accountId, setAccountId] = useState(null);

    const [accounts, setAccounts] = useState([]);
    const [schools, setSchools] = useState([]);

    const [showPass, setShowPass] = useState(false);
    const [accountChange, setAccountChange] = useState(false)

    const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(onSave, validate, { obj });

    let accessToken = jsonToken.decode(localStorage.getItem("access_token"));

    useEffect(() => {
        loadAccounts();
        loadTeacher();
    }, []);

    useEffect(() => {
        loadSchools(accountId)
    }, [accountId]);

    useEffect(() => {
        setAccountId(props.accval)
    }, [props.accval]);

    useEffect(() => {
        setEdited(JSON.stringify(values) != JSON.stringify(uneditedObj))
        if (values.accountId != -1) {
            setDisabledSchool(false);
        } else {
            setDisabledSchool(true);
        }
    }, [values]);

    useEffect(() => {
        if (accessToken.roles[0] !== "SUPER_ADMIN") {
            if (accounts.length === 0) {
                setDisabledSchool(true)
            }
        }
    }, [props?.selectedId])

    useEffect(() => {
        if (accessToken.roles[0] === "SCHOOL_ADMIN" || accessToken.roles[0] === "ACCOUNT_ADMIN") {
            if (accounts.length === 1) {
                setAccountId(accounts[0].id)
                setFormData({ ...values, accountId: accounts[0].id })
            }
        }
    }, [accounts])

    useEffect(() => {
        if (accessToken.roles[0] === "SCHOOL_ADMIN") {
            if (schools.length === 1) {
                setFormData({ ...values, organization_id: schools[0].id })
            }
        }
    }, [schools])

    function loadAccounts() {
        setLoading(true);
        StudentService.getAccountsForUser()
            .then((response) => {
                if (response?.data?.accountList) {
                    setAccounts(response.data.accountList);
                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    function loadSchools() {
        if (!accountId || accountId <= 0) {
            setSchools([]);
            return;
        }
        setLoading(true);
        StudentService.getSchoolsForAccount(accountId)
            .then((response) => {
                if (response?.data) {
                    setSchools(response.data);

                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    function loadTeacher() {
        if (!props.selectedId || props.selectedId <= 0) {
            return
        }
        setLoading(true);
        getTeacherbyId(props.selectedId)
            .then((response) => {
                if (response?.data) {
                    var data = response.data;
                    data.password = '';
                    data.confirmPassword = '';
                    //data.accountId = props.accval;
                    setUneditedObj(data);
                    setFormData(data);
                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    function onAccountChange(value) {
        setAccountChange(true)
        setFormData({ ...values, accountId: value, organization_id: -1 });
        setAccountId(value);
    }

    function onSchoolChange(value) {
        setFormData({ ...values, organization_id: value })
    }

    function onSave() {
        setLoading(true);
        delete values.confirmPassword;
        console.log(values)
        let submitPayload = {
            id: props.selectedId,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            username: values.username,
            organization_id: values.organization_id,
            confirmPassword: values.confirmPassword,
            status: 1,
            roleId: 2,
            staffId: values.staffId,
            accountId: values.accountId

        }
        updateTeacher2(submitPayload)
            .then((response) => {
                if (response.status == 200) {
                    let messages = [{ type: 'success', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    loadTeacher();
                }
                else {
                    let messages = [{ type: 'error', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    loadTeacher();
                }

            }).catch((err) => {
                let messages = [{ type: 'error', text: err.message }]
                notification.open({
                    placement: 'top',
                    duration: "2",
                    description: [
                        messages.map((item) => {
                            if (item.type === 'success') {
                                return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'error') {
                                return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'warning') {
                                return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                        })
                    ],

                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });
            }).finally(() => {
                setLoading(false);
            });
    }

    function hideAlert() {
        var alert = { showAlert: false, message: "", type: "success" }
        setAlertData(alert);
    }

    function onShowPassChange(e) {
        if (!e.target.checked) {
            setFormData({ ...values, password: '', confirmPassword: '', showPass: false })
        } else {
            setFormData({ ...values, showPass: true })
        }
        setShowPass(e.target.checked)
    }
    function onEmailChange(e) {
        e.persist();
        let emailValue = e.target.value;
        if(emailValue.length == 0){
            setFormData({ ...values, email: null })
        }
        else{
            setFormData({ ...values, email: e.target.value })
        }
    }
    function onUserNameChange(e) {
        e.persist();
        setFormData({ ...values, username: e.target.value })
    }

    return (
        <>
            <div>
                <Spin tip="Loading..." spinning={loading} size="large">
                    <form autocomplete="off">
                        <Row>
                            <Col lg='12' className="py-2 text-center">
                                {alertData.showAlert ?
                                    <Alert message={alertData.message} type={alertData.type}
                                        action={
                                            <a type="text" onClick={hideAlert}>
                                                x
                                            </a>
                                        } /> : ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <div className="form-row">
                                    <div className="form-group col-lg-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>First Name
                                        </label>

                                        <Input
                                            type="text"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autocomplete="off"
                                            placeholder=""

                                            value={values.firstName}
                                            onChange={handleChange}
                                            name="firstName"
                                            invalid={!!errors?.firstName}
                                        />
                                        <FormFeedback>{errors?.firstName}</FormFeedback>
                                    </div>
                                    <div className="form-group col-lg-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>Last Name
                                        </label>
                                        <Input
                                            type="text"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autocomplete="off"
                                            placeholder=""
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            invalid={!!errors?.lastName}
                                        />
                                        <FormFeedback>{errors?.lastName}</FormFeedback>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-lg-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            Email
                                        </label>
                                        <input type="text" id="hiddenField" style={{display: "none"}} autoComplete="email" />
                                        <Input
                                            type="text"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            placeholder=""
                                            name="email"
                                            autoComplete="new_mail"
                                            value={values.email}
                                            onChange={(e) => { onEmailChange(e) }}
                                            invalid={!!errors?.email}
                                        />
                                        <FormFeedback>{errors?.email}</FormFeedback>
                                    </div>
                                    <div className="form-group col-lg-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>Username
                                        </label>
                                        <input type="text" id="hiddenField" style={{display: "none"}} autoComplete="username" />
                                        <Input
                                            type="text"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            placeholder=""
                                            name="userName"
                                            autoComplete="off"
                                            onChange={(e) => { onUserNameChange(e) }}
                                            value={values.username}
                                            invalid={!!errors?.username2}
                                            disabled
                                        />
                                        <FormFeedback>{errors?.username2}</FormFeedback>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-lg-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            Staff ID
                                        </label>
                                        <Input
                                            type="text"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autocomplete="off"
                                            placeholder=""
                                            name="staffId"
                                            onChange={handleChange}
                                            value={values.staffId}
                                            invalid={!!errors?.staffId}
                                        />
                                        <FormFeedback>{errors?.staffId}</FormFeedback>

                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>Account
                                        </label>
                                        <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                            showSearch
                                            placeholder="Select an Account"
                                            optionFilterProp="children"
                                            size="large"
                                            className="search-drop"
                                            value={values.accountId}
                                            disabled={disableEnableDropdown("Account", props.user_roll)}
                                            invalid={!!errors?.accountId}
                                            onChange={(value) => {
                                                onAccountChange(value);
                                            }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            <option key="-1" value="-1">Please Select an Account</option>
                                            {
                                                accounts.filter(item => item.name !== null).map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                            }
                                        </Select>
                                        <FormFeedback>{errors?.accountId}</FormFeedback>
                                    </div>
                                    <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>School
                                        </label>
                                        <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                            showSearch
                                            placeholder="Select a School"
                                            optionFilterProp="children"
                                            size="large"
                                            className="search-drop"
                                            value={values.organization_id == -1 ? "Please Select a School" : values.organization_id}
                                            invalid={!!errors?.organization_id}
                                            disabled={schools.length === 0}
                                            onChange={(value) => {
                                                onSchoolChange(value);
                                            }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            <option key="-1" value="-1">Please Select a School</option>
                                            
                                            {
                                                schools?.length > 0 && schools.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                            }
                                        </Select>
                                        <FormFeedback>{errors?.organization_id}</FormFeedback>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <Input type="password" hidden={true} />
                                    <div className="form-group col-md-6 col-sm-12">
                                        <FormGroup check inline >
                                            <Input type="checkbox"
                                                checked={values
                                                    .showPass}
                                                onChange={onShowPassChange}
                                                className="checked-margin"
                                            />
                                            <label check className="pt-6">
                                                Change Password
                                            </label>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="form-row" hidden={!values.showPass}>
                                    <div className="form-group col-md-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            Password
                                        </label>
                                        <input type="password" id="hiddenPasswordField" style={{display: "none"}} autoComplete="current-password" />
                                        <Input
                                            type="password"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autoComplete="off"
                                            placeholder=""
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            invalid={!!errors?.password}
                                        />
                                        <FormFeedback>{errors?.password}</FormFeedback>
                                    </div>
                                    <div className="form-group col-md-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            Confirm Password
                                        </label>
                                        <Input
                                            type="password"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autoComplete="off"
                                            placeholder=""
                                            name="confirmPassword"
                                            value={values.confirmPassword}
                                            onChange={handleChange}
                                            invalid={!!errors?.confirmPassword}
                                        />
                                        <FormFeedback>{errors?.confirmPassword}</FormFeedback>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='12'>
                                <div className="center mt-4">
                                    <Button color="warning" className="mr-15" onClick={props.onClose}>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleSubmit} color="success" disabled={!edited}>Save</Button>
                                </div>
                                <div className="center mt-4">
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Spin>
            </div>
        </>
    );
}

export default TeacherEdit;
