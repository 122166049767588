import { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from 'react-router-dom'

// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  Row,
  Col,
  CardImgOverlay,
} from "reactstrap";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import CardImgs from "../../../assets/images/CardImg.png"
import CardImgs2 from "../../../assets/images/CardImg2.png"


function Class(props) {
  const { TabPane } = Tabs;
  const onChange = (key) => {
    // console.log(key);
  };
  return (
    <>
    <div className="container">
      <div className="content">
       
        <Row>
          <Col md='3'>
          <Link to="/AdminLesson">
            <div className="course-card">
              <Card className="border-0 rounded-0  ">
                <CardImg
                  alt="Class Card"
                  src={CardImgs}
                  width="100%"
                />
                <div className="">
                  {/* default state layer start */}
                  <CardText className="overlayer-center w-80 overlayer-bg-dark overlayer-space-20 text-white layer-1">
                  <h4 className="text-white"> AP Physics</h4>
                  </CardText>
                  {/* default state end */}

                  {/* hover state layer start */}
                  <CardImgOverlay className="overlayer-center w-100 h-100 overlayer-bg-dark layer-2 ">
                    <CardText className="overlayer-center w-100 text-white">
                     <h4 className="text-white"> AP Physics</h4>
                      <hr className="hr-white" />
                      <small className="text-white">56 Lessons</small>
                    </CardText>
                  </CardImgOverlay>
                  {/* hover state layer end  */}
                </div>
              </Card>
            </div>
            </Link>
          </Col>
          <Col md='3'>
            <div className="course-card">
              <Card className="border-0 rounded-0  ">
                <CardImg
                  alt="Class Card"
                  src={CardImgs2}
                  width="100%"
                />
                <div className="">
                  {/* default state layer start */}
                  <CardText className="overlayer-center w-80 overlayer-bg-dark overlayer-space-20 text-white layer-1">
                  <h4 className="text-white"> Anatomy and Physiology</h4>
                  </CardText>
                  {/* default state end */}

                  {/* hover state layer start */}
                  <CardImgOverlay className="overlayer-center w-100 h-100 overlayer-bg-dark layer-2 ">
                    <CardText className="overlayer-center w-100 text-white">
                     <h4 className="text-white"> Anatomy and Physiology</h4>
                      <hr className="hr-white" />
                      <small className="text-white">56 Lessons</small>
                    </CardText>
                  </CardImgOverlay>
                  {/* hover state layer end  */}
                </div>
              </Card>
            </div>
          </Col>
          <Col md='3'>
            <div className="course-card">
              <Card className="border-0 rounded-0  ">
                <CardImg
                  alt="Class Card"
                  src={CardImgs2}
                  width="100%"
                />
                <div className="">
                  {/* default state layer start */}
                  <CardText className="overlayer-center w-80 overlayer-bg-dark overlayer-space-20 text-white layer-1">
                  <h4 className="text-white"> Anatomy and Physiology</h4>
                  </CardText>
                  {/* default state end */}

                  {/* hover state layer start */}
                  <CardImgOverlay className="overlayer-center w-100 h-100 overlayer-bg-dark layer-2 ">
                    <CardText className="overlayer-center w-100 text-white">
                     <h4 className="text-white"> Anatomy and Physiology</h4>
                      <hr className="hr-white" />
                      <small className="text-white">56 Lessons</small>
                    </CardText>
                  </CardImgOverlay>
                  {/* hover state layer end  */}
                </div>
              </Card>
            </div>
          </Col>
          <Col md='3'>
            <div className="course-card">
              <Card className="border-0 rounded-0  ">
                <CardImg
                  alt="Class Card"
                  src={CardImgs}
                  width="100%"
                />
                <div className="">
                  {/* default state layer start */}
                  <CardText className="overlayer-center w-80 overlayer-bg-dark overlayer-space-20 text-white layer-1">
                  <h4 className="text-white"> AP Physics</h4>
                  </CardText>
                  {/* default state end */}

                  {/* hover state layer start */}
                  <CardImgOverlay className="overlayer-center w-100 h-100 overlayer-bg-dark layer-2 ">
                    <CardText className="overlayer-center w-100 text-white">
                     <h4 className="text-white"> AP Physics</h4>
                      <hr className="hr-white" />
                      <small className="text-white">56 Lessons</small>
                    </CardText>
                  </CardImgOverlay>
                  {/* hover state layer end  */}
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
      </div>
    </>
  );
}

export default Class;
