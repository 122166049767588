import React, { useEffect, useState } from "react";
import { Carousel, Tooltip, message, notification } from "antd";
import { Row, Col, Table, Label } from "reactstrap";
import { Form, Input, Select, Drawer, Button, Checkbox } from "antd";

import { ArrowRightOutlined, ArrowLeftOutlined, CheckCircleOutlined } from "@ant-design/icons";
import CarosalImage1 from "../../../../assets/images/img1.jpg";
import ImgCom from "./ImgCom";
import { object } from "prop-types";
import SubExplorerImageCarosel from "./SubExplorerImageCarosel";
import SubExplorerImageCarosel2 from "./SubExplorerImageCarosel2";
import SubExplorerImageCarosel3 from "./SubExplorerImageCarosel3";
const contentStyle = {
  height: "400px",
  width: "540px",
};

const h3Style = {
  color: "#fff",
  textAlign: "center",
};
const previewStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  margin: "0 auto",
  marginTop: 32,
  maxWidth: 250,
};

const ExplorerImageCarosel = (props) => {
  const [selectedImg, setSelectedImg] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [disable, setDisabled] = useState(false);
  const [axisDisplay, setAxisDisplay] = useState([]);
  const [saveClick, setSaveClick] = useState(false);

  const [initialData, setInitialData] = useState([{}]);
  const [initialDataX, setInitialDataX] = useState(0);
  const [initialDataY, setInitialDataY] = useState(0);
  const [initialDataScaleValue, setInitialDataScaleValue] = useState(0);
  const [allInitialPoints, setAllInitialPoints] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);
  const [checkedStatus, setCheckedStatus] = useState(true);
  const [fourPoints, setFourPoints] = useState([]);
  const [BarPoints, setBarPoints] = useState([]);


  const changeHandler = (e) => {
    setInitialDataX(e.target.value)
    
    setDisabled(false);
    setSaveClick(false);
  }
  const changeHandler2 = (e) => {
    setInitialDataY(e.target.value)
    setDisabled(false);
    setSaveClick(false);
  }

  const changeHandler3 = (e) => {
    setInitialDataScaleValue(e.target.value)
    setDisabled(false);
    setSaveClick(false);
  }

  const getFunForQuestionAnswer = (value) => {
    setDisabled(false);
    setSaveClick(false);
    setFourPoints(value)
  }
  const getFunForBarAnswer = (value) => {
    setDisabled(false);
    setSaveClick(false);
    setBarPoints(value)
  }

  useEffect(() => {
    // setInitialData({
    //   x:initialData[currentSlide]?.x,
    //   y:initialData[currentSlide]?.y
    // })
    setAllInitialPoints((prev) => [
      ...prev,
      {
        [currentSlide]: { x: initialData?.x, y: initialData?.y },
      },
    ]);
  }, [currentSlide]);

  const onChange = (currentSlide) => {
    setCurrentSlide(currentSlide);
  };


  useEffect(() => {
    setAxisDisplay([]);
    setInitialDataX(props.initialdata2.initialDataX ?? 0)
    setInitialDataY(props.initialdata2.initialDataY ?? 0)
    setInitialDataScaleValue(props.initialdata2.initialDataScaleValue?? 0)
    setInitialData(props.initialdata);
    setCurrentSlide(0);
    setCheckedStatus(props.initialFourPointOptionValue)

    if (props.initialdata.length == 0) {
      setAxisDisplay([]);
    }
    setDisabled(props.disabled);
    if (props.imageUrls?.length < 1) {
      setSaveClick(props.imageUrls?.length < 1);
    } else if (props.imageUrls?.length >= 1) {
      setSaveClick(true);
    } else {
      setSaveClick(true);
    }
  }, [props]);


  const selectedField = (e) => {

    setDisabled(false);
    setSaveClick(false);
    setSelectedValue(e.target.value)
  }

  const ImgeComponent = props.imageUrls?.map((rowIndex, i, array) => {
    if (rowIndex === -1) {
      return (
        <React.Fragment>
          <div key={rowIndex} style={previewStyle}>
            {array.map(
              (colIndex) =>
                colIndex !== 0 && (
                  <h3 key={(rowIndex + 1) * (colIndex + 1)} style={h3Style}>
                    Preview: {colIndex}
                  </h3>
                )
            )}
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div style={contentStyle} className="canvas-wrap">
          <img
            src={rowIndex}
            height={400}
            width={540}
            className="explore-measure-img grid"
            alt=""
          />
          <canvas
            id={"cas" + i}
            height={400}
            width={540}
            onClick={(e) => {
              
              let mouseX = e.nativeEvent.offsetX;
              let mouseY = e.nativeEvent.offsetY;
              
              const result = initialData?.find(
                (x) => parseInt(Object.keys(x)[0]) === currentSlide
              );
              if (result !== undefined) {
                setAxisDisplay([]);
                result[currentSlide]?.push({ x: mouseX, y: mouseY });
                setInitialData([...initialData]);
              } else {
                setAxisDisplay([]);
                setInitialData((pastData) => [
                  ...pastData,
                  {
                    [initialData.length]: [{ x: mouseX, y: mouseY }],
                  },
                ]);
                
              }

            }}
            className="explore-measure-img-canvas block"
          ></canvas>
        </div>
      </React.Fragment>
    );
  });

  useEffect(
    (x) => {
      setAxisDisplay([]);
      initialData?.map((data, i) => {
        if (parseInt(Object.keys(data)[0]) === currentSlide) {
          Object.values(data).map((subdata) => {
            if(subdata.length > 1){
              setAxisDisplay([subdata[subdata.length-1]]);
            }else{
              setAxisDisplay(subdata);
            }
          });
        }
      });
    },
    [initialData, currentSlide]
  );

  const showSuccessNotification = () => {
    notification.open({
      placement: 'top',
      duration: '2',
      description: [
        // <></>
        <li>
          <CheckCircleOutlined className="text-success ant-icon-up" />
            <span className="ml-4">Initial data points added successfully</span>
        </li>
      ]
    })
  }
  return (
    <>
      <Row align="middle">
        <Col>
          <Carousel
            style={contentStyle}
            afterChange={onChange}
            className="explore-main-container"
            height={400}
            width={540}
            arrows
            nextArrow={<ArrowRightOutlined />}
            prevArrow={<ArrowLeftOutlined />}
          >
            {ImgeComponent}
          </Carousel>
        </Col>
      </Row>

      <Row className="mt-3" hidden={props?.disable === true}>
        <Col md="6" style={{ margin: "auto" }}>
          <table
            style={{
              width: "100%",
              borderColor: "#ADA4A4",
              borderStyle: "solid",
              borderWidth: 0.5,
              width: "100%",
            }}
          >
            {axisDisplay.map((data, index) => {
              return (
                <>
                  <tr
                    className="text-center"
                    style={{
                      borderColor: "#ADA4A4",
                      borderStyle: "solid",
                      borderWidth: 0.5,
                      width: "100%",
                    }}
                  >
                    <td
                      className="px-5 py-2"
                      style={{
                        borderColor: "#ADA4A4",
                        borderStyle: "solid",
                        borderWidth: 0.5,
                        width: "50%",
                      }}
                    >
                      X<sub>{index}</sub> - {data.x}
                    </td>
                    <td
                      className="px-5 py-2"
                      style={{
                        borderColor: "#ADA4A4",
                        borderStyle: "solid",
                        borderWidth: 0.5,
                        width: "50%",
                      }}
                    >
                      Y<sub>{index}</sub> - {data.y}
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </Col>
      </Row>
      <br />
      <br />
      <div className="flex-rows">
         <Row>
          <Col xs='6'>
            <Label>Select Type</Label>
            <select  onClick={selectedField} class="form-control">
              <option value="0">Select One </option>
              <option value="1">Fixed point</option>
              <option value="3">Fixed Points (Energy Graph)</option>
              <option value="4">Fixed point (Air Pollution)</option>
              <option value="2">Four Points</option>
              <option value="5">Diversity</option>
              <option value="6">Biodiversity</option>
            </select>                 
          </Col>
          {selectedValue === "2" &&

            <Col xs='3'>
            <Form.Item name="checked" label="">
                <Tooltip
                    title={`select if you want the inbound range, else it will take it as outbound range`}
                >
                  <Checkbox
                    className="mt-25"
                    checked={checkedStatus}
                    onChange={(e) => {
                      // use the props.initialFourPointOptionValue to get the DB data
                      setCheckedStatus(e.target.checked);
                      setDisabled(false);
                      setSaveClick(false);                    
                    }}
                  >
                    Inbound Range
                  </Checkbox>
                </Tooltip>
              </Form.Item>            
            </Col>
          }
        </Row>
        {/*
        {selectedValue === "1" && (<>
          <Row>
          <Col xs="6">
            <Form.Item name="type" label="Answer Type">
              <Select
                mode=""
                placeholder="Click here to Question type"
              ></Select>
            </Form.Item>
          </Col>
          <Col xs="6">
            <Form.Item
              name="answer"
              label="Correct Answer (only for string answers)"
            >
              <Input placeholder="Add the answer" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs="6">
            <Form.Item name="answer1" label="Correct Answer-Start from">
              <Input placeholder="Answer range start from" />
            </Form.Item>
          </Col>
          <Col xs="6">
            <Form.Item name="answer2" label="Correct Answer-End from">
              <Input placeholder="Answer range end from" />
            </Form.Item>
          </Col>
        </Row>
        </>)}
        */}
        {selectedValue === "1" && (<> 
        
        <Row>
          <Col xs="6">
            <Form.Item
              name="x"
              label="Base point - X axis"
            >
                {/* for now don't remove the log as without the log the answer[index] doesn't gets updated*/}
                {console.log(initialDataX)}
              <Input onChange={changeHandler}  type="number" value={initialDataX} placeholder={"Add the X value"} />
            </Form.Item>
          </Col>
          <Col xs="6">
            <Form.Item
              name="y"
              label="Base point - Y axis"
            >
            {/* for now don't remove the log as without the log the answer[index] doesn't gets updated*/}
            {console.log(initialDataY)}
              <Input onChange={changeHandler2} type="number" value={initialDataY} placeholder={"Add the Y value"} />
            </Form.Item>
          </Col>
        </Row>
        </>)}

        {selectedValue === "3" && (<> 
        <Row>
          <Col xs="6">
            <Form.Item
              name="x"
              label="Final image Point - X axis"
            >
             {/* for now don't remove the log as without the log the answer[index] doesn't gets updated*/}
             {console.log(initialDataX)}
              <Input onChange={changeHandler}  type="number" value={initialDataX} placeholder={"Add the X value of final image"} />
            </Form.Item>
          </Col>
          <Col xs="6">
            <Form.Item
              name="y"
              label="Final image point - Y axis"
            >
             {/* for now don't remove the log as without the log the answer[index] doesn't gets updated*/}
             {console.log(initialDataY)}
              <Input onChange={changeHandler2} type="number" value={initialDataY} placeholder={"Add the Y value of final image"} />
            </Form.Item>
          </Col>
        </Row>
        </>)}

        {selectedValue === "4" && (<> 
        <Row>
         
          <Col xs="6">
            <Form.Item
              name="y"
              label="Final image point - Y axis"
            >
             {/* for now don't remove the log as without the log the answer[index] doesn't gets updated*/}
             {console.log(initialDataY)}
              <Input onChange={changeHandler2} type="number"  value={initialDataY} placeholder={"Add the Y value of final image"} />
            </Form.Item>
          </Col>
          <Col xs="6">
            <Form.Item
              name="Scale Value"
              label="Scale Value"
            >
             {/* for now don't remove the log as without the log the answer[index] doesn't gets updated*/}
             {console.log(initialDataScaleValue)}
              <Input onChange={changeHandler3}  type="number" value={initialDataScaleValue} placeholder={ "Add the Scale value"} />
            </Form.Item>
          </Col>
        </Row>
        </>)}

        {selectedValue === "2" &&
        // u need to pass props.initialFourPointData to send the DB data to the component
                <SubExplorerImageCarosel length={props.imageUrls} getValuesFromQuestionAnswer={getFunForQuestionAnswer} initialFourPointData={props.initialFourPointData}/>
          }
          {selectedValue === "5" &&
                <SubExplorerImageCarosel2 length={props.imageUrls} getValuesFromBarAnswer={getFunForBarAnswer} initialBarData={props.initialBarOptionsValues}/>
          }
          {selectedValue === "6" && (
            <>
                <Row>
                  <Col>
                  <Form.Item
                    name="x"
                    label="X Range"
                  >
                   {/* for now don't remove the log as without the log the answer[index] doesn't gets updated*/}
                    {console.log(initialDataY)}
                    <Input onChange={changeHandler2} type="number" value={initialDataY} placeholder={"Add the X value for range calculation"} style={{width:"calc(50% - 15px)"}} />
                  </Form.Item>
                </Col>
                </Row>
                <SubExplorerImageCarosel3 length={props.imageUrls} getValuesFromBarAnswer={getFunForBarAnswer} initialBarData={props.initialBarOptionsValues}/>
            </>
            )
          }
      </div>


      <Row hidden={props?.disable === true}>
        <Col xs="12">
          <button
            disabled={saveClick}
            class="btn btn-success btn-sm btn-block my-2"
            type="primary"
            htmlType="submit"
            block
            onClick={() => {
              props.submitInitialPoints(initialData);
              props.submitInitialPointsMain({ initialDataX, initialDataY, initialDataScaleValue });
              props.submitFourPointsOption(checkedStatus)
              props.submitBarOption(BarPoints)
              props.submitFourPoints(fourPoints)

              //message.success("Images uploaded successfully")
              showSuccessNotification();
              setSaveClick(true);
            }}
          >
            Save
          </button>
        </Col>
      </Row>
    </>
  ); 
};
export default ExplorerImageCarosel;
