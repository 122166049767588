
import { List } from 'antd'
import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Row } from 'reactstrap'

export default function ThirdTypeQuestion(props) {

  let [InputVal, setInputVal] = useState("");
  const onKeyPress = (e) => {
    if (e.which === 13) {
      submit();
    }
  }
  const submit = () => {
    props.data.onClick(InputVal)
    setInputVal("")
    document.getElementById("example").value = ""
  }
  return (


    <div>

      <Row>
        <Col md="12">
          <strong>{props.data.initPayload?.instruction_heading}</strong>
        </Col>

        <Col md="12">
          <h6 className="">  {props.data.data && props.data.data !== null && props.data.data?.description} <span className='text text-success'>{props.data.initPayload?.instruction}</span>
         {props.data.chartType === "Line Type3"&&
                    ( <button onClick={props.onNextValue} className="btn btn-explore ml-2">
                      Next
                    </button>)}
          <br /></h6>
        </Col>


      </Row>
      {props.data.chartType === "Bar"   &&
        <Row>
         {
          props.data.chartType === "Bar"  &&  <div className='col-md-6'>
          <Input disabled={props.data.data === null || props.data.data === undefined} type="number" name="question" id="example" onKeyPress={onKeyPress} onChange={(e) => { setInputVal(e.target.value) }} />
        </div>
         }
          <div className='col-md-6'>
            {props.data.chartType === "Bar"  &&
              <Button disabled={props.data.chartType === "Bar"  ? props.data.data === null || props.data.data === undefined :false} color="btn btn-explore ml-2" onClick={() => {
                if(props.data.chartType === "Bar"){
                  submit();
                }
               
                
              }}>Next</Button>
            }
          </div>


        </Row>
      }
      <Row>
        <Col>
        </Col>

        <Col xs="2" className="pt-4">
        </Col>
      </Row>
    </div>




    //     <>{
    //         props.data.length > 0 && props.data.map(x =>{
    //             <div className="">
    //     <h5 className="pt-4"> {x?.description.split(":")[0]}</h5>
    //     <p className="w30">
    //      {x?.description.split(":")[1]}
    //     </p>
    //     <Row>
    //       <Col xs="6" className="pt-4">
    //         <textarea
    //           className="form-control tarea"
    //           defaultValue={ props.containedPayload[x.id-1]?.answer}
    //           onChange={props.changeHandler}
    //           rows={8}
    //           id="textarea"
    //           name={x.id-1}
    //         ></textarea>
    //       </Col>
    //     </Row>
    //   </div>
    //         })
    //     }
    //     </>
  )
}
