import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Config from "./Config";

export const TermApi = createApi({

    reducerPath: "TermApi",

    baseQuery: fetchBaseQuery(
        {
            baseUrl: Config.APIURL,
            prepareHeaders: (headers, { getState }) => {
                const token = localStorage.getItem("access_token");

                // If we have a token set in state, let's assume that we should be passing it.
                if (token) {
                    headers.set("Authorization", `Bearer ${token}`)
                }

                return headers
            },
        }
    ),
    endpoints: (builder) => ({
        getTerms: builder.query({
            query: (id) =>  `admin/term/getTermBySchool/${id}`

           
       ,
        })
    })
})


export const { useGetTermsQuery } = TermApi;