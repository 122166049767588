import { useState, useEffect } from "react";
import { Spin, Input, Table, Select, Drawer, message } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import { Row, Col } from "reactstrap";
import Edit from "./edit";
import AccountAdminService from "../../../../api/AccountAdminService"
import SchoolService from "../../../../api/SchoolService";
import { useDispatch } from "react-redux";
import { editschoolAdmins } from "../../../../reducers/schoolAdminSlice";
import jsonToken from 'jsonwebtoken'
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";
const { Option } = Select;

function AdminView(props) {
  const [size, setSize] = useState();
  const [visible, setVisible] = useState(false);
  const [editKey, setEditKay] = useState(1);

  const [loading, setLoadingSA] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [accountId, setAccountId] = useState(null);
  const [schools, setSchools] = useState([]);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [searchSchoolAdminText, setSearchSchoolAdminText] = useState("");
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  const columns = [
    {
      title: "School Admin Name",
      dataIndex: "firstName",
      key: "firstName",
      className: "fixed-size",
      render: (value, row, index) => {
        return row.firstName + " " + row.lastName;
      }
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Action",
      render: (text, record) => <span className="btn btn-sm btn-info" onClick={(e) => { showEditDrawer(record); }}>Edit</span>,
      className: "action"
    },
  ];

  const dispatch = useDispatch();

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (props.activeTab == props.tabId) {
      loadAccounts()
      if (schoolId && schoolId > 0) {
        loadAdmins()
      }
    }
  }, [props.activeTab])

  useEffect(() => {
    if (accessToken.roles[0] === "ACCOUNT_ADMIN") {
      if (accounts && accounts.length === 1) {
        setAccountId(accounts[0].id);
      }
    }
  }, [accounts])

  useEffect(() => {
    loadAdmins()
  }, [schoolId]);

  function loadAccounts() {
    setLoadingSA(true);
    AccountAdminService.getAccountsForUser()
      .then((response) => {
        if (response?.data?.accountList) {
          setAccounts(response.data.accountList);
        }
      }).catch((err) => {
        console.log(err.message);
      }).finally(() => {
        setLoadingSA(false);
      });
  }

  function loadAdmins(page = currentPage, pageSize = defaultPageSize, search = searchTerm) {
    setAdmins([]);
    setTableData([]);
    // if (!schoolId || schoolId <= 0) {
    //   return;
    // }
    setCurrentPage(page);
    setDefaultPageSize(pageSize);
    if(schoolId){

      setLoadingSA(true);
      AccountAdminService.getSchAdminBySchoolId(schoolId, page, pageSize, search)
        .then((response) => {
          if (response?.data) {
            setAdmins(response.data.items);
            setTableData(response.data.items);
            setTotal(response.data.totalItems);
            setCurrentPage(response.data.page);
          }
        }).catch((err) => {
          console.log(err.message);
        }).finally(() => {
          setLoadingSA(false);
        });
    }
  }

  function onSearchByName(e) {
    setCurrentPage(1);
    const currValue = e.target.value;
    setSearchTerm(currValue)
    setSearchSchoolAdminText(currValue);
    setCurrentPage(1);
    loadAdmins(1, defaultPageSize, currValue)
  }

  const onClose = () => {
    setSelectedEdit(null);
    loadAdmins()
    setVisible(false);
    setEditKay(prev => prev + 1);
  };

  const showEditDrawer = (record) => {

    dispatch(editschoolAdmins(record));
    setSelectedEdit(record);
    setSize("large");
    setVisible(true);
  };

  const onAccountChange = (value) => {
    setCurrentPage(1);
    setAccountId(value)
    setSearchSchoolAdminText("");
    if (value === "-1") {
      setSchools([]);
    }
  };
  const onSchChange = (value) => {
    setCurrentPage(1);
    setSchoolId(value)
    setSearchTerm("");
    setSearchSchoolAdminText("");
  };
  useEffect(() => {
    if (accountId || accountId !== null) {
      setSchoolId(null)
      setSchools([])
      setTotal(0)
      setLoadingSA(true)
      SchoolService.getSchoolsForAccount(accountId)
        .then(res => {
          if (res.status === 200) {
            setSchools(res.data)
          }
        })
        .finally(() => {
          setLoadingSA(false)
        })
    }
  }, [accountId])

  return (
    <>
      <div>
          <div className="my-5">
            <Row>

              <Col md="4">
                <Select
                  showSearch
                  placeholder="Search by Account"
                  optionFilterProp="children"
                  size="large"
                  className="search-drop"
                  value={accountId}
                  disabled={disableEnableDropdown("Account", props.user_roll)}
                  onChange={onAccountChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <option key="-1" value="-1">Please Select an Account</option>
                  {
                    accounts.filter(item => item.name !== null).map((obj) => <Option key={obj.id} value={obj.id}>{obj.name}</Option>)
                  }
                </Select>
              </Col>
              <Col md="4">
                <Select
                  showSearch
                  placeholder="Search by School"
                  optionFilterProp="children"
                  size="large"
                  className="search-drop"
                  onChange={onSchChange}
                  value={schoolId !== "-1" ? schoolId : null}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {accountId !== undefined && accountId !== null && accountId > 0 ?
                    <option selected key="-1" value="-1">Please Select a School</option>
                    : <option key="-1" value="-1">Select an Account First</option>
                  }
                  {
                    schools.length > 0 && schools.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                  }
                </Select>
              </Col>

              <Col md="4">
                <Input
                  size="large"
                  placeholder="Search"
                  prefix={<SearchOutlined className="ml-3 mr-3" />}
                  onChange={onSearchByName}
                  value={searchSchoolAdminText}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col md="12">
              <Table
                dataSource={tableData}
                loading={loading}
                //pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                pagination={{
                  current: currentPage,
                  position: ["bottomCenter"],
                  total: total, // Total number of items in the API response (you may need to fetch this dynamically)
                  pageSize: defaultPageSize, // Number of items to display per page
                  onChange: (page, pageSize) => loadAdmins(page, pageSize), // Callback function to handle page changes
                  showSizeChanger: false
                }}
                columns={columns}
                className="thead-dark admin-table"
              />
            </Col>
          </Row>
          <Drawer
            className="medium"
            title={`Edit School Admin`}
            placement="right"
            size={size}
            onClose={onClose}
            visible={visible}
          > 
            <Edit key={editKey} user_roll={props.user_roll} selectedName={selectedEdit?.name} selectedId={selectedEdit?.id} onClose={onClose} />
          </Drawer>
      </div>
    </>
  );
}

export default AdminView;
