import { useState, useEffect } from "react";
import { Spin, Input, Table, Select, Drawer } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import { Row, Col, Button } from "reactstrap";
import ClassService from "../../../../api/ClassService"

import Assign from "./Assign"
import Edit from "./edit"
import jsonToken from 'jsonwebtoken';
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";
import { disableEnableButtons } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableButtons";

function ClassesView(props) {
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  const [size, setSize] = useState();
  const [visibleAssignDrawer, setVisibleAssignDrawer] = useState(false);
  const [visibleEditDrawer, setVisibleEditDrawer] = useState(false);
  const [editKey, setEditKay] = useState(1);
  const [assignKey, setAssignKay] = useState(1);

  const [accountId, setAccountId] = useState(null);
  const [schoolId, setSchoolId] = useState(null);

  const [accounts, setAccounts] = useState([]);
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fullClassList, setfullClassList] = useState([]);
  const [pageRefresh, setPageRefresh] = useState(false);


  const [selectedClassAssign, setSelectedClassAssign] = useState(null);
  const [selectedClassEdit, setSelectedClassEdit] = useState(null);

  const [disableAccount, setDisableAccount] = useState(false);
  const [disableSchool, setDisableSchool] = useState(false);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("")
  const [searchClassText, setSearchClassText] = useState("");

  useEffect(() => {
    if (props.activeTab == props.tabId) {
      loadAccounts()
      if (accountId && accountId > 0) {
        loadSchools(accountId)
      }
      if (schoolId && schoolId > 0) {
        loadClasses()
      }
    }
  }, [props.activeTab])

  useEffect(() => {
    loadSchools(accountId)
  }, [accountId]);

  useEffect(() => {
    if(props.open == false && schoolId !== null && schoolId > 0){
      setPageRefresh(currentVal => !currentVal);
    }
  }, [props.open])

  useEffect(() => {
    loadClasses()
  }, [schoolId, pageRefresh]);

  useEffect(() => {
    if (accessToken.roles[0] === "SCHOOL_ADMIN" || accessToken.roles[0] === "ACCOUNT_ADMIN" || accessToken.roles[0] === "TEACHER") {
      if (accounts && accounts.length === 1) {
        setAccountId(accounts[0].id)
        setDisableAccount(true);
      }
    }
  }, [accounts])

  useEffect(() => {
    if (accessToken.roles[0] === "SCHOOL_ADMIN" || accessToken.roles[0] === "TEACHER") {
      if (schools && schools.length === 1) {
        setSchoolId(schools[0].id)
        setDisableSchool(true);
      }
    }
  }, [schools])

  useEffect(() => {
    if(schoolId != null){
      getfullClassList();
    }
  }, [schoolId])

  const columns = [
    {
      title: "Class Name",
      dataIndex: "name",
      key: "name",
    },
    ...(!disableEnableButtons("create-class", accessToken.roles[0]) ? [{
      title: "Action",
      render: (text, record) => <Button className="btn btn-sm btn-info" onClick={() => { showEditDrawer(record) }}>Edit</Button>,
      className: "action"
    }] : []),
    {
      title: "Assign",
      render: (text, record) => <span className="btn btn-sm btn-info" onClick={() => { showAssignDrawer(record) }}>Assign</span>,
      className: "action"
    },
  ];

  const showAssignDrawer = (record) => {
    setSelectedClassAssign(record)
    setSize("small");
    setVisibleAssignDrawer(true);
  };

  const onCloseAssignDrawer = () => {

    setVisibleAssignDrawer(false);
    setAssignKay(prev => prev + 1);
    setSelectedClassAssign(undefined);

  };
  const showEditDrawer = (record) => {
    setSelectedClassEdit(record);
    setSize("small");
    setVisibleEditDrawer(true);
  };
  const onCloseEditDrawer = () => {
    setSelectedClassEdit(null);
    loadClasses();
    setVisibleEditDrawer(false);
    setEditKay(prev => prev + 1);
  }

  const { Option } = Select;

  const onAccountChange = (value) => {
    setCurrentPage(1);
    setAccountId(value);
    setTotal(0)
    setSearchClassText("");
    if (value === "-1") {
      setSchools([]);
      setSchoolId("-1");
    }
  };

  const onSchoolChange = (value) => {
    setCurrentPage(1);
    setSchoolId(value)
    setSearchTerm("");
    setSearchClassText("");
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  function onSearchByName(e) {
    setCurrentPage(1);
    const currValue = e.target.value;
    setSearchTerm(currValue);
    setSearchClassText(currValue);
    setCurrentPage(1);
    loadClasses(1, defaultPageSize, currValue);
  }

  function loadAccounts() {
    setLoading(true);
    ClassService.getAccountsForUser()
      .then((response) => {
        if (response?.data?.accountList) {
          setAccounts(response.data.accountList);
        }
      }).catch((err) => {
        console.log(err.message);
      }).finally(() => {
        setLoading(false);
      });
  }

  function loadSchools() {
    setSchools([]);
    setTableData([])
    if (!accountId || accountId <= 0) {
      return;
    }
    setLoading(true);
    ClassService.getSchoolsForAccount(accountId)
      .then((response) => {
        if (response?.data) {
          setSchools(response.data);
          setSchoolId(null)
        }
      }).catch((err) => {
        console.log(err.message);
      }).finally(() => {
        setLoading(false);
      });
  }

  function getfullClassList(){
    setfullClassList([]);

    try {
      ClassService.getClassesForDropDownBySchool(schoolId)
      .then((response) => {
        if(response?.data){
          setfullClassList(response.data);
        }
      }) 
    } catch (error) {
      console.log("get class list error :",error)
    }
  }

  function loadClasses(page = currentPage, pageSize = defaultPageSize, search = searchTerm) {
    setClasses([]);
    setTableData([]);
   if(schoolId){
     setLoading(true);
     ClassService.getClassesForSchoolPaged(schoolId, page, pageSize, search)
       .then((response) => {
         if (response?.data) {
           setClasses(response.data.items);
           setTableData(response.data.items)
           setTotal(response.data.totalItems);
           setCurrentPage(response.data.page);
         }
       }).catch((err) => {
         console.log(err);
       }).finally(() => {
         setLoading(false);
       });
   }
  }

  return (
    <>
      <div>
          <div className="my-5">
            <Row>
              <Col md="4">
                <Select
                  showSearch
                  placeholder="Search by Account"
                  optionFilterProp="children"
                  size="large"
                  className="search-drop"
                  onChange={onAccountChange}
                  onSearch={onSearch}
                  value={accountId}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={disableEnableDropdown("Account", props.user_roll)}
                >
                  <option key="-1" value="-1">Please Select an Account</option>
                  {
                    accounts.filter(item => item.name !== null).map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                  }
                </Select>
              </Col>
              <Col md="4">
                <Select
                  showSearch
                  placeholder="Search by School"
                  optionFilterProp="children"
                  size="large"
                  className="search-drop"
                  onChange={onSchoolChange}
                  onSearch={onSearch}
                  value={schoolId !== "-1" ? schoolId : null}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={disableEnableDropdown("School", props.user_roll)}
                >
                  {accountId !== undefined && accountId !== null && accountId > 0 ?
                    <option selected key="-1" value="-1">Please Select a School</option>
                    : <option key="-1" value="-1">Select an Account First</option>}
                  {
                    schools.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                  }
                </Select>
              </Col>
              <Col md="4">
                <Input
                  size="large"
                  placeholder="Search"
                  prefix={<SearchOutlined className="ml-3 mr-3" />}
                  onChange={onSearchByName}
                  value={searchClassText}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col md="12">
              <Table
                dataSource={tableData}
                loading={loading}
                //pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                pagination={{
                  current: currentPage,
                  position: ["bottomCenter"],
                  total: total, // Total number of items in the API response (you may need to fetch this dynamically)
                  pageSize: defaultPageSize, // Number of items to display per page
                  onChange: (page, pageSize) => loadClasses(page, pageSize), // Callback function to handle page changes
                  showSizeChanger: false
                }}
                columns={columns}
                className="thead-dark admin-table"
              />
            </Col>
          </Row>
          <Drawer
            className="medium"
            title={`Assign Students`}
            placement="right"
            size={size}
            onClose={onCloseAssignDrawer}
            visible={visibleAssignDrawer}
          >
            <Assign closeDrawer={onCloseAssignDrawer} key={assignKey} selectedClassName={selectedClassAssign?.name} selectedClassId={selectedClassAssign?.id ?? undefined} classes={classes} fullClassList={fullClassList} />
          </Drawer>
          <Drawer
            className="medium"
            title={`Edit Class`}
            placement="right"
            size={size}
            onClose={onCloseEditDrawer}
            visible={visibleEditDrawer}
            destroyOnClose={true}
          >
            <Edit key={editKey}
              onClose={onCloseEditDrawer}
              accval={accountId}
              selectedClassName={selectedClassEdit?.name}
              selectedClassId={selectedClassEdit?.id}
              selectedClassToEdit={selectedClassEdit}
              schoolId={schoolId}
              user_roll={props.user_roll}
            />
          </Drawer>
      </div>
    </>
  );
}

export default ClassesView;
