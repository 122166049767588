import React, { useEffect, useState } from "react";
const slopeFunction = (xData, yData)=>{
    // Calculate the rise and run of the line.
    const rise = yData[yData.length-1] - yData[0];
    const run = xData[xData.length-1] - xData[0];
  
    // Return the slope.
    return rise / run;
  }

const  getDistance = (xData, yData,  xFixed, yFixed) => {
let lengths = []
for(let i = 0; i < xData.length; i++){
    const dx = xData[i] - xFixed;
    const dy = yData[i] - yFixed;
    const hypotenuse = Math.sqrt(dx * dx + dy * dy);
    lengths.push(hypotenuse)
}
return lengths
}

const circleCOD = (xData, yData)=>{

    const distances = getDistance(xData, yData,  -1.5, 33)
    const sum = distances.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const avgDistance = sum / distances.length;

    let checker = 0;

    distances.map(x=>{
        if(x > avgDistance - 1 && x < avgDistance + 1){
            checker++;
        }
    })

    return (checker/ distances.length) * 100;
    
}

function CoordinatesValues({dataTableValue,chartData, data, clickState, lesson,chartType}) {
    const [triggerValue, setTriggerValue] = useState(0);
    const [optionsLength, setOptionsLength] = useState(1);

   
    
    const increaseFunction = () => {
        if(triggerValue < optionsLength-1){
            setTriggerValue(triggerValue+1);
        }
    };

    const decreaseFunction = () => {
        if(triggerValue > 0){
        setTriggerValue(triggerValue-1);
        }
    };
    useEffect(() => {
        setTriggerValue(0)
        setOptionsLength(data?.length);
    },[data])

    const tablePayload = {};

    const TABLE_DISPLAY = (x, cameFrom) =>{
        if(x.value === "slope"){
            if(data?.yData !== undefined && cameFrom === "first"){
                return ({keyValue:"Slope", objectValue: `${clickState == 0 ? parseFloat(slopeFunction(data?.xData,data?.yData)).toFixed(2) : parseFloat(slopeFunction(data?.yData,data?.xData)).toFixed(2)} ${x.si}`})
            }
            if(tablePayload?.xData !== undefined && cameFrom === "second"){
                return ({keyValue:"Slope", objectValue: `${parseFloat(slopeFunction(tablePayload?.yData,tablePayload?.xData)).toFixed(2)} ${x.si}`})
            }
        };

        if(x.value === "v"){
            if(!isNaN(data?.speed) && data?.speed !== undefined && cameFrom === "first"){
                return ({keyValue:"v", objectValue: `${parseFloat(data?.speed).toFixed(2)} ${x.si}`})
            }
            if(!isNaN(tablePayload?.speed) && tablePayload?.speed !== undefined && cameFrom === "second"){
                return ({keyValue:"v", objectValue: `${parseFloat(tablePayload?.speed).toFixed(2)} ${x.si}`})
            }
        };

        if(x.value === "v2"){
            

            if(!isNaN(data?.speed2) && data?.speed2 !== undefined && cameFrom === "first"){
                return ({keyValue:"v2", objectValue: `${parseFloat(data?.speed2).toFixed(2)} ${x.si}`})
            }
            if(!isNaN(tablePayload?.speed2) && tablePayload?.speed2 !== undefined && cameFrom === "second"){
                return ({keyValue:"v2", objectValue: `${parseFloat(tablePayload?.speed2).toFixed(2)} ${x.si}`})
            }
        };

        if(x.value === "fit"){

            if(chartType === "Line Type12"){
                if(cameFrom === "first"){
                    const value = circleCOD(data?.xData,data?.yData)
                    return (
                        {keyValue:"Fit", objectValue: `${parseFloat(value).toFixed(2)} / 100 ${x.si}`}
                        )
    
                }
                if(cameFrom === "second"){
                    const value = circleCOD(tablePayload?.yData,tablePayload?.xData)
                    return ({keyValue:"Fit", objectValue: `${parseFloat(value).toFixed(2)} / 100 ${x.si}`})
                }

            }if(chartType ==="Line Type3"){
                if(!isNaN(data?.cod) && data?.cod !== undefined && cameFrom === "first"){
                    return ({keyValue:"Fit", objectValue: `100.00 / 100 ${x.si}`})
    
                }
                if(!isNaN(tablePayload?.cod) && tablePayload?.cod !== undefined && cameFrom === "second"){
                    return ({keyValue:"Fit", objectValue: `100.00 / 100 ${x.si}`})
    
                }
            }else{
                if(!isNaN(data?.cod) && data?.cod !== undefined && cameFrom === "first"){
                    return ({keyValue:"Fit", objectValue: `${parseFloat(data?.cod).toFixed(2)} / 100 ${x.si}`})
    
                }
                if(!isNaN(tablePayload?.cod) && tablePayload?.cod !== undefined && cameFrom === "second"){
                    return ({keyValue:"Fit", objectValue: `${parseFloat(tablePayload?.cod).toFixed(2)} / 100 ${x.si}`})
    
                }
            }

        };

        if(x.value === "a"){
            
            if(!isNaN(data?.speed) && data?.speed !== undefined && cameFrom === "first"){
                return ({keyValue:"a", objectValue: `${parseFloat(data?.speed).toFixed(2)} ${x.si}`})
            }
            if(!isNaN(tablePayload?.speed) && tablePayload?.speed !== undefined && cameFrom === "second"){
                return ({keyValue:"a", objectValue: `${parseFloat(tablePayload?.speed).toFixed(2)} ${x.si}`})
            }
        };

        if(x.value === "max"){
            if(data?.yData !== undefined && cameFrom === "first"){
                return ({keyValue:"Max", objectValue: `${clickState == 0 ? Math.max(...data?.yData):Math.max(...data?.xData)} ${x.si}`})
            }
            if(tablePayload?.xData !== undefined && cameFrom === "second"){
                return ({keyValue:"Max", objectValue: `${Math.max(...tablePayload?.xData)} ${x.si}`})
            }
        };


        if(x.value === "halfTime"){
            if(data?.xData !== undefined && cameFrom === "first"){

                const fData = clickState == 0 ? data?.xData : data?.yData

                fData.sort((a, b) => a - b);
                const middleIndex = Math.floor(fData.length / 2);
                const isEven = fData.length % 2 === 0;
                const lowerMiddleIndex = isEven ? middleIndex - 1 : middleIndex;
                const upperMiddleIndex = middleIndex;

                const median = isEven ? (fData[lowerMiddleIndex] + fData[upperMiddleIndex]) / 2 : fData[middleIndex];

                return ({keyValue:"Half Time", objectValue: `${median} ${x.si}`})
            }
            if(tablePayload?.yData !== undefined && cameFrom === "second"){
                const fData = tablePayload?.yData

                fData.sort((a, b) => a - b);
                const middleIndex = Math.floor(fData.length / 2);
                const isEven = fData.length % 2 === 0;
                const lowerMiddleIndex = isEven ? middleIndex - 1 : middleIndex;
                const upperMiddleIndex = middleIndex;

                const median = isEven ? (fData[lowerMiddleIndex] + fData[upperMiddleIndex]) / 2 : fData[middleIndex];

                return ({keyValue:"Half Time", objectValue: `${median} ${x.si}`})
            }
        };

        if(x.value === "period"){
            if(data?.yData !== undefined && cameFrom === "first"){

                const fData = clickState == 0 ? data?.xData : data?.yData;

                const value = (parseInt(fData[fData.length-1])*2);

                return ({keyValue:"period", objectValue: `${value} ${x.si}`})
            }
            if(tablePayload?.xData !== undefined && cameFrom === "second"){
                const fData = tablePayload?.yData;

                const value = (parseInt(fData[fData.length-1])*2);

                return ({keyValue:"period", objectValue: `${value} ${x.si}`})
            }
        };
        
    }

    if(data?.type===false){      
        return (
            <>
                <div className="xy-table">
                    <div class="row">
                        <div class="col-md-12 text-center">
                        {
                            clickState !== 2 ? <span><b>{data?.labels}</b></span> :""
                        }
                        </div>
                    </div>
                    <span class="notranslate">
                        <table class="table">
                        <thead>
                            <tr>
                            <th className="dlabel"> {chartData?.xLabel } </th>
                            <th className="dlabel"> {chartData?.yLabel }</th>
                            </tr>
                        </thead>
                        <tbody>
                        
                        {clickState === undefined ? (
                            
                            <>
                            {data?.xData.map((value, i) => (
                            <tr key={i}>
                                <td>{chartType === "Line Volume Defined" ? parseInt(value) : chartType === "Line Type10" || chartType === "Line Type11" ? value : parseFloat(value).toFixed(1)}</td>
                                <td>{data?.yData[i]}</td>
                            </tr>
                        ))}
                            </>
                        ):(
                            <>
                            {clickState !== 0 ? (<>
                                {chartType === "curve" ? (<>
                                    {data?.xData.map((value, i) => (
                                        <tr key={i}>
                                            <td>{parseFloat(value).toFixed(1)}</td>
                                            <td>{data?.yData[i]}</td>
                                        </tr>
                                    ))}
                                </>) : (<>
                                    {data?.xData.map((value, i) => (
                                        <tr key={i}>
                                            <td>{chartType === "Line Volume Defined" ? parseInt(data?.yData[i]) : chartType === "Line Type10" || chartType === "Line Type11" ? data?.yData[i] :parseFloat(data?.yData[i]).toFixed(1)}</td>
                                            {/* <td>{value}v</td> */}
                                            {chartType === "Line Type8" ? (
                                            <>
                                            <td>{((value).split("; \n "))[0] === "PE:0.00" ? "PE:0" : ((value).split("; \n "))[0]}<br/>{((value).split("; \n "))[1] === "KE:0.00" ? "KE:0" : ((value).split("; \n "))[1]}</td>
                                            </>
                                        ) : (
                                            <td>{value}</td>
                                        )}
                                        </tr>
                                    ))}
                                </>)}
                                
                         </>): (
                            <>
                                {data?.xData.map((value, i) => (
                                    <tr key={i}>
                                        <td>{chartType === "Line Volume Defined" ? parseInt(value) : chartType === "Line Type10" || chartType === "Line Type11" ? value : parseFloat(value).toFixed(1)}</td>
                                        {chartType === "Line Type8" ? (
                                            <>
                                            <td>{((data?.yData[i]).split("; \n "))[0] === "PE:0.00" ? "PE:0" : ((data?.yData[i]).split("; \n "))[0]}<br/>{((data?.yData[i]).split("; \n "))[1] === "KE:0.00" ? "KE:0" : ((data?.yData[i]).split("; \n "))[1]}</td>
                                            </>
                                        ) : (
                                            <td>{data?.yData[i]}</td>
                                        )}
                                        
                                    </tr>
                                ))}   
                            </>)}
                            </>
                        )}
                        
                        </tbody>
                    </table> 
                    </span>
                    {dataTableValue !== undefined && dataTableValue?.map((x)=>{
                       const result = TABLE_DISPLAY(x, "first");
                       return (
                        <div>
                            <span class="notranslate">
                            <center>
                                { data?.yData.length > 2 && result !== undefined && 
                                    `${result?.keyValue} : ${result?.objectValue}`
                                }
                            </center>
                            </span>
                        </div>
                       )
                    })}
                    {chartType === "Line Type12" && (
                        <div>
                            <span class="notranslate">
                            <center>
                                {
                                data?.yData.length > 2 &&
                                    `r(x,y) : -1.5° , 33°`
                                }
                            </center>
                            </span>
                        </div>
                    )}
                </div>
            </>
          );
    }
    else{
        

        if(chartType !== "Bar"  && clickState === 1){
             tablePayload.labels = data[triggerValue]?.labels;
                tablePayload.xData = data[triggerValue]?.yData;
                tablePayload.yData = data[triggerValue]?.xData;
                tablePayload.speed = data[triggerValue]?.speed;
                tablePayload.speed2 = data[triggerValue]?.speed2;
                tablePayload.cod = data[triggerValue]?.cod;
                tablePayload.multi = true;

              
        }
        else{
            if(data.length > 1){
                tablePayload.multi = true;
                tablePayload.labels = data[triggerValue]?.labels;
                tablePayload.xData = data[triggerValue]?.yData;
                tablePayload.yData = data[triggerValue]?.xData;
                tablePayload.speed = data[triggerValue]?.speed;
                tablePayload.speed2 = data[triggerValue]?.speed2;
                tablePayload.cod = data[triggerValue]?.cod;                
            }else{
                data.map((x)=>{
                    tablePayload.labels = x?.labels;
                    tablePayload.yData = x?.xData;
                    tablePayload.speed = x?.speed;
                    tablePayload.speed2 = x?.speed2;
                    tablePayload.xData = x?.yData;
                    tablePayload.cod = x?.cod;
                    tablePayload.multi = false;
                })
                
            }
            
        }
        return(
            <>
            <div className="xy-table">
            {tablePayload.multi === true &&
                     <div class="row">
                    <div class="col-md-3 text-center" onClick={decreaseFunction}>
                    <i class="fa fa-angle-left"></i>
                    </div>
                    <div class="col-md-6 no-padding text-center">
                        <span><b>{tablePayload?.labels}</b></span>
                    </div>
                    <div class="col-md-3 text-center" onClick={increaseFunction}>
                    <i class="fa fa-angle-right"></i>
                        </div>
                   </div>}

                   {tablePayload.multi === false &&
                     <div class="row">
                        <div class="col-md-12 text-center">
                    {
                        <span><b>{tablePayload?.labels}</b></span>
                    }
                    </div>
                   </div>

                   }

                
                    <span class="notranslate">
                        <table class="table">
                        <thead>
                            <tr>
                            <th>{chartData?.xLabel }</th>
                            <th>{chartData?.yLabel }</th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            {clickState === undefined ? (<>
                                {tablePayload?.xData  &&tablePayload?.xData.map((value, i) => (
                                <tr key={i}>
                                    <td>{parseFloat(value).toFixed(1)}</td>
                                    <td>{tablePayload?.yData[i]}</td>
                                </tr>
                            ))}
                            </>):(<>
                                {tablePayload?.xData  &&tablePayload?.xData.map((value, i) => (
                                <tr key={i}>
                                    <td>{chartType === "Line Type10" || chartType === "Line Type11"? tablePayload?.yData[i] : parseFloat(tablePayload?.yData[i]).toFixed(1)}</td>
                                    {chartType === "Line Type8" ? (
                                            <>
                                        <td>{((value).split("; \n "))[0] === "PE:0.00" ? "PE:0" : ((value).split("; \n "))[0]}<br/>{((value).split("; \n "))[1] === "KE:0.00" ? "KE:0" : ((value).split("; \n "))[1]}</td>
                                        </>
                                    ) : (
                                        <td>{value}</td>
                                    )}
                                </tr>
                                ))}
                                </>)}
                            
                        </tbody>
                        </table>   
                    </span>
                    {dataTableValue !== undefined && dataTableValue.map((x)=>{
                       const result = TABLE_DISPLAY(x, "second");
                       return (
                        <div>
                            <span class="notranslate">
                            <center>
                                { result !== undefined && 
                                    `${result?.keyValue} : ${result?.objectValue}`
                                }
                            </center>
                            </span>
                        </div>
                       )
                    })}
                    {chartType === "Line Type12" && (
                        <div>
                            <span class="notranslate">
                            <center>
                                {
                                    `r(x,y) : -1.5° , 33°`
                                }
                            </center>
                            </span>
                        </div>
                    )}
                </div>
            </>
        )
    }
  
}

export default CoordinatesValues;