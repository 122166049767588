import React, { Component } from "react";
import { Redirect, Route } from 'react-router-dom'
import jsonToken from 'jsonwebtoken'
import PropTypes from 'prop-types'
import { LoginCheckWithPages } from "../components/LoginCheck/LoginCheck";

function AuthorizeRoute({ children, ...rest }) {
    let token = localStorage.getItem("access_token");
    let authenticated = !!token && token != '';
    console.log('authenticated', authenticated);
    LoginCheckWithPages();
    if (!authenticated) {
        return (
            <Route
                {...rest}
                render={({ location }) => {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location },
                            }}
                        />
                    );
                }}
            />
        );
    } else {
        let authorized = false;
        if (rest.roles == '*') {
            authorized = true;
        } else {
            authorized = true;
            let accessToken = jsonToken.decode(token);
            let allowedRoles = rest.roles.split(",");
            console.log('allowedRoles', allowedRoles)
            for (let i = 0; i < allowedRoles.length; i++) {
                let role = allowedRoles[i];
                let index = accessToken.roles.findIndex(element => {
                    console.log('element', element)
                    console.log('role', role)
                    return element.toLowerCase() === role.toLowerCase();
                });
                console.log('index', index)
                if (index > -1) {
                    authorized = true;
                }
            }
        }
        return (
            <Route
                {...rest}
                render={({ location }) => {
                    return authorized === true ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/Unauthorized",
                                state: { from: location },
                            }}
                        />
                    );
                }}
            />
        );
    }


};

AuthorizeRoute.propTypes = {
    roles: PropTypes.string.isRequired,
};


export default AuthorizeRoute;