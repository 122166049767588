import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from "react-router-dom";
 
// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Table,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Breadcrumb, Radio, Input, Form, Modal, Button, Tabs, Checkbox, Image, Spin } from "antd";
import "antd/dist/antd.css";

import jsonToken from 'jsonwebtoken'
import AnswerDisplay from "../AnswerDisplay";
import { getExplainStepByLessonIdAndLevel } from "../../../../../api/TeacherService";

function ExplainChild(props) {

  const [classLessonName, setClassLessonName] = useState("");
  const [lessonName, setLessonName] = useState("");
  const [initPayload, setInitPayload] = useState({
    description: "",
    explainStepQuestionAnswers: [],
    explainStepQuestions: [],
    id: "",
    lesson_id: "",
    videoUrl: "",
    exploreStepResponses: "",
    experiments: [],
    lessonCode: "",
    chartType: "",
    guideline:"",
  });
  const [loading, setLoading] = useState(true);
  let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;

  }

  useEffect(() => {
    setLoading(true)
    getExplainStepByLessonIdAndLevel(parseInt(props.data.lessonId), props.data.levelId)
      .then((response) => {
        if(response !== undefined){
          setInitPayload(response?.data)
          setClassLessonName(response?.data?.className);
          setLessonName(response?.data?.lessonName);
        }
        
        setLoading(false)
      })
  }, [props])

  // console.log(initPayload?.description)
  // const modifiedDescription = initPayload?.description ? initPayload?.description.split(":") ?? ":" : "";

  useEffect(()=>{
    const childPayload ={
      classLevelName : classLessonName,
      lessonName : lessonName
    }
    props.subChildCall(childPayload)
  },[classLessonName, lessonName])
  
  return (
    <Spin tip="Loading..." spinning={loading} size="large">
      <div className="content explain-page tab-style ">
        <div className="card-chart  card card-grey no-border">
          <div className="card-body">
            <h2><center>Smart Science<sup>&reg;</sup></center></h2>
           
            <center>
              <p>
                Answer all questions. Use any information in the graph window.<br />
                Click <b>Submit</b> when you finish.
              </p>
            </center>
            {
              initPayload?.description !== "" &&
                  <>
                  <h5> LAB DESCRIPTION </h5>
                    <hr className="hr-dark" />
                    <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div>
                  </>
            }<br/>
            {
              initPayload?.guideline !== "" && 
                <>
                    <br/>
                    <h5> LAB GUIDELINE </h5>
                    <hr className="hr-dark" />
                    <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.guideline }}></div>

                </>
              
            }
            {/* {modifiedDescription[0] !== "" &&  <Row className="">
              <Col xs="12" className=""> */}
                {/* <h3 className="mt-2 mb-2">
                  {modifiedDescription[0]}
                </h3>
                <br />
                <p>
                  {modifiedDescription[1]}
                </p> */}
                {/* <div dangerouslySetInnerHTML={{ __html: modifiedDescription }}></div>
              </Col>
            </Row>} */}
           
          </div>
        </div>

        <div className="card-chart p2 card card-grey">
          <div className="card-body">
            <Row className="pt-4">
              <Col lg="12">
                <Card className="bg-evaluate border-0 rounded-0 no-border guidelines-description-table">
                  <CardBody className="">
                    <div className="pt-4">
                       <AnswerDisplay data={{
                          type: 2,
                          payload: initPayload?.explainStepQuestions,
                        }} />
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default ExplainChild;
