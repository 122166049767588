import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'

export default function WelcomeData(props) {
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000); // Update every second

        return () => clearInterval(intervalId);
    }, []);

    const dateTimeOptions = {
        // weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        // timeZoneName: 'short', // Display the time zone name
    };
    return (
        <div className='welcome-container'>
            <Row>
                <Col md="12">
                    <h6>Welcome Back 👋</h6>
                    <h5>{props.user}</h5>
                    <text className='date-time'>{currentDateTime.toLocaleDateString(undefined, dateTimeOptions)} | {Intl.DateTimeFormat().resolvedOptions().timeZone}</text>
                </Col>
            </Row>
        </div>
    )
}
