import { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from "react-router-dom";
// reactstrap components
import { Card, CardText, CardImg, Row, Col, CardBody } from "reactstrap";
import { Button } from "react-bootstrap";
import { Tabs, Input, Table, Space, Drawer } from "antd";
import "antd/dist/antd.css";
import Create from "./Create";
import View from "./view";
import ImportTeacher from "./Import";
import jsonToken from 'jsonwebtoken'
import { disableEnableButtons } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableButtons";

function handleDownload() {
  const csvData = "First Name,Last Name,Username,Email,Password,StaffId,School,Account";
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = "CSV_FileFormate.csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function Teacher(props) {
  const [flag, setFlag] = useState(false);
  const [key, setKey] = useState(1);
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));

  useEffect(() => {
    if (props.activeTab == props.tabId) {
      var tempKey = key + 1;
      setKey(tempKey)
    }
  }, [props.activeTab])


  const onChange = (key) => {
    console.log(key);
  };
  useEffect(() => {
    setFlag(false)
  }, [props])

  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const showDrawer = () => {
    setOpen(true);
    setSize('default');
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="container-fluid admin-tabs">
        <Row>
          <Col md="12">
            <Card className="border-0">
              <CardBody className="e">
                <Row>
                  <Col md="6">
                    <h5>Teacher</h5>
                  </Col>
                  <Col md="6">
                    <div className="pull-right">
                      <Button
                        type="primary"
                        className="mr10 mb20"
                        onClick={() => setFlag(!flag)}
                        size="sm"
                      >
                        {flag ? "View" : "New"}
                      </Button>

                      <Button hidden={disableEnableButtons("Import",accessToken.roles[0])} type="primary" className="mb20" size="sm" onClick={showDrawer}>
                          Import
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {flag ? <Create a={flag} setFlag={setFlag} user_roll={accessToken.roles[0]} /> : <View key={key} h={flag} user_roll={accessToken.roles[0]} />}
                  </Col>
                </Row>
              </CardBody>
              <Drawer 
              title="Import Teachers List" 
              placement="right" 
              className="import-drawer"
              onClose={onClose} 
              destroyOnClose={true}
              open={open}
              extra={
                <Space>
                  <Button type="primary" onClick={handleDownload}>
                    Sample CSV
                  </Button>
                </Space>
              }
              >
                <ImportTeacher data={2}/>
              </Drawer>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Teacher;
