import React from 'react';
import { Chart, Line } from 'react-chartjs-2';



const options = {
  plugins: {
    legend: {
        display: false,
    },
  },
  maintainAspectRatio: false,
  responsive:true,
  // scales: {
  //   yAxes: [
  //     {
  //       ticks: {
  //         beginAtZero: true,
  //       },
  //     },
  //   ],
  // },
};

const CurveRegressionChart = ({ chartData }) => {
  // console.log({chartData})
  if (chartData?.type === 1) {
    const data = {
      labels: chartData?.datasets[0]?.xData,
      datasets: [
        {
          label: '',
          data: chartData?.datasets[0]?.yData,
          fill: false,
          borderColor: chartData?.datasets[0]?.borderColor,
          borderWidth: chartData?.datasets[0]?.borderWidth,
          lineTension: 0.4, // added lineTension property to create curved line
          pointRadius: 1, // set pointRadius to 3
          pointBackgroundColor: 'black', // set pointBackgroundColor to white
        },
      ],
    };
    return (
        <>
            <Line data={data} options={options} />
        </>
    );
  } else {
    const mixDataValue = [];
    chartData.map((x) => {
      const value = {
          type: 'scatter',
          label: '',
          showLine:true,
          data: x?.xyData.map((value, index) => ({ x: parseFloat(x.xData[index]), y: parseFloat(x.yData[index]) })),
          // fill: false,
          borderColor: x?.borderColor,
          borderWidth: x?.borderWidth,
          lineTension: 0.4, // added lineTension property to create curved line
          pointRadius: 1, // set pointRadius to 3
          pointBackgroundColor: 'black', // set pointBackgroundColor to white
      }

      mixDataValue.push(value)
    });

    const data = {
      datasets: mixDataValue,
    };

      return(
        <>
          <Chart type="bar" data={data} options={options} />
        </>
      )
    }  
};

export default CurveRegressionChart;
