import React, { useState } from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import regression from 'regression';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);


function LineRegressionChart({type, chartData, dataFromGraph }) {



    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false,
        responsive:true,
        // scales: {
        //     y: {
        //       beginAtZero: true,
        //     }
        //   },
        //         min: 0,
        //         ticks: {
        //             callback: function (value, index, ticks) {

        //                 if (chartData?.datasets == undefined || chartData?.datasets == null) return value;

        //                 if (chartData?.datasets[0]?.yLabel !== undefined) {

        //                     return + value + " " + chartData?.datasets[0]?.yLabel

        //                 } else

        //                     if (chartData[0]?.yLabel !== undefined && chartData[0]?.yLabel !== 0) {

        //                         return + value + " " + chartData[0]?.yLabel

        //                     } else
        //                         if (chartData?.datasets[0]?.ygrLabel !== undefined && chartData?.datasets[0]?.ygrLabel !== 0) {

        //                             return + value + " " + chartData?.datasets[0]?.ygrLabel;
        //                         } else
        //                             if (chartData?.datasets[0]?.ygrLabel !== undefined && chartData?.datasets[0]?.ygrLabel !== 0) {

        //                                 return value;
        //                             } else {

        //                                 return value;
        //                             }
        //             }
        //         }
        //     },

        // }

    };
    
    if (chartData?.type === 1) {
        const dataWithOutString = []
        chartData?.datasets[0]?.xyData.map((x)=>{
            dataWithOutString.push([x[0], parseFloat(x[1])])
        })
        const result = regression.linear(dataWithOutString);
        const labels = result.labels;


        let regressionData1 = [];
        // let regressionData2 = [];
        

        const fitValue = (result?.r2) * 100;
        const speed = chartData?.datasets[0]?.yData[(chartData?.labels?.length) - 1] / chartData?.datasets[0]?.xData[(chartData?.labels?.length) - 1];
        
        dataFromGraph(fitValue, speed.toFixed(3),undefined);
        
            for (let i = 0; i < chartData?.labels?.length; i++) {
                let xVal = chartData?.datasets[0]?.xData[i];
                let yVal = result?.equation[0] * xVal + result.equation[1];
    
                regressionData1.push({ x: xVal, y: yVal })
            }
        
        
        const formattedData = chartData?.datasets[0]?.xyData;
        // console.log(formattedData);
        const newData = [];
        formattedData.map(x=>{
            newData.push(x)
        })
        
        // newData.push([8,null])
        const data = {
            datasets: [
                {
                    type: 'scatter',
                    pointStyle: "star",
                    borderColor: '"black"',
                    borderWidth: 1,
                    data:newData,
                },
                {
                    type: 'line',
                    pointRadius: 0,
                    data: regressionData1,
                    borderColor: chartData?.datasets[0]?.borderColor,
                    borderWidth: chartData?.datasets[0]?.borderWidth,
                }
            ],
        };
        // console.log(data)
        return (
            <>
                <Chart type='bar' data={data} options={options} />
            </>
        );
    } else {

        const mixDataValue = [];

        chartData.map((x) => {
            const dataWithOutString = []
            x?.xyData.map((y)=>{
                dataWithOutString.push([y[0], parseFloat(y[1])])
            })
            const result = regression.linear(dataWithOutString);

            let regressionData = [];

                for (let i = 0; i < x.categoryData.length; i++) {
                    let xVal = x.xData[i];
                    let yVal = result?.equation[0] * xVal + result.equation[1];
                    regressionData.push({ x: xVal, y: yVal })
                }

            const payload1 = {
                type: 'scatter',
                pointStyle: "star",
                borderColor:  x.borderColor,
                borderWidth: 1,
                data: x.xyData,
            }


            const payload2 = {
                type: 'line',
                pointRadius: 0,
                data: regressionData,
                borderColor: x.borderColor,
                borderWidth: x.borderWidth,
            }

                mixDataValue.push(payload1)
                mixDataValue.push(payload2)

           
        });

        const data = {
            datasets: mixDataValue,
        };


        return (
            <>
                <Chart type='bar' data={data} options={options}  />
            </>
        );
    }




}

export default LineRegressionChart;