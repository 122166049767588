import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import RestService from '../../api/RestService'
import './style.scss'
import jsonToken from 'jsonwebtoken';
import { CloseOutlined } from '@ant-design/icons';
import { Spin } from 'antd';


function SubSidebarNotificationSuperAdmin({loader, onClickValues, data, keyValue}) {
        return(
        <>
            <div className="notification-container unvisited">
                {/* <div className='notification-container-hover text-right' >
                   <CloseOutlined onclick={removeLink} />
                 </div> */}
                <div className='pr-2 click' disabled={loader} onClick={()=>onClickValues(data,keyValue)}>
                <Spin tip="Loading..." spinning={false} size="large">
                    {data?.message}
                </Spin>
                </div>
            </div>
        </>
        )
       
}

export default SubSidebarNotificationSuperAdmin