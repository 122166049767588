
import Config from "./Config";
import axios from "axios";


// admin/term/getAllTerms
export const getAllTerms = async () => {
  let token = localStorage.getItem("access_token");
  // if (id !== undefined) {
  // const response = await axios.get(Config.APIURL + `admin/term/getTermBySchool/${id}`, {
  const response = await axios.get(Config.APIURL + `admin/term/getAllTerms`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;

    return data;
  })
    .catch((err) => {
      console.log("error", err);
      return Promise.reject(err.response?.data ? err.response?.data : err);
    })
  return response;
  // }
}
export const getTermList = async (page, size, search = "") => {
  let token = localStorage.getItem("access_token");
  const response = await axios.get(Config.APIURL + `admin/term/getAllTerms_paged?page=${page}&size=${size}&search=${search}`,{
    headers: { 
      "Authorization": `Bearer ${token}` 
    },
  }).then((res) => {
    const data = res.data;
    return data;
  }).catch((error) => console.log("error", error));
  
  return response;
}

//example :- /term/getTermByTermId/22
export const getTermByTermID = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await axios.get(Config.APIURL + `admin/term/getTermByTermId/${id}`, {
    headers: { 
      "Authorization": `Bearer ${token}` 
    },
  }).then((res) => {
    const data = res.data;
    return data;
  }).catch((error) => console.log("error", error));
  
  return response;
}

export const getTermBySchool = async (id, page, size, search = "") => {
  let token = localStorage.getItem("access_token");
  const encodedSearch = encodeURIComponent(search);

  const response = await axios.get(Config.APIURL + `admin/term/getTermBySchool_paged/${id}?page=${page}&size=${size}&search=${encodedSearch}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
  }).catch((error) => console.log("error", error));
  
  return response;
  
}

// term/createNewTerm
export const CreateNewTerm = async (payload) => {
  let token = localStorage.getItem("access_token");
  const response = await axios.post(Config.APIURL + `admin/term/createNewTerm`, payload, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    // console.log("st in class", data);
    return data;
  })
    .catch((err) => {
      console.log("error", err);
      return Promise.reject(err.response?.data ? err.response?.data : err);
    })
  return response;
};

// admin/term/createNewTerm
export const AddNewTerm = async (payload) => {
  let token = localStorage.getItem("access_token");
  const response = await axios.post(Config.APIURL + `admin/term/createNewTerm`, payload, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    // console.log("st in class", data);
    return data;
  })
    .catch((err) => {
      console.log("error", err);
      return Promise.reject(err.response?.data ? err.response?.data : err);
    })
  return response;
};

// api/admin/term/updateTerm
export const EditTerm = async (payload) => {
  let token = localStorage.getItem("access_token");
  const response = await axios.post(Config.APIURL + `admin/term/updateTerm`, payload, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((response) => {
    const data = response.data;
    return data;
  })
    .catch((err) => {
      console.log("error", err);
      return Promise.reject(err.response?.data ? err.response?.data : err);
    })

  return response;
};
