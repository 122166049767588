import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link, useHistory,useParams } from "react-router-dom";

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Table,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { Radio, Input, Form ,Tabs, Spin, Breadcrumb } from "antd";
import "antd/dist/antd.css";
import jsonToken from 'jsonwebtoken';
// import RestService from "../../../api/RestService";

import { getEvaluateStepByLessonIdAndLevel } from "../../../../../api/TeacherService";

import AnswerDisplay from "../AnswerDisplay";


function EvaluateChild(props) {
  const [payload, setPayload] = useState([]);
  const [containedPayload, setContainedPayload] = useState({});
  const [loading, setLoading] = useState(true);
  const [classLevelName,setClassLevelName] = useState("");
  const [lessonName, setLessonName] = useState("");
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");

  useEffect(()=>{
      if(props.complted === true || props.disabled !== undefined && props.disabled=== false ){
        setReviewStatus(false)

      }else{
        setReviewStatus(true)

      }
  },[props])

  const [initPayload, setInitPayload] = useState({
    description: "",
    evaluateStepQuestionAnswers: [],
    evaluateStepQuestions: [],
    videoUrl: "",
    id: "",
    lesson_id: "",
    guideline:"",
  });
  const [reviewStatus, setReviewStatus] = useState(props.disabled ?? true);
  let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;

  }
  useEffect( () => {
    setLoading(true)
    getEvaluateStepByLessonIdAndLevel(parseInt(props.data.lessonId), props.data.levelId)
      .then((response) => {
        if(response !== undefined){
          setInitPayload(response?.data)
          setClassLevelName(response?.data?.className);
          setLessonName(response?.data?.lessonName);
        }else{
          setValidationMsg("Corrupted Data")
          setIsModalOpen(true)
        }

        setLoading(false)
      })
  }, [props])

  let history = useHistory();
  let { id, lessonId } = useParams();

  const handleCancel1 = () => {
    window.scrollTo(0, 0);
    setIsModalOpen(false);
  };
  const handleOk1 = () => {
    history.push({
      pathname: `/Teacher/PlanLesson/${id}`
    })
  };
  const sendPayload = (value) => {
    setPayload(value);
  };

  useEffect(()=>{
    const childPayload ={
      classLevelName : classLevelName,
      lessonName : lessonName
    }
    props.subChildCall(childPayload)
  },[classLevelName, lessonName])

  return (
    <Spin tip="Loading..." spinning={loading} size="large">
          {/* < Modal isOpen={isModalOpen} toggle={handleCancel1}>
                        <ModalBody>
                          <p>{validationMsg}</p>
                        </ModalBody>
                        <ModalFooter className="center">
                          <Button
                            color="success"
                            onClick={handleOk1}
                          >
                            Go to Lesson
                          </Button>
                          <Button color="warning" onClick={handleCancel1}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal> */}
      <div className="content evaluate-page tab-style">
        <div className="card-chart  card">
          <div className="card-body">
            <h2><center>Smart Science<sup>&reg;</sup></center></h2>
            
            <center>
              <p>
                Answer all questions. Use any information in the graph window.<br />
                Click <b>Submit</b> when you finish.
              </p>
            </center>
            

            {
              initPayload?.guideline !== "" && 
                <>
                    <br/>
                    <h5> LAB GUIDELINE </h5>
                    <hr className="hr-dark" />
                    <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.guideline }}></div>

                </>
              
            }<br/>
            <Row className="">
              <Col xs="12" className="">
                <br/>
              <div className="eve-text px-4 py-4">  <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div> </div>
              </Col>
            </Row>
            {/* <div class="lab-hypothesis ex-tab active hypoex">
            <Row>
                <Col md="12">
                    <fieldset>
                    <legend><strong>Hypothesis </strong> </legend>
                    <p className="f15 hype-text">
                    "Selected Hypothesis Shown Here"
                    </p>
                    </fieldset>
                </Col>
            </Row>
                </div>
            <br/>
            <br/> */}
            <Row className="pt-4">
              <Col xs="12">
                <Card className="bg-evaluate border-0 rounded-0 guidelines-description-table">
                  <CardBody className="ml-5 mt-5 mb-5">
                    <h5> Questions</h5>
                    <hr className="black" />
                    <Row>
                      <Col xs="12">
                        <AnswerDisplay data={
                          {
                            type: 1,
                            question: initPayload?.evaluateStepQuestions,
                            answers: initPayload?.evaluateStepQuestionAnswers,
                            preData: containedPayload,
                            className:"",
                            getData: sendPayload,
                            reviewStatus: reviewStatus,
                            subtype: 1
                          }
                        } />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>


      </div>
    </Spin>
  );
}

export default EvaluateChild;
