export default function validate(obj) {
  let errors = {};  
  let values = {...obj};
  let moreBlank =/^([a-zA-Z0-9][^ ...]+\s)*[a-zA-Z0-9][^ ...]+$/g ;
  let specialChar = /^((?![-_!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+).)*$/gm;
  let doubleWhiteSpace =/\s{2,}/g

  Object.keys(values).map(k => values[k] = trimObjectStrings(k));
 
  if (!values.description) {
    errors.description = 'Description is required';
  }else if (values.description.length > 250) {
    errors.description = 'Description must be less than 250 characters';
  }

  if (!values.name) {
    errors.name = 'School Name is required';
  }else if (values.name.length > 50) {
    errors.name = 'School Name must be less than 50 characters';
  }
  // else if (!values.name.match(specialChar)) {
  //   errors.name = 'School Name can not have special characters';
  // }

  if (values.account_id < 0) {
    errors.account_id = 'Account is required';
  }

  function trimObjectStrings(key){
    if(typeof values[key] == 'string' && !key.toLowerCase().includes("password")){
      return values[key].trim();
    }
    return values[key];
  }

  return errors;
};