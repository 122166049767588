import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Tabs, Input, Table, Select, Drawer, Spin } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  Row,
  Col,
  CardBody,
  Button,
} from "reactstrap";

import Create from "./Create";
import { getSchoolData } from "../../../../api/TeacherService";
import { GetAccountData } from "../../../../api/AccountService";
import { getTermBySchool, getTermList } from "../../../../api/TermService";
import { useDispatch, useSelector } from "react-redux";
import { getTerms, editTerms } from "../../../../reducers/termSlice";
import Edit from "./Edit"
import jsonToken from 'jsonwebtoken'
import { getAccoutList } from "../../../../reducers/accountSlice";
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";

const { Option } = Select;

function TermView(props) {

  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  const columns = [
    {
      title: "Term Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Account",
    //   dataIndex: "account",
    //   key: "account",
    // },
    // {
    //   title: "School",
    //   dataIndex: "organization_name",

    //   key: "organization_name",

    // },
    {
      title: "Start Date",
      dataIndex: "started_date",
      key: "started_date",
      width: 150,
      align: "center"
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: 150,
      align: "center"
    },
    {
      title: "Action",
      render: (text, record) => <span className="btn btn-sm btn-info" onClick={() => { showLargeDrawer(record) }}>Edit</span>,
      className: "action",
      align: "center"
    },
  ];

  const [addAccount, setAddAccount] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [termData, setTermData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [accVal, setAccVal] = useState();
  const [schoolVal, setSchoolVal] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedTermEdit, setSelectedTermEdit] = useState();
  const [size, setSize] = useState();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch()
  const [disable, setDisable] = useState(false)
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [searchTermText, setSearchTermText] = useState("");


  useEffect(() => {
    dispatch(getTerms())
    dispatch(getAccoutList())
  }, [])

  useEffect(() => {
    // get account list for select dropdown
    const Allaccount = async () => {
      setLoading(true)
      const response = await GetAccountData();
      const data = await response?.data;
      setAddAccount(data.accountList);
      setLoading(false);
    }
    Allaccount();
  }, [])

  useEffect(() => {
    const schoolList = async () => {
      if (accVal !== undefined || accVal !== 0) {
        setLoading(true)
        const response = await getSchoolData(accVal);
        if (response !== undefined) {
          const data = await response?.data;
          setSchoolData(data);
          setLoading(false);
        }
      } else {
        console.log("error")
      }
    }
    schoolList();
  }, [accVal])

  useEffect(() => {
    Allterms();
  }, [visible])

  // get term list for data table
  const Allterms = async (page = currentPage, pageSize = defaultPageSize, search = searchTerm) => {
    setLoading(true);
    setTotal(0);
    await getTermList(page, pageSize, search)
      .then((response) => {
        if (response.status == 200) {
          const data = response.data.items;

          setTermData(data);
          setTotal(response.data.totalItems);
          setCurrentPage(response.data.page);

        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  useEffect(() => {
    if (accessToken.roles[0] === "SCHOOL_ADMIN" || accessToken.roles[0] === "ACCOUNT_ADMIN") {
      if (addAccount && addAccount.length === 1) {
        setAccVal(addAccount[0].id);
      }
    }
  }, [accessToken])


  const onChangeAccount = (value) => {
    setCurrentPage(1);
    setSchoolVal();
    setAccVal(value);
    setSearchTermText("");
  }

  const onChangeSchool = (value) => {
    setCurrentPage(1);
    setSchoolVal(value);
    setSearchTerm("");
    setSearchTermText("");
  }

  const onClose = () => {
    setVisible(false);
  };

  const showLargeDrawer = (record) => {
    setSelectedTermEdit({ ...record, accountId: accVal, schoolId: schoolVal });

    dispatch(editTerms({ ...record, accountId: accVal, schoolId: schoolVal }))
    setVisible(true);
    setSize("large");
  };

  const searchTable = (searchtext) => {
    setCurrentPage(1);
    setSearchTerm(searchtext);
    setSearchTermText(searchtext)
    setCurrentPage(1);
    Allterms(1, defaultPageSize, searchtext)
  }

  return (
    <>
      <div>
        <div className="my-5">
              {/* top search bar */}
              <Row>
                <Col md="3"></Col>
                <Col lg="6" sm="12">
                  <CardBody className="my-3">
                    <Input
                      size="large"
                      placeholder="Search"
                      onChange={(e) => searchTable(e.target.value)}
                      prefix={<SearchOutlined className="mx-3" />}
                      value={searchTermText}
                    />
                  </CardBody>
                </Col>
              </Row>

              {/* All term list */}
              <Row>
                <Col md="12">
                  {/* account - {addaccount} */}
                  <Table
                    dataSource={termData}
                    // dataSource={termData}
                    //pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                    pagination={{
                      current: currentPage,
                      position: ["bottomCenter"],
                      total: total, // Total number of items in the API response (you may need to fetch this dynamically)
                      pageSize: defaultPageSize, // Number of items to display per page
                      onChange: (page, pageSize) => Allterms(page, pageSize), // Callback function to handle page changes
                      showSizeChanger: false
                    }}
                    loading={loading}
                    columns={columns}
                    className="thead-dark admin-table"
                  />
                </Col>
              </Row>
              <Drawer
                className="medium"
                title={`Edit Term`}
                placement="right"
                size={size}
                destroyOnClose={true}
                onClose={onClose}
                visible={visible}
              >
                {/* <Create /> */}
                <Edit
                  selectedTermId={selectedTermEdit?.id}
                  selectedTermName={selectedTermEdit?.name}
                  selectedTermData={selectedTermEdit}
                  onClose={onClose}
                  getaccVal={accVal}
                  getschoolVal={schoolVal}
                  user_roll={props.user_roll}
                />
              </Drawer>
            
        </div>
      </div>
    </>
  );
}

export default TermView;
