class MathCalculationService {

    // @define function with n params

    computationExample(data1, data2){
            return data1 + data2
    }

    // for browning motion 
    // root of xValue square + xValue square
    simpleLine(mouseX, mouseY, initialValueY, initialValueX,intervalGraph, absoluteCalculation){
        // console.log(initialValueY, initialValueX)
        if(initialValueY === 0){
            return ({value:0, initialValueX:mouseX, initialValueY:mouseY})
        }else{
            if(absoluteCalculation === false){
                const xDistance =mouseX - initialValueX;
                const yDistance = mouseY - initialValueY;
                const value = Math.sqrt( Math.pow(xDistance, 2) + Math.pow(yDistance, 2)).toFixed(2)
                return ({value:value, initialValueX:mouseX, initialValueY:mouseY})
            }else{
                const xDistance = Math.abs(mouseX - initialValueX);
                const yDistance = Math.abs(mouseY - initialValueY);
                const value = Math.sqrt( Math.pow(xDistance, 2) + Math.pow(yDistance, 2)).toFixed(2)
                return ({value:value, initialValueX:mouseX, initialValueY:mouseY})
            }
        }
    }

    simpleVolumeLine(mouseX, mouseY, initialValueY, initialValueX,intervalGraph, absoluteCalculation){
        // console.log(initialValueY, initialValueX)
        if(initialValueY === 0){
            return ({value:0, initialValueX:mouseX, initialValueY:mouseY})
        }else{
            if(absoluteCalculation === false){
                const xDistance = mouseX - initialValueX;
                const yDistance = mouseY - initialValueY;
                const value = Math.sqrt( Math.pow(xDistance, 2) + Math.pow(yDistance, 2)).toFixed(2)
                return ({value:value, initialValueX:mouseX, initialValueY:mouseY})
            }else{
                const xDistance = Math.abs(mouseX - initialValueX);
                const yDistance = Math.abs(mouseY - initialValueY);
                const value = Math.sqrt( Math.pow(xDistance, 2) + Math.pow(yDistance, 2)).toFixed(2)
                return ({value:value, initialValueX:mouseX, initialValueY:mouseY})
            }
        }
    }

    LineForceDefined(initialYValue, mouseY, absoluteCalculation,directionValue){
        if(absoluteCalculation === false){
            if(directionValue === "1"){
                const yDistance = ((mouseY - initialYValue)/10).toFixed(2);
                return ({value:yDistance})
            }else if(directionValue === "2"){
                const yDistance = ((initialYValue - mouseY)/10).toFixed(2);
                return ({value:yDistance})
            }else{
                const yDistance = ((mouseY - initialYValue)/10).toFixed(2);
                return ({value:yDistance})
            }
        }else{
            if(directionValue === "1"){
                const yDistance = Math.abs((mouseY - initialYValue)/10).toFixed(2);
                return ({value:yDistance})
            }else if(directionValue === "2"){
                const yDistance = Math.abs((initialYValue - mouseY)/10).toFixed(2);
                return ({value:yDistance})
            }else{
                const yDistance = Math.abs((mouseY - initialYValue)/10).toFixed(2);
                return ({value:yDistance})
            }
        }
    }

    LineTemperatureDefined(initialYDifference,initialYValue, mouseY, range ){
        const yDifference = initialYValue - mouseY;
        const value = ((range /initialYDifference)* yDifference).toFixed(2);
        return ({value:value})
    }

    LineRadialDefined(iniX,iniY, mouseY, mouseX,zeroX,zeroY){

        // const a = Math.sqrt( Math.pow((iniX-zeroX), 2) + Math.pow((iniY-zeroY), 2)).toFixed(2)
        // const b = Math.sqrt( Math.pow((mouseX-zeroX), 2) + Math.pow((mouseY-zeroY), 2)).toFixed(2)
        // const c = Math.sqrt( Math.pow((iniX-mouseX), 2) + Math.pow((iniY-mouseY), 2)).toFixed(2)

        // console.log(a,b,c)

        // const aTheta = ((Math.acos((Math.pow(b,2) - Math.pow(a,2) - Math.pow(c,2))/ (-2 * a * c)))).toFixed(2)

        // console.log(aTheta * 180 / Math.PI) 

        let inRads = Math.atan2(iniY - zeroY, iniX  - zeroX) - Math.atan2( mouseY - zeroY, mouseX - zeroX);

        if (inRads < 0)
        inRads = Math.abs(inRads);
        else
        inRads = 2 * Math.PI - inRads;

        function radians_to_degrees(radians){
            var pi = Math.PI;
            return radians * (180/pi);
            }
        const angle = radians_to_degrees(inRads);

        const gaugeAngle = parseInt(angle * (105/360))

        let finalAngle = 0
        
        if(gaugeAngle > 84){
            const negativeValue = gaugeAngle - 105;
            finalAngle = negativeValue * (1000/20);
        }else{
            if(gaugeAngle < 61){
                finalAngle = gaugeAngle * (1000/20);
            }else{
                finalAngle = 0;
            }
        }
        return ({value:finalAngle})
    }

    LineOfBestFitXForward(mouseX, initialValueX, absoluteCalculation){
        if(initialValueX === 0){
            return ({value:0, initialValueX:mouseX})
        }else{
            if(absoluteCalculation === false){
                const value = mouseX - initialValueX;
                return ({value:value, initialValueX:mouseX})
            }else{
                const value = Math.abs(mouseX - initialValueX);
                return ({value:value, initialValueX:mouseX})
            }
            
        }
    }

}


export default new MathCalculationService();
