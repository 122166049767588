import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link, useParams } from "react-router-dom";
import jsonToken from 'jsonwebtoken'
import ChartValidations from "../Explore/childComponent/ChartValidations"
// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Table,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Breadcrumb, Radio, Input, Form, Modal, Button, Tabs, Checkbox, Image, Spin } from "antd";
import "antd/dist/antd.css";
import img1 from "../../../assets/images/img1.jpg";
import x_axis from "../../../assets/images/x-axis.svg";
import y_axis from "../../../assets/images/y-axis.svg";
import graph from "../../../assets/images/graph.svg";
import Video from "../../../components/Video";
import up from "../../../assets/images/graph-up.svg";

import LabDescription from "../../../components/Questions/LabDescription";
import Answers from "./answers";
import AnswersReview from "./answers-review";

import LineChart from "../../../components/Charts/LineChart";
import { UserData } from "../../../components/Charts/Data";
import RestService from "../../../api/RestService";
import LineRegressionChart from "../../../components/Charts/LineRegressionChart";
import CoordinatesValues from "../Explore/childComponent/coordinatesValues";
import AnswerDisplay from "../AnswerDisplay";
import FloatingButton from "../../../components/FloatingButton";
import { useSelector } from "react-redux";
import LessonService from "../../../api/LessonService";

function Evaluate(props) {

  const [selectValue, setSelectValue] = useState()
  const [counter, setCounter] = useState(0);
  const [reviewStatus, setReviewStatus] = useState(props.disabled ?? true);
  const [payload, setPayload] = useState([]);
  const [containedPayload, setContainedPayload] = useState({});
  const [cellId, setCellId] = useState([]);
  const [selectOption, setSelectOption] = useState([]);
  const [allGraphValue, setAllGraphValue] = useState("");
  const [checked, setChecked] = useState(false);
  const [clickState, setClickState] = useState(2);

  const [xLabel, setXLabel] = useState();
  const [yLabel, setYLabel] = useState();
  const [xgrLabel, setXgrLabel] = useState();
  const [ygrLabel, setYgrLabel] = useState();
  const [lavelData, setLavelData] = useState();

  const [lesson, setLesson] = useState(parseInt(props.evaluateId));
  const [lesson2, setLesson2] = useState(parseInt(props.exploreId));
  const [loading, setLoading] = useState(true);
  const [datasets, setDatasets] = useState([
    {
      label: "",
      yData: [],
      xData: [],
      color: ""
    }
  ]);
  const [classLessonName, setClassLessonName] = useState("");
  const [lessonCode, setLessonCode] = useState("")
  const [lessonName, setLessonName] = useState("");
  const [mappingId, setMappingId] = useState("");

  const TSdate = Date.now();
  const dateFormat = new Date(TSdate);
  const MonthYear = dateFormat.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
  const HrsMinSec = dateFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });

  const [initPayload, setInitPayload] = useState({
    description: "",
    evaluateStepQuestionAnswers: [],
    evaluateStepQuestions: [],
    id: "",
    lesson_id: "",
    exploreStepResponses: "",
    experiments: [],
    lessonCode: "",
    chartType: ""
  });

  let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;

  }

  const [responseData, setResponseData] = useState({
    borderColor: "",
    borderWidth: "",
    labels: "",
    xData: [],
    yData: [],
    xyData: [],
    categoryData: [],
    cod: "",
    speed: "",
    speed2: "",
  })
  const [responseMixData, setResponseMixData] = useState([]);
  const [graphMixStatus, setGraphMixStatus] = useState(false);
  const [yLabelUnit, setYLabelUnit] = useState();
  const [xLabelUnit, setXLabelUnit] = useState();
  const [definedHypothesis, setDefinedHypothesis] = useState("");

  const Label = useSelector(state => state?.graph?.GraphDataTable)


  useEffect(() => {

    const dataSendToMap = Label[0]?.exploreStepExperiment;
    setLavelData(dataSendToMap)
    dataSendToMap?.map(x => {
      if (x.name[0].value === selectValue) {

        let xValue = " ";
        let yValue = " ";
        if (x.x !== null && x.y !== null) {
          if (x.x.SI !== "") {
            xValue = `(${x.x.SI})`;
          }
          if (x.y.SI !== "") {
            yValue = `(${x.y.SI})`;
          }


          setXLabel(`${x.x.Value} ${xValue}`)
          setYLabel(`${x.y.Value} ${yValue}`)
          setXgrLabel(x.x?.SI)
          setYgrLabel(x.y?.SI)
        }
      }
    })

  }, [selectValue])

  // console.log(ygrLabel)

  useEffect(() => {
    if (props.complted === true || props.disabled !== undefined && props.disabled === false) {
      setReviewStatus(false)

    } else {
      setReviewStatus(true)

    }
  }, [props])
  useEffect(() => {


    if (datasets && datasets.length > 0) {
      datasets.map(x => {
        if (x.yData.length == 0) {
          setDatasets(datasets.filter(x => x.yData.length > 0))
        }
      })
    }
  }, [datasets])
  useEffect(() => {
    setLoading(true)
    RestService.getEvaluateStepInfoByEvaluateStepId(lesson, props.username, lesson2)
      .then((response) => {
        if (counter === 0) {

          setInitPayload(response?.data)
          setLoading(false)
          setCounter(1);
          setClassLessonName(response?.data?.className);
          setLessonName(response?.data?.lessonName);


        } else {

          const secondSendPayload = {
            description: initPayload.description,
            evaluateStepQuestionAnswers: initPayload.evaluateStepQuestionAnswers,
            evaluateStepQuestions: initPayload.evaluateStepQuestions,
            exploreStepResponses: response.data.exploreStepResponses,
            experiments: response.data.experiments,
            id: initPayload.id,
            lesson_id: initPayload.lesson_id,
            lessonCode: initPayload.lessonCode,
            chartType: initPayload.chartType

          }
          setInitPayload(secondSendPayload)
          setLoading(false)
        }


      })
  }, [props, reviewStatus])

  // console.log(initPayload)

  function convert(data) {
    return Array.isArray(data)
      ? data.reduce((obj, el, i) => (el + 1 && (obj[i] = convert(el)), obj), {})
      : data;
  }

  useEffect(() => {

    setLoading(true)
    RestService.getEvaluateStepByIdAndUserName(props.username, lesson)
      .then((response) => {
        if (response) {
          setContainedPayload(convert(response?.data[0]?.response_json.answers))
          setLoading(false)
        }
      })
  }, [props, reviewStatus])

  const { TabPane } = Tabs;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const sendPayload = (value) => {
    setPayload(value);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const { TextArea } = Input;
  const [value, setValue] = useState(1);

  const ReviewFun = () => {
    setReviewStatus(false)
    window.scrollTo(0, 0)
  };

  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.distance),
    datasets: [
      {
        data: UserData.map((data) => data.time),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
        options: {
          plugins: {
            title: {
              display: true,
              text: "",
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "Distance",
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Time",
              },
            },
          },
        },
        label: "",
      },
    ],
  });


  // console.log(initPayload)
  useEffect(() => {
    setDefinedHypothesis(initPayload?.exploreStepResponses[0]?.response_jason?.hypothesis)
    setResponseMixData([]);
    setResponseData({
      borderColor: "",
      label: selectValue,
      borderWidth: "",
      labels: "",
      xData: [],
      yData: [],
      xyData: [],
      categoryData: [],
      cod: "",
      speed: "",
      speed2: "",
      userMainHypothesis: "",
    });
    if (Object.keys(cellId).length > 0 && Object.keys(cellId).length === selectOption.length) {
      setChecked(true)
    }
    // console.log(initPayload?.exploreStepResponses[0])
    if (initPayload?.exploreStepResponses[0]?.response_jason?.answers !== null && initPayload?.exploreStepResponses[0]?.response_jason?.answers !== undefined) {
      if (cellId.length !== 0 && cellId !== undefined && cellId !== null) {
        const filteredList = Object.keys(initPayload?.exploreStepResponses[0]?.response_jason?.answers)
          .filter(key => Object.values(cellId).includes(key))
          .reduce((obj, key) => {
            obj[key] = initPayload?.exploreStepResponses[0]?.response_jason?.answers[key];
            return obj;
          }, {});
        if (Object.values(filteredList).length <= 1) {
          setGraphMixStatus(false)

          if (Object.values(filteredList)[0] !== undefined && Object.keys(filteredList)[0] !== null) {
            const responsePayload = {
              borderColor: Object.values(filteredList)[0].borderColor,
              borderWidth: Object.values(filteredList)[0].borderWidth,
              labels: Object.values(filteredList)[0].label,
              xgrLabel: xgrLabel,
              ygrLabel: ygrLabel,
              xData: Object.values(filteredList)[0].xData,
              yData: Object.values(filteredList)[0].yData,
              xyData: Object.values(filteredList)[0].xyData,
              categoryData: Object.values(filteredList)[0].categoryData,
              cod: Object.values(filteredList)[0].cod,
              speed: Object.values(filteredList)[0].speed,
              speed2: Object.values(filteredList)[0].speed2,
            }
            // setDatasets([{
            //   label:Object.values(filteredList)[0].label,
            //   yData:Object.values(filteredList)[0].yData,
            //   xData:Object.values(filteredList)[0].xData
            // }])
            setResponseData(responsePayload);
          }

        } else {
          setResponseMixData([]);
          setGraphMixStatus(true);
          for (let k = 0; k < Object.values(filteredList).length; k++) {
            if (Object.values(filteredList)[k] !== undefined && Object.keys(filteredList)[k] !== null) {
              const responsePayload = {
                borderColor: Object.values(filteredList)[k].borderColor,
                borderWidth: Object.values(filteredList)[k].borderWidth,
                labels: Object.values(filteredList)[k].displayLabel,
                xgrLabel: xgrLabel,
                ygrLabel: ygrLabel,
                xData: Object.values(filteredList)[k].xData,
                yData: Object.values(filteredList)[k].yData,
                xyData: Object.values(filteredList)[k].xyData,
                categoryData: Object.values(filteredList)[k].categoryData,
                cod: Object.values(filteredList)[k].cod,
                speed: Object.values(filteredList)[k].speed,
                speed2: Object.values(filteredList)[k].speed2,
                lesson: lesson2
              }
              setResponseMixData((preData) => [...preData, responsePayload]);
              // setDatasets([{
              //   label:Object.values(filteredList)[k].label,
              //   yData:Object.values(filteredList)[k].yData,
              //   xData:Object.values(filteredList)[k].xData
              // }])
            }

          }
        }




      }

      let arr = [];
      // console.log(initPayload)
      if (selectOption.length !== Object.keys(initPayload?.exploreStepResponses[0].response_jason?.answers).length) {
        Object.keys(initPayload?.exploreStepResponses[0].response_jason?.answers).map(x => {
          arr.push({
            name: x,
            isChecked: false,
            color: initPayload?.exploreStepResponses[0].response_jason?.answers[x].borderColor,
            displayLabel: initPayload?.exploreStepResponses[0].response_jason?.answers[x].displayLabel,
            mappingId: initPayload?.exploreStepResponses[0].response_jason?.answers[x].mappingId,
          })


        })
        setSelectOption(arr)
      }




    }
  }, [cellId, initPayload]);

  useEffect(() => {
    if (initPayload?.exploreStepResponses[0]?.response_jason?.answers !== null && initPayload?.exploreStepResponses[0]?.response_jason?.answers !== undefined) {

      if (selectOption.length !== Object.keys(initPayload?.exploreStepResponses[0]?.response_jason?.answers).length) {
        Object.keys(initPayload?.exploreStepResponses[0]?.response_jason?.answers).map(x => {
          if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].mappingId !== undefined && initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].mappingId !== "") {
            // console.log(initPayload?.exploreStepResponses[0]?.response_jason?.answers[x])
            setMappingId(initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].mappingId)
          }

        })
      }

    }
  }, [initPayload])
  // console.log(mappingId, initPayload?.chartType[mappingId], initPayload?.chartType)

  // const graphdata1 = {
  //   labels: responseData.categoryData,
  //   label: selectValue,
  //   lesson: initPayload.lesson_id,
  //   type: responseData.type || 1,
  //   chartType: initPayload?.chartType[mappingId]?.graphType,
  //   datasets: [
  //     {
  //       label: responseData.labels,
  //       xgrLabel: xgrLabel,
  //       ygrLabel: ygrLabel,
  //       xData: responseData.xData,
  //       yData: responseData.yData,
  //       xyData: responseData.xyData,
  //       borderColor: responseData.borderColor,
  //       borderWidth: responseData.borderWidth,
  //     },
  //   ],
  // };

  // const dataFromGraph = (fitValue, speed) => {
  // };
  // const tablePayload = {
  //   type: graphMixStatus,
  //   labels: initPayload?.exploreStepResponses[0]?.response_jason.answers[selectValue]?.displayLabel,
  //   chartType: initPayload?.chartType[mappingId]?.graphType,
  //   xData: initPayload?.chartType[mappingId]?.graphType === "curve" ? responseData.xData : responseData.yData,
  //   yData: initPayload?.chartType[mappingId]?.graphType === "curve" ? responseData.yData : responseData.xData,
  //   speed: responseData.speed,
  //   speed2: responseData.speed2,
  //   cod: responseData.cod,
  // };

  // const viewData = datasets.map(i => {
  //   return {
  //     labels: i.label,
  //     type: 2,
  //     xData: i.yData.length > 0 ? i.xData : [],
  //     yData: i.yData.length > 0 ? i.yData : [],
  //   }
  // })

  // // @to-do labes dynamic
  // const secondLessonTablePayload = {
  //   // labels: [1, 2, 3, 4, 5, 6],
  //   labels: datasets[0]?.xData,
  //   type: 1,
  //   datasets: datasets

  // }
  // // console.log(initPayload)
  // const thirdLessonTablePayload = {
  //   labels: initPayload?.chartType[mappingId]?.graphType === "Bar YesNo" ? datasets.map(i => i.xData
  //   )[0] : datasets.map(i => {
  //     return i.xData
  //   }),
  //   type: 3,
  //   yLabel: ygrLabel,
  //   datasets: datasets.map(i => {
  //     return {
  //       label: i.label,
  //       yData: i.yData,
  //       borderColor: initPayload?.chartType[mappingId]?.graphType === "Bar YesNo" ? i.borderColor : i.color,
  //     }
  //   })
  // }

  // // console.log(thirdLessonTablePayload)
  // const onChange = (e) => {

  //   setValue(e.target.value);
  // };

  // const submitFun = async () => {
  //   // @todo username should be changed

  //   const submitPayload = {

  //     username: user,
  //     userId: initPayload.id,
  //     status: 2,
  //     response_json: {
  //       answers: Object.values(payload)
  //     },
  //     last_update_date: new Date().toISOString().slice(0, 10),
  //     evaluate_step_id: lesson,
  //   }


  //   // @todo should succesfully completed out here
  //   await RestService.postEvaluateStepUserResponses(submitPayload)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         props.activatingRS();
  //         window.scrollTo(0, 0)
  //         props.submitTrigger("3");
  //       }
  //     })
  // }
  // const changeHandler = (event) => {
  //   setClickState(1);
  //   const check = event.target.checked;
  //   const name = event.target.name;
  //   setSelectValue(name);


  //   if (check === true) {
  //     setChecked(false)
  //     selectOption.map(x => {
  //       if (x.name === name) {
  //         x.isChecked = true
  //       }
  //     })
  //     setClickState(1)

  //     setSelectOption(selectOption)

  //     if (initPayload?.chartType[mappingId]?.graphType === "curve") {
  //       // console.log("yo come here")

  //       if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {

  //         if (datasets.findIndex(x => x.label === name) !== -1) {

  //           datasets.filter(x => x.label === name)
  //           setDatasets(datasets)
  //         }
  //         else {
  //           setDatasets(prev => (
  //             [
  //               ...prev,
  //               {
  //                 label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].displayLabel,
  //                 yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData,
  //                 color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].borderColor,
  //                 xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData
  //               }
  //             ]
  //           ))
  //         }

  //       }
  //     } else if (initPayload?.chartType[mappingId]?.graphType === "Bar YesNo") {
  //       if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {

  //         if (datasets.findIndex(x => x.label === name) !== -1) {

  //           datasets.filter(x => x.label === name)
  //           setDatasets(datasets)
  //         }
  //         else {
  //           setDatasets(prev => (
  //             [
  //               ...prev,
  //               {
  //                 label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].displayLabel,
  //                 yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData,
  //                 color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(item => {
  //                   return item?.color
  //                 }),
  //                 borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].borderColor,

  //                 xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData
  //               }
  //             ]
  //           ))
  //         }

  //       }
  //     }
  //     else {
  //       if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {

  //         if (datasets.findIndex(x => x.label === name) !== -1) {

  //           datasets.filter(x => x.label === name)
  //           setDatasets(datasets)
  //         }
  //         else {


  //           setDatasets(prev => (
  //             [
  //               ...prev,
  //               {
  //                 label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].displayLabel,
  //                 yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(i => {
  //                   return i?.value
  //                 }),
  //                 color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(i => {
  //                   return i?.color
  //                 }),
  //                 xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData,
  //                 borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].borderColor
  //               }
  //             ]
  //           ))
  //         }

  //       }
  //     }

  //     setCellId(prev => ({
  //       ...prev,
  //       [name]: name
  //     }))
  //   } else {
  //     // console.log("Tst",datasets.filter(x => x.label !== name))

  //     setDatasets(datasets.filter(x => x.label !== name))
  //     selectOption.map(x => {
  //       if (x.name === name) {
  //         x.isChecked = false
  //       }
  //     })
  //     if (selectOption.findIndex(x => x.isChecked === true) > -1) {

  //     }
  //     else {
  //       // console.log("Hello world")
  //       setClickState(2)
  //     }

  //     setSelectOption(selectOption)
  //     setChecked(false)
  //     delete cellId[name];
  //     setCellId(prev => ({
  //       ...prev,
  //     }))
  //   }
  // }


  // const changeHandler3 = (event) => {
  //   const check = event.target.checked;
  //   const name = event.target.name;


  //   if (check === true) {
  //     selectOption.map(x => {
  //       x.isChecked = true
  //     });
  //     setClickState(2)
  //     setSelectOption(selectOption)
  //     // console.log(selectOption)
  //     setSelectValue(selectOption[0].name);
  //     setChecked(true)
  //     name.map((x) => {
  //       if (initPayload?.chartType[mappingId]?.graphType === "curve") {
  //         if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label === x) {
  //           if (datasets.findIndex(x => x.label === x) !== -1) {


  //           }
  //           else {

  //             setDatasets(prev => (
  //               [
  //                 ...prev,
  //                 {
  //                   label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].displayLabel,
  //                   yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData,
  //                   color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].borderColor,
  //                   xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].xData
  //                 }
  //               ]
  //             ))


  //           }
  //         }
  //       } else if (initPayload?.chartType[mappingId]?.graphType === "Bar YesNo") {
  //         if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label === x) {
  //           if (datasets.findIndex(x => x.label === x) !== -1) {


  //           }
  //           else {
  //             // console.log(initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].borderColor)

  //             setDatasets(prev => (
  //               [
  //                 ...prev,
  //                 {
  //                   label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].displayLabel,
  //                   yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
  //                     return i
  //                   }),
  //                   color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
  //                     return i?.color
  //                   }),
  //                   xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].xData,
  //                   borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].borderColor,

  //                 }
  //               ]
  //             ))


  //           }
  //         }
  //       }
  //       else {
  //         if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label === x) {
  //           if (datasets.findIndex(x => x.label === x) !== -1) {


  //           }
  //           else {

  //             setDatasets(prev => (
  //               [
  //                 ...prev,
  //                 {
  //                   label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].displayLabel,
  //                   yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
  //                     return i?.value
  //                   }),
  //                   color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
  //                     return i?.color
  //                   }),
  //                   xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].xData,
  //                   borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].borderColor,

  //                 }
  //               ]
  //             ))


  //           }
  //         }
  //       }
  //       setCellId(prev => ({
  //         ...prev,
  //         [x]: x
  //       }))
  //     })
  //   } else {
  //     setDatasets([{
  //       label: null,
  //       yData: [],
  //       xData: []

  //     }])
  //     selectOption.map(x => {
  //       x.isChecked = false
  //     });

  //     setSelectOption(selectOption)
  //     setChecked(false)
  //     setGraphMixStatus(false)
  //     setCellId("");
  //   }
  // }


  useEffect(() => {
    const createPayloadForAll = [];
    selectOption.map((x) => {
      createPayloadForAll.push(x.name)
    });
    setAllGraphValue(createPayloadForAll)
  }, [selectOption]);


  // console.log(selectValue)
  // console.log(datasets)
  // console.log(initPayload.chartType)

  return (
    <Spin tip="Loading..." spinning={loading} size="large">

      <div className="content evaluate-page tab-style scroll-tab">
        <div className="card-chart  card">
          <div className="card-body">
            
            <div class="lab-hypothesis ex-tab active hypoex">
              <Row>
                <Col md="12">
                  <fieldset>
                    <legend><strong>Hypothesis </strong> </legend>
                    <p className="f15 hype-text">
                      {definedHypothesis}
                    </p>
                  </fieldset>
                </Col>
              </Row>


            </div>
            <br />

            <br /><br /><br />
            <Row className="pt-4">
              <Col xs="12">
                <Card className="bg-evaluate border-0 rounded-0">
                  <CardBody className="ml-5 mt-5 mb-5">
                    <h5> Questions</h5>
                    <hr className="black" />
                    <Row>
                      <Col xs="12">
                        <AnswerDisplay data={
                          {
                            type: 1,
                            question: initPayload.evaluateStepQuestions,
                            answers: initPayload.evaluateStepQuestionAnswers,
                            preData: containedPayload,
                            getData: sendPayload,
                            reviewStatus: false,
                            subtype: 1
                          }
                        } />
                        {/* <Answers question={initPayload.evaluateStepQuestions} answers={initPayload.evaluateStepQuestionAnswers} preData={containedPayload} getData={sendPayload}/> */}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>


            {!reviewStatus ? (<Row className="">
              <Col xs="12" className="">
                <br />
                <div className="eve-text px-4 py-4 guidelines-description-table">  <div dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div> </div>
              </Col>
            </Row>) : ("")}

          </div>
        </div>


      </div>
    </Spin>
  );
}

export default Evaluate;
