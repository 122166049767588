import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Radio, Input, Select } from 'antd'
import { Button } from 'reactstrap'
import Hypothesis from '../components/Hypothesis'
import { CalculationModule, GraphModule, TableValue } from '../../../Module/ChartType.Module'
import DataTableValues from "../components/DataTableValues"

function HypoAndTable(props) {
    const [formLayout, setFormLayout] = useState('horizontal');
    const [disabled,setDisabled] = useState(false);

    useEffect(()=>{
        setDisabled(true)
    },[])

    return (
        <>
        <div className='container'>
            <Row className='question-headder-style px--15px'>
                <Col md={24} className='px-3' >
                    <b>Hypothesis</b>
                </Col>
            </Row>
            <div>
                <Hypothesis type={props.type} explore_id={props.explore_id} disabled={disabled} GoToQuestionTab={props.GoToQuestionTab}  back = {props.back} />
            </div>
            </div>
        </>
    )
}

export default HypoAndTable