import axios from "axios";
import Config from "./Config";


class RestService {

  //getMyClasses
  getMyClasses() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'studentservice/getMyClasses', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  //getLessonsByClass
  getLessonsByClass(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'studentservice/getLessonsByClass/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  //explore tab
  getExploreStepInfoById(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'studentservice/getExploreStepInfoById/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  getTeacherEvaluateData() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/teacher/getTeacherEvaluateData', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }
  getSchoolData() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/teacher/getSchoolData', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  getTeacherEvaluateDataPaged(page, size) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + `admin/teacher/getTeacherEvaluateData_paged?page=${page}&size=${size}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  getTeacherEvaluateOrganizationScreen(id) {
    const token = localStorage.getItem("access_token");
    let pass = "";
    if (id === "-1") {
      pass = "";
    } else {
      pass = id;
    }

    return axios.get(Config.APIURL + 'admin/teacher/teacherEvaluateOrganizationScreen?organizationId=' + pass, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  getOrganizationForRoles() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/teacher/getOrganizationForRoles', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  getTeachers(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/teacher/getInstructorsByOrgId/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }
  getClassesByTeacherId(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/teacher/getClassesByTeacherId/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })

  }
  getLessonsByClassId(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/teacher/getLessonsByClassId/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })

  }
  getEvaluatedataforStudents(teacherid, classid, lessonId) {
    var queryString = `admin/teacher/instructor-list?teacherId=${teacherid}`;
    if (classid && classid > 0) {
      queryString = queryString + `&classId=${classid}`

      if (lessonId && lessonId > 0) {
        queryString = queryString + `&lessonId=${lessonId}`
      }
    }
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + queryString, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })

  }

  getAllStepMarks(engageId, evaluateId, explainId, extendId, name) {
    const encodedUsername = encodeURIComponent(name);

    var queryString = `admin/teacher/getAllStepMarks?engageStepId=${engageId !== null ? engageId : 0}&evaluateStepId=${evaluateId !== null ? evaluateId : 0}&explainStepId=${explainId != null ? explainId : 0}&extendStepId=${extendId !== null ? extendId : 0}&userName=${encodedUsername}`;
    console.log("res1", queryString)
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + queryString, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  getStudentMarks(step, dt, name) {
    console.log("res", step, dt, name)

    var value = ""

    if (dt === 1) {

      value = `engageStepId=${step}`

    }
    if (dt === 2) {

      value = `evaluateStepId=${step}`
    }
    if (dt === 3) {

      value = `explainStepId=${step}`
    }
    if (dt === 4) {

      value = `extendStepId=${step}`
    }




    if (value !== "") {
      console.log("ok")

      var queryString = `admin/teacher/getAllStepMarks?${value}&userName=${name}`;
      console.log("res1", queryString)
      const token = localStorage.getItem("access_token");
      return axios.get(Config.APIURL + queryString, { headers: { "Authorization": `Bearer ${token}` } })
        .then((res) => {
          const data = res.data;
          return data;
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })

    }



  }

  postExploreStepStatus(userData) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'studentservice/postExploreStepUserStatus/', userData, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  getExploreStepUserStatusByIdAnduserName(username, id) {
    const token = localStorage.getItem("access_token");
    const encodedUsername = encodeURIComponent(username);

    return axios.get(Config.APIURL + 'studentservice/getExploreStepUserStatusByUserName?name=' + encodedUsername + "&id=" + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
      })
  }

  //engage tab
  getEngageStepInfoById(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'studentservice/getAllEngageStepInfosById/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
      })
  }

  postEngageStepUserResponses(userData, classId, lessonId) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + `studentservice/postEngageStepUserResponses?classId=${classId}&lessonId=${lessonId}`, userData, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  getEngageStepByIdAndUserName(username, id) {
    const token = localStorage.getItem("access_token");
    const encodedUsername = encodeURIComponent(username);

    return axios.get(Config.APIURL + 'studentservice/getEngageStepByIdAndUserName?name=' + encodedUsername + "&id=" + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
      })
  }


  //evaluate tab
  getEvaluateStepInfosById(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'studentservice/getEvaluateStepByEvaluateStepId/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
      })
  }

  // getEvaluateStepInfoByEvaluateStepId
  getEvaluateStepInfoByEvaluateStepId(id, userName, expId) {
    const encodedUsername = encodeURIComponent(userName);

    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'studentservice/getEvaluateStepInfoByEvaluateStepId?id=' + id + "&name=" + encodedUsername + "&exploreStepId=" + expId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
      })
  }

  postEvaluateStepUserResponses(userData, classId, lessonId) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + `studentservice/postEvaluateStepUserResponses?classId=${classId}&lessonId=${lessonId}`, userData, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }
  postEngageMarks(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/teacher/postEngageStepUserMarks', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }
  postEvaluateMarks(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/teacher/postEvaluateStepUserMarks', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }
  postExplainMarks(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/teacher/postExplainStepUserMarks', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }
  postExtendMarks(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/teacher/postExtendStepUserMarks', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  getEvaluateStepByIdAndUserName(username, id) {
    const token = localStorage.getItem("access_token");
    const encodedUsername = encodeURIComponent(username);

    return axios.get(Config.APIURL + 'studentservice/getEvaluateStepByIdAndUserName?name=' + encodedUsername + "&id=" + id , { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
      })
  }

  //explain tab
  getExplainStepInfosById(id, userName, expId) {
    const token = localStorage.getItem("access_token");
    const encodedUsername = encodeURIComponent(userName);

    return axios.get(Config.APIURL + 'studentservice/getAllExplainStepInfos?id=' + id + "&name=" + encodedUsername + "&exploreStepId=" + expId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
      })
  }

  postExplainStepUserResponses(userData) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'studentservice/postExplainStepUserResponses/', userData, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  getExplaineStepByIdAndUserName(username, id) {
    const token = localStorage.getItem("access_token");
    const encodedUsername = encodeURIComponent(username);

    return axios.get(Config.APIURL + 'studentservice/getExplaineStepByIdAndUserName?name=' + encodedUsername + "&id=" + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
      })
  }

  //extend tab
  getExtendInfosById(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'studentservice/getAllExtendInfosById/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
      })
  }

  postExtendStepUserResponses(userData) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'studentservice/postExtendStepUserResponses/', userData, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  getExtendStepByIdAndUserName(username, id) {
    const token = localStorage.getItem("access_token");
    const encodedUsername = encodeURIComponent(username);

    return axios.get(Config.APIURL + 'studentservice/getExtendStepByIdAndUserName?name=' + encodedUsername + "&id=" + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
      })
  }

  postTimeDuration(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'studentservice/updateAllStepStudentDuration?duration=' + data.totalDuration + '&userName=' + data.username + '&engageStepId=' + data.engageStepId + '&evaluateStepId=' + data.evaluateStepId + '&exploreStepId=' + data.exploreStepId + '&explainStepId=' + data.explainStepId + '&extendStepId=' + data.extendStepId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        // console.log(res)
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  // notification
  getAllStepStudentNotificationDetails() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'studentservice/getAllStepStudentnotificationDeatils', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  // notification superadmin
  getSuperAdminNotificationDetails() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'notification/getAllNotifications', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  // read notification superadmin
  readSuperAdminNotificationDetails(notificationId, classId) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + `notification/updateNotificationReadStatus?${notificationId ? `notificationId=${notificationId}` : `classId=${classId}`}`,{}, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }

  // http://localhost:8080/api/studentservice/updateStudentNotificationStatus?userName=nuwan&engageStepId=1&stepType=engageStep
  getUpdateStudentNotificationStatus(data) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'studentservice/updateStudentNotificationStatus?' + data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }
}

export default new RestService();

