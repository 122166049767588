import React, { useEffect, useState } from "react";
import jsonToken from 'jsonwebtoken'
import $ from 'jquery';
import { slide as Menu } from "react-burger-menu";
import { useHistory, Link, useLocation } from 'react-router-dom'
import { Alert, Button, Space } from 'antd';
// import cookies from "js-cookie";
import classNames from "classnames";
import SidebarNotification from "../SidebarNotification/SidebarNotification";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import MyComponent from "./transalotor";
import dotenv from 'dotenv';
import Cookies from "js-cookie";
import SidebarNotificationSuperAdmin from "../SidebarNotification/SidebarNotificationSuperAdmin";

dotenv.config();

const Sidebar = (props) => { 
  let location = useLocation()
  const history = useHistory();
  const [inRole, setInRole] = useState("");

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
        sourceLanguage: "en",
      },
      "google_translate_element"
    );

    // Add event listener for language selection change
    // const translateElement = document.querySelector("#google_translate_element");
    // if (translateElement) {
    //   translateElement.addEventListener("change", () => {
    // setTimeout(function() {
    //   window.location.reload();
    // }, 800);
    //   });
    // }
  };
  const [firstTymSetter, setFirstTymSetter] = useState(true)

  useEffect(() => {
    if (inRole === "STUDENT" && firstTymSetter) {
      var addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
      setFirstTymSetter(false)
    }
  }, [inRole]);

  const [header, setHeader] = useState({
    h1: `WELCOME `,
    h2: `Switch Classes`,
    h3: `Language `,
    h4: `NOTIFICATIONS`,
    h5: `Log Out`,
    h6: `Are you sure you want to change Class?`,
    h7: `Yes`,
    h8: `No`,
  });

  let message = `${header.h1} USER`;
  let token = localStorage.getItem("access_token");

  const GlobeIcon = ({ width = 24, height = 24 }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      className="bi bi-globe"
      viewBox="0 0 16 16"
    >
      <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
    </svg>
  );
  const handleToggle = () => {
    // $(document).ready(function(){
    //$('#dropdownMenuButton1').on('click', function(){

    $(".dropdown-menu").toggleClass("show");
    // });
    // });
  };
  // const currentLanguageCode = cookies.get("i18next") || "en";
  // const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  // const { t } = useTranslation();


  // useEffect(() => {

  //   console.log("Setting page stuff");
  //   document.body.dir = currentLanguage.dir || "ltr";
  //   document.title = t("Smart Science");
  // }, [currentLanguage, t]);
  const [isMenuOpen, handleMenu] = useState(false);
  const handleCloseMenu = () => {
    handleMenu(false);
  };
  const handleStateChange = (state) => {
    handleMenu(state.isOpen);
  };
  useEffect(() => {

    $(".dropdown-menu").removeClass("show");
    handleToggle()

    // function hasRole(role) {
    let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
    let index = accessToken.roles.findIndex(element => {
      setInRole(element);
      // return element.toLowerCase() === role.toLowerCase();
    });
    // return index > -1;
    // }

    // if (hasRole('TEACHER')) {
    //   return (
    //     
    //   );

    // }

  }, [])
  if (token) {
    let accessToken = jsonToken.decode(token);
    message = header.h1 + accessToken.firstName.toUpperCase();
  }

  function onLogout(e) {
    if (inRole === "STUDENT") {
      Cookies.remove('googtrans')
    }
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    // localStorage.removeItem('targetLanguage');
    localStorage.removeItem('userRoutes');

    localStorage.removeItem("curriculumName");
    localStorage.removeItem("curriculumLevel");
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('notificationCount');
    localStorage.removeItem('className');
    localStorage.removeItem('lessonID');
    localStorage.removeItem('labelLegends');
    sessionStorage.clear();
    history.push(`/login`)
    window.location.reload();
    // window.location.href = "/";    
  }

  const handleClickConfirm = () => {
    confirmAlert({
      title: 'Switch Class',
      message: header.h6,
      buttons: [
        {
          label: header.h7,
          onClick: () => {
            //e.preventDefault()
            handleCloseMenu()
            history.push(`/ClassIndex`)
            document.location.reload(true);
          }
        },

        {
          label: header.h8,
          onClick: () => {
            handleCloseMenu()
          },
        },
      ],
    });
  };

  let accessToken2 = jsonToken.decode(localStorage.getItem("access_token"));

  return (
    <Menu isOpen={isMenuOpen}
      onStateChange={handleStateChange} right>
      <center className="menu-title">{message}</center>
      <div className="rmenu">

        <Link >
        </Link>
        {inRole == "SCHOOL_ADMIN" ?
          <div>
            <li>
              <Link className="menu-item" to={'/Home'} onClick={(e) => {
                handleCloseMenu()
                history.push(`/Home`)
              }
              } >
                Home
              </Link>
            </li>
            <li>
              <Link className="menu-item" to={'/Organization'} onClick={(e) => {
                e.preventDefault()
                handleCloseMenu()
                history.push(`/Organization`)
              }

              } >
                Teacher Evaluation
              </Link>
            </li>
          </div>
          : ""}
        {inRole == "ACCOUNT_ADMIN" ?
          <div>
            <li>
              <Link className="menu-item" to={'/Home'} onClick={(e) => {
                handleCloseMenu()
                history.push(`/Home`)
              }
              } >
                Home
              </Link>
            </li>
            <li>
              <Link className="menu-item" to={'/Organization'} onClick={(e) => {
                e.preventDefault()
                handleCloseMenu()
                history.push(`/Organization`)
              }

              } >
                Teacher Evaluation
              </Link>
            </li>
          </div>
          : ""}
        {inRole == "SUPER_ADMIN" ?
          <div>
            {/* <li>
              <Link id="home" className="menu-item" onClick={() => handleCloseMenu()}>
                Additional Information
              </Link>
            </li> */}

            <li>
              <Link className="menu-item" to={'/Home'} onClick={(e) => {
                handleCloseMenu()
                history.push(`/Home`)
              }
              } >
                Home
              </Link>
            </li>
            {inRole != "SUPER_ADMIN" ?
              <li>
                <Link className="menu-item" onClick={(e) => {
                  e.preventDefault()
                  handleCloseMenu()
                  history.push(`/MyAccount`)
                }

                } >
                  My Account
                </Link>
              </li>
              : ""}
            <li>
              <Link className="menu-item" to={'/Teacher/PlanClass'} onClick={(e) => {
                e.preventDefault()
                handleCloseMenu()
                history.push(`/Teacher/PlanClass`)
              }

              } >
                Lesson Plan
              </Link>
            </li>
            <li>
              <Link className="menu-item" to={'/Organization'} onClick={(e) => {
                e.preventDefault()
                handleCloseMenu()
                history.push(`/Organization`)
              }

              } >
                Teacher Evaluation
              </Link>
            </li>

            <div>
              <br />
              <center className="menu-title">NOTIFICATIONS</center>
              <div className="notification-scroller">
                <SidebarNotificationSuperAdmin notificationCount={(data) => {
                  props.notificationCount(data)
                }} isMenuOpen={isMenuOpen} />
              </div>
            </div>
          </div>
          : ""}
        {inRole == "TEACHER" ?
          <div>
            {/* <li>
              <Link id="home" className="menu-item" onClick={() => handleCloseMenu()}>
                Additional Information
              </Link>
            </li> */}

            <li>
              <Link className="menu-item" to={'/Home'} onClick={(e) => {
                handleCloseMenu()
                history.push(`/Home`)
              }
              }
              >
                Home
              </Link>
            </li>

            <li>
              <Link className="menu-item" to={'/MyAccount'} onClick={(e) => {
                e.preventDefault()
                handleCloseMenu()
                history.push(`/MyAccount`)
              }

              } >
                My Account
              </Link>
            </li>
            <li>
              <Link className="menu-item" to={'/Teacher/PlanClass'} onClick={(e) => {
                e.preventDefault()
                handleCloseMenu()
                history.push(`/Teacher/PlanClass`)
              }

              } >
                Lesson Plan
              </Link>
            </li>
            <li>
              <Link className="menu-item" to={`/Instructor/1/Classes/${accessToken2.userId}`} onClick={(e) => {
                e.preventDefault()
                handleCloseMenu()
                history.push(`/Instructor/1/Classes/${accessToken2.userId}`)
              }

              } >
                Evaluation
              </Link>
            </li>
          </div>
          : ""}
        {inRole == "STUDENT" && location.pathname == "/LessonLab" ?
          <div>
            <li>
              <Link className="menu-item" onClick={() => handleCloseMenu()}
              // onClick={(e) => { e.preventDefault()
              //   history.push(``)} } 
              >
                Goals
              </Link>
            </li>
            <li>
              <Link className="menu-item" onClick={() => handleCloseMenu()}
              // onClick={(e) => {e.preventDefault()
              //   history.push(``) }}
              >
                Procedure
              </Link>
            </li>
            <li>
              <Link className="menu-item" onClick={() => handleCloseMenu()}
              // onClick={(e) => {e.preventDefault()
              //   history.push(``) } }
              >
                Scientist
              </Link>
            </li>
            <li>
              <Link className="menu-item" onClick={() => handleCloseMenu()}
              // onClick={(e) => { e.preventDefault()
              //   history.push(``) }  }
              >
                SI Units
              </Link>
            </li>
            <li>
              <Link className="menu-item" onClick={() => handleCloseMenu()}
              // onClick={(e) => { e.preventDefault()
              //   history.push(``)}}
              >
                Dictionary
              </Link>
            </li>
            <li>
              <Link className="menu-item" onClick={() => handleCloseMenu()}
              // onClick={(e) => { e.preventDefault()
              //   history.push(``) } }
              >
                Vocabulary
              </Link>
            </li>
          </div>

          : ""}


        {inRole == "STUDENT" ?
          <div>
            <li>
              <Link className="menu-item" onClick={(e) => {
                e.preventDefault()
                handleCloseMenu()
                handleClickConfirm()
              }

              } >
                {header.h2}
              </Link>
            </li>
            <li>
              <div className="dropdown">
                <a
                  className="menu-item no-click"

                >
                  {header.h3}

                </a>
                <div className="language" aria-labelledby="">
                  <div id="google_translate_element"></div>
                </div>

              </div>
            </li>

            <div>
              <br />
              <center className="menu-title">{header.h4}</center>
              <div className="notification-scroller">
                <SidebarNotification notificationCount={(data) => {
                  props.notificationCount(data)
                }} />
              </div>
            </div>
          </div>
          : ""}


        {/* <Link >Switch Classes</Link>
        <Link >Change Language</Link>
        <Link >Lab Assistant</Link> */}
      </div>
      <div className="rmenu logout">
        <Button ghost block>
          <a id="home" className="menu-item" onClick={onLogout}>
            {header.h5}
          </a>
        </Button>
      </div>
      <span style={{ fontSize: 9, textAlign: "center", position: "absolute", left: "40%", transform: "translateX(-30%)", bottom: 10 }}>
      © Copyright 2023 Smart Science Education Inc. <br/> All rights reserved.
      </span>
    </Menu>
  );
};

export default Sidebar;
