import React from "react";
import { Redirect } from 'react-router-dom'
import jsonToken from 'jsonwebtoken';

const Unauthorized = () => {
    return (
        <div>
            <div className="override">
                <p>Unauthorized Screen</p>
            </div>
        </div>

    );
}

export default Unauthorized;