/*
* Author        : @Syanapsys
* Created date  : 28/02/2032
* Discription   : Disable Enable dropdowns based on user roll

Component Logic And Usage
********************************************************************************************************************************
1. Account Admin Login Disable Account Dropdown on Create/Edit/View  School, School Admin, Term, Teacher, Student and Class
2. School Admin Login Disable Account and School Dropdowns on Create/Edit/View Teacher, Student and Class
3. Teacher Login Disable Account and School Dropdowns on Create/Edit/View Student 
4. Teacher Login Disable Account , School and Teacher Dropdowns on Create/Edit/View Class
********************************************************************************************************************************
*/

/*
@Name       : disableEnableDropDown
@Parameters : dropdown_type (Account/School/Teacher) , user_roll (SUPER_ADMIN/ACCOUNT_ADMIN/SCHOOL_ADMIN/TEACHER)
@Return     : Returning true to disable element and returning false to enable
*/
export const disableEnableDropdown = (dropdown_type = null, user_roll = null, assignedStdList = null) => {
    if (dropdown_type != null && user_roll != null) {
        /*On Dropdown Type Account*/
        if (dropdown_type === "Account") {
            if (user_roll === "SUPER_ADMIN") {
                if(assignedStdList > 0){
                    return true;
                }
                return false;
            } else if (user_roll === "ACCOUNT_ADMIN") {
                return true;
            } else if (user_roll === "SCHOOL_ADMIN") {
                return true;
            } else if (user_roll === "TEACHER") {
                return true;
            }
        }
        /*On Dropdown Type School*/
        else if (dropdown_type === "School") {
            if (user_roll === "SUPER_ADMIN") {
                if(assignedStdList > 0){
                    return true;
                }
                return false;
            } else if (user_roll === "ACCOUNT_ADMIN") {
                return false;
            } else if (user_roll === "SCHOOL_ADMIN") {
                return false;
            } else if (user_roll === "TEACHER") {
                return true;
            }
        }
        /*On Dropdown Type Teacher*/
        else if (dropdown_type === "Teacher") {
            if (user_roll === "SUPER_ADMIN") {
                return false;
            } else if (user_roll === "ACCOUNT_ADMIN") {
                return false;
            } else if (user_roll === "SCHOOL_ADMIN") {
                return false;
            } else if (user_roll === "TEACHER") {
                return true;
            }
        }
    } else {
        /*Errors On Console*/
        if (dropdown_type === null) {
            console.error("disableEnableDropDown: Please set Dropdown Type");
        } else if (user_roll === null) {
            console.error("disableEnableDropDown: Please set User Roll");
        }
    }
}
