import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';


const EnergyChart = (props) => {
let initialPE;
const [float_S, setSpeed] = useState([]);
const [float_PE, setPE] = useState([]);
const [float_KE, setKE] = useState([])
const [float_labels, setFloat_labels] = useState([]);

// for mix data
let float_mixS = []
let float_mixPE = []
let float_mixKE = []
const [mixedDatasets, setMixedDatasets] = useState([]);
const [mixedSpeed, setMixedSpeed] = useState({});
 
const calculateEnergy = (energy, indexStatus) => {
  const mass = 1;
  let kE;
      if(indexStatus){
        kE = 0
        initialPE = energy;
      }else{
        kE = parseFloat(initialPE - energy).toFixed(2);
      }
      const speedValue =  parseFloat(Math.sqrt((2 * kE) / mass)).toFixed(2);
      setPE(prev => [...prev, parseFloat(energy).toFixed(2)])
      setKE(prev => [...prev, parseFloat(kE).toFixed(2)])
      setSpeed(prev => [...prev, parseFloat(speedValue).toFixed(2)])
}

const calculateEnergyMix = (energy, index) => {
  const mass = 1;
  let kE;
      if(index === 0 || index === 3){
        kE = 0
        initialPE = energy;
      }else{
        kE = parseFloat(initialPE - energy).toFixed(2);
      }
      const speedValue =  parseFloat(Math.sqrt((2 * kE) / mass)).toFixed(2);
       float_mixS.push(parseFloat(speedValue))
       float_mixPE.push(parseFloat(energy).toFixed(2))
       float_mixKE.push(parseFloat(kE).toFixed(2))
}

useEffect(()=>{
  if (props?.chartData?.type === 1) {
    const float_labels1 = props?.chartData?.datasets[0]?.xData.map(value => parseFloat(value));
    setSpeed([])
    setPE([])
    setKE([])
    let trigger = 0;
    let prevValue;
    let prevValue2;
    let skipper;
    props?.chartData?.datasets[0]?.yData.map((value, index) =>{
      const filter = value.split(":");
      const filter2 = filter[1].split(";");
      let indexStatus;
      if(index === 0){
        indexStatus = true;
        skipper = false;
        prevValue = parseFloat(filter2[0]);
      }else{
        if(trigger === 0){
          if(prevValue - parseFloat(filter2[0]) <= 0){
            trigger = 1
            prevValue2 = prevValue
            prevValue = parseFloat(filter2[0]);
            skipper = false;
            indexStatus = false
          }else{
            prevValue = parseFloat(filter2[0]);
            skipper = false;
            indexStatus = false
          }
        }else{
          if(prevValue - parseFloat(filter2[0]) >= 0){
            trigger = 0
            skipper = true;
            const prevKE = 0;
            const currentKE = parseFloat(prevValue - parseFloat(filter2[0])).toFixed(2);
            const prevSpeedValue =  parseFloat(Math.sqrt((2 * prevKE) / 1)).toFixed(2);
            const currentSpeedValue =  parseFloat(Math.sqrt((2 * currentKE) / 1)).toFixed(2);
            setPE(prev => [...prev, parseFloat(filter2[0])])
            setKE(prev => [...prev.slice(0, -1), prevKE, currentKE])
            setSpeed(prev => [...prev.slice(0, -1), prevSpeedValue, currentSpeedValue])
            initialPE = prevValue;
            prevValue = parseFloat(filter2[0]);

          }else{
            prevValue2 = prevValue
            prevValue = parseFloat(filter2[0]);
            indexStatus = false;
            skipper = false;
          }
        }
      }
      if(!skipper){
        calculateEnergy(parseFloat(filter2[0]), indexStatus)
      }
    });
    setFloat_labels(float_labels1)
  }else{
    setMixedDatasets([])
    setMixedSpeed({})
    

    props?.chartData.map((value) =>{
      const labelPayloadName = `Kinetic Energy ${value.labels}`;
       float_mixS = []
       float_mixPE = []
       float_mixKE = []
       
      let trigger = 0;
      let prevValue;
      let prevValue2;
      let skipper;
      value.yData.map((value2, index) => {
        const filter = value2.split(":");
        const filter2 = filter[1].split(";");
        let indexStatus;
      if(index === 0){
        indexStatus = true;
        skipper = false;
        prevValue = parseFloat(filter2[0]);
      }else{
        if(trigger === 0){
          if(prevValue - parseFloat(filter2[0]) <= 0){
            trigger = 1
            prevValue2 = prevValue
            prevValue = parseFloat(filter2[0]);
            skipper = false;
            indexStatus = false
          }else{
            prevValue = parseFloat(filter2[0]);
            skipper = false;
            indexStatus = false
          }
        }else{
          if(prevValue - parseFloat(filter2[0]) >= 0){
            trigger = 0
            skipper = true;
            const prevKE = 0;
            const currentKE = parseFloat(prevValue - parseFloat(filter2[0])).toFixed(2);
            const prevSpeedValue =  parseFloat(Math.sqrt((2 * prevKE) / 1)).toFixed(2);
            const currentSpeedValue =  parseFloat(Math.sqrt((2 * currentKE) / 1)).toFixed(2);
            float_mixS =[...float_mixS.slice(0, -1), prevSpeedValue, currentSpeedValue];
            float_mixPE = [...float_mixPE,parseFloat(filter2[0])];
            float_mixKE= [...float_mixKE.slice(0, -1),prevKE, currentKE];
            initialPE = prevValue;
            prevValue = parseFloat(filter2[0]);

          }else{
            prevValue2 = prevValue
            prevValue = parseFloat(filter2[0]);
            indexStatus = false;
            skipper = false;
          }
        }
      }
      if(!skipper){
        calculateEnergyMix(parseFloat(filter2[0]), index)
      }
      })

      const floatPE = float_mixPE.map(value => parseFloat(value));
      const floatKE = float_mixKE.map(value => parseFloat(value));
      const floatSpeed = float_mixS.map(value => parseFloat(value));
      setMixedDatasets(prev => [
        ...prev, 
        {
          label: `Potential Energy ${value.labels}`,
          data: floatPE.map((value3, index) => ({ x: value.xData[index], y: value3 })), // Replace with your actual potential energy values
          borderColor: value?.borderColor,
          backgroundColor: 'green', 
          pointBackgroundColor: 'white',
          pointBorderColor: 'green', 
          fill: false,
          tension: 0.3,
        },
        {
          label: `Kinetic Energy ${value.labels}`,
          data: floatKE.map((value3, index) => ({ x: value.xData[index], y: value3 })), // Replace with your actual kinetic energy values
          borderColor: value?.borderColor,
          backgroundColor: 'blue', 
          pointBackgroundColor: 'blue',
          // pointBorderColor: 'blue', 
          borderDash: [10, 2],

          fill: false,
          tension: 0.3,
        }
      ])
      setMixedSpeed(prev=> ({
        ...prev,
        [labelPayloadName]: floatSpeed
      }));

    })
  }
},[props])

if (props?.chartData?.type === 1) {
  const data = {
    labels: float_labels, // Replace with your actual time labels
    datasets: [
      {
        label: 'Potential Energy',
        data: float_PE, // Replace with your actual potential energy values
        borderColor: props?.chartData?.datasets[0]?.borderColor,
        backgroundColor: 'green', 
        pointBackgroundColor: 'white',
        pointBorderColor: 'green', 
        fill: false,
        tension: 0.3,
      },
      {
        label: 'Kinetic Energy',
        data: float_KE, // Replace with your actual kinetic energy values
        borderColor: props?.chartData?.datasets[0]?.borderColor,
        backgroundColor: 'blue', 
        pointBackgroundColor: 'blue',
        // pointBorderColor: 'blue', 
        borderDash: [10, 2],

        fill: false,
        tension: 0.3,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            if(context.dataset.label === "Kinetic Energy"){
              const dataIndex = context.dataIndex;
              const datasetIndex = context.datasetIndex;
              const velocity = float_S[dataIndex];
              const yLabel = context.chart.data.datasets[datasetIndex].data[dataIndex];
              return `Kinetic Energy: ${yLabel} J, Velocity: ${velocity} m/s`; // Customize the tooltip content as needed
            }else{
              const dataIndex = context.dataIndex;
              const datasetIndex = context.datasetIndex;
              const yLabel = context.chart.data.datasets[datasetIndex].data[dataIndex];
              return `Potential Energy: ${yLabel} J`; // Customize the tooltip content as needed
            }
            
          },
        },
      },
    },
    responsive : true,
    maintainAspectRatio : false,
    aspectRatio: 2,
    layout : {
      autoPadding : false
    }
  };
  return <Line key={JSON.stringify(props.chartData)} data={data} options={options} height="200" />;
}else{
  const data = {
    datasets: mixedDatasets,
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            if(context.dataset.label.startsWith("Kinetic Energy ")){
              const dataIndex = context.dataIndex;
              const datasetIndex = context.datasetIndex;
              const velocityGet = mixedSpeed[context.dataset.label];
              const velocity = velocityGet[dataIndex]
              const yLabel = context.chart.data.datasets[datasetIndex].data[dataIndex].y;
              return `${context.dataset.label}: ${yLabel} J, Velocity: ${velocity} m/s`; // Customize the tooltip content as needed
            }else{
              const dataIndex = context.dataIndex;
              const datasetIndex = context.datasetIndex;
              const yLabel = context.chart.data.datasets[datasetIndex].data[dataIndex].y;
              return `${context.dataset.label}: ${yLabel} J`; // Customize the tooltip content as needed
            }
            
          },
        },
      },
    },
    responsive : true,
    maintainAspectRatio : false,
    aspectRatio: 2,
    layout : {
      autoPadding : false
    }
  };
  return <Line key={JSON.stringify(props.chartData)} data={data} options={options} hheight="200" />;
}

};

export default EnergyChart;
