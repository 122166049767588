import React, { useState } from 'react';
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import info from "../../../assets/images/info.svg"
// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  CardBody,
  CardTitle,
  Row,
  Col,
  Progress,
  Badge,
  CardImgOverlay,
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import graph from "../../../assets/images/graph-image.png";

function Lesson(props) {
  const { TabPane } = Tabs;
  const onChange = (key) => {
    // console.log(key);
  };
  const [modal, setModal] = useState(false);
  const togglePopup = () => setModal(!modal);
  return (
    <>
    <div className="container">
      <div className="content">
      <div>
      <Modal isOpen={modal} toggle={togglePopup}>
        <ModalHeader toggle={togglePopup}></ModalHeader>
        <ModalBody>
          <center>
           <img src={info} alt='popup-thum' />
          </center>
          <div className='popup-text'>
          This assignment will be removed.

Do you wish to continue?

          </div>
        </ModalBody>
        <ModalFooter className='center'>
          <Button color="success" onClick={togglePopup}>Yes</Button>
          <Button color="warning" onClick={togglePopup}>No</Button>
        </ModalFooter>
      </Modal>
    </div>
        <Row>
          <Col md="3">
            <div class="lesson-image" onClick={togglePopup}>
              <img src={graph} alt="Avatar" class="image" />
              <div class="overlay">
                <p className="g-card-top">
                  Introductory
                  <span className="g-card-top-right pull-right">SCORE</span>
                </p>
                <p className="g-card-title">
                  Simple Graphs
                  <span className="g-card-title-right pull-right">160</span>
                </p>
              
              </div>
            </div>
          </Col>
          <Col md="3">
            <div class="lesson-image" onClick={togglePopup}>
              <img src={graph} alt="Avatar" class="image" />
              <div class="overlay">
                <p className="g-card-top">
                  Introductory
                  <span className="g-card-top-right pull-right">SCORE</span>
                </p>
                <p className="g-card-title">
                  Simple Graphs
                  <span className="g-card-title-right pull-right">160</span>
                </p>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div class="lesson-image" onClick={togglePopup}>
              <img src={graph} alt="Avatar" class="image" />
              <div class="overlay">
                <p className="g-card-top">
                  Introductory
                  <span className="g-card-top-right pull-right">SCORE</span>
                </p>
                <p className="g-card-title">
                  Simple Graphs
                  <span className="g-card-title-right pull-right">160</span>
                </p>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div class="lesson-image" onClick={togglePopup}>
              <img src={graph} alt="Avatar" class="image" />
              <div class="overlay">
                <p className="g-card-top">
                  Introductory
                  <span className="g-card-top-right pull-right">SCORE</span>
                </p>
                <p className="g-card-title">
                  Simple Graphs
                  <span className="g-card-title-right pull-right">160</span>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      </div>
    </>
  );
}

export default Lesson;
