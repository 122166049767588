
import { List } from 'antd'
import React from 'react'
import { Col, Row } from 'reactstrap'

export default function FourthTypeQuestion(props) {
  // const displayMsg = (value) =>{

  //   let question;
  //   const subQuestion =[];
  //   value?.split("-").map((x,index)=>{

  //     if(index === 0){
  //       question = x
  //     }else{
  //       subQuestion.push(x)
  //     }
  //   });
  //   return(
  //     <>
  //     <p>{question}</p><br/>
  //     {subQuestion.length ? (<>
  //       <List
  //         dataSource={subQuestion}
  //         renderItem={(item, x) => (
            
  //           <List.Item key={x}>
          
  //             <div className="col-md-12">
  //               <p >&#8226; {item}</p>
  //             </div>
  //           </List.Item>
  //         )}
  //       />
  //     </>) : ("")}
          
  //     </>
  //   )
  // }
  return (
  
  <>
  <div className="">
   <Row className="space-my-20 space-mx-20">
    <List
      dataSource={props.data}
      renderItem={(item, x) => (
        <List.Item key={x}>
          <div className="w-100">
          <Row>
          <Col xs="6" className="pt-4">
          {/* <h5 className="pt-4"> {(x+1) + ". "}{item?.description.split(":")[0]} </h5>
          {displayMsg(item?.description.split(":")[1])} */}
            <div dangerouslySetInnerHTML={{ __html: item?.description }}></div>

          </Col>
          <Col xs="6" className="pt-4">
          <img src={item?.description.split(",")[1]} alt="" />
          </Col>
        </Row>
     <Row>
       <Col xs="12" className="pt-4">        
       <textarea
           className="form-control tarea"
           defaultValue={ props.containedPayload === undefined || Object.keys(props.containedPayload).length === 0 ?  "":props?.containedPayload[x]?.question_id === item?.id ? props?.containedPayload[x]?.answer:""}       
               onChange={props.changeHandler}
          rows={8}
          id="textarea"
         name={item.id-1}
         disabled={props.freezingStatus}
        ></textarea>
    </Col>    </Row>
   </div>
        </List.Item>
      )}
    />

    
  </Row>
  </div>
    </>
  )
}
