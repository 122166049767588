import axios from "axios";
import Config from "./Config";

class ClassService {
  getCreateClassInitData() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/class/getCreationData', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
        const error = err.response.data
        return error;
      })
  }

  getClassById(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/class/getClassById/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
        const error = err.response.data
        return error;
      })
  }

  getAccountsForUser() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/account/getAccountsByUserId', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
        const error = err.response.data
        return error;
      })
  }

  getSchoolsForAccount(accountId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/school/schoolsByAccount/' + accountId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
        const error = err.response.data
        return error;
      })
  }

  getClassesForSchool(schoolId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/class/getClassByOrganizationId/' + schoolId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
        const error = err.response.data
        return error;
      })
  }
  getClassesForSchoolPaged(schoolId, page, size, search) {
    const token = localStorage.getItem("access_token");
    const encodedSearch = encodeURIComponent(search);

    return axios.get(Config.APIURL + 'admin/class/getClassByOrganizationId_paged/' + schoolId + `?page=${page}&size=${size}&search=${encodedSearch}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
        const error = err.response.data
        return error;
      })
  }

  getClassStudents(classId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/class/getStudnetsToBeAssignedClassId/' + classId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
        const error = err.response.data
        return error;
      })
  }
  getClassStudentsList(schoolId, classId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + `admin/student/studentsByClassId/${classId}/${schoolId}`, { headers: { "Authorization": `Bearer ${token}` } })
    // return axios.get(Config.APIURL + `admin/student/studentsByClassId_paged/${classId}/${schoolId}?page=1&size=10&search=`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
        const error = err.response.data
        return error;
      })
  }

  getClassesForDropDownBySchool(schoolId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/class/getClassesForDropDownByOrgId/' + schoolId, { headers: {"Authorization": `Bearer ${token}`} })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err);
        const error = err.response.data
        return error;
      })
  }

  postNewClass(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/class/addNewClass', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      // .catch((err) => {console.log("error", err.response?.data ? err.response?.data : err)
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        return Promise.reject(err.response?.data ? err.response?.data : err);

      })

  }

  updateClass(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/class/updateClass', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        return Promise.reject(err.response?.data ? err.response?.data : err);

      })
  }

  assignStudentsToClass(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/class/studentsAssignClasses', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
        const error = err.response.data
        return error;
      })
  }

  checkCurriculum(classId){
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/class/getCompletedLessonsForClassId/' + classId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
  }
}

export default new ClassService();