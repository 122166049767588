import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import {
  Radio,
  Input,
  Form,
  Modal,
  Button,
  Tabs,
  Select,
  Table,
  Row,
  Col,
  Space,
  Spin,
} from "antd";
import "antd/dist/antd.css";
import { SearchOutlined, ArrowLeftOutlined } from "@ant-design/icons";

// import { columns } from "./data";

import moment, { isDate } from "moment";
import RestService from "../../../../api/RestService";
import { useHistory, useParams } from 'react-router';
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";
import jsonToken from 'jsonwebtoken'
import { CardBody } from "reactstrap";

function Instructor(props) {
  const { Search } = Input;
  const { id } = useParams();
  const [dataSource, setDataSource] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [organizations, setOrganizations] = useState([])
  const [loading, setLoading] = useState(false);
  const [searchValue, setSetSearchValue] = useState("")

  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  let history = useHistory();

  

  useEffect(() => {
    setLoading(true);
    let teacher_id = id;
    if (accessToken.roles[0] === "SUPER_ADMIN") {
      teacher_id = "-1";
    }
    RestService.getTeacherEvaluateOrganizationScreen(teacher_id).then(res => {
      if (res.data?.length > 0) {
        setDataSource(res.data?.map((x, i) => {
          return {
            teacherId:x.teacherId,
            key: i,
            organization: "",
            instructors: x.teacherName,
            class: x.classCount,
            lessons: x.lessonCount,
            student: x.studentCount,
            angIntro: parseFloat(x.engageStepAvg).toFixed(2),
            angQuiz: parseFloat(x.evaluateStepAvg).toFixed(2),
            angRpt: parseFloat(x.explainStepAvg).toFixed(2),
            angExt: parseFloat(x.extendStepAvg).toFixed(2)
          }
        }))
      }
      setLoading(false)
    })

    RestService.getOrganizationForRoles(id).then(res => {
      if (res.data?.length > 0) {
        // console.log(res.data)
        setOrganizations(
          res.data?.map((x, i) => {
            return { id: x.id, name: x.name }
          })
        )
      }
    })
  }, [])

  const handleChange = (value, event) => {
    setLoading(true)
    RestService.getTeacherEvaluateOrganizationScreen(value).then(res => {
      if (res.data?.length > 0) {
        setDataSource(res.data?.map((x, i) => {
          return {
            teacherId:x.teacherId,
            key: i,
            organization: "",
            instructors: x.teacherName,
            class: x.classCount,
            lessons: x.lessonCount,
            student: x.studentCount,
            angIntro: x.engageStepAvg,
            angQuiz: x.evaluateStepAvg,
            angRpt: x.explainStepAvg,
            angExt: x.extendStepAvg
          }
        }))
      }
      else{
        setDataSource([])
      }
      setLoading(false);
    })
  }

  const columns = [
    {
      title: 'Instructor',
      dataIndex: 'instructors',
      key: 'instructors',
      width:300,
      sorter: (a, b) => a.instructors.localeCompare(b.instructors),
      render: (_, record) => (
        <Space size="middle">
          {record.instructors}
  
        </Space>
      ),
    },
  
    {
      title: 'Class ',
      dataIndex: 'class',
      key: 'class',
      sorter: (a, b) => a.class - b.class,
    },
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
      sorter: (a, b) => a.student - b.student,
    },
    {
      title: 'Lessons',
      dataIndex: 'lessons',
      key: 'lessons',
      sorter: (a, b) => a.lessons - b.lessons,
    },
    {
      title: 'Engage',
      dataIndex: 'angIntro',
      key: 'angIntro',
      sorter: (a, b) => a.angIntro - b.angIntro,
    },
    {
      title: 'Evaluate',
      dataIndex: 'angQuiz',
      key: 'angQuiz',
      sorter: (a, b) => a.angQuiz - b.angQuiz,
    },
    {
      title: 'Explain',
      dataIndex: 'angRpt',
      key: 'angRpt',
      sorter: (a, b) => a.angRpt - b.angRpt,
    },
    {
      title: 'Extend',
      dataIndex: 'angExt',
      key: 'angExt',
      sorter: (a, b) => a.angExt - b.angExt,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
   
        <Button type="primary" size='medium' 
        onClick={(e) => {
              e.preventDefault()
              history.push(`/Instructor/${id}/Classes/${record.teacherId}`)
            }} >View</Button>
  
      ),
    },
  ];

  useEffect(()=>{
      if(searchValue){
        const filteredData = dataSource.filter(item => item.instructors.toLowerCase().includes(searchValue.toLowerCase()));
        setDataFiltered(filteredData)
      }else{
        setDataFiltered(dataSource)
      }
  },[dataSource, searchValue]);

  return (
    <>
      <Spin tip="Loading..." spinning={loading} size="large">
        <div className="container-fluid">
          <Row  gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}>
            <Col span={12} offset={6}>
              <center>
                <h3>My Instructors</h3>
              </center>
            </Col>
          </Row>
          <Row className="py-3 px-5">
            <Col span={6}>
              <Button icon={<ArrowLeftOutlined />} onClick={(e) => {
                e.preventDefault()
                history.push("/Organization")
              }} className="border-0"></Button>
              Go Back
            </Col>
            {accessToken.roles[0] === "ACCOUNT_ADMIN" || accessToken.roles[0] === "SUPER_ADMIN" &&
            <Col className="gutter-row" span={6}>
              
                  <Select
                    showSearch
                    placeholder="Select School"
                    optionFilterProp="children"
                    size="large"
                    className="search-drop"
                    onChange={(value, event) => handleChange(value, event)}
                  >
                    <option key="-1" value="-1">
                      Please Select a School
                    </option>
                    {organizations.filter(item => item.name !== null).map((obj) => (
                      <option key={obj.id} value={obj.id}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
            
              </Col>
                  }

{accessToken.roles[0] !== "ACCOUNT_ADMIN" || accessToken.roles[0] !== "SCHOOL_ADMIN" &&

<Col className="gutter-row" span={3}>
  <div></div>
  </Col>
                }
                {accessToken.roles[0] === "SCHOOL_ADMIN"  &&

<Col className="gutter-row" span={3}>
  <div></div>
  </Col>
                }
              <Col className="gutter-row mr10" span={6}>
                  <Input
                    size="large"
                    placeholder="Search"
                    onChange={(e) => { 
                      setSetSearchValue(e.target.value)
                      }}
                    prefix={<SearchOutlined className="mx-3" />}
                  />
              </Col>
          </Row>

          <div className="px-5">
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              {/* <Col className="gutter-row" span={3}></Col> */}
             
            </Row>
            <Row className="py-3">
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={dataFiltered}
                  pagination={{
                    position: ["bottomCenter"],
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                  className="thead-dark rl-button"
                />
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </>
  );
}

export default Instructor;
