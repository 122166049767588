export default function validate(obj) {
  let errors = {};
  let values = { ...obj };
  let moreBlank = /^(\w+\s)*\w+$/g;
  let specialChar = /^((?![-_!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+).)*$/gm;

  Object.keys(values).map(k => values[k] = trimObjectStrings(k));

  var description = values.labdescription;
  var div1 = document.createElement("div");
  div1.innerHTML = description;
  var desc = div1.textContent || div1.innerText || "";
  var richTextChecker1 = values.labdescription.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;| /g, '').trim();

  var guideline = values.guideline;
  var div2 = document.createElement("div");
  div2.innerHTML = guideline;
  var guide = div2.textContent || div2.innerText || "";
  var richTextChecker2 = values.guideline.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;| /g, '').trim();


  // if (values.description.length > 250) {
  //   errors.description = 'Account Description must be less than 250 characters';
  // }

  if (values.classLevel === undefined || values.classLevel <= 0) {
    errors.classLevel = 'Class Level is required';
  }
  if (values.labdescription === undefined || desc === undefined || values.labdescription.length === 0  || richTextChecker1 === "") {
    errors.labdescription = 'Lab Description is required';
  }
  if (values.guideline === undefined || guide === undefined || values.guideline.length === 0  || richTextChecker2 === "") {
    errors.guideline = 'Guideline is required';
  }

  function trimObjectStrings(key) {
    if (typeof values[key] == 'string' && !key.toLowerCase().includes("password")) {
      return values[key].trim();
    }
    return values[key];
  }

  return errors;
};