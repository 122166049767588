// const [response, setResponse] = useState([]);
import Config from "./Config";
import axios from "axios";
import jsonToken from 'jsonwebtoken'

export const GetAllClassesWithLessons = async (role, data,id) => {
  let token = localStorage.getItem("access_token")
  // console.log(token);
  if (role === "TEACHER" || role === "ACCOUNT_ADMIN" || role === "SCHOOL_ADMIN") {
    const response = await axios.get(Config.APIURL + "admin/teacher/teacherEvaluateHomeGradeScreen?teacherId=" + id, {
      //const response = await axios.get("https://run.mocky.io/v3/8d647c24-20a0-4ae2-b683-9008fef607b9",{
      headers: {
        // 'Content-Type': ('application/json'),
        // 'Access-Control-Allow-Methods':'*', 

        "Authorization": `Bearer ${token}`
      }
    }).then((res) => {
      console.log("red", res.data)
      const data = res.data;
      return data;
    })
      // .then((response) => response.json())
      // .then((data) => {
      //   return data;
      // })
      .catch((error) => console.log("error", error));

    return response;
  }
  else {
    const response = await axios.get(Config.APIURL + "admin/teacher/teacherEvaluateHomeGradeScreen", {
      //const response = await axios.get("https://run.mocky.io/v3/8d647c24-20a0-4ae2-b683-9008fef607b9",{
      headers: {
        // 'Content-Type': ('application/json'),
        // 'Access-Control-Allow-Methods':'*', 

        "Authorization": `Bearer ${token}`
      }
    }).then((res) => {
      console.log("red", res.data)
      const data = res.data;
      return data;
    })
      // .then((response) => response.json())
      // .then((data) => {
      //   return data;
      // })
      .catch((error) => console.log("error", error));

    return response;
  }
  // setResponse(response);
  // if (!response.ok) {
  //   throw new Error('Network response was not OK');
  // }
  // const data = await response?.data
  // const res = await data.json()
  // const data = await response.json();


  // console.log(response);
  //   .catch((error) => console.log("error", error));

  // return response;
};


export const GetClassesByOrganizationId = async (id) => {
  let token = localStorage.getItem("access_token");
  const response = await axios.get(Config.APIURL + `admin/teacher/teacherEvaluateClassHomeScreen?classId=${id}`, {
    //const response = await axios.get("https://run.mocky.io/v3/8833067d-9d55-4f2e-8293-c65acfa48d1b",{
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
    // .then((response) => response.json())
    // .then((data) => {
    //   console.log(data);
    //   return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};

export const GetAllExplainStepInfos = async (id, level) => {
  let token = localStorage.getItem("access_token");
  //const response = await axios.get("https://run.mocky.io/v3/18ebe6b8-549a-4e58-ba85-961e27162d34",{
  const response = await axios.get(Config.APIURL + `admin/teacher/studentEvaluateHomeScreen?studentId=${id}&classLevelId=${level}`, {
    // const response = await fetch(`api/user/getUserWithClassesByUserId/${id}`,{
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    // console.log("st in class", data);
    return data;
    // .then((response) => response.json())
    // .then((data) => {
    //   console.log(data);
    //   return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};

export const GetStudentsEvaluateLessonScore = async (id, level, lessonId) => {
  let token = localStorage.getItem("access_token");
  //const response = await axios.get("https://run.mocky.io/v3/18ebe6b8-549a-4e58-ba85-961e27162d34",{
  const response = await axios.get(Config.APIURL + `admin/teacher/getStudentsEvaluateLessonScore?studentId=${id}&&lessonLevel=${level}&lessonId=${lessonId}`, {
    // const response = await fetch(`api/user/getUserWithClassesByUserId/${id}`,{
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    // console.log("st in class", data);
    return data;
    // .then((response) => response.json())
    // .then((data) => {
    //   console.log(data);
    //   return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};

export const GetOneStudentData = async (id, username) => {
  let token = localStorage.getItem("access_token");
  const response = await axios.get("https://run.mocky.io/v3/de214dcc-617f-4768-8b90-3236cf436340", {
    // const response = await axios.get(Config.APIURL +`studentservice/getEvaluateStepInfoByEvaluateStepId/${id}/${username}`,{
    // const response = await fetch(`api/user/getUserWithClassesByUserId/${id}`,{
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    // console.log("st in class", data);
    return data;
    // .then((response) => response.json())
    // .then((data) => {
    //   console.log(data);
    //   return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};

export const GetLessonsByClass = async (lessonId) => {
  let token = localStorage.getItem("access_token");
  const response = await axios.get(Config.APIURL + `lesson/getLessonsByClassId/${lessonId}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    // console.log("st in class", data);
    return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};

export const getClassByOrganizationId = async (Id) => {
  let token = localStorage.getItem("access_token");
  const response = await axios.get(Config.APIURL + `admin/class/getClassByOrganizationId/${Id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    // console.log("st in class", data);
    return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};

export const GetAccountById = async (accountData) => {
  let token = localStorage.getItem("access_token");
  const response = await axios.get(Config.APIURL + `admin/account/getAccountsByUserId`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
  })
    .catch((err) => {
      return ("Server Error: ", err);
    })
};

export const GetSelectData = async () => {
  let token = localStorage.getItem("access_token");
  const response = await axios.get(Config.APIURL + `admin/class/home`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    // console.log("st in class", data);
    return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};

export const getTeacherList = async (schoolVal) => {
  let token = localStorage.getItem("access_token");
  if (schoolVal !== undefined) {
    const response = await axios.get(Config.APIURL + `admin/techer/getTeachersByOrgId/${schoolVal}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then((res) => {
      const data = res.data;
      return data;
    })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })

    return response;
  }
}

export const getTeacherListPaged = async (schoolVal, page = 1, size, search = "") => {
  let token = localStorage.getItem("access_token");
  const encodedSearch = encodeURIComponent(search);

  // if (schoolVal !== undefined) {
    const response = await axios.get(Config.APIURL + `admin/techer/getTeachersByOrgId_paged/${schoolVal}?page=${page}&size=${size}&search=${encodedSearch}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then((res) => {
      const data = res.data;
      return data;
    })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })

    return response;
  // }
}

// export const GetStudentData = async (schoolId, classId) => {
//   let token = localStorage.getItem("access_token");
//     const response = await axios.get(Config.APIURL +`admin/student/studentsByClassId/${schoolId}/${classId}`,{
//     // const response = await axios.get(Config.APIURL +`admin/student/studentsByClassId/0/1`,{
//       headers:{
//          "Authorization": `Bearer ${token}`
//       }
//     }).then((res) => {
//       const data = res.data;
//       // console.log("st in class", data);
//       return data;
//     })
//     .catch((error) => console.log("error", error));

//   return response;
//   };

export const GetAllSchool = async () => {
  let token = localStorage.getItem("access_token");
  const response = await axios.get(Config.APIURL + `admin/school/home`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    // console.log("st in class", data);
    return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};
export const getSchoolData = async (accVal) => {
  let token = localStorage.getItem("access_token");
  if (accVal !== undefined) {


    const response = await axios.get(Config.APIURL + `admin/school/schoolsByAccount/${accVal}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then((res) => {
      const data = res.data;

      return data;
    })
      .catch((error) => console.log("error", error));
    return response;
  }

};

export const getClassData = async (accVal) => {
  let token = localStorage.getItem("access_token");

  const response = await axios.get(Config.APIURL + `admin/class/getClassByOrganizationId/${accVal}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    // console.log("st in school", data);
    return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};

export const addNewTeacher = async (data) => {
  let token = localStorage.getItem("access_token");

  const response = await axios.post(Config.APIURL + `admin/techer/addNewTeacher`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    // console.log(res)
    // if(res.response?.status == 500){
    //   throw Error();
    // }
    const data = res.data;

    return data;
  })
    .catch((err) => {

      // console.log("error", err)
      return Promise.reject(err.response?.data ? err.response?.data : err);
    }
    );

  return response;
};

export const UpdateTeacher = async (data) => {
  let token = localStorage.getItem("access_token");

  const response = await axios.post(Config.APIURL + `admin/techer/updateTeacher`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;

    return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};

let abortController = new AbortController();

export const getStudentData = async (accVal, schoolVal, page, size, search) => {
  let token = localStorage.getItem("access_token");

  const encodedSearch = encodeURIComponent(search); // Encodes special characters
  // Cancel the previous request
  abortController.abort(); 
  // Create a new controller for the new request
  abortController = new AbortController(); 
  
  const response = await axios.get(Config.APIURL + `admin/student/studentsByClassId_paged/${accVal}/${schoolVal}?page=${page}&size=${size}&search=${encodedSearch}`, {
    headers: {"Authorization": `Bearer ${token}`},
    signal: abortController.signal
  }).then((res) => {
    const data = res.data;
    console.log("st in school", data);
    return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};



export const updateEngageComment = (data) => {
  let token = localStorage.getItem("access_token");
  return axios.post(Config.APIURL + `admin/teacher/postEngageStepUserComment`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
  })
    .catch((error) => {

      return Promise.reject(error.response?.data ? error.response?.data : error);
    });

}

export const updateMarksToClever = (lessonId,classId,username,lessonLevel,action) => {
  let token = localStorage.getItem("access_token");
  return axios.post(Config.APIURL + `admin/teacher/postStepUserMarksToClever?lessonId=${lessonId}&classId=${classId}&username=${username}&lessonLevel=${lessonLevel}&action=${action}`, {}, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
  })
}
export const updateCommentsToClever = (lessonId,classId,username,lessonLevel,action, step) => {
  let token = localStorage.getItem("access_token");
  return axios.post(Config.APIURL + `admin/teacher/postStepUserMarksToClever?lessonId=${lessonId}&classId=${classId}&username=${username}&lessonLevel=${lessonLevel}&action=${action}&step=${step}`, {}, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
  })
}

export const updateExploreComment = (data) => {
  let token = localStorage.getItem("access_token");
  return axios.post(Config.APIURL + `admin/teacher/postExploreStepUserComment`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
  })
    .catch((error) => {

      return Promise.reject(error.response?.data ? error.response?.data : error);
    });

}
export const updateEvaluateComment = (data) => {
  let token = localStorage.getItem("access_token");
  return axios.post(Config.APIURL + `admin/teacher/postEvaluateStepUserComment`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
  })
    .catch((error) => {

      return Promise.reject(error.response?.data ? error.response?.data : error);
    });

}


export const updateExplainComment = (data) => {
  let token = localStorage.getItem("access_token");
  return axios.post(Config.APIURL + `admin/teacher/postExplainStepUserComment`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
  })
    .catch((error) => {

      return Promise.reject(error.response?.data ? error.response?.data : error);
    });

}

export const updateExtendComment = (data) => {
  let token = localStorage.getItem("access_token");
  return axios.post(Config.APIURL + `admin/teacher/postExtendStepUserComment`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
  })
    .catch((error) => {

      return Promise.reject(error.response?.data ? error.response?.data : error);
    });

}







export const TeacherStudents = async () => {
  let token = localStorage.getItem("access_token")
  // console.log(token);
  const response = await axios.get(Config.APIURL + "teacher/getUserDetailsByUserName/dinesh", {
    // const response = await fetch("api/classes/getAllClassesWithLessons",{
    headers: {
      // 'Content-Type': ('application/json'),
      // 'Access-Control-Allow-Methods':'*', 

      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    console.log(data)
    return data;
  })
    // .then((response) => response.json())
    // .then((data) => {
    //   return data;
    // })
    .catch((error) => console.log("error", error));

  return response;
  // setResponse(response);
  // if (!response.ok) {
  //   throw new Error('Network response was not OK');
  // }
  // const data = await response?.data
  // const res = await data.json()
  // const data = await response.json();


  // console.log(response);
  //   .catch((error) => console.log("error", error));

  // return response;


};


export const getTeacherbyId = (id) => {
  const token = localStorage.getItem("access_token");
  return axios.get(Config.APIURL + 'admin/student/searchStudentById/' + id, { headers: { "Authorization": `Bearer ${token}` } })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("error", err.response?.data ? err.response?.data : err)
      // return Promise.reject(err.response?.data ? err.response?.data : err);
    })
};


export const updateTeacher2 = (data) => {
  const token = localStorage.getItem("access_token");
  return axios.post(Config.APIURL + 'admin/techer/updateTeacher/', data, { headers: { "Authorization": `Bearer ${token}` } })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("error", err.response?.data ? err.response?.data : err)
      return Promise.reject(err.response?.data ? err.response?.data : err);
    })
};


//getCurriculumsByTeacherId || super admin
export const getCurriculumsByTeacherId = (id) => {

  const deToken = jsonToken.decode(localStorage.getItem("access_token"));
  if (deToken.roles[0] === "SUPER_ADMIN") {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/teacher/plan/getCurriculamsForSuperAdmin', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  } else {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/teacher/plan/getCurriculamsByTeacherId/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
  }
};

//getLessonsByCurriculumId
export const getLessonsByCurriculumId = (id) => {
  const token = localStorage.getItem("access_token");
  return axios.get(Config.APIURL + 'admin/teacher/plan/getLessonsByCurriculamId/' + id, { headers: { "Authorization": `Bearer ${token}` } })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
};

//getAllLevelsByCurriculumId
export const getAllLevelsByCurriculumId = (id, lessonId) => {
  const token = localStorage.getItem("access_token");
  return axios.get(Config.APIURL + 'admin/teacher/plan/getAllLevelsByCurriculamId/' + id + "/" + lessonId, { headers: { "Authorization": `Bearer ${token}` } })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
};

//getDescriptionByLessonId
export const getDescriptionByLessonId = (id) => {
  const token = localStorage.getItem("access_token");
  return axios.get(Config.APIURL + 'admin/teacher/plan/getDescriptionByLessonId/' + id, { headers: { "Authorization": `Bearer ${token}` } })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
};

//getEnageStepByLessonIdAndLevel
export const getEnageStepByLessonIdAndLevel = (id, levelId) => {
  const token = localStorage.getItem("access_token");
  return axios.get(Config.APIURL + 'admin/teacher/plan/getEnageStepByLessonIdAndLevel/' + id + '/' + levelId, { headers: { "Authorization": `Bearer ${token}` } })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
};

//getEvaluateStepByLessonIdAndLevel
export const getEvaluateStepByLessonIdAndLevel = (id, levelId) => {
  const token = localStorage.getItem("access_token");
  return axios.get(Config.APIURL + 'admin/teacher/plan/getEvaluateStepByLessonIdAndLevel/' + id + '/' + levelId, { headers: { "Authorization": `Bearer ${token}` } })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
};

//getExplainStepByLessonIdAndLevel
export const getExplainStepByLessonIdAndLevel = (id, levelId) => {
  const token = localStorage.getItem("access_token");
  return axios.get(Config.APIURL + 'admin/teacher/plan/getExplainStepByLessonIdAndLevel/' + id + '/' + levelId, { headers: { "Authorization": `Bearer ${token}` } })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
};

//getExtendStepByLessonIdAndLevel
export const getExtendStepByLessonIdAndLevel = (id, levelId) => {
  const token = localStorage.getItem("access_token");
  return axios.get(Config.APIURL + 'admin/teacher/plan/getExtendStepByLessonIdAndLevel/' + id + '/' + levelId, { headers: { "Authorization": `Bearer ${token}` } })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
};

//getExploreStepByLessonIdAndLevel
export const getExploreStepByLessonIdAndLevel = (id, levelId) => {
  const token = localStorage.getItem("access_token");
  return axios.get(Config.APIURL + 'admin/teacher/plan/getExploreStepByLessonIdAndLevel/' + id + '/' + levelId, { headers: { "Authorization": `Bearer ${token}` } })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
};

//getAllClassesToLoggedUser
export const getAllClassesToLoggedUser = (id) => {
  const token = localStorage.getItem("access_token");
  return axios.get(Config.APIURL + 'admin/teacher/plan/getAllClassesToLoggedUser/' + id , { headers: { "Authorization": `Bearer ${token}` } })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
};


//getAllAssignedAndUnassignedLessonsToClass
export const getAllAssignedAndUnassignedLessonsToClass = (id,classId) => {
  const token = localStorage.getItem("access_token");
  return axios.get(Config.APIURL + 'admin/teacher/plan/getAllAssignedAndUnassignedLessonsToClass/' + id + '/' + classId, { headers: { "Authorization": `Bearer ${token}` } })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
};

// assignAndUnassignMultipleLessonToClass
export const assignAndUnassignMultipleLessonToClass = async (data, classId) => {
  let token = localStorage.getItem("access_token");

  const response = await axios.post(Config.APIURL + `admin/teacher/plan/assignAndUnassignMultipleLessonToClass?classId=${classId}`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;

    return data;
  })
    .catch((error) => {
      console.log("error", error)
      return error?.response?.data;
    });

  return response;
};