import React, { useState, Component, useEffect, useContext } from 'react';
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
    Card,
    CardBody,
    CardText,
    CardImg,
    Row,
    Col,
    CardImgOverlay,
    Badge,
    FormGroup,
    Label
} from "reactstrap";
import { message, Steps, Form, Input, Button, Drawer, Space, Select, Checkbox, Radio, Table, Popconfirm, notification, Spin } from "antd";
import "antd/dist/antd.css";

import { Source1, dataSet1 } from "./data/data"

import Question from '../components/question';
import LessonService from '../../../../api/LessonService';
import { EngageContext } from './EngageContext';
import { CheckCircleOutlined } from '@ant-design/icons';
function cancel(e) {


}
function QuestionList(props) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const { value, setValue } = useContext(EngageContext);
    const [allOptions,setAllOptions] =useState([ {
        value:'Beginner',
        id:1,

    },{
        value:'Basic',
        id:2,

    },
    {
        value:'Middle',
        id:3,

    },
    {
        value:'Advanced',
        id:4,

    },
    {
        value:'Honors',
        id:5,

    },
    ])
    const [size, setSize] = useState();
    const [editQuestions,setEditQuestions] =useState();
    const [dataSource,setDataSource] =useState([

    ])
    const [view,setView] = useState(false);

    const showLargeDrawer = () => {
        setSize('large');
        setVisible(true);
    };

    const onClose = () => {
        setEditQuestions(undefined);
        setVisible(false);
        setDataSource([])
        setLoading(true);
        LessonService.getAllQuestions(props.engage_id).then((res) =>{

                let arr =[]
                res.data.map(i =>{
                    var html = i.description;
                    var div = document.createElement("div");
                    div.innerHTML = html;
                    var text = div.textContent || div.innerText || "";
                    let obj = {
                        key: i.id,
                        id: i.id,
                        question: text,
                        leval: allOptions.filter(x => x.id === i.class_level_id).map(i => {return i.value})[0],
                        action:''
                    }
                    arr.push(obj)
                })
                if (arr.length !== 0) {
                    props.setDisableContinueButton(false);
                }
                setDataSource(prev =>([...prev,...arr]))
            }).finally(()=>{
                setLoading(false)
            })

    };
    const onEditQuesion =(data) =>{

        LessonService.getSpecificQuestion(data).then((res) =>{
            setEditQuestions(res.data)
            if(Object.keys(res.data).length > 0){
                showLargeDrawer();
            }

        })


    }
    console.log("test",value)

    const showNotificationOnTop = (type, message) => {
        notification.open({
          placement: 'top',
          duration: '2',
          description: [
            type === 'success' ?
            <li>
              <CheckCircleOutlined className="text-success ant-icon-up" />
              <span className="ml-4">{message}</span>
            </li>
            : 
            <li>
              <CheckCircleOutlined className="text-danger ant-icon-up" />
              <span className="ml-4">{message}</span>
            </li>
          ]
        })
      }

    const onDeleteQuestion =(data) =>{
        setLoading(true)
        LessonService.deleteQuestion(data).then(res =>{
            console.log(res)
            if(res?.status === 200){
                showNotificationOnTop('success', res.message);
            }
            else{
                showNotificationOnTop('error', res.message);
            }
            setDataSource([])
            LessonService.getAllQuestions(props.engage_id).then((res) =>{

                let arr =[]

                res.data.map(i =>{
                    var html = i.description;
                    var div = document.createElement("div");
                    div.innerHTML = html;
                    var text = div.textContent || div.innerText || "";
                    let obj = {
                        key: i.id,
                        id: i.id,
                        question: text,
                        leval: allOptions.filter(x => x.id === i.class_level_id).map(i => {return i.value})[0],
                        action:''
                    }
                    arr.push(obj)
                })
                if (arr.length === 0) {
                    props.setDisableContinueButton(true);
                }
                setDataSource(prev =>([...prev,...arr]))
            })
        }).finally(()=>{
            setLoading(false)
        })
    }
    const dataSet1 = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
        },
        // {
        //     title: ' Level',
        //     dataIndex: 'leval',
        //     key: 'leval',
        //     render: (_, record) => (
        //         <Space size="middle">
        //           {record.action} 
        //           <Badge  className='bg-success' size="sm">
        //           <small>{record.leval}</small>
        //           </Badge >

        //         </Space>
        //       ),
        // },
        {
            title: ' Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {record.action}
                    <Badge  className='bg-warning' size="sm"  onClick={() =>{
                        onEditQuesion(record.id)
                        setView(true)
                    }}>
                        <small>View</small>
                    </Badge >
                    {
                        props.type !== 2 && <>
                            <Badge  className='bg-primary' size="sm" onClick={() =>{
                                onEditQuesion(record.id)
                                setView(false)
                            }}  >
                                <small>Edit</small>
                            </Badge >
                            <Popconfirm
                                title="Are you sure to delete this?"
                                onConfirm={(e) =>onDeleteQuestion(record.id)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            ><Badge  className='bg-danger' size="sm" >
                                    <small>Remove</small>
                                </Badge ></Popconfirm>
                        </>
                    }


                </Space>
            ),
        },
    ];
    useEffect(() =>{
        setLoading(true)
        LessonService.getAllQuestions(props.engage_id).then((res) =>{

            let arr =[]
            res.data.map(i =>{
                var html = i.description;
                var div = document.createElement("div");
                div.innerHTML = html;
                var text = div.textContent || div.innerText || "";
                let obj = {
                    key: i.id,
                    id: i.id,
                    question: text ,
                    leval: allOptions.filter(x => x.id === i.class_level_id).map(i => {return i.value})[0],
                    action:''
                }
                arr.push(obj)
            })

            if (arr.length !== 0) {
                props.setDisableContinueButton(false);
            }
            setDataSource(prev =>([...prev,...arr]))
        }).finally(()=>{
            setLoading(false)
        })
    },[props.engage_id])

    return (
        <>
            <div className="container" >
                <Row>
                    <Col xs='12' className='py-2'>
                        <div className='text-center'>
                            {
                                props.type !== 2 &&  <button class="btn btn-info" style={{ padding: "8px 32px" }} onClick={()=>{
                                    setEditQuestions(undefined)
                                    showLargeDrawer();
                                    setView(false)

                                }} >
                                    Add a new Question
                                </button>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <CardBody>
                            <Row>
                                <Col xs='12' className="mb-5">
                                    <Table loading={{indicator: <Spin size='large' className='spinner-new' tip='Loading...' />, spinning: loading}} dataSource={dataSource} columns={dataSet1} pagination={false} bordered className="thead-dark space-py-20" />
                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                </Row>
                <Drawer
                    title={`Engage | Add New Question`}
                    placement="right"
                    size={size}
                    onClose={onClose}
                    visible={visible}
                    destroyOnClose={true}
                >
                    <Question onClose={onClose} view={view} engage_id={props.engage_id} currentClassLevel={props.currentClassLevel} editData={editQuestions} />
                </Drawer>
                <Row>
                    <Col>
                        {
                            props.back
                        }
                    </Col>
                    <Col className='pull-right'>
                        {
                            props.continue
                        }
                    </Col>
                </Row>

            </div>
        </>
    );
}

export default QuestionList;
