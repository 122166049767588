import { useState, useEffect } from "react";
import {
  Card,
  CardText,
  FormFeedback,
  Row,
  Col,
  Input,
  Button,
  FormGroup,
} from "reactstrap";
import { Alert, Spin } from "antd";
import AccountAdminService from "../../../../api/AccountAdminService";
import useCustFormValidator from "../../../../hooks/useCustFormValidator";

import { TableValue } from "../../../Module/ChartType.Module";
import validate from "./dataTableValidation";

function AdminCreate(props) {
  var obj = {};

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [uneditedObj, setUneditedObj] = useState({});
  const [alertData, setAlertData] = useState({
    showAlert: false,
    message: "",
    type: "success",
  });
  const [showPass, setShowPass] = useState(false);

  const { handleChange, handleSubmit, setFormData, reset, values, errors } =
    useCustFormValidator(onSave, validate, obj);

  //   useEffect(() => {
  //     loadAccounts();
  //   }, []);

  //   useEffect(() => {
  //     getAdminFromId();
  //   }, [props.selectedId]);
  useEffect(() => {
    setFormData({})
    // console.log("updated",props.updateDataTableValue)
    if (props.updateDataTableValue?.length > 0) {
      // console.log("1",props.updateDataTableValue)
      props.updateDataTableValue?.map(x => {
        setFormData(prev => ({ ...prev, ...{ [x.value]: true, [`si${x.value}`]: x.si } }))
      })
    }
    else {
      setFormData(
        {
          v: false,
          siv: "",
          v2: false,
          siv2: "",
          fit: false,
          sifit: "",
          a: false,
          sia: "",
          max: false,
          simax: "",
          slope: false,
          sislope: "",
          halfTime: false,
          sihalfTime: "",
          period: false,
          siperiod: "",
        }
      )
    }



  }, [props.updateDataTableValue])



  useEffect(() => {
    // console.log("val",values)
    let arr = [];
    if (Object.keys(values).length > 0) {
      if (values.v === true) {
        let obj1 = {
          value: "v",
          si: values.siv
        }
        arr.push(obj1)
      }
      else if (values.v === false) {
        arr.filter(x => x.value !== "v")
      }
      if (values.v2 === true) {
        let obj2 = {
          value: "v2",
          si: values.siv2
        }
        arr.push(obj2)
      }
      else if (values.v2 === false) {
        arr.filter(x => x.value !== "v2")
      }
      if (values.fit === true) {
        let obj3 = {
          value: "fit",
          si: values.sifit
        }
        arr.push(obj3)
      }
      else if (values.fit === false || values.fit === undefined) {
        arr.filter(x => x.value !== "fit")
      }
      if (values.a === true) {
        let obj4 = {
          value: "a",
          si: values.sia
        }
        arr.push(obj4)
      }
      else if (values.a === false) {
        arr.filter(x => x.value !== "a")
      }
      if (values.max === true) {
        let obj5 = {
          value: "max",
          si: values.simax
        }
        arr.push(obj5)
      }
      else if (values.max === false) {
        arr.filter(x => x.value !== "max")
      }

      if (values.slope === true) {
        let obj6 = {
          value: "slope",
          si: values.sislope
        }
        arr.push(obj6)
      }
      else if (values.slope === false) {
        arr.filter(x => x.value !== "slope")
      }
      if (values.halfTime === true) {
        let obj7 = {
          value: "halfTime",
          si: values.sihalfTime
        }
        arr.push(obj7)
      }
      else if (values.halfTime === false) {
        arr.filter(x => x.value !== "halfTime")
      }

      if (values.period === true) {
        let obj8 = {
          value: "period",
          si: values.siperiod
        }
        arr.push(obj8)
      }
      else if (values.period === false) {
        arr.filter(x => x.value !== "period")
      }
      // console.log("output",arr)
      props.setDataTableValue(arr)
    }
  }, [values]);

  //   function loadAccounts() {

  //   }

  //   function getAdminFromId() {

  //   }







  function onSave() {

  }



  return (
    <>
      <div>
        <Spin tip="Loading..." spinning={loading} size="large">
          <Row>
            <Col md="6">
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <FormGroup check inline>
                    <Input
                      disabled={props.disabled}
                      type="checkbox"
                      checked={values.v}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setFormData({ ...values, siv: "", v: false })
                        }
                        else {
                          setFormData({ ...values, v: e.target.checked, siv: "" })
                        }
                      }}
                    />
                    <label check className="pt-6">
                      Volume 1
                    </label>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row" hidden={!values.v}>
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example6">
                    SI unit
                  </label>
                  <Input
                    disabled={props.disabled}
                    type="text"
                    id="form3Example6"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.siv}
                    name="siv"
                    onChange={handleChange}
                    invalid={!!errors?.siv}
                  />
                  <FormFeedback>{errors?.siv}</FormFeedback>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <FormGroup check inline>
                    <Input
                      disabled={props.disabled}
                      type="checkbox"
                      checked={values.v2}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setFormData({ ...values, siv2: "", v2: false })
                        }
                        else {
                          setFormData({ ...values, v2: e.target.checked, siv2: "" })
                        }
                      }}
                    />
                    <label check className="pt-6">
                      Volume 2
                    </label>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row" hidden={!values.v2}>
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example6">
                    SI unit
                  </label>
                  <Input
                    disabled={props.disabled}
                    type="text"
                    id="form3Example6"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.siv2}
                    name="siv2"
                    onChange={handleChange}
                    invalid={!!errors?.siv2}
                  />
                  <FormFeedback>{errors?.siv2}</FormFeedback>
                </div>
              </div>
            </Col>

            <Col md="6">
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <FormGroup check inline>
                    <Input
                      disabled={props.disabled}
                      type="checkbox"
                      checked={values.fit}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setFormData({ ...values, sifit: "", fit: false })
                        }
                        else {
                          setFormData({ ...values, fit: e.target.checked, sifit: "" })
                        }
                      }}
                    />
                    <label check className="pt-6">
                      Fit
                    </label>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row" hidden={!values.fit}>
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example6">
                    SI unit
                  </label>
                  <Input
                    disabled={props.disabled}
                    type="text"
                    id="form3Example6"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.sifit}
                    name="sifit"
                    onChange={handleChange}
                    invalid={!!errors?.sifit}
                  />
                  <FormFeedback>{errors?.sifit}</FormFeedback>
                </div>

              </div>
            </Col>

            <Col md="6">
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <FormGroup check inline>
                    <Input
                      disabled={props.disabled}
                      type="checkbox"
                      checked={values.a}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setFormData({ ...values, sia: "", a: false })
                        }
                        else {
                          setFormData({ ...values, a: e.target.checked, sia: "" })
                        }
                      }}
                    />
                    <label check className="pt-6">
                      Acceleration
                    </label>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row" hidden={!values.a}>
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example6">
                    SI unit
                  </label>
                  <Input
                    disabled={props.disabled}
                    type="text"
                    id="form3Example6"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.sia}
                    name="sia"
                    onChange={handleChange}
                    invalid={!!errors?.sia}
                  />
                  <FormFeedback>{errors?.sia}</FormFeedback>
                </div>

              </div>
            </Col>

            <Col md="6">
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <FormGroup check inline>
                    <Input
                      disabled={props.disabled}
                      type="checkbox"
                      checked={values.max}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setFormData({ ...values, simax: "", max: false })
                        }
                        else {
                          setFormData({ ...values, max: e.target.checked, simax: "" })
                        }
                      }}
                    />
                    <label check className="pt-6">
                      Maximum
                    </label>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row" hidden={!values.max}>
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example6">
                    SI unit
                  </label>
                  <Input
                    disabled={props.disabled}
                    type="text"
                    id="form3Example6"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.simax}
                    name="simax"
                    onChange={handleChange}
                    invalid={!!errors?.simax}
                  />
                  <FormFeedback>{errors?.simax}</FormFeedback>
                </div>

              </div>
            </Col>

            <Col md="6">
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <FormGroup check inline>
                    <Input
                      disabled={props.disabled}
                      type="checkbox"
                      checked={values.slope}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setFormData({ ...values, sislope: "", slope: false })
                        }
                        else {
                          setFormData({ ...values, slope: e.target.checked, sislope: "" })
                        }
                      }}
                    />
                    <label check className="pt-6">
                      Slope
                    </label>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row" hidden={!values.slope}>
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example6">
                    SI unit
                  </label>
                  <Input
                    disabled={props.disabled}
                    type="text"
                    id="form3Example6"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.sislope}
                    name="sislope"
                    onChange={handleChange}
                    invalid={!!errors?.sislope}
                  />
                  <FormFeedback>{errors?.sislope}</FormFeedback>
                </div>

              </div>
            </Col>
            

            <Col md="6">
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <FormGroup check inline>
                    <Input
                      disabled={props.disabled}
                      type="checkbox"
                      checked={values.halfTime}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setFormData({ ...values, sihalfTime: "", halfTime: false })
                        }
                        else {
                          setFormData({ ...values, halfTime: e.target.checked, sihalfTime: "" })
                        }
                      }}
                    />
                    <label check className="pt-6">
                      Half Time
                    </label>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row" hidden={!values.halfTime}>
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example6">
                    SI unit
                  </label>
                  <Input
                    disabled={props.disabled}
                    type="text"
                    id="form3Example6"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.sihalfTime}
                    name="sihalfTime"
                    onChange={handleChange}
                    invalid={!!errors?.sihalfTime}
                  />
                  <FormFeedback>{errors?.sihalfTime}</FormFeedback>
                </div>

              </div>
            </Col>

            <Col md="6" hidden={props.selectedItems.length > 0 ? props.selectedItems !== "Line Type2" : props.selectedItems2 !== "Line Type2"}>
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <FormGroup check inline>
                    <Input
                      disabled={props.disabled}
                      type="checkbox"
                      checked={values.period}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setFormData({ ...values, siperiod: "", period: false })
                        }
                        else {
                          setFormData({ ...values, period: e.target.checked, siperiod: "" })
                        }
                      }}
                    />
                    <label check className="pt-6">
                      Period
                    </label>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row" hidden={!values.period}>
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example6">
                    SI unit
                  </label>
                  <Input
                    disabled={props.disabled}
                    type="text"
                    id="form3Example6"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.siperiod}
                    name="siperiod"
                    onChange={handleChange}
                    invalid={!!errors?.siperiod}
                  />
                  <FormFeedback>{errors?.siperiod}</FormFeedback>
                </div>

              </div>
            </Col>
          </Row>

        </Spin>
      </div>
    </>
  );
}

export default AdminCreate;
