import { combineReducers } from "redux";
// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import navigation from "./navigation.js";

import authReducer from "./authReducer.js";

// const persistConfig = {
//   key: "root",
//   storage, 
//   whitelist: ["auth"]
// }

const rootReducer = combineReducers({
  navigation,
  auth: authReducer
 
});


// export default persistReducer(persistConfig, rootReducer);
export default (rootReducer);

