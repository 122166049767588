import React, { useState } from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import regression from 'regression';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);


function SplitLineChart({ chartData, dataFromGraph }) {
    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false,
        responsive:true,
    };

   

    if (chartData?.type === 1) {
        const slopValue = []
        chartData?.labels.map((x, index)=>{
            const slope = (chartData?.datasets[0]?.yData[x-1]-chartData?.datasets[0]?.yData[x-2])/(chartData?.datasets[0]?.xData[x-1]-chartData?.datasets[0]?.xData[x-2]);
            if(Number.isNaN(slope)){
                slopValue.push(1);    
            }else{
                slopValue.push(slope);            
            }
        });

        const differentValue = []

        slopValue.map((x, index) =>{
            const difference = slopValue[index+1]-x;
            if(!Number.isNaN(difference)){
                if(index !== 0){
                    differentValue.push({value: Math.abs(difference), index: index});
                }
            }
        });

        const sortedValue =  differentValue.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
        const breakerIndex = sortedValue[0]?.index

        //end of calculation
        
       const firstLineData = [];
       const secondLineData = [];

       const firstDataSetY =[];
       const secondDataSetY =[];
       const firstDataSetX =[];
       const secondDataSetX =[];
       chartData?.datasets[0]?.yData.map((x, index)=>{
        if(index <= breakerIndex){
            firstDataSetY.push(x)
        }else{
            secondDataSetY.push(x)
        }
   });
   chartData?.datasets[0]?.xData.map((x, index)=>{
    if(index <= breakerIndex){
        firstDataSetX.push(x)
    }else{
        secondDataSetX.push(x)
    }
});

       chartData?.datasets[0]?.xyData.map((x, index)=>{
            if(index <= breakerIndex){
                firstLineData.push([x[0],parseFloat(x[1])])
            }else{
                secondLineData.push([x[0],parseFloat(x[1])])
            }
       });

       const result1 = regression.linear(firstLineData);
       const result2 = regression.linear(secondLineData);


        let regressionData1 = [];
        let regressionData2 = [];
        const splitCombine = []

        const fitValue = (result1?.r2) * 100;
        const fitValue2 = (secondDataSetY[secondDataSetY.length - 1] - secondDataSetY[0]) / (secondDataSetX[secondDataSetX.length - 1] - secondDataSetX[0]);
        const speed = (firstDataSetY[(firstDataSetY.length) - 1] - firstDataSetY[0]) / (firstDataSetX[(firstDataSetX.length) - 1] - firstDataSetX[0]);
        
        dataFromGraph(fitValue, speed.toFixed(3),fitValue2.toFixed(3));
        
        
        
            for (let i = 0; i <= breakerIndex; i++) {
                let xVal = chartData?.datasets[0]?.xData[i];
                let yVal = result1?.equation[0] * xVal + result1.equation[1];
    
                regressionData1.push({ x: xVal, y: yVal });
                

                if(i === breakerIndex) {
                    splitCombine.push({ x: xVal, y: yVal })
                }

            }  

            for (let j = chartData?.labels?.length-1; j > breakerIndex; j--) {
                
                let xVal = chartData?.datasets[0]?.xData[j];
                let yVal = result2?.equation[0] * xVal + result2.equation[1];
    
                regressionData2.push({ x: xVal, y: yVal })
                
                if(j === breakerIndex+1) {
                    splitCombine.push({ x: xVal, y: yVal })
                }
            }        
        
        const data = {
            datasets: [
                {
                    type: 'scatter',
                    pointStyle: "star",
                    borderColor: '"black"',
                    pointRadius: 1.5,
                    data: chartData?.datasets[0]?.xyData,
                },
                {
                    type: 'line',
                    pointRadius: 0,
                    data: regressionData2,
                    borderColor: chartData?.datasets[0]?.borderColor,
                    borderWidth: 1.5,

                },
                {
                    type: 'line',
                    pointRadius: -1,
                    data: regressionData1,
                    borderColor: chartData?.datasets[0]?.borderColor,
                    borderWidth: 1.5,

                },
                {
                    type: 'line',
                    pointRadius: -1,
                    data: splitCombine,
                    borderColor: chartData?.datasets[0]?.borderColor,
                    borderWidth: 1.5,
                    lineTension: 0.6,
                }
            ],
        };
        return (
            <>
                <Chart type='bar' data={ data } options={options} />
            </>
        );
    } else {

        const mixDataValue = [];

        chartData.map((x) => {
             //assign and making the pars for computation
                const slopValue = [];
                x.categoryData.map((y)=>{
                    const slope = (x.yData[y-1]-x.yData[y-2])/(x.xData[y-1]-x.xData[y-2]);
                    if(Number.isNaN(slope)){
                        slopValue.push(1);    
                    }else{
                        slopValue.push(slope);            
                    }
                });


                const differentValue = []

                slopValue.map((x, index) =>{
                    const difference = slopValue[index+1]-x;
                    if(!Number.isNaN(difference)){
                        if(index !== 0){
                            differentValue.push({value: Math.abs(difference), index: index});
                        }
                    }
                });

                const sortedValue =  differentValue.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
                const breakerIndex = sortedValue[0]?.index;
                //end of calculation

            const firstLineData = [];
            const secondLineData = [];
     
            x?.xyData.map((x, index)=>{
                 if(index <= breakerIndex){
                     firstLineData.push([x[0],parseFloat(x[1])])
                 }else{
                     secondLineData.push([x[0],parseFloat(x[1])])
                 }
            });
     
            const result1 = regression.linear(firstLineData);
            const result2 = regression.linear(secondLineData);
            const splitCombine = []
     
            // const result = regression.linear(x.xyData);

            let regressionData = [];
            let regressionData3 = [];

                for (let i = 0; i <= breakerIndex; i++) {
                    let xVal = x.xData[i];
                    let yVal = result1?.equation[0] * xVal + result1.equation[1];
                    regressionData.push({ x: xVal, y: yVal })
                    if(i === breakerIndex) {
                        splitCombine.push({ x: xVal, y: yVal })
                    }
                }  
                for (let j = x?.categoryData?.length-1; j > breakerIndex; j--) {
                    let xVal = x.xData[j];
                    let yVal = result2?.equation[0] * xVal + result2.equation[1];
                    regressionData3.push({ x: xVal, y: yVal })
                    if(j === breakerIndex+1) {
                        splitCombine.push({ x: xVal, y: yVal })
                    }
                }  
           
            const payload1 = {
                type: 'scatter',
                pointStyle: "star",
                borderColor: 'black',
                pointRadius: 1.5,
                data: x.xyData,
            }


            const payload2 = {
                type: 'line',
                pointRadius: -1,
                data: regressionData,
                borderColor: x.borderColor,
                borderWidth: 1.5,

            }

            const payload3 = {
                type: 'line',
                pointRadius: -1,
                data: regressionData3,
                borderColor: x.borderColor,
                borderWidth: 1.5,

            }
            const payload4 = {
                type: 'line',
                pointRadius: -1,
                data: splitCombine,
                borderColor: x.borderColor,
                borderWidth: 1.5,

            }


                mixDataValue.push(payload1)
                mixDataValue.push(payload2)
                mixDataValue.push(payload3)
                mixDataValue.push(payload4)
            

           
        });

        const data = {
            datasets: mixDataValue,
        };


        return (
            <>
                <Chart type='bar' data={data} options={options} />
            </>
        );
    }




}

export default SplitLineChart;