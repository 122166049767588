import { useState, useEffect } from 'react';

const useCustFormValidator = (callback, validate, initValues) => {

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setValues(initValues)
  }, []);

  useEffect(() => {
    if (isSubmitting) {
      let err = validate(values);
      setErrors(err);
    }
  }, [values]);

  const handleSubmit = (event) => {
    let err = validate(values);
    
    setErrors(err);
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    if (Object.keys(err).length === 0) {
      Object.keys(values).map(k => values[k] = trimObjectStrings(k));
      callback();
      setIsSubmitting(false);
    }
  };

  const handleChange = (event,event2 = null,custom_id_name = null) => {
    if(event2 === null ){
      event.persist();
      setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    }else{
      let key = "accountId";
      if(custom_id_name !== null){
        key = custom_id_name;
      }
      setValues(values => ({ ...values, [key]: event2.key }));
    }
  };

  function setFormData(data) {
    setValues(data);
  }

  function reset() {
    setErrors({});
    setIsSubmitting(false)
  }

  function trimObjectStrings(key){
    if(typeof values[key] == 'string' && !key.toLowerCase().includes("password")){
      return values[key].trim();
    }
    return values[key];
  }

  return {
    handleChange,
    handleSubmit,
    setFormData,
    reset,
    values,
    errors,
  }
};

export default useCustFormValidator;