import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: ' ',
    },
  },
  responsive : true,
  maintainAspectRatio : false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      max: 25,
      min: 0
    },
  }
};

export function MultipleBar(props) {
  
  const [xData, setXData] = useState([]);
  const [yData, setYData] = useState([]);


  useEffect(()=>{

    if(props?.chartData?.datasets){
      console.log(props)
      if(props?.chartData?.datasets[0] !== undefined){
        setXData(props?.chartData?.datasets[0]?.xData)
  
        const result = props?.chartData?.datasets[0]?.yData.reduce((acc, subArr) => {
          subArr.forEach((num, i) => {
            if (!acc[i]) acc[i] = [];
            acc[i].push(num);
          });
          return acc;
        }, []);
        setYData(result)
      }
    }
  },[props])
  const labels = xData;

  const data = {
    labels,
    datasets: [
      {
        label: 'Ladybug',
        data: yData[0],
        backgroundColor: 'rgb(255, 51, 51)',
      },
      {
        label: 'Grasshopper',
        data: yData[1],
        backgroundColor: '#3F7E10',
      },
      {
        label: 'Pillbug',
        data: yData[2],
        backgroundColor: '#302E2C',
      },
      {
        label: 'Scarab',
        data: yData[3],
        backgroundColor: '#676767',
      },
      {
        label: 'Aphid',
        data: yData[4],
        backgroundColor: 'rgb(136, 181, 54)',
      },
    ],
  };
  
  return <Bar key={JSON.stringify(props.chartData)} options={options} data={data} />;
}