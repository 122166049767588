import React from 'react'
import { BarChart } from '../../../../components/Charts/BarChart';
import CurveChart from '../../../../components/Charts/CurveChart';
import { NormalLineChart } from '../../../../components/Charts/NormalLineChart';
import LineRegressionChart from '../../../../components/Charts/LineRegressionChart';
import SplitLineChart from '../../../../components/Charts/SplitLineChart';
import SimpleLineChart from '../../../../components/Charts/simpleLineChart';
import SimpleCurveChart from '../../../../components/Charts/SimpleCurveChart';
import { BarChartWithYesNo } from '../../../../components/Charts/BarChartWithYesNo';
import SinChart from '../../../../components/Charts/sinChart';
import CurveRegressionChart from '../../../../components/Charts/CurveRegressionChart';
import { BarChartMultiClick } from '../../../../components/Charts/BarChartMultiClick';
import Trajectory from '../../../../components/Charts/Trajectory';
import { MultipleBar } from '../../../../components/Charts/MultipleBar';
import EnergyChart from '../../../../components/Charts/EnergyChart';
import { MultipleBarDynamic } from '../../../../components/Charts/MultipleBarDynamic';
import { MultipleBarDynamic2 } from '../../../../components/Charts/MultipleBarDynamic2';
import CircleLineChart from '../../../../components/Charts/CircleLineChart';

export default function ChartValidations(props) {
  console.log(props.type)
  let element = null;
  switch (props.type) {

    case "splitLine":
    // case "Line Volume Defined":
      case "SplitLineChart":
      element = <SplitLineChart name={props?.heading} chartData={props?.chartData} dataFromGraph={props?.dataFromGraph} data={props?.data} />
      break;
    case "Line":
    case "LineRegressionChart":
    case "Line Volume Defined":
    case "Line Type9":

          element = <LineRegressionChart type={props.type} name={props?.heading} chartData={props?.chartData} dataFromGraph={props?.dataFromGraph} data={props?.data} />
          break;
      case "Position2":
        case "Line Type2":
          case "SinChart":
      element = <SinChart name={props?.heading} chartData={props?.chartData} dataFromGraph={props?.dataFromGraph} data={props?.data} />
      break;
          case "Line Type3":
    case "Line Radial Defined":
    case "Simple Line":
    case "Line Temperature Defined":
    case "SimpleLineChart":
    case "Line Force Defined":
    case "normal":
    case "Line Type4":
      element = <SimpleLineChart name={props?.heading} chartData={props?.chartData} dataFromGraph={props?.dataFromGraph} data={props?.data} />
      break;
    case "Line Type12":
      element = <CircleLineChart name={props?.heading} dataFromGraph={props?.dataFromGraph} chartData={props?.chartData} data={props?.data} />
      break;
    case "Bar":
    case "BarChart":
    case "Line Type5":
        element = <BarChart name={props.heading} data={props.data} height={400} />
        break;
    case "Bar YesNo":
      case "BarChartWithYesNo":
      element = <BarChartWithYesNo name={props.heading} data={props.data} height={400} />
      break;
    case "curve":
    case "simple curve":
    case "CurveChart":

      element = <CurveChart name={props.heading} data={props.data} />
      break;
    case "curve2":
      element = <CurveRegressionChart name={props?.heading} chartData={props?.chartData} dataFromGraph={props?.dataFromGraph} data={props?.data} />
      break;
    case "Bar Click2Count":
      element = <BarChartMultiClick name={props?.heading} chartData={props?.chartData} dataFromGraph={props?.dataFromGraph} data={props?.data} />
      break;
    case "Line Type6":
      element = <Trajectory name={props?.heading} chartData={props?.chartData} />
      break;
    case "Line Type7":
      element = <MultipleBar name={props?.heading} chartData={props?.chartData} />
      break;
    case "Line Type8":
      element = <EnergyChart name={props?.heading} chartData={props?.chartData} />
    break;
    case "Line Type10":
      element = <MultipleBarDynamic name={props?.heading} chartData={props?.chartData} />
    break;
    case "Line Type11":
      element = <MultipleBarDynamic2 name={props?.heading} chartData={props?.chartData} />
    break;
  }
  return (
    <div style={{ height: "300px" }}>
      {element}
    </div>
  )
}
