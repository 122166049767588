import React from "react";
import { Redirect } from 'react-router-dom'
import jsonToken from 'jsonwebtoken'

const Home = () => {
    let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
    function hasRole(role) {
        let index = accessToken.roles.findIndex(element => {
            return element.toLowerCase() === role.toLowerCase();
        });
        return index > -1;
    }
    
    if (hasRole('SUPER_ADMIN')){
        return (<Redirect to='/MyAccount' />);//todo: Set correct one
    }else if (hasRole('SCHOOL_ADMIN')){
        return (<Redirect to='/MyAccount' />);//todo: Set correct one
    }else if (hasRole('ACCOUNT_ADMIN')){
        return (<Redirect to='/MyAccount' />);//todo: Set correct one
    }else if (hasRole('TEACHER')) {
        return (<Redirect to={`/Instructor/1/Classes/${accessToken.userId}`} />);
    } else if (hasRole('STUDENT')){
        return (<Redirect to='/ClassIndex' />);
    }else {
        return(<div>Unknown Role</div>);
    }
}

export default Home;