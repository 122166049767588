// import { createStore, applyMiddleware } from 'react-redux'
// import logger from "redux-logger"
// import { persistStore } from "redux-presist"
// import thunk from "redux-thunk"

// import rootReducer from "./rootReducer"
import { configureStore } from '@reduxjs/toolkit'
// import { reducer } from '../rootSlicec'

import { GetTermReducer } from './termSlice.js'
import { AccountReducer } from './accountSlice.js'
import { studentreducer } from './studentSlice.js'
import { GetTeacherReducer } from './teacherSlice.js'
import { GetCounterReducer } from './coeunterSlice.js'
import { schoolReducer } from './schoolSlice.js'
import { GraphDatareducer } from './graphDataSlice.js'
import { schoolAdminReducer } from './schoolAdminSlice.js'; 
import { TermApi } from '../api/TermApi.js'


// const middleware = {thunk};

// if(process.env.NODE_ENV === "development") {
//     middleware.push(logger);

// }

// export const store = createStore(rootReducer, applyMiddleware(...middleware));

export const store = configureStore({
    reducer: {
        [TermApi.reducerPath]: TermApi.reducer,
        counter: GetCounterReducer,
        app: GetTermReducer,
        account: AccountReducer,
        school: schoolReducer,
        student: studentreducer,
        teacher: GetTeacherReducer,
        graph: GraphDatareducer,
        schoolAdmin: schoolAdminReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(TermApi.middleware)

})

// export const persiststore = persistStore(store);
