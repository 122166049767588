import { useEffect, useState } from "react";
// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Breadcrumb, Radio, Input, Form, Modal, Button, Tabs, Checkbox, Image, Spin, notification } from "antd";
import "antd/dist/antd.css";
import jsonToken from 'jsonwebtoken';
import RestService from "../../../api/RestService";
import AnswerDisplay from "../AnswerDisplay";
import FloatingButton from "../../../components/FloatingButton";
import InstructorComment from "../components/InstructorComment/InstructorComment";
import Pdf from "../../../components/Pdf";
import { ExclamationCircleOutlined } from '@ant-design/icons';
function Extend(props) {
  const [payload, setPayload] = useState();

  const [initPayload, setInitPayload] = useState({
    description: "",
    extendStepQuestionAnswers: [],
    extendStepQuestions: [],
    id: "",
    lesson_id: "",
    guideline: "",
  });

  const [bluredLayer, setBluredLayer] = useState("");

  const [containedPayload, setContainedPayload] = useState({});
  const [reviewStatus, setReviewStatus] = useState(props.disabled ?? true);
  const [lesson, setLesson] = useState(parseInt(props.extendId));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");
  const [lessonName, setLessonName] = useState("");
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loadingText, setLoadingText] = useState("Loading...");

  const [freezingStatus, setFreezingStatus] = useState(false)

  const [teacherComment, setTeacherComment] = useState("");
  const [teacherCommentStats, setTeacherCommentStats] = useState(false);
  function convert(data) {
    return Array.isArray(data)
      ? data.reduce((obj, el, i) => (el + 1 && (obj[i] = convert(el)), obj), {})
      : data;
  }

  let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;

  }


  useEffect(() => {
    setLoading(true)
    setLoading2(true)

    RestService.getExtendStepByIdAndUserName(props.user, props.extendId)
      .then((response) => {
        if (response) {
          if (response?.data[0]?.evaluated !== 0) {
            setFreezingStatus(true)
          } else {
            setFreezingStatus(false)
          }
          if (response?.data[0]?.comment !== null && response?.data[0]?.comment !== undefined && response?.data[0]?.comment !== "") {
            setTeacherCommentStats(true)
            setTeacherComment(response?.data[0]?.comment)
          }
          setContainedPayload(response?.data[0]?.response_json?.answers);
          setPayload(convert(response?.data[0]?.response_json?.answers))
          setLoading(false)
          setLoading2(false)
          // setBluredLayer(" ")

        } else {
          setLoading(false)
          setLoading2(true)
          // setLoadingText("Student has not answer yet")
          // notification.warning({
          //   message: `Notification`,
          //   description:
          //     'Student has not answer yet',
          //   placement:'bottom',
          //   duration: 0,
          // });
          // setBluredLayer("blured-content")
        }
      })

  }, [props.extendId, props.user])

  useEffect(() => {
    setLoading(true)
    if (props.extendId && props.extendId !== null) {
      RestService.getExtendInfosById(props.extendId)
        .then((response) => {
          setInitPayload(response?.data);
          setLoading(false)
          setLessonName(response?.data?.lessonName);
        })
    }
  }, [props.extendId])


  const { TabPane } = Tabs;

  const [classLessonName, setClassLessonName] = useState(localStorage.getItem("className"));
  const { TextArea } = Input;

  const ReviewFun = () => {
    setReviewStatus(false)
    window.scrollTo(0, 0)
  };
  const changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const count = parseInt(name) + 1;
    let index = null;
    for (const [key, value] of Object.entries(payload)) {
      if (value.question_id == count) {
        index = key;
        break;
      }
    }
    if (index != null) {
      let changed_payload = payload;
      changed_payload[index] = { question_id: count, answer: value };
      setPayload(changed_payload);
    } else {
      setPayload(prev => ({
        ...prev,
        [name]: { question_id: count, answer: value }
      }));
    }
  };

  const submitFun = async () => {
    if (payload === undefined) {
      setValidationMsg("Answer at least on question to proceed")
      setIsModalOpen(true);
    }
    else if (initPayload.extendStepQuestions.length > Object.keys(payload).length) {
      setValidationMsg("Did you finish all parts of your extra-credit report? You can review any part of your work on the lesson by navigating through the tabs")
      setIsModalOpen(true);

    } else {
      setLoading(true)
      const submitPayload = {
        username: user,
        userId: initPayload.id,
        status: 2,
        response_json: {
          answers: Object.values(payload)
        },
        last_update_date: new Date().toISOString().slice(0, 10),
        extend_step_id: lesson,
      }


      await RestService.postExtendStepUserResponses(submitPayload)
        .then((response) => {

          if (response.status === 200) {

            const end = new Date();
            props.getEndDuration(end);
            const url = (window.location.href).split("#");
            window.open(url[0], "_self")
          }
          setLoading(false)


        })
    }
  }

  const submitFun2 = async () => {
    const url = (window.location.href).split("#");
    window.open(url[0], "_self")
  }

  const handleOk1 = async () => {
    setReviewStatus(false);
    setIsModalOpen(false);
    if (payload !== undefined) {
      setLoading(true)

      const submitPayload = {
        username: user,
        userId: initPayload.id,
        status: 2,
        response_json: {
          answers: Object.values(payload)
        },
        last_update_date: new Date().toISOString().slice(0, 10),
        extend_step_id: lesson,
      }
      await RestService.postExtendStepUserResponses(submitPayload)
        .then((response) => {

          if (response.status === 200) {
            const end = new Date();
            props.getEndDuration(end);
            const url = (window.location.href).split("#");
            window.open(url[0], "_self")
          }
          setLoading(false)
        })
    }
  };

  const handleCancel1 = () => {

    setReviewStatus(true);
    window.scrollTo(0, 0);
    setIsModalOpen(false);
  };
  const TSdate = Date.now();
  const dateFormat = new Date(TSdate);
  const MonthYear = dateFormat.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
  const HrsMinSec = dateFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });

  const uploadBtnFun = (x) => {
    setLoading(x)
  }
  useEffect(() => {
    if (initPayload?.extendStepQuestions) {
      localStorage.setItem("bodyCountExtend", initPayload?.extendStepQuestions.length);
    }
  }, [initPayload])

  const antIcon = <ExclamationCircleOutlined style={{ fontSize: 36 }} />;
  return (

    <Spin tip="Loading..." spinning={loading} size="large">
      <div className="page-with-sub-spinners">
        {/* <Spin tip={loadingText} spinning={loading2} size="large" indicator={antIcon}> */}
          <div className="content extend-page tab-style scroll-tab">
          <div className={`${bluredLayer}`} />

            <div className="card-chart p-3 card card-grey no-border">
              <div className="card-body">
                <Modal title="" visible={isModalOpen} onOk={handleOk1} onCancel={handleCancel1}>
                  <p>{validationMsg}</p>
                </Modal>
                <div id="headerDescriptionExtend">

                  <h2><center id="header1info">Smart Science <sup>&reg;</sup>Review</center></h2>
                  <p align="center" className="tf15" family="CarmenSans-Regular">

                  </p>
                  <p align="center" id="header2info" className="tf15" family="CarmenSans-Regular">
                    {`${props.user ?? user} - ${classLessonName} - ${lessonName}`}
                  </p>

                  <center><br />
                    <p id="header4info">
                      Answer all questions. Use any information in the graph window.<br />
                      Click <b>Submit</b> when you finish.
                    </p>
                  </center>
                </div>
              </div>
              <div className="card-body">
                <div id="descriptionExtend">

                  {
                    initPayload?.description !== "" &&
                    initPayload?.description !== null &&
                    initPayload?.description !== undefined &&
                    <>
                      <h5 id="description1Extend"> LAB DESCRIPTION </h5>
                      <hr className="hr-dark" />
                      <div id="lab-description">
                        <div id="description2Extend" className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div>
                      </div>
                    </>
                  }<br />
                </div>
                <div id="guidelineExtend">

                  {
                    initPayload?.guideline !== "" &&
                    initPayload?.guideline !== null &&
                    initPayload?.guideline !== undefined &&
                    <>
                      <br />
                      <h5 id="guideline1Extend"> LAB GUIDELINE </h5>
                      <hr className="hr-dark" />
                      <div id="lab-guideline">
                        <div id="guideline2Extend" className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.guideline }}></div>
                      </div>

                    </>

                  }<br />
                </div>
              </div>
            </div>

            <div className="card-chart  card card-grey">
              <div className="card-body" id="bodyDescriptionExtend">
                <Row className="">
                  <Col xs="12">
                    <Card className="bg-evaluate border-0 rounded-0 no-border guidelines-description-table">
                      <CardBody className="">
                        <AnswerDisplay data={{
                          from: "Extend",
                          type: 2,
                          payload: initPayload?.extendStepQuestions,
                          changeHandler: changeHandler,
                          containedPayload: containedPayload,
                          freezingStatus: freezingStatus
                        }} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {/* {
              teacherCommentStats && <InstructorComment  data = {teacherComment}/>
            }             */}
              </div>
            </div>
            <div className="card-chart  card card-grey">
              <div className="card-body"  >
                <Row>
                  <FloatingButton name={!props.complted && reviewStatus ? 'Review' : freezingStatus ? 'Exit' : 'Submit Answers'} onClick={!props.complted && reviewStatus ? ReviewFun : freezingStatus ? submitFun2 : submitFun} status={true}/>
                </Row>
                <Row className="pt-4">
                  <Col md="12" >
                    {/* 
                  //get header
                  //lab description, lab guidelines
                  //question and answer
                  //teachers comment
                  //lessonName 
                */}
                    {/* <Pdf 
                data = {{
                  header : "headerDescriptionExtend",
                  description : "descriptionExtend",
                  body : "bodyDescriptionExtend",
                }}
                uploadBtnFun={uploadBtnFun}
              headingData ={{
                      // username: props.username ?? user,
                      // classLessonName: classLessonName,
                      lessonName: lessonName,
                      // MonthYear:MonthYear,
                      // HrsMinSec:HrsMinSec,
                      btnStatus:"Extend",
                      status: (initPayload?.description !== "" &&
                      initPayload?.description !== null &&
                      initPayload?.description !== undefined) ||  (initPayload?.guideline !== "" && 
                      initPayload?.guideline !== null && 
                      initPayload?.guideline !== undefined )
                        }}
              //       description = {{
              //         description:"lab-description",
              //         guideline:"lab-guideline"
              //       }}
              /> */}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        {/* </Spin> */}
      </div>
    </Spin>
  );
}

export default Extend;
