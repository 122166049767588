import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Row, Upload, Col, Spin, notification } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Config from '../../../../api/Config';
import { CheckCircleOutlined } from "@ant-design/icons";

const AddExplorerImagesUpload = (props) => {
  // let tempFileList = []
  const [fileList, setFileList] = useState([]);
  const [disable, setDisable] = useState(false);
  const [statusData, setStatusData] = useState(false);
  const [loading, setLoading] = useState(false)
  useEffect(()=>{
    if(props?.existingData.length > 0 && props?.existingData !== undefined){
      setDisable(true)
    }
    if(props.type !== "images" && props?.existingData.length > 0){
      const data = props?.existingData
      if(data){
        const pl = {
          uid: data[0]?.uid,
          name: data[0]?.name,
          url: data[0]?.url
        }
        setFileList([pl])
      }
    }
  },[props])

  // let FormateType = "";
  let imageMimeTypes = [];

  switch(props.type){
    case "image" :
    case "image1" :
      imageMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg']
      break;
    case "video":
      imageMimeTypes = ['video/mp4']
      break;
  }

  const showNotificationOnTop = (type, message) => {
    notification.open({
      placement: 'top',
      duration: '2',
      description: [
        type === 'success' ?
        <li>
          <CheckCircleOutlined className="text-success ant-icon-up" />
          <span className="ml-4">{message}</span>
        </li>
        : 
        <li>
          <CheckCircleOutlined className="text-danger ant-icon-up" />
          <span className="ml-4">{message}</span>
        </li>
      ]
    })
  }

  const uploadImage = async options => {
    const { onSuccess, onError, file, onProgress } = options;
    const isImage = imageMimeTypes.includes(file.type);
      if (!isImage) {
        if(props.type === "video"){
          showNotificationOnTop('error', 'Only video files are allowed!');
        }else{
          showNotificationOnTop('error', 'Only image files are allowed!');
        }
        const error = new Error("Wrong file type");
        onError({ error });
        return false;
      }else{
        const token = localStorage.getItem("access_token");
        const fmData = new FormData();
        const config = {
          headers: { "Authorization": `Bearer ${token}` },
        };
        fmData.append("file", file);
        fmData.append("uId", file.uid);

        try {
          const res = await axios.post(
            `${Config.APIURL}admin/lesson/uploadFile`,
            fmData,
            config
          );
          onSuccess("Ok");
          props.uploadFileData(res.data.data.fileUrl,res?.data?.data?.lessonCode,file.uid)
        } catch (err) {
          console.log("Eroor: ", err);
          const error = new Error("Some error");
          onError({ err });
        }
    }
  }

  const uploadImage2 = async options => {
    setLoading(true);
    const { onSuccess, onError, file, onProgress } = options;
    const isImage = imageMimeTypes.includes(file.type);
      if (!isImage) {
        if(props.type === "video"){
          showNotificationOnTop('error', 'Only video files are allowed!');
        }else{
          showNotificationOnTop('error', 'Only image files are allowed!');
        }
        const error = new Error("Wrong file type");
        onError({ error });
        setLoading(false);
        return false;
      }else{
        const token = localStorage.getItem("access_token");
        if(disable === true){
          onRemove(fileList[0]);
        }
        const fmData = new FormData();
        const config = {
          headers: { "Authorization": `Bearer ${token}` },
        };
        fmData.append("file", file);
        fmData.append("uId", file.uid);
        try {
          const res = await axios.post(
            `${Config.APIURL}admin/lesson/uploadFile`,
            fmData,
            config
          );
          onSuccess("Ok");
          const newFileList = [ {
            uid: file.uid,
            name: file.name,
            url: res.data.data.fileUrl
          }];
          setFileList(newFileList);
          setDisable(true)

          props.uploadFileData(res.data.data.fileUrl,res?.data?.data?.lessonCode,file.uid)
        } catch (err) {
          console.log("Error: ", err);
          const error = new Error("Some error");
          onError({ err });
        }
        setLoading(false);
      }
  }


  const onRemove = async (file) =>{
    const token = localStorage.getItem("access_token");
    const fmData = new FormData();
    const config = {
      headers: { "Authorization": `Bearer ${token}` },
    };
    // if(props.type !==image){
    //   fmData.append("fileName", `${file.url}`);
    // }else{
      if(file.originFileObj){
        fmData.append("fileName", `${file.originFileObj.uid}_SSA_${file.originFileObj.name}`);
      }else{
        fmData.append("fileName", `${file.uid}_SSA_${file.name}`);
      }
    // }
    try {
      const res = await axios.post(
        `${Config.APIURL}admin/lesson/deleteFile`,
        fmData,
        config
      );
      setFileList([]);
      setDisable(false)
      props.deletedFile(res.data.data.fileUrl)
    } catch (err) {
      console.log("Eroor: ", err);
    }

  }


  const dataValues = {
    listType: 'text',
    showUploadList: { showRemoveIcon: true },
    defaultFileList: props.existingData !== undefined && props.existingData.length !== 0 && props.existingData[0] !== undefined ? Object.values(props.existingData) : [],
    accept: imageMimeTypes.join(','), // accept only image files
    customRequest:uploadImage,
    classNames: 'custom-image-uploader',
    onRemove: onRemove,
    // onDrop(e) {
    //   const { files } = e.dataTransfer;
    //   const isAllImages = Array.from(files).every(file =>
    //     imageMimeTypes.includes(file.type)
    //   );
    //   if (!isAllImages) {
    //     if(props.type === "video"){
    //       message.error('Only video files are allowed!');
    //     }else{
    //       message.error('Only image files are allowed!');
    //     }
    //     e.preventDefault();
    //   }
    // },
    // beforeUpload(file) {
    //   const isImage = imageMimeTypes.includes(file.type);
    //   if (!isImage) {
        // if(props.type === "video"){
        //   message.error('Only video files are allowed!');
        // }else{
        //   message.error('Only image files are allowed!');
        // }        return false;
    //   }
    //   return true;
    // },
  };

  const dataValues2 = {
    listType: 'text',
    showUploadList: { showRemoveIcon: true },
    fileList: fileList,
    accept: imageMimeTypes.join(','), // accept only image files
    customRequest: uploadImage2,
    classNames: 'custom-image-uploader',
    onRemove: onRemove,
    // onDrop(e) {
    //   const { files } = e.dataTransfer;
    //   const isAllImages = Array.from(files).every(file =>
    //     imageMimeTypes.includes(file.type)
    //   );
    //   if (!isAllImages) {
    //     if(props.type === "video"){
    //       message.error('Only video files are allowed!');
    //     }else{
    //       message.error('Only image files are allowed!');
    //     }
    //     e.preventDefault();
    //   }
    // },
    // beforeUpload(file) {
    //   const isImage = imageMimeTypes.includes(file.type);
    //   if (!isImage) {
    //     if(props.type === "video"){
    //       message.error('Only video files are allowed!');
    //     }else{
    //       message.error('Only image files are allowed!');
    //     }        return false;
    //   }
    //   return true;
    // },
  };

  return (
  
    <>
    {props.type === "image" ? (
      <div className='upload'>
      <Upload.Dragger
      disabled={props?.disable === true}
        {...dataValues}
      >
        <Button className='upload-btn' block icon={<UploadOutlined className='ant-icon-up' />}>Drag and Drop or Click to Upload</Button>
      </Upload.Dragger>
      </div>
    ) : (
      <div className='upload'>
      {/* <Upload.Dragger
        listType='text'
        showUploadList={{showRemoveIcon:true}}
        fileList={fileList}
        accept={FormateType}
        customRequest={uploadImage2}
        classNames="custom-image-uploader"
        onRemove={onRemove}
      > */}
      <Upload.Dragger
      disabled={props?.disable === true}
        {...dataValues2}
      >
        <Spin tip="Loading..." spinning={loading} size="large">
          <Button disabled={disable} className='upload-btn' block icon={<UploadOutlined className='ant-icon-up' />}>Drag and Drop or Click to Upload</Button>
        </Spin>
      </Upload.Dragger>
      </div>
    ) }
      
  
    </>
  );
}


export default AddExplorerImagesUpload;