import React, { useEffect } from "react";
import { Bubble } from "react-chartjs-2";


const options = {
  scales: {
    x: {
      type: "linear",
      position: "bottom",
      max: 50,
      min: -15,
      ticks: {
        stepSize: 5,
        callback: function (value) {
          return `${value}°`;
        },
      },
    },
    y: {
      type: "linear",
      beginAtZero: true,
      display: true,
      stacked: true,
      max: 65,
      min: 0,
      ticks: {
        stepSize: 5,
        callback: function (value) {
          return `${value}°`;
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          if(context.dataset.data[context.dataIndex].r){
            if(context.dataset.pointRadius === 3){
              return `(${context.parsed.x}°, ${context.parsed.y}°)`;
            }else{
              return `(${context.parsed.x}°, ${context.parsed.y}°, ${parseFloat(context.raw.r).toFixed(2)})`;
            }
          }
        },
        title: function () {
          return null;
        },
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 2,
  layout: {
    autoPadding: false,
  },
};

const  getDistance = (xData, yData,  xFixed, yFixed) => {
  let lengths = []
  for(let i = 0; i < xData.length; i++){
    const dx = xData[i] - xFixed;
    const dy = yData[i] - yFixed;
    const hypotenuse = Math.sqrt(dx * dx + dy * dy);
    lengths.push(hypotenuse)
  }
  return lengths
}

// Function to apply transparency to a color dynamically
function applyTransparency(color, alpha) {
  // Convert the color from hex to RGB
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);

  // Create the RGBA string with the specified alpha
  const rgbaColor = `rgba(${r}, ${g}, ${b}, ${alpha})`;

  return rgbaColor;
}

export default function CircleLineChart({ chartData, dataFromGraph }) {  
  if (chartData?.type === 1) {
    const { xData, yData } = chartData.datasets[0];
    
    // Fixed point coordinates
    const xFixed = -1.5;
    const yFixed = 33;
    const answers = getDistance(xData, yData, xFixed, yFixed);

    const sum = answers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const distance = sum / answers.length;

    // Assuming xData and yData are arrays of data points
    const data = {
      datasets: [
        {
          label: "Center Point",
          data: [{ x: xFixed, y: yFixed, r: distance * 6 }], // Adjust the center point radius to touch data points
          backgroundColor:  chartData?.datasets[0]?.borderColor === "black"? applyTransparency("#000000", 0.5)  : applyTransparency(chartData?.datasets[0]?.borderColor, 0.6),
          borderColor: chartData?.datasets[0]?.borderColor,
          borderWidth: 2,
        },
        {
          label: "Data Points",
          data: xData.map((x, index) => ({ x, y: yData[index], r: 0.8 })),
          backgroundColor: chartData?.datasets[0]?.borderColor,
          borderWidth: 3,
          pointRadius: 3,
          borderColor: chartData?.datasets[0]?.borderColor,
        },
        {
          label: "center",
          data: xData.length>0 ? [{ x: xFixed, y: yFixed}] : [],
          backgroundColor: "rgba(0, 0, 255, 0.6)",
          borderWidth: 2,
          borderColor: "rgba(0, 0, 255, 1)",
          tooltip: false, // Hide tooltip for the "Center Point" dataset
        },
      ],
    };

    

    return (
      <div className="bubble-chart">
        <Bubble id="bubble" data={data} options={options} className="bubble-chart" />
      </div>
    );
  }else{
    // console.log(chartData)
    let data2 = []
    chartData.map(x=>{
      const xData = x.xData;
      const yData = x.yData;

        // Fixed point coordinates
        const xFixed = -1.5;
        const yFixed = 33;
        const answers = getDistance(xData, yData, xFixed, yFixed);

        const sum = answers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        const distance = sum / answers.length;

        // Assuming xData and yData are arrays of data points
          data2.push({
            label: `Center Point-${x.labels}`,
            data: [{ x: xFixed, y: yFixed, r: distance * 6 }], // Adjust the center point radius to touch data points
            backgroundColor:  x.borderColor === "black"? applyTransparency("#000000", 0.5)  : applyTransparency(x.borderColor, 0.6),
            borderColor: x?.borderColor,
            borderWidth: 2,
          })
          data2.push({
            label: `Data Points-${x.labels}`,
            data: xData.map((x, index) => ({ x, y: yData[index], r: 0.8 })),
            backgroundColor: x.borderColor,
            borderWidth: 3,
            pointRadius: 3,
            borderColor: x.borderColor,
          })
          data2.push({
            label: `center-${x.labels}`,
            data: xData.length>0 ? [{ x: xFixed, y: yFixed}] : [],
            backgroundColor: "rgba(0, 0, 255, 0.6)",
            borderWidth: 2,
            borderColor: "rgba(0, 0, 255, 1)",
            tooltip: false, // Hide tooltip for the "Center Point" dataset
          })
        })
    const data = {
      datasets: data2
    };
    return (
      <div className="bubble-chart">
        <Bubble id="bubble" data={data} options={options} className="bubble-chart" />
      </div>
    );
  }
}
