import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from "react-router-dom";

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Table,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Breadcrumb, Radio, Input, Form, Modal, Button, Tabs, Checkbox, Image, Spin } from "antd";
import "antd/dist/antd.css";
import img1 from "../../../assets/images/explore.png";
import x_axis from "../../../assets/images/x-axis.svg";
import y_axis from "../../../assets/images/y-axis.svg";
import graph from "../../../assets/images/graph.svg";
import Video from "../../../components/Video";
import up from "../../../assets/images/graph-up.svg";

import LabDescription from "../../../components/Questions/LabDescription";

import LineChart from "../../../components/Charts/LineChart";
import { UserData } from "../../../components/Charts/Data";
import RestService from "../../../api/RestService";
import CoordinatesValues from "../Explore/childComponent/coordinatesValues";
import LineRegressionChart from "../../../components/Charts/LineRegressionChart";
import jsonToken from 'jsonwebtoken'
import ChartValidations from "../Explore/childComponent/ChartValidations";
import AnswerDisplay from "../AnswerDisplay";
import FloatingButton from "../../../components/FloatingButton";
import { useSelector } from "react-redux";
import LessonService from "../../../api/LessonService";

function Explain(props) {

  const [xLabel, setXLabel] = useState();
  const [yLabel, setYLabel] = useState();
  const [xgrLabel, setXgrLabel] = useState();
  const [ygrLabel, setYgrLabel] = useState();
  const [selectValue, setSelectValue] = useState()

  const [reviewStatus, setReviewStatus] = useState(props.disabled ?? true);
  const [lessonCode, setLessonCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");
  const [payload, setPayload] = useState();
  const [cellId, setCellId] = useState("");
  const [selectOption, setSelectOption] = useState([]);
  const [checked, setChecked] = useState(false);
  const [clickState, setClickState] = useState(2);
  const [counter, setCounter] = useState(0);
  const [classLessonName, setClassLessonName] = useState("");
  const [lessonName, setLessonName] = useState("");
  const [initPayload, setInitPayload] = useState({
    description: "",
    explainStepQuestionAnswers: [],
    explainStepQuestions: [],
    id: "",
    lesson_id: "",
    videoUrl: "",
    exploreStepResponses: "",
    experiments: [],
    lessonCode: "",
    chartType: ""
  });
  const [responseData, setResponseData] = useState({
    borderColor: "",
    borderWidth: "",
    labels: "",
    xData: [],
    yData: [],
    xyData: [],
    categoryData: [],
    cod: "",
    speed: "",
    speed2: "",
    userMainHypothesis: "",
  });
  const TSdate = Date.now();
  const dateFormat = new Date(TSdate);
  const MonthYear = dateFormat.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
  const HrsMinSec = dateFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });

  const [responseMixData, setResponseMixData] = useState([]);
  const [graphMixStatus, setGraphMixStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [onlyOneOption, setOnlyOneOption] = useState([]);
  const [datasets, setDatasets] = useState([
    {
      label: "",
      yData: [],
      xData: [],
      color: ""
    }
  ]);
  const [containedPayload, setContainedPayload] = useState({});
  const [definedHypothesis, setDefinedHypothesis] = useState("");
  const [lesson, setLesson] = useState(parseInt(props.explainId))
  const [lesson2, setLesson2] = useState(parseInt(props.exploreId))
  const [allGraphValue, setAllGraphValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [mappingId, setMappingId] = useState("");

  function convert(data) {
    return Array.isArray(data)
      ? data.reduce((obj, el, i) => (el + 1 && (obj[i] = convert(el)), obj), {})
      : data;
  }
  let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;

  }

  const Label = useSelector(state => state.graph.GraphDataTable)

  // console.log(Label[0].exploreStepExperiment);

  const dataSendToMap = Label[0]?.exploreStepExperiment;

  useEffect(() => {
    dataSendToMap?.map(x => {
      if (x.name[0].value === selectValue) {
        let xValue = " ";
        let yValue = " ";
        if (x.x !== null && x.y !== null) {
          if (x.x.SI !== "") {
            xValue = `(${x.x.SI})`;
          }
          if (x.y.SI !== "") {
            yValue = `(${x.y.SI})`;
          }


          setXLabel(`${x.x.Value} ${xValue}`)
          setYLabel(`${x.y.Value} ${yValue}`)
          setXgrLabel(x.x?.SI)
          setYgrLabel(x.y?.SI)
        }
      }
    })
  }, [selectValue])


  useEffect(() => {
    setLoading(true)
    RestService.getExplainStepInfosById(lesson, props.username, lesson2)
      .then((response) => {
        if (counter === 0) {

          setInitPayload(response?.data)
          setLoading(false)
          setCounter(1);
          setClassLessonName(response?.data?.className);
          setLessonName(response?.data?.lessonName);
        } else {

          const secondSendPayload = {
            description: initPayload.description,
            experiments: response.data.experiments,
            explainStepQuestions: response.data.explainStepQuestions,
            explainStepQuestionAnswers: response.data.explainStepQuestionAnswers,
            exploreStepResponses: response.data.exploreStepResponses,
            id: initPayload.id,
            lesson_id: initPayload?.lesson_id,
            lessonCode: initPayload.lessonCode,
            chartType: initPayload?.chartType
          }
          setInitPayload(secondSendPayload)
          setLoading(false)
          setLessonName(response?.data?.lessonName);

        }
      })
    RestService.getExplaineStepByIdAndUserName(props.username, lesson)
      .then((response) => {
        if (response) {
          setContainedPayload(response?.data[0]?.response_json.answers);
          setPayload(convert(response?.data[0]?.response_json.answers))
          setLoading(false)
        }
      })
  }, [props])



  // useEffect(() => {
  //   setLoading(true)
  //   RestService.getExplaineStepByIdAndUserName(user, lesson)
  //     .then((response) => {
  //       if (response) {
  //         setContainedPayload(response?.data[0]?.response_json.answers);
  //         setPayload(convert(response?.data[0]?.response_json.answers))
  //         setLoading(false)
  //       }
  //     })
  // }, [])
  // s


  const { TabPane } = Tabs;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const ReviewFun = () => {
    setReviewStatus(false)
    window.scrollTo(0, 0)
  };
  const { TextArea } = Input;
  const [value, setValue] = useState(1);

  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.distance),
    datasets: [
      {
        data: UserData.map((data) => data.time),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
        options: {
          plugins: {
            title: {
              display: true,
              text: "",
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "Distance",
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Time",
              },
            },
          },
        },
        label: "",
      },
    ],
  });

  useEffect(() => {

    setDefinedHypothesis(initPayload?.exploreStepResponses[0]?.response_jason?.hypothesis)


    setResponseMixData([]);
    setResponseData({
      borderColor: "",
      borderWidth: "",
      labels: "",
      xData: [],
      yData: [],
      xyData: [],
      categoryData: [],
      cod: "",
      speed: "",
      speed2: "",
      userMainHypothesis: "",
    });

    if (Object.keys(cellId).length > 0 && Object.keys(cellId).length === selectOption.length) {
      setChecked(true)
    }
    if (initPayload?.exploreStepResponses[0]?.response_jason?.answers !== null && initPayload?.exploreStepResponses[0]?.response_jason?.answers !== undefined) {
      if (cellId.length !== 0 && cellId !== undefined && cellId !== null) {
        const filteredList = Object.keys(initPayload?.exploreStepResponses[0]?.response_jason?.answers)
          .filter(key => Object.values(cellId).includes(key))
          .reduce((obj, key) => {
            obj[key] = initPayload?.exploreStepResponses[0]?.response_jason?.answers[key];
            return obj;
          }, {});
        if (Object.values(filteredList).length <= 1) {
          setGraphMixStatus(false)
          if (Object.values(filteredList)[0] !== undefined && Object.keys(filteredList)[0] !== null) {
            const responsePayload = {
              borderColor: Object.values(filteredList)[0].borderColor,
              borderWidth: Object.values(filteredList)[0].borderWidth,
              labels: Object.values(filteredList)[0].displayLabel,
              xgrLabel: xgrLabel,
              ygrLabel: ygrLabel,
              xData: Object.values(filteredList)[0].xData,
              yData: Object.values(filteredList)[0].yData,
              xyData: Object.values(filteredList)[0].xyData,
              categoryData: Object.values(filteredList)[0].categoryData,
              cod: Object.values(filteredList)[0].cod,
              speed: Object.values(filteredList)[0].speed,
              speed2: Object.values(filteredList)[0].speed2,
            }
            // setDefinedHypothesis(Object.values(filteredList)[0].userMainHypothesis)
            setResponseData(responsePayload);
            // setDatasets([{
            //   label:Object.values(filteredList)[0].label,
            //   yData:Object.values(filteredList)[0].yData,
            //   xData:Object.values(filteredList)[0].xData
            // }])
          }

        } else {
          setResponseMixData([]);
          setGraphMixStatus(true);
          for (let k = 0; k < Object.values(filteredList).length; k++) {
            if (Object.values(filteredList)[k] !== undefined && Object.keys(filteredList)[k] !== null) {
              const responsePayload = {
                borderColor: Object.values(filteredList)[k].borderColor,
                borderWidth: Object.values(filteredList)[k].borderWidth,
                labels: Object.values(filteredList)[k].displayLabel,
                xgrLabel: xgrLabel,
                ygrLabel: ygrLabel,
                xData: Object.values(filteredList)[k].xData,
                yData: Object.values(filteredList)[k].yData,
                xyData: Object.values(filteredList)[k].xyData,
                categoryData: Object.values(filteredList)[k].categoryData,
                cod: Object.values(filteredList)[k].cod,
                speed: Object.values(filteredList)[k].speed,
                speed2: Object.values(filteredList)[0].speed2,
                lesson: lesson2
              }
              // setDatasets([{
              //   label:Object.values(filteredList)[k].label,
              //   yData:Object.values(filteredList)[k].yData,
              //   xData:Object.values(filteredList)[k].xData
              // }])
              setResponseMixData((preData) => [...preData, responsePayload]);
            }

          }

        }




      }

      let arr = []
      if (selectOption.length !== Object.keys(initPayload?.exploreStepResponses[0].response_jason?.answers).length) {
        Object.keys(initPayload?.exploreStepResponses[0].response_jason?.answers).map(x => {

          arr.push({
            name: x,
            isChecked: false,
            color: initPayload?.exploreStepResponses[0].response_jason?.answers[x].borderColor,
            displayLabel: initPayload?.exploreStepResponses[0].response_jason?.answers[x].displayLabel,
            mappingId: initPayload?.exploreStepResponses[0].response_jason?.answers[x].mappingId,
          })

        })

        setSelectOption(arr)
      }




    }

  }, [cellId, initPayload]);


  useEffect(() => {
    if (initPayload?.exploreStepResponses[0]?.response_jason?.answers !== null && initPayload?.exploreStepResponses[0]?.response_jason?.answers !== undefined) {

      if (selectOption.length !== Object.keys(initPayload?.exploreStepResponses[0]?.response_jason?.answers).length) {
        Object.keys(initPayload?.exploreStepResponses[0]?.response_jason?.answers).map(x => {
          if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].mappingId !== undefined && initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].mappingId !== "") {
            // console.log(initPayload?.exploreStepResponses[0]?.response_jason?.answers[x])
            setMappingId(initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].mappingId)
          }

        })
      }

    }
  }, [initPayload])


  const graphdata1 = {
    labels: responseData.categoryData,
    type: responseData.type || 1,
    lesson: initPayload?.lesson_id,
    datasets: [
      {
        label: responseData.labels,
        xgrLabel: xgrLabel,
        ygrLabel: ygrLabel,
        xData: responseData.xData,
        yData: responseData.yData,
        xyData: responseData.xyData,
        borderColor: responseData.borderColor,
        borderWidth: responseData.borderWidth,
      },
    ],
  };

  const dataFromGraph = (fitValue, speed) => {
  };
  // console.log(responseData.yData)
  const tablePayload = {
    type: graphMixStatus,
    labels: responseData.labels,
    xData: initPayload?.chartType[mappingId]?.graphType === "curve" ? responseData.xData : responseData.yData,
    yData: initPayload?.chartType[mappingId]?.graphType === "curve" ? responseData.yData : responseData.xData,
    speed: responseData.speed,
    speed2: responseData.speed2,
    cod: responseData.cod,
    chartType: responseData.chartType
  };



  const changeHandler2 = (event) => {

    const check = event.target.checked;
    const name = event.target.name;
    setSelectValue(name);

    if (check === true) {
      setClickState(1);
      setChecked(false)


      if (initPayload?.chartType === "curve") {
        if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {
          if (datasets.findIndex(x => x.label === name) !== -1) {

            datasets.filter(x => x.label === name)
            setDatasets(datasets)
          }
          else {
            setDatasets(prev => (
              [
                ...prev,
                {
                  label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].displayLabel,
                  yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData,
                  color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].borderColor,
                  xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData
                }
              ]
            ))
          }

        }
      } else if (initPayload?.chartType === "Bar YesNo") {
        if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {

          if (datasets.findIndex(x => x.label === name) !== -1) {

            datasets.filter(x => x.label === name)
            setDatasets(datasets)
          }
          else {
            setDatasets(prev => (
              [
                ...prev,
                {
                  label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].displayLabel,
                  yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData,
                  color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(item => {
                    return item?.color
                  }),
                  borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].borderColor,

                  xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData
                }
              ]
            ))
          }

        }
      }
      else {
        if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].label === name) {
          if (datasets.findIndex(x => x.label === name) !== -1) {

            datasets.filter(x => x.label === name)
            setDatasets(datasets)
          }
          else {
            setDatasets(prev => (
              [
                ...prev,
                {
                  label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].displayLabel,
                  yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(i => {
                    return i?.value
                  }),
                  color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].yData.map(i => {
                    return i?.color
                  }),
                  xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].xData,
                  borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[name].borderColor,
                }
              ]
            ))
          }

        }
      }
      selectOption.map(x => {
        if (x.name === name) {
          x.isChecked = true
        }
      })


      setSelectOption(selectOption)
      setCellId(prev => ({
        ...prev,
        [name]: name
      }))
    } else {
      setDatasets(datasets.filter(x => x.label !== name))
      selectOption.map(x => {
        if (x.name === name) {
          x.isChecked = false
        }
      })

      setSelectOption(selectOption)
      setChecked(false)
      delete cellId[name];
      setCellId(prev => ({
        ...prev,
      }))
    }

  }

  const changeHandler3 = (event) => {
    const check = event.target.checked;
    const name = event.target.name;


    if (check === true) {
      selectOption.map(x => {
        x.isChecked = true
      });
      setClickState(2)
      setSelectOption(selectOption)
      setSelectValue(selectOption[0].name);
      setChecked(true)
      name.map((x) => {
        if (initPayload?.chartType[mappingId]?.graphType === "curve") {
          if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label === x) !== -1) {


            }
            else {
              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].displayLabel,
                    yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData,
                    color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].borderColor,
                    xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].xData
                  }
                ]
              ))
            }
          }
        } else if (initPayload?.chartType[mappingId]?.graphType === "Bar YesNo") {
          if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label === x) !== -1) {


            }
            else {
              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].displayLabel,
                    yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
                      return i
                    }),
                    color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
                      return i?.color
                    }),
                    xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].xData,
                    borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].borderColor,

                  }
                ]
              ))


            }
          }
        }
        else {
          if (initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label === x) !== -1) {
            }
            else {
              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].displayLabel,
                    yData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
                      return i?.value
                    }),
                    color: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].yData.map(i => {
                      return i?.color
                    }),
                    xData: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].xData,
                    borderColor: initPayload?.exploreStepResponses[0]?.response_jason?.answers[x].borderColor,
                  }
                ]
              ))
            }
          }
        }
        setCellId(prev => ({
          ...prev,
          [x]: x
        }))
      })
    } else {
      setDatasets([{
        label: null,
        yData: [],
        xData: [],
      }])
      selectOption.map(x => {
        x.isChecked = false
      });
      if (selectOption.findIndex(x => x.isChecked === true) > -1) {

      }
      else {
        // console.log("Hello world")
        setClickState(2)
      }
      setSelectOption(selectOption)
      setChecked(false)
      setGraphMixStatus(false)
      setCellId("");
    }
  }

  useEffect(() => {
    const createPayloadForAll = []
    selectOption.map((x) => {
      createPayloadForAll.push(x.name)
    });

    setAllGraphValue(createPayloadForAll)
  }, [selectOption]);

  useEffect(() => {


    if (datasets && datasets.length > 0) {
      datasets.map(x => {
        if (x.yData.length == 0) {
          setDatasets(datasets.filter(x => x.yData.length > 0))
        }
      })
    }
  }, [datasets])
  const display2 = selectOption.map((x, i) => {
    const styleValue = `ex`;
    return (
      <div className="chk-div">
        <Checkbox checked={x.isChecked} className="bold pr-3" name={x.name} onChange={changeHandler2}></Checkbox>
        <div className={styleValue} style={{ backgroundColor: x.color }} >{x.name}</div>
      </div>
    )
  })

  const onChange = (e) => {

    setValue(e.target.value);
  };

  const modifiedDescription = initPayload?.description.split(":") ?? ":";

  const viewData = datasets.map(i => {
    // console.log(i)
    return {
      labels: i.label,
      type: 2,
      xData: i.yData.length > 0 ? i.xData : [],
      yData: i.yData.length > 0 ? i.yData : [],
    }
  })
  // console.log(viewData)
  const secondLessonTablePayload = {
    // labels: [1, 2, 3, 4, 5, 6],
    labels: datasets[0]?.xData,
    type: 1,
    datasets: datasets
  }

  const thirdLessonTablePayload = {
    labels: initPayload?.chartType[mappingId]?.graphType === "Bar YesNo" ? datasets.map(i => i.xData
    )[0] : datasets.map(i => {
      return i.xData
    }),
    type: 3,
    yLabel: ygrLabel,
    datasets: datasets.map(i => {
      return {
        label: i.label,
        yData: i.yData,
        borderColor: initPayload?.chartType[mappingId]?.graphType === "Bar YesNo" ? i.borderColor : i.color,
      }
    })
  }

  // console.log(selectValue)

  const changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const count = parseInt(name) + 1;

    setPayload(prev => ({
      ...prev,
      [name]: { question_id: count, answer: value }
    }));
  };

  function display() {
    // let modifiedQuestion =["",""]
    // if(lesson !== 3) {
    //    modifiedQuestion = x?.description.split(":");
    // }
    // else{
    //   modifiedQuestion =x?.description.split(",");
    // }

    return (
      <div className="pt-4">

        {
          initPayload?.chartType[mappingId]?.graphType !== "curve" && <AnswerDisplay data={{
            type: 2,
            payload: initPayload?.explainStepQuestions,
            changeHandler: changeHandler,
            containedPayload: containedPayload

          }} />
        }

        {
          initPayload?.chartType[mappingId]?.graphType === "curve" &&

          <AnswerDisplay data={{
            type: 4,
            payload: initPayload?.explainStepQuestions,
            changeHandler: changeHandler,
            containedPayload: containedPayload

          }} />
          //   <Row>
          //   <Col xs="6" className="pt-4">
          //   <h5 className="pt-4"> {modifiedQuestion[0]} </h5>
          //   <div style={{fontSize:"23px"}} dangerouslySetInnerHTML={{ __html: modifiedQuestion[2] }} />


          //   </Col>
          //   <Col xs="6" className="pt-4">
          //   <img src={modifiedQuestion[1]} alt="" />
          //   </Col>
          // </Row>
        }
        {/* <Row>
          <Col xs="6" className="pt-4">
            <textarea
              class="form-control tarea"
              defaultValue={containedPayload[x.id-1]?.answer}
              onChange={changeHandler}
              rows={8}
              id="textarea"
              name={x.id-1}
            ></textarea>
          </Col>
        </Row> */}
      </div>
    )
  }

  const submitFun = async () => {
    if (payload === undefined) {
      setValidationMsg("Fields empty, once proceed cannot submit for remarking. Do you wish to continue?")
      setIsModalOpen(true);

    } else if (initPayload.explainStepQuestions.length > Object.keys(payload).length) {
      setValidationMsg("Some fields are empty, once proceed cannot submit for remarking. Do you wish to continue?")
      setIsModalOpen(true);

    } else {
      const submitPayload = {
        username: user,
        userId: initPayload.id,
        status: 2,
        response_json: {
          answers: Object.values(payload)
        },
        last_update_date: new Date().toISOString().slice(0, 10),
        explain_step_id: lesson,
      }

      await RestService.postExplainStepUserResponses(submitPayload)
        .then((response) => {
          if (response?.status === 200) {
            window.scrollTo(0, 0)
            props.submitTrigger("5");
          }
        })
    }
  }

  const handleOk1 = async () => {
    setReviewStatus(false);
    setIsModalOpen(false);
    const submitPayload = {
      username: user,
      userId: initPayload.id,
      status: 2,
      response_json: {
        answers: Object.values(payload)
      },
      last_update_date: new Date().toISOString().slice(0, 10),
      explain_step_id: lesson,
    }


    // @todo should succesfully completed out here
    await RestService.postExplainStepUserResponses(submitPayload)
      .then((response) => {
        if (response?.status === 200) {
          window.scrollTo(0, 0)
          props.submitTrigger("5");
        }
      })
  };

  const handleCancel1 = () => {

    setReviewStatus(true);
    window.scrollTo(0, 0);
    setIsModalOpen(false);
  };

  return (
    <Spin tip="Loading..." spinning={loading} size="large">
      <div className="content explain-page tab-style scroll-tab disabled-input" >
        <div className="card-chart  card card-grey no-border">
          <div className="card-body">
            <Modal title="" visible={isModalOpen} onOk={handleOk1} onCancel={handleCancel1}>
              <p>{validationMsg}</p>
            </Modal>
           
            <Row className="">
              <Col xs="12" className="">
               
                <div class="lab-hypothesis ex-tab active hypoe">
                  <Row>
                    <Col md="12">
                      <fieldset>
                        <legend><strong>Hypothesis </strong> </legend>
                        <p className="f15 hype-text">
                          {definedHypothesis}
                        </p>
                      </fieldset>
                    </Col>
                  </Row>
                </div>
                <br />
              </Col>
            </Row>
            <br />
          </div>
        </div>

        <div className="card-chart p2 card card-grey">
          <div className="card-body">
            <Row className="pt-4">
              <Col lg="12">
                <Card className="bg-evaluate border-0 rounded-0 no-border">
                  <CardBody className="">
                    {/* {display} */}

                    <div className="pt-4">

                      {
                        initPayload?.chartType[mappingId]?.graphType !== "curve" && <AnswerDisplay data={{
                          type: 2,
                          payload: initPayload?.explainStepQuestions,
                          changeHandler: changeHandler,
                          containedPayload: containedPayload

                        }} />
                      }

                      {
                        initPayload?.chartType[mappingId]?.graphType === "curve" &&

                        <AnswerDisplay data={{
                          type: 4,
                          payload: initPayload?.explainStepQuestions,
                          changeHandler: changeHandler,
                          containedPayload: containedPayload

                        }} />
                      }
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* <Row className="button-center p2">
              {reviewStatus ? (
                  <button onClick={ReviewFun} type="primary" class="btn btn-primary btn-lg mt-50 floating-button-center">
                  Review
                </button>
                ) : (
                  <button onClick={submitFun} type="primary" class="btn btn-primary btn-lg mt-50 floating-button-center">
                  Submit Answers
                </button>
                )}
            </Row> */}
          </div>
          {/* <Row>
            <FloatingButton name={!props.complted && reviewStatus ? 'Review' : 'Submit Answers'} onClick={!props.complted && reviewStatus ? ReviewFun : submitFun} />
          </Row> */}
        </div>
      </div>
    </Spin>
  );
}

export default Explain;
