import { CardBody, Badge } from "reactstrap";
import { Input, Table, Col, Row, Space, Button } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";

import { GetAllClassesWithLessons } from "../../../api/TeacherService";
import { useHistory } from "react-router";
import jsonToken from 'jsonwebtoken'
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const { Column } = Table;


function Home(props) {
  let history = useHistory();
  const {instructorId,id} = useParams();

  const [rowid, setRowid] = useState()
  const [LessonData, setlessonData] = useState([]);
  const [data, setData] = useState([]);
  const [completeData, setCompleteData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setsearchResult] = useState([]);
  const [welcome, setWelcome] = useState('');


  useEffect(() => {
    document.body.classList.add('admin-nav');
    ConsumeApi();
    showMessage()
  }, []);

  var token1 = localStorage.getItem("access_token");
  let accessToken1 = jsonToken.decode(token1);

  console.log("token", accessToken1)


  function showMessage() {
    var token = localStorage.getItem("access_token");
    if (!token) {
      return
    }
    let accessToken = jsonToken.decode(token);

    let name = accessToken.firstName;
    //setWelcome("Hey " + name.charAt(0).toUpperCase() + name.slice(1) + ", welcome to your dashboard");
    setWelcome("My Classes");
  }

  const getSearchTerm = (e) => {
    const currValue = e.target.value;
    setSearchTerm(currValue);
    if (currValue !== "" && currValue.length > 0) {
      const filteredData = data.filter(entry =>
        entry.className.toString().toLowerCase().includes(currValue.toLowerCase()) ||
        entry.curriculamName.toString().toLowerCase().includes(currValue.toLowerCase())
        )
      setsearchResult(filteredData);
    } else {
      setsearchResult(data.data)
    }
  }

  const columns = [
    {
      title: 'Class Name',
      dataIndex: 'className',
      key: 'className',
      width:300,
      sorter: (a, b) => a.className.localeCompare(b.className),
      // defaultSortOrder: 'descendm',
      // sorter: (a, b) => a.className.length - b.className.length,

      selector: (rowKey) => rowKey.className,
      onCell: (record, rowIndex) => ({
        style: {
          // backgroundColor: '#FFEDE9'
        }
      })
    },
    {
      title: '',
      dataIndex: '',
      key: 'className',
      width:'100px',
      align : 'center',
      //sorter: (a, b) => a.className.localeCompare(b.className),
      render: (_, record) => (
        setRowid(record.className),

        <Space size="middle">
          {/* {record.className}  */}
          <Button type="danger" size='small' onClick={(e) => {
            e.preventDefault()
            history.push(`/Instructor/${instructorId}/Classes/${id}/Lesson/${encodeURIComponent(record.className)}/${record.classId}/${record.classLevel}`)
          }}>Grade</Button>
        </Space>
      ),
      selector: (rowKey) => rowKey.className,
      onCell: (record, rowIndex) => ({
        style: {
          // backgroundColor: '#FFEDE9'
        }
      })
    },


    {
      title: 'Curriculum',
      dataIndex: 'curriculamName',
      key: 'lessonHeading',
      selector: (rowKey) => rowKey.lessonHeading,
      sorter: (a, b) => a.curriculamName.localeCompare(b.curriculamName),
      onCell: (record, rowIndex) => ({
        style: {
          // backgroundColor: '#E9FAEF'
        }
      })

    },
    // {
    //   title: '',
    //   dataIndex: '',
    //   key: 'lessonHeading',
    //   selector: (rowKey) => rowKey.lessonHeading,
    //   render: (_, record) => (
    //     <Space size="middle">
    //       {/* {record.lessonHeading} */}
    //       {/* <Badge className='bg-success' size='small' onClick={(e) => {
    //         e.preventDefault()
    //         history.push(`/Teacher/Home/ViewLesson/${record.lessonId}`)
    //       }}
    //       >
    //         <small>Assign Lesson</small>
    //       </Badge > */}
    //     </Space>
    //   ),
    //   onCell: (record, rowIndex) => ({
    //     style: {
    //       backgroundColor: '#E9FAEF'
    //     }
    //   })

    // },
    {
      title: 'Class Average',
      dataIndex: 'classAverage',
      key: 'lessonTotalScore',
      align: 'center',
      width: '180px',
      sorter: (a, b) => a.classAverage - b.classAverage,
      defaultSortOrder: 'descend',
      render: (_, record) => (
        <Space size="middle">
          {/* {record.lessonHeading} */}

          <small>{record.classAverage !== "NaN" && record.classAverage.toFixed(2)}</small>

        </Space>
      )

    },
    {
      title: 'No. of Students',
      dataIndex: 'studentCount',
      key: 'noStudent',
      align: 'center',
      width: '200px',
      sorter: (a, b) => a.studentCount - b.studentCount,
      selector: (rowKey) => rowKey.noStudent,
    },
    {
      title: 'Date Created',
      dataIndex: 'createdDate',
      key: 'class_created_date',
      width: '180px',
      sorter: (a, b) => {
        if (a.createdDate === null || b.createdDate === null) {
          return a.createdDate === null ? 1 : -1; // null values will be pushed to the bottom
        }
        return a.createdDate.localeCompare(b.createdDate);
      },
      selector: (rowKey) => rowKey.class_created_date,
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => a.class_created_date - b.class_created_date,
      render: (_, record) => (

        <Space size="middle">

          <small>{record.createdDate !== null && moment(record.createdDate).utc().format('YYYY-MM-DD | HH:m:s')}</small>
        </Space>
      ),


    },
    {
      title: 'Last Activity',
      dataIndex: 'lastActivityDate',
      key: 'class_end_date',
      width: '180px',
      sorter: (a, b) => {
        if (a.lastActivityDate === null || b.lastActivityDate === null) {
          return a.lastActivityDate === null ? 1 : -1; // null values will be pushed to the bottom
        }
        return a.lastActivityDate.localeCompare(b.lastActivityDate);
      },
      selector: (rowKey) => rowKey.class_end_date,
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => a.class_end_date - b.class_end_date,
      render: (_, record) => (

        <Space size="middle">

          <small>{record.lastActivityDate !== null && moment(record.lastActivityDate).utc().format('YYYY-MM-DD | HH:m:s')}</small>
        </Space>
      ),

    },
  ];


  async function ConsumeApi() {
    setLoading(true)
    const responceData = await GetAllClassesWithLessons(accessToken1.roles[0],
      accessToken1.userId,id);
    if (responceData !== undefined || responceData !== null) {
      let dataArray = [];
      console.log("resData", responceData)
      setData(responceData?.data);
      // console.log("table data ", responceData);
      // responceData[0] && 
      // responceData.map((item) => {
      //   let dataObject = {
      //     className: item.className,
      //     lessonHeading: item.lessonHeading,
      //     lessonTotalScore: item.lessonTotalScore,
      //     class_created_date: item.class_created_date,
      //     class_end_date: item.class_end_date,

      //   };
      //   dataArray.push(dataObject);

      // });
      // setData(dataArray);

      // setCompleteData(
      //   dataArray.filter((item) => item.status === 3 || item.status === 2),
      //   dataArray.filter((item) => item.email === userData.email)
      // );
      // setPendingData(dataArray.filter((item) => item.status === 1));
      // console.log("variable is undefined or null");
    } else {
      console.log("variable is NOT undefined or null");
    }

    setLoading(false);
  }


  return (
    <>
      <div className="container-fluid">

        <Row>
          <Col span={12} offset={6}>
            <center>
              <h3>{welcome}</h3>
            </center>
          </Col>
        </Row>
        <Row className="py-3 px-5">
          {accessToken1.roles[0] === "TEACHER" ? 
          (<Col span={8}>
          </Col>) 
            : 
          (<Col span={8}>
            <Button icon={<ArrowLeftOutlined />} onClick={(e) => {
              e.preventDefault()
              history.push(`/Instructor/${instructorId}`)
            }} className='border-0'></Button>
            Go Back
          </Col>)}
          <Col span={8}>
            <Input size="large"
              placeholder="Search"
              onChange={getSearchTerm}
              prefix={<SearchOutlined
                className="ml-3 mr-3" />} />
          </Col>
        </Row>
        <div className="px-5">
          <Row className="py-3">
            <Col span={24}>
              <Table
                dataSource={searchTerm?.length < 1 || searchTerm?.length === undefined ? data : searchResult}
                loading={loading}
                pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                columns={columns}
                className='thead-dark'
              />
            </Col>
          </Row>
        </div>

      </div>
    </>
  );
}

export default Home;
