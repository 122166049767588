import { useState, useEffect } from "react";
import { Card, CardText, FormFeedback, Row, Col, Input, Button } from "reactstrap";
import { Alert, notification, Spin } from 'antd';
import AccountAdminService from "../../../../api/AccountAdminService"


import SchoolService from "../../../../api/SchoolService";
import jsonToken from 'jsonwebtoken';
import SunEditor from "suneditor-react";
import AddExplorerImagesUpload from "./AddExplorerImagesUpload";
import useCustFormValidator from "../../../../hooks/useCustFormValidator";

import Answer from "./answer";
import LessonService from "../../../../api/LessonService";
import EvaluateAnswer from "./EvaluateAnswer";
import validate from "./EvaluateQuestionValidations";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
function AdminCreate(props) {
  var obj = {
    id: 0,
    description: "",
    evaluate_step_id: props.evaluate_id,
    question_type_id: 1,
    class_level_id: "",
    questionExplanation: "",
    image: "",

  }
  const [accounts, setAccounts] = useState([]);
  const [schools, setSchools] = useState([]);
  const [edited, setEdited] = useState(false);
  const [uneditedObj, setUneditedObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [lodeState, setLodeState] = useState(false);
  const [description,setDesc] = useState("");
  const [explain,setExplainData] = useState("");
  const [QuestionAnswers,setQuestionAnswers] = useState([]);
  const [disable,setDisabled] = useState(false);
  const options6 = ['MCQ'];
  const [addnewAnswer,setAddNewAnswer] = useState(false);
  const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  const [imageUrl,setImageUrl] = useState("");
  const [imageData,setImageData] = useState([]);
 
  const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(onSave, validate, obj);

  useEffect(() => {
   // loadAccounts()

    if(props.editData !== undefined){
      setFormData({
        id: props.editData.id,
        description: props.editData.description,
        evaluate_step_id: props.evaluate_id,
        question_type_id: 1,
        class_level_id: props.editData.class_level_id,
        questionExplanation: props.editData.questionExplanation,
        image:props.editData.image ,
    
      })
      setUneditedObj({
        id: props.editData.id,
        description: props.editData.description,
        evaluate_step_id: props.evaluate_id,
        question_type_id: 1,
        class_level_id: props.editData.class_level_id,
        questionExplanation: props.editData.questionExplanation,
        image:props.editData.image ,
    
      })
      if(props.editData.image !== ""){
        if(props.editData.image !== undefined && props.editData.image !== null){
          const split = props.editData.image.split("/assets/")[1];
          const part = split.split("_SSA_");
          const data = [{
            uid: part[0],
            name: part[1],
            status: 'done',
            url: props.editData.image,
          }]
          setImageData(data);
      }
        setImageUrl(props.editData.image)
      }
      setQuestionAnswers(props.editData.evaluateStepQuestionAnswer)
      setAddNewAnswer(true)
    }else{
      setFormData({
        id: 0,
        description: "",
        evaluate_step_id: props.evaluate_id,
        question_type_id: 1,
        class_level_id: props.currentClassLevel,
        questionExplanation: "",
        image: "",
    
      })
      setUneditedObj({
        id: 0,
        description: "",
        evaluate_step_id: props.evaluate_id,
        question_type_id: 1,
        class_level_id: props.currentClassLevel,
        questionExplanation: "",
        image: "",
    
      })
      setImageData([]);
      setImageUrl("")
      setQuestionAnswers([])
      setAddNewAnswer(false)
    }
    setDisabled(false)
  }, [props]);


  useEffect(()=>{
    setEdited(JSON.stringify(values) != JSON.stringify(uneditedObj))
  },[values])
  
  useEffect(()=>{
    if(imageUrl === ""){
      handleChange(null, { key: "" }, "image");
    }else{
      handleChange(null, { key: imageUrl }, "image");
    }
  },[imageUrl])

  // function loadAccounts() {
  //   setLoading(true);
  //   AccountAdminService.getAccountsForUser()
  //     .then((response) => {
  //       if (response?.data?.accountList) {
  //         setAccounts(response.data.accountList);
  //       }
  //     }).catch((err) => {
  //       alert(err.message);
  //     }).finally(() => {
  //       setLoading(false);
  //     });
  // }

  // function onEmailChange(e) {
  //   e.persist();
  //   setFormData({ ...values, username: e.target.value, email: e.target.value })
  // }

  // function onEmailChange(e) {
  //   e.persist();
  //   setFormData({ ...values, username: e.target.value, email: e.target.value })
  // }
  // console.log(accounts)
  // useEffect(() => {

  //   if (accessToken.roles[0] === "SCHOOL_ADMIN"
  //     && accounts.length === 1
  //   ) {

  //     setLodeState(true)

  //     setFormData({ ...values, accountId: accounts[0].id });
  //     setAccounts(accounts[0].id)
  //   }

  // }, [lodeState])

  const getLoader = (e) => {
    setLoading(e)
  }
  function onSave() {
    
      setLoading(true);
      let submitPayload ={
        id: values.id,
        description: values.description,
        evaluate_step_id: props.evaluate_id,
        question_type_id: 1,
        class_level_id: props.currentClassLevel,
        questionExplanation: values.questionExplanation,
        image: imageUrl,
    
      }
      
  

      LessonService.addEvaluateStepQuestions(submitPayload)
        .then((response) => {
          if(response.status === 200){
            let messages = [{type:'success',text: response.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
          });
            setFormData(response.data)
            setUneditedObj(response.data)
            setDisabled(false)
            //clearForm2();
            //props.onClose()
            setAddNewAnswer(true)
           }
           else{
            setFormData({ ...values })
            let messages = [{type:'error',text: response.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
          });
            setAddNewAnswer(false)
           }
          }).catch((err) => {
            setFormData({ ...values })
            let messages = [{type:'error',text: err.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
          });
            setAddNewAnswer(false)
            clearForm2();
          }).finally(() => {
            setLoading(false);
          });
    
  }

  function hideAlert() {
    var alert = { showAlert: false, message: "", type: "success" }
    setAlertData(alert);
  }

  function clearForm() {
    reset();
    hideAlert();
    setFormData(obj);
    props.onClose()   
    // props.setFlag(false);
    setAddNewAnswer(false)
  }

  function clearForm2() {
    //reset();
   // setFormData(obj);
    setTimeout(function() {
      hideAlert();
    }, 2000);
    props.onClose()
  }

  const onChangeQuestion = (e) =>{
    setDesc(e)
  }
  const onChangeExplanation = (e) =>{
    setExplainData(e)
  }


  useEffect(() => {
    if(description.length > 0 || explain.length > 0){
      setFormData({...values,description:description,questionExplanation:explain})
    }
  },[description,explain])

  //   useEffect(() => {
  //     setLodeState(false);

  //     if(accessToken.roles[0] === "ACCOUNT_ADMIN" 
  //     && schools.length === 1

  //     ){
  //         setFormData({ ...values, organization_id: 2});
  //         setAccounts(2)  
  //     }
  // }, [schools ])

  // useEffect(() => {
  //   if (values.accountId) {
  //     SchoolService.getSchoolsForAccount(values.accountId)
  //       .then(res => {
  //         if (res.status === 200) {
  //           setSchools(res.data)
  //         }
  //       })
  //   }
  // }, [values.accountId]);

  const uploadImgUrl = (ulrData) =>{
    setImageUrl(ulrData)
  }
  const deleteImgUrl = () =>{
    setImageUrl("")
    setImageData([])

    if(values.id && values.id > 0){
      const payload = {
        "stepType":"evaluate",
        "image":""
      }
      LessonService.updatImage(values.id, payload)
    }
  }
  return (
    <>
      <div>
        <Spin tip="Loading..." spinning={loading} size="large">
          <Row>
            <Col md="3"></Col>
            <Col lg='6' className="py-2 text-center">
              {alertData.showAlert ?
                <Alert message={alertData.message} type={alertData.type}
                  action={
                    <a type="text" onClick={hideAlert}>
                      x
                    </a>
                  } /> : ""}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="form-row">
                {/* <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example1">
                    <span className="required-field">*</span>Question Type
                  </label>
                  <Input
                  disabled={props.view}
                    id="form3Example1"
                    type="select"
                    className="form-control form-control-lg"
                    value={values.question_type_id}
                    name="question_type_id"
                    onChange={(e) => { handleChange(e) }}
                    invalid={!!errors?.question_type_id}
                  >
                    {
                      
                        (
                          <>
                            <option key="-1" value="-1">Please Select a Question Type</option>
                            {
                              options6.map((obj,i) => <option key={i+1} value={i+1}>{obj}</option>)
                            }
                          </>
                        )

                    }
                  </Input>
                  <FormFeedback>{errors?.question_type_id}</FormFeedback>
                </div> */}
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example1">
                    <span className="required-field">*</span>Question
                  </label>
                  <SunEditor
                  disable={props.view}
            setContents={values.description}
            showToolbar={true}
            setDefaultStyle="height: 150px"
            setOptions={{
           "katex": "window.katex",
           "imageUploadSizeLimit": "5000000",
             buttonList: [
           [
            "undo",
            "redo",
            //"font",
            "fontSize",
            "formatBlock",
            "paragraphStyle",
            "blockquote",
            "bold",
            "underline",
            "italic",
            "strike",
            "hiliteColor",
            "subscript",
            "superscript",
            "math",
            "removeFormat",
            "horizontalRule",
            // "lineHeight",
            //"strike",
            "list",
            "outdent",
            "indent",
            "align",
            "table",
            "image",
            "codeView",
           // "preview",
           // "print",
            //"save",
            //"template",
             ],
         ]
       }}

       onChange={onChangeQuestion}

     />
                  <div className='error-msg'>{errors?.description}</div>

                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-12 ">
                  <label className="form-label" htmlFor="form3Example2">
                    Question Image
                  </label>
                  <br/>
                  {/* <Input
                    type="text"
                    id="form3Example2"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.image}
                    name="image"
                    onChange={handleChange}
                    
                  />  */}
                    <div className="mt--18">
                  <AddExplorerImagesUpload  disable={props.view} type={"image1"} uploadFileData={uploadImgUrl} deletedFile={deleteImgUrl} existingData={imageData}/> 
</div>
                  {/* <FormFeedback>{errors?.firstName}</FormFeedback> */}
                </div>
                <div className="form-group col-md-12 ">
                  <label className="form-label" htmlFor="form3Example3">
                    <span className="required-field">*</span>Question Explanation
                  </label>
                  <SunEditor
                  disable={props.view}
       setContents={values.questionExplanation}
       showToolbar={true}
       setDefaultStyle="height: 150px"
       setOptions={{
           "katex": "window.katex",
           "imageUploadSizeLimit": "5000000",
         buttonList: [
           [
            "undo",
            "redo",
            //"font",
            "fontSize",
            "formatBlock",
            "paragraphStyle",
            "blockquote",
            "bold",
            "underline",
            "italic",
            "strike",
            "hiliteColor",
            "subscript",
            "superscript",
            "math",
            "removeFormat",
            "horizontalRule",
            // "lineHeight",
            //"strike",
            "list",
            "outdent",
            "indent",
            "align",
            "table",
            "image",
            "codeView",
           // "preview",
           // "print",
            //"save",
            //"template",
             ],
         ]
       }}

       onChange={onChangeExplanation}
     />
                   <div className='error-msg'>{errors?.questionExplanation}</div>

                </div>
              </div>
           
            </Col>
          </Row>
          <Row>
          {!props.view && (
            <Col md='12'>
              <div className="center mt-4">
                <Button color="warning" className="mr-15" onClick={clearForm}>Cancel</Button>
                <Button color="success" onClick={()=>{
                          handleSubmit();
                        }} disabled={!edited}>Save</Button>
              </div>
            </Col>
          )}
          </Row>
          <Row>
            <Col md='12'>
              <EvaluateAnswer getLoader={getLoader} clearForm={clearForm} saveClick={addnewAnswer} disable={props.view === true} disabled={disable} view={props.view}  questionData={uneditedObj}  evaluate_id={props?.evaluate_id}   QuestionAnswers={ QuestionAnswers?.map((i,index) => {return {
                key:index,
                answer:i.answer,
                description:i.description,
                correctAnswer:i.is_correct_answer,
                id:i.id
            
            }}) ?? undefined} />
            
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
}

export default AdminCreate;
