import { useEffect } from "react";
// answers = {question_id: 1, answer: '1'} answer = answerNumber
// question  = {answer: "It gets <b>bigger</b> ↑",answerNumber: 1,description: "The tree is getting taller at the same time as time moves ahead.",engageStepId: 1,id: 1,is_correct_answer: 1,question_id: 1}
const CalculateMarks = (answers, questions, questionLength, marks) => {
    var marksPoint = 0;
        
    answers.map(x =>{   
        questions.filter(function(v) {
                if (v["question_id"] == x.question_id  && v["answerNumber"] == x.answer){
                    if(v.is_correct_answer){
                        marksPoint++;
                    }
                }
            })
        })
        const finalMarks = parseInt((marksPoint / questionLength)* marks)

        return finalMarks;
}

export default CalculateMarks;