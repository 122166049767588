import axios from "axios";
import Config from "./Config";

class CurriculumService {
  getAll() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'curriculum/getAllCurriculums', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getAllPaged(page, size, search = "") {
    const token = localStorage.getItem("access_token");
    const encodedSearch = encodeURIComponent(search);
    return axios.get(Config.APIURL + `curriculum/getAllCurriculums_paged?page=${page}&size=${size}&search=${encodedSearch}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getCurriculumLessons(curriculumId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/curriculum/getAssignLessions/' + curriculumId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getById(curriculumId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'curriculum/getCurriculumById/' + curriculumId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  updateCurriculum(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/curriculam/updateCurriculam', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  insertCurriculum(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/curriculam/createCurriculam', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  deleteCurriculum(curriculumId) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/curriculam/deleteCurriculum/' + curriculumId, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
}

export default new CurriculumService();