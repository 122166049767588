import { isDate } from "moment";

export default function validate(obj) {
    let errors = {};  
    let values = {...obj};
    let moreBlank =/^(\w+\s)*\w+$/g ;
    let specialChar = /^((?![-_!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+).)*$/gm;
  
    Object.keys(values).map(k => values[k] = trimObjectStrings(k));
  
    if (!values.name || values.name == '') {
      errors.name = 'Term Name is required';
    }else if(values.name && values.name != '' && values.name.length > 50){
      // errors.firstName = 'First Name must contain less than 50 characters';
      errors.name = 'Term Name must be less than 50 characters';
    }
    // else if (!values.name.match(specialChar)) {
    //   errors.name = 'Term Name can not have special characters';
    // }
  
    
  
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  
    if (!values.stdate || isDate(values.stdate)) {
      errors.stdate = 'Start Date  is required';
    } 
  
    if (!values.endDate || isDate(values.endDate)) {
      errors.endDate = 'End Date is required';
    }else if(new Date(values.stdate).getTime() >= new Date(values.endDate).getTime()  ){
      errors.endDate = 'End Date should be larger than Start Date';
    }
  
    // if (!values.accountId || values.accountId < 0) {
    //   errors.accountId = 'Account is required';
    // }
  
    // if (!values.organization_id || values.organization_id < 0) {
    //   errors.organization_id = 'School is required';
    // }
  
  
  
    function trimObjectStrings(key){
      if(typeof values[key] == 'string' && !key.toLowerCase().includes("password")){
        return values[key].trim();
      }
      return values[key];
    }
  
    return errors;
  };