import { Form, Tabs, Select, Image, Button } from "antd";
import "antd/dist/antd.css";
import { useState, useEffect } from "react";
import ReactJsAlert from "reactjs-alert"
import Config from "../../../../../../api/Config";
import "../../../../../LessonLab/Explore/childComponent/styles.css";

function removeDuplicateObjectFromArray(array, key) {
    return array.filter((obj, index, self) =>
        index === self.findIndex((el) => (
            el[key] === obj[key]
        ))
    )
}
const MapChild = (props) => {
   
    const [firstDropdown, setFirstDropdown] = useState("");
    const [secondDropdown,setSecondDropdown] = useState("");
    const [FirstOptions,setFirstOptions] =useState({});
    const [SecondOptions,setSecondOptions] = useState({});
    const [folderNameValue, setFolderNameValue] = useState("");
    const [activTab, setActivTab] = useState('1');
    const [selectval,setSelectVal] = useState('');
    const [disableActiveTabFeature, setdisableActiveTabFeature] = useState('1');
    const [selectVideo, setSelectVideo] = useState("");
    const [selectImg, setSelectImg] = useState([]);
    const [experi, setExperi] = useState(true);
    const [alertExpri, setAlertExpri] = useState(false);
    const [multipleData,setMultipleData] = useState([]);
    const [measure,setMeasure] = useState("");
    const [second,setSecond] = useState();
   const [val,setVal] = useState("");
    const [disabled,setDisabled] = useState(true);
    const [clickCrosshairDisable, setClickCrosshairDisable ] = useState(true)
    const [buttonData,setButtonData] = useState([]);

    const { TabPane } = Tabs;
    let src;
    const onKeyPress = (e) => {
        if (e.which === 13) {
            props.onCanvasClick(e);
        }
      }
      
    useEffect(()=>{

        if(props.chartType === "Line Type7"){
            setClickCrosshairDisable(!props.disableState)
            setButtonData(props.buttonData)
        }else{
            if(props.chartType === "Line Type4" || props.chartType === "Bar" ){
                setClickCrosshairDisable(false);
            }else{
                setClickCrosshairDisable(true);
            }
        }
    },[props])

    useEffect(()=>{

        if(selectval !== undefined){
            props?.data.map((x) =>{
                if(x.name[0].value === selectval){
                    setSelectVideo(x.videoUrl);
                    setSelectImg(x.images.split(","));
                    setVal(x.name[0]?.value)
                    props?.exColor(x.name[0]?.colors);
                    props?.selectedValue(x.name[0]?.value);
                    props?.id(x.id);
                    props?.visualName(x.description?.visualName);
                    props?.mappingId(x.description?.id);
                    setFolderNameValue(x.name[0]?.value);
                }
            });
            props?.resetValue();
            
        }
    },[selectval])


    useEffect(() =>{
       let firstarr = [];
       let SecondArr = [];
       const uniquefirstarr = [];
       const uniqueSecondArr = [];

        props?.data?.map((x,i) =>{
            if(x.name?.length > 0){
                setFirstDropdown(x.name[0]?.dropDownName);
                setSecondDropdown(x.name[1]?.dropDownName);
                
                firstarr.push({"value":x.name[0]?.option[0],"option":x.description?.visualName[0]})
                SecondArr.push({"value":x.name[1]?.option[0],"option":x.description?.visualName[1]})
                
                setVal(x.name[0]?.value)

                       }

           
        })

        const unique = firstarr.filter(element => {
            const isDuplicate = uniquefirstarr.includes(element.value);
          
            if (!isDuplicate) {
                uniquefirstarr.push(element.value);
          
              return true;
            }
          
            return false;
          });
        
          const unique2 = SecondArr.filter(element => {
            const isDuplicate = uniqueSecondArr.includes(element.value);
          
            if (!isDuplicate) {
                uniqueSecondArr.push(element.value);
          
              return true;
            }
          
            return false;
          });

        setFirstOptions(unique)
        setSecondOptions(unique2)
       
    },[props.data])

    useEffect(()=>{
       if(multipleData.length > 0){
        setMultipleData(removeDuplicateObjectFromArray(multipleData,"first"))
       }
    },[multipleData])


   

    //image called
    if (props.count >= selectImg.length) {
        const tCount = selectImg.length;
        src = selectImg[tCount - 1];
    } else {
        src = selectImg[props.count]
    }

    const Option = Select.Option;

    const handleChange = (e) => {
        props.toggleTab(false)
        setSelectVal(e)
        
        setActivTab('1');
        props?.data.map((x) =>{
             
            if(x.description === val){
                setSelectVideo(x.videoUrl);
                setSelectImg(x.images.split(","));
                setVal(x.name[0]?.value)
                 props?.exColor(x.name[0]?.colors)
            }
        });
        setdisableActiveTabFeature("1");

    }
    const tabToggle = () => {
        setActivTab('2');
    };
    const tabT = () => {
        setActivTab('1');

    };
    
    const expeiAlert = (value) =>{
        setExperi(true);
        props?.resetValue();
        handleChange(value);

    }
    const onHandle = (key) => {
        setActivTab(key);
        setdisableActiveTabFeature("2")
    };

    function convertStringToCamelCase(sentence) {
        return sentence.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g,
        function(camelCaseMatch, i) {
           if (+camelCaseMatch === 0)
              return "";
           return i === 0 ? camelCaseMatch.toLowerCase() :
           camelCaseMatch.toUpperCase();
        });
     }

     const [header, setHeader] = useState({
        h1: `Pick experiment `,
        h2: `Watch `,
        h3: `Measure `,
      });

       // for thr insect lab
    const buttons = buttonData.map(({ name, x, y, id }) => (
        <button
        className= {`insects ${name}` }
        key={id}
        onClick={() => props.onCanvasClick2(id)}
        style={{ position: "absolute", top: `${y}px`, left: `${x}px` }}
        disabled={props.btnDisableState}
        >
        </button>
    ));

     return (
        <>
         
        <Form layout="vertical">
       
            {
                secondDropdown === undefined &&
                <Form.Item label={`${header.h1} (${firstDropdown}) `}
                      className="experiment-dropdown" >
                      {experi ? 
                        <Select
                        getPopupContainer={trigger => trigger.parentNode}
                        placeholder="Select"
                        
                        onChange={handleChange}
                        >
                          {FirstOptions.map((x, id) =>{
                              return(
                                  <Select.Option value={x.value} key={id}>{x.option}</Select.Option>
                              )
                          })}
                          
                        </Select>
                    : 
                    <Select
                    placeholder="Select"
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={expeiAlert}
                    >
                    {FirstOptions.map((x, id) =>{
                        return(
                            <Select.Option value={x.value} key={id}>{x.option}</Select.Option>
                        )
                    })}
                    </Select>

                    }
                     
                </Form.Item>
                
            }
                {
                    secondDropdown &&
                   
                   <div className="flex">
                        
                   <Form.Item label={firstDropdown} className="half-drop">
                   <Select
                    placeholder="Choose One"
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={(e)=>{
                        props.toggleTab(false)
                        
                        setActivTab('1');
                        setMeasure(e);
                        setDisabled(false);
                        setSelectVal(`${e}-${second}`);

                        }}
                    >
                    {FirstOptions.map((x, id) =>{
                        return(
                            <Select.Option value={x.value} key={id}>{x.option}</Select.Option>
                        )
                    })}
                    </Select>

            </Form.Item>
            <Form.Item label={secondDropdown} className="half-drop">
                <Select 
                    placeholder="Choose One"
                    disabled ={disabled}
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={(e)=>{
                        props.toggleTab(false)
                        setActivTab('1');
                        setSecond(e)
                        setSelectVal(`${measure}-${e}`);
                        }}
                    >
                    {SecondOptions.map((x, id) =>{
                        return(
                            <Select.Option value={x.value} key={id}>{x.option}</Select.Option>
                        )
                    })}
                </Select>
        </Form.Item>

   
  
             </div>
          
              
                }
                </Form>
              
                    <ReactJsAlert
                      status={alertExpri?.status}   // true or false
                      type="Warning"   // success, warning, error, info
                      title="All progress will be lost. Are you sure you want to proceed?"   // title you want to display
                      
                      Close={() => {
                        tabT()
                        handleChange()
                     }
                    }   // callback method for hide
                    />
          
            {/* tabs section */}
            <div className="inner-tab">
                <div className="lesson-plan">
                <Tabs activeKey={activTab} onChange={onHandle} >
                    <TabPane tab={header.h2} key="1" >
                        <video className="video-lg" id="video" src={`${selectVideo}`} controls onEnded={() => {
                            props.toggleTab(true)
                            setExperi(false)
                            tabToggle()
                        }}
                        ></video>
                    </TabPane>
                    <TabPane
                        tab={header.h3}
                        key="2"
                        disabled={activTab === "1" && disableActiveTabFeature === "1" ? true : false}
                    >
                        <div className="explore-main-container" height={400} width={540} >
                            <div className="explore-measure-img-container" height={400} width={540} >
                                {
                                    props.chartType !== "curve"? <img id="canvasimage" height={400} width={540} className="explore-measure-img" src={`${src}`} alt="" />
                                    :
                                    <img id="canvasimage" height={400} width={540} className="explore-measure-img" src={`${src}`} alt="" />
                                }
                                {
                                   clickCrosshairDisable   && (
                                    <div style={{ position: "relative" }}>
                                        <canvas id="experimentcanvas"  height={400} width={540} onClick={props.onCanvasClick} onKeyPress={onKeyPress} className="explore-measure-img-canvas" />
                                        {props.chartType === "Line Type7" && buttons}
                                    </div>
                                    )
                                }
                            </div>
                            <div>
                            </div>
                
                        </div>
                        
                    </TabPane>
                </Tabs>
                </div>
            </div>
        </>
    );
}

export default MapChild;
