import { useEffect, useState } from "react";
import { Spin, Input, Table, Select, Drawer, Popconfirm, notification } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined, CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Assign from "./Assign"
import { Row, Col, Badge } from "reactstrap";
import CurriculumService from "../../../../api/CurriculumService"

import Edit from "./edit"

function CurriculumView(props) {
  const [size, setSize] = useState();
  const [visibleEditDrawer, setVisibleEditDrawer] = useState(false);

  const [editKey, setEditKay] = useState(1);

  const [curriculams, setCurriculams] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoadingC] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const [selectedEdit, setSelectedEdit] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (props.activeTab == props.tabId) {
      loadCurriculums()
    }
  }, [props.activeTab])

  function loadCurriculums(page = currentPage, pageSize = defaultPageSize, search = searchTerm) {
    setCurrentPage(page);
    setDefaultPageSize(pageSize);

    setLoadingC(true);
    CurriculumService.getAllPaged(page, pageSize, search)
      .then((response) => {
        if (response?.data) {
          setCurriculams(response.data.items);
          setTableData(response.data.items);
          setTotal(response.data.totalItems);
          setCurrentPage(response.data.page);
        }
      }).catch((err) => {
        console.log(err.message);
      }).finally(() => {
        setLoadingC(false);
      });
  }

  function onSearchByName(e) {
    setCurrentPage(1);
    const currValue = e.target.value;
    setSearchTerm(currValue);
    setCurrentPage(1);
    loadCurriculums(1, defaultPageSize, currValue)
  }

  const columns = [
    {
      title: "Curriculum Name",
      dataIndex: "name",
      key: "name",
      className: "fixed-size",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Action",
      render: (text, record) =>
        <div className="button-set">
          <span className="btn btn-sm btn-info float-left" onClick={() => { showEditDrawer(record) }}>Edit</span>
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={(e) => { deleteRecord(record) }}
            okText="Yes"
            cancelText="No"
          >
            <span className='btn btn-sm btn-danger float-left ml-1' size="sm" >
              Delete
            </span >
          </Popconfirm>
        </div>,
      className: "action"
    }
  ];

  const showEditDrawer = (record) => {
    setSelectedEdit(record);
    setVisibleEditDrawer(true);
    setSize("small");
  };

  const deleteRecord = (record) => {
    setLoadingC(true);
    CurriculumService.deleteCurriculum(record.id).then((response) => {
      notification.open({
        placement: 'top',
        duration: "2",
        description: [<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{response.message}</span></li>],
      });
      loadCurriculums();
    }).catch((err) => {
      notification.open({
        placement: 'top',
        duration: "5",
        description: [<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{err.message}</span></li>],
      });
    }).finally(() => {
      setLoadingC(false);
    });
  };

  const onCloseEditDrawer = () => {
    loadCurriculums();
    setVisibleEditDrawer(false);
    setEditKay(prev => prev + 1);
  }

  return (
    <>
      <div>
          <div className="my-5">
            <Row>
              <Col md="4"></Col>
              <Col md="4">
                <Input
                  size="large"
                  placeholder="Search"
                  prefix={<SearchOutlined className="ml-3 mr-3" />}
                  onChange={onSearchByName}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col md="12">
              <Table
                dataSource={tableData}
                //pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                pagination={{
                  current: currentPage,
                  position: ["bottomCenter"],
                  total: total, // Total number of items in the API response (you may need to fetch this dynamically)
                  pageSize: defaultPageSize, // Number of items to display per page
                  onChange: (page, pageSize) => loadCurriculums(page, pageSize), // Callback function to handle page changes
                  showSizeChanger: false
                }}
                loading={loading}
                columns={columns}
                className="thead-dark admin-table"
              />
            </Col>
          </Row>
          <Drawer
            className="medium"
            title={`Edit Curriculum`}
            placement="right"
            size={size}
            onClose={onCloseEditDrawer}
            visible={visibleEditDrawer}
          >
            <Edit key={editKey} selectedName={selectedEdit?.name} onClose={onCloseEditDrawer} selectedId={selectedEdit?.id}/>
          </Drawer>
      </div>
    </>
  );
}

export default CurriculumView;
