import axios from "axios";
import Config from "./Config";

class AccountAdminService {

  getAccountsForUser() {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/account/getAccountsByUserId', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
      })

  }

  getAdminsByAccountId(id, page, size, search = "") {
    const token = localStorage.getItem("access_token");
    const encodedSearch = encodeURIComponent(search);
    return axios.get(Config.APIURL + `admin/account/getAccountAdminsByAccountId_paged/${id}?page=${page}&size=${size}&search=${encodedSearch}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)

        // return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  getSchAdminBySchoolId(id, page, size, search = "") {
    const token = localStorage.getItem("access_token");
    const encodedSearch = encodeURIComponent(search);

    return axios.get(Config.APIURL + 'admin/school/getSchoolAdminsBySchoolId_paged/' + id + `?page=${page}&size=${size}&search=${encodedSearch}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  getAdminById(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/account/getAccountAdminsByUserId/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  getSchAdminById(id) {
    const token = localStorage.getItem("access_token");
    return axios.get(Config.APIURL + 'admin/school/getSchoolAdminsByUserId/' + id, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        // return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  insertNewAdmin(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/account/createNewAdminAccount', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  insertNewSchAdmin(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/school/createNewSchoolAdmin', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  updateAdmin(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/account/updateAdminAccount', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
  updateSchAdmin(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/school/updateSchoolAdmin', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }

  updateDefaultStudentPassword(data) {
    const token = localStorage.getItem("access_token");
    return axios.post(Config.APIURL + 'admin/student/updateUserPassword', data, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const data = res;
        return data;
      })
      .catch((err) => {
        console.log("error", err.response?.data ? err.response?.data : err)
        return Promise.reject(err.response?.data ? err.response?.data : err);
      })
  }
}

export default new AccountAdminService();