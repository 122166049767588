import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { Row, Col, Badge } from "reactstrap";

function SubExplorerImageCarosel2(props) {
  const [answers, setAnswers] = useState([]);
  const [trigger, setTrigger] = useState(0);

  useEffect(()=>{
    if(props.initialBarData && props.initialBarData.length > 0 && trigger === 0){
      setAnswers(props.initialBarData)
      setTrigger(trigger+1)
    }
  },[props])

  
  function handleAnswerChange(index, fieldName, value) {
    const newAnswers = [...answers];
    newAnswers[index] = {
      ...newAnswers[index],
      [fieldName]: value,
    };
    setAnswers(newAnswers);
  }

  useEffect(()=>{
    props.getValuesFromBarAnswer(answers);
  },[answers]);


  if (props?.length !== undefined) {
    return props?.length.map((url, index) => (
      <React.Fragment key={index}>
        <Row>
          <Col md="6">
            <Form.Item name={`answer1-${index}`} label={`Image ${index+1} -  X Value`}>
              {/* for now don't remove the log as without the log the answer[index] doesn't gets updated*/}
            {console.log(answers[index]?.answer1)}
              <Input
                type='text'
                placeholder={ "X Value"}
                value={answers[index]?.answer1}
                onChange={(e) => handleAnswerChange(index, 'answer1', e.target.value)}
                required
              />
            </Form.Item>
          </Col>
          <Col md="2">
            <Form.Item name={`colorPointer-${index}`} label={`Bar Colour`}>
            {console.log(answers[index]?.colorPointer)}
              {/* for now don't remove the log as without the log the answer[index] doesn't gets updated for color */}
                  <Input type="color"  
                  className='wi20'
                  value={answers[index]?.colorPointer}
                  onChange={(e) => handleAnswerChange(index, 'colorPointer', e.target.value)}
                  />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    ));
  }
  return null;
}

export default SubExplorerImageCarosel2;
