
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Config from '../api/Config';


const initialState = {
    editGraphData: [],
    GraphDataTable: [],
   
}

const graphDataSlice = createSlice({
    name: "graphData",
    initialState,
    
    reducers: {
        getGraphData: (state, action) => {
            state.GraphDataTable = (action.payload);
        
        },
        addToTable: (state, action) => {
            state.GraphDataTable.push(action.payload)
        },

        // editGraphData: (state, action) => { 
        //     state.graphData = action.payload 
        // }

    },
   
})

export const GraphDatareducer = graphDataSlice.reducer;

export const { addGraphData, editGraphData, addToTable } = graphDataSlice.actions;