import Config from "./Config";
import axios from "axios";


export const GetAllLessons = async () => {
  let token = localStorage.getItem("access_token");
  const response = await axios.get(Config.APIURL + `lesson/getAllLessons`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};

export const GetAllCurriculams = async () => {
  let token = localStorage.getItem("access_token");
  const response = await axios.get(Config.APIURL + `admin/class/getAllCurriculams`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};

export const AddNewClass = async (classData) => {
  let token = localStorage.getItem("access_token");
  const response = await axios.post(Config.APIURL + `admin/class/addNewClass`, classData, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};

export const updateLessonStatus = async (lessonId, status) => {
  // const token = localStorage.getItem("access_token");
  // return await axios.post(Config.APIURL + 'admin/lesson/updateLessonStatus/' + lessonId + '/' + status, { headers: { "Authorization": `Bearer ${token}` } })
  //   .then((res) => {
  //     const data = res.data;
  //     return data;
  //   })
  //   .catch((err) => {
  //     return Promise.reject(err.response?.data ? err.response?.data : err);
  //   })


  let token = localStorage.getItem("access_token");
  const response = await axios.post(Config.APIURL + `admin/lesson/updateLessonStatus/${lessonId}/${status}`, [], {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    return data;
  })
    .catch((error) => console.log("error", error));

  return response;
};