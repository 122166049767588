import { useState, useEffect } from "react";
import { Input, FormFeedback, Row, Col, Button } from "reactstrap";
import { Alert, notification, Spin } from 'antd';
import "antd/dist/antd.css";
import CurriculumService from "../../../../api/CurriculumService"
import useCustFormValidator from "../../../../hooks/useCustFormValidator";
import validate from './curriculumFormValidationRules';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
function CurriculumEdit(props) {

  const [loading, setLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [uneditedObj, setUneditedObj] = useState({});
  const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });

  const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(onSave, validate, {});

  useEffect(() => {
    getCurriculumFromId(props.selectedId);
  }, [props.selectedId]);

  useEffect(() => {
    setEdited(JSON.stringify(values) != JSON.stringify(uneditedObj))
  }, [values]);

  function getCurriculumFromId(id) {
    setLoading(true);
    CurriculumService.getById(id)
      .then((response) => {
        if (response.data) {
          setUneditedObj(response.data);
          setFormData(response.data);
        }
      }).catch((err) => {
        //alert(err.message);
      }).finally(() => {
        setLoading(false);
      });
  }

  function onSave() {
    setLoading(true);
    let submitPayload ={
      id:props.selectedId,
      description:values.description,
      name:values.name

    }
    CurriculumService.updateCurriculum(submitPayload)
      .then((response) => {
        console.log(response)
        if (response.status == 200) {
          let messages = [{type:'success',text: response.message}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
          onCancel();
        } else if (response.status == 500) {
          let messages = [{type:'error',text: response.message}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
        }
      }).catch((err) => {
        let messages = [{type:'error',text: err.message}]
        notification.open({
          placement: 'top',
          duration:"2",
          description: [
              messages.map((item) => {
                  if(item.type === 'success') {
                      return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if(item.type === 'error') {
                      return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if(item.type === 'warning') {
                      return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
              })
          ],
  
          onClick: () => {
              console.log('Notification Clicked!');
          },
      });
      }).finally(() => {
        setLoading(false);
      });
  }

  function onCancel() {
    reset();
    getCurriculumFromId(props.selectedId);
  }

  function hideAlert() {
    var alert = { showAlert: false, message: "", type: "success" }
    setAlertData(alert);
  }

  return (
    <>
      <Spin tip="Loading..." spinning={loading} size="large">
        <Row>
          <Col lg='12' className="py-2 text-center">
            {alertData.showAlert ?
              <Alert message={alertData.message} type={alertData.type}
                action={
                  <a type="text" onClick={hideAlert}>
                    x
                  </a>
                } /> : ""}
          </Col>
        </Row>
        <form>
          <div>
            <Row>
              <Col lg="12">
                <div className="form-outline mb-3">
                  <label className="form-label" htmlFor="form3Example4">
                    <span className="required-field">*</span>Curriculum Name
                  </label>
                  <Input
                    type="text"
                    id="form3Example4"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    name="name"
                    onChange={(e) => { handleChange(e) }}
                    invalid={!!errors?.name}
                    value={values?.name}
                  />
                  <FormFeedback>{errors?.name}</FormFeedback>
                </div>
                <div className="form-outline mb-3">
                  <label className="form-label" htmlFor="form3Example4">
                  <span className="required-field">*</span>Description
                  </label>
                  <Input
                    id="exampleText"
                    name="description"
                    type="textarea"
                    onChange={(e) => { handleChange(e) }}
                    value={values?.description}
                    invalid={!!errors?.description}
                  />
                  <FormFeedback>{errors?.description}</FormFeedback>
                </div>
                <div>
                  <Row>
                    <Col lg='12' className="center">
                      <Button color="warning" className="mr-15" onClick={props.onClose}>Cancel</Button>
                      <Button onClick={handleSubmit} color="success" disabled={!edited}>Save</Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </form>
      </Spin>
    </>
  );
}

export default CurriculumEdit;
