import { useEffect,useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Table,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Radio, Input, Form, Modal, Button, Tabs, Spin } from "antd";

import usePreventZoom from "./components/usePreventZoom/usePreventZoom";

import Engage from "./Engage/index";
import Explore from "./Explore/main";
import 'animate.css';
import Evaluate from "./Evaluate/index";
import Explain from "./Explain/index";
import Extend from "./Extend/index";
import Question from "../../components/Questions/Question";
import Step from "../../components/Step";
import Footer from "../../components/Footer/Footer";
import { useParams } from "react-router";
import jsonToken from 'jsonwebtoken';
import RestService from "../../api/RestService";

function LessonLabIndex(props) {

  usePreventZoom()
  let {engageStepId,evaluateStepId,explainStepId,exploreStepId,extendStepId} = useParams();
  let user = "";
  let token = localStorage.getItem("access_token");
  let userRoute = JSON.parse(localStorage.getItem('userRoutes'));
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;
    
  }

// used for activity duration.
const [previousDuration, setPreviousDuration] = useState(0);

    // RestService.getEngageStepByIdAndUserName(user, engageStepId)
    //   .then((response) => {
    //     if (response) {
    //       console.log("yo can see", response);
    //       //  setPreviousDuration()
    //     }
    //   })
  const start = new Date();

  function getEndDuration (value){
    const diffInMilliseconds = value - start;
    const diffInSeconds = diffInMilliseconds / 1000;
    const diffInMinutes = diffInSeconds / 60;

    const totalDuration = parseFloat(diffInMinutes) +  parseFloat(previousDuration);
    const submitPayload = {
      engageStepId:engageStepId,
      evaluateStepId:evaluateStepId,
      explainStepId:explainStepId,
      exploreStepId:exploreStepId,
      extendStepId:extendStepId,
      totalDuration:totalDuration,
      username: user
    }
    
    setPreviousDuration(totalDuration)
    RestService.postTimeDuration(submitPayload)
  }


  const { TabPane } = Tabs;
  const [active,setActive] = useState(true);
  const [navStatus, setNavStatus] = useState(1);
  const [complted1,setCompleted1] = useState(false);
  const [complted2,setCompleted2] = useState(false);
  const [complted3,setCompleted3] = useState(false);
  const [complted4,setCompleted4] = useState(false);
  const [complted5,setCompleted5] = useState(false);
  const [engageStatus,setEngageStatus]  = useState(true);
  const [locStore,setLocStore]  = useState("");
  
  const stopAllMedia = () => {
    // Stop all video elements
    const videos = document.querySelectorAll('video');
    videos.forEach((video) => video.pause());
  
    // Stop all audio elements
    const audios = document.querySelectorAll('audio');
    audios.forEach((audio) => audio.pause());
  
    // Stop all YouTube iframes
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach((iframe) => {
      if (iframe.src.includes('youtube.com')) {
        const src = iframe.src;
        iframe.src = src; // Reloading the iframe to stop the video
      }
    });
  };
  const activationFun =() =>{
    setActive(false)
  }
  const [activeTab, setActiveTab] = useState("1");

  const callback = (key) => {
    updateTabBackground(key);
    setActiveTab(key);
    stopAllMedia();
   
  };

  
  

  useEffect(() => {
    
    const getNotificationDetails = localStorage.getItem("specific")
    setInterval(()=>{
      setLocStore(getNotificationDetails)
    },2000)
    try{
     setTimeout(() =>{
       RestService.getEngageStepByIdAndUserName(user, engageStepId)
       .then((response) => {
         if (response) {
           setPreviousDuration(response?.data[0]?.duration)
            if(response.data[0].status === 2){
             setActiveTab("2");
             setCompleted1(true)
             setEngageStatus(false);
            }
            
         }
       })
       RestService.getExploreStepUserStatusByIdAnduserName(user, exploreStepId)
       .then((response) => {
         if (response) {
          console.log(response)
            if(response.data[0].status === 2){
             
             setActiveTab("3");
             setActive(false);
             setCompleted2(true);
   
            }
            
         }
       })
       RestService.getEvaluateStepByIdAndUserName(user, evaluateStepId)
       .then((response) => {
         if (response) {
            if(response.data[0].status === 2){
             
             setActiveTab("4");
             setActive(false);
             setCompleted3(true);
   
            }
            
         }
       })
       RestService.getExplaineStepByIdAndUserName(user, explainStepId)
       .then((response) => {
         if (response) {
            if(response.data[0].status === 2){
             
             setActiveTab("5");
             setActive(false);
             setCompleted4(true);
   
            }
            
         }
       })
       RestService.getExtendStepByIdAndUserName(user, extendStepId)
       .then((response) => {
         if (response) {
            if(response.data[0].status === 2){
             
             setActiveTab("5");
             setActive(false);
             setCompleted5(true);
   
            }
            
         }
       })
      },1000)
    }
    catch(e){
      console.log("Error",e)
    }   
}, []); 



  useEffect(()=>{
      if(locStore === "engageStep"){
        localStorage.removeItem("specific");
        callback("1");
        }
        else if(locStore === "exploreStep"){
          localStorage.removeItem("specific");
          callback("2");
        }
        else if(locStore === "evaluateStep"){
          localStorage.removeItem("specific");
          callback("3");
        }
        else if(locStore === "explainStep"){
          localStorage.removeItem("specific");
          callback("4");
        }
        else if(locStore === "extendStep"){
          localStorage.removeItem("specific");
          callback("5");
        }
  },[locStore])
 
  const updateTabBackground = (key) => {
    const tabs = document.querySelectorAll(".ant-tabs-tab");
    const tabback = document.querySelectorAll(".ant-tabs-nav-list");
    const pageback = document.querySelectorAll(".page-back");
    if (key == 1) {
      tabs[key - 1].classList.add("engage-tab");
      tabback[key - 1].classList.add("engage-tab-back");
      pageback[key - 1].classList.add("engage-tab-back");
      
    }
   
    setTimeout(() => {
      if (key == 1) {
        tabs[key - 1].classList.add("engage-tab");
        tabback[key - 1].classList.add("engage-tab-back");
        pageback[key - 1].classList.add("engage-tab-back");
        tabback[key - 1].classList.remove("explore-tab-back");
        pageback[key - 1].classList.remove("explore-tab-back");
        tabback[key - 1].classList.remove("evaluate-tab-back");
        pageback[key - 1].classList.remove("evaluate-tab-back");
        tabback[key - 1].classList.remove("explain-tab-back");
        pageback[key - 1].classList.remove("explain-tab-back");
        tabback[key - 1].classList.remove("expand-tab-back");
        pageback[key - 1].classList.remove("expand-tab-back");
      } else if (key == 2) {
        tabs[key - 1].classList.add("explore-tab");
        tabback[key - 2].classList.add("explore-tab-back");
        pageback[key - 2].classList.add("explore-tab-back");
        tabback[key - 2].classList.remove("engage-tab-back");
        pageback[key - 2].classList.remove("engage-tab-back");
        tabback[key - 2].classList.remove("evaluate-tab-back");
        pageback[key - 2].classList.remove("evaluate-tab-back");
        tabback[key - 2].classList.remove("explain-tab-back");
        pageback[key - 2].classList.remove("explain-tab-back");
        tabback[key - 2].classList.remove("expand-tab-back");
        pageback[key - 2].classList.remove("expand-tab-back");
      } else if (key == 3) {
        tabs[key - 1].classList.add("evaluate-tab");
        tabback[key - 3].classList.add("evaluate-tab-back");
        pageback[key - 3].classList.add("evaluate-tab-back");
        tabback[key - 3].classList.remove("engage-tab-back");
        pageback[key - 3].classList.remove("engage-tab-back");
        tabback[key - 3].classList.remove("explore-tab-back");
        pageback[key - 3].classList.remove("explore-tab-back");
        tabback[key - 3].classList.remove("explain-tab-back");
        pageback[key - 3].classList.remove("explain-tab-back");
        tabback[key - 3].classList.remove("expand-tab-back");
        pageback[key - 3].classList.remove("expand-tab-back");
      } else if (key == 4) {
        tabs[key - 1].classList.add("explain-tab");
        tabback[key - 4].classList.add("explain-tab-back");
        pageback[key - 4].classList.add("explain-tab-back");
        tabback[key - 4].classList.remove("evaluate-tab-back");
        pageback[key - 4].classList.remove("evaluate-tab-back");
        tabback[key - 4].classList.remove("explore-tab-back");
        pageback[key - 4].classList.remove("explore-tab-back");
        tabback[key - 4].classList.remove("engage-tab-back");
        pageback[key - 4].classList.remove("engage-tab-back");
        tabback[key - 4].classList.remove("expand-tab-back");
        pageback[key - 4].classList.remove("expand-tab-back");
      } else if (key == 5) {
        tabs[key - 1].classList.add("expand-tab");
        tabback[key - 5].classList.add("expand-tab-back");
        pageback[key - 5].classList.add("expand-tab-back");
        tabback[key - 5].classList.remove("evaluate-tab-back");
        pageback[key - 5].classList.remove("evaluate-tab-back");
        tabback[key - 5].classList.remove("explore-tab-back");
        pageback[key - 5].classList.remove("explore-tab-back");
        tabback[key - 5].classList.remove("explain-tab-back");
        pageback[key - 5].classList.remove("explain-tab-back");
        tabback[key - 5].classList.remove("engage-tab-back");
        pageback[key - 5].classList.remove("engage-tab-back");
      }
    }, 100);
  };
  const submitTrigger=(key)=>{
    if(parseInt(key) === 2){
      setEngageStatus(false);
    }
    callback(key);

  
  }

  const setEvaluateComplete = () => {
    setCompleted3(true)
  }

  const setEngageComplete = () => {
    setCompleted1(true)
  }
  

  const engageTotalMarks = 25;
  const evaluateTotalMarks = 25;

  const [header, setHeader] = useState({
    h1: `Engage`,
    h2: `Explore`,
    h3: `Evaluate`,
    h4: `Explain`,
    h5: `Extend`,
  });
  return (
    // <Spin tip="Loading..." spinning={loading} size="large">
      <div className="container-fluid page-back">
        <div className="content">
          <Row>
            <Col md="12" className="ltab fixed-ltab pb-4 fixed-tab">
              <Tabs
              defaultActiveKey={activeTab}
                activeKey={activeTab}
                onChange={callback}
                className="lesson-tab"
              >
                <TabPane tab={header.h1} key="1" className="engage">
                  <Engage getEndDuration={getEndDuration} submitTrigger={submitTrigger} engageId={engageStepId} marks={engageTotalMarks} activatingRS={setEngageComplete} complted={complted1} firstTime={userRoute?.userRoute?.engage === 0}/>
                </TabPane>
                <TabPane tab={header.h2} key="2" className="explore" disabled ={engageStatus}>
                  <Explore activeTab={activeTab} getEndDuration={getEndDuration} activationFun={activationFun} submitTrigger={submitTrigger} exploreId={exploreStepId} firstTime={userRoute?.userRoute?.explore === 0}/>
                </TabPane>
                <TabPane tab={header.h3} key="3" className="evaluate" disabled ={active}>
                  <Evaluate  getEndDuration={getEndDuration} submitTrigger={submitTrigger} evaluateId={evaluateStepId} marks={evaluateTotalMarks} activatingRS={setEvaluateComplete} exploreId={exploreStepId} complted={complted3} firstTime={userRoute?.userRoute?.evaluate === 0}/>
                </TabPane>
                <TabPane tab={header.h4} key="4" className="explain" disabled ={active}>
                  <Explain  getEndDuration={getEndDuration} submitTrigger={submitTrigger} explainId={explainStepId} exploreId={exploreStepId}  complted={complted4} firstTime={userRoute?.userRoute?.explain === 0}/>
                </TabPane>
                <TabPane tab={header.h5} key="5" className="expand" disabled ={active} >
                  <Extend getEndDuration={getEndDuration} submitTrigger={submitTrigger} extendId={extendStepId} complted={complted5} firstTime={userRoute?.userRoute?.extend === 0}/>
                </TabPane>
               
              </Tabs>
             
              
            
            </Col>
          </Row>
        </div>
      </div>

    // </Spin>
  );
}

export default LessonLabIndex;
