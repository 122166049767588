import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { Row, Col, Badge } from "reactstrap";

function SubExplorerImageCarosel(props) {
  // console.log(props)
  const [answers, setAnswers] = useState([]);
  const [trigger, setTrigger] = useState(0);

  useEffect(()=>{
    if(props.initialFourPointData && props.initialFourPointData.length > 0 && trigger === 0){
      setAnswers(props.initialFourPointData)
      setTrigger(trigger+1)
    }
  },[props])

  
  function handleAnswerChange(index, fieldName, value) {
    // Clone the answers array to avoid mutating state directly
    const newAnswers = [...answers];
    // Update the answer object for the specified index
    newAnswers[index] = {
      ...newAnswers[index],
      [fieldName]: value,
    };
    // console.log(newAnswers)
    // Update the state with the new answers array
    setAnswers(newAnswers);
  }

  useEffect(()=>{
    props.getValuesFromQuestionAnswer(answers);
  },[answers]);

//   useEffect(()=>{
//     setAnswers(Object.values(props.pastAnswers));
//   },[props.pastAnswers]);


  if (props?.length !== undefined) {
    return props?.length.map((url, index) => (
      <React.Fragment key={index}>
        <Row>
          <Col md="3">
            <Form.Item name={`answer1-${index}`} label={`Image ${index+1} - (0,0) : X-Value`}>
            {/* for now don't remove the log as without the log the answer[index] doesn't gets updated*/}
              {console.log(answers[index]?.answer1)}
              <Input
                type='number'
                placeholder={"(0,0) : X-Value"}
                value={answers[index]?.answer1}
                onChange={(e) => handleAnswerChange(index, 'answer1', e.target.value)}
                required
              />
            </Form.Item>
          </Col>
          <Col md="3">
            <Form.Item name={`answer3-${index}`} label={`(0,0) : Y-Value`}>
            {/* for now don't remove the log as without the log the answer[index] doesn't gets updated*/}
            {console.log(answers[index]?.answer3)}
              <Input
                type='number'
                placeholder={"(0,0) : Y-Value"}
                value={answers[index]?.answer3}
                onChange={(e) => handleAnswerChange(index, 'answer3', e.target.value)}
                required
              />
            </Form.Item>
          </Col>
          <Col md="3">
            <Form.Item name={`answer2-${index}`} label={`(1,0) : X-Value`}>
            {/* for now don't remove the log as without the log the answer[index] doesn't gets updated*/}
            {console.log(answers[index]?.answer2)}

              <Input
                type='number'
                placeholder={"(1,0) : X-Value"}
                value={answers[index]?.answer2}
                onChange={(e) => handleAnswerChange(index, 'answer2', e.target.value)}
                required
              />
            </Form.Item>
          </Col>
          <Col md="3">
            <Form.Item name={`answer4-${index}`} label={`(0,1) : Y_Value`}>
            {/* for now don't remove the log as without the log the answer[index] doesn't gets updated*/}
            {console.log(answers[index]?.answer4)}

              <Input
                type='number'
                placeholder={ "(0,1) : Y_Value"}
                value={answers[index]?.answer4}
                onChange={(e) => handleAnswerChange(index, 'answer4', e.target.value)}
                required
              />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    ));
  }
  return null;
}

export default SubExplorerImageCarosel;
