import { useCallback, useEffect, useMemo, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Tabs, Input, Table, Select, Spin, Drawer, Space, notification, Transfer, Switch, Tooltip } from "antd";
import "antd/dist/antd.css";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  Row,
  Col,
  CardBody,
  Button,
  FormFeedback
} from "reactstrap";
import axios from "axios";
import Config from "../../../api/Config";
import LessonService from "../../../api/LessonService";
import CurriculumService from "../../../api/CurriculumService";
import useCustFormValidator from "../../../hooks/useCustFormValidator";
import validate from "./LessonDuplicationValidation";
import { updateLessonStatus } from "../../../api/LessonService1";

const { Option } = Select;

const onSearch = (value) => {
  console.log("search:", value);
};

function ViewClass(props) {
  const [lessons, setLesson] = useState([]);
  const [curriculum, setCurriculum] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [lessonDuplicationId, setLessonDuplicationId] = useState(undefined);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCurriculums, setSelectedCurriculums] = useState([]);
  const [selectedCurriculumsSend, setSelectedCurriculumsSend] = useState([]);

  const [checkedMap, setCheckedMap] = useState({});
  const [searchText, setSearchtext] = useState('')

  const onChangeStatus = (status, lessonId) => {
    // Update the checked value in the checkedMap state
    setCheckedMap((prevCheckedMap) => ({
      ...prevCheckedMap,
      [lessonId]: status,
    }));

    let status_value = 0;
    if (status) {
      status_value = 1;
    }

    updateLessonStatus(lessonId, status_value).then(res => {
    })
  };

  const columns = [
    {
      title: "Lesson Name",
      dataIndex: "heading",
      key: "name",
      className: "fixed-255",
    },
    {
      title: "Curriculum",
      dataIndex: "curriculumName",
      key: "curriculum",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) => {
        const defStatus = record.status === 1 ? true : false;
        const switchValue = checkedMap[record.id] !== undefined ? checkedMap[record.id] : defStatus;
        console.log('record.parent', record.parent)
        return (
          <>
            {/* status: draft/published */}
            <Tooltip title={switchValue ? 'Publish' : 'Draft'}>
              {/* https://ant.design/components/switch */}
              <Switch
                checked={switchValue}
                disabled={record.parent === 0 || record.parent === 1 ? true : false}
                onChange={(e) => onChangeStatus(e, record.id)}
              />
            </Tooltip>
          </>
        );
      }
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <>
          <Row>
            <Col md="12">
              <Button
                disabled={record.parent === 0 || record.parent === 1 ? false : true}
                className="btn btn-sm btn-info"
                onClick={() => {
                  props.setCurrent(0);
                  props.setEditInfo(record);
                  console.log(90, record);
                }}
              >
                Edit
              </Button>
              <Button
                disabled={record.parent === 0 || record.parent === 1 ? false : true}
                className="btn btn-sm btn-info mx-2" onClick={() => {
                  setLessonDuplicationId(record?.id)
                  getCurriculumForLesson(record?.id)
                  showDrawer(record)
                }}
              >
                Duplicate
              </Button>
            </Col>
          </Row>

        </>
      ),
      className: "lesson-action",
    },
  ];

  const mockData = Array.from({
    length: 20,
  }).map((_, i) => ({
    key: i.toString(),
    title: `content${i + 1}`,
    description: `description of content${i + 1}`,
  }));

  useEffect(() => {
    loadLeasson();
    CurriculumService.getAll()
      .then((response) => {
        if (response?.status === 200) {
          setAccounts(response.data);
        }
      }).catch((err) => {
        console.log(err.message);
      }).finally(() => {
      });

  }, []);

  // no need for this, as its a function that can be called directly when the user selects the btn
  // useEffect(()=>{
  //   getCurriculumForLesson(lessonDuplicationId);
  // },[lessonDuplicationId])

  useEffect(() => {
    if (selectedCurriculums.length > 0) {
      setSelectedCurriculumsSend([]);
      let new_set = [];
      selectedCurriculums.forEach(element => {
        let data = curriculum[curriculum.findIndex(x => x.id == element)];
        new_set.push(data.id + ":" + data.name)
      });
      setSelectedCurriculumsSend(new_set);
    } else {
      setSelectedCurriculumsSend([]);
    }
    setFormData({ ...values, selectOption: selectedCurriculums })
  }, [selectedCurriculums]);

  useEffect(() => {
    const timeOutId = setTimeout(() => onSearchByLesson(), 500)
    return () => clearTimeout(timeOutId)
  }, [searchText])

  function loadLeasson(page = currentPage, pageSize = defaultPageSize, search = searchText) {
    setLoading(true);
    LessonService.getLesson(page, pageSize, search)
      .then((response) => {
        if (response?.data) {
          setLesson(response.data.items);
          setTotal(response.data.totalItems);
          setCurrentPage(response.data.page);
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getCurriculumForLesson(id) {
    setLoading(true);
    LessonService.getCurriculumById(id)
      .then((response) => {
        if (response?.data) {
          setCurriculum(response.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onSearchByLesson() {
    setCurrentPage(1);
    loadLeasson(1, defaultPageSize, searchText)
  }

  const mapTableData = useMemo(() => {
    return lessons.map((lesson) => ({
      ...lesson,
      curriculumName: accounts.find(({ id }) => id === lesson.curriculamId)
        ?.name,
    }));
  }, [lessons, accounts]);

  useEffect(() => {
    setTableData(mapTableData);
  }, [mapTableData]);


  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
    setSelectedCurriculums([]);
  };

  var obj = {
    LessonNameField: "",
    selectOption: ""
  }

  const { handleChange, handleSubmit, setFormData, values, errors, reset } = useCustFormValidator(onSave, validate, obj);

  const onClose = () => {
    setSelectedCurriculums([]);
    setOpen(false);
    setFormData({
      LessonNameField: "",
      selectOption: ""
    });
    reset();

  };

  async function onSave() {
    setLoading(true);

    if (lessonDuplicationId !== undefined) {
      const token = localStorage.getItem("access_token");
      const config = {
        headers: { "Authorization": `Bearer ${token}` },
      };
      let dataset = {
        curriculumList: selectedCurriculumsSend,
        lessonId: lessonDuplicationId
      };
      try {
        await axios.post(
          `${Config.APIURL}admin/lesson/duplicate-lesson-mulitiple-curriculam`,
          dataset,
          config
        ).then((response) => {
          if (response.data.status === 200) {
            setLoading(false);
            loadLeasson();
            notification.open({
              placement: 'top',
              duration: "2",
              description: <li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{response.data.message}</span></li>,
            });
          } else {
            setLoading(false);
            loadLeasson();
            notification.open({
              placement: 'top',
              duration: "2",
              description: <li><CloseCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{response.data.message}</span></li>,
            });
          }
          onClose();
        });
      } catch (err) {
        setLoading(false);
        const data = JSON.parse(err.request.response)
        notification.open({
          placement: 'top',
          duration: "2",
          description: <li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{data.message}</span></li>,
        });
        onClose();
      }
    }
  }

  const changeHandler2 = (e) => {
    setFormData({ ...values, selectOption: e })
  }

  const handleChangeCurricumum = (newTargetKeys) => {
    // setAssignStudentsToClass(true)
    setSelectedCurriculums(newTargetKeys);
  };
  return (
    <>
      <div>
        <Spin tip="Loading..." spinning={loading} size="large">
          <div className="my-5">
            <Row>
              <Col md="4"></Col>
              <Col md="4">
                <Input
                  size="large"
                  placeholder="Search"
                  prefix={<SearchOutlined className="ml-3 mr-3" />}
                  onChange={event => setSearchtext(event.target.value)}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col md="12">
              <Table
                dataSource={tableData}
                // pagination={{
                //   position: ["bottomCenter"],
                //   defaultPageSize: 10,
                //   showSizeChanger: true,
                //   pageSizeOptions: ["10", "20", "30"],
                // }}
                pagination={{
                  current: currentPage,
                  position: ["bottomCenter"],
                  total: total, // Total number of items in the API response (you may need to fetch this dynamically)
                  pageSize: defaultPageSize, // Number of items to display per page
                  onChange: (page, pageSize) => loadLeasson(page, pageSize), // Callback function to handle page changes
                  showSizeChanger: false
                }}
                columns={columns}
                className="thead-dark admin-table"
              />
            </Col>
          </Row>
          <Drawer
            title="Lesson Duplication"
            placement="right"
            className="medium"
            onClose={onClose}
            destroyOnClose={true}
            open={open}>
            {/* <div className="form-outline mb-3 override-FormFeedback">
              <label className="form-label" htmlFor="form3Example4">
                <span className="required-field">*</span>Lesson Title
              </label>
              <Input
                type="text"
                id="form3Example4"
                className="form-control"
                autocomplete="off"
                placeholder=""
                name="LessonNameField"
                value={values.LessonNameField}
                onChange={handleChange}
                invalid={!!errors?.LessonNameField}
              />
              <FormFeedback>{errors?.LessonNameField}</FormFeedback>
            </div> */}
            <div className="form-outline mb-3 override-FormFeedback">
              <label className="form-label" htmlFor="form3Example4">
                <span className="required-field">*</span>Select Curriculums<br />
              </label>
              {/* <Select
                showSearch
                placeholder="Select a Curriculum"
                optionFilterProp="children"
                size="large"
                className="search-drop"
                name="selectOptionField"
                onChange={changeHandler2}
              // invalid={!!errors?.selectOptionField}
              >
                {
                  accounts.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                }
              </Select> */}

              <Transfer
                className="mt-3 duplicate"
                dataSource={curriculum}
                showSearch
                titles={["Unassigned", "Assigned"]}
                //filterOption={filterOption}
                targetKeys={selectedCurriculums}
                onChange={handleChangeCurricumum}
                //onSearch={handleSearch}
                render={(item) => item.name}
                rowKey={record => record.id}
                locale={{
                  itemUnit: "Curriculums",
                  itemsUnit: "Curriculums",
                  notFoundContent: "The list is empty",
                  searchPlaceholder: "Search here",
                }}
              />
              <FormFeedback className="override-FormFeedback">{errors?.selectOption}</FormFeedback>
            </div>
            <Row>
              <Col lg='12' className="center mt-4">
                <Button color="warning" onClick={onClose} className="mr-15">Cancel</Button>
                <Button color="success" onClick={handleSubmit}>Save</Button>
              </Col>
            </Row>
          </Drawer>
        </Spin>
      </div>
    </>
  );
}

export default ViewClass;
