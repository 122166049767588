import React, { useEffect, useState } from "react";


import { Chart, Line } from "react-chartjs-2";

// const data = {
//   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
//   datasets: [
//     {
//       label: "First dataset",
//       data: [33, 53, 85, 41, 44, 65],
//       fill: true,
//       backgroundColor: "rgba(75,192,192,0.2)",
//       borderColor: "rgba(75,192,192,1)"
//     },
//     {
//       label: "Second dataset",
//       data: [33, 25, 17, 51, 54, 76],
//       fill: false,
//       borderColor: "#742774"
//     }
//   ]
// };



export default function CurveChart(props) {
  const [data, setData]=useState({});
  const options = {
    plugins: {
        legend: {
            display: false,
        },
    },
    maintainAspectRatio: false,
        responsive:true,
    // scales: {
    //   y: {
    //     min: 0,
    //     ticks: {
    //         callback: function (value, index, ticks) {
    //             if (props?.data?.yLabel == undefined || props?.data?.yLabel == null) return value;

    //             if (props?.data?.yLabel !== undefined) {

    //                 return + value + " " + props?.data?.yLabel;

    //             } else
    //                return value;              
    //         }
    //     }
    // },
    //     // x: {
    //     //     // autoSkip: false,
    //     //     ticks: {
    //     //       // For a category axis, the val is the index so the lookup via getLabelForValue is needed
    //     //       callback: function(index, label) {
    //     //         // Hide every 2nd tick label
    //     //         // console.log(label)
    //     //         // return `\$${this.getLabelForValue(label)}`

    //     //         const labels = props?.data?.labels[maxIndex]
    //     //         // console.log(labels[index])
    //     //         if(labels[index]>= 0 && labels[index] <10){
    //     //           if(labels[parseInt(index)-1] === undefined ){
    //     //             return "5 hrs";
    //     //           }else{
    //     //             if(labels[parseInt(index)+1]=== undefined){
    //     //               return "10 hrs";
    //     //             }
    //     //             // else{
    //     //             //   return "";
    //     //             // }
    //     //           }
    //     //         }else if(labels[index] >=10 && labels[index] < 15){
    //     //           if(labels[parseInt(index)-1] < 10){
    //     //             return "10 hrs";
    //     //           }else{
    //     //             if( labels[parseInt(index)+1]=== undefined){
    //     //               return "15 hrs";
    //     //             }
    //     //             // else{
    //     //             //   return "";
    //     //             // }
    //     //           }
    //     //         }else{
    //     //           // console.log(props.data.labels[parseInt(index)+1]=== undefined)
    //     //           if(labels[parseInt(index)-1]< 15){
    //     //             return "15 hrs";
    //     //           }
    //     //           // else{
    //     //           //   return "";
    //     //           // }
    //     //         }
    //     //         // return val
    //     //       }
    //     //     }
    //     // }
  
    // }
  
  };

  //triming the undefined values
  for (let i = props.data.labels.length - 1; i >= 0; i--) {
    if (props.data.labels[i] === undefined) {
     props.data.datasets.splice(i, 1);
    }
  }
    const value = {
    datasets:props.data.datasets.slice().reverse().filter((ele, ind) => ind === props.data.datasets.slice().reverse().findIndex(elem => elem.label === ele.label)).reverse().map((item, i) => {
      const arr = props.data.labels.filter(item => item !== undefined);

      const formattedData = arr[i];
      const newData = [];
        formattedData?.map((x,index)=>{
            newData.push({y:parseFloat(item.yData[index]), x:parseFloat(x)})
        })
      return {
            type: 'scatter',
            // label:item.label  === "undefined" || item.label === null ? "" : item.label,
            lineTension: 0.5,            
            pointRadius: 1,
            borderColor: item.borderColor || 'black',
            data: newData,
            borderWidth: 1.5,
            showLine: true,

        }
    })
  };

  // console.log(value)

  return (
    <div className="chart">
      <Chart type='bar'  data={value} options={options} responsive="true" height={200}/>
      </div>
  );
}