import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input, Radio, Tooltip } from 'antd';
import { Row, Col, Badge } from "reactstrap";

function SubExplorerImageCarosel3(props) {
  const [answers, setAnswers] = useState([]);
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    if (props.initialBarData && props.initialBarData.length > 0 && trigger === 0) {
      setAnswers(props.initialBarData);
      setTrigger(trigger + 1);
    }
  }, [props]);

  function handleAnswerChange(index, value, fieldName) {
    const newAnswers = [...answers];
    newAnswers[index] = {
      ...newAnswers[index],
      [fieldName]: value,
    };
    setAnswers(newAnswers);
  }

  useEffect(() => {
    props.getValuesFromBarAnswer(answers);
  }, [answers]);

  function handleNumInputsChange(e, index, fieldName) {
    const value = e.target.value;
    const num = parseInt(value, 10);

    const newAnswers = [...answers];
    if (newAnswers[index] && newAnswers[index][fieldName]) {
      delete newAnswers[index];
    }
    newAnswers[index] = {
      ...newAnswers[index],
      [fieldName]: num,
    };
    setAnswers(newAnswers);
  }


  if (props?.length !== undefined) {
    return props?.length.map((url, index) => (
      <React.Fragment key={index}>
        <Row>
          <Col md="6">
            <Form.Item label={`Number of Inputs ( Image ${index + 1} )`}>
             {/* for now don't remove the log as without the log the answer[index] doesn't gets updated*/}
             {console.log(answers[index]?.answer1)}
              <Input
                type="number"
                placeholder={`0`}
                value={answers[index]?.answer1}
                onChange={(e) => { handleNumInputsChange(e, index, 'answer1') }}
                min={0}
              />
              {!!answers[index]?.answer1 && answers[index]?.answer1 !== 0 && (
                <>
                  <Radio.Group
                  value={answers[index]?.answer3}
                  onChange={(e) => handleAnswerChange(index, e.target.value, `answer3`)}
                >
                  <Row className='hei15'>
                  <Col md="65">
                    <Form.Item
                              name={``}
                              label={`Correct Answer`}
                              // className='flexr'
                            >
                    </Form.Item>
                  </Col>
                  <Col md="7">
                  
                    <Form.Item
                              name={``}
                              label={`Label`}
                              // className='flexr'
                            >
                    </Form.Item>
                  </Col>
                  </Row>
                  {Array.from({ length: answers[index]?.answer1 ? answers[index]?.answer1 : 0 }).map((_, index2) => {
                    const labelValue = answers[index]?.[`LabelValue_${index2 + 1}`] || `Label ${index2 + 1}`;
                    const previousLabelFilled = !!answers[index]?.[`LabelValue_${index2}`];

                    return (
                      <div >
                    
                      <Row key={index2} >
                      <Col md="5" className='mtop43'>
                      <center>
                          <Tooltip title="Correct Answer">
                          
                            <Radio value={labelValue} checked={answers[index]?.answer2 === labelValue}>
                              &nbsp;
                            </Radio>
                          </Tooltip>
                          </center>
                        </Col>
                        <Col md="7" className=''>
                        
                        <Form
                          layout="">
                          <Form.Item
                            name={`answer1-${index}${index2}`}
                            label={``}
                            //className='flexr'
                          >
                            <Input
                              type="text"
                              placeholder={labelValue}
                              value={labelValue}
                              onChange={(e) => handleAnswerChange(index, e.target.value, `LabelValue_${index2 + 1}`)}
                              disabled={index2 !== 0 &&  !previousLabelFilled}
                              required
                            />
                          </Form.Item>
                          </Form>
                        </Col>
                        
                      </Row>
                      </div>
                    );
                  })}
                </Radio.Group>
                </>
              )}
              
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label={`X Value`}
            >
              <Input
                type="text"
                placeholder={answers[index]?.answer2 ? answers[index]?.answer2 : `X Value`}
                value={answers[index]?.answer2}
                onChange={(e) => handleAnswerChange(index, e.target.value, `answer2`)}
                required
              />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    ));
  }
  return null;
}

export default SubExplorerImageCarosel3;
