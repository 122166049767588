import React, { useEffect, useState } from "react";

import { Row, Col, Badge } from "reactstrap";
import {
  Form,
  Input,
  Select,
  Drawer,
  Button,
  Table,
  Space,
  message,
  Checkbox,
  Tooltip,
} from "antd";
import "antd/dist/antd.css";

import { Editor } from "react-draft-wysiwyg";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { FormGroup, Label, FormFeedback } from "reactstrap";
import Hypothesis from "../components/Hypothesis";
import AddExpTypes from "../components/AddExpTypes";
import LessonService from "../../../../api/LessonService";
import outputModals from "../components/modals";
import draftToHtml from "draftjs-to-html";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import { Collapse, Divider } from 'antd';
import AddExpQuestions from "../components/AddExpQuestions";
import AddExplorerImages from "../components/AddExplorerImages";
import Config from "../../../../api/Config";
import {
  CalculationModule,
  GraphModule,
  TableValue,
} from "../../../Module/ChartType.Module";
import { PointerModule, DirectionModule } from "../../../Module/Pointer.Module";
import MultipleMessages from "../../../dashboard/components/MultipleMessages";

import AddExplorerImagesUpload from "../components/AddExplorerImagesUpload";

import { notification, List } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import DataTableValues from "../components/DataTableValues";
import ExpDrawerSub from "./ExpDrawerSub";


const { Panel } = Collapse;

const options2 = ["Length ", "Volume", "Mass"];
const options3 = ["British", "SI"];
const options4 = ["Small", "Large"];
// const options5 = ["Numerical", "Strings"];
const options5 = ["Numerical"];
const options6 = ["Linear", "Bar", "Pie"];
const option7 = ["Open Ended"];
const option8 = ["R"];
const option9 = ["Open Ended", "Single Choice"];

const dataSourceSubExp = [];

const dataSourceExpImgList = [];

function ExpDrawer(props) {
  const [editorState, setEditorstate] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedDirection, setSelectedDirection] = useState("");
  const [Absolute_Calculation, setAbsoluteCalculation] = useState(true);
  const [videoUrl, setVideoUrl] = useState("");
  const [imgUrl, setImgUrl] = useState([]);
  const filteredOptions2 = options2.filter((o) => !selectedItems.includes(o));
  const filteredOptions3 = options3.filter((o) => !selectedItems.includes(o));
  const filteredOptions4 = options4.filter((o) => !selectedItems.includes(o));

  const filteredOptions6 = options6.filter((o) => !selectedItems.includes(o));
  const filteredOptions7 = option7.filter((o) => !selectedItems.includes(o));
  const filteredOptions8 = option8.filter((o) => !selectedItems.includes(o));
  const filteredOptions9 = option9.filter((o) => !selectedItems.includes(o));
  const [experiment_id, setExperiment_id] = useState(0);
  const [items, setItems] = useState([]);
  const filteredOptions5 = items?.filter((o) => !selectedItems.includes(o));
  const [otherDropdownOptions, setOtherDropdownOptions] = useState([]);
  const [form] = Form.useForm();
  const [disabled, setDisbled] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [childrenDrawer2, setChildrenDrawer2] = useState(false);
  const [editorState2, setEditorstate2] = useState();
  const [typesofDropdown, setTypesofDropdown] = useState();
  const [initialData, setInitData] = useState([]);
  const [initialData2, setInitData2] = useState([]);
  const [totalImages, setTotalImages] = useState([]);
  const [dataTableValue, setDataTableValue] = useState([]);
  const [answerQuestion, setAnswerQuestion] = useState([]);
  const [updateDataTableValue, setUpdateDataTableValue] = useState([]);
  const [initialFourPointData, setInitialFourPointData] = useState([]);
  const [initialFourPointOptionValue, setInitialFourPointOptionValue] = useState()
  const [initialBarOptionsValues, setInitialBarOptionsValues] = useState()
  //const plainOptions = ['Fill', 'Max'];
  const [messages, setMessage] = useState([]);
  const [answerType, setAnswerType] = useState("Numerical");
  const [experiment, setEx] = useState({
    status: "",
    message: "",
  });
  const [instruction, setin] = useState({
    status: "",
    message: "",
  });
  const [question, setQu] = useState({
    status: "",
    message: "",
  });
  const [answers, setAn] = useState({
    status: "",
    message: "",
  });

  const [imageData, setImageData] = useState({
    folder_name: "",
    images: [],
    index: 0,
  });

  const [imageData2, setImageData2] = useState([]);
  const [videoData, setVideoData2] = useState([]);
  const [plainOptions, setPlainOptions] = useState([]);

  const [hiddenStatus, setHiddenStatus] = useState({
    "1": false,
    "2": false,
    "3": false,
    "4": false,
    "5": true,
    "6": true,
    "7": false,
    "8": true,
    "9": true,
    "10": false,
    "11": false,
    "12": true,
    "13": true,
  })

  const hiddenFunction = (value) => {
    switch (value) {
      case "Line":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": false,
          "7": false,
          "8": false,
          "9": true,
          "10": false,
          "11": false,
          "12": false,
          "13": true,
        });
        break;
      case "Bar":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": true,
          "6": true,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": false,
        });
        break;
      case "curve":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": false,
          "7": false,
          "8": true,
          "9": false,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "splitLine":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": false,
          "7": false,
          "8": false,
          "9": true,
          "10": false,
          "11": false,
          "12": false,
          "13": true,
        });
        break;
      case "Line Force Defined":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": false,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": false,
          "13": true,
        });
        break;
      case "Simple Line":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": false,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": false,
          "13": true,
        });
        break;
      case "Line Radial Defined":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": true,
          "7": false,
          "8": true,
          "9": false,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "Line Volume Defined":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": false,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "Line Temperature Defined":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": false,
          "7": false,
          "8": false,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "Bar YesNo":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": true,
          "6": true,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "Line Type2":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": false,
          "7": false,
          "8": false,
          "9": true,
          "10": false,
          "11": false,
          "12": false,
          "13": true,
        });
        break;
      case "Line Type3":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": true,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": false,
          "13": true,
        });
        break;
      case "normal":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": true,
          "6": false,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": false,
          "13": true,
        });
        break;
      case "Line Type4":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": true,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "Line Type5":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": true,
          "6": true,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "curve2":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": false,
          "7": false,
          "8": false,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "Bar Click2Count":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": true,
          "6": true,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "Line Type6":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": true,
          "6": true,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": false,
          "13": true,
        });
        break;
      case "Line Type7":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": true,
          "6": true,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "Line Type8":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": false,
          "7": false,
          "8": false,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "Line Type9":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": true,
          "6": true,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "Line Type10":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": true,
          "6": true,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "Line Type11":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": true,
          "6": true,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;
      case "Line Type12":
        setHiddenStatus({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": true,
          "6": true,
          "7": false,
          "8": true,
          "9": true,
          "10": false,
          "11": false,
          "12": true,
          "13": true,
        });
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    hiddenFunction(selectedItems);
  }, [selectedItems])

  useEffect(() => {
    let dynamicKey;

    for (let key in props?.editData?.chart_type) {
      if (props?.editData?.chart_type[key] && props?.editData?.chart_type[key]["graphType"]) {
        dynamicKey = key;
        break;
      }
    }

    console.log(dynamicKey)
    hiddenFunction(props?.editData?.chart_type[dynamicKey]?.graphType);
  }, [props])
  // console.log(props)

  useEffect(() => {
    setPlainOptions([]);
    LessonService.getAllExperimentsTypes().then((res) => {
      setItems(res.data.map((o) => o.name));
    });

    if (props.editData === undefined) {
      form.resetFields();
    }
  }, [props]);
  const columnsExpImgList = [
    {
      title: "Number of Images",
      dataIndex: "num_img",
      key: "num_img",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Badge
            className="bg-danger"
            size="sm"
            onClick={() => {
              setChildrenDrawer2(true);
              setImageData({
                folder_name: record.folderName,
                images: record.images,
                index: record.index,
              });
            }}
          >
            <small>Edit</small>
          </Badge>

        </Space>
      ),
    },
  ];
  const columnsSubExp = [
    {
      title: "Sub Experiment",
      dataIndex: "subExp",
      key: "subExp",
    },
    {
      title: "Options",
      dataIndex: "opt",
      key: "opt",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {record && props.view === false && (
            <>
              <Badge
                className="bg-danger"
                size="sm"
                onClick={() => {
                  setChildrenDrawer(true);
                  setTypesofDropdown([
                    {
                      dropdown: record.subExp,
                      option: record.opt,
                      id: record?.id,
                    },
                  ]);
                }}
              >
                <small>Edit</small>
              </Badge>
              <Badge
                className="bg-danger"
                size="sm"
                onClick={() => {
                  notification.open({
                    placement: 'top',
                    duration: '2',
                    description: [
                      <li>
                        <CheckCircleOutlined className='text-success ant-icon-up' />
                        <span className='ml-4'>Sub Experiment deleted successfully.</span>
                      </li>
                    ]
                  })
                  setOtherDropdownOptions(
                    otherDropdownOptions.filter(
                      (x) =>
                        x.dropdown !== record.subExp && x.option !== record.opt
                    )
                  );
                  
                  setDisbled(false)
                }}
              >
                <small>Delete</small>
              </Badge>
            </>
          )}
        </Space>
      ),
    },
  ];
  const uploadVideoUrl = (ulrData) => {
    setDisbled(false);
    setVideoUrl(ulrData)
  }

  useEffect(() => {
    form.validateFields(['videoUrl']);
  }, [videoUrl])

  const deleteVideoUrl = () => {
    setVideoUrl("");
    setDisbled(false);
    setVideoData2([]);
    if(experiment_id && experiment_id > 0){
      const payload = {
        "video":""
      }
      LessonService.updatVideo(experiment_id, payload)
    }
  }

  const [questionAnswer, setQuestionAnswer] = useState([])
  useEffect(() => {
    if (props.editData !== undefined) {

      setUpdateDataTableValue([]);
      setExperiment_id(props.editData.id);
      const QuestionAnswerPreviousPayload = {};
      const QuestionAnswerPreviousPayload2 = [];
      props.editData?.images?.split(",").map((_, index) => {
        QuestionAnswerPreviousPayload[`answer1-${index}`] = props.editData?.exploreStepQuestionAnswers[index]?.answer1;
        QuestionAnswerPreviousPayload[`answer2-${index}`] = props.editData?.exploreStepQuestionAnswers[index]?.answer2;
        QuestionAnswerPreviousPayload2.push({
          "answer1": props.editData?.exploreStepQuestionAnswers[index]?.answer1,
          "answer2": props.editData?.exploreStepQuestionAnswers[index]?.answer2
        })
      })
      setQuestionAnswer(QuestionAnswerPreviousPayload2)

      let value = props.editData?.description?.id;
      setAbsoluteCalculation(props.editData?.chart_type?.AbsoluteCalculation ?? true)
      form.setFieldsValue({
        ...QuestionAnswerPreviousPayload,
        name: props.editData?.name[0]?.dropDownName,
        description: props.editData?.description?.visualName[0],
        videoUrl: props.editData?.videoUrl,
        chart_type: props.editData?.chart_type[value]?.graphType,
        cal_method: props.editData?.chart_type[value]?.calculationMethod,
        chartinterval: props.editData?.chart_interval,
        InitialValue: props.editData?.x?.InitialValue,
        canvasscale: parseFloat(props.editData?.chart_type?.canvasscale),
        directionValue: props.editData?.chart_type?.directionValue,
        colour: props.editData?.name[0]?.colors,
        colorPointer: props.editData?.chart_type[value]?.PointerType?.color,
        instruction: props.editData?.exploreStepInstruction[0]
          ? props.editData.exploreStepInstruction[0]?.instruction
          : "",
        instruction_heading: props.editData?.exploreStepInstruction[0]
          ? props.editData.exploreStepInstruction[0]?.instruction_heading
          : "",
        xaxis: props.editData?.x?.Value,
        yaxis: props.editData?.y?.Value,
        yaxisunit: props.editData?.y?.SI,
        xaxisunit: props.editData?.x?.SI,
        x: props.editData?.chart_type?.value1 ?? [],
        y: props.editData?.chart_type?.value1 ?? [],
        type: answerType,
        point_type: props.editData?.chart_type[value]?.PointerType?.Type,
        images: props.editData?.images,
        dataTableValue: props.editData?.chart_type[value]?.dataTableValue,
        // "answer1-0":
        //   parseInt(props.editData.exploreStepQuestion[0]?.type) - 1 === 0
        //     ? props.editData?.exploreStepQuestionAnswers[0]?.answer1
        //     : "",
        // "answer2-0":
        //   parseInt(props.editData.exploreStepQuestion[0]?.type) - 1 === 0
        //     ? props.editData?.exploreStepQuestionAnswers[0]?.answer2
        //     : "",
        // answer:
        //   parseInt(props.editData.exploreStepQuestion[0]?.type) - 1 === 0
        //     ? ""
        //     : props.editData?.exploreStepQuestionAnswers[0]?.answer1,
      });
      setUpdateDataTableValue(props.editData?.chart_type?.dataTableValue);
      if (props?.editData?.chart_type?.value1?.value) {
        setInitData2({ initialDataX: props?.editData?.chart_type?.value1?.value?.x, initialDataY: props?.editData?.chart_type?.value1?.value?.y, initialDataScaleValue: props?.editData?.chart_type?.value1?.value?.initialDataScaleValue } ?? []);
      } else {
        setInitData2([])
      }

      if (props?.editData?.chart_type?.value2) {
        setInitialFourPointData(props?.editData?.chart_type?.value2);
      } else {
        setInitialFourPointData([])
      }

      if (props?.editData?.chart_type?.value2option !== null || props?.editData?.chart_type?.value2option !== undefined) {
        setInitialFourPointOptionValue(props?.editData?.chart_type?.value2option);
      } else {
        setInitialFourPointOptionValue()
      }

      if (props?.editData?.chart_type?.value3option !== null || props?.editData?.chart_type?.value3option !== undefined) {
        setInitialBarOptionsValues(props?.editData?.chart_type?.value3option);
      } else {
        setInitialBarOptionsValues()
      }
      setImageData({
        folder_name: undefined,
        images: props.editData?.images?.split(",").map((x) => {
          return x;
        }),
      });
      setImageData2(props.editData?.images?.split(",").map((x) => {
        const split = x.split("/assets/")[1];
        if (split !== undefined) {
          const part = split.split("_SSA_");
          const data = {
            uid: part[0],
            name: part[1],
            status: 'done',
            url: x,
          }
          return data;
        }
      }))

      const videoUrlSplit = props.editData?.videoUrl.split("/assets/")[1];
      if (videoUrlSplit !== undefined) {
        const videoPart = videoUrlSplit.split("_SSA_");
        const videoPL = [{
          uid: videoPart[0],
          name: videoPart[1],
          status: 'done',
          url: props.editData?.videoUrl,
        }]

        setVideoData2(videoPL);
      }
      setVideoUrl(props.editData?.videoUrl)

      // setVideoUrl( props.editData?.images?.split(",").map((x) => {
      //   const split = x.split("/assets/")[1];
      //   const part = split.split("_SSA_");
      //   const data = {
      //     uid: part[0],
      //     name: part[1],
      //     status: 'done',
      //     url: x,
      //   }
      //   return data;
      // }))
      if(props.editData?.images && props.editData?.images.length === 0){
        setTotalImages([]);
        setImgUrl([])
      }else{
        setTotalImages([
          {
            folder_name: undefined,
            url: props.editData?.images?.split(",").map((x) => {
              return x;
            }),
            index: 0,
          },
        ]);
        setImgUrl(props.editData?.images?.split(",").map((x) => {
          return x;
        }))
      }
      
      setOtherDropdownOptions(
        props.editData?.name
          ?.filter((o, i) => i !== 0)
          ?.map((o, i) => {
            return {
              dropdown: o.dropDownName,
              option: o.option?.length === 1 ? o.option[0] : o.option,
              id: i,
            };
          })
      );
      setEditorstate(props.editData.exploreStepQuestion[0]?.description ?? "");
      setDisbled(true)
    } else {
      form.resetFields();
      setEditorstate();
      setImageData([]);
      setImageData2([]);
      setOtherDropdownOptions([]);
      setInitData([]);
      setInitData2([]);
      setInitialFourPointOptionValue();
      setInitialBarOptionsValues();
      setInitialFourPointData([])
      setPlainOptions([]);
      setTotalImages([]);
      setUpdateDataTableValue([]);
      setExperiment_id(0)
      setDisbled(true)
    }
  }, [props]);

  useEffect(() => {
    setAnswerType("Numerical")
  }, [selectedItems])

  const onFinish = (values) => {
    let arr = [];
    if (imageData.images?.length >= 1) {
      let value = props.explore_id;
      let chart_type = {
        [value]: {
          graphType: values.chart_type,
          calculationMethod: values.chart_type,
          // calculationMethod: values.cal_method,
          PointerType: {
            color: values.colorPointer ?? "#000000",
            width: "1",
            Type: values.point_type,
          },
        },
        dataTableValue: dataTableValue ?? [],
        value1: {
          exp: otherDropdownOptions.length > 0 ? `${values.description}-${otherDropdownOptions[0].option}` : values.description,
          value: {
            x: parseInt(initialData2.initialDataX),
            y: parseInt(initialData2.initialDataY),
            initialDataScaleValue: parseInt(initialData2.initialDataScaleValue),
          }
        },
        value2: initialFourPointData,
        value2option: initialFourPointOptionValue,
        value3option: initialBarOptionsValues,
        "canvasscale": values.canvasscale !== "" ? parseFloat(values.canvasscale) : 1.0,
        "directionValue": values.directionValue,
        "AbsoluteCalculation": Absolute_Calculation,
      };
      let namePayload = null;
      let visualName = [values.description];

      if (otherDropdownOptions?.length > 0) {
        let resources =
          otherDropdownOptions?.map((o) => {
            return {
              dropDownName: o.dropdown,
              option: [o.option],
            };
          }) ?? [];

        let namePayload1 = [
          {
            dropDownName: values.name,
            option: [values.description],
            colors: values.colour ?? "#000000",
            value: values.description,
          },
        ];
        if (resources?.length > 0) {
          let namePayload2 = [
            {
              dropDownName: values.name,
              option: [values.description],
              colors: values.colour ?? "#000000",
              value: values.description + "-" + resources[0].option,
            },
          ];
          namePayload = [...namePayload2, ...resources];
          visualName = [
            values.description,
            resources
              ?.map((x) => {
                return x.option;
              })
              .toString(),
          ];
        } else {
          namePayload = namePayload1;
        }
      } else {
        namePayload = [
          {
            dropDownName: values.name,
            option: [values.description],
            colors: values.colour,
            value: values.description,
          },
        ];
      }



      // let experimentObj = {
      //   id: experiment_id ?? 0,
      //   name: namePayload,
      //   description: {
      //     folderName: imageData.folder_name,
      //     visualName: visualName,
      //     id: props.explore_id,
      //   },
      //   images: imgUrl.join(","),
      //   videoUrl: videoUrl,
      //   explore_step_id: props.explore_id,
      //   chart_type: chart_type,
      //   chart_interval: values.chartinterval ?? 0.0,
      //   x: {
      //     Value: values.xaxis ?? "",
      //     SI: values.xaxisunit ?? "",
      //   },
      //   y: {
      //     Value: values.yaxis ?? "",
      //     SI: values.yaxisunit ?? "",
      //   },
      // };

      let obj = {
        id: experiment_id ?? 0,
        name: namePayload,
        description: {
          folderName: values.description,
          visualName: visualName,
          id: props.explore_id,
        },
        images: imgUrl.join(","),
        videoUrl: videoUrl,
        explore_step_id: props.explore_id,
        chart_type: chart_type,
        chart_interval: values.chartinterval ?? 0.0,
        x: {
          Value: values.xaxis ?? "",
          SI: values.xaxisunit ?? "",
          InitialValue: values.InitialValue,
        },
        y: {
          Value: values.yaxis ?? "",
          SI: values.yaxisunit ?? "",
        },
      };

      LessonService.addExploreExperiment(obj).then((res) => {
        let experiment = {
          type: res.status === 200 ? 'success' : 'error',
          text: res.message
        }

        arr.push(experiment)

        if (res.status == 200) {
          setDisbled(true);
          let id = res.data.explore_step_id;
          let experiment_id_value = res.data.id;
          setExperiment_id(id);
          if (
            id &&
            id !== 0 
          ) {
            let instructionObj = {
              experiment_id: experiment_id_value,
              exploreStepId: props.explore_id,
              instruction: values.instruction ?? "",
              instruction_heading: values.instruction_heading ?? "",
            };
            let obj1 = {
              id: experiment_id_value,
              exploreStepInstruction: [instructionObj],
            };

            LessonService.addExploreInstructions(obj1).then((res) => {
              let instruction = {
                type: res.status === 200 ? 'success' : 'error',
                text: res.message
              }
              arr.push(instruction)

              // if (editorState === undefined && values.type === undefined || editorState !== undefined && editorState?.length === 0 && values.type === undefined) {
              //   notification.open({
              //     placement: "top",
              //     duration: "2",
              //     description: [
              //       arr.map((item) => {
              //         if (item.type === "success") {
              //           return (
              //             <li>
              //               <CheckCircleOutlined className="text-success ant-icon-up" />
              //               <span className="ml-4">{item.text}</span>
              //             </li>
              //           );
              //         }
              //         if (item.type === "error") {
              //           return (
              //             <li>
              //               <CloseCircleOutlined className="text-danger ant-icon-up" />
              //               <span className="ml-4">{item.text}</span>
              //             </li>
              //           );
              //         }
              //         if (item.type === "warning") {
              //           return (
              //             <li>
              //               <InfoCircleOutlined className="text-warning ant-icon-up" />
              //               <span className="ml-4">{item.text}</span>
              //             </li>
              //           );
              //         }
              //       }),
              //     ],

              //     onClick: () => {
              //     },
              //   });
              //   props.onClose();
              // }
              if (res.status == 200) {
                // message.success(res.message)
                let obj = {
                  description: editorState,
                  type: options5?.findIndex((x) => x === values.type) + 1,
                  explore_step_exp_id: experiment_id_value,
                  exploreStepId: props.explore_id,
                };

                LessonService.addExploreQuestions({
                  id: experiment_id_value,
                  exploreStepQuestion: [obj],
                }).then((res) => {
                  // setQu({
                  //     status: res.status,
                  //     message: res.message
                  // })
                  let question = {
                    type: res.status === 200 ? 'success' : 'error',
                    text: res.message
                  }
                  arr.push(question)

                  if (values?.answer?.length === 0 && values.answer1.length === 0 && values.answer2.length === 0) {
                    notification.open({
                      placement: "top",
                      duration: "2",
                      description: [
                        arr.map((item) => {
                          if (item.type === "success") {
                            return (
                              <li>
                                <CheckCircleOutlined className="text-success ant-icon-up" />
                                <span className="ml-4">{item.text}</span>
                              </li>
                            );
                          }
                          if (item.type === "error") {
                            return (
                              <li>
                                <CloseCircleOutlined className="text-danger ant-icon-up" />
                                <span className="ml-4">{item.text}</span>
                              </li>
                            );
                          }
                          if (item.type === "warning") {
                            return (
                              <li>
                                <InfoCircleOutlined className="text-warning ant-icon-up" />
                                <span className="ml-4">{item.text}</span>
                              </li>
                            );
                          }
                        }),
                      ],
                    });
                    props.onClose();
                  }
                  if (res?.status === 200) {
                    // message.success(res.message)
                    const payload = []
                    answerQuestion.map((x) => {
                      const data = {
                        answer1: x.answer1 ?? 0,
                        answer2: x.answer2 ?? 0,
                        answerNumber: 1,
                        question_id: res?.data?.exploreStepQuestion[0]?.id,
                        answer_type: values?.type === "Numerical" ? "R" : "N",
                        answer_data_type:
                          values?.type === "Numerical" ? "N" : "R",
                        exploreStepId: props.explore_id,
                        exploreStepExpId: experiment_id,
                      }
                      payload.push(data)
                    })

                    // let obj2 = {
                    //   answer1: values?.answer ? values.answer : values.answer1,
                    //   answer2: values?.answer2 ? values.answer2 : "",
                    //   answerNumber: 1,
                    //   question_id: res?.data?.exploreStepQuestion[0]?.id,
                    //   answer_type: values?.type === "Numerical" ? "R" : "N",
                    //   answer_data_type:
                    //     values?.type === "Numerical" ? "N" : "R",
                    //   exploreStepId: props.explore_id,
                    //   exploreStepExpId: experiment_id,
                    // };
                    localStorage.setItem(
                      "an",
                      JSON.stringify({
                        status: undefined,
                        message: undefined,
                      })
                    );
                    LessonService.addExploreQuestionAnswers({
                      explore_step_exp_id: experiment_id,
                      exploreStepQuestionAnswers: payload,
                    }).then((res) => {
                      let answer = {
                        type: res.status === 200 ? 'success' : 'error',
                        text: res.message
                      }
                      arr.push(answer)
                      notification.open({
                        placement: "top",
                        duration: "2",
                        description: [
                          arr.map((item) => {
                            if (item.type === "success") {
                              return (
                                <li>
                                  <CheckCircleOutlined className="text-success ant-icon-up" />
                                  <span className="ml-4">{item.text}</span>
                                </li>
                              );
                            }
                            if (item.type === "error") {
                              return (
                                <li>
                                  <CloseCircleOutlined className="text-danger ant-icon-up" />
                                  <span className="ml-4">{item.text}</span>
                                </li>
                              );
                            }
                            if (item.type === "warning") {
                              return (
                                <li>
                                  <InfoCircleOutlined className="text-warning ant-icon-up" />
                                  <span className="ml-4">{item.text}</span>
                                </li>
                              );
                            }
                          }),
                        ],

                        onClick: () => {
                        },
                      });
                      props.onClose();
                      if (res?.status === 200) {
                        // message.success(res.message)
                      } else {
                        //message.error(res.message)
                      }
                    });
                    props.onClose();
                  } else {
                    // message.error(res.message)
                  }
                });
              } else {
                // message.error(res.message)
              }
            });
          }
        } 
      });
    } else {
      notification.open({
        placement: 'top',
        duration: '2',
        description: [
          <li>
            <CloseCircleOutlined className="text-danger ant-icon-up" />
            <span className='ml-4'>Add at least one image for Experiment Image List.</span>
          </li>
        ]
      })
    }

  };


  const onChangeQuestion = (editorState) => {
    setEditorstate(editorState);
    setDisbled(false);
  };
  const onChange = (e, value) => {
    setDisbled(false);
  };
  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
    setTypesofDropdown();
  };
  const showChildrenDrawer2 = () => {
    setChildrenDrawer2(true);
    setImageData(undefined);
    setImageData2([]);
    setInitData([]);
    setInitData2([]);
    setInitialFourPointData([]);
    setInitialFourPointOptionValue();
    setInitialBarOptionsValues();
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };
  const onChildrenDrawerClose2 = () => {
    setChildrenDrawer2(false);
  };

  const getFunForQuestionAnswer = (value) => {
    setAnswerQuestion(value)
  }



  return (
    <>
      <div className="container">
        <Row>
          <Col xs="12">
          </Col>
        </Row>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          disabled={props.view}
          onChange={onChange}
          initialValues={{
            type: "Numerical"
          }}
        >
          <Row className="question-headder-style">
            <Col xs="12" className="">
              <b>Experiment Type</b>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Form.Item
                name="name"
                label="Experiment Type"
                rules={[
                  { required: true, message: "Experiment Type is required" },
                ]}
              >
                <Input
                  getPopupContainer={trigger => trigger.parentElement}
                  placeholder="Enter Experiment Type"
                  autoComplete="off"
                  value={selectedItems}
                  onChange={(e) => {
                    setDisbled(false);
                  }}
                />
                {/* <FormFeedback> </FormFeedback> */}
              </Form.Item>
            </Col>
            <Col xs="6">
              <Form.Item
                name="description"
                label="Experiment"
                rules={[{ required: true, message: "Experiment is required" }]}
              >
                <Input placeholder=" " autoComplete="off" />
                {/* <FormFeedback> </FormFeedback> */}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <Row className="question-headder-style">
                <Col xs="6" className="">
                  <b>Experiment Subtypes</b>
                </Col>
                <Col xs="6">
                  {props.view === false && (
                    <div className="text-right">
                      <Badge
                        color="dark"
                        className="p-2"
                        onClick={showChildrenDrawer}
                      >
                        Add New
                      </Badge>
                    </div>
                  )}
                </Col>
                <Drawer
                  width={320}
                  closable={true}
                  title="Experiment Subtypes"
                  onClose={onChildrenDrawerClose}
                  destroyOnClose={true}
                  visible={childrenDrawer}
                >
                  <AddExpTypes
                    onChildrenDrawerClose = {onChildrenDrawerClose}
                    view={props.view}
                    types={typesofDropdown}
                    onFinish={(data) => {
                      data?.types?.map((data, index) => {
                        if (
                          otherDropdownOptions?.findIndex(
                            (x) =>
                              x.dropdown === data.dropdown ||
                              x.option === data.option
                          ) !== -1
                        ) {
                          otherDropdownOptions[
                            otherDropdownOptions?.findIndex(
                              (x) =>
                                x.dropdown === data.dropdown ||
                                x.option === data.option
                            )
                          ].dropdown = data.dropdown;
                          otherDropdownOptions[
                            otherDropdownOptions?.findIndex(
                              (x) =>
                                x.dropdown === data.dropdown ||
                                x.option === data.option
                            )
                          ].option = data.option;
                          otherDropdownOptions[
                            otherDropdownOptions?.findIndex(
                              (x) =>
                                x.dropdown === data.dropdown ||
                                x.option === data.option
                            )
                          ].id = data.id;

                          // setTotalImages(totalImages)
                        } else {
                          setOtherDropdownOptions((prev) => [...prev, data]);
                        }
                      });
                    }}
                  />
                </Drawer>
              </Row>
              <Row>
                <Col xs="12">
                  <Table
                    dataSource={otherDropdownOptions?.map((x, i) => {
                      return {
                        id: i,
                        subExp: x.dropdown,
                        opt: x.option,
                      };
                    })}
                    columns={columnsSubExp}
                    pagination={false}
                    bordered
                    className="thead-dark space-py-20"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Row className="question-headder-style">
                <Col xs="6" className="">
                  <b>Experiment Image List</b>
                </Col>
                <Col xs="6">
                  {props.view === false && totalImages?.length < 1 && (
                    <div className="text-right">
                      <Badge
                        color="dark"
                        className="p-2"
                        onClick={showChildrenDrawer2}
                      >
                        Add New
                      </Badge>
                    </div>
                  )}
                </Col>
                <Drawer 
                  title={`Add Experiment Images`}
                  closable={true}
                  onClose={onChildrenDrawerClose2}
                  visible={childrenDrawer2}
                >
                  <AddExplorerImages
                    type={"image"}
                    disabled={props.view}
                    totalImages={totalImages}
                    initialData={initialData}
                    initialData2={initialData2}
                    initialFourPointData={initialFourPointData}
                    initialBarOptionsValues={initialBarOptionsValues}
                    initialFourPointOptionValue={initialFourPointOptionValue}
                    imageData={imageData}
                    imageData2={imageData2}
                    experiment_id = {experiment_id}
                    onSubmitImageData={(data) => {
                      setDisbled(false)
                      
                      setImageData({
                        folder_name: data.folder_name,
                        images: data.url,
                        index: data.index
                      })
                      setTotalImages([data]);
                      setImgUrl(data.url)

                      
                    }}
                    initialValuesUpdate={(data) => {
                      setInitData(data);
                    }}
                    initialValuesUpdateMain={(data) => {
                      setInitData2(data);
                    }}

                    initialFourPoint={(data) => {
                      setInitialFourPointData(data)
                    }}

                    initialFourPointOption={(data) => {
                      setInitialFourPointOptionValue(data)
                    }}

                    initialBarOption={(data) => {
                      setInitialBarOptionsValues(data)
                    }}


                  />
                </Drawer>
              </Row>
            </Col>
            <Col xs="12">
              <Table
                dataSource={totalImages.map((x, i) => {
                  return {
                    index: i,
                    num_img: x.url?.length,
                    images: x.url,
                    folderName: x.folder_name
                  };
                })}
                columns={columnsExpImgList}
                pagination={false}
                bordered
                className="thead-dark space-py-20"
              />
            </Col>
          </Row>

          <Row className="question-headder-style">
            <Col xs="12" className="">
              <b>Experiment</b>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Form.Item
                name="videoUrl"
                label="Add Video"
                rules={[{
                  required: true,
                  message: 'Video URL is required',
                  validator: (_, value) => {
                    if (videoUrl !== "") {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('Video URL is required');
                    }
                  }
                }]}
              >
                  <div className="mt--18"> 
                <AddExplorerImagesUpload disable={props.view} type={"video"} uploadFileData={uploadVideoUrl} deletedFile={deleteVideoUrl} existingData={videoData} />
                </div>
              </Form.Item>
            </Col>
            <Col xs="6">
              <Form.Item
                name="chart_type"
                label="Graph Type"
                rules={[{ required: true, message: "Graph Type is required" }]}
              >
                <Select
                  showSearch
                  getPopupContainer={trigger => trigger.parentElement}
                  mode=""
                  placeholder="Add Graph type"
                  value={
                    selectedItems
                      ? selectedItems
                      : form.getFieldValue().chart_type
                  }
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => {
                    setSelectedItems(e);
                    setDisbled(false);
                  }}
                  style={{
                    width: "100%",
                    // color: "black",
                  }}
                >
                  <option key="-1" value="" >Select Graph Type</option>
                  {GraphModule.map((item, id) => (
                    <Select.Option key={id} value={item.value}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <label>Select Data Table Parameters</label>
            </Col>
            <Col md="12">
              <DataTableValues
                selectedItems={selectedItems}
                selectedItems2={form.getFieldValue().chart_type}
                disabled={props.view !== false}
                updateDataTableValue={updateDataTableValue}
                setDataTableValue={(data) => {
                  setDataTableValue(data);
                }}
              />
            </Col>{" "}
          </Row>

          <Row>
            <Col xs="6" hidden={hiddenStatus["1"]}>
              <Form.Item name="xaxis" label="X-Axis">
                <Input placeholder="" autoComplete="off" />
                {/* <FormFeedback> </FormFeedback> */}
              </Form.Item>
            </Col>
            <Col xs="6" hidden={hiddenStatus["2"]}>
              <Form.Item name="yaxis" label="Y-Axis">
                <Input placeholder="" autoComplete="off" />
                {/* <FormFeedback> </FormFeedback> */}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs="6" hidden={hiddenStatus["3"]}>
              <Form.Item name="xaxisunit" label="X-Axis Unit">
                <Input placeholder="" autoComplete="off" />
                {/* <FormFeedback> </FormFeedback> */}
              </Form.Item>
            </Col>
            <Col xs="6" hidden={hiddenStatus["4"]}>
              <Form.Item name="yaxisunit" label="Y-Axis Unit">
                <Input placeholder="" autoComplete="off" />
                {/* <FormFeedback> </FormFeedback> */}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs='3' hidden={hiddenStatus["7"]}>
              <Form.Item name="point_type" label="Point Type" >

                <Select
                  getPopupContainer={trigger => trigger.parentElement}
                  mode=""
                  placeholder="Add Point type"
                  value={selectedItems ? selectedItems : form.getFieldValue().point_type}
                  onChange={(e) => {
                    setSelectedItems(e)
                    setDisbled(false)
                  }}
                  style={{
                    width: '100%',
                  }}
                >
                  <option key="-1" value="" >Select Point Type</option>
                  {PointerModule.map((item, id) => (
                    <Select.Option key={id} value={item.value}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                {/* <FormFeedback> </FormFeedback> */}
              </Form.Item>
            </Col>
            <Col xs='3' hidden={hiddenStatus["11"]}>
              <Form.Item name="colorPointer" label="Point Colour"   >
                <input type="color" disabled={props.view !== false} />
              </Form.Item>
            </Col>
            <Col xs='3' hidden={hiddenStatus["10"]}>
              <Form.Item name="colour" label="Line Colour"   >
                <input type="color" disabled={props.view !== false} />
                {/* <FormFeedback> </FormFeedback> */}
              </Form.Item>
            </Col>
            <Col xs='3' hidden={hiddenStatus["12"]}>
              <Form.Item name="Absolute_Calculation" label="">
                <Tooltip
                  title={`Absolute values works only for; Line Regression Chart. Two Line Regression Chart and Standard Chart (fixed point calculation)`}
                >
                  <Checkbox
                    className="mt-25"
                    checked={Absolute_Calculation}
                    onChange={(e) => {
                      setAbsoluteCalculation(e.target.checked);
                      setDisbled(false);
                    }}
                  >
                    Absolute Calculation
                  </Checkbox>
                </Tooltip>
              </Form.Item>
            </Col>
            <Col xs='3' hidden={hiddenStatus["5"]}>
              <Form.Item name="chartinterval" label="Chart Intervel"  >
                <Input placeholder="" type='number' />
              </Form.Item>
              {/* <FormFeedback> </FormFeedback> */}
            </Col>
            <Col xs='3' hidden={hiddenStatus["6"]}>
              <Tooltip
                title={`width:540, height:400`}
              >
                <Form.Item name="canvasscale" label="Canvas Scale"  >
                  <Input placeholder="" type='number' />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col xs='3' hidden={hiddenStatus["8"]}>
              <Form.Item name="directionValue" label="Direction" >

                <Select
                  getPopupContainer={trigger => trigger.parentElement}
                  mode=""
                  placeholder="Select Direction"
                  value={selectedDirection ? selectedDirection : form.getFieldValue().directionValue}
                  onChange={(e) => {
                    setSelectedDirection(e)
                    setDisbled(false)
                    
                  }}
                  style={{
                    width: '100%',
                  }}
                >
                  <option key="-1" value="" >Select Direction</option>
                  {DirectionModule.map((item, id) => (
                    <Select.Option key={id} value={item.value}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                {/* <FormFeedback> </FormFeedback> */}
              </Form.Item>
            </Col>
            <Col xs='2' hidden={hiddenStatus["9"]}>
              <Form.Item name="InitialValue" label="Initial Value ( X )"   >
                <Input placeholder="" type='number' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
          </Row>
          <Row>
            <Col xs="6">
              <Form.Item name="instruction" label="Instruction">
                <Input placeholder="Type Instruction" autoComplete="off"/>
                {/* <FormFeedback> </FormFeedback> */}
              </Form.Item>
            </Col>
            <Col xs="6">
              <Form.Item name="instruction_heading" label="Instruction Header">
                <Input placeholder="Type Instruction Header" autoComplete="off" />
                {/* <FormFeedback> </FormFeedback> */}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <Form.Item
                name="question"
                label="Question"
                className="full-width"
              >
                <SunEditor
                  disable={props.view}
                  setContents={props.editData?.exploreStepQuestion[0]?.description}
                  showToolbar={true}
                  setDefaultStyle="height: 150px"
                  onChange={(e) => {
                    setDisbled(false)
                    
                    onChangeQuestion(e)
                  }}
                  setOptions={{
                    katex: "window.katex",
                    buttonList: [
                      [
                        "undo",
                        "redo",
                        //"font",
                        "fontSize",
                        "formatBlock",
                        "paragraphStyle",
                        "blockquote",
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "hiliteColor",
                        "subscript",
                        "superscript",
                        "math",
                        "removeFormat",
                        "horizontalRule",
                        // "lineHeight",
                        //"strike",
                        "list",
                        "outdent",
                        "indent",
                        "align",
                        "table",
                        "image",
                        "codeView",
                        // "preview",
                        // "print",
                        //"save",
                        //"template",
                      ],
                    ],
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className={hiddenStatus["13"] ? "hidden" : ""}>
            <Col xs="12">
              <Form.Item name="type" label="Answer Type">
                <Select
                  getPopupContainer={trigger => trigger.parentElement}
                  mode=""
                  placeholder="Click here to answer type"
                  value={selectedItems ? selectedItems : form.getFieldValue().type}
                  onChange={(e) => {
                    setSelectedItems(e);
                    setDisbled(false);
                  }}
                  style={{
                    width: "100%",
                  }}
                  notFoundContent={null}
                >
                  {/* {options5?.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))} */}
                </Select>

              </Form.Item>
            </Col>
          </Row>
          <Collapse>
            <Panel header="Answers" key="1" className={hiddenStatus["13"] ? "hidden" : ""}>
              <ExpDrawerSub length={totalImages} getValuesFromQuestionAnswer={getFunForQuestionAnswer} pastAnswers={questionAnswer} />
            </Panel>
          </Collapse>


          <Row>
            <Col xs="12">
              <br />
              <button
                disabled={props.view || disabled}
                class="btn btn-success btn-sm btn-block my-2"
                type="primary"
                htmlType="submit"
                block
              >
                Add Experiment
              </button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default ExpDrawer;
