import React, { useEffect, useState } from "react";

import { Bubble } from 'react-chartjs-2';

function findCircleIntersection(circles) {
  // Define a function to calculate the distance between two points
  function distance(x1, y1, x2, y2) {
    return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
  }

  // Define a function to find the intersection point of two circles
  function circleIntersection(x1, y1, r1, x2, y2, r2) {
    const d = distance(x1, y1, x2, y2);
    const a = (r1 ** 2 - r2 ** 2 + d ** 2) / (2 * d);
    
    const h = Math.sqrt(Math.abs(r1 ** 2 - a ** 2));
    const x3 = x1 + a * (x2 - x1) / d;
    const y3 = y1 + a * (y2 - y1) / d;
    const x4 = x3 + h * (y2 - y1) / d;
    const y4 = y3 - h * (x2 - x1) / d;
    return [x4, y4];
  }

  let x = 0;
  let y = 0;
  let count = 0;
  for (let i = 0; i < circles.length - 1; i++) {
    for (let j = i + 1; j < circles.length; j++) {
      // console.log(circles)
      const [xi, yi] = circleIntersection(circles[i].x, circles[i].y, circles[i].r, circles[j].x, circles[j].y, circles[j].r);
      // console.log(xi,yi)
      x += xi;
      y += yi;
      count++;
    }
  }
  x /= count;
  y /= count;
  return [x, y];
}



const options = {
  scales: {
    x: {
      type: 'linear',
      position: 'bottom',
      max: 450,
      min: 0,
      ticks: {
        stepSize: 50
      }
    },
    y: {
      type: 'linear',
      beginAtZero: true,
      display: true,
      stacked: true,
      max: 450,
      min: 0
    },
     //height: 500
    
  },
  
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {

          if(context.dataset.data[context.dataIndex].r){
            // return `(${context.parsed.x}, ${context.parsed.y})`;
            return `(${context.parsed.x}, ${context.parsed.y}, ${context.raw.r})`;
          }

          if(!context.dataset.data[context.dataIndex].r){
            if(context.dataset.pointRadius === 4){
              return `(${context.parsed.x}, ${context.parsed.y})`;
            }
          }
        },
        title: function () {
          return null;
        },
      },
    },
  },
  
  responsive : true,
  maintainAspectRatio : false,
  aspectRatio: 2,
  layout : {
    autoPadding : false
  }
};

export default function Trajectory(props) {
  // for the dots
    const [data1, setData1] = useState([])
    // for the bubble
    const [data2, setData2] = useState([])
    const [data3, setData3] = useState([])
  useEffect(()=>{
    setData1([])
    setData2([])
    setData3([])

    if(props?.chartData.datasets !== undefined){
      if(props?.chartData?.datasets[0]?.xyData){
        props?.chartData?.datasets[0]?.xyData.map((x,index)=>{
  
          // extracting the x, y value
          const filter1 = x[1].split('(');
          const filter2 = filter1[1].split(')');
          const filter3 = filter2[0].split(',');
          const value = {
              "x" : parseInt(filter3[0]),
              "y" : parseInt(filter3[1])
          }
  
          const range =( parseFloat(x[0]).toFixed(1) * 8.7).toFixed(2);
          const value2 = {
              "x" : parseInt(filter3[0]),
              "y" : parseInt(filter3[1]),
              "r" : parseFloat(range),
              // "radius":parseFloat(range),
          }
          setData1((preData) => [...preData, value])
          setData2((preData) => [...preData, value2])
        })
      }
    }
  },[props])

  useEffect(()=>{
    if(data2.length>2){
     
      const [x, y] = findCircleIntersection(data2);
      
      const value = {
        "x" : parseInt(x).toFixed(2),
        "y" : parseInt(y).toFixed(2)
      }
      setData3([value])

    }
  },[data2])
const data = {
  datasets: [
    {
      label: 'Dots',
      data: data1,
      pointRadius: 2,
      pointBackgroundColor: 'black',
      pointBorderColor: 'black',
      pointHoverRadius: 2,
      showLine: false,
    },
    {
      label: 'Dots',
      data: data3,
      pointRadius: 4,
      pointBackgroundColor: 'red',
      pointBorderColor: 'red',
      pointHoverRadius: 5,
      showLine: false,
    },
    {
      label: 'Circles',
      data: data2,
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
      ],
      borderWidth: 1,
      hoverBackgroundColor: [
        'rgba(255, 99, 132, 0.4)',
        'rgba(54, 162, 235, 0.4)',
        'rgba(255, 206, 86, 0.4)',
        'rgba(75, 192, 192, 0.4)',
      ],
      hoverBorderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
      ],
    },
  ],
};

return (
    <div className="bubble-chart">
        <Bubble id="bubble" data={data} options={options} className="bubble-chart"/>
    </div>
  );
}