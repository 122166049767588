export default function validate(obj) {
    let errors = {};  
    let values = {...obj};
    let blankspaces = /^(\S+$)/;
    let moreBlank =/\s{2,}/g ;
    let specialChar = /^((?![-_!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+).)*$/gm;
  
    Object.keys(values).map(k => values[k] = trimObjectStrings(k));
  
    if (!values.heading || values.heading == '' || !values.heading.replace(/\s/g, '').length) {
      errors.heading = 'Title is required';
    } else if (values.heading.length > 50) {
      errors.heading = 'Title  must be less than 50 characters';
    }
    // else if (!values.heading.match(specialChar)) {
    //   errors.heading = 'Title can not have special characters';
    // }
  
    if (!values.code || values.code == '') {
      errors.code = 'Code is required';
    } else if (values.code.length > 50) {
      errors.code = 'Code must be less than 50 characters';
    }
    // else if (!values.code.match(specialChar)) {
    //   errors.code = 'Code can not have special characters';
    // }
  
    var mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+(\.[A-Za-z]{2,4})+$/;
    // var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    
    // if (!values.email || values.email == '') {
    //   errors.email = 'Email address is required';
    // } else if (!values.email.match(mailformat)) {
    //   errors.email = 'Email address is invalid';
    //   // console.log(values.email)
    // }
    
    // else if (values.email.length > 50) {
    //   errors.email = 'Email must be less than 50 characters';
    // }
  
    if (!values.image || values.image == '') {
      errors.image = 'Thumbnail is required';
    }
  
    // if (!values.age || values.age == '') {
    //   errors.age = 'Age is required';
    // } else if (isNaN(values.age)) {
    //   errors.age = 'Invallid age';
    // } else if (values.age < 5 || values.age > 120) {
    //   errors.age = 'Age must contain only number between 5 - 120';
    // }
  
    if (!values.curriculamId || values.curriculamId < 0) {
      errors.curriculamId = 'Curriculum is required';
    }
    
    if (values.status === undefined || values.status < 0 || values.status === null) {
      errors.status = 'Status is required';
    }
  
    // if (!values.image || values.image == "") {
    //   errors.image = 'Password is required';
    // }else if (values.password.length < 4) {
    //   errors.password = 'Password must contain at least 4 characters';
    // } else if (values.password.length > 15) {
    //   errors.password = 'Password must contain less than 15 characters';
    // }
    // else if (!values.password.match(blankspaces)) {
    //   errors.password = 'Password field cannot contain blank spaces';
    // }
  
    // if (!values.confirmPassword || values.confirmPassword == "") {
    //   errors.confirmPassword = 'Confirm Password is required';
    // } else if (values.confirmPassword != values.password) {
    //   errors.confirmPassword = 'Passwords must match';
    // } else if (values.confirmPassword.length < 4) {
    //   errors.confirmPassword = 'Confirm Password must contain at least 4 characters';
    // } else if (values.confirmPassword.length > 15) {
    //   errors.confirmPassword = 'Confirm Password must contain less than 15 characters';
    // }else if (!values.confirmPassword.match(blankspaces)) {
    //   errors.confirmPassword = 'Confirm Password field cannot contain blank spaces';
    // }
  
    function trimObjectStrings(key){
      if(typeof values[key] == 'string' && !key.toLowerCase().includes("password")){
        return values[key].trim();
      }
      return values[key];
    }
  
    return errors;
  };