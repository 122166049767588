import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
    Card,
    CardText,
    CardImg,
    Row,
    Col,
    CardImgOverlay,
    Button,
    Badge,
    FormGroup,
    Label,
    FormFeedback
} from "reactstrap";
import {
    message,
    Steps,
    Form,
    Input,
    Space,
    Select,
    Checkbox,
    Radio,
    notification,
} from "antd";
import "antd/dist/antd.css";
import outputModals from './modals'
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import LessonService from "../../../../api/LessonService";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
const { TextArea } = Input;

function Hypothesis(props) {
    const [selectedItems, setSelectedItems] = useState([]);
    const [editorState, setEditorstate] = useState();
    const [editData, setEditData] = useState();
    const [hypothesisId, setHypothesisId] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [addOptions,setAddOptions] = useState(false)
    const [form] = Form.useForm();
    const [continued,setContinued] = useState(true)

    useEffect(() => {

       if(props.explore_id !== undefined) {
        LessonService.getHypothesisOfExperiment(props.explore_id).then(res => {
            if(res.data != undefined || res.data !== null){
               form.setFieldsValue({

                   Hytype: option9.find((x, i) => i === res.data.type-1),
                   hypothesis1:res.data.description,
                   hypothesis: res.data.hypotheisOptions.map(i => {
                       return {
                           option: i.option,
                           id: i.id,
                           hypotheis_id: res.data.hypotheis_id,
                           explore_step_id: props.explore_id,
                       }
   
                   })
               })
              setSelectedItems(option9.find((x, i) => i === res.data.type-1))
               if(res.data.type === 2){
                 setAddOptions(false)
               }
               else{
                setAddOptions(true)
               }
                   setHypothesisId(res.data.id)
                    //setEditorstate(res.data.description)
               
                    setDisabled(true)
                    setContinued(res.data !== null? false :true)
            }
       })
       }
       else{
        form.resetFields()
        setDisabled(true)
        setContinued(true)
      
       }

    }, [props.explore_id])

    
    const onFinish = (values) => {
        let arrayOfNotifications = [];
        console.log("type",values.Hytype,values.hypothesis?.length > 0,values.Hytype ==="Open Ended" ? values.hypothesis?.length > 0: true)
     if( values.Hytype ==="Open Ended" ? true:values.hypothesis?.length > 0 ){
        let objHypothesis = {
            "id": hypothesisId,
            "description": (editorState)?.toString() ?? values.hypothesis1,
            "type": option9.findIndex(x => x === values.Hytype)+1 ,
            "explore_step_id": props.explore_id
        }

        LessonService.addExploreHypothesis(objHypothesis).then(res => {
            if (res.status == 200) {
                setDisabled(true)
                let messages = [{type:'success',text: res.message}]
                let message1 = { type: 'success', text: res.message }
                arrayOfNotifications.push(message1);
                if (values.Hytype === "Open Ended") {
                notification.open({
                  placement: 'top',
                  duration:"2",
                  description: [
                      arrayOfNotifications.map((item) => {
                          if(item.type === 'success') {
                              return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                          }
                          if(item.type === 'error') {
                              return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                          }
                          if(item.type === 'warning') {
                              return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                          }
                      })
                  ],
          
                  onClick: () => {
                      console.log('Notification Clicked!');
                  },
              });
            }
                setContinued(false)
                let arr = [];
                values.hypothesis.map(i => {
                    let obj = {
                        "option": i.option,
                        "hypotheis_id": res.data.id,
                        "explore_step_id": props.explore_id
                    }
                    arr.push(obj)
                })

                let obj6 = {
                    id: res.data.id,
                    hypotheisOptions: arr
                }

               if(arr.length >= 0){
                LessonService.addExploreHypothesisOptions(obj6).then(res => {
                    if (res.status == 200) {
                        let message2 = { type: 'success', text: res.message }
                        arrayOfNotifications.push(message2)
        notification.open({
          placement: 'top',
          duration:"2",
          description: [
            arrayOfNotifications.map((item) => {
                  if(item.type === 'success') {
                      return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if(item.type === 'error') {
                      return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if(item.type === 'warning') {
                      return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
              })
          ],
  
          onClick: () => {
              console.log('Notification Clicked!');
          },
      });
                        setDisabled(true)
                        setContinued(false)
                    }
                    else {
                        let messages = [{type:'error',text: res.message}]
        notification.open({
          placement: 'top',
          duration:"2",
          description: [
              messages.map((item) => {
                  if(item.type === 'success') {
                      return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if(item.type === 'error') {
                      return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if(item.type === 'warning') {
                      return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
              })
          ],
  
          onClick: () => {
              console.log('Notification Clicked!');
          },
      });
                        setDisabled(false)
                        setContinued(true)
                    }
                })
               }
            }
            else {
                let messages = [{type:'error',text: res.message}]
                notification.open({
                  placement: 'top',
                  duration:"2",
                  description: [
                      messages.map((item) => {
                          if(item.type === 'success') {
                              return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                          }
                          if(item.type === 'error') {
                              return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                          }
                          if(item.type === 'warning') {
                              return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                          }
                      })
                  ],
          
                  onClick: () => {
                      console.log('Notification Clicked!');
                  },
              });
                setDisabled(false)
                setContinued(true)
            }
        })
     }
     else {
        notification.open({
            placement: 'top',
            duration:"2",
            description: [
                <li>
                    <CloseCircleOutlined className='text-danger ant-icon-up' />
                    <span className='ml-4'>Add at least one hypothesis option</span>
                </li>
            ]
        });
     }
    };
    const option9 = ["Single Choice","Open Ended"]
    const filteredOptions9 = option9.filter((o) => !selectedItems?.includes(o));
    const onChangeHypothesis = (e) => {
        setEditorstate(e.target.value)
    }

    return (
        <Form name="dynamic_form_nest_item" layout="vertical" onFinish={onFinish} form={form} disabled={props.type === 2}  onChange={(values) => {
            setDisabled(false)
        }} >
            <Row>
                <Col xs='12'>
                    <Form.Item name="Hytype" label="Hypothesis Type" rules={[{ required: true, message: 'Hypothesis type is Required' }]} >
                        <Select
                            mode=""
                            placeholder="Click here to Hypothesis type"
                            value={selectedItems}
                            onChange={(e) =>{
                                setSelectedItems(e)
                                if(e === 'Open Ended'){
                                    setAddOptions(false)
                                }
                                else{
                                    setAddOptions(true)
                                }
                                setDisabled(false)
                            }}
                            style={{
                                width: '100%',
                            }}
                        >
                            {filteredOptions9.map((item,i) => (
                                <Select.Option key={i} value={item}>
                                    {item}
                                </Select.Option>
                            ))}
                        </Select>
                        {/* <FormFeedback> </FormFeedback> */}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs='12'>
                    <Form.Item name="hypothesis1" label="Hypothesis" rules={[{ required: true, message: 'Hypothesis is Required' }]}>
                        <Input
                            
                            value={editorState}
                            onChange={onChangeHypothesis}

                        />
                         {/* <FormFeedback> </FormFeedback> */}
                    </Form.Item>
                </Col>
            </Row>
            {
                selectedItems !== 'Open Ended' && addOptions && <Form.List name="hypothesis">
                {(fields, { add, remove }) => (
                    <>
                        <Row className='question-headder-style'>
                            <Col xs='6' className='' >
                                <b>Hypothesis Options</b>
                            </Col>
                            <Col xs='6'>
                                { addOptions &&
                                    <div className='text-right' hidden={props.type === 2}>
                                    <Badge color="dark" className='p-2' onClick={() => {
                                        add()
                                        setDisabled(false)
                                    }} >
                                        Add New
                                    </Badge>
                                </div>
                                }
                            </Col>
                        </Row>
                        {fields.map(({ key, name, ...restField }) => (

                            <>
                                <Form.Item {...restField} className="mb-2" name={[name, 'option']} label="" rules={[{ required: true, message: 'Hypothesis Option is Required' }]}>
                                    <Input placeholder="Hypothesis option " />
                                    {/* <FormFeedback> </FormFeedback> */}
                                </Form.Item>
                                {addOptions &&
                                    <div className='text-right' hidden={props.type === 2}>
                                    <Badge  color="danger" className='p-2 mb-3' onClick={() => {
                                        setDisabled(false)
                                        remove(name)
                                    }} >
                                        Remove This
                                    </Badge>
                                </div>
                                }
                            </>

                        ))}
                    </>
                )}
            </Form.List>
            }
          {
            selectedItems !== 'Open Ended' &&
            <Form.Item>
            {/* <button class="btn btn-success btn-sm btn-block my-2" type="danger" htmlType="submit" block  >
                 Add to List
             </button> */}
             
         </Form.Item>
          }
           
            <Form.Item>
                    <Row>
                        <Col lg='12' md={24}>
                            <div className="center mt-4">
                                {/* <Button color="warning" className="mr-15" onClick={(e) => {
                                props.back();
                                }}>Cancel</Button> */}
                                <Button color="success" disabled={disabled} onClick={(e) => {
                                    e.preventDefault()
                                    form.submit()
                                }} type="primary" htmlType="submit">Save</Button>
                            </div>
                        </Col>
                    </Row>
                </Form.Item>
            <div>
                
                <Row>
                    <Col md={6} className='pull-left'>
                        <button
                            className="mb10 btn btn-info"
                            style={{padding: "14px 31px"}}
                            onClick={(e) => {
                                props.back();
                                }}
                        >
                            {"Back"}
                        </button>
                    </Col>
                    <Col md={6} className='pull-right'>
                        {
                            console.log("con",continued)
                        }
                        <button
                            type="primary"
                            disabled={continued}
                            className="mb10 btn btn-info"
                            style={{padding: "14px 31px"}}
                            onClick={(e) => {

                                props.GoToQuestionTab();
                                }}
                        >
                            {"Continue"}
                        </button>
                    </Col>
                </Row>
            </div>        
        </Form>
    );
}

export default Hypothesis;
