import { Modal } from 'antd';
import React, {  useEffect, useState } from 'react'
import { Button, Col, Input, Row } from 'reactstrap'

export default function ThirdTypeQuestion(props) {
  let [InputVal, setInputVal] = useState("");
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  const countDown = () => {
    const instance = modal.warning({
      title: 'Enter a proper number',
    });
    setTimeout(() => {
      instance.destroy();
    }, 3000);
  };

  const onKeyPress = (e) => {
    if (e.which === 13) {
      submit();
    }
  }


  const submit = () => {
    if(props.data.chartType === "Bar" ){
      props.data.onClick(InputVal)
    }else if(props.data.chartType === "Line Type4"  ){
      if(InputVal === "" || !InputVal){
        countDown();
      }else{
        props.data.onClick2(InputVal)
      }
    }else if(props.data.chartType === "Line Type9" ){
      props.data.onClick3(InputVal)
    }else{
      props.data.onClick(InputVal)
    }
    setInputVal("")
    document.getElementById("example").value = ""
  }

  const [header, setHeader] = useState({
    h1: `Next `,
    h2: `Watch `,
    h3: `Measure `,
    h4: `Start `,
  });
  
  const [btnValueTrigger, setBtnValueTrigger] = useState(true);
  const [btnValue, setBtnValue] = useState(header.h4);
  const [btnDisable, setBtnDisable] = useState(false);
  
  useEffect(()=>{
    // if(props.data.clickCount === 0){
      setBtnValue(header.h4);
      setBtnDisable(false);
      setBtnValueTrigger(true);
    // }
  },[props.data.resetValue])

  return (


    <div ref={props.refPass}>

            {/* <Modal title="" visible={isModalOpen}  onOk={handleCancel1}
              okText="OK">
              <p>Enter a proper number </p>
            </Modal> */}
            {contextHolder}

      <Row>
        <Col md="12">
          <strong>{props.data.initPayload?.instruction_heading}</strong>
        </Col>

        <Col md="12">
          <h6 className="">  

          {props.data.data && props.data.data !== null && (<>
            <div dangerouslySetInnerHTML={{ __html: props.data.data?.description}}></div>
            <span className='text text-success'>{props.data.initPayload?.instruction}</span>
          </>)} 
                    {props.data.chartType === "Line Type3"  &&
                    ( <button onClick={props.onNextValue} disabled={props.data.disableValue} className="btn btn-explore min-w ml-2">
                      {header.h1}
                    </button>)}
                    {props.data.chartType === "Line Type10" &&
                    ( <button onClick={props.onNextValue5} disabled={props.data.disableValue} className="btn btn-explore min-w ml-2">
                      {header.h1}
                    </button>)}
                    {props.data.chartType === "Line Type11" &&
                    ( <button onClick={props.onNextValue5} disabled={props.data.disableValue} className="btn btn-explore min-w ml-2">
                      {header.h1}
                    </button>)}
                    {props.data.chartType === "Bar Click2Count" &&
                    ( <button onClick={props.onNextValue2} disabled={props.data.disableValue} className="btn btn-explore min-w ml-2">
                      {header.h1}
                    </button>)}
                    {props.data.chartType === "Line Type7" &&
                      (btnValueTrigger === false ?
                        ( <button onClick={()=>{
                            props.onNextValue3();
                            props.canvasDisable(true);
                            // props.canvasBtnDisable(true);
                            setBtnValueTrigger(true);
                          }} disabled={props.data.disableValue } className="btn btn-explore min-w ml-2">
                          {header.h1}
                        </button>)
                      :
                        ( <button onClick={()=>{
                            props.onNextValue4();
                            let total = 7500;
                            setBtnValue(`${(total/1000)} sec`)
                            setBtnDisable(true);
                            props.canvasDisable(false);
                            props.canvasBtnDisable(false);
                            //used for showing the time remaining  
                            const intervalId = setInterval(function() {
                              total = total - 500;
                              setBtnValue(`${(total/1000)} sec`);
                            }, 500);

                          // setBtnValue()
                          setTimeout(function() {
                            props.canvasDisable(false);
                            props.canvasBtnDisable(true);
                            clearInterval(intervalId); 
                            setBtnValueTrigger(false);
                            total = 7500;
                            setBtnValue(header.h4);
                            setBtnDisable(false)

                            // Code to be executed after 7 seconds
                          }, 7500);

                          }} disabled={props.data.disableValue || btnDisable } className="btn btn-explore min-w ml-2">
                          {btnValue}
                        </button>))
                    }
          <br /></h6>
        </Col>


      </Row>
      {props.data.chartType === "Bar"   &&
        <Row>
         {
          props.data.chartType === "Bar"  &&  <div className='col-md-6'>
          <Input disabled={props.data.disableValue} type="number" name="question" id="example" onKeyPress={onKeyPress} onChange={(e) => { setInputVal(e.target.value) }}  onWheel={(e) => e.target.blur()}/>
        </div>
         }
          <div className='col-md-6'>
            {props.data.chartType === "Bar"  &&
              <Button disabled={props.data.disableValue} color="btn btn-explore ml-2" onClick={() => {
                if(props.data.chartType === "Bar"){
                  submit();
                }
               
                
              }}>{header.h1}</Button>
            }
          </div>
        </Row>
      }
      {props.data.chartType === "Line Type4"   &&
        <Row>
         {
          props.data.chartType === "Line Type4" &&  <div className='col-md-6'>
          <Input disabled={props.data.disableValue} type="number" name="question" id="example" onKeyPress={onKeyPress} onChange={(e) => { setInputVal(e.target.value) }} onWheel={(e) => e.target.blur()}/>
        </div>
         }
          <div className='col-md-6'>
            {props.data.chartType === "Line Type4" &&
              (<Button disabled={ props.data.disableValue} color="btn btn-explore ml-2" onClick={() => {
                if(props.data.chartType === "Line Type4"){
                  submit();
                }
               
                
              }}>{header.h1}</Button>)
            }
          </div>
        </Row>
      }
      { props.data.chartType === "Line Type9"   &&
        <Row>
         {
          props.data.chartType === "Line Type9" &&  <div className='col-md-6'>
          <Input disabled={props.data.disableValue2} type="number" name="question" id="example" onKeyPress={onKeyPress} onChange={(e) => { setInputVal(e.target.value) }}  onWheel={(e) => e.target.blur()}/>
        </div>
         }
          <div className='col-md-6'>
            { props.data.chartType === "Line Type9" &&
              (<Button disabled={ props.data.disableValue2 } color="btn btn-explore ml-2" onClick={() => {
                if( props.data.chartType === "Line Type9"){
                  submit();
                }
               
                
              }}>{header.h1}</Button>)
            }
          </div>
        </Row>
      }
      <Row>
        <Col>
        </Col>

        <Col xs="2" className="pt-4">
        </Col>
      </Row>
    </div>
  )
}
