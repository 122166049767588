import React from 'react';
import { Button, message, Space, notification, Row, Col, List } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

const openNotification = ({messages}) => {
    console.log("message",messages)
    notification.open({
        placement: 'top',
        duration:"2",
        description: [
            messages.map((item) => {
                if(item.type === 'success') {
                    return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if(item.type === 'error') {
                    return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if(item.type === 'warning') {
                    return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
            })
        ],

        onClick: () => {
            console.log('Notification Clicked!');
        },
    });
};
function MultipleMessages(props) {
    const [messageApi, contextHolder] = message.useMessage();

    const openNotification = () => {
        notification.open({
            placement: 'top',
            duration:"2",
            description: [
                props.messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-3'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-3'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-3'>{item.text}</span></li>);
                    }
                })
            ],

            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    return (

        <>
            {contextHolder}
            <Space>
                <Button onClick={openNotification}>Notfication</Button>
            </Space>
        </>
    )
}

export default MultipleMessages