import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from 'react-router-dom'

// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  Row,
  Col,
  CardImgOverlay,
} from "reactstrap";
import { Tabs,Spin } from "antd";
import "antd/dist/antd.css";
import CardImgs from "../../../assets/images/CardImg.png"
import CardImgs2 from "../../../assets/images/CardImg2.png"
import { getCurriculumsByTeacherId } from "../../../api/TeacherService";
import jsonToken from 'jsonwebtoken'
 

function Class({searchValue}) {
  const [data, setData] = useState({data:""});
  const [dataFiltered, setDataFiltered] = useState({data:""});
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    if(data.status === 200){
      if(searchValue){
        const filteredData = data.data.filter(item => item.curriculamName.toLowerCase().includes(searchValue.toLowerCase()));
        setDataFiltered({"data":filteredData, "message":data?.message, "status":data?.status})
      }else{
        setDataFiltered(data)
      }
    }
  },[searchValue, data]);

  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));

   useEffect( ()=>{
    getCurriculumsByTeacherId(accessToken.userId).then((response) => {
      setData(response);
      setLoading(false);

     })
   }, []);
 
   const display = Object.values(dataFiltered.data).map((x)=>{
           if(dataFiltered.status === 200){
            return(
            <Col lg='3' md='6' className="mt-10">
            <Link onClick={()=>{
              localStorage.setItem("curriculumName", x.curriculamName);
            }} value={x.curriculamId} to={`/Teacher/PlanLesson/${x.curriculamId}`}>
              <div className="course-card">
                <Card className="border-0 rounded-0  ">
                  <CardImg
                    alt="Class Card"
                    src={CardImgs}
                    width="100%"
                  /> 
                  <div className="">
                    <CardText className="overlayer-center w-80 overlayer-bg-dark overlayer-space-20 text-white layer-1">
                    <h4 className="text-white">{x.curriculamName}</h4>
                    </CardText>
                    <CardImgOverlay className="overlayer-center w-100 h-100 overlayer-bg-dark layer-2 ">
                      <CardText className="overlayer-center w-100 text-white">
                      <h4 className="text-white">{x.curriculamName}</h4>
                        <hr className="hr-white" />
                        {/* change to number of  lessons in that class*/}
                        <small className="text-white">{x.description}</small>
                      </CardText>
                    </CardImgOverlay>
                  </div>
                </Card>
              </div>
              </Link>
            </Col>
            )
        }
   },[dataFiltered]);
  return (
    <>
    <div className="container">
      <Spin tip="Loading..." spinning={loading} size="large">
        <div className="content">
          <Row>
            {display}
          </Row>
        </div>
      </Spin>
      </div>
    </>
  );
}

export default Class;