import { useState, useEffect, useRef } from "react";
import { Input, FormFeedback, Row, Col, Button, ModalHeader, ModalBody, ModalFooter, Modal } from "reactstrap";
import { Alert, notification, Select, Spin } from 'antd';
import "antd/dist/antd.css";
import ClassService from "../../../../api/ClassService"
import useCustFormValidator from "../../../../hooks/useCustFormValidator";
import validate from './classFormValidationRules';
import { getTeacherList } from "../../../../api/TeacherService";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";
import info from "../../../../assets/images/info.svg"
import { getTermByTermID } from "../../../../api/TermService";
import moment from "moment";

function ClasseEdit(props) {
    const [initData, setInitData] = useState({ classLevels: [], curriculams: [], terms: [] });
    const [loading, setLoading] = useState(false);
    const [edited, setEdited] = useState(false);
    const [editedClassCurriculum, setEditedClassCurriculum] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [uneditedObj, setUneditedObj] = useState({});
    const [teacherData, setTeacherData] = useState({});
    const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });

    const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(saveClass, validate, {});
    const [accounts, setAccounts] = useState([]);
    const [accountId, setAccountId] = useState(null);
    const [schools, setSchools] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [accountChange, setAccountChange] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedTermId, setSelectedTermId] = useState(null)
    const togglePopup = () => setModal(!modal);
    const [assignedStdList, setAssignedStdList] = useState(null);

    let startDateRef = useRef();
    let endDateRef = useRef();

    useEffect(() => {
        getAssignedStudentList();
    }, [])

    useEffect(() => {
        getInitData();
    }, []);

    useEffect(() => {
        getClassFromId(props.selectedClassId);
    }, [props.selectedClassId]);

    useEffect(() => {
        setAccountId(props.accval)
    }, [props.accval]);

    useEffect(() => {
        loadSchools(accountId)
    }, [accountId]);

    useEffect(() => {
        setEdited(JSON.stringify(values) != JSON.stringify(uneditedObj))
    }, [values]);

    useEffect(() => {
        if (accounts.length > 0) {
            setDisabled(true)
        }
    }, [props?.accval])

    useEffect(() => {
        loadTeachers(values.organizationId);
    }, [values.organizationId]);

    useEffect(() => {
        if (selectedTermId > 0) {
            startDateRef.current.disabled = true;
            endDateRef.current.disabled = true;
            getTermByID(selectedTermId);
        }
        else {
            startDateRef.current.disabled = false;
            endDateRef.current.disabled = false;
            // setFormData({ ...values, startDate: moment().format('yyyy-MM-DD'), endDate: moment().add(1, 'years').format('yyyy-MM-DD') })
        }
    }, [selectedTermId])

    const getAssignedStudentList = async () => {
        if (props.selectedClassToEdit?.id) {
            await ClassService.getClassStudentsList(props.schoolId, props.selectedClassToEdit?.id)
                .then((response) => {
                    setAssignedStdList(response.data);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        }
    }

    const getTermByID = async (id) => {
        setLoading(true);
        if (id) {
            await getTermByTermID(id)
                .then((response) => {
                    if (response) {
                        let data = response.data;
                        setFormData({ ...values, startDate: data.started_date, endDate: data.end_date })
                    }
                })
                .catch((error) => {
                    console.log("error :", error.message)
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    const checkUniqueEntry = (data, newEntry) => {
        const exist = data.some(entry => entry.id === newEntry.id);
        if (!exist) {
            data.push(newEntry)
        }
        return data;
    }

    function loadTeachers(schoolId) {
        if (schoolId) {
            if (!accountId || accountId <= 0) {
                setSchools([]);
                return;
            }
            setLoading(true);
            getTeacherList(schoolId)
                .then((response) => {
                    if (response.data) {

                        const updatedData = checkUniqueEntry(response.data, teacherData)
                        setTeachers(updatedData);
                    }
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    function getInitData() {
        setLoading(true);
        ClassService.getCreateClassInitData()
            .then((response) => {
                if (response.data) {
                    setInitData(response.data);
                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
                loadAccounts();
            });
    }

    function loadAccounts() {
        setLoading(true);
        ClassService.getAccountsForUser()
            .then((response) => {
                if (response?.data?.accountList) {
                    setAccounts(response.data.accountList);
                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    function loadSchools() {
        if (!accountId || accountId <= 0) {
            setSchools([]);
            setTeachers([]);
            return;
        }
        setLoading(true);
        ClassService.getSchoolsForAccount(accountId)
            .then((response) => {
                if (response?.data) {
                    setSchools(response.data);
                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    function getClassFromId(id, loading = true) {
        if (!id) {
            return;
        }
        if (loading) {
            setLoading(true);
        }
        ClassService.getClassById(id)
            .then((response) => {
                if (response?.data) {
                    let data = { ...response.data, accountId: props.accval };
                    setUneditedObj(data);
                    setFormData(data);
                    setTeacherData(data?.teacherData)
                    setTeachers([data?.teacherData]);
                    setSelectedTermId(data.termId)
                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                if (loading) {
                    setLoading(false);
                }
            });
    }

    const sendData = () => {
        setLoading(true);
        setEdited(false);

        let submitPayload = {
            id: props.selectedClassId,
            "accountId": accountId,
            "organizationId": values.organizationId,
            "description": values.description,
            "name": values.name,
            "startDate": values.startDate,
            "endDate": values.endDate,
            "status": 1,
            "quizGrade": 100,
            "classLevelId": values.classLevelId,
            "hypothesisType": 1,
            "curriculamId": values.curriculamId,
            "teacherId": values.teacherId,
            "classCode": values.classCode,
            "termId": values.termId,
        }

        ClassService.updateClass(submitPayload)
            .then((response) => {
                if (response.status === 200) {
                    let messages = [{ type: 'success', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    setTimeout(function () {
                        getClassFromId(response.data.id, false);
                    }, 2000);
                }
                else {
                    let messages = [{ type: 'error', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    setFormData(values)
                }
            }).catch((err) => {
                let messages = [{ type: 'error', text: err.message }]
                notification.open({
                    placement: 'top',
                    duration: "2",
                    description: [
                        messages.map((item) => {
                            if (item.type === 'success') {
                                return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'error') {
                                return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'warning') {
                                return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                        })
                    ],

                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });
                setFormData(values)

            }).finally(() => {
                setLoading(false);
            });
    }

    function saveClass() {
        if (editedClassCurriculum) {
            // setEditedClassCurriculum(false);
            ClassService.checkCurriculum(props.selectedClassId)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.lessonCount > 0) {
                            togglePopup();
                        } else {
                            sendData()
                        }
                    }
                })

        } else {
            sendData()
        }
    }

    function hideAlert() {
        var alert = { showAlert: false, message: "", type: "success" }
        setAlertData(alert);
    }

    function preventDecimal(e) {
        if (e.key === '.' || e.key === 'e') {
            e.preventDefault();
        }
    }

    function onAccountChange(value, e) {
        setAccountChange(true);
        setFormData({ ...values, accountId: value, organizationId: -1, teacherId: -1 });
        setAccountId(value);
    }

    function onSchoolChange(value, e) {
        setFormData({ ...values, organizationId: value, teacherId: -1 });
    }

    function onChangeTeacherName(value, e) {
        setFormData({ ...values, teacherId: value });
    }

    function onChangeClassLevel(value) {
        setFormData({ ...values, classLevelId: value })
    }

    function onChangeHypothesisType(value) {
        setFormData({ ...values, hypothesisType: value })
    }

    function onChangeCurriculum(value) {
        setFormData({ ...values, curriculamId: value })
    }
    function onTermChange(value) {
        setFormData({ ...values, termId: value })
        setSelectedTermId(value)
    }
    const filterTeacherValues = (input, option) => {
        if (option.children.length === 3) {
            let tempString = option.children.join("");
            return tempString.toLowerCase().includes(input.toLowerCase());
        }
    }

    return (
        <>
            <div >
                <Modal isOpen={modal} toggle={togglePopup}>
                    <ModalBody>
                        <center>
                            <img src={info} alt='popup-thum' />
                        </center>
                        <div className='popup-text'>
                            Students have already answered this curriculum. Changing of
                            curriculum can loose the student records.
                            Do you wish to change?

                        </div>
                    </ModalBody>
                    <ModalFooter className='center'>
                        <Button color="success" onClick={() => {
                            sendData();
                            togglePopup();
                        }}>Yes</Button>
                        <Button color="warning" onClick={togglePopup}>No</Button>
                    </ModalFooter>
                </Modal>
                <Row>
                    <Col lg='12' className=" center mt-4 py-2 text-center">

                        {alertData.showAlert ?
                            <Alert message={alertData.message} type={alertData.type}
                                action={
                                    <a type="text" onClick={hideAlert}>
                                        x
                                    </a>
                                } /> : ""}
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Spin tip="Loading..." spinning={loading} size="large">
                            <div className="form-row">
                                <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>Account
                                    </label>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Select an Account"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={accountId}
                                        disabled={disableEnableDropdown("Account", props.user_roll, assignedStdList?.length)}
                                        onChange={(value, event) => {
                                            onAccountChange(value, event)
                                        }}
                                        invalid={!!errors?.accountId}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <option key="-1" value="-1">Please Select an Account</option>
                                        {
                                            accounts.filter(item => item.name !== null).map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                        }
                                    </Select>
                                    <FormFeedback>{errors?.accountId}</FormFeedback>
                                </div>
                                <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>School
                                    </label>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Select a School"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={values.organizationId == -1 ? "Please Select a School" : values.organizationId}
                                        disabled={disableEnableDropdown("School", props.user_roll, assignedStdList?.length)}
                                        onChange={(value, event) => {
                                            onSchoolChange(value, event);
                                        }}
                                        invalid={!!errors?.organizationId}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <option selected key="-1" value="-1">Please Select a School</option>
                                        {
                                            schools.length > 0 &&
                                            schools?.map((obj) =>
                                                <option key={obj.id} value={obj.id}>{obj.name}</option>
                                            )
                                        }
                                    </Select>
                                    <FormFeedback>{errors?.organizationId}</FormFeedback>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>Teacher
                                    </label>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Please Select a Teacher"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={values.teacherId == -1 ? "Please Select a Teacher" : values.teacherId}
                                        disabled={disableEnableDropdown("Teacher", props.user_roll)}
                                        onChange={(value, event) => {
                                            onChangeTeacherName(value, event);
                                        }}
                                        invalid={!!errors?.teacherId}
                                        filterOption={(input, option) =>
                                            filterTeacherValues(input, option)
                                        }
                                    >
                                        <option selected key="-1" value="-1">Please Select a Teacher</option>
                                        {
                                            teachers?.map((obj) =>
                                                <option key={obj.id} value={obj.id}>{obj.firstName} {obj.lastName}</option>
                                            )
                                        }
                                    </Select>
                                    <FormFeedback>{errors?.teacherId}</FormFeedback>
                                </div>
                                <div className="form-group col-md-6 col-sm-12">
                                    <label className="form-label" htmlFor="form3Example4">Description</label>
                                    <Input
                                        type="text"
                                        id="form3Example4"
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        placeholder=""
                                        value={values?.description}
                                        name="description"
                                        onChange={(e) => { handleChange(e) }}
                                        invalid={!!errors?.description}
                                    />
                                    <FormFeedback>{errors?.description}</FormFeedback>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6 col-sm-12">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>Class Name/Number
                                    </label>
                                    <Input
                                        type="text"
                                        id="form3Example4"
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        placeholder=""
                                        value={values?.name}
                                        name="name"
                                        onChange={(e) => { handleChange(e) }}
                                        invalid={!!errors?.name}
                                    />
                                    <FormFeedback>{errors?.name}</FormFeedback>
                                </div>
                                <div className="form-group col-md-6 col-sm-12">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>Class Code
                                    </label>
                                    <Input
                                        type="text"
                                        id="form3Example4"
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        placeholder=""
                                        value={values?.classCode}
                                        name="classCode"
                                        onChange={(e) => { handleChange(e) }}
                                        invalid={!!errors?.classCode}
                                    />
                                    <FormFeedback>{errors?.classCode}</FormFeedback>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-lg-6 col-sm-12 override-FormFeedback">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>Class Level
                                    </label>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Please Select a Class Level"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={values.classLevelId}
                                        onChange={(value, event) => {
                                            onChangeClassLevel(value)
                                        }}
                                        invalid={!!errors?.classLevelId}
                                    >
                                        <option key="-1" value="-1">Please Select a Class Level</option>
                                        {
                                            initData.classLevels.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                        }
                                    </Select>
                                    <FormFeedback>{errors?.classLevelId}</FormFeedback>

                                </div>
                                {/* <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                    <label className="form-label" htmlFor="form3Example4">
                                        Hypothesis Type
                                    </label>
                                    <Select
                                    getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Please Select a Hypothesis Type"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={values.hypothesisType == 1 ? "Single Choice" : values.hypothesisType == -1 ? "Please Select a Hypothesis Type" : "Open Ended"}
                                        onChange={(value, event) => {
                                            onChangeHypothesisType(value)
                                        }}
                                        invalid={!!errors?.classLevelId}
                                    >
                                        <option key="-1" value="-1">Please Select a Hypothesis Type</option>
                                        <option value="2">Open Ended</option>
                                        <option value="1">Single Choice</option>
                                    </Select>
                                    <FormFeedback>{errors?.hypothesisType}</FormFeedback>
                                </div> */}
                                {/* </div>
                            <div className="form-row"> */}
                                <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                    <label className="form-label" htmlFor="form3Example4">
                                        <span className="required-field">*</span>Course Curriculum
                                    </label>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Please Select a Curriculum"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={values.curriculamId}
                                        onChange={(value, event) => {
                                            onChangeCurriculum(value);
                                            setEditedClassCurriculum(true);
                                        }}
                                        invalid={!!errors?.classLevelId}
                                    >
                                        <option key="-1" value="-1">Please Select a Curriculum</option>
                                        {
                                            initData.curriculams.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                        }
                                    </Select>
                                    <FormFeedback>{errors?.curriculamId}</FormFeedback>
                                </div>
                                {/* <div className="form-group col-md-6 col-sm-12">
                                    <label className="form-label" htmlFor="form3Example4">
                                        Preferred Quiz Grade
                                    </label>
                                    <Input
                                        type="number"
                                        id="form3Example4"
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        placeholder=""
                                        max="100"
                                        min="1"

                                        value={values?.quizGrade}
                                        name="quizGrade"
                                        onChange={(e) => { handleChange(e) }}
                                        invalid={!!errors?.quizGrade}
                                        onKeyDown={preventDecimal}
                                    />
                                    <FormFeedback>{errors?.quizGrade}</FormFeedback>

                                </div> */}
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6 col-sm-12">
                                    <label className="form-label" htmlFor="form3Example4">
                                        Term
                                    </label>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder="Please Select a Term"
                                        optionFilterProp="children"
                                        size="large"
                                        className="search-drop"
                                        value={values.termId <= 0 ? "Please Select a Term" : values.termId}
                                        onChange={(value, event) => {
                                            onTermChange(value);
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <option key="-1" value="-1">Please Select a Term</option>
                                        {
                                            initData.terms.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6 col-sm-12">
                                    <label className="form-label" htmlFor="startDate">
                                        <span className="required-field">*</span>Start Date
                                    </label>
                                    <Input
                                        innerRef={startDateRef}
                                        type="date"
                                        id="startDate"
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        placeholder=""
                                        value={values?.startDate}
                                        name="startDate"
                                        onChange={(e) => { handleChange(e) }}
                                        invalid={!!errors?.startDate}
                                    />
                                    <FormFeedback>{errors?.startDate}</FormFeedback>
                                </div>
                                <div className="form-group col-md-6 col-sm-12">
                                    <label className="form-label" htmlFor="endDate">
                                        <span className="required-field">*</span>End Date
                                    </label>
                                    <Input
                                        innerRef={endDateRef}
                                        type="date"
                                        id="endDate"
                                        className="form-control form-control-lg"
                                        autoComplete="off"
                                        placeholder=""
                                        value={values?.endDate}
                                        name="endDate"
                                        onChange={(e) => { handleChange(e) }}
                                        invalid={!!errors?.endDate}
                                    />
                                    <FormFeedback>{errors?.endDate}</FormFeedback>
                                </div>
                            </div>
                            <div className="center mt-4">
                                <Button onClick={props.onClose} color="warning" className="mr-15">Cancel</Button>
                                <Button onClick={handleSubmit} color="success" disabled={!edited}>Save</Button>
                            </div>
                            <div className="center mt-4">
                            </div>
                        </Spin>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ClasseEdit;
