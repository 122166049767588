import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    CardSubtitle,
    CardText,
    CardImg,
    Row,
    Col,
    Modal, ModalHeader, ModalBody,
    UncontrolledTooltip,
} from "reactstrap";
import { Radio, Input, Space, Button, Tabs, Table, Image } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
// import img1 from "../../../assets/images/img1.jpg";

// import { dataSource, columns } from './data'
import Column from "antd/lib/table/Column";
import { Link, useHistory, useParams } from "react-router-dom";
import AssignPop from "./AssignPop"
import { GetLessonsByClass } from "../../../../../api/TeacherService";
import thumb from '../../../../../assets/images/thumb-sm.png'

function ViewLesson(props) {
    const { TabPane } = Tabs;
    let { lessonId } = useParams();
    let history = useHistory();

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResult, setsearchResult] = useState([]);

    // const [modal, setModal] = useState(false);

    const [size, setSize] = useState();
    const [component, setcomponent] = useState();

    // const toggleConfirm = () => setModal(!modal);

    const [response, setResponse] = useState([]);
    const [loading, setLoading] = useState(true);

    console.log(response)
    useEffect(() => {
        ConsumeApi();

    }, [])

    async function ConsumeApi() {
        setLoading(false);
        const responceData = await GetLessonsByClass(lessonId);
        if (responceData !== undefined || responceData !== null) {
            // let dataArray = [];

            console.log("table data ", responceData);
            setResponse(responceData?.data);
        } else {
            console.log("variable is NOT undefined or null");
        }
        setLoading(false);
    }


    const getSearchTerm = (e) => {
        const currValue = e.target.value;
        console.log(currValue);
        setSearchTerm(currValue);
        if (searchTerm !== "") {
            const filteredData = response.filter(entry =>
                entry.heading.toString().toLowerCase().includes(currValue.toLowerCase()))
            // console.log(Object.values(currValue))
            setsearchResult(filteredData);
        } else {
            setsearchResult(response)
        }
    }

    // const togglePopupAssign = () => {
    //   setModal(!modal)
    //   setSize('xl');
    //   // setcomponent(<Engage data={responce} />)
    //   setcomponent(<AssignPop />)
    // };
    // const toggle = () => {
    //   setModal(!modal)
    // };

    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Accept': 'application/json'
    //   }
    // }
    // )
    const columns = [
        {
            title: "Lesson Name's",
            dataIndex: 'heading',
            key: 'heading',
            render: (_, record) => (
                <Space size="middle">

                    <Link to={`/Teacher/ClassDetails/${lessonId}`} >{record.heading}</Link>
                </Space>
            ),

        },
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (_, record) => (
                <Space size="middle">
                    <Button className='btn btn-success rounded-0' size='small' onClick={(e) => {
                        e.preventDefault();
                        history.push('/AssignLesson')
                    }}
                        href='#'>Assign Lesson</Button>
                    {/* <Button className='btn btn-success danger-0' size='small' href='#'>Unassign</Button> */}
                </Space>
            ),
        },
        {
            title: 'Lesson',
            dataIndex: 'lesson',
            key: 'lesson',
            render: (_, record) => (
                <Space size="middle">
                    <Button className='btn btn-dark rounded-0' size='small' href='#'>View</Button>
                </Space>
            ),
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (_, record) => (
                <Space size="middle">
                    <Image src={thumb} className='small-thumb' preview={false} />
                </Space>
            ),
        },
    ];
    return (
        <>
            <div className="content">
                <Row>
                    <Col xs="4" className="space-py-20">
                        <Button icon={<ArrowLeftOutlined />}></Button>
                    </Col>
                </Row>
                <Row className="py-3 px-5">
                    <Col span={8}>
                        <Button onClick={() => history.push(`/Organization`)} icon={<ArrowLeftOutlined />} className='border-0'></Button>
                        Back
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="space-py-20">
                        <Card className="bg-dark border-0 rounded-0">
                            <Col xs='4'>
                                <CardBody className="ml-3 mt-3 mb-3">
                                    <Input size="large"
                                        placeholder="Search"
                                        onChange={getSearchTerm}
                                        prefix={<SearchOutlined
                                            className="ml-3 mr-3" />} />
                                </CardBody>
                            </Col>
                        </Card>
                    </Col>
                </Row>

                <Table
                    dataSource={response} columns={columns}
                    // loading={loading}
                    pagination={{ pageSize: 3, total: 10, showSizeChanger: true }}
                // dataSource={searchTerm.length <= 1 ? response?.data : searchResult}
                // dataSource={searchTerm?.length <= 1 || searchTerm?.length === undefined ? response : searchResult} 
                />

            </div>

        </>
    );
}

export default ViewLesson;


