import { Space, Button, Input } from 'antd';
const { Search } = Input;
const url = (window.location.href).split("#");

const columns = [
  {
    title: 'School Name',
    dataIndex: 'organization',
    key: 'organization',
    width:300,
    sorter: (a, b) => a.organization.localeCompare(b.organization),
  },
  {
    title: 'Instructors',
    dataIndex: 'instructors',
    key: 'instructors',
    sorter: (a, b) => a.instructors - b.instructors,
  },
  {
    title: 'Class ',
    dataIndex: 'class',
    key: 'class',
    sorter: (a, b) => a.class - b.class,
  },
  {
    title: 'Student',
    dataIndex: 'student',
    key: 'student',
    sorter: (a, b) => a.student - b.student,
  },
  {
    title: 'Lessons',
    dataIndex: 'lessons',
    key: 'lessons',
    sorter: (a, b) => a.lessons - b.lessons,
  },
  {
    title: 'Avg Engage',
    dataIndex: 'angIntro',
    key: 'angIntro',
    sorter: (a, b) => a.angIntro - b.angIntro,
  },
  {
    title: 'Avg Evaluate',
    dataIndex: 'angQuiz',
    key: 'angQuiz',
    sorter: (a, b) => a.angQuiz - b.angQuiz,
  },
  {
    title: 'Avg Explain',
    dataIndex: 'angRpt',
    key: 'angRpt',
    sorter: (a, b) => a.angRpt - b.angRpt,
  },
  {
    title: 'Avg Extend',
    dataIndex: 'angExt',
    key: 'angExt',
    sorter: (a, b) => a.angExt - b.angExt,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (_, record) => (
      <>
      {record.role === "TEACHER" ? 
        (<Button type="primary" size='medium' href={`${url[0]}#/Instructor/1/Classes/${record.userId}`}>View</Button>)
        :
        (<Button type="primary" size='medium' href={`${url[0]}#/Instructor/${record.org_id}`}>View</Button>)
      }
      </>
    ),
  },
];

export { columns }