import { useState, useEffect } from "react";
import { Card, CardText, FormFeedback, Row, Col, Input, Button, FormGroup } from "reactstrap";
import { Alert, notification, Select, Spin } from 'antd';
import { updateTeacher2, getTeacherbyId, addNewTeacher } from "../../../../api/TeacherService";
import jsonToken from 'jsonwebtoken'
import useCustFormValidator from "../../../../hooks/useCustFormValidator";

import StudentService from "../../../../api/StudentServiceNew"
import validate from "./createFormValidation";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";

function TeacherEdit(props) {

    const [initialData, setInitialData] = useState({
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        username: "",
        organization_id: -1,
        confirmPassword: '',
        status: 1,
        roleId: 2,
        staffId: "",
        accountId: -1
    });

    var obj = {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        username: "",
        organization_id: -1,
        confirmPassword: '',
        status: 1,
        roleId: 2,
        staffId: "",
        accountId: -1
    }
    const [loading, setLoading] = useState(false);
    const [edited, setEdited] = useState(true);
    const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });

    const [accountId, setAccountId] = useState(null);

    const [accounts, setAccounts] = useState([]);
    const [schools, setSchools] = useState([]);

    const [showPass, setShowPass] = useState(false);
    const [accountChange, setAccountChange] = useState(false)

    const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(onSave, validate, obj);

    let accessToken = jsonToken.decode(localStorage.getItem("access_token"));

    useEffect(() => {
        loadAccounts();
        //loadTeacher();
    }, []);

    useEffect(() => {
        loadSchools(accountId)
    }, [accountId]);

    useEffect(() => {
        setAccountId(-1)
    }, [props.accval]);

    useEffect(() => {
        if (accessToken.roles[0] === "SCHOOL_ADMIN" || accessToken.roles[0] === "ACCOUNT_ADMIN") {
            if (accounts.length === 1) {
                setAccountId(accounts[0].id)
                setFormData({ ...values, accountId: accounts[0].id })
            }
        }
    }, [accounts])

    useEffect(() => {
        if (accessToken.roles[0] === "SCHOOL_ADMIN") {
            if (schools.length === 1) {
                setFormData({ ...values, organization_id: schools[0].id })
            }
        }
    }, [schools])

    function loadAccounts() {
        setLoading(true);
        StudentService.getAccountsForUser()
            .then((response) => {
                if (response?.data?.accountList) {
                    setAccounts(response.data.accountList);
                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    function loadSchools() {
        if (!accountId || accountId <= 0) {
            setSchools([]);
            return;
        }
        setLoading(true);
        StudentService.getSchoolsForAccount(accountId)
            .then((response) => {
                if (response?.data) {
                    setSchools(response.data);

                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    function loadTeacher() {
        if (!props.selectedId || props.selectedId <= 0) {
            return
        }
        setLoading(true);
        getTeacherbyId(props.selectedId)
            .then((response) => {
                if (response?.data) {
                    var data = response.data;
                    data.password = '';
                    data.confirmPassword = '';
                    data.accountId = -1;
                    setFormData(data);
                }
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    function onAccountChange(value) {
        setAccountChange(true)
        // e.persist();
        setFormData({ ...values, accountId: value, organization_id: -1 });
        setAccountId(value);
    }
    function onSchoolChange(value) {
        setFormData({ ...values, organization_id: value })
    }

    function setInitialValues() {
        if (accessToken.roles[0] === "SCHOOL_ADMIN" || accessToken.roles[0] === "ACCOUNT_ADMIN") {
            var newDataset = initialData;
            newDataset.accountId = values.accountId;
            setInitialData(newDataset);
            setFormData(initialData)
        } else {
            setSchools([]);
            setAccountId(-1);
            setFormData(initialData);
        }
    }

    function onSave() {
        setLoading(true);
        delete values.confirmPassword;

        let submitPayload = {
            id: 0,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            username: values.username,
            organization_id: values.organization_id,
            confirmPassword: values.confirmPassword,
            status: 1,
            roleId: 2,
            staffId: values.staffId,
            accountId: values.accountId

        }
        addNewTeacher(submitPayload)
            .then((response) => {
                if (response.status == 200) {
                    let messages = [{ type: 'success', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    loadTeacher();
                    setInitialValues();
                }
                else {
                    let messages = [{ type: 'error', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    loadTeacher();
                    setFormData(obj)
                }

            }).catch((err) => {
                let messages = [{ type: 'error', text: err.message }]
                notification.open({
                    placement: 'top',
                    duration: "2",
                    description: [
                        messages.map((item) => {
                            if (item.type === 'success') {
                                return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'error') {
                                return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'warning') {
                                return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                        })
                    ],

                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });
            }).finally(() => {
                setLoading(false);
            });
    }

    function hideAlert() {
        var alert = { showAlert: false, message: "", type: "success" }
        setAlertData(alert);
    }

    function onShowPassChange(e) {
        if (!e.target.checked) {
            setFormData({ ...values, password: '', confirmPassword: '', showPass: false })
        } else {
            setFormData({ ...values, showPass: true })
        }
        setShowPass(e.target.checked)
    }
    function onEmailChange(e) {
        e.persist();
        setFormData({ ...values, email: e.target.value })
    }
    function onUserNameChange(e) {
        e.persist();
        setFormData({ ...values, username: e.target.value })
    }

    return (
        <>
            <div>
                <Spin tip="Loading..." spinning={loading} size="large">
                    <form>
                        <Row>
                            <Col lg='12' className="py-2 text-center">
                                {alertData.showAlert ?
                                    <Alert message={alertData.message} type={alertData.type}
                                        action={
                                            <a type="text" onClick={hideAlert}>
                                                x
                                            </a>
                                        } /> : ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <div className="form-row">
                                    <div className="form-group col-lg-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>First Name
                                        </label>

                                        <Input
                                            type="text"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autocomplete="off"
                                            placeholder=""

                                            value={values.firstName}
                                            onChange={handleChange}
                                            name="firstName"
                                            invalid={!!errors?.firstName}
                                        />
                                        <FormFeedback>{errors?.firstName}</FormFeedback>
                                    </div>
                                    <div className="form-group col-lg-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>Last Name
                                        </label>
                                        <Input
                                            type="text"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autocomplete="off"
                                            placeholder=""
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            invalid={!!errors?.lastName}
                                        />
                                        <FormFeedback>{errors?.lastName}</FormFeedback>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-lg-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            Email
                                        </label>
                                        <input type="text" id="hiddenField" style={{display: "none"}} autoComplete="email" />
                                        <Input
                                            type="text"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            placeholder=""
                                            name="email"
                                            autoComplete="new_mail"
                                            value={values.email}
                                            onChange={(e) => { onEmailChange(e) }}
                                            invalid={!!errors?.email}
                                        />
                                        <FormFeedback>{errors?.email}</FormFeedback>
                                    </div>
                                    <div className="form-group col-lg-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>Username
                                        </label>
                                        <input type="text" id="hiddenField" style={{display: "none"}} autoComplete="username" />
                                        <Input
                                            type="text"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            placeholder=""
                                            name="userName"
                                            autoComplete="off"
                                            onChange={(e) => { onUserNameChange(e) }}
                                            value={values.username}
                                            invalid={!!errors?.username2}
                                        />
                                        <FormFeedback>{errors?.username2}</FormFeedback>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-lg-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            Staff ID
                                        </label>
                                        <Input
                                            type="text"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autocomplete="off"
                                            placeholder=""
                                            name="staffId"
                                            onChange={handleChange}
                                            value={values.staffId}
                                            invalid={!!errors?.staffId}
                                        />
                                        <FormFeedback>{errors?.staffId}</FormFeedback>

                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>Account
                                        </label>
                                        <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                            showSearch
                                            placeholder="Select an Account"
                                            optionFilterProp="children"
                                            size="large"
                                            className="search-drop"
                                            value={values.accountId === -1 ? "Please Select an Account" : values.accountId}
                                            disabled={disableEnableDropdown("Account", props.user_roll)}
                                            invalid={!!errors?.accountId}
                                            onChange={(value) => {
                                                onAccountChange(value);
                                            }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            {
                                                accounts.length > 0 && (
                                                    <>
                                                        <option key="-1" value="-1">Please Select an Account</option>
                                                        {
                                                            accounts.filter(item => item.name !== null).map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                                        }
                                                    </>
                                                )
                                            }
                                        </Select>
                                        <FormFeedback>{errors?.accountId}</FormFeedback>
                                    </div>
                                    <div className="form-group col-md-6 col-sm-12 override-FormFeedback">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>School
                                        </label>
                                        <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                            showSearch
                                            placeholder="Select a School"
                                            optionFilterProp="children"
                                            size="large"
                                            className="search-drop"
                                            value={values.organization_id == -1 ? "Please Select a School" : values.organization_id}
                                            invalid={!!errors?.organization_id}
                                            disabled={disableEnableDropdown("School", props.user_roll)}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={(value) => {
                                                onSchoolChange(value);
                                            }}
                                        >
                                            {
                                                <option key="-1" value="-1">Please Select a School</option>
                                            }
                                            {
                                                schools.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                            }
                                        </Select>
                                        <FormFeedback>{errors?.organization_id}</FormFeedback>
                                    </div>
                                </div>
                                {/* <div className="form-row">
                                    <div className="form-group col-md-6 col-sm-12">
                                        <FormGroup check inline >
                                            <Input type="checkbox"
                                                checked={values
                                                    .showPass}
                                                onChange={onShowPassChange}
                                                className="checked-margin"
                                            />
                                            <label check className="pt-6">
                                                Change Password
                                            </label>
                                        </FormGroup>
                                    </div>
                                </div> */}
                                <div className="form-row" >
                                    <div className="form-group col-md-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>Password
                                        </label>
                                        <input type="password" id="hiddenPasswordField" style={{display: "none"}} autoComplete="current-password" />
                                        <Input
                                            type="password"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            placeholder=""
                                            name="password"
                                            autoComplete="off"
                                            value={values.password}
                                            onChange={handleChange}
                                            invalid={!!errors?.password}
                                        />
                                        <FormFeedback>{errors?.password}</FormFeedback>
                                    </div>
                                    <div className="form-group col-md-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>Confirm Password
                                        </label>
                                        <Input
                                            type="password"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autoComplete="off"
                                            placeholder=""
                                            name="confirmPassword"
                                            value={values.confirmPassword}
                                            onChange={handleChange}
                                            invalid={!!errors?.confirmPassword}
                                        />
                                        <FormFeedback>{errors?.confirmPassword}</FormFeedback>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='12'>
                                <div className="center mt-4">
                                    <Button color="warning" className="mr-2 fixed-width-115" onClick={() => {
                                        props.setFlag(false)
                                    }}>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleSubmit} className="fixed-width-115" color="success" disabled={!edited}>Save</Button>
                                </div>
                                <div className="center mt-4">
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Spin>
            </div>
        </>
    );
}

export default TeacherEdit;
