import { useEffect, useState } from "react";

// reactstrap components
import { Card, CardText, CardImg, Row, Col, CardBody } from "reactstrap";
import { Button } from "react-bootstrap";
import { Tabs, Input, Table, Space } from "antd";
import "antd/dist/antd.css";
import NewClass from "./new";
import ViewClass from "./view";

import { useHistory, Link, useLocation } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 


function CreateClass(props) {
  const [infoIndex, setInfoIndex] = useState(0);
  const [flag, setFlag] = useState(false);
  const { TabPane } = Tabs;
  const [editInfo, setEditInfo] = useState();
  const [edited,setEdited] = useState(false)

  const onChange = (key) => {
    // console.log(key);
  };
  const currentFunction = (key) => {
    setInfoIndex(key);
    setFlag(true);
  };

  const handleButtonClick = () => {
    setFlag(!flag);
    setEditInfo(null);
  };
  useEffect(()=>{
    setFlag(false)
  },[props])

  const [isMenuOpen, handleMenu] = useState(false);
  const handleCloseMenu = () => {
    handleMenu(false);
  };
  const handleStateChange = (state) => {
    handleMenu(state.isOpen);
  };
  const history = useHistory();


  const handleClickConfirm = () => {
    confirmAlert({
      title: 'Switch to Lesson',
      message: 'Are you sure you want to switch?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            //e.preventDefault()
            handleCloseMenu()
            handleButtonClick()
          }
          },
        
        {
          label: 'No',
          onClick: () => {
            handleCloseMenu()
          },
        },
      ],
    });
  };

  return (
    <>
      <div className="container-fluid admin-tabs">
        <Row>
          <Col md="12">
            <Card className="border-0">
              <CardBody className="e">
                <Row>
                  <Col md="6">
                    <h5>Lesson</h5>
                  </Col>
                  <Col md="6">
                    <div className="pull-right">
                      <Button
                        type="primary"
                        className="mr10 mb20"
                        // onClick={handleButtonClick}
                        size="sm"
                        onClick={(e) => {
                          handleCloseMenu()
                          if(flag && edited){
                            handleClickConfirm()
                          }
                          else{
                            handleButtonClick()
                          }
                          
                        }}
                        
                      >
                        {flag ? "View" : "New"}
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {flag ? (
                      <NewClass
                        a={flag}
                        setFlag={setFlag}
                        infoIndex={infoIndex}
                        editInfo={editInfo}
                        editData ={(dta) =>{
                          setEdited(dta)
                        }}
                      />
                    ) : (
                      <ViewClass
                        h={flag}
                        setCurrent={currentFunction}
                        setEditInfo={setEditInfo}
                      />
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CreateClass;
