import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from "react-router-dom";

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle, 
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Table,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Breadcrumb, Radio, Input, Form, Modal, Button, Tabs, Checkbox, Image, Spin } from "antd";
import "antd/dist/antd.css";
import img1 from "../../../assets/images/explore.png";
import x_axis from "../../../assets/images/x-axis.svg";
import y_axis from "../../../assets/images/y-axis.svg";
import graph from "../../../assets/images/graph.svg";
import Video from "../../../components/Video";
import up from "../../../assets/images/graph-up.svg";
import jsonToken from 'jsonwebtoken'

import LabDescription from "../../../components/Questions/LabDescription";

import LineChart from "../../../components/Charts/LineChart";
import { UserData } from "../../../components/Charts/Data";
import RestService from "../../../api/RestService";
import AnswerDisplay from "../AnswerDisplay";
import FloatingButton from "../../../components/FloatingButton";

function Extend(props) {
    const [payload, setPayload] = useState();

    const [initPayload, setInitPayload] = useState({
      description:"",
      extendStepQuestionAnswers:[],
      extendStepQuestions:[],
      id:"",
      lesson_id:""
    });

    const [containedPayload, setContainedPayload] = useState({});
    const [reviewStatus,setReviewStatus] = useState( props.disabled ?? true);
    const [lesson,setLesson] = useState(parseInt(props.extendId));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [validationMsg, setValidationMsg] = useState("");
    const [lessonName,setLessonName] = useState("");
    const [loading,setLoading] = useState(true);
    function convert(data) {
      return Array.isArray(data)
          ? data.reduce( (obj, el, i) => (el+1 && (obj[i] = convert(el)), obj), {} )
          : data;
    }

    let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;
    
  }


    
    useEffect( ()=>{
     setLoading(true)
     RestService.getExtendInfosById(lesson)
    .then((response) => {
      setInitPayload(response.data);setLoading(false)
      setLessonName(response?.data?.lessonName);
      setClassLessonName(response?.data?.className);
      })
    }, [])

    // useEffect( ()=>{
    //   setLoading(true)
    //   RestService.getExtendStepByIdAndUserName(user,lesson)
    //  .then((response) => {
    //   if(response){
        
    //     setContainedPayload(response?.data[0]?.response_json.answers);
    //     setPayload(convert(response?.data[0]?.response_json.answers))
    //     setLoading(false)
    //   }
    //    })
    //  }, [])


     useEffect( ()=>{
      if(props.username !== undefined){
        setLoading(true)
      RestService.getExtendStepByIdAndUserName(props.username,lesson)
     .then((response) => {
      if(response){
        
        setContainedPayload(response?.data[0]?.response_json.answers);
        setPayload(convert(response?.data[0]?.response_json.answers))
        setLoading(false)
      }
       })
      }
     }, [props])

  

  const { TabPane } = Tabs;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [classLessonName, setClassLessonName] = useState("");
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const { TextArea } = Input;
  const [value, setValue] = useState(1);


  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.distance),
    datasets: [
      {
        data: UserData.map((data) => data.time),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
        options: {
          plugins: {
            title: {
              display: true,
              text: "",
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "Distance",
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Time",
              },
            },
          },
        },
        label: "",
      },
    ],
  });

  const onChange = (e) => {
   
    setValue(e.target.value);
  };
 
  const modifiedDescription = initPayload?.description.split(":");
   const secondtypemodifiedDescription  = initPayload?.description.split("?");
  const newMod = secondtypemodifiedDescription[0].split("H");

  
  const ReviewFun = () => {
    setReviewStatus(false)
    window.scrollTo(0, 0)
  };  
  const changeHandler = (event) => {
    const name = event.target.name ;
    const value = event.target.value;
    const count = parseInt(name)+1;

    setPayload(prev => ({
      ...prev,
      [name]: {question_id: count, answer: value}
    }));
  };
 

  const submitFun = async() => {
    if(payload === undefined || initPayload.extendStepQuestions.length > Object.keys(payload).length) {
      setValidationMsg("Did you finish all parts of your extra-credit report? Do you wish to continue?")
      setIsModalOpen(true);  

    }else{
        const submitPayload = {
          username:user,
          userId:initPayload.id,
          status:2,
          response_json:{
            answers:Object.values(payload)
          },
          last_update_date:new Date().toISOString().slice(0, 10),
          extend_step_id:lesson,
        }

      
        await RestService.postExtendStepUserResponses(submitPayload)
        .then((response) =>{
        
          if(response.status === 200) {
            const url = (window.location.href).split("#");
            // window.scrollTo(0, 0);
            window.open(url[0],"_self")
          }
          
        })
      }
  }

  const handleOk1 = async () => {
    setReviewStatus(false);
    setIsModalOpen(false);
    
    const submitPayload = {
      username:user,
      userId:initPayload.id,
      status:2,
      response_json:{
        answers:Object.values(payload)
      },
      last_update_date:new Date().toISOString().slice(0, 10),
      extend_step_id:lesson,
    }

  
    await RestService.postExtendStepUserResponses(submitPayload)
    .then((response) =>{
    
      if(response.status === 200) {
        const url = (window.location.href).split("#");
        // window.scrollTo(0, 0);
        window.open(url[0],"_self")
      }
      
    })
  };

  const handleCancel1 = () => {
    
    setReviewStatus(true);
    window.scrollTo(0, 0);
    setIsModalOpen(false);
  };
  const TSdate = Date.now();
  const dateFormat = new Date(TSdate);
  const MonthYear = dateFormat.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
  const HrsMinSec = dateFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });

   
  return (
    <Spin tip="Loading..." spinning={loading} size="large">
      <div className="content extend-page tab-style scroll-tab disabled-input">
        <div className="card-chart p-3 card card-grey no-border">
          <div className="card-body">
          <Modal title="" visible={isModalOpen} onOk={handleOk1} onCancel={handleCancel1}>
            <p>{validationMsg}</p>
          </Modal>
          
            {/* {
              lesson ===3 &&
              <Row className="">
              <Col xs="6" className="">
                <h3 className="mt-2 mb-2">{newMod[0]}</h3>
                <br/>
                <p>{"H"+newMod[1]}</p>
                <br />
                <p>
                  {secondtypemodifiedDescription[1]}
                </p>
              </Col>
            </Row>
            } */}
          </div>
        </div>

        <div className="card-chart  card card-grey">
          <div className="card-body">
            <Row className="">
              <Col xs="12">
                <Card className="bg-evaluate border-0 rounded-0 no-border">
                  <CardBody className="">
                    {/* {display} */}

                    
                    <AnswerDisplay data = {{
                      type:2,
                      payload:initPayload?.extendStepQuestions,
                      changeHandler:changeHandler,
                      containedPayload:containedPayload

                    }} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              <FloatingButton name={!props.complted && reviewStatus ? 'Review':'Submit Answers'} onClick={!props.complted && reviewStatus ? ReviewFun:submitFun} />
            </Row> */}

            {/* <Row className="button-center p2">
            {reviewStatus ? (
                <button onClick={ReviewFun} type="primary" class="btn btn-primary btn-lg mt-50 floating-button-center">
                Review
              </button>
              ) : (
                <button onClick={submitFun} type="primary" class="btn btn-primary btn-lg mt-50 floating-button-center">
                Submit Answers
              </button>
              )}
            </Row> */}
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default Extend;
