import { useState, useEffect } from "react";
import { FormFeedback, Row, Col, Input, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Alert, notification, Select, Spin } from 'antd';
import jsonToken from 'jsonwebtoken'
import useCustFormValidator from "../../../../hooks/useCustFormValidator";
import { EditTerm, getTermByTermID } from "../../../../api/TermService";
import moment from "moment";
import validate from "./editFormValidation";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import info from '../../../../assets/images/info.svg'


function TermEdit(props) {
    var obj = {
        name: props.selectedTermData?.name,
        stdate: props.selectedTermData?.started_date,
        endDate: props.selectedTermData?.end_date,
    }
    const [loading, setLoading] = useState(false);
    const [edited, setEdited] = useState(false);
    const [uneditedObj, setUneditedObj] = useState(obj);
    const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });

    const [assignedClasses, setAssignedClasses] = useState(0);
    const [modal, setModal] = useState(false);
    
    const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(onSave, validate, { obj });

    let accessToken = jsonToken.decode(localStorage.getItem("access_token"));

    useEffect(() => {
        getTermAssignedClass(props.selectedTermId);
    }, [props.selectedTermId])

    const getTermAssignedClass = async (termID) => {
        // setLoading(true);
        if (termID && accessToken.roles[0] === "SUPER_ADMIN") {
            await getTermByTermID(termID)
                .then((response) => {
                    if (response) {
                        let data = response.data;
                        let editobj = {
                            id: data.id,
                            name: data.name,
                            stdate: data.started_date,
                            endDate: data.end_date,
                        }

                        setFormData(editobj);
                        setUneditedObj(editobj)
                        setAssignedClasses(data.assignedClassCount);
                    }
                }).catch((error) => {
                    console.log("error :", error.message);
                }).finally(() => {
                    // setLoading(false);
                })
        }
    }

    useEffect(() => {
        setEdited(JSON.stringify(values) != JSON.stringify(uneditedObj))
    }, [values]);

    function onSave() {
        if (assignedClasses > 0) {
            togglePopup();
        }
        else {
            sendData();
        }
    }

    const togglePopup = () => {
        setModal(!modal);
    }

    const sendData = () => {
        setLoading(true);
        let editData = {
            id: values.id,
            name: values.name,
            started_date: moment(values.stdate).format('yyyy-MM-DD'),
            end_date: moment(values.endDate).format('yyyy-MM-DD'),
            created_user_id: accessToken.userId,
            status: 1,
        }
        EditTerm(editData)
            .then((response) => {
                if (response.status == 200) {
                    let messages = [{ type: 'success', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    getTermAssignedClass(response.data.id);
                }
                else {
                    let messages = [{ type: 'error', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                }
            }).catch((err) => {
                let messages = [{ type: 'error', text: err.message }]
                notification.open({
                    placement: 'top',
                    duration: "2",
                    description: [
                        messages.map((item) => {
                            if (item.type === 'success') {
                                return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'error') {
                                return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'warning') {
                                return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                        })
                    ],

                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });
            }).finally(() => {
                setLoading(false);
            });
    }

    function hideAlert() {
        var alert = { showAlert: false, message: "", type: "success" }
        setAlertData(alert);
    }

    const clearForm = () => {
        reset();
        setFormData(values);
        props.onClose();
        setTimeout(function () {
            hideAlert();
        }, 2000);
    }

    return (
        <>
            <div>
                <Modal
                    isOpen={modal} toggle={togglePopup}
                >
                    <ModalBody>
                        <center>
                            <img src={info} alt="popup-thum-img" />
                        </center>
                        <div className="popup-text">
                            This term is already in used in classes. Changing this term can
                            effect those class records.
                            Do you wish to proceed?
                        </div>
                    </ModalBody>
                    <ModalFooter className="center">
                        <Button
                            color="success"
                            onClick={() => {
                                sendData();
                                togglePopup();
                            }}
                        >
                            Yes
                        </Button>
                        <Button color="warning" onClick={togglePopup}>No</Button>
                    </ModalFooter>
                </Modal>
                <Row>
                    <Col md="12">
                        <Spin tip="Loading..." spinning={loading} size="large">
                            <form>
                                <Row>
                                    <Col lg='12' className="py-2 text-center">
                                        {alertData.showAlert ?
                                            <Alert message={alertData.message} type={alertData.type}
                                                action={
                                                    <a type="text" onClick={hideAlert}>
                                                        x
                                                    </a>
                                                } /> : ""}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div className="form-row">
                                            <div className="form-group col-lg-12 col-sm-12">
                                                <label className="form-label" htmlFor="form3Example4">
                                                    <span className="required-field">*</span>Term Name
                                                </label>

                                                <Input
                                                    type="text"
                                                    id="form3Example4"
                                                    className="form-control form-control-lg"
                                                    autocomplete="off"
                                                    placeholder=""

                                                    value={values.name}
                                                    onChange={handleChange}
                                                    name="name"
                                                    invalid={!!errors?.name}
                                                />
                                                <FormFeedback>{errors?.name}</FormFeedback>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-lg-12 col-sm-12">
                                                <label className="form-label" htmlFor="form3Example4">
                                                    <span className="required-field">*</span>Start Date
                                                </label>
                                                <Input
                                                    type="date"
                                                    id="form3Example4"
                                                    className="form-control form-control-lg"
                                                    autocomplete="off"
                                                    placeholder=""
                                                    name="stdate"
                                                    value={values.stdate}
                                                    onChange={handleChange}
                                                    invalid={!!errors?.stdate}
                                                />
                                                <FormFeedback>{errors?.stdate}</FormFeedback>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-lg-12 col-sm-12">
                                                <label className="form-label" htmlFor="form3Example4">
                                                    <span className="required-field">*</span>End Date
                                                </label>
                                                <Input
                                                    type="date"
                                                    id="form3Example4"
                                                    className="form-control form-control-lg"
                                                    autocomplete="off"
                                                    placeholder=""
                                                    name="endDate"
                                                    onChange={handleChange}
                                                    value={values.endDate}
                                                    invalid={!!errors?.endDate}

                                                />
                                                <FormFeedback>{errors?.endDate}</FormFeedback>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='12'>
                                        <div className="center mt-4">
                                            <Button color="warning" className="mr-15" onClick={clearForm}>
                                                Cancel
                                            </Button>
                                            <Button onClick={handleSubmit} color="success" disabled={!edited}>Save</Button>
                                        </div>
                                        <div className="center mt-4">
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </Spin>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default TermEdit;
