// export const CalculationModule = [
//     // "SplitLine",
//     // "Line Volume Defined",
//     "Line",
//     // "Line Type2",
//     // "Position2",
//     // "Line Type3",
//     // "Line Radial Defined",
//     // "Simple Line",
//     // "Line Force Defined",
//     // "Line Temperature Defined",
//     "Bar",
//     "Bar YesNo",
//     // "curve",
//     // "Simple curve",
// ]

// export const GraphModule = [
//     "SplitLineChart",
//     "LineRegressionChart",
//     "SinChart",
//     "SimpleLineChart",
//     "Bar",
//     "BarChartWithYesNo",
//     // "CurveChart",
// ]

export const GraphModule = [
        {value:"Line",name:"Line Regression Chart"},
        {value:"Bar",name:"Bar (Number Input)"},
        {value:"curve",name:"Curve Chart (Fixed Point - Absolute Calculation)"},
        {value:"splitLine",name:"Two Line Regression Chart"},
        {value:"Line Force Defined",name:"Standard Chart (Fixed Point Calculation)"},
        {value:"Simple Line",name:"Standard Chart (Two - Points Calculation)"},
        {value:"Line Radial Defined",name:"Special Chart (Circular Redial)"},
        {value:"Line Volume Defined",name:"Special Chart (Volume Graph Category)"},
        {value:"Line Temperature Defined",name:"Special Chart (Phase Changes Category)"},
        {value:"Bar YesNo",name:"Bar (Canvas Click)"},
        {value:"Line Type2",name:"Special Chart (Spring Energy Category)"},
        {value:"Line Type3",name:"Special Chart (Photosynthesis and Light Category)"},
        {value:"normal",name:"X,Y Position"},
        {value:"Line Type4",name:"Graph (Number Input)"},
        {value:"Line Type5",name:"Bar (Canvas Input)"},
        {value:"curve2",name:"Curve Chart (Fixed Point - No Absolute Calculation)"},
        {value:"Bar Click2Count",name:"Bar Click to Count"},
        {value:"Line Type6",name:"Earthquake"},
        {value:"Line Type7",name:"Natural Selection"},
        {value:"Line Type8",name:"Energy"},
        {value:"Line Type9",name:"Air Pollution"},
        {value:"Line Type10",name:"Diversity"},
        {value:"Line Type11",name:"Biodiversity"},
        {value:"Line Type12",name:"Circle Chart"},

    ]

// data table values
export const TableValue =[
    {value:"v", label:"Volume 1"},
    {value:"v2", label:"Volume 2"},
    {value:"fit", label:"Fit"},
    {value:"a", label:"Acceleration"},
    {value:"max", label:"Maximum"},
    {value:"Slope", label:"Slope"},
    {value:"halfTime", label:"Half Time"},
    {value:"Period", label:"Period"},
]