import { useEffect, useState } from "react";
import { Input, Table, Select, Drawer, Spin } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import Edit from "./Edit";
import { Row, Col, } from "reactstrap";
import { getClassData, getSchoolData, getStudentData } from "../../../../api/TeacherService";
import { useDispatch } from "react-redux";
import { addToTable, editStudent } from "../../../../reducers/studentSlice.js"
import { GetAccountData } from "../../../../api/AccountService";
import jsonToken from 'jsonwebtoken';
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";

const { Option } = Select;

const onSearch = (value) => {
  console.log("search:", value);
};

function StudentsView(props) {

  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  const columns = [
    {
      title: "Student Name",
      dataIndex: "firstName",
      key: "firstName",
      className: "fixed-size",
      render: (value, row, index) => {
        console.log(row)
        return row.firstName + " " + row.lastName;

      }
    },
    {
      title: "School",
      dataIndex: "organizationName",
      key: "school",
    },
    {
      title: "Action",

      render: (text, record) => <span className="btn btn-sm btn-info" onClick={() => showDrawer(record)}>Edit</span>,
      className: "action"
    },
  ];

  const [accountData, setAccountData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [accVal, setAccVal] = useState();
  const [schoolVal, setSchoolVal] = useState();
  const [classVal, setClassVal] = useState();
  const [searchTerm, setSearchTerm] = useState("")
  const [loadingAc, setLoadingAc] = useState(false);
  const [size, setSize] = useState();
  const [isopen, setIsOpen] = useState(false);
  const [selectedStudentEdit, setSelectedStudentEdit] = useState(null);
  const [disableAccount, setDisableAccount] = useState(false);
  const [disableSchool, setDisableSchool] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [searchStudentText, setSearchStudentText] = useState("")

  const dispatch = useDispatch();

  const showDrawer = (record) => {
    setSelectedStudentEdit(record);
    setIsOpen(true);
    setSize("large");
    dispatch(editStudent({ ...record, accountId: accVal }))
    console.log(record)
  };

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (props.activeTab == props.tabId) {
      getAccounts();
      if (accVal && accVal > 0) {
        getSchools();
      }
      if (schoolVal && schoolVal > 0) {
        getClasses();
      }
      if (schoolVal && schoolVal > 0) {
        getStudents();
      }
    }
  }, [props.activeTab])

  useEffect(() => {
    if (accVal && accVal > 0) {
      getSchools();
    }
  }, [accVal])

  useEffect(() => {
    if (schoolVal && schoolVal > 0) {
      getClasses();
    }
  }, [schoolVal])

  useEffect(() => {
    if (schoolVal && schoolVal > 0) {
      getStudents();
    } else {
      setStudentData([]);
    }
  }, [schoolVal, classVal])

  useEffect(() => {
    if (schoolVal && schoolVal > 0 && !isopen) {
      getStudents();
    }
  }, [isopen])

  useEffect(() => {
    if (accessToken.roles[0] === "SCHOOL_ADMIN" || accessToken.roles[0] === "ACCOUNT_ADMIN" || accessToken.roles[0] === "TEACHER") {
      if (accountData && accountData.length === 1) {
        setAccVal(accountData[0].id)
        setDisableAccount(true);
      }
    }
  }, [accountData])

  useEffect(() => {
    if (accessToken.roles[0] === "SCHOOL_ADMIN" || accessToken.roles[0] === "TEACHER") {
      if (schoolData && schoolData.length === 1) {
        setSchoolVal(schoolData[0].id)
        setDisableSchool(true);
      }
    }
  }, [schoolData])

  const getStudents = async (page = currentPage, pageSize = defaultPageSize, search = searchTerm) => {
    setLoadingAc(true)
    let classs = classVal;
    if (!classs) {
      classs = 0;
    }
    try{
      const response = await getStudentData(classs, schoolVal, page, pageSize, search);
      const data = await response?.data.items;
      setStudentData(data);
      dispatch(addToTable(data));
      setTotal(response.data.totalItems);
      setCurrentPage(response.data.page);
      setLoadingAc(false);
    }catch{
      setLoadingAc(false);
    }
    
  }

  const getAccounts = async () => {
    setClassData(null)
    setSchoolData(null)
    setLoadingAc(true)
    const response = await GetAccountData();
    const data = await response?.data;

    setAccountData(data.accountList);
    setLoadingAc(false);
  }

  const getSchools = async () => {
    setSchoolData([])
    setLoadingAc(true)
    const response = await getSchoolData(accVal);
    const data = await response?.data;
    setSchoolData(data);
    setLoadingAc(false);
    setStudentData([])
    setClassData([])
  }

  const getClasses = async () => {
    setClassData([])
    setLoadingAc(true)
    if (schoolVal || schoolVal > 0) {
      const response = await getClassData(schoolVal);
      const data = await response?.data;
      setClassData(data);

    } else {
      console.log("error")
    }
    setLoadingAc(false);
  }

  const onChangeAccount = (value) => {
    setCurrentPage(1);
    setClassVal();
    setSchoolVal()
    setAccVal(value);
    setTotal(0);
    setSearchStudentText("");

    if (value === "-1") {
      setSchoolData([]);
      setClassData([]);
    }
  }
  const onChangeSchool = (value) => {
    setCurrentPage(1);
    setClassVal();
    setSchoolVal(value);
    setSearchTerm("")
    setSearchStudentText("");
    if (value === "-1") {
      setClassData([]);
    }
  }
  const onChangeClass = (value) => {
    setCurrentPage(1);
    setClassVal(value);
    setSearchTerm("")
    setSearchStudentText("");
  }

  const searchTable = (searchtext) => {
    setCurrentPage(1);
    setSearchTerm(searchtext);
    setSearchStudentText(searchtext);
    setCurrentPage(1);
    getStudents(1, defaultPageSize, searchtext);
  }
  return (
    <>
      <div>
          <div className="my-5">
            <Row>
              <Col md="3">
                <Select
                  showSearch
                  placeholder="Search by Account"
                  optionFilterProp="children"
                  size="large"
                  disabled={disableEnableDropdown("Account", props.user_roll)}
                  value={accVal}
                  className="search-drop"
                  onChange={onChangeAccount}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {
                    <>
                      {
                        accountData && accountData !== undefined ? <option key="-1" value="-1">Please Select an Account</option> : ""
                      }
                      {
                        accountData && accountData !== undefined ?
                          accountData.map((obj, index) => {
                            return (
                              <option key={index.id} value={obj.id}>{obj.name}</option>
                            )
                          })
                          : "No Accounts"
                      }
                    </>
                  }
                </Select>
              </Col>
              <Col md="3">
                <Select
                  showSearch
                  placeholder="Search by School"
                  optionFilterProp="children"
                  size="large"
                  className="search-drop"
                  onChange={onChangeSchool}
                  onSearch={onSearch}
                  value={schoolVal !== "-1" ? schoolVal : null}
                  disabled={disableEnableDropdown("School", props.user_roll)}
                >
                  {accVal !== null && accVal !== undefined && accVal > 0 ?
                    <option selected key="-1" value="-1">Please Select a School</option>
                    : <option key="-1" value="-1">Select an Account First</option>}
                  {
                    schoolData && schoolData !== undefined ?

                      schoolData.map((obj, index) => {
                        return (
                          <option key={index.id} value={obj.id}>{obj.name}</option>
                        )
                      })
                      : "No Schools"

                  }

                </Select>
              </Col>
              <Col md="3">
                <Select
                  showSearch
                  placeholder="Search by Class"
                  size="large"
                  className="search-drop"
                  optionFilterProp="children"
                  onChange={onChangeClass}
                  onSearch={onSearch}
                  value={classVal !== "-1" ? classVal : null}
                >
                  {schoolVal !== null && schoolVal !== undefined && schoolVal > 0 ?
                    <option selected key="-1" value="-1">Please Select a Class</option>
                    :
                    <Option key="-1" value="-1">Select a School First</Option>
                  }
                  {schoolVal !== null && schoolVal !== undefined && schoolVal > 0 ?

                    <Option value="0">All Students</Option>
                    : ""
                  }
                  {classData && classData !== undefined ?
                    <>
                      {classData.map((obj, index) => {
                        return (
                          <option key={index.id} value={obj.id}>{obj.name}</option>
                        )
                      })}
                    </>
                    : "No Schools"
                  }
                </Select>
              </Col>
              <Col md="3">
                <Input
                  size="large"
                  placeholder="Search"
                  onChange={(e) => {
                    searchTable(e.target.value)}}
                  prefix={<SearchOutlined className="ml-3 mr-3" />}
                  value={searchStudentText}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col md="12">
              <Table
                loading={loadingAc}
                dataSource={studentData}
                //pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                pagination={{
                  current: currentPage,
                  position: ["bottomCenter"],
                  total: total, // Total number of items in the API response (you may need to fetch this dynamically)
                  pageSize: defaultPageSize, // Number of items to display per page
                  onChange: (page, pageSize) => getStudents(page, pageSize), // Callback function to handle page changes
                  showSizeChanger: false
                }}
                columns={columns}
                className="thead-dark admin-table"
              />
            </Col>
          </Row>
        <Drawer
          className="medium"
          title={`Edit Student`}
          placement="right"
          size={size}
          destroyOnClose={true}
          onClose={onClose}
          visible={isopen}
        >
          <Edit
            accval={accVal}
            onClose={onClose}
            schoolVal={schoolVal}
            selectedStudentName={selectedStudentEdit?.name}
            selectedStudentId={selectedStudentEdit?.id}
            selectData={selectedStudentEdit}
            user_roll={props.user_roll}
          />
        </Drawer>
      </div>
    </>
  );
}

export default StudentsView;
