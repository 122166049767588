import React from 'react'
import DragAndDropUploader from '../../../../components/DragAndDropUploader/DragAndDropUploader/DragAndDropUploader'

function Import(props) { 
  return (
    <>
        <DragAndDropUploader data={props.data}/>
    </>
  )
}

export default Import