import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Table,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
  FormFeedback
} from "reactstrap";
import { Radio, Input, Form, Modal, Tabs,Breadcrumb, Alert, notification, Spin, Tooltip } from "antd";
import "antd/dist/antd.css";
import Pdf from "../../../../../../components/Pdf";
import { SearchOutlined, ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';


import TeacherExplain from "./explain";

import Question from "../../../../../../components/Questions/Question"
import Step from "../../../../../../components/Step";
import Footer from "../../../../../../components/Footer/Footer";
import { useHistory, useParams } from "react-router";
import { GetAllExplainStepInfos, GetStudentsEvaluateLessonScore, GetOneStudentData, updateEngageComment, updateEvaluateComment, updateExplainComment, updateExploreComment, updateExtendComment, updateMarksToClever, updateCommentsToClever } from "../../../../../../api/TeacherService";
import Engage from "../../../../../LessonLab3/Engage";
import ExploreMain from "../../../../../LessonLab3/Explore/main";
import Evaluate from "../../../../../LessonLab3/Evaluate";
import Extend from "../../../../../LessonLab3/Extend";
import Explain from "../../../../../LessonLab3/Explain";
import TextArea from "antd/lib/input/TextArea";
import RestService from "../../../../../../api/RestService";
import PdfDownload2 from "../../../../../../components/Pdf/index2";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

function StudentPage(props) {
  const { TabPane } = Tabs;
  const {className,lessonName,id, studentLevel, lessonID} = useParams();
  const name = localStorage.getItem('studentName');
  const [spObject, setSpObject] = useState({});
  const [state,setState] = useState(1);
  const [updateComment,setUpdateComment] = useState("");
  const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });
  const [inputValue, setInputValue] = useState("");
  const [saveClick,setSaveClick] =useState(false)
  const [saveCommentClick,setSaveCommentClick] =useState(false)
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lessonAct, setLessonAct] = useState({
    1: true,
    2: true,
    3: true,
    5: true,
    5: true
  })

  const stopAllMedia = () => { 
    // Stop all video elements
    const videos = document.querySelectorAll('video');
    videos.forEach((video) => video.pause());
  
    // Stop all audio elements
    const audios = document.querySelectorAll('audio');
    audios.forEach((audio) => audio.pause());
  
    // Stop all YouTube iframes
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach((iframe) => {
      if (iframe.src.includes('youtube.com')) {
        const src = iframe.src;
        iframe.src = src; // Reloading the iframe to stop the video
      }
    });
  };
  
  const callback = (key) => {
    updateTabBackground(key);
    stopAllMedia();
  };

  let payload2 = JSON.parse(localStorage.getItem("stepIDs"));
  let studentStatus = localStorage.getItem("EvaluationStudentStatus");
  let lmsState = localStorage.getItem("EvaluationLMSState");

  let history = useHistory();

  useEffect(() => {
    updateTabBackground();
    setState(1)
    setSaveClick(true)
    setSaveCommentClick(true)
  }, []);
  useEffect(() =>{
    setError(null);
    
    GetStudentsEvaluateLessonScore(id, studentLevel, lessonID)
    .then((response) => {
      response.data.map((x,i) =>{
          if(x.lessonName === decodeURIComponent(className) ){
            let payload = {
              extendStepId:x.extendStepId,
              explainStepId:x.explainStepId,
              exploreStepId:x.exploreStepId,
              evaluateStepId:x.evaluateStepId,
              engageStepId:x.engageStepId,
              userName:x.userName,
              lessonName:x.lessonName,
              engageStepComment:x.engageStepComment,
              engageStepMarks : x.engageStepMarks,
              evaluateStepComment : x.evaluateStepComment,
              evaluateStepMarks:  x.evaluateStepMarks,
              explainStepComment: x.explainStepComment,
              explainStepMarks: x.explainStepMarks,
              exploreStepComment:  x.exploreStepComment,
              extendStepComment: x.extendStepComment,
              extendStepMarks:x.extendStepMarks,
              totalDurtion:x.totalDurtion,
              retakesCount:x.retakesCount,
              totalScore:x.totalScore
            }
            setSpObject({
              userName:payload.userName,
              engageStep:payload.engageStepId,
              exploreStep:payload.exploreStepId,
              evaluateStep:payload.evaluateStepId,
              explainStep:payload.explainStepId,
              extendStep:payload.extendStepId,
              totalDurtion:payload.totalDurtion,
              retakesCount:payload.retakesCount,
              totalScore:payload.totalScore,
        
            })
            if(state === 1){
              setUpdateComment(payload.engageStepComment !== null ? payload.engageStepComment : "")
              setInputValue(payload.engageStepMarks !== null ? payload.engageStepMarks : "")
            }
            else if(state === 2){
              setUpdateComment(payload.exploreStepComment !== null ? payload.exploreStepComment : "")
              setInputValue("")
            }
            else if(state === 3){
              setUpdateComment(payload.evaluateStepComment !== null ? payload.evaluateStepComment : "")
              setInputValue(payload.evaluateStepMarks !== null ? payload.evaluateStepMarks : "")
            }
            else if(state === 4){
              setUpdateComment(payload.explainStepComment !== null ? payload.explainStepComment : "")
              setInputValue(payload.explainStepMarks !== null ? payload.explainStepMarks : "")
            }
            else if(state === 5){
              setUpdateComment(payload.extendStepComment !== null ? payload.extendStepComment : "")
              setInputValue(payload.extendStepMarks !== null ? payload.extendStepMarks : "")
            }
             
          }
          
      })
    })
  },[state])

  const getRefreshedValues =() =>{
    GetStudentsEvaluateLessonScore(id, studentLevel, lessonID)
    .then((response) => {
      response.data.map((x,i) =>{
          if(x.lessonName === decodeURIComponent(className) ){
            let payload = {
              extendStepId:x.extendStepId,
              explainStepId:x.explainStepId,
              exploreStepId:x.exploreStepId,
              evaluateStepId:x.evaluateStepId,
              engageStepId:x.engageStepId,
              userName:x.userName,
              lessonName:x.lessonName,
              engageStepComment: x.engageStepComment,
              engageStepMarks: x.engageStepMarks,
              evaluateStepComment: x.evaluateStepComment,
              evaluateStepMarks: x.evaluateStepMarks,
              explainStepComment : x.explainStepComment,
              explainStepMarks : x.explainStepMarks,
              exploreStepComment:  x.exploreStepComment,
              extendStepComment: x.extendStepComment,
              extendStepMarks:x.extendStepMarks,
              totalDurtion:x.totalDurtion,
              retakesCount:x.retakesCount,
              totalScore:x.totalScore
            }
            setSpObject({
             ...spObject,
              totalDurtion:payload.totalDurtion,
              retakesCount:payload.retakesCount,
              totalScore:payload.totalScore,
        
            })
          }
      })
    })
  }
    

  useEffect(() => {

    setSpObject({
      userName:payload2.userName,
      engageStep:payload2.engageStepId,
      exploreStep:payload2.exploreStepId,
      evaluateStep:payload2.evaluateStepId,
      explainStep:payload2.explainStepId,
      extendStep:payload2.extendStepId,
      totalDurtion:payload2.totalDurtion,
      retakesCount:payload2.retakesCount,
      totalScore:payload2.totalScore,

    })
    setUpdateComment(payload2.engageStepComment !== null ? payload2.engageStepComment : "")
    setInputValue(payload2.engageStepMarks !== null ? payload2.engageStepMarks : "")
    setSaveClick(true)
  }, []);

  function showAlert(message, success) {
    const alert = { showAlert: true, message: message, type: success ? "success" : "error" }
    setAlertData(alert);
    setTimeout(function () {
      hideAlert();
    }, 2000);
  }
  function toHoursAndMinutes(totalMinutes) {
    
    const minutes = parseInt(totalMinutes % 60);
    const hours = Math.floor(totalMinutes / 60);
  
    return `${hours}h${minutes > 0 ? ` ${minutes}m`  : ''}`;
  }

  
  function hideAlert() {
    var alert = { showAlert: false, message: "", type: "success" }
    setAlertData(alert);
  }

  function marksToClever(lessonId,classId,username,lessonLevel,action){
    try{
      updateMarksToClever(lessonId,classId,username,lessonLevel,action)
    }catch(e){
      console.log(e)
    }
  }

  function commentToClever(lessonId,classId,username,lessonLevel,action, step){
    try{
      updateCommentsToClever(lessonId,classId,username,lessonLevel,action, step)
    }catch(e){
      console.log(e)
    }
  }

  const submitComment=()=>{
   
    if(updateComment.trim().length > 0){
      if(state ===1){
        let submitPayload = {
          
            "comment":updateComment,
            "engage_step_id":spObject?.engageStep,
            "username":spObject?.userName
        
        }
        updateEngageComment(submitPayload).then((res)=>{
          if(res.status === 200){
            if(studentStatus === "CL" && lmsState){
              commentToClever(lessonID, payload2.classId, encodeURIComponent(spObject?.userName), payload2.lessonStepLevel, "comment", "Engage" )
            }
            let messages = [{type:'success',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
            setUpdateComment(res.data.comment)
         
            
          }
          else {
            let messages = [{type:'error',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
          }
        }).catch(err =>{
          let messages = [{type:'error',text: err.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
        })
      }
      else if(state ===2){
        let submitPayload = {
          
            "comment":updateComment,
            "explore_step_id":spObject?.exploreStep,
            "userName":spObject?.userName
        
        }
  
        updateExploreComment(submitPayload).then((res)=>{
          if(res.status === 200){
            if(studentStatus === "CL" && lmsState){
              commentToClever(lessonID, payload2.classId, encodeURIComponent(spObject?.userName), payload2.lessonStepLevel, "comment", "Explore" )
            }
            let messages = [{type:'success',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
            
          }
          else {
            let messages = [{type:'error',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
          }
        }).catch(err =>{
          let messages = [{type:'error',text: err.message}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
        })
      }
      else if(state ===3){
        let submitPayload = {
  
            "comment":updateComment,
            "evaluate_step_id":spObject?.evaluateStep,
            "username":spObject?.userName
        }
        updateEvaluateComment(submitPayload).then((res)=>{
          if(res.status === 200){
            if(studentStatus === "CL" && lmsState){
              commentToClever(lessonID, payload2.classId, encodeURIComponent(spObject?.userName), payload2.lessonStepLevel, "comment", "Evaluate" )
            }
            let messages = [{type:'success',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
            setUpdateComment(res.data.comment)
          
            
          }
          else {
            let messages = [{type:'error',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
          }
        }).catch(err =>{
          let messages = [{type:'error',text: err.message}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
        })
      }
      else if(state ===4){
        let submitPayload = {
  
  
            "comment":updateComment,
            "explain_step_id":spObject?.explainStep,
            "username":spObject?.userName
        }
        updateExplainComment(submitPayload).then((res)=>{
          if(res.status === 200){
            if(studentStatus === "CL" && lmsState){
              commentToClever(lessonID, payload2.classId, encodeURIComponent(spObject?.userName), payload2.lessonStepLevel, "comment", "Explain" )
            }
            let messages = [{type:'success',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
            setUpdateComment(res.data.comment)
           
            
          }
          else {
            let messages = [{type:'error',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
          }
        }).catch(err =>{
          let messages = [{type:'error',text: err.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
        })
      }
      else if(state ===5){
        let submitPayload = {
  
            "comment":updateComment,
            "extend_step_id":spObject?.extendStep,
            "username":spObject?.userName
        }
        updateExtendComment(submitPayload).then((res)=>{
          if(res.status === 200){
            if(studentStatus === "CL" && lmsState){
              commentToClever(lessonID, payload2.classId, encodeURIComponent(spObject?.userName), payload2.lessonStepLevel, "comment", "Extend" )
            }
            setUpdateComment(res.data.comment)
            let messages = [{type:'success',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
           
            
          }
          else {
            let messages = [{type:'error',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
          }
        }).catch(err =>{
          let messages = [{type:'error',text: err.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
        })
      }
    }
    
    setSaveCommentClick(true)
   // setUpdateComment("")

  }
  function hideAlert() {
    var alert = { showAlert: false, message: "",  }
    setAlertData(alert);
  }
  function isValid(maxValue) {
    if (inputValue.length === 0 || inputValue === "") {
      setError("Score is required")
      return false;
    }
    if (isNaN(inputValue)) {
      setError("Invalid score")
      return false;
    }
    let marks = parseInt(inputValue);

    let name = ""

    switch(state){
      case 1 :
        name = "Engage";
        break;
      case 2 :
        name = "Explore";
          break;
      case 3 :
        name = "Evaluate";
        break;
      case 4 :
        name = "Explain";
        break;
      case 5 :
        name = "Extend";
        break;
      default:
        name = "";
    }

    if (marks < 0) {
      setError("Score must be grater than 0")
      return false;
    }

    if (marks > maxValue) {
      setError(`${name} step score cannot be more than ${maxValue}`)
      return false;
    }
    // setError(null);
    return true;
  }
  const onMarkChange = (e) => {
    setSaveClick(false)
    setInputValue(e.target.value)
  }
  const onSubmitAnswers = (maxValue) => {
    setSaveClick(true);
    setLoading(true)
    let valid = isValid(maxValue);
    if (!valid) {
      setLoading(false);
    }
    else {
      setError(null);
      //setLoading(true)
      if (state === 1) {
        RestService.postEngageMarks(
          {
            "marks": parseInt(inputValue),
            "engage_step_id": spObject?.engageStep,
            "username": spObject?.userName
          }
        ).then(res => {
          if (res?.status === 200) {
            if(studentStatus === "CL" && lmsState){
               marksToClever(lessonID, payload2.classId, encodeURIComponent(spObject?.userName), payload2.lessonStepLevel, "marks" )
            }
            getRefreshedValues();
            let messages = [{type:'success',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });

             setInputValue(res.data.marks)
            //props.onClose()
          }
          else {
            let messages = [{type:'error',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });

          }
        }).catch(err =>{
          // console.log("err",err)
          let messages = [{type:'error',text: "No records"}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });

        }).finally(() => {
          //hideAlert();
         setLoading(false);
        });
      }
      else if (state === 3) {
        RestService.postEvaluateMarks(
          {
            "marks": parseInt(inputValue),
            "evaluate_step_id": spObject?.evaluateStep,
            "username": spObject?.userName
          }
        ).then(res => {
          if (res?.status === 200) {
            if(studentStatus === "CL" && lmsState){
               marksToClever(lessonID, payload2.classId, encodeURIComponent(spObject?.userName), payload2.lessonStepLevel, "marks" )
            }
            getRefreshedValues();
            let messages = [{type:'success',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });

            setInputValue(res.data.marks)

            // setClick(false)
            // setDisabled(true)
            //props.onClose()
          }
          else {
            let messages = [{type:'error',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });

          }
        }).catch(err =>{
          let messages = [{type:'error',text: "No records"}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });

        }).finally(() => {
          setLoading(false);
        });

      }
      else if (state === 4) {
        RestService.postExplainMarks(
          {
            "marks": parseInt(inputValue),
            "explain_step_id": spObject?.explainStep,
            "username": spObject?.userName
          }
        ).then(res => {
          if (res?.status === 200) {
            if(studentStatus === "CL" && lmsState){
               marksToClever(lessonID, payload2.classId, encodeURIComponent(spObject?.userName), payload2.lessonStepLevel, "marks" )
            }
            getRefreshedValues();
            let messages = [{type:'success',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });

            setInputValue(res.data.marks)
            // setClick(false)
            // setDisabled(true)
            //props.onClose()
          }
          else {
            let messages = [{type:'error',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });

          }
        }).catch(err =>{
          let messages = [{type:'error',text: "No records"}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });

        }).finally(() => {
          setLoading(false);
        });

      }
      else if (state === 5) {
        RestService.postExtendMarks(
          {
            "marks": parseInt(inputValue),
            "extend_step_id": spObject?.extendStep,
            "username": spObject?.userName
          }
        ).then(res => {
          if (res?.status === 200) {
            if(studentStatus === "CL" && lmsState){
               marksToClever(lessonID, payload2.classId, encodeURIComponent(spObject?.userName), payload2.lessonStepLevel, "marks" )
            }
            getRefreshedValues();
            let messages = [{type:'success',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });

            setInputValue(res.data.marks)

            // setClick(false)
            // setDisabled(true)
            //props.onClose()
          }
          else {
            let messages = [{type:'error',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });

          }
        }).catch(err =>{
          let messages = [{type:'error',text: "No records"}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });

        }).finally(() => {
          setLoading(false);
        });

      }
    }
  }
  
  const updateTabBackground = (key) => {
    const tabs = document.querySelectorAll(".ant-tabs-tab");
    const tabback = document.querySelectorAll(".ant-tabs-nav-list");
    const pageback = document.querySelectorAll(".page-back");

    let res ={};
    setState(parseInt(key))

    
    
    if (key == 1) {
      tabs[key - 1].classList.add("engage-tab");
    }
    // setUpdateComment("")
    // setInputValue("")
    
    
    setTimeout(() => {
      if (key == 1) {
        //setUpdateComment(res.engageStepComment !== null ? res.engageStepComment : "")
        //setInputValue(res.engageStepMarks !== null ? res.engageStepMarks : "")
        tabs[key - 1].classList.add("engage-tab");
        tabback[key - 1].classList.add("engage-tab-back");
        pageback[key - 1].classList.add("engage-tab-back");
        tabback[key - 1].classList.remove("explore-tab-back");
        pageback[key - 1].classList.remove("explore-tab-back");
        tabback[key - 1].classList.remove("evaluate-tab-back");
        pageback[key - 1].classList.remove("evaluate-tab-back");
        tabback[key - 1].classList.remove("explain-tab-back");
        pageback[key - 1].classList.remove("explain-tab-back");
        tabback[key - 1].classList.remove("expand-tab-back");
        pageback[key - 1].classList.remove("expand-tab-back");
      } else if (key == 2) {
       // setUpdateComment(res.exploreStepComment !== null ? res.exploreStepComment : "")
    //setInputValue("")
        tabs[key - 1].classList.add("explore-tab");
        tabback[key - 2].classList.add("explore-tab-back");
        pageback[key - 2].classList.add("explore-tab-back");
        tabback[key - 2].classList.remove("engage-tab-back");
        pageback[key - 2].classList.remove("engage-tab-back");
        tabback[key - 2].classList.remove("evaluate-tab-back");
        pageback[key - 2].classList.remove("evaluate-tab-back");
        tabback[key - 2].classList.remove("explain-tab-back");
        pageback[key - 2].classList.remove("explain-tab-back");
        tabback[key - 2].classList.remove("expand-tab-back");
        pageback[key - 2].classList.remove("expand-tab-back");
      } else if (key == 3) {
        //setUpdateComment(res.evaluateStepComment !== null ? res.evaluateStepComment : "")
    //setInputValue(res.evaluateStepMarks !== null ? res.evaluateStepMarks : "")
        tabs[key - 1].classList.add("evaluate-tab");
        tabback[key - 3].classList.add("evaluate-tab-back");
        pageback[key - 3].classList.add("evaluate-tab-back");
        tabback[key - 3].classList.remove("engage-tab-back");
        pageback[key - 3].classList.remove("engage-tab-back");
        tabback[key - 3].classList.remove("explore-tab-back");
        pageback[key - 3].classList.remove("explore-tab-back");
        tabback[key - 3].classList.remove("explain-tab-back");
        pageback[key - 3].classList.remove("explain-tab-back");
        tabback[key - 3].classList.remove("expand-tab-back");
        pageback[key - 3].classList.remove("expand-tab-back");
      } else if (key == 4) {
        //setUpdateComment(res.explainStepComment !== null ? res.explainStepComment : "")
   // setInputValue(res.explainStepMarks !== null ? res.explainStepMarks : "")
        tabs[key - 1].classList.add("explain-tab");
        tabback[key - 4].classList.add("explain-tab-back");
        pageback[key - 4].classList.add("explain-tab-back");
        tabback[key - 4].classList.remove("evaluate-tab-back");
        pageback[key - 4].classList.remove("evaluate-tab-back");
        tabback[key - 4].classList.remove("explore-tab-back");
        pageback[key - 4].classList.remove("explore-tab-back");
        tabback[key - 4].classList.remove("engage-tab-back");
        pageback[key - 4].classList.remove("engage-tab-back");
        tabback[key - 4].classList.remove("expand-tab-back");
        pageback[key - 4].classList.remove("expand-tab-back");
      } else if (key == 5) {
       // setUpdateComment(res.extendStepComment !== null ? res.extendStepComment : "")
    //setInputValue(res.extendStepMarks !== null ? res.extendStepMarks : "")
        tabs[key - 1].classList.add("expand-tab");
        tabback[key - 5].classList.add("expand-tab-back");
        pageback[key - 5].classList.add("expand-tab-back");
        tabback[key - 5].classList.remove("evaluate-tab-back");
        pageback[key - 5].classList.remove("evaluate-tab-back");
        tabback[key - 5].classList.remove("explore-tab-back");
        pageback[key - 5].classList.remove("explore-tab-back");
        tabback[key - 5].classList.remove("explain-tab-back");
        pageback[key - 5].classList.remove("explain-tab-back");
        tabback[key - 5].classList.remove("engage-tab-back");
        pageback[key - 5].classList.remove("engage-tab-back");
      }
    }, 0);
  };

  const [bodyCountExplain, setBodyCountExplain] = useState(0);
  const [bodyCountExtend, setBodyCountExtend] = useState(0);


  let intervalId = setInterval(function() {
    const value1 = localStorage.getItem("bodyCountExplain")
    const value2 = localStorage.getItem("bodyCountExtend")

    setBodyCountExplain(value1)
    setBodyCountExtend(value2)
  }, 2000);
  
  setTimeout(function() {
    clearInterval(intervalId);
  }, 5000);


  const onWheelFunction  = (e) => {
    e.target.blur();
  }
// console.log(spObject)

//activating Lessons
useEffect(()=>{
   setLoading(true);
   const requests = [
     RestService.getEngageStepByIdAndUserName(spObject?.userName, spObject?.engageStep),
     RestService.getExploreStepUserStatusByIdAnduserName(spObject?.userName, spObject?.exploreStep),
     RestService.getEvaluateStepInfoByEvaluateStepId(spObject?.evaluateStep, spObject?.userName, spObject?.exploreStep),
     RestService.getExplainStepInfosById(spObject?.explainStep, spObject?.userName, spObject?.exploreStep),
     RestService.getExtendStepByIdAndUserName(spObject?.userName, spObject?.extendStep)
   ];
 
   Promise.all(requests.map(p => p.catch(e => e))) 
     .then((responses) => {
       responses.forEach((response, index) => {
         setLessonAct(prevState => ({
           ...prevState,
           [index + 1]: !response
         }));
       });
     })
     .catch(error => {
       console.error("Error fetching data: ", error);
     })
     .finally(() => {
       setLoading(false);
     });
},[spObject]);

  return (
    <>
    <Spin tip="Loading..." spinning={loading} size="large">
      <div className="container">
      <Row className="py-3">
          <Col span={12} offset={6}>
            <center>
              <h3>{decodeURIComponent(name)}</h3>
              <p className="bold-para">
              {decodeURIComponent(className)}
              </p>
            </center>
          </Col>
        
        </Row>
        <hr  className="hr-dark"/>
        <Row className="py-3">
            <Col md={6} >
              <ArrowLeftOutlined className=" mr-2 back-btn-hover" onClick={() =>{history.goBack()}} />
              Go Back
            </Col>
            <Col xs='6'>
           
              <div className="">
                <span className="mr-3">Total Score : <b>{spObject?.totalScore}</b></span>
                <span className="mr-3">No. Retakes : <b>{spObject?.retakesCount}</b></span>
                <span className="mr-3">Time Duration : <b> {toHoursAndMinutes(spObject?.totalDurtion !== null &&spObject?.totalDurtion )}</b></span>
              </div>
            </Col>
        
          </Row>
      </div>
        <div className="container-fluid page-back p25r">
          <div className="content">
            <Row>

            </Row>
            <Row>
              <Col md="12" className="ltab evtab override-FormFeedback" >
                <Tabs
                  defaultActiveKey="1"
                  onChange={callback}
                  className="lesson-tab"
                >
                  <TabPane tab={lessonAct["1"] ? (
                      <span>
                        <Tooltip title="Student has not answer yet!">
                            Engage
                        </Tooltip>
                      </span>
                    ) : "Engage"} key="1" className="engage" disabled={lessonAct["1"]}>
                    <Engage engageId={spObject?.engageStep} user={spObject?.userName} disabled={false}  />
                  </TabPane>
                  <TabPane tab={lessonAct["2"] ? (
                      <span>
                        <Tooltip title="Student has not answer yet!">
                        Explore
                        </Tooltip>
                      </span>
                    ) : "Explore"} key="2" className="explore" disabled={lessonAct["2"]}>
                    <ExploreMain exploreId={spObject?.exploreStep} user={spObject?.userName} explainId={spObject?.explainStep} />
                  </TabPane>
                  <TabPane tab={lessonAct["3"] ? (
                      <span>
                        <Tooltip title="Student has not answer yet!">
                        Evaluate
                        </Tooltip>
                      </span>
                    ) : "Evaluate"} key="3" className="evaluate" disabled={lessonAct["3"]}>
                    <Evaluate evaluateId={spObject?.evaluateStep} user={spObject?.userName} exploreId={spObject?.exploreStep} disabled={false}  />
                  </TabPane>
                  <TabPane tab={lessonAct["4"] ? (
                      <span>
                        <Tooltip title="Student has not answer yet!">
                          Explain
                        </Tooltip>
                      </span>
                    ) : "Explain"} key="4" className="explain" disabled={lessonAct["4"]}>
                    <Explain explainId={spObject?.explainStep} user={spObject?.userName} exploreId={spObject?.exploreStep} disabled={false} />
                  </TabPane>
                  <TabPane tab={lessonAct["5"] ? (
                      <span>
                        <Tooltip title="Student has not answer yet!">
                          Extend
                        </Tooltip>
                      </span>
                    ) : "Extend"} key="5" className="expand" disabled={lessonAct["5"]}>
                    <Extend extendId={spObject?.extendStep} user={spObject?.userName} exploreId={spObject?.exploreStep} disabled={false} />
                  </TabPane>
                </Tabs>
                <br/>
                <br/>
                <Row>
              <Col md="3"></Col>
              <Col lg='6' className="py-2 text-center">
                {alertData.showAlert ?
                  <Alert message={alertData.message} type={alertData.type}
                    action={
                      <a type="text" onClick={hideAlert}>
                        x
                      </a>
                    } /> : ""}
              </Col>
            </Row>
            { state !== 2 &&
              <Row >
              <Col md="6">
            <div id ="marks" >
              <label>Score </label>
            <Input
                        id={state === 4 ? `scoreExplain` : state === 5 ? `scoreExtend` : `scoreOthers`}
                        className="form-control form-control-lg"
                        placeholder="Score"
                        onInput={onMarkChange}
                        min="0"
                        value={inputValue}
                        required={true}
                        type="number"
                        invalid={!!error}
                        onWheel={onWheelFunction}
                      />
            </div>
                      <FormFeedback>{error} </FormFeedback>
              </Col>
              <Col md="6">
              <Button disabled={saveClick} color="success" className="mt--25" onClick={()=>{onSubmitAnswers(state === 4 ? 50 : 25)}} >Add Score</Button>
              </Col>
              </Row>
            }
            <Row> 
            <Col md="12">
                <div id="com" >
                  <label id={state === 4 ? `InstructorHeaderExplain` : `InstructorHeaderExtend`}>
                    Comment
                  </label>
                  <TextArea
                
                  id={state === 4 ? `InstructorContentExplain` : `InstructorContentExtend`}
                placeholder="Comment" className="form-control form-control-lg" value={updateComment} rows={4} onChange={(e) => {
                  setUpdateComment(e.target.value);
                  if(e.target.value !== ""){
                    setSaveCommentClick(false);
                  }else{
                    setSaveCommentClick(true);
                  }
                }}  />
                </div>
                </Col>
                </Row>
                <br/>
                <div >
                <Button color="success" disabled={saveCommentClick } onClick={submitComment} >Add Comment</Button>
                </div>
                {
                  state !== 1 && state !== 2 && state !== 3 &&
                  <span className="pull-right">
                <Pdf 
                  from="teacher"
                  data = {state === 4 ?{
                    header : "headerDescriptionExplain",
                    description : "descriptionExplain",
                    guideline:"guidelineExplain",
                    body: "bodyDescriptionExplain",
                    graph: "pdfGeneration",
                    bodyCount:bodyCountExplain,
                    marks: inputValue,
                    comment:updateComment
                    
                  } :{
                    header: "headerDescriptionExtend",
                    description: "descriptionExtend",
                    guideline:"guidelineExtend",
                    body: "bodyDescriptionExtend",
                    bodyCount:bodyCountExtend,
                    marks: inputValue,
                    comment:updateComment
                  }
                }
                  uploadBtnFun={(e)=>{

                  }}
                  headingData ={{
                        lessonName: className,

                        btnStatus:state === 4 ? "Explain":"Extend",
                        status: true
                          }}/> 
                </span>
                }
              
                <br/><br/>
              </Col>
            
            </Row>
          </div>
        </div>
    </Spin>
    </>
  );
}

export default StudentPage;
