import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import logo from "../../assets/images/logo.svg";
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
} from "reactstrap";

import { closeSidebar, openSidebar } from "../../actions/navigation";
import MenuIcon from "../Icons/HeaderIcons/MenuIcon";
import SearchIcon from "../Icons/HeaderIcons/SearchIcon";

import userImg from "../../assets/james.jpg";

import s from "./Header.module.scss";
import "animate.css";

const Header = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [rolePermission , setRolePermission] = useState(false)

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSidebar = () => {
    if (props.sidebarOpened) {
      props.dispatch(closeSidebar());
    } else {
      const paths = props.location.pathname.split("/");
      paths.pop();
      props.dispatch(openSidebar());
    }
  };

  useEffect(()=>{
    if(props.role === "STUDENT"){
      setRolePermission(true);
    }
    // else if(props.role === "SUPER_ADMIN"){
    //   setRolePermission(true);
    // }
    else{
      setRolePermission(false);
    }
  },[props.role])
  console.log(props.count)
  return (
    <>
        <Navbar className={`${s.root} d-print-none`}>
       <img className="logo" src={logo} />
    </Navbar>
       {
        (rolePermission && 
        props.count !== undefined && 
        props.count !== 0 && props.count)?<div className="notification-bubble"><span class="notranslate">{props.count}</span></div>:("")
        // <div className="notification-bubble"> {props.count !== undefined && props.count !== 0 && props.count} </div> 
       }
    </>

  );
};

export default Header;
