import React from 'react'
import Answers from './firstTypeQuestion';
import FourthTypeQuestion from './fourthTypeQuestion';
import SecondTypeQuestion from './secondTypeQuestion';
import ThirdTypeQuestion from './ThirdTypeQuestion';
/*
case 1: multiple choice question with images if exist 
case 2: short answer question without images
case 3: used to display description and instruction for the explore tab 
case 4: short answer question with images 
*/
export default function AnswerDisplay(props) {
  // console.log(props)
 let element = null;
 switch(props.data.type){
    case 1:
        element = 
        <Answers question ={props.data.question} answers = {props.data.answers}  preData = {props.data.preData} getData = {props.data.getData} className={props.className} reviewStatus={props.data.reviewStatus} subtype={props.data.subtype}/>
          break;

    case 2:
        
        element =
        <SecondTypeQuestion  refPass={props?.refPass} from = {props.data.from} data = {props.data.payload} changeHandler ={props.data.changeHandler} containedPayload ={props.data.containedPayload}  /> //freezingStatus = {props.data.freezingStatus} use this to bring back the freezing feature
          break;

    case 3:
      element =
        <div style={{paddingBottom:"4px"}}>{
           props.data.videoEnd &&

          <ThirdTypeQuestion refPass={props?.refPass} canvasDisable={props.canvasDisable} canvasBtnDisable={props.canvasBtnDisable} onNextValue={props.onNextValue} onNextValue2={props.onNextValue2} onNextValue3={props.onNextValue3} onNextValue4={props.onNextValue4} onNextValue5={props.onNextValue5} data= {props.data}/>
        }</div>
         break;

    case 4:
      element  = <FourthTypeQuestion data= {props.data.payload} changeHandler ={props.data.changeHandler} containedPayload ={props.data.containedPayload}  freezingStatus = {props.data.freezingStatus}/>
      break;
 }

  return (
    <div>
        {element}
    </div>
  )
}
