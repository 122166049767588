import React, { useContext, useEffect, useState } from "react";
import "antd/dist/antd.css";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message, notification, Space } from "antd";
import { Badge, Col, Row } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import TextArea from "antd/lib/input/TextArea";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import {
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import LessonService from "../../../../api/LessonService";
import outputModals from "./modals";
import { EngageContext } from "../Engage/EngageContext";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
const EvaluateAnswer = (props) => {
  const [checked, setChecked] = useState(false);
  const [val, setVal] = useState([]);
  const [editorState, setEditorstate] = useState();
  const { value, setValue } = useContext(EngageContext);
  const [disabled, setDisabled] = useState(false);
  const [addAnswers, setAddAnswers] = useState(false);
  const [afterAdd, setAfterAdd] = useState(false);
  const [fields, setFields] = useState([
    {
      key: 0,
      description: "Wheat Flour",
      amount: 1000,
    },
    {
      key: 1,
      description: "Sugar",
      amount: 800,
    },
    {
      key: 2,
      description: "Sal",
      amount: 800,
    },
  ]);

  const onFinish = (values) => {
    let arr = [];

    if (values.answers && values.answers.length > 0) {
      values.answers.map((item, i) => {
        if (item !== undefined) {
          let obj = {
            answer: item.answer,
            answerNumber: i + 1,
            question_id: props.questionData?.id,
            description: item?.description,
            is_correct_answer: item?.correctAnswer === true ? 1 : 0,
            evaluateStepId: props.evaluate_id,
            id: item.id ? item.id : 0,
          };
          arr.push(obj);
        }
      });

      let obj2 = {
        id: props.questionData?.id,
        evaluateStepQuestionAnswer: arr,
      };
      props.getLoader(true);
      LessonService.addEvaluateQuestionAnswers(obj2)
        .then((res) => {
          if (res.status == 200) {
            //message.success(res.message);
            let messages = [{type:'success',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
          props.clearForm();

           // setValue(true);
            setDisabled(true);
          } else {
            let messages = [{type:'error',text: res.message}]
            notification.open({
              placement: 'top',
              duration:"2",
              description: [
                  messages.map((item) => {
                      if(item.type === 'success') {
                          return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'error') {
                          return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                      if(item.type === 'warning') {
                          return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                      }
                  })
              ],
      
              onClick: () => {
                  console.log('Notification Clicked!');
              },
          });
            setValue(false);
          }
        })
        .catch((e) => {
          let messages = [{type:'error',text:e.message}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
        })
        .finally(()=>{
          props.getLoader(false)
        })
    }
  };
  const [form] = Form.useForm();
  useEffect(() => {
    if (props.QuestionAnswers !== false) {
      let values = [];
      props?.QuestionAnswers?.map((i) => {
        values.push(i.answer);
      });

      setVal(values);
      setDisabled(true);
      setAfterAdd(props.saveClick);
    }
    setFields();

    form.setFieldsValue({ answers: props.QuestionAnswers });
    if (props.QuestionAnswers?.length === 0) {
      setValue(false);
      setDisabled(true);
    } else {
      setValue(true);
      setDisabled(true);
    }
    setEditorstate();
    setAddAnswers(false);
    setAfterAdd(props.saveClick); 
  }, [props]);
  const onChange = (e) => {
    setAddAnswers(true);
    setChecked(e.target.checked);
    setAfterAdd(true);
  };

  const onChangeAnswer = (editorState) => {
    setEditorstate(editorState);
  };
  return (
    <Form
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      layout="vertical"
      onChange={(values) => {
        setDisabled(false);
        setAddAnswers(true);
        setAfterAdd(true);
      }}
    >
      <Form.List name="answers">
        {(fields, { add, remove }) => (
          <>
            <Row className="question-headder-style stick-top">
              <Col xs="6" className="">
                <b>Answers</b>
              </Col>
              <Col xs="6">
                {props.view === false && afterAdd && (
                  <div className="text-right">
                    <Badge
                      color="light"
                      className="p-2 text-dark"
                      onClick={() => {
                        add();
                        setAddAnswers(true);
                      }}
                    >
                      Add New Answer
                    </Badge>
                  </div>
                )}
              </Col>
            </Row>
            {fields.map(({ key, name, ...restField }) => (
              <>
                <Form.Item
                  {...restField}
                  name={[name, "answer"]}
                  //label="Answer {}"
                  label={"Answer"}
                  // rules={[{ required: true, message: "Answer is required" }]}
                  rules={[
                    // { required: true, message: "Answer is required" },
                    {
                      required: true,
                      validator: (_, value) => {
                        if(value === undefined || value === null){
                          return Promise.reject("Answer is required");
                        }else{
                          const cleanedValue = value.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;| /g, '').trim();
                          if (cleanedValue.length === 0) {
                            return Promise.reject("Answer is required");
                          }
                        }
                        
                        return Promise.resolve();
                      },
                    },
                  ]}
                >

<SunEditor
disable={props.disable}
       setContents={form.getFieldsValue().answers[name]?.answer ?? ""}
       showToolbar={true}
       setDefaultStyle="height: 150px"
       setOptions={{
           "katex": "window.katex",
           "imageUploadSizeLimit": "5000000",
         buttonList: [
           [
            "undo",
            "redo",
            //"font",
            "fontSize",
            "formatBlock",
            "paragraphStyle",
            "blockquote",
            "bold",
            "underline",
            "italic",
            "strike",
            "hiliteColor",
            "subscript",
            "superscript",
            "math",
            "removeFormat",
            "horizontalRule",
            // "lineHeight",
            //"strike",
            "list",
            "outdent",
            "indent",
            "align",
            "table",
            "image",
            "codeView",
           // "preview",
           // "print",
            //"save",
            //"template",
             ],
         ]
       }}
       onChange={(e) =>{
        setDisabled(false);
        setAddAnswers(true);
       setAfterAdd(true);
       }}
     />
                {/* <FormFeedback> </FormFeedback> */}
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "description"]}
                  label={"Feedback"}
                  
                >
                  {/* <TextArea rows={4} placeholder=""    /> */}
                  {/* <Editor
                    readOnly={props.view}
                    contentState={
                      form.getFieldsValue().answers[name]?.description ??
                      undefined
                    }
                    editorClassName="demo-editor"
                    onEditorStateChange={(edito) => {
                      setDisabled(false);
                      setAddAnswers(true);
                      setAfterAdd(true);
                    }}
                  /> */}

<SunEditor
disable={props.disable}
       setContents={form.getFieldsValue().answers[name]?.description ??
        ""}
       showToolbar={true}
       setDefaultStyle="height: 150px"
       setOptions={{
           "katex": "window.katex",
           "imageUploadSizeLimit": "5000000",
         buttonList: [
           [
            "undo",
            "redo",
            //"font",
            "fontSize",
            "formatBlock",
            "paragraphStyle",
            "blockquote",
            "bold",
            "underline",
            "italic",
            "strike",
            "hiliteColor",
            "subscript",
            "superscript",
            "math",
            "removeFormat",
            "horizontalRule",
            // "lineHeight",
            //"strike",
            "list",
            "outdent",
            "indent",
            "align",
            "table",
            "image",
            "codeView",
           // "preview",
           // "print",
            //"save",
            //"template",
             ],
         ]
       }}
       onChange={(e) =>{
        setDisabled(false);
        setAddAnswers(true);
       setAfterAdd(true);
       }}
     />
                {/* <FormFeedback> </FormFeedback> */}
                </Form.Item>
                <Row>
                  <Col xs="6">
                    <Form.Item
                      {...restField}
                      name={[name, "correctAnswer"]}
                      valuePropName="checked"
                    >
                      <Checkbox
                        disabled={props.view}
                        value={checked ?? false}
                        onChange={onChange}
                      >
                        Correct Answer
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs="6">
                    {props.view === false && afterAdd && (
                      <div className="text-right">
                        <Badge
                          color="danger"
                          className="p-2"
                          onClick={() => {
                            remove(name);
                            setAddAnswers(true);
                            setDisabled(false);
                          }}
                        >
                          Remove This
                        </Badge>
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ))}
          </>
        )}
      </Form.List>
      <Form.Item>
        <Row>
          <Col xs="12">
            {props.view === false && addAnswers && (
              <button
                disabled={disabled}
                class="btn btn-success btn-sm btn-block"
                type="primary"
                htmlType="submit"
                block
              >
                Add to List
              </button>
            )}
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default EvaluateAnswer;
