import Config from "./Config";
import axios from "axios";
import { useState } from "react";
import jsonToken from 'jsonwebtoken'

export const AddNewAccount = async (data) => {
  let token = localStorage.getItem("access_token");
  const response = await axios.post(Config.APIURL + `admin/account/createNewAccount`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    // console.log("st in class", data);
    return data;
  })
    .catch((err) => {
      console.log("error", err.response?.data ? err.response?.data : err)
      return Promise.reject(err.response?.data ? err.response?.data : err);
    })

  return response;
};

// admin/account/updateAccount

export const UpdateAccount = async (payload) => {
  let token = localStorage.getItem("access_token");
  const response = await axios.post(Config.APIURL + `admin/account/updateAccount`, payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    },

  })
    .then((res) => {
      const data = res.data;
      // console.log("st in student post ", res.body);
      return data;
    })
    .catch((err) => {
      console.log("error", err.response?.data ? err.response?.data : err)
      return Promise.reject(err.response?.data ? err.response?.data : err);
    })

  return response;
};



// export const GetAccountById = async () => {
//     let token = localStorage.getItem("access_token");
//       const response = await axios.post(Config.APIURL +`admin/account/getAccountsByUserId`,{
//         headers:{
//       "Authorization": `Bearer ${token}`,
//       "Content-Type": "application/json" 
//    },

//       })
//       .then((res) => {
//         // const data = res.data;
//         // console.log("st in student post ", res.body);
//         return res;
//       })
//       .catch((error) => console.log("error", error));

//     return response;
//     };



export const GetAccountData = async () => {

  let token = localStorage.getItem("access_token");
  return axios.get(Config.APIURL + 'admin/account/getAccountsByUserId', { headers: { "Authorization": `Bearer ${token}` } })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      return ("Server Error: ", err);
    })
  // .then((res) => {
  //   const data = res.data;
  //   // console.log("st in account", data);
  //   return data;
  // })
  // .catch((error) => console.log("error", error));

  // return response;
};

let abortController = new AbortController();


export const GetAccountDataPaged = async (page, size, search = "") => {

  let token = localStorage.getItem("access_token");
  const encodedSearch = encodeURIComponent(search);

  // Cancel the previous request
  abortController.abort(); 
  // Create a new controller for the new request
  abortController = new AbortController(); 

  return axios.get(Config.APIURL + `admin/account/getAccountsByUserId_paged?page=${page}&size=${size}&search=${encodedSearch}`, 
  { 
    headers: { "Authorization": `Bearer ${token}` },
    signal: abortController.signal // Pass the abort signal
  })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      return ("Server Error: ", err);
    })
  // .then((res) => {
  //   const data = res.data;
  //   // console.log("st in account", data);
  //   return data;
  // })
  // .catch((error) => console.log("error", error));

  // return response;
};


//admin/curriculam/createCurriculam

export const AddNewCurriculam = async (data) => {
  let token = localStorage.getItem("access_token");
  const response = await axios.post(Config.APIURL + `admin/curriculam/createCurriculam`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then((res) => {
    const data = res.data;
    // console.log("st in class", data);
    return data;
  })
    .catch((err) => {
      console.log("error", err.response?.data ? err.response?.data : err)
      return Promise.reject(err.response?.data ? err.response?.data : err);
    })

  return response;
};