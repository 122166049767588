import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
 

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const  options = {
    maintainAspectRatio: false,
    responsive:true,
    plugins: {
        legend: {
            display: false,
        },
    },
  }


const colors = ["#A56ABE","#5599C8","#F4D03E","#58D68D","#5C6D7D","#EC7064","#A56ABE","#5599C8","#F4D03E","#58D68D","#5C6D7D","#EC7064"]


export function BarChartMultiClick(props) {
  if(props?.chartData?.type === 1){
    let labels = props.chartData.labels
    
      const payload = []
      props.chartData.datasets.map(x=>{
        if(x !== undefined){
          payload.push(x)
        }
      })

      if(props?.chartData !== undefined || props?.chartData !== null){
        let lengthCount = [];
        let labelArray = [];
        
        props?.chartData?.datasets.map((x)=>{
          if(x?.yData !== undefined){
            lengthCount.push(x.xData[x.xData.length-1])
          }
        })
       const maxValue =  Math.max(...lengthCount)
       for(let i=1;i<=maxValue;i++){
          labelArray.push(i)
        }

        if(props.data.labels.length <= labelArray.length ){
          labels = labelArray
        }
      }

      const values = {
        labels :labels,
        datasets:payload.filter((ele, ind) => ind === payload.findIndex( elem => elem.label === ele.label  )).map((item, i) =>{
            return {
                label:item.label  === "undefined" || item.label === null ? "" : item.label,
                borderColor: colors.map( i => i),
                backgroundColor: item.borderColor === "black" ? colors.map( i => i) : item.borderColor || colors.map( i => i),
                borderWidth:"3",
                data:item.label  === "undefined" ? []: item.yData
            }
        })
      }
  
      
    return <Bar options={options} data={values} responsive="true"  height={200}/>;
  }else{
    
    let labels2 = props.data.labels;
    
    const payload = []
    props.chartData.map(x=>{
      if(x !== undefined){
        payload.push(x)
      }
    })

    if(props?.chartData !== undefined || props?.chartData !== null){
        let lengthCount = [];
        let labelArray = [];
        props?.chartData.map((x)=>{
          if(x?.yData !== undefined){
            lengthCount.push(x.xData[x.xData.length-1])
          }
        })
       const maxValue =  Math.max(...lengthCount)

       for(let i=1;i<=maxValue;i++){
          labelArray.push(i)
        }

        if(props.data.labels.length <= labelArray.length ){
          labels2 = labelArray
        }
      }
      const values = {
        labels :labels2,
        datasets:payload.map((item, i) =>{
            return {
                label:item.labels === "undefined" || item.labels === null ? "" : item.labels,
                borderColor: colors.map( i => i),
                backgroundColor: item.borderColor || colors.map( i => i),
                borderWidth:"3",
                data:item.label  === "undefined" ? []: item.xyData.map((value, index) => ({ x: parseFloat(item.xData[index]), y: parseFloat(item.yData[index]) })),
            }
        })
      }
    
    return <Bar options={options} data={values} responsive="true"  height={200}/>;

  }
    
}

 