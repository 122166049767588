import { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Row,
  Col,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import "antd/dist/antd.css";
//import Bootbox from "bootbox-react";
import { Input, Form, Tabs, Select, Image, Checkbox, Spin, Radio } from "antd";
 
import MapChild from "./childComponent/map";
import ReactJsAlert from "reactjs-alert";
import RestService from "../../../../../api/RestService";
import MathCalculationService from "../../../../../components/Calculation/MathCalculation"
import jsonToken from 'jsonwebtoken'
import ChartValidations from "../../../../LessonLab/Explore/childComponent/ChartValidations";
import AnswerDisplay from "../../../../LessonLab/AnswerDisplay";
import CordinateDisplayBox from "../../../../LessonLab/Explore/childComponent/CordinatedBoard";
import { useDispatch } from "react-redux";
import { addToTable } from "../../../../../reducers/graphDataSlice";
import { getExploreStepByLessonIdAndLevel } from "../../../../../api/TeacherService";

const buttonData = [
  { name: "ladybug", x: 0, y: 0 },
  { name: "grasshopper", x: 0, y: 0 },
  { name: "pillBug", x: 0, y: 0 },
  { name: "scarab", x: 0, y: 0 },
  { name: "aphid", x: 0, y: 0 },
];


function ExploreMain(props) {

  const dispatch = useDispatch();

  const [canvasDisableSetter, setCanvasDisableSetter] = useState(true);
  const [canvasBtnDisableSetter, setCanvasBtnDisableSetter] = useState(true);

  const [activator, setActivator] = useState(0);
  const [btnClickValueStore, setBtnClickValueStore] = useState([]);
  const [randomGenBtn, setRandomGenBtn] = useState([]);
  const [weights, setWeights] = useState([5, 5, 5, 5, 5]);


  const [HardCodedValue, setHardCodedValue] = useState([]);
  const [initialValues, setInitialValues] = useState([]);
  const [ffixedValueCurveGraph, setFfixedValueCurveGraph] = useState([]);
  const [EarthquakeDataValues, setEarthquakeDataValues] = useState([]);
  const [pPhaseChangeIdea, setPPhaseChangeIdea] = useState([]);
  const [gGasVolPressure, setGGasVolPressure] = useState([]);
  const [BarValueList, setBarValue] = useState([]);

  const [initPayload, setInitPayload] = useState(
    {
      exploreStepExperiment: [],
      hypothesis: [],
      exploreStepInfoInstructions: [],
      exploreStepQuestionAnswers: [],
      exploreStepQuestions: [],
      hypotheisOptions: [],
      description: [],
      id: "",
      lesson_id: "",
      lessonCode: "",
      chartType: "",
      guideline: "",
    });


  const [dataTableValue, setDataTableValue] = useState(
    [{
      value: "",
      si: ""
    }]
  )

  let ctx = null;
  const [initialValue, setInitialValue] = useState([]);
  const [screenShot, setScreenShot] = useState([]);
  const [initialValueY, setInitialValueY] = useState(0);
  const [initialValueX, setInitialValueX] = useState(0);
  const [experimentColor, setExperimentColor] = useState();
  const [speedDataFromGraph, setSpeedDataFromGraph] = useState();
  const [codDataFromGraph, setCodDataFromGraph] = useState();
  const [xData, setXData] = useState([]);
  const [xyData, setXYData] = useState([]);
  const [yData, setYData] = useState([]);
  const [yLabel, setYLabel] = useState();
  const [yLabelUnit, setYLabelUnit] = useState();
  const [xLabelUnit, setXLabelUnit] = useState();
  const [xLabel, setXLabel] = useState();
  const [clickCount, setClickCount] = useState(0);
  const [messureMentValues, setMessureMentValues] = useState([])
  const [instructionCount, setInstructionCount] = useState(0);
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertStatus2, setAlertStatus2] = useState(false);
  const [alertStatus3, setAlertStatus3] = useState(false);
  const [alertStatus4, setAlertStatus4] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  // const [chartTog, setChartTog] = useState()
  const [videoEnd, setDestatus] = useState(false);
  const [lessonStats, setLessonStats] = useState(false);
  // const [coordinatesDataY, setCoordinateDataY] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [disableState, setDisableState] = useState(true);
  const [userMainHypothesis, setUserMainHypothesis] = useState({ data: [] });
  const [userIHypothesis, setUserIHypothesis] = useState("");
  const [instructions, setInstructions] = useState("Instructions");
  const [selectValue, setSelectValue] = useState("");
  const [mappingId, setMappingId] = useState("");
  const [id, setId] = useState("");
  const [visualName, setVisualName] = useState("");
  const [intervalGraph, setIntervalGraph] = useState("");
  const [selectImg, setSelectImg] = useState([]);
  const [mainPayload, setMainPayload] = useState({});
  const [containedPayload, setContainedPayload] = useState([]);
  const [selectionOption, setSelectionOption] = useState([]);
  const [cellId, setCellId] = useState("");
  const [hypoState, setHypoState] = useState(0);
  const [clickState, setClickState] = useState(2);
  const [creatingTrigger, setCreatingTrigger] = useState(0);
  const { TextArea } = Input;
  const [allGraphValue, setAllGraphValue] = useState("");
  const [checked, setChecked] = useState(false)
  const [lesson, setLesson] = useState(initPayload?.lesson_id);
  const [selectedLesson, setSelectedLesson] = useState(0);
  const [descriptionValue, setDescriptionValue] = useState({});
  const [clickCount2, setClickCount2] = useState(0);
  const [clickCount3, setClickCount3] = useState(0);
  const [switcher, setSwitcher] = useState(0);
  const [canvasScale, setCanvasScale] = useState(1);
  const [initialXValue, setInitialXValue] = useState(1);
  const [directionValue, setDirectionValue] = useState("0");
  const [absoluteCalculation, setAbsoluteCalculation] = useState(true);
  const [fourPointValue, setFourPointValue] = useState([]);
  const [fourPointOption, setFourPointOption] = useState(false);
  const [triggerNSLab, setTriggerNSLab] = useState(true);
  const [initialPE, setInitialPE] = useState();
  //energy graph chnage in KE
  const [trigger, setTrigger] = useState(0);
  const [preValue, setPreValue] = useState(0);
  const [skipper, setSkipper] = useState(false);
  const [prevClickCount, setPrevClickCount] = useState(0);


  //for Earthquake lab
  const [clickCount4, setClickCount4] = useState(0);
  const [clickCount5, setClickCount5] = useState(0);
  const [clickCount6, setClickCount6] = useState(0);

  const [x2Value, setX2Value] = useState(0);
  const [distanceValue, setDistanceValue] = useState(0);

  // air pollution
  const [clickCountSetter, setClickCountSetter] = useState(0);
  const [tempYValue, setTempYValue] = useState(0);
  const [apBtnDisbale, setApBtnDisbale] = useState(true);

  // use to store xValues or yValues for incremental purpose
  const [storeValue, setStoreValue] = useState(0);

  const [datasets, setDatasets] = useState([
    {
      label: selectValue,
      label2: selectValue,
      yData: [],
      color: "red"
    }
  ]);

  const secondLessonTablePayload = {
    labels: selectImg.map((item, i) => i + 1),
    type: clickState,
    datasets: (clickState !== 0 && clickCount > 0) ? datasets.map((x, id) => {
      if (id !== 0) {
        return x
      }
    }) : datasets
  }

  useEffect(() => {

    if (visualName[1] !== undefined) {
      setDescriptionValue({ "value": visualName[0] + visualName[1], "display": `${visualName[0]} :  ${visualName[1]}` })

    } else {
      setDescriptionValue({ "value": visualName[0], "display": `${visualName[0]}` })

    }
  }, [visualName])


  // @to-do chnage a common set input
  useEffect(() => {
    //   console.log(dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod)
    if (initPayload?.chartType[mappingId]?.calculationMethod === "Line" || initPayload?.chartType[mappingId]?.calculationMethod === "splitLine" || initPayload?.chartType[mappingId]?.calculationMethod === "Simple Line" || initPayload?.chartType[mappingId]?.calculationMethod === "Line Radial Defined" || initPayload?.chartType[mappingId]?.calculationMethod === "Line Force Defined") {
      // console.log("you come here")
      setLessonStats(true)
    }
    else {
      setLessonStats(false);
    }

  }, [initPayload, mappingId])

  useEffect(() => {
    setLoading(true)
    getExploreStepByLessonIdAndLevel(parseInt(props.data.lessonId), props.data.levelId)

      .then((response) => {
        setInitPayload(response?.data);
        if (response?.data?.chartType?.dataTableValue !== undefined) {
          setDataTableValue(response?.data?.chartType?.dataTableValue)
        }
        dispatch(addToTable(response?.data));
        setLoading(false)
        //updateRequest
      })

  }, [])

  useEffect(() => {

    if (initPayload.chartType[mappingId]?.calculationMethod !== "curve" && initPayload.chartType[mappingId]?.calculationMethod !== "Bar YesNo") {
      setDatasets([
        {
          label: descriptionValue.display,
          displayLabel: descriptionValue.display,
          yData: messureMentValues.map(x => {
            return x.value
          }),
          color: messureMentValues.map(x => {
            return x.color
          })

        }
      ])
    }

    if (initPayload.chartType[mappingId]?.calculationMethod === "Bar YesNo") {
      setDatasets([{
        label2: selectValue,
        label: descriptionValue.display,
        yData: messureMentValues.map(x => {
          return x
        })
      }])
    }

  }, [messureMentValues, mappingId])

  useEffect(() => {
    if (clickCount4 === 0) {
      setClickCount5(0);
    } else if (clickCount4 === 1) {
      // setX2Value(0);
      // setDistanceValue(0);
      setClickCount5(clickCount5 + 1);
    } else if (clickCount4 === 2) {
      setClickCount5(clickCount5 + 1)
      // setClickCount(clickCount+1)
    } else {
      setClickCount5(clickCount5 + 1)
    }
  }, [clickCount4])

  useEffect(() => {
    if (clickCount5 === 0 || clickCount5 === 1) {

    } else if (clickCount5 === 2) {
      setClickCount(clickCount + 1)
    } else {
      setClickCount(clickCount + 1)
      setClickCount4(0)
    }
  }, [clickCount5])

  useEffect(() => {

    if (initPayload?.chartType[mappingId]?.calculationMethod === "curve") {

      setDatasets([
        {
          label: selectValue,
          yData: xData
        }
      ])
    }
  }, [initPayload, xData, mappingId])
  const [secondResponseData, setSecondResponseData] = useState({
    borderColor: "",
    borderWidth: "",
    labels: "",
    xData: [],
    yData: [],
    xyData: [],
    categoryData: [],
    cod: "",
    speed: "",
  });
  const [responseMixData, setResponseMixData] = useState([]);
  const [graphMixStatus, setGraphMixStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [speed2, setSpeed2] = useState();

  const [teacherComment, setTeacherComment] = useState("");
  const [teacherCommentStats, setTeacherCommentStats] = useState(false);

  let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;

  }

  useEffect(() => {
    setMessureMentValues([])
    initPayload?.exploreStepExperiment.map((x) => {
      if (x.id === id) {
        setIntervalGraph(x.chart_interval);
        setSelectImg(x.images.split(","));
        let xValue = " ";
        let yValue = " ";
        if (x.x !== null) {
          if (x.x.SI.length > 0) {
            xValue = `(${x.x.SI})`;
          }
          setXLabel(`${x.x.Value} ${xValue}`)

          setXLabelUnit(x.x.SI)
        }
        if (x.y !== null) {
          if (x.y.SI.length > 0) {
            yValue = `(${x.y.SI})`;
          }

          setYLabel(`${x.y.Value} ${yValue}`)
          setYLabelUnit(x.y.SI)
        }



      }
    })

  }, [selectValue, mappingId])
  useEffect(() => {
    setMessureMentValues([]);
  }, [selectValue]);



  // useEffect(() => {
  //   setLoading(true)
  //   RestService.getExploreStepUserStatusByIdAnduserName(user, props.exploreId)
  //     .then((response) => {
  //       if(response?.data[0]?.comment !== null && response?.data[0]?.comment !== undefined && response?.data[0]?.comment !== ""){
  //         setTeacherCommentStats(true)
  //         setTeacherComment(response?.data[0]?.comment)
  //       }
  //       if (response?.status === 200 && response) {
  //         response?.data.map((x) => {

  //           setContainedPayload({
  //             id: x?.id,
  //             explore_step_id: x?.explore_step_id,
  //             response_jason: JSON.parse(x?.response_jason),
  //           })


  //         })
  //         setCreatingTrigger(0)

  //       }
  //     })
  //   setLoading(false)
  // }, [props, creatingTrigger])

  useEffect(() => {
    setResponseMixData([]);
    setSecondResponseData({
      borderColor: "",
      borderWidth: "",
      labels: "",
      xData: [],
      yData: [],
      xyData: [],
      categoryData: [],
      cod: "",
      speed: "",
      userMainHypothesis: "",
    });
    if (Object.keys(cellId).length > 0 && Object.keys(cellId).length === selectionOption.length) {
      setChecked(true)
    }

    if (containedPayload?.response_jason?.answers !== undefined) {
      if (cellId.length !== 0 && cellId !== undefined && cellId !== null) {
        const filteredList = Object.keys(containedPayload?.response_jason?.answers)
          .filter(key => Object.values(cellId).includes(key))
          .reduce((obj, key) => {
            obj[key] = containedPayload?.response_jason?.answers[key];
            return obj;
          }, {});

        if (Object.values(filteredList).length <= 1) {
          setGraphMixStatus(false)
          if (Object.values(filteredList)[0] !== undefined && Object.keys(filteredList)[0] !== null) {
            const responsePayload = {
              borderColor: Object.values(filteredList)[0].borderColor,
              borderWidth: Object.values(filteredList)[0].borderWidth,
              labels: Object.values(filteredList)[0].displayLabel,
              xData: Object.values(filteredList)[0].xData,
              yData: Object.values(filteredList)[0].yData,
              xyData: Object.values(filteredList)[0].xyData,
              categoryData: Object.values(filteredList)[0].categoryData,
              cod: Object.values(filteredList)[0].cod,
              speed: Object.values(filteredList)[0].speed,
            }
            setSecondResponseData(responsePayload);
          }

        } else {
          setResponseMixData([]);
          setGraphMixStatus(true);
          for (let k = 0; k < Object.values(filteredList).length; k++) {
            if (Object.values(filteredList)[k] !== undefined && Object.keys(filteredList)[k] !== null) {
              const responsePayload = {
                borderColor: Object.values(filteredList)[k].borderColor,
                borderWidth: Object.values(filteredList)[k].borderWidth,
                labels: Object.values(filteredList)[k].displayLabel,
                xData: Object.values(filteredList)[k].xData,
                yData: Object.values(filteredList)[k].yData,
                xyData: Object.values(filteredList)[k].xyData,
                categoryData: Object.values(filteredList)[k].categoryData,
                cod: Object.values(filteredList)[k].cod,
                speed: Object.values(filteredList)[k].speed,
                yLabel: yLabelUnit,
                lesson: lesson
              }
              setResponseMixData((preData) => [...preData, responsePayload]);
            }

          }

        }

      }

      Object.values(containedPayload?.response_jason?.answers).map((x) => {


        if (Object.keys(x.userMainHypothesis).length > 0) {
          setUserMainHypothesis(x.userMainHypothesis);
          setHypoState(1);
        }
      });


    }
    // containedPayload?.response_jason?.answers

  }, [containedPayload, cellId])


  const graphdata1 = {
    labels: secondResponseData.categoryData,
    lesson: lesson,
    type: 1,
    datasets: [
      {
        yLabel: yLabelUnit,
        label: secondResponseData.labels,
        xData: secondResponseData.xData,
        yData: secondResponseData.yData,
        xyData: secondResponseData.xyData,
        borderColor: secondResponseData.borderColor,
        borderWidth: secondResponseData.borderWidth,
      },
    ],
  };
  const tablePayload2 = {
    type: graphMixStatus,
    labels: secondResponseData.labels,
    xData: initPayload?.chartType[mappingId]?.calculationMethod !== "curve" ? secondResponseData.yData : secondResponseData.xData,
    yData: initPayload?.chartType[mappingId]?.calculationMethod !== "curve" ? secondResponseData.xData : secondResponseData.yData,
    speed: secondResponseData.speed,
    cod: secondResponseData.cod,
  };


  useEffect(() => {


    if (datasets && datasets.length > 0) {
      datasets.map(x => {
        if (x.yData.length == 0) {
          setDatasets(datasets.filter(x => x.yData.length > 0))
        }
      })
    }
  }, [datasets])

  useEffect(() => {
    setMainPayload({});

    const data = containedPayload?.response_jason?.answers;
    if (data !== undefined && data !== null) {

      const selectval = Object.keys(data);
      const slength = selectval.length;
      const value = Object.values(data);

      for (let i = 0; i < slength; i++) {
        if (selectval[i] !== undefined && selectval[i] !== null) {


          setMainPayload(prev => ({
            ...prev,
            [selectval[i]]: {
              userMainHypothesis: value[i]?.userMainHypothesis,
              label: selectval[i],
              displayLabel: value[i]?.displayLabel,
              xData: value[i]?.xData,
              yData: value[i]?.yData,
              xyData: value[i]?.xyData,
              categoryData: value[i]?.categoryData,
              speed: value[i]?.speed,
              speed2: value[i]?.speed2,
              cod: value[i]?.cod,
              borderColor: value[i]?.borderColor,
              borderWidth: value[i]?.borderWidth,
              mappingId: parseInt(mappingId),
            }
          }))

          // }
          // else if (initPayload.chartType[mappingId]?.graphType === "Bar") {
          //   setMainPayload(prev => ({
          //     ...prev,
          //     [selectval[i]]: {
          //       userMainHypothesis: value[i]?.userMainHypothesis,
          //       label: selectval[i],
          //       xData: value[i]?.xData,
          //       yData: value[i]?.yData,
          //       xyData: value[i]?.xyData,
          //       categoryData: value[i]?.categoryData,
          //       speed: value[i]?.speed,
          //       cod: value[i]?.cod,
          //       borderColor: value[i]?.borderColor,
          //       borderWidth: value[i]?.borderWidth,
          //     }
          //   }))
          // }
        }

      }

      let arr = []
      Object.keys(data).map(x => {
        if (data[x].displayLabel !== undefined) {
          arr.push({
            name: data[x].label,
            displayName: data[x].displayLabel,
            isChecked: false,
            color: data[x].borderColor
          })
        }

      })

      setSelectionOption(arr)



    }
  }, [containedPayload?.response_jason?.answers, selectValue])
  // @to-do remove this one later
  useEffect(() => {
    if (initPayload?.exploreStepExperiment !== undefined) {
      const value = []
      const initialValues = [] //for curve chart
      let lableValue2 = "";

      initPayload?.exploreStepExperiment.map(x => {
        if (x?.description?.visualName.length > 1) {
          lableValue2 = `${x?.description?.visualName[0]}-${x?.description?.visualName[1]}`
        } else {
          lableValue2 = x?.description?.folderName
        }

        value.push(lableValue2);
        if (x?.chart_type?.value1 !== undefined) {
          initialValues.push(x?.chart_type?.value1)
        }
      })
      setHardCodedValue(value);
      setInitialValues(initialValues)
    }
  }, [initPayload])

  useEffect(() => {
    // @todo should be later set to DB

    // value1 is the 0 pointing position, and value 2 is the zero position
    setGGasVolPressure([
      {
        exp: HardCodedValue[0] ?? "Air",
        value1: {
          x: 229,
          y: 133,
        },
        value2: {
          x: 284,
          y: 154,
        },
        range: [170, 380, 530, 702, 853, 941, 4700, 5865],
        xValue: 65
      },
      {
        exp: HardCodedValue[1] ?? "CO2",
        value1: {
          x: 241,
          y: 137,
        },
        value2: {
          x: 291,
          y: 155,
        },
        range: [150, 343, 520, 687, 854, 990, 4700, 5865],
        xValue: 65
      },
      {
        exp: HardCodedValue[2] ?? "Helium",
        value1: {
          x: 222,
          y: 134,
        },
        value2: {
          x: 276,
          y: 156,
        },
        range: [157, 340, 512, 652, 827, 943, 4700, 5865],
        xValue: 65
      },
      {
        exp: HardCodedValue[3] ?? "Propane",
        value1: {
          x: 209,
          y: 134,
        },
        value2: {
          x: 263,
          y: 154,
        },
        range: [168, 354, 555, 698, 835, 1006, 4700, 5865],
        xValue: 65
      },
    ]);


    setPPhaseChangeIdea([
      {
        exp: HardCodedValue[0] ?? "Water",
        value1: {
          x: 302,
          y: 263,
        },
        value2: {
          x: 302,
          y: 244,
        },
        range: 10
      },
      {
        exp: HardCodedValue[1] ?? "SaltWater",
        value1: {
          x: 304,
          y: 232
        },
        value2: {
          x: 304,
          y: 214,
        },
        range: 10
      },
      {
        exp: HardCodedValue[2] ?? "Alcohol",
        value1: {
          x: 305,
          y: 249,
        },
        value2: {
          x: 305,
          y: 231,
        },
        range: 10
      },
    ]);

    //@to get later from the DB
    setFfixedValueCurveGraph([
      {
        exp: HardCodedValue[0] ?? "June",
        value: {
          x: 296,
          y: 270,
        }
      },
      {
        exp: HardCodedValue[1] ?? "July",
        value: {
          x: 291,
          y: 273,
        }
      },
      {
        exp: HardCodedValue[2] ?? "August",
        value: {
          x: 298,
          y: 268,
        }
      },
      {
        exp: HardCodedValue[3] ?? "September",
        value: {
          x: 292,
          y: 264,
        }
      },
      {
        exp: HardCodedValue[4] ?? "October",
        value: {
          x: 293,
          y: 269,
        }
      },
      {
        exp: HardCodedValue[5] ?? "November",
        value: {
          x: 204,
          y: 265,
        }
      },
      {
        exp: HardCodedValue[6] ?? "December",
        value: {
          x: 192,
          y: 268,
        }
      },
    ])

    //hard coded value for earthquake lab
    setEarthquakeDataValues([
      {
        exp: HardCodedValue[0] ?? "1",
        value: ["CI_DV", "CI_ER", "CI_WE", "CI_CB", "CI_HM"],
        speed: 8.7
      },
      {
        exp: HardCodedValue[1] ?? "2",
        value: ["CI_SCI", "CI_CIA", "CI_KML", "CI_GOR", "CI_CPE", "CI_SDD", "CI_FMP"],
        speed: 8.7
      },
      {
        exp: HardCodedValue[2] ?? "3",
        value: ["CI_PSR", "CI_OLI", "CI_SRN", "CI_PDU", "CI_CRN"],
        speed: 8.7
      },
      {
        exp: HardCodedValue[3] ?? "4",
        value: ["CI_WES", "CI_YUH", "CI_SWS", "CI_CRR"],
        speed: 8.7
      },
      {
        exp: HardCodedValue[4] ?? "5",
        value: ["CI_LRL", "CI_WBS", "PB_B921", "CI_CCC"],
        speed: 8.7
      },
      {
        exp: HardCodedValue[5] ?? "6",
        value: ["CI_LRL", "CI_SRT", "CI_CCC", "PB_B917", "CI_WCH", "CI_TEH", "CI_WVP"],
        speed: 8.7
      },
      {
        exp: HardCodedValue[6] ?? "7",
        value: ["CI_CSP", "CI_LUG", "CI_SUN", "CI_RVR", "CI_EL2", "CI_SME"],
        speed: 8.7
      },
      {
        exp: HardCodedValue[7] ?? "8",
        value: ["CI_MPM", "PB_918", "PB_B916", "NN_QSM", "CI_TOW", "NN_PAN"],
        speed: 8.7
      },
    ])
  }, [HardCodedValue])



  const drawX2 = (x, y, name) => {

    if (name === "ladybug")
      ctx.strokeStyle = '#FF3333';

    if (name === "grasshopper")
      ctx.strokeStyle = '#9ACD32';

    if (name === "pillBug")
      ctx.strokeStyle = '#6D5E54';

    if (name === "scarab")
      ctx.strokeStyle = '#167E5C';

    if (name === "aphid")
      ctx.strokeStyle = '#88B536'

    ctx.lineWidth = 3;
    ctx.beginPath();
    ctx.arc(x + 17, y + 19, 15, 0, 2 * Math.PI);
    ctx.stroke();

  }

  const drawX3 = (x, y, color) => {
    ctx.strokeStyle = color;

    ctx.lineWidth = 3;
    ctx.beginPath();
    ctx.arc(x, y, 15, 0, 2 * Math.PI);
    ctx.stroke();

  }
  const drawX = (x, y) => {
    // if(dataSendToMap.find(x => x.id === id).chart_type === "Line Temperature Defined" || dataSendToMap.find(x => x.id === id).chart_type === "Line Force Defined"){
    //   ctx.strokeStyle = `rgb(0,0,255)`;
    // }else if(dataSendToMap.find(x => x.id === id).chart_type === "Line Radial Defined"){
    //   ctx.strokeStyle = `rgb(255, 0, 0)`;
    // }else{
    //   ctx.strokeStyle = `rgb(0, 0, 0)`;
    // }
    ctx.strokeStyle = `${dataSendToMap.find(x => x.id === id).chart_type[mappingId].PointerType.color}`
    ctx.beginPath();

    // if(dataSendToMap.find(x => x.id === id).chart_type === "Line Temperature Defined" || dataSendToMap.find(x => x.id === id).chart_type === "Line Force Defined"){
    if (dataSendToMap.find(x => x.id === id).chart_type[mappingId].PointerType.Type === "horizontalLine") {
      ctx.lineWidth = dataSendToMap.find(x => x.id === id).chart_type[mappingId].PointerType.width;

      ctx.moveTo(x - 3, y);
      ctx.lineTo(x + 3, y);
      ctx.stroke();

      // ctx.moveTo(x + 2, y - 2);
      // ctx.lineTo(x - 2, y + 2);
      // ctx.stroke();
    }
    // dataSendToMap.find(x => x.id === id).chart_type ==="Line Radial Defined"
    else if (dataSendToMap.find(x => x.id === id).chart_type[mappingId].PointerType.Type === "dot") {
      ctx.lineWidth = dataSendToMap.find(x => x.id === id).chart_type[mappingId].PointerType.width;;
      ctx.moveTo(x - 0.5, y + 0.5);
      ctx.lineTo(x + 0.5, y - 0.5);
      ctx.stroke();
    } else if (dataSendToMap.find(x => x.id === id).chart_type[mappingId].PointerType.Type === "verticalLine") {
      ctx.lineWidth = dataSendToMap.find(x => x.id === id).chart_type[mappingId].PointerType.width;

      ctx.moveTo(x, y - 3);
      ctx.lineTo(x, y + 3);
      ctx.stroke();
    } else if (dataSendToMap.find(x => x.id === id).chart_type[mappingId].PointerType.Type === "circleLine") {
      ctx.lineWidth = dataSendToMap.find(x => x.id === id).chart_type[mappingId].PointerType.width;

      ctx.arc(x, y, 15, 0, 2 * Math.PI);
      ctx.stroke();
    } else {
      ctx.lineWidth = 1;

      ctx.moveTo(x - 2, y - 2);
      ctx.lineTo(x + 2, y + 2);
      ctx.stroke();

      ctx.moveTo(x + 2, y - 2);
      ctx.lineTo(x - 2, y + 2);
      ctx.stroke();
    }
  };


  useEffect(() => {
    // selectedLesson, setSelectedLesson

    const findIndex = initPayload?.exploreStepExperiment.findIndex(x => x.id === id)
    if (findIndex !== -1) {
      setSelectedLesson(findIndex)
    } else {
      setSelectedLesson(0)
    }
    // assigning canvasScale
    initPayload?.exploreStepExperiment.map(x => {
      // x.find(x => x.exp === selectValue)?.value?.x
      let lableValue = "";
      if (x?.description?.visualName.length > 1) {
        lableValue = `${x?.description?.visualName[0]}-${x?.description?.visualName[1]}`
      } else {
        lableValue = x?.description?.folderName
      }

      if (lableValue === selectValue) {
        setCanvasScale(parseFloat(x.chart_type.canvasscale ?? 1.0))
        setDirectionValue(x.chart_type.directionValue ?? "0")
        setFourPointOption(x.chart_type.value2option ?? false)
        setFourPointValue(x.chart_type.value2)
        setBarValue(x.chart_type.value3option)
        setAbsoluteCalculation(x.chart_type.AbsoluteCalculation ?? true)
        setInitialXValue(parseFloat(x?.x?.InitialValue) || 1)
      }
    })
  }, [selectValue])

  const onCanvasClick2 = (e) => {

    setClickState(0);
    let canvas = document.getElementById("experimentcanvas");
    ctx = canvas.getContext("2d");
    const value = randomGenBtn.filter(obj => obj.id === e);
    drawX2(value[0].x, value[0].y, value[0].name);


    //for render
    const count = {};
    const count2 = {};

    for (const value of btnClickValueStore) {
      if (value in count) {
        count[value] += 1;
        count2[value] += 1;
      } else {
        count[value] = 1;
        count2[value] = 1;
      }
    }

    if (value[0].name in count2) {
      count2[value[0].name] += 1;
    } else {
      count2[value[0].name] = 1;
    }

    const ladybugValue = count2.ladybug ? weights[0] - parseInt(count2.ladybug) : weights[0];
    const grasshopperValue = count2.grasshopper ? weights[1] - parseInt(count2.grasshopper) : weights[1];
    const pillBugValue = count2.pillBug ? weights[2] - parseInt(count2.pillBug) : weights[2];
    const scarabValue = count2.scarab ? weights[3] - parseInt(count2.scarab) : weights[3];
    const aphidValue = count2.aphid ? weights[4] - parseInt(count2.aphid) : weights[4];

    const array1 = [ladybugValue, grasshopperValue, pillBugValue, scarabValue, aphidValue]
    // console.log(array1)
    // if(triggerNSLab){
    //   setCategoryData((prevArray) => [...prevArray, clickCount+1]);
    //   setXYData((prevArray) => [...prevArray, [[clickCount+1],array1]]);
    //   setXData((prevArray) => [...prevArray,array1]);
    //   setYData((prevArray) => [...prevArray, clickCount+1]);
    //   setTriggerNSLab(false);

    // }else{
    setXYData((prevArray) => {
      const newArray = prevArray.slice(0, -1); // create a new array that excludes the last element
      return [...newArray, [yData, array1]]; // create a new array that combines the modified existing array with the new value of array1
    });
    setXData((prevArray) => {
      const newArray = prevArray.slice(0, -1); // create a new array that excludes the last element
      return [...newArray, array1]; // create a new array that combines the modified existing array with the new value of array1
    });
    // }

    //setters
    setBtnClickValueStore(prev => [
      ...prev,
      value[0].name
    ])
    setRandomGenBtn(randomGenBtn.filter(obj => obj.id !== e));

  }

  const onCanvasClick = (e) => {
    setClickState(0);
    // setDatasets([{
    //   label: null,
    //   yData: [],
    //   xData:[]
    // }])
    selectionOption.map(x => {
      x.isChecked = false
    });
    setSelectionOption(selectionOption)
    setChecked(false)
    setGraphMixStatus(false)
    setCellId("");
    let images = selectImg;
    if (dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod !== "Line Type11" && dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod !== "Line Type7" && dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod !== "Line Type10" && dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod !== "Line Type9" && dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod !== "Line Type6" && dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod !== "Bar Click2Count" && dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod !== "Bar YesNo" && dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod !== "Line Type3") {
      setClickCount(clickCount + 1);
    }

    if (dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod === "Line Type3") {
      // setClickCount(clickCount3 + 1);
    }
    if (clickCount < images.length) {
      let canvas = document.getElementById("experimentcanvas");
      ctx = canvas.getContext("2d");
      let mouseX = e.nativeEvent.offsetX;
      let mouseY = e.nativeEvent.offsetY;

      // draw the XY
      switch (dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod) {
        case "Bar YesNo":
          if (mouseX > 400) {
            drawX(mouseX, mouseY);
          }
          break;
        case "Line Type7":
          break;

        case "Line Type11":
          if (mouseX >= initialValues.find(x => x.exp === selectValue)?.value?.y) {
            drawX3(mouseX, mouseY, `${dataSendToMap.find(x => x.id === id).chart_type[mappingId].PointerType.color}`);
          }
          break;
        case "Line Type9":
          if (clickCountSetter <= 0 && mouseY <= initialValues.find(x => x.exp === selectValue)?.value?.y) {
            drawX(mouseX, mouseY);
          }
          break;
        case "Line Type12":
          setScreenShot(prev => ({
            ...prev,
            [clickCount]: { x: mouseX, y: mouseY }
          }))

          ctx.strokeStyle = `${dataSendToMap.find(x => x.id === id).chart_type[mappingId].PointerType.color}`
          ctx.beginPath();
          ctx.lineWidth = 1;

          ctx.moveTo(mouseX - 2, mouseY - 2);
          ctx.lineTo(mouseX + 2, mouseY + 2);
          ctx.stroke();

          ctx.moveTo(mouseX + 2, mouseY - 2);
          ctx.lineTo(mouseX - 2, mouseY + 2);
          ctx.stroke();
          // Connect the points
          ctx.moveTo(mouseX - 2, mouseY - 2);
          ctx.lineTo(mouseX + 2, mouseY - 2);
          ctx.lineTo(mouseX + 2, mouseY + 2);
          ctx.lineTo(mouseX - 2, mouseY + 2);
          ctx.lineTo(mouseX - 2, mouseY - 2);
          if (Object.values(screenShot).length > 0) {
            const points = Object.values(screenShot);
            ctx.strokeStyle = `${dataSendToMap.find(x => x.id === id).chart_type[mappingId].PointerType.color}`;
            ctx.beginPath();
            ctx.lineWidth = 1;

            // Draw the first point
            const firstPoint = points[0];
            ctx.moveTo(firstPoint.x, firstPoint.y);

            // Draw lines between the rest of the points
            for (let i = 1; i < points.length; i++) {
              const point = points[i];
              ctx.lineTo(point.x, point.y);
            }
            ctx.lineTo(mouseX, mouseY);
            ctx.stroke();
          }

          break;
        case "Line Type10":
          //ctx.lineWidth = 3;
          // ctx.beginPath();
          // ctx.arc(mouseX+0, mouseY+5, 15, 0, 2 * Math.PI);
          // ctx.fillStyle = "#FE0000";
          // ctx.fill();
          const color = BarValueList && BarValueList[clickCount].colorPointer !== undefined ? BarValueList[clickCount].colorPointer : "#000000";
          drawX3(mouseX, mouseY, color)

          break;
        default:
          drawX(mouseX, mouseY);
          break;
      }

      // @todo should be later set to DB

      // value1 is the 0 pointing position, and value 2 is the zero position
      const GasVolPressure = gGasVolPressure
      const PhaseChangeIdea = pPhaseChangeIdea


      switch (dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod) {

        case "Line Type2":
          let value6
          if (directionValue === "1") {
            value6 = MathCalculationService.LineOfBestFitXForward(mouseX, initialValueX, intervalGraph, absoluteCalculation);
          } else if (directionValue === "2") {
            value6 = MathCalculationService.LineOfBestFitXForward(mouseY, initialValueX, intervalGraph, absoluteCalculation);
          } else {
            value6 = MathCalculationService.LineOfBestFitXForward(mouseX, initialValueX, intervalGraph, absoluteCalculation);
          }



          if (clickCount === 0) {
            setInitialValueX(value6.initialValueX)
          }


          setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

          setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
          setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, parseFloat((value6.value / 10 * canvasScale).toFixed(3))]]);
          setXData((prevArray) => [...prevArray, (value6.value / 10 * canvasScale).toFixed(3)]);
          break;
        case "Simple Line":
          //@to-do set two initial values x  and y zero => (238, 138) > 238 will be zero referencing for x-axis and 138 for y axis
          //result should set in the categoryData, xData, yData, xyData
          const value = MathCalculationService.simpleLine(mouseX / 10, mouseY / 10, initialValueY, initialValueX, intervalGraph, absoluteCalculation);

          const result = (parseFloat(value.value) + parseFloat(storeValue)).toFixed(2);

          setInitialValueY(value.initialValueY)
          setInitialValueX(value.initialValueX)


          setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

          setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
          setStoreValue(result)

          setXData((prevArray) => [...prevArray, (result * canvasScale).toFixed(3)]);
          setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, parseFloat((result) * canvasScale).toFixed(3)]]);


          break;
        // GasVolPressure 
        case "Line Radial Defined":
          const iniX = GasVolPressure.find(x => x.exp === selectValue)?.value1?.x;
          const iniY = GasVolPressure.find(x => x.exp === selectValue)?.value1?.y;
          const zeroX = GasVolPressure.find(x => x.exp === selectValue)?.value2?.x;
          const zeroY = GasVolPressure.find(x => x.exp === selectValue)?.value2?.y;
          let xValue = GasVolPressure.find(x => x.exp === selectValue)?.xValue;
          
          const value5 = MathCalculationService.LineRadialDefined(iniX, iniY, mouseY, mouseX, zeroX, zeroY);

          setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

          setYData((prevArray) => [...prevArray, (clickCount * intervalGraph) + xValue]);
          setXData((prevArray) => [...prevArray, value5.value]);
          setXYData((prevArray) => [...prevArray, [(clickCount * intervalGraph) + xValue, value5.value]]);
          break;

        // AtWoodMachine
        case "Line Force Defined":
          let value2
          if (directionValue === "1") {
            value2 = MathCalculationService.LineForceDefined(initialValueY, mouseX, absoluteCalculation, directionValue);
          } else if (directionValue === "2") {
            value2 = MathCalculationService.LineForceDefined(initialValueY, mouseY, absoluteCalculation, directionValue);
          } else {
            value2 = MathCalculationService.LineForceDefined(initialValueY, mouseY, absoluteCalculation, directionValue);
          }
          if (clickCount === 0) {
            if (directionValue === "1") {
              setInitialValueY(mouseX);
            } else if (directionValue === "2") {
              setInitialValueY(mouseY);
            } else {
              setInitialValueY(mouseY);
            }
            setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

            setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
            setXData((prevArray) => [...prevArray, "0"]);
            setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, "0"]]);
          } else {
            setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

            setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
            setXData((prevArray) => [...prevArray, (value2.value * canvasScale).toFixed(3)]);
            setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, (value2.value * canvasScale).toFixed(3)]]);
          }

          break;

        // PhaseChangeIdea
        case "Line Temperature Defined":
          let initialYDifference;

          if (absoluteCalculation === false) {
            initialYDifference = (((PhaseChangeIdea.find(x => x.exp === selectValue)?.value1?.y - PhaseChangeIdea.find(x => x.exp === selectValue)?.value2?.y)));
          } else {
            initialYDifference = Math.abs(((PhaseChangeIdea.find(x => x.exp === selectValue)?.value1?.y - PhaseChangeIdea.find(x => x.exp === selectValue)?.value2?.y)));
          }
          const initialYValue2 = PhaseChangeIdea.find(x => x.exp === selectValue)?.value1?.y;
          const range = PhaseChangeIdea.find(x => x.exp === selectValue)?.range;

          let value3
          if (directionValue === "1") {
            value3 = MathCalculationService.LineTemperatureDefined(initialYDifference, initialYValue2, mouseX, range);
          } else if (directionValue === "2") {
            value3 = MathCalculationService.LineTemperatureDefined(initialYDifference, initialYValue2, mouseY, range);
          } else {
            value3 = MathCalculationService.LineTemperatureDefined(initialYDifference, initialYValue2, mouseY, range);
          }

          setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

          setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
          setXData((prevArray) => [...prevArray, (value3.value * canvasScale).toFixed(3)]);
          setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, parseFloat(value3.value * canvasScale).toFixed(3)]]);

          break;

        case "Line Volume Defined":

          let value4
          if (directionValue === "1") {
            value4 = MathCalculationService.simpleVolumeLine(mouseX / 10, mouseY / 10, initialValueY, initialValueX, intervalGraph, absoluteCalculation);
          } else if (directionValue === "2") {
            value4 = MathCalculationService.simpleVolumeLine(mouseY / 10, mouseX / 10, initialValueY, initialValueX, intervalGraph, absoluteCalculation);
          } else {
            value4 = MathCalculationService.simpleVolumeLine(mouseX / 10, mouseY / 10, initialValueY, initialValueX, intervalGraph, absoluteCalculation);
          }
          if (clickCount === 0) {
            setInitialValueY(value4.initialValueY)
            setInitialValueX(value4.initialValueX)
          }


          setCategoryData((prevArray) => [...prevArray, clickCount + 1]);

          setYData((prevArray) => [...prevArray, clickCount * intervalGraph]);
          setXYData((prevArray) => [...prevArray, [clickCount * intervalGraph, parseFloat((value4.value) * canvasScale).toFixed(3)]]);
          setXData((prevArray) => [...prevArray, (value4.value * canvasScale).toFixed(3)]);
          break;

        case "Bar YesNo":
          setYData([1, 2, 3, 4, 5, 6]);

          setCategoryData([1, 2, 3, 4, 5, 6]);

          let barValue;

          if (mouseX > 400) {
            if (mouseY > 0 && mouseY <= 66) {
              barValue = { 0: "gas" }
            } else if (mouseY > 66 && mouseY <= 132) {
              barValue = { 1: "liquid" }
            } else if (mouseY > 132 && mouseY <= 199) {
              barValue = { 2: "solid" }
            } else if (mouseY > 199 && mouseY <= 267) {
              barValue = { 3: "color" }
            } else if (mouseY > 267 && mouseY < 334) {
              barValue = { 4: "light" }
            } else {
              barValue = { 5: "heat" }
            }
            setXData(prev => ({
              ...prev,
              [Object.keys(barValue)]: Object.values(barValue)
            }));

            setMessureMentValues(prev => [
              ...prev,
              { [parseInt(Object.keys(barValue)) + 1]: 1 }
            ]);
          }
          break;

        case "Line Type3":
          setCategoryData((prevArray) => [...prevArray, clickCount3 + 1]);
          if (switcher === 0) {
            setXYData((prevArray) => [...prevArray, [clickCount3 * intervalGraph, clickCount2 + 1]]);
            setXData((prevArray) => [...prevArray, clickCount2 + 1]);
            setYData((prevArray) => [...prevArray, clickCount3 * intervalGraph]);
            setClickCount2(clickCount2 + 1)
            setClickCount3(clickCount3 + 1);
            setSwitcher(1)
          } else {
            setXYData(prevArray => {
              const value = prevArray[prevArray.length - 1]
              prevArray.pop();
              return [...prevArray, [value[0] ?? 0, clickCount2 + 1]];
            });
            setXData(prevArray => {
              prevArray.pop()
              return [...prevArray, clickCount2 + 1];
            });
            setClickCount2(clickCount2 + 1)
          }
          break;

        case "Line Type6":
          if (clickCount4 === 0) {
            if (clickCount === images.length - 3) {
              setClickCount(clickCount + 3)
            }
            setX2Value(mouseX);
          }
          if (clickCount4 === 1) {
            // later put in the ratio of the scall factor
            setDistanceValue(parseFloat((mouseX - x2Value) / 8).toFixed(2));
          }

          if (clickCount4 === 2) {
            const DataValue = EarthquakeDataValues.find(x => x.exp === selectValue);
            const Name = DataValue?.value[clickCount6];

            const value = `${Name} (${mouseX},${400 - mouseY})`;
            setCategoryData((prevArray) => [...prevArray, Name]);
            setXYData((prevArray) => [...prevArray, [distanceValue, value]]);
            setXData((prevArray) => [...prevArray, value]);
            setYData((prevArray) => [...prevArray, distanceValue]);
            setClickCount6(clickCount6 + 1)
          }

          setClickCount4(clickCount4 + 1);


          break;
        case "Bar Click2Count":
          if (switcher === 0) {
            setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
            setXYData((prevArray) => [...prevArray, [(clickCount * 1) + 1, clickCount2 + 1]]);
            setXData((prevArray) => [...prevArray, clickCount2 + 1]);
            setYData((prevArray) => [...prevArray, (clickCount * 1) + 1]);
            setClickCount2(clickCount2 + 1)
            setSwitcher(1)
          } else {
            setXYData(prevArray => {
              const value = prevArray[prevArray.length - 1]
              prevArray.pop();
              return [...prevArray, [value[0] ?? 0, clickCount2 + 1]];
            });
            setXData(prevArray => {
              prevArray.pop()
              return [...prevArray, clickCount2 + 1];
            });
            setClickCount2(clickCount2 + 1)
          }
          break;
        case "Line Type5":
          let answer = 0;

          if (fourPointOption === true) {
            if (mouseX >= parseInt(fourPointValue[clickCount]?.answer1) && mouseX <= parseInt(fourPointValue[clickCount]?.answer2) && mouseY >= parseInt(fourPointValue[clickCount]?.answer4) && mouseY <= parseInt(fourPointValue[clickCount]?.answer3)) {
              answer = 1;
            } else {
              answer = 0;
            }
          } else {
            if (mouseX >= parseInt(fourPointValue[clickCount]?.answer1) && mouseX <= parseInt(fourPointValue[clickCount]?.answer2) && mouseY >= parseInt(fourPointValue[clickCount]?.answer4) && mouseY <= parseInt(fourPointValue[clickCount]?.answer3)) {
              answer = 0;
            } else {
              answer = 1;
            }
          }

          if (answer === 1) {
            setMessureMentValues(prev => [...prev, { value: 1, color: "green" }])
          } else {
            setMessureMentValues(prev => [...prev, { value: 1, color: "red" }])
          }
          break;
        case "Line Type9":
          if (mouseY <= initialValues.find(x => x.exp === selectValue)?.value?.y) {
            if (clickCountSetter <= 0) {
              const fracValue = parseFloat(540 / initialValues.find(x => x.exp === selectValue)?.value?.initialDataScaleValue).toFixed(1)
              const airPopulationScale = parseFloat(mouseX / fracValue).toFixed(1)
              setTempYValue(airPopulationScale);
              setApBtnDisbale(false);
              setClickCountSetter(1)
            } else {
              setAlertStatus3(true)
            }
          } else {
            if (clickCountSetter <= 0) {
              setAlertStatus4(true)
            } else {
              setAlertStatus3(true)
            }
          }
          break;
        case "Line Type10":

          if (clickCount2 === clickCount) {
            setCategoryData((prevArray) => [...prevArray, BarValueList ? BarValueList[clickCount]?.answer1 : clickCount + 1]);
            setXYData((prevArray) => [...prevArray, [BarValueList ? BarValueList[clickCount].colorPointer : "000000", clickCount3 + 1]]);
            setXData((prevArray) => [...prevArray, clickCount3 + 1]);
            setYData((prevArray) => [...prevArray, BarValueList ? BarValueList[clickCount].answer1 : clickCount + 1]);


            setClickCount2(clickCount2 + 1);
            setClickCount3(clickCount3 + 1);
          } else {

            setXYData((prevArray) => [...prevArray.slice(0, -1), [BarValueList ? BarValueList[clickCount].colorPointer : "000000", clickCount3 + 1]]);
            setXData((prevArray) => [...prevArray.slice(0, -1), clickCount3 + 1]);
            setYData((prevArray) => [...prevArray.slice(0, -1), BarValueList ? BarValueList[clickCount].answer1 : clickCount + 1]);
            setClickCount3(clickCount3 + 1);
          }

          break;
        case "Line Type11":
          if (mouseX >= initialValues.find(x => x.exp === selectValue)?.value?.y) {

            function getRangeName(totalRange, division, value, rangeName) {
              const rangeSize = totalRange / division;
              const rangeIndex = Math.floor(value / rangeSize);
              // Define range names for each division
              const rangeNames = rangeName;
              if (rangeIndex >= 0 && rangeIndex < division) {
                return rangeNames[rangeIndex];
              } else {
                return "Not Defined";
              }
            }

            const listValue = BarValueList[clickCount];
            const { answer1, answer2, answer3, ...newObject } = listValue;
            const objectValues = Object.values(newObject);

            const totalRange = 400;
            const division = objectValues.length;


            const rangeValue = getRangeName(totalRange, division, mouseY, objectValues);
            if (clickCount3 === 0) {
              setCategoryData((prevArray) => [...prevArray, answer2 ? answer2 : clickCount + 1]);
              setXYData((prevArray) => [...prevArray, [answer3 ? rangeValue === answer3 ? "#00FF00" : "#FF0000" : "000000", rangeValue]]);
              setXData((prevArray) => [...prevArray, rangeValue]);
              setYData((prevArray) => [...prevArray, answer2 ? answer2 : clickCount + 1]);
              setClickCount3(clickCount3 + 1);
            } else {
              setXYData((prevArray) => [...prevArray.slice(0, -1), [answer3 ? rangeValue === answer3 ? "#00FF00" : "#FF0000" : "000000", rangeValue]]);
              setXData((prevArray) => [...prevArray.slice(0, -1), rangeValue]);
              setYData((prevArray) => [...prevArray.slice(0, -1), answer2 ? answer2 : clickCount + 1]);

            }
          }
          break;

        case "Line Type8":
          const mass = 1;
          let skipper = false;
          let height;
          if (directionValue === "1") {
            // for x axis
            height = (initialValues.find(x => x.exp === selectValue)?.value?.x - mouseX) * canvasScale;
          } else if (directionValue === "2") {
            // for y asix
            height = (initialValues.find(x => x.exp === selectValue)?.value?.y - mouseY) * canvasScale
          } else {
            height = (initialValues.find(x => x.exp === selectValue)?.value?.y - mouseY) * canvasScale
          }
          const energy = parseFloat(mass * 9.8 * (height > 0 ? height : 0)).toFixed(2);
          const calculateEnergy = (energy, index) => {
            let kE;
            if (index === 0) {
              kE = 0
              setInitialPE(energy);
            } else {
              kE = parseFloat(initialPE - energy).toFixed(2);
            }
            return kE;
          }

          if (clickCount === 0) {
            skipper = false;
          } else {
            if (trigger === 0) {
              if (preValue - energy <= 0) {
                setTrigger(1)
                skipper = false;
              } else {
                skipper = false;
              }
            } else {
              if (preValue - energy >= 0) {
                setTrigger(0)
                skipper = true;
              } else {
                skipper = false;
              }
            }
          }

          if (!skipper) {
            const KE = calculateEnergy(energy, clickCount)
            const data = `PE:${energy}; \n KE:${KE}`
            setXYData((prevArray) => [...prevArray, [parseFloat(clickCount * intervalGraph).toFixed(2), data]]);
            setXData((prevArray) => [...prevArray, data]);
            setPrevClickCount(prevClickCount);
            setPreValue(energy);
            setYData((prevArray) => [...prevArray, parseFloat(clickCount * intervalGraph).toFixed(2)]);
            setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
          } else {
            const prevKE = 0;
            const currentKE = parseFloat(preValue - energy).toFixed(2);
            const dataPrev = `PE:${preValue}; \n KE:${prevKE}`
            const data = `PE:${energy}; \n KE:${currentKE}`
            setXYData((prevArray) => [...prevArray.slice(0, -1), [parseFloat(prevClickCount * intervalGraph).toFixed(2), prevKE], [parseFloat(clickCount * intervalGraph).toFixed(2), data]]);
            setXData((prevArray) => [...prevArray.slice(0, -1), dataPrev, data]);
            setPrevClickCount(prevClickCount);
            setInitialPE(energy);
            setPreValue(energy);
            setYData((prevArray) => [...prevArray, parseFloat(clickCount * intervalGraph).toFixed(2)]);
            setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
          }


          break;

        case "Line Type12":
          const mX = (35 - parseFloat(((540 - mouseX) / 540) * 50)).toFixed(2)
          const mY = (50 - parseFloat((mouseY / 400) * 35)).toFixed(2)
          setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
          setXYData((prevArray) => [...prevArray, [parseFloat(mX), parseFloat(mY)]]);
          setXData((prevArray) => [...prevArray, parseFloat(mY)]);
          setYData((prevArray) => [...prevArray, parseFloat(mX)]);
          break;
        case "curve2":
          if (directionValue === "1" || absoluteCalculation === true) {
            const yDistance = Math.abs(((initialValues.find(x => x.exp === selectValue)?.value?.y - mouseY) / 10).toFixed(2));
            const xDistance = Math.abs(((initialValues.find(x => x.exp === selectValue)?.value?.x - mouseX) / 10).toFixed(2));
            const YValue = Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2)).toFixed(2)


            if (initialValues.find(x => x.exp === selectValue)?.value?.x - mouseX < 0 || absoluteCalculation === true) {
              setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
              setYData((prevArray) => [...prevArray, (clickCount * intervalGraph).toFixed(1)]);
              setXYData((prevArray) => [...prevArray, [(clickCount * intervalGraph).toFixed(1), (YValue * canvasScale).toFixed(3)]]);
              setXData((prevArray) => [...prevArray, (YValue * canvasScale).toFixed(3)]);
            } else {
              setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
              setYData((prevArray) => [...prevArray, (clickCount * intervalGraph).toFixed(1)]);
              setXYData((prevArray) => [...prevArray, [(clickCount * intervalGraph).toFixed(1), (YValue * canvasScale * -1).toFixed(3)]]);
              setXData((prevArray) => [...prevArray, (YValue * canvasScale * -1).toFixed(3)]);
            }


          } else if (directionValue === "2") {
            const yDistance = Math.abs(((initialValues.find(x => x.exp === selectValue)?.value?.y - mouseY) / 10).toFixed(2));
            const xDistance = Math.abs(((initialValues.find(x => x.exp === selectValue)?.value?.x - mouseX) / 10).toFixed(2));
            const YValue = Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2)).toFixed(2)

            if (initialValues.find(x => x.exp === selectValue)?.value?.y - mouseY < 0) {
              setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
              setYData((prevArray) => [...prevArray, (clickCount * intervalGraph).toFixed(1)]);
              setXYData((prevArray) => [...prevArray, [(clickCount * intervalGraph).toFixed(1), (YValue * canvasScale * -1).toFixed(3)]]);
              setXData((prevArray) => [...prevArray, (YValue * canvasScale * -1).toFixed(3)]);
            } else {
              setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
              setYData((prevArray) => [...prevArray, (clickCount * intervalGraph).toFixed(1)]);
              setXYData((prevArray) => [...prevArray, [(clickCount * intervalGraph).toFixed(1), (YValue * canvasScale).toFixed(3)]]);
              setXData((prevArray) => [...prevArray, (YValue * canvasScale).toFixed(3)]);
            }

          } else {

            const yDistance = Math.abs(((initialValues.find(x => x.exp === selectValue)?.value?.y - mouseY) / 10).toFixed(2));
            const xDistance = Math.abs(((initialValues.find(x => x.exp === selectValue)?.value?.x - mouseX) / 10).toFixed(2));
            const YValue = Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2)).toFixed(2)


            if (initialValues.find(x => x.exp === selectValue)?.value?.x - mouseX < 0) {
              setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
              setYData((prevArray) => [...prevArray, (clickCount * intervalGraph).toFixed(1)]);
              setXYData((prevArray) => [...prevArray, [(clickCount * intervalGraph).toFixed(1), (YValue * canvasScale).toFixed(3)]]);
              setXData((prevArray) => [...prevArray, (YValue * canvasScale).toFixed(3)]);
            } else {
              setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
              setYData((prevArray) => [...prevArray, (clickCount * intervalGraph).toFixed(1)]);
              setXYData((prevArray) => [...prevArray, [(clickCount * intervalGraph).toFixed(1), (YValue * canvasScale * -1).toFixed(3)]]);
              setXData((prevArray) => [...prevArray, (YValue * canvasScale * -1).toFixed(3)]);
            }
          }
          break;
        case "normal":
          if (directionValue === "1") {
            setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
            setXYData((prevArray) => [...prevArray, [parseInt(mouseX), parseInt((400 - mouseY) * canvasScale)]]);
            setXData((prevArray) => [...prevArray, parseInt((400 - mouseY) * canvasScale)]);
            setYData((prevArray) => [...prevArray, parseInt(mouseX)]);
          } else if (directionValue === "2") {
            setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
            setXYData((prevArray) => [...prevArray, [parseInt(mouseY), parseInt(mouseX * canvasScale)]]);
            setXData((prevArray) => [...prevArray, parseInt(mouseX * canvasScale)]);
            setYData((prevArray) => [...prevArray, parseInt(mouseY)]);
          } else {
            setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
            setXYData((prevArray) => [...prevArray, [parseInt(mouseX), parseInt((400 - mouseY) * canvasScale)]]);
            setXData((prevArray) => [...prevArray, parseInt((400 - mouseY) * canvasScale)]);
            setYData((prevArray) => [...prevArray, parseInt(mouseX)]);
          }
          break;
        default:
          if (lessonStats) {

            if (clickCount === 0) {
              if (directionValue === "1") {
                setInitialValue(mouseX);
                setInitialValueY(+initialValueY + + intervalGraph);
                setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
                const xValue = mouseX - mouseX;

                setXData((prevArray) => [...prevArray, (xValue * canvasScale).toFixed(3)]);
                setXYData((prevArray) => [...prevArray, [initialValueY, parseFloat(xValue * canvasScale).toFixed(3)]]);
                setYData((prevArray) => [...prevArray, initialValueY]);
                setInitialValueY(+initialValueY + + intervalGraph);
              } else if (directionValue === "2") {
                setInitialValue(mouseY);
                setInitialValueY(+initialValueY + + intervalGraph);
                setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
                const xValue = mouseY - mouseY;

                setXData((prevArray) => [...prevArray, (xValue * canvasScale).toFixed(3)]);
                setXYData((prevArray) => [...prevArray, [initialValueY, parseFloat(xValue * canvasScale).toFixed(3)]]);
                setYData((prevArray) => [...prevArray, initialValueY]);
                setInitialValueY(+initialValueY + + intervalGraph);
              } else {
                setInitialValue(mouseX);
                setInitialValueY(+initialValueY + + intervalGraph);
                setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
                const xValue = mouseX - mouseX;

                setXData((prevArray) => [...prevArray, (xValue * canvasScale).toFixed(3)]);
                setXYData((prevArray) => [...prevArray, [initialValueY, parseFloat(xValue * canvasScale).toFixed(3)]]);
                setYData((prevArray) => [...prevArray, initialValueY]);
                setInitialValueY(+initialValueY + + intervalGraph);
              }
            } else {
              setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
              let xValue;
              if (dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod === "splitLine") {
                if (directionValue === "1") {
                  if (absoluteCalculation === false) {
                    if (selectValue === "SlowFast") {
                      xValue = ((mouseX - initialValue) * 8);
                    } else if (selectValue === "FastSlow") {
                      xValue = ((mouseX - initialValue) * 7);
                    } else if (selectValue === "TurnAround") {
                      xValue = ((mouseX - initialValue) * 10);
                    } else {
                      xValue = ((mouseX - initialValue) * 9);
                    }
                  } else {
                    if (selectValue === "SlowFast") {
                      xValue = Math.abs((initialValue - mouseX) * 8);
                    } else if (selectValue === "FastSlow") {
                      xValue = Math.abs((initialValue - mouseX) * 7);
                    } else if (selectValue === "TurnAround") {
                      xValue = Math.abs((initialValue - mouseX) * 10);
                    } else {
                      xValue = Math.abs((initialValue - mouseX) * 9);
                    }
                  }

                } else if (directionValue === "2") {
                  if (absoluteCalculation === false) {
                    if (selectValue === "SlowFast") {
                      xValue = ((initialValue - mouseY) * 8);
                    } else if (selectValue === "FastSlow") {
                      xValue = ((initialValue - mouseY) * 7);
                    } else if (selectValue === "TurnAround") {
                      xValue = ((initialValue - mouseY) * 10);
                    } else {
                      xValue = ((initialValue - mouseY) * 9);
                    }
                  } else {
                    if (selectValue === "SlowFast") {
                      xValue = Math.abs((initialValue - mouseY) * 8);
                    } else if (selectValue === "FastSlow") {
                      xValue = Math.abs((initialValue - mouseY) * 7);
                    } else if (selectValue === "TurnAround") {
                      xValue = Math.abs((initialValue - mouseY) * 10);
                    } else {
                      xValue = Math.abs((initialValue - mouseY) * 9);
                    }
                  }

                } else {
                  if (absoluteCalculation === false) {
                    if (selectValue === "SlowFast") {
                      xValue = ((initialValue - mouseX) * 8);
                    } else if (selectValue === "FastSlow") {
                      xValue = ((initialValue - mouseX) * 7);
                    } else if (selectValue === "TurnAround") {
                      xValue = ((initialValue - mouseX) * 10);
                    } else {
                      xValue = ((initialValue - mouseX) * 9);
                    }
                  } else {
                    if (selectValue === "SlowFast") {
                      xValue = Math.abs((initialValue - mouseX) * 8);
                    } else if (selectValue === "FastSlow") {
                      xValue = Math.abs((initialValue - mouseX) * 7);
                    } else if (selectValue === "TurnAround") {
                      xValue = Math.abs((initialValue - mouseX) * 10);
                    } else {
                      xValue = Math.abs((initialValue - mouseX) * 9);
                    }
                  }

                }
              } else {
                if (directionValue === "1") {
                  if (absoluteCalculation === false) {
                    xValue = ((initialValue - mouseX) / 100);
                  } else {
                    xValue = Math.abs((initialValue - mouseX) / 100);
                  }

                } else if (directionValue === "2") {
                  if (absoluteCalculation === false) {
                    xValue = ((initialValue - mouseY) / 100);
                  } else {
                    xValue = Math.abs((initialValue - mouseY) / 100);
                  }
                } else {
                  if (absoluteCalculation === false) {
                    xValue = ((initialValue - mouseX) / 100);
                  } else {
                    xValue = Math.abs((initialValue - mouseX) / 100);
                  }

                }
              }

              setXData((prevArray) => [...prevArray, (xValue * canvasScale).toFixed(3)]);
              setXYData((prevArray) => [...prevArray, [initialValueY, parseFloat(xValue * canvasScale).toFixed(3)]]);

              setYData((prevArray) => [...prevArray, initialValueY]);
              setInitialValueY(+initialValueY + + parseFloat(intervalGraph));
              if (clickCount === images.length - 1) {
                setAlertStatus({ status: true });
                // submitFun();

              }
            }
          }
      }
      // console.log(xyData)

      //@to get later from the DB
      // const fixedValueCurveGraph = ffixedValueCurveGraph



      if (initPayload?.chartType[mappingId]?.calculationMethod === "curve") {

        const yDistance = Math.abs(((initialValues.find(x => x.exp === selectValue)?.value?.y - mouseY) / 10).toFixed(2));
        const xDistance = Math.abs(((initialValues.find(x => x.exp === selectValue)?.value?.x - mouseX) / 10).toFixed(2));
        const Xvalue = Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2)).toFixed(2)
        setXData(prev => [...prev, (Xvalue * canvasScale).toFixed(3)])
        if (yData.length === 0) {
          setYData([parseFloat(initPayload?.exploreStepExperiment[selectedLesson]?.x?.InitialValue)])
        }
        else {
          const val = yData[yData.length - 1] + parseFloat(initPayload?.exploreStepExperiment[selectedLesson]?.chart_interval)
          setYData(prev => [...prev, val])
        }
      }
    } else {
      // submitFun();
      setAlertStatus2({ status: true });

    }
  };

  const resetValue = () => {
    // count = {};
    // count2 = {};
    setScreenShot([])
    setAlertStatus3(false)
    setAlertStatus4(false)
    setClickCountSetter(0);
    setApBtnDisbale(true)
    setBtnClickValueStore([]);
    setActivator(activator + 1);
    setWeights([5, 5, 5, 5, 5]);
    setRandomGenBtn([])
    setClickCount(0);
    setClickCount2(0);
    setX2Value(0);
    setDistanceValue(0);
    setClickCount3(0);
    setClickCount4(0);
    setClickCount5(0);
    setClickCount6(0);
    setSwitcher(0);
    setInitialValueY(0);
    setStoreValue(0);
    setInitialValue([]);
    setCategoryData([]);
    setYData([]);
    setXData([]);
    setXYData([]);
    setMessureMentValues([]);


    if (initPayload.chartType[mappingId]?.calculationMethod !== "Bar") {
      let canvas = document.getElementById("experimentcanvas");

      if (canvas != null) {
        ctx = canvas.getContext("2d")
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    }
  };
  const colorForLessonOne = [{
    exp: 1,
    color: "#9F80E1"
  },
  {
    exp: 2,
    color: "#9CC9E8"
  },
  {
    exp: 3,
    color: "#9F8AA7"
  },
  {
    exp: 4,
    color: "#80A6AB"
  },
  {
    exp: 5,
    color: "#6D7E26"
  },
  {
    exp: 6,
    color: "#B9EEA6"
  },
  {
    exp: 7,
    color: "#FF94DA"
  },
  {
    exp: 8,
    color: "#B1FFE1"
  },
  {
    exp: 9,
    color: "#F4FFA5"
  },
  {
    exp: 10,
    color: "#8FB3D6"
  },
  {
    exp: 11,
    color: "#F5B080"
  },
  {
    exp: 12,
    color: "#DF8080"
  }

  ]
  const BowienGraph = [{
    exp: 1,
    color: "#9CBDEF"
  },
  {
    exp: 2,
    color: "#BB81CC"
  },
  {
    exp: 3,
    color: "#86B389"
  },
  {
    exp: 4,
    color: "#FFB6A6"
  },
  {
    exp: 5,
    color: "#B3877D"
  },
  {
    exp: 6,
    color: "#3e68a7"
  },
  {
    exp: 7,
    color: "#86b389"
  },
  {
    exp: 8,
    color: "#ffb6a6"
  },
  {
    exp: 9,
    color: "#b3877d"
  },
  {
    exp: 10,
    color: "#77d5d8"
  },
  {
    exp: 11,
    color: "#a9b55a"
  },
  {
    exp: 12,
    color: "#bb81cc"
  }

  ]
  const dataSendToMap = initPayload?.exploreStepExperiment;
  const currentIndex = initPayload?.exploreStepExperiment.findIndex(x => x.id === id)
  const selectedOne = initPayload?.exploreStepExperiment.filter(x => x.id === id)
  const selectedExperimentQuestions = selectedOne?.length > 0 ? initPayload.exploreStepQuestions.filter(x => x.explore_step_exp_id == selectedOne[0].id) : []





  useEffect(() => {
    if (initPayload?.hypothesis[0]?.type == 2) {
      setHypoState(1)
      setUserMainHypothesis("")
    }
  }, [initPayload])
  //payload for LineChart




  const payload = {
    labels: categoryData,
    lesson: lesson,
    type: 1,
    datasets: [
      {
        yLabel: yLabelUnit,
        xLabel: xLabelUnit,
        // Labels: xLabel,
        label: selectValue,
        xData: yData,
        yData: initPayload?.chartType[mappingId]?.calculationMethod === "curve" ? xData.map(i => {
          return i?.value
        }) : xData,
        xyData: xyData,
        borderColor: clickState === 0 ? "black" : mainPayload[selectValue]?.borderColor,
        borderWidth: 2,
      },
    ],
  };

  const viewData = datasets.map((i, index) => {
    return {
      type: 2,
      xData: i.yData.length > 0 ? i.yData.map((item, i) => i + 1) : [],
      yData: i.yData.length > 0 ? i.yData : [],
      // labels: i.label !== null && i.label.length > 0 ? i.label : i.displayLabel,
      labels: i.label,
    }

  })

  let tablePayload = {
    type: graphMixStatus,
    labels: descriptionValue.display,
    xData: yData,
    yData: xData,
    speed: speedDataFromGraph,
    speed2: speed2,
    cod: codDataFromGraph,
  };
  // console.log(tablePayload)
  const thirdLessonTablePayload = {
    labels: [yData],
    type: 3,
    yLabel: yLabelUnit,
    datasets: datasets.map(i => {
      return {
        label: i.label,
        yData: i.yData,
        borderColor: i.color,
      }
    })
  }

  const thirdLessonTablePayloadMixData = {
    labels:
      datasets.map(i => {
        return i.xData
      }),
    type: 3,
    yLabel: yLabelUnit,
    datasets: datasets.map(i => {
      return {
        label: i.label,
        yData: i.yData,
        borderColor: i.color,
      }
    })
  }

  const dataFromGraph = (fitValue, speed, speed2) => {

    setSpeedDataFromGraph(speed);

    setCodDataFromGraph(fitValue);

    setSpeed2(speed2)

  };

  const junkDataFromGraph = (fitValue, speed) => {
  };


  const getSelectValue = (data) => {
    setSelectValue(data);
  }

  const getMappingId = (data) => {
    setMappingId(data);
  }

  const getId = (data) => {
    setId(data)
  }

  const getVisualName = (data) => {
    setVisualName(data)
  }


  const handleChange = (e) => {
    const { checked, value } = e.target;
    const { data } = userMainHypothesis;
    if (checked) {
      setUserMainHypothesis(value);
      setHypoState(1)
    }
  };

  const handleChange2 = (e) => {
    const { value } = e.target;
    setUserMainHypothesis(value);
    setDisableState(false);

    if (value === "") {
      setDisableState(true);
    }
  };

  const handleConfirm = () => {
    setShowConfirm(false);

    return setUserMainHypothesis({
      data: [userIHypothesis],
    });
  };

  const handleCancel = () => {
    return setShowConfirm(false);
  };

  useEffect(() => {
    if (selectValue !== undefined && selectValue !== "") {
      if (initPayload?.chartType[mappingId]?.calculationMethod !== "Bar" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type5" && initPayload?.chartType[mappingId]?.calculationMethod !== "Bar YesNo") {

        setMainPayload(prev => ({
          ...prev,
          [selectValue]: {
            userMainHypothesis: userMainHypothesis,
            label: selectValue,
            displayLabel: descriptionValue.display,
            xData: yData,
            yData: xData,
            xyData: xyData,
            categoryData: categoryData,
            speed: speedDataFromGraph,
            cod: codDataFromGraph,
            speed2: speed2,
            borderColor: experimentColor,
            borderWidth: 2,
            mappingId: parseInt(mappingId),
          }
        }))

      }
      else if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar YesNo" && messureMentValues.length > 0) {

        setMainPayload(prev => ({
          ...prev,
          [selectValue]: {
            userMainHypothesis: userMainHypothesis,
            label: selectValue,
            displayLabel: descriptionValue.display,
            xData: yData,
            yData: messureMentValues,
            xyData: xyData,
            categoryData: categoryData,
            speed: speedDataFromGraph,
            cod: codDataFromGraph,
            speed2: speed2,
            borderColor: experimentColor,
            borderWidth: 2,
            mappingId: parseInt(mappingId),
          }
        }))

      }
      else if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar" || initPayload?.chartType[mappingId]?.calculationMethod === "Line Type5") {
        setMainPayload(prev => ({
          ...prev,
          [selectValue]: {
            userMainHypothesis: userMainHypothesis,
            label: selectValue,
            displayLabel: descriptionValue.display,
            xData: selectImg.map((item, i) => i + 1),
            yData: messureMentValues,
            borderColor: experimentColor,
            borderWidth: 2,
            mappingId: parseInt(mappingId),
          }
        }))
      }
    }


  }, [selectValue, userMainHypothesis, yData, xData, speedDataFromGraph, codDataFromGraph, messureMentValues, speed2, mappingId]);

  const submitFun = async () => {
    setLoading(true)
    if (mainPayload[Object.keys(mainPayload).find(i => i === selectValue)].yData.length !== undefined) {
      if (mainPayload[Object.keys(mainPayload).find(i => i === selectValue)].yData.length > 0) {
        if (!(selectionOption.findIndex(x => x.name === selectValue) !== -1)) {
          setSelectionOption(prev => [...prev, {
            name: selectValue,
            isChecked: false

          }])
        }
        const sendPayload = {
          userName: user,
          userId: initPayload?.id,
          status: 2,
          response_jason: {
            answers: mainPayload,
            hypothesis: userMainHypothesis
          },
          last_update_date: new Date().toISOString().slice(0, 10),
          explore_step_id: props.exploreId,
        }


        // await RestService.postExploreStepStatus(sendPayload)
        //   .then((response) => {
        //     if (response?.status === 200) {
        //       const end = new Date();
        //       props.getEndDuration(end);
        //       setCreatingTrigger(1);
        //       setLoading(false)

        //     }
        //     setLoading(false)

        //   })
      }
    } else {

      if (Object.values(mainPayload[Object.keys(mainPayload).find(i => i === selectValue)].yData).length > 0) {
        if (!(selectionOption.findIndex(x => x.name === selectValue) !== -1)) {
          setSelectionOption(prev => [...prev, {
            name: selectValue,
            isChecked: false

          }])
        }
        const sendPayload = {
          userName: user,
          userId: initPayload?.id,
          status: 2,
          response_jason: {
            answers: mainPayload
          },
          last_update_date: new Date().toISOString().slice(0, 10),
          explore_step_id: props.exploreId,
        }



        // await RestService.postExploreStepStatus(sendPayload)
        //   .then((response) => {

        //     if (response.status === 200) {

        //       const end = new Date();
        //       props.getEndDuration(end);
        //       setCreatingTrigger(1);
        //       setLoading(false);  
        //     }
        //     setLoading(false)

        //   })
      }
      setLoading(false)
    }


  }
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(true);
  const togglePopup = () => setModal(!modal);

  function renderHypothesis(data) {

    if (hypoState === 0) {
      let dropDown =
        data === undefined
          ? null
          : data?.hypotheisOptions.length > 0 ? (
            data?.hypotheisOptions?.map((x, id) => {

              if (data?.hypothesis[0]?.type === 1) {
                return (<div>
                  <strong className="">
                    <div className="form-check m-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="hypothesis"
                        value={x.option}
                        id={id}
                        onChange={handleChange}
                      />
                      &nbsp;
                      {x.option}
                    </div>
                  </strong>
                </div>
                );
              } else if (data?.hypothesis[0]?.type === 2) {
                // console.log("thankgod",modal)
                return (
                  <div>

                    <strong className="">
                      <div className="form-text m-3">

                        {/* <Modal isOpen={modal2} toggle={togglePopup}>
                          <ModalHeader toggle={togglePopup}></ModalHeader>
                          <ModalBody>
                            <center>{initPayload?.hypothesis[0]?.description}</center>
                            <div className="popup-text">
                              <TextArea rows={5} onChange={handleChange2} />
                            </div>
                          </ModalBody>
                          <ModalFooter className="center">
                            <Button
                              color="success"
                              onClick={() => {
                                setHypoState(1)
                                setModal2(false)
                              }}
                              disabled={disableState}
                            >
                              Submit
                            </Button>
                          </ModalFooter>
                        </Modal> */}
                      </div>
                    </strong>
                  </div>
                );
              }
            })
          ) : (
            <div>

              <strong className="">
                <div className="form-text">
                  <strong>Student written hypothesis goes here</strong>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <textarea class="form-control tarea mb-3" id="textarea" name="textarea" disabled></textarea>
                    </div>
                  </div>

                  {/* <Modal isOpen={modal2} toggle={togglePopup}>
                  <ModalHeader toggle={togglePopup}></ModalHeader>
                  <ModalBody>
                    <center>{initPayload?.hypothesis[0]?.description}</center>
                    <div className="popup-text">
                      <TextArea rows={5} onChange={handleChange2} />
                    </div>
                  </ModalBody>
                  <ModalFooter className="center">
                    <Button
                      color="success"
                      onClick={() => {
                        setHypoState(1)
                        setModal2(false)
                      }}
                      disabled={disableState}
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </Modal> */}
                </div>
              </strong>
            </div>
          )






      return (
        <Row>
          <Col md="8">
            <h3 className="">{initPayload?.hypothesis[0]?.description}</h3>
            <br />
            {(() => {
              if (initPayload?.hypothesis[0]?.type === 1) {
                return (
                  <p className="">Pick a hypothesis below</p>
                )
              } else if (initPayload?.hypothesis[0]?.type === 2) {
                return (
                  <p className="">
                    <Badge
                      className="btn btn-dark border-0 round-0 hype-button"
                      onClick={togglePopup}
                    >
                      Write Hypothesis
                    </Badge></p>
                )
              }
            })}
            {dropDown}


            <br />
          </Col>
          <Col></Col>
        </Row>
      );

    } else {

      if (data?.hypothesis[0]?.type === 1) {
        return (
          <Row>
            <Col md="8" className="hypo">
              <h3 className="">{initPayload?.hypothesis[0]?.description}</h3>
              <br />
              <Row>
                <Col md="12">
                  <fieldset>
                    <legend><strong>Hypothesis </strong> </legend>
                    <p className="f15 hype-text">
                      {userMainHypothesis}
                    </p>
                  </fieldset>
                </Col>
              </Row>

              <br />
            </Col>

            <Col></Col>
          </Row>
        )
      }
      else {
        return (
          <div>

            <strong className="">
              <div className="form-text">
                <strong>Student written hypothesis goes here</strong>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <textarea class="form-control tarea mb-3" id="textarea" name="textarea" disabled></textarea>
                  </div>
                </div>

                {/* <Modal isOpen={modal2} toggle={togglePopup}>
                <ModalHeader toggle={togglePopup}></ModalHeader>
                <ModalBody>
                  <center>{initPayload?.hypothesis[0]?.description}</center>
                  <div className="popup-text">
                    <TextArea rows={5} onChange={handleChange2} />
                  </div>
                </ModalBody>
                <ModalFooter className="center">
                  <Button
                    color="success"
                    onClick={() => {
                      setHypoState(1)
                      setModal2(false)
                    }}
                    disabled={disableState}
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Modal> */}
              </div>
            </strong>
          </div>
        )
      }

    }

  }
  const changeHandlerRadio = (event) => {
    setClickState(1)
    const name = event.target.value;
    // console.log(name)
    // setChecked(false)
    // if (check === true) {
    selectionOption.map(x => {
      if (x.name === name) {
        x.isChecked = true
      }
    })


    if (initPayload?.chartType[mappingId]?.calculationMethod === "curve") {
      if (containedPayload?.response_jason?.answers[name].label === name) {

        if (datasets.findIndex(x => x.label2 === name) !== -1) {

          if (clickState !== 0) {

            let res = datasets.filter(x => x.label2 === name)
            setDatasets(res)

          } else {
            setDatasets([{
              label: containedPayload?.response_jason?.answers[name].displayLabel,
              label2: containedPayload?.response_jason?.answers[name].label,
              yData: containedPayload?.response_jason?.answers[name].yData,
              xData: containedPayload?.response_jason?.answers[name].xData,
              color: containedPayload?.response_jason?.answers[name].borderColor,

            }])
          }

        }
        else {
          setDatasets(prev => (
            [
              ...prev,
              {
                label: containedPayload?.response_jason?.answers[name].displayLabel,
                label2: containedPayload?.response_jason?.answers[name].label,
                yData: containedPayload?.response_jason?.answers[name].yData,
                xData: containedPayload?.response_jason?.answers[name].xData,
                color: containedPayload?.response_jason?.answers[name].borderColor,

              }
            ]
          ))
        }

      }
    }
    if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar YesNo") {
      if (containedPayload?.response_jason?.answers[name].label === name) {
        if (datasets.findIndex(x => x.label2 === name) !== -1) {
          const sameNamePayload = [{
            label: containedPayload?.response_jason?.answers[name].displayLabel,
            label2: containedPayload?.response_jason?.answers[name].label,
            yData: containedPayload?.response_jason?.answers[name].yData,
            color: containedPayload?.response_jason?.answers[name].yData.map(item => {
              return item?.color
            }),
            borderColor: containedPayload?.response_jason?.answers[name].borderColor,
          }]
          setDatasets(sameNamePayload)
        }
        else {
          setDatasets(prev => (
            [
              ...prev,
              {
                label: containedPayload?.response_jason?.answers[name].displayLabel,
                label2: containedPayload?.response_jason?.answers[name].label,
                yData: containedPayload?.response_jason?.answers[name].yData,
                color: containedPayload?.response_jason?.answers[name].yData.map(item => {
                  return item?.color
                }),
                borderColor: containedPayload?.response_jason?.answers[name].borderColor,
              }
            ]
          ))
        }

      }
    }
    else {
      if (containedPayload?.response_jason?.answers[name].label === name && initPayload?.chartType[mappingId]?.calculationMethod !== "Bar YesNo" && initPayload?.chartType[mappingId]?.calculationMethod !== "curve") {
        if (datasets.findIndex(x => x.label2 === name) !== -1) {

          if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar" || initPayload?.chartType[mappingId]?.calculationMethod === "Line Type5") {
            const sameNamePayload = [{
              label: containedPayload?.response_jason?.answers[name].displayLabel,
              label2: containedPayload?.response_jason?.answers[name].label,
              yData: containedPayload?.response_jason?.answers[name].yData.map(item => {
                return item?.value
              }),
              color: containedPayload?.response_jason?.answers[name].yData.map(item => {
                return item?.color
              }),
              borderColor: containedPayload?.response_jason?.answers[name].borderColor,
            }]
            setDatasets(sameNamePayload)
          } else {
            datasets.filter(x => x.label2 === name)
            setDatasets(datasets)
          }

        }
        else {
          setDatasets(prev => (
            [
              ...prev,
              {
                label: containedPayload?.response_jason?.answers[name].displayLabel,
                label2: containedPayload?.response_jason?.answers[name].label,
                yData: containedPayload?.response_jason?.answers[name].yData.map(item => {
                  return item?.value
                }),
                color: containedPayload?.response_jason?.answers[name].yData.map(item => {
                  return item?.color
                }),
                borderColor: containedPayload?.response_jason?.answers[name].borderColor,
              }
            ]
          ))
        }

      }
    }
    setSelectionOption(selectionOption)
    setCellId(prev => ({
      [name]: name
    }))

  }

  const changeHandler = (event) => {
    setClickState(1)

    const check = event.target.checked;
    const name = event.target.name;
    setChecked(false)
    if (check === true) {
      selectionOption.map(x => {
        if (x.name === name) {
          x.isChecked = true
        }
      })


      if (initPayload?.chartType[mappingId]?.calculationMethod === "curve") {
        if (containedPayload?.response_jason?.answers[name].label === name) {

          if (datasets.findIndex(x => x.label2 === name) !== -1) {

            if (clickState !== 0) {

              let res = datasets.filter(x => x.label2 === name)
              setDatasets(res)

            } else {
              setDatasets([{
                label: containedPayload?.response_jason?.answers[name].displayLabel,
                label2: containedPayload?.response_jason?.answers[name].label,
                yData: containedPayload?.response_jason?.answers[name].yData,
                xData: containedPayload?.response_jason?.answers[name].xData,
                color: containedPayload?.response_jason?.answers[name].borderColor,

              }])
            }

          }
          else {
            setDatasets(prev => (
              [
                ...prev,
                {
                  label: containedPayload?.response_jason?.answers[name].displayLabel,
                  label2: containedPayload?.response_jason?.answers[name].label,
                  yData: containedPayload?.response_jason?.answers[name].yData,
                  xData: containedPayload?.response_jason?.answers[name].xData,
                  color: containedPayload?.response_jason?.answers[name].borderColor,

                }
              ]
            ))
          }

        }
      }
      if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar YesNo") {
        if (containedPayload?.response_jason?.answers[name].label === name) {
          if (datasets.findIndex(x => x.label2 === name) !== -1) {
            const sameNamePayload = [{
              label: containedPayload?.response_jason?.answers[name].displayLabel,
              label2: containedPayload?.response_jason?.answers[name].label,
              yData: containedPayload?.response_jason?.answers[name].yData,
              color: containedPayload?.response_jason?.answers[name].yData.map(item => {
                return item?.color
              }),
              borderColor: containedPayload?.response_jason?.answers[name].borderColor,
            }]
            setDatasets(sameNamePayload)
          }
          else {
            setDatasets(prev => (
              [
                ...prev,
                {
                  label: containedPayload?.response_jason?.answers[name].displayLabel,
                  label2: containedPayload?.response_jason?.answers[name].label,
                  yData: containedPayload?.response_jason?.answers[name].yData,
                  color: containedPayload?.response_jason?.answers[name].yData.map(item => {
                    return item?.color
                  }),
                  borderColor: containedPayload?.response_jason?.answers[name].borderColor,
                }
              ]
            ))
          }

        }
      }
      else {
        if (containedPayload?.response_jason?.answers[name].label === name && initPayload.chartType[mappingId]?.calculationMethod !== "Bar YesNo" && initPayload.chartType[mappingId]?.calculationMethod !== "curve") {
          if (datasets.findIndex(x => x.label2 === name) !== -1) {

            if (initPayload.chartType[mappingId]?.calculationMethod === "Bar" || initPayload.chartType[mappingId]?.calculationMethod === "Line Type5") {
              const sameNamePayload = [{
                label: containedPayload?.response_jason?.answers[name].displayLabel,
                label2: containedPayload?.response_jason?.answers[name].label,
                yData: containedPayload?.response_jason?.answers[name].yData.map(item => {
                  return item?.value
                }),
                color: containedPayload?.response_jason?.answers[name].yData.map(item => {
                  return item?.color
                }),
                borderColor: containedPayload?.response_jason?.answers[name].borderColor,
              }]
              setDatasets(sameNamePayload)
            } else {
              datasets.filter(x => x.label2 === name)
              setDatasets(datasets)
            }

          }
          else {
            setDatasets(prev => (
              [
                ...prev,
                {
                  label: containedPayload?.response_jason?.answers[name].displayLabel,
                  label2: containedPayload?.response_jason?.answers[name].label,
                  yData: containedPayload?.response_jason?.answers[name].yData.map(item => {
                    return item?.value
                  }),
                  color: containedPayload?.response_jason?.answers[name].yData.map(item => {
                    return item?.color
                  }),
                  borderColor: containedPayload?.response_jason?.answers[name].borderColor,
                }
              ]
            ))
          }

        }
      }
      setSelectionOption(selectionOption)
      setCellId(prev => ({
        ...prev,
        [name]: name
      }))
    } else {
      setDatasets(datasets.filter(x => x.label2 !== name))
      selectionOption.map(x => {
        if (x.name === name) {
          x.isChecked = false
        }
      })
      if (selectionOption.findIndex(x => x.isChecked === true) > -1) {

      }
      else {
        setClickState(2)
      }
      setSelectionOption(selectionOption)
      setChecked(false)
      delete cellId[name];
      setCellId(prev => ({
        ...prev,
      }))
    }
  }
  const changeHandler3 = (event) => {
    const check = event.target.checked;
    const name = event.target.name;

    if (check === true) {
      selectionOption.map(x => {
        x.isChecked = true
      });
      setClickState(1)

      setSelectionOption(selectionOption)
      setChecked(true)
      name.map((x) => {
        if (initPayload?.chartType[mappingId]?.calculationMethod === "curve") {

          if (containedPayload?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label2 === x) !== -1) {

            }
            else {
              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: containedPayload?.response_jason?.answers[x].displayLabel,
                    label2: containedPayload?.response_jason?.answers[x].label,
                    yData: containedPayload?.response_jason?.answers[x].yData,
                    xData: containedPayload?.response_jason?.answers[x].xData,
                    color: containedPayload?.response_jason?.answers[x].borderColor,


                  }
                ]
              ))



            }

          }
        } else if (initPayload?.chartType[mappingId]?.calculationMethod === "Bar" || initPayload?.chartType[mappingId]?.calculationMethod === "Line Type5") {
          if (containedPayload?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label2 === x) !== -1) {

            }
            else {
              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: containedPayload?.response_jason?.answers[x].displayLabel,
                    label2: containedPayload?.response_jason?.answers[x].label,
                    yData: containedPayload?.response_jason?.answers[x].yData.map(i => {
                      return i?.value
                    }),
                    xData: containedPayload?.response_jason?.answers[x].xData,
                    borderColor: containedPayload?.response_jason?.answers[x].borderColor,
                    color: containedPayload?.response_jason?.answers[x].yData.map(i => {
                      return i?.color
                    })


                  }
                ]
              ))



            }

          }
        } else if (initPayload.chartType[mappingId]?.calculationMethod === "Bar YesNo") {

          if (containedPayload?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label2 === x) !== -1) {

            }
            else {
              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: containedPayload?.response_jason?.answers[x].displayLabel,
                    label2: containedPayload?.response_jason?.answers[x].label,
                    yData: containedPayload?.response_jason?.answers[x].yData.map(i => {
                      return i
                    }),
                    xData: containedPayload?.response_jason?.answers[x].xData,
                    borderColor: containedPayload?.response_jason?.answers[x].borderColor,
                    color: containedPayload?.response_jason?.answers[x].yData.map(i => {
                      return i?.color
                    })


                  }
                ]
              ))



            }

          }
        }
        else {
          if (containedPayload?.response_jason?.answers[x].label === x) {
            if (datasets.findIndex(x => x.label2 === x) !== -1) {


            }
            else {
              setDatasets(prev => (
                [
                  ...prev,
                  {
                    label: containedPayload?.response_jason?.answers[x].displayLabel,
                    label2: containedPayload?.response_jason?.answers[x].label,
                    yData: containedPayload?.response_jason?.answers[x].yData.map(i => {
                      return i?.value
                    }),
                    color: containedPayload?.response_jason?.answers[x].yData.map(i => {
                      return i?.color
                    }),
                    borderColor: containedPayload?.response_jason?.response_jason?.answers[x].borderColor,


                  }
                ]
              ))



            }

          }
        }

        setCellId(prev => ({
          ...prev,
          [x]: x
        }))
      })
    } else {
      setDatasets([{
        label: null,
        yData: [],
        xData: []
      }])
      selectionOption.map(x => {
        x.isChecked = false
      });
      setClickState(2)
      setSelectionOption(selectionOption)
      setChecked(false)
      setGraphMixStatus(false)
      setCellId("");
    }
  }

  const setExColor = (color) => {
    setExperimentColor(color)
  }
  useEffect(() => {
    const createPayloadForAll = []
    selectionOption.map((x) => {
      createPayloadForAll.push(x.name)
    });

    setAllGraphValue(createPayloadForAll)
  }, [selectionOption]);


  const optionsDisplay = selectionOption?.filter(x => x.name !== "undefined").map((x, i) => {
    const styleValue = `ex`;
    return (
      <div className="chk-div">
        <Checkbox checked={x.isChecked} className="bold" name={x.name} onChange={changeHandler}> <span className={styleValue.toLowerCase()} style={{ backgroundColor: x.color }}  >{x.displayName} </span></Checkbox>

      </div>
    )
  })

  const optionsDisplay2 = selectionOption?.filter(x => x.name !== "undefined").map((x, i) => {
    const styleValue = `ex`;
    return (
      <div className="">
        {/* <Checkbox checked={x.isChecked} className="bold" name={x.name} onChange={changeHandler}> <span className={styleValue.toLowerCase()} style={{backgroundColor:x.color}}  >{x.displayName} </span></Checkbox> */}
        <Radio value={x.name} onChange={changeHandlerRadio}>
          <span className={styleValue.toLowerCase()} style={{ backgroundColor: x.color }}  >{x.displayName} </span>
        </Radio>
      </div>
    )
  })

  const onNextValue = () => {
    // setDestatus(true)
    setCategoryData((prevArray) => [...prevArray, clickCount3 + 1]);

    setYData((prevArray) => [...prevArray, clickCount3 * intervalGraph]);
    setXYData((prevArray) => [...prevArray, [clickCount3 * intervalGraph, clickCount2]]);
    setXData((prevArray) => [...prevArray, clickCount2]);
    setClickCount(clickCount + 1)
    setClickCount3(clickCount3 + 1)
    setSwitcher(0)
  }

  const onNextValue2 = () => {
    setClickCount(clickCount + 1)
    setClickCount2(0)
    setSwitcher(0)
  }

  // let count = {};
  // let count2 = {};

  const onNextValue3 = () => {

    const count = {};

    for (const value of btnClickValueStore) {
      if (value in count) {
        count[value] += 1;
      } else {
        count[value] = 1;
      }
    }

    const ladybugValue = count.ladybug ? weights[0] - parseInt(count.ladybug) : weights[0];
    const grasshopperValue = count.grasshopper ? weights[1] - parseInt(count.grasshopper) : weights[1];
    const pillBugValue = count.pillBug ? weights[2] - parseInt(count.pillBug) : weights[2];
    const scarabValue = count.scarab ? weights[3] - parseInt(count.scarab) : weights[3];
    const aphidValue = count.aphid ? weights[4] - parseInt(count.aphid) : weights[4];

    // console.log({count, weights})

    const eatenTotal = ladybugValue + grasshopperValue + pillBugValue + scarabValue + aphidValue;
    const ratio = 25 / eatenTotal;

    const ladybugValue2 = Math.ceil(ladybugValue * ratio) <= 0 ? 0 : Math.ceil(ladybugValue * ratio);
    const grasshopperValue2 = Math.ceil(grasshopperValue * ratio) <= 0 ? 0 : Math.ceil(grasshopperValue * ratio);
    const pillBugValue2 = Math.ceil(pillBugValue * ratio) <= 0 ? 0 : Math.ceil(pillBugValue * ratio);
    const scarabValue2 = Math.ceil(scarabValue * ratio) <= 0 ? 0 : Math.ceil(scarabValue * ratio);
    const aphidValue2 = Math.ceil(aphidValue * ratio) <= 0 ? 0 : Math.ceil(aphidValue * ratio);

    const array1 = [ladybugValue, grasshopperValue, pillBugValue, scarabValue, aphidValue]
    const array2 = [ladybugValue2, grasshopperValue2, pillBugValue2, scarabValue2, aphidValue2]
    // Scale the array so that the total sum is 25
    let sum = array2.reduce((acc, cur) => acc + cur, 0);
    let scaleFactor = 25 / sum;
    let scaledNumbers = array2.map(num => parseInt((num * scaleFactor).toFixed(0)));


    // if(btnClickValueStore.length === 0){
    //   setCategoryData((prevArray) => [...prevArray, clickCount+1]);
    //   setXYData((prevArray) => [...prevArray, [clickCount+1,array1]]);
    //   setXData((prevArray) => [...prevArray,array1]);
    //   setYData((prevArray) => [...prevArray, clickCount+1]);
    // }
    setWeights(scaledNumbers);
    // setTriggerNSLab(true);
    setBtnClickValueStore([]);
    setClickCount(clickCount + 1);
  }
  const onNextValue4 = () => {
    setClickState(0);

    const result = [];
    setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
    setXYData((prevArray) => [...prevArray, [clickCount + 1, weights]]);
    setXData((prevArray) => [...prevArray, weights]);
    setYData((prevArray) => [...prevArray, clickCount + 1]);

    for (let i = 0; i < buttonData.length; i++) {
      for (let j = 0; j < weights[i]; j++) {
        let x, y;
        do {
          x = Math.floor(Math.random() * 450);
          y = Math.floor(Math.random() * 350);
        } while (result.some(obj => Math.abs(obj.x - x) < 45 && Math.abs(obj.y - y) < 45) && x < 450 && y < 350);
        const obj = {
          id: result.length,
          name: buttonData[i].name,
          x,
          y,
        };
        result.push(obj);
      }
    }
    // while (result.length > 25) {
    //   result.pop();
    // }
    // setTriggerNSLab(true);

    setRandomGenBtn(result)
  }
  const onNextValue5 = () => {

    if (clickCount >= clickCount2) {
      setClickCount2(clickCount2 + 1);
    }
    let canvas = document.getElementById("experimentcanvas");
    ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    setClickCount(clickCount + 1);
    setClickCount3(0);
    setClickCount(clickCount + 1);
    setClickCount3(0);
  }
  const canvasDisable = (value) => {
    setCanvasDisableSetter(value)
  }

  const canvasBtnDisable = (value) => {
    setCanvasBtnDisableSetter(value)
  }

  var hyp = renderHypothesis(initPayload);
  return (
    <Spin tip="Loading..." spinning={loading} size="large">

      <div className="content explore-page tab-style scroll-tab">
        <Row>
          <Col md="12">
            <Card className="card-chart">
              {
                initPayload?.description !== "" && initPayload?.description !== null &&
                initPayload?.description !== undefined &&
                <>
                  <CardBody className="mt-10 ">
                    <h5> LAB DESCRIPTION </h5>
                    <hr className="hr-dark" />
                    <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div>
                  </CardBody>
                </>
              }

              {
                initPayload?.guideline !== "" && initPayload?.guideline !== null &&
                initPayload?.guideline !== undefined &&
                <>
                  <CardBody className="mt-20">

                    <h5> LAB GUIDELINE</h5>
                    <hr className="hr-dark" />
                    <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.guideline }}></div>
                  </CardBody>
                </>

              }

              <br />
              <CardBody>
                {hyp}
                <div className={hypoState === 0 ? "blur" : ""} style={{display:'flex', flexDirection:'row', overflowX:'auto',justifyContent:'space-between'}}>
                  <div md="" className="zoom fixed-md">
                    <MapChild
                      buttonData={randomGenBtn}
                      lesson={initPayload?.lessonCode}
                      chartType={initPayload?.chartType[mappingId]?.calculationMethod}
                      data={dataSendToMap}
                      count={clickCount}
                      disableState={canvasDisableSetter}
                      btnDisableState={canvasBtnDisableSetter}
                      id={getId}
                      mappingId={getMappingId}
                      visualName={getVisualName}
                      exColor={setExColor}
                      onCanvasClick={onCanvasClick}
                      onCanvasClick2={onCanvasClick2}
                      resetValue={resetValue}
                      selectedValue={getSelectValue}
                      toggleTab={(videoEnd) => {
                        setDestatus(videoEnd);
                      }}
                    />


                    <ReactJsAlert
                      status={alertStatus?.status} // true or false
                      type="Completed" // success, warning, error, info
                      title="Click save button to save changes" // title you want to display
                      Close={() => setAlertStatus({ status: false })} // callback method for hide
                    />

                    <ReactJsAlert
                      status={alertStatus3} // true or false
                      type="warning" // success, warning, error, info
                      title="Enter population count by counting the people icons on left" // title you want to display
                      Close={() => setAlertStatus3(false)} // callback method for hide
                    />

                    <ReactJsAlert
                      status={alertStatus4} // true or false
                      type="warning" // success, warning, error, info
                      title="Click on scale where color matches sky just below" // title you want to display
                      Close={() => setAlertStatus4(false)} // callback method for hide
                    />

                    <ReactJsAlert
                      status={alertStatus2?.status} // true or false
                      type="Completed" // success, warning, error, info
                      title="Click save button to save changes" // title you want to display
                      Close={() =>
                        // {
                        setAlertStatus2({ status: false })
                        //   submitFun()
                        // }
                      } // callback method for hide
                    />
                    {
                      videoEnd &&


                      <Col md="" className="pt-5">

                        <AnswerDisplay
                          onNextValue={onNextValue}
                          onNextValue2={onNextValue2}
                          onNextValue3={onNextValue3}
                          onNextValue4={onNextValue4}
                          onNextValue5={onNextValue5}
                          canvasDisable={canvasDisable}
                          canvasBtnDisable={canvasBtnDisable}
                          data={{
                            type: 3,
                            resetValue: activator,
                            videoEnd: videoEnd,
                            lesson: lesson,
                            chartType: initPayload?.chartType[mappingId]?.calculationMethod,
                            clickCount: clickCount,
                            initPayload: initPayload?.exploreStepInfoInstructions.find(x => x.experiment_id === selectedOne[0]?.id),
                            currentIndex: clickCount === initPayload?.exploreStepExperiment.length,
                            data: selectedExperimentQuestions[0],
                            disableValue: dataSendToMap.find(x => x.id === id).chart_type[mappingId].calculationMethod === "Bar Click2Count" ? selectImg.length - 1 < clickCount + 1 : selectImg.length - 1 < clickCount,
                            disableValue2: apBtnDisbale,

                            onClick: (input) => {
                              selectionOption.map(x => {
                                x.isChecked = false
                              });
                              setClickState(0)
                              setSelectionOption(selectionOption)

                              const matchingQuestions = initPayload?.exploreStepQuestionAnswers.filter(
                                x => x.question_id === selectedExperimentQuestions[0].id
                              );
                              const checkTheRange = matchingQuestions[clickCount]

                              if (checkTheRange?.answer_type === "R") {
                                if (parseFloat(checkTheRange?.answer1) <= parseFloat(input) && parseFloat(checkTheRange?.answer2) >= parseFloat(input)) {
                                  setMessureMentValues(prev => [...prev, { value: parseFloat(input), color: "green" }])
                                }
                                else {
                                  setMessureMentValues(prev => [...prev, { value: parseFloat(input), color: "red" }])
                                }
                              }
                              setClickCount(clickCount + 1);

                            },

                            // for input output
                            onClick2: (input) => {
                              selectionOption.map(x => {
                                x.isChecked = false
                              });
                              setClickState(0)
                              setSelectionOption(selectionOption)
                              setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
                              setXYData((prevArray) => [...prevArray, [parseFloat((clickCount * intervalGraph) + initialXValue), parseFloat(input)]]);
                              setYData((prevArray) => [...prevArray, parseFloat((clickCount * intervalGraph) + initialXValue)]);
                              setXData((prevArray) => [...prevArray, parseFloat(input)]);
                              setClickCount(clickCount + 1);

                            },

                            //for air pollution 
                            onClick3: (input) => {
                              selectionOption.map(x => {
                                x.isChecked = false
                              });
                              setApBtnDisbale(true)
                              setClickState(0)
                              setClickCountSetter(0)
                              setSelectionOption(selectionOption)
                              setCategoryData((prevArray) => [...prevArray, clickCount + 1]);
                              setXYData((prevArray) => [...prevArray, [parseInt(input), parseFloat(tempYValue)]]);
                              setXData((prevArray) => [...prevArray, parseFloat(tempYValue)]);
                              setYData((prevArray) => [...prevArray, parseInt(input)]);
                              setClickCount(clickCount + 1);

                            }


                          }} />

                      </Col>
                    }


                  </div>
                  {videoEnd && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type7" && initPayload?.chartType[mappingId]?.calculationMethod !== "Bar YesNo" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type5" ? (
                    <>
                      {initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type6" ? (
                        <div md="" className="dtable" style={{marginLeft:16, marginRight:16}}>
                          <CordinateDisplayBox
                            dataTableValue={dataTableValue}
                            chartData={{ xLabel, yLabel, xLabelUnit, yLabelUnit }}
                            data={{ clickState: clickState, lesson: initPayload?.lessonCode, chartType: initPayload?.chartType[mappingId]?.calculationMethod, data: initPayload?.chartType[mappingId]?.calculationMethod !== "Bar" ? clickState === 0 ? tablePayload : (graphMixStatus ? responseMixData : tablePayload2) : initPayload?.chartType[mappingId]?.calculationMethod === "Bar" && viewData }} />
                        </div>
                      ) : (

                        <div xl="" className="dtable" style={{marginLeft:16, marginRight:16}}>
                          <CordinateDisplayBox
                            dataTableValue={dataTableValue}
                            chartData={{ xLabel, yLabel, xLabelUnit, yLabelUnit }}
                            data={{ clickState: clickState, lesson: initPayload?.lessonCode, chartType: initPayload?.chartType[mappingId]?.calculationMethod, data: initPayload?.chartType[mappingId]?.calculationMethod !== "Bar" ? clickState === 0 ? tablePayload : (graphMixStatus ? responseMixData : tablePayload2) : initPayload?.chartType[mappingId]?.calculationMethod === "Bar" && viewData }} />
                        </div>
                      )}
                    </>
                  ) : ("")}

                  {
                    // videoEnd && lessonStats !== true && 

                    <div xs="" className="chdiv" style={{marginTop:'1.8rem', minWidth:'540px'}}>
                      {videoEnd ? (
                        <>

                          <Col md="12">
                            <div className="filters">
                              {initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type6" &&
                                initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type11" &&
                                initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type7" &&
                                <div className="chk-div">
                                  <Checkbox disabled={clickState === 0} checked={checked} className="bold" name={allGraphValue} onChange={changeHandler3}>
                                    <span className="all"><b>All</b></span>
                                  </Checkbox>
                                </div>
                              }
                              {initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type6" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type11" && initPayload?.chartType[mappingId]?.calculationMethod !== "Line Type7" ? optionsDisplay :
                                (
                                  <div className="row">
                                    <Radio.Group >
                                      {optionsDisplay2}
                                    </Radio.Group>
                                  </div>
                                )
                              }
                            </div>
                          </Col>

                          <Col md="12">
                            <div className="chart">
                            {dataSendToMap.find(x => x.id === id)?.y !== undefined && dataSendToMap.find(x => x.id === id).y !== null &&
                                  <>
                                    {dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType !== "Line Type10" &&
                                      dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType !== "Line Type6" &&
                                      dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType !== "Line Type11" &&
                                      dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType !== "Line Type12" && (
                                        <>
                                          {yData.length !== 0 || viewData.length !== 0 ? (
                                            <div className="distance">
                                              {clickState !== 0 ? yLabel : clickCount !== 0 ? yLabel : ""}</div>
                                          ) : ""}
                                        </>
                                      )}
                                    {dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType === "Line Type6" && (
                                      <>
                                        {yData.length !== 0 || viewData.length !== 0 ? (
                                          <div className="distance-star-path">
                                            {yLabel}</div>
                                        ) : ""}
                                      </>
                                    )}

                                    {dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType === "Line Type10" && (
                                      <>
                                        {yData.length !== 0 || viewData.length !== 0 ? (
                                          <div className="distance">
                                            {yLabel}</div>
                                        ) : ""}
                                      </>
                                    )}
                                    {dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType === "Line Type12" && (
                                      <>
                                        {yData.length !== 0 || viewData.length !== 0 ? (
                                          <div className="distance-star-path">
                                            {yLabel}</div>
                                        ) : ""}
                                      </>
                                    )}
                                    {dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType === "Line Type11" && (
                                      <>
                                        {yData.length !== 0 || viewData.length !== 0 ? (
                                          <div className="distance">
                                            {yLabel}</div>
                                        ) : ""}
                                      </>
                                    )}
                                    {/* putting new condition constrain as clickState and clickCount isn't a functional for line Type10 */}
                                  </>
                                }
                              <ChartValidations
                                type={dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType
                                }
                                lesson={lesson}
                                chartType={initPayload?.chartType[mappingId]?.calculationMethod}
                                heading={
                                  dataSendToMap.find(x => x.id === id)?.description

                                }
                                data={initPayload?.chartType[mappingId]?.calculationMethod !== "curve" ?
                                  secondLessonTablePayload : clickState === 0 ? thirdLessonTablePayload : thirdLessonTablePayloadMixData
                                }
                                chartData={clickState === 0 ? payload : (graphMixStatus ? responseMixData : graphdata1)} dataFromGraph={clickState === 0 ? dataFromGraph : junkDataFromGraph}
                              />
                            </div>
                            <br />
                            <Row>
                              <Col md="12">
                                {
                                  initPayload?.chartType[mappingId]?.calculationMethod !== "Bar YesNo" ? <center>
                                    {dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType !== "Line Type11" && dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType !== "Line Type10" && (
                                      <>
                                        {yData.length !== 0 || viewData.length !== 0 ? (
                                          <div className={initPayload?.chartType[mappingId]?.calculationMethod === "Line Type6" ? "time-bublegraph" : initPayload?.chartType[mappingId]?.calculationMethod === "Line Type12" ? "time-bublegraph" : "time"}>
                                            {/* time -> timebubblegraph 
                                            only for bubble graphs  */}
                                            {clickState !== 0 ? xLabel : clickCount !== 0 ? xLabel : ""}</div>
                                        ) : ""}
                                      </>
                                    )}

                                    {dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType === "Line Type10" && (
                                      <>
                                        {yData.length !== 0 || viewData.length !== 0 ? (
                                          <div className="time">
                                            {xLabel}</div>
                                        ) : ""}
                                      </>
                                    )}
                                    {dataSendToMap.find(x => x.id === id)?.chart_type[mappingId].graphType === "Line Type11" && (
                                      <>
                                        {yData.length !== 0 || viewData.length !== 0 ? (
                                          <div className="time">
                                            {xLabel}</div>
                                        ) : ""}
                                      </>
                                    )}
                                    <br />
                                  </center> : ""
                                }
                              </Col>
                              <Col md="12">


                              </Col>
                              <Col md="1"></Col>

                            </Row>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                </div>
              </CardBody>
            </Card>

          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default ExploreMain;