import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Radio, Input, Form, Modal, Button, Tabs, Spin, Breadcrumb, notification} from "antd";
import "antd/dist/antd.css";
import jsonToken from 'jsonwebtoken';
import { ExclamationCircleOutlined } from '@ant-design/icons';


import RestService from "../../../api/RestService";
import AnswerDisplay from "../AnswerDisplay";
import FloatingButton from "../../../components/FloatingButton";
import CalculateMarks from "../calculateMarks";
import InstructorComment from "../components/InstructorComment/InstructorComment";

function Engage(props) {
  const { TabPane } = Tabs;

  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [payload, setPayload] = useState([]);
  const [containedPayload, setContainedPayload] = useState({});
  const [loadingText, setLoadingText] = useState("Loading...");
  const [classLevelName, setClassLevelName] = useState(localStorage.getItem("className"));
  const [lessonName, setLessonName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teacherComment, setTeacherComment] = useState("");
  const [teacherCommentStats, setTeacherCommentStats] = useState(false);
  const TSdate = Date.now();
  const dateFormat = new Date(TSdate);
  const MonthYear = dateFormat.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
  const HrsMinSec = dateFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });

  const [bluredLayer, setBluredLayer] = useState(" ");
  const selectedClassID = localStorage.getItem("selectedClassID");
  const selectedLessonID = localStorage.getItem("selectedLessonID");

  const handleOk1 = () => {
    setReviewStatus(false)
    window.scrollTo(0, 0)
    setIsModalOpen(false)
  };


  const handleCancel1 = () => {
    setReviewStatus(true)
    window.scrollTo(0, 0)
    setIsModalOpen(false)
  };

  useEffect(() => {
    if (props.complted === true || props.disabled !== undefined && props.disabled === false) {
      setReviewStatus(false)

    } else {
      setReviewStatus(true)

    }
  }, [props])

  const [initPayload, setInitPayload] = useState({
    description: "",
    guideline: "",
    engageStepQuestionAnswers: [],
    engageStepQuestions: [],
    videoUrl: "",
    id: "",
    lesson_id: "",
  });

  const [reviewStatus, setReviewStatus] = useState(props.disabled ?? true);
  const [lesson, setLesson] = useState(props.engageId);

  let user = "";
  let userId = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;
    userId = accessToken.userId;
  }

  function convert(data) {
    return Array.isArray(data)
      ? data.reduce((obj, el, i) => (el + 1 && (obj[i] = convert(el)), obj), {})
      : data;
  }

  useEffect(() => {
    setLoading(true)
    if (props.engageId !== undefined && props.engageId !== null) {
      RestService.getEngageStepInfoById(props.engageId)
        .then((response) => {
          setInitPayload(response?.data)
          setLessonName(response?.data?.lessonName);
          setLoading(false)
        })
    }
  }, [props.engageId, setInitPayload])

  useEffect(() => {
    setLoading(true)
    RestService.getEngageStepByIdAndUserName(props.user, props.engageId)
      .then((response) => {
        if (response) {
          if (response?.data[0]?.comment !== null && response?.data[0]?.comment !== undefined && response?.data[0]?.comment !== "") {
            setTeacherCommentStats(true)
            setTeacherComment(response?.data[0]?.comment)
          }
          setContainedPayload(convert(response?.data[0]?.response_json?.answers))
          setLoading(false)
          setLoading2(false)
          // setBluredLayer(" ")
        } else {
          setLoading(false)
          setLoading2(true)
          // notification.warning({
          //   message: `Notification`,
          //   description:
          //     'Student has not answer yet',
          //   placement:'bottom',
          //   duration: 0,
          // });
          // setBluredLayer("blured-content")          

          // setLoadingText("Student has not answer yet")

        }
      })

  }, [props.user, props.engageId])


  const sendPayload = (value) => {
    setPayload(value);
  };
  const { TextArea } = Input;

  const ReviewFun = () => {
    setIsModalOpen(true);

  };

  const submitFun = async () => {
    setLoading(true)
    const name = CalculateMarks(Object.values(payload), initPayload.engageStepQuestionAnswers, initPayload.engageStepQuestions.length, props.marks)
    const submitPayload = {
      username: user,
      userId: userId,
      status: 2,
      response_json: {
        answers: Object.values(payload)
      },
      last_update_date: new Date().toISOString().slice(0, 10),
      engage_step_id: lesson,
      marks: name,
    } 

    await RestService.postEngageStepUserResponses(submitPayload, selectedClassID, selectedLessonID)
      .then((response) => {
        if (response.status === 200) {
          props.activatingRS();
          const end = new Date();
          props.getEndDuration(end);
          window.scrollTo(0, 0);
          props.submitTrigger("2");
        }
        setLoading(false)

      })
  }
  const antIcon = <ExclamationCircleOutlined style={{ fontSize: 36 }} />;
  return (
    <>
      <Spin tip="Loading..." spinning={loading} size="large">
        <div className="page-with-sub-spinners">
          {/* <Spin tip={loadingText} spinning={loading2} size="large" indicator={antIcon}> */}
          <div className="content engage-page tab-style scroll-tab">
            <div className={`${bluredLayer}`} />
            <div className="card-chart card border-0">
              <div className="card-body">
                <Modal title="" visible={isModalOpen} onOk={handleOk1} onCancel={handleCancel1}>
                  <p>Your answer will be graded. Do you wish to continue? </p>
                </Modal>
                {/* <Row className="">
                <Col xs="12" className="">
                  
                </Col>
              </Row> */}
                <Row className="pt-2">
                  <Col xs="12">
                    <Card className="bg-engage border-0 rounded-0">
                      <CardBody className="ml-5 mt-5 mb-5 description-images-60">
                        <h2><center>Smart Science <sup>&reg;</sup></center></h2>
                        <p align="center" className="tf15" family="CarmenSans-Regular">

                        </p>
                        <p align="center" className="tf15" family="CarmenSans-Regular">
                          {`${props.user ?? user} - ${classLevelName} - ${lessonName}`}
                        </p>
                        {/* <p align="center" className="tf15" family="CarmenSans-Regular">
                        {`${MonthYear} - ${HrsMinSec} `}
                      </p> */}
                        <center>
                          <br />
                          <p>
                            <strong>
                              Correct answers are marked in <span className="text-success">green</span>. Wrong answers are marked in <span className="text-danger">red</span>.<br></br>
                              The worked-out correct answer follows each question.
                            </strong>
                            <br /><br />
                          </p>
                        </center>
                        {
                          initPayload?.description !== undefined && initPayload?.description !== null &&
                          initPayload?.description !== "" &&
                          <>
                            <h5> LAB DESCRIPTION </h5>
                            <hr className="hr-dark" />
                            <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div>
                          </>
                        }<br />
                        {
                          initPayload?.guideline !== undefined && initPayload?.guideline !== null &&
                          initPayload?.guideline !== "" &&
                          <>
                            <br />
                            <h5> LAB GUIDELINE </h5>
                            <hr className="hr-dark" />
                            <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.guideline }}></div>

                          </>

                        }

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <br />
                <Row className="">
                  <Col xs="12">
                    <Card className="bg-engage border-0 rounded-0 guidelines-description-table">
                      <CardBody className="ml-5 mt-5 mb-5">
                        <h5> Questions</h5>
                        <hr className="hr-dark" />
                        <Row>
                          <Col xs="12">
                            {/* type should be coming form admin side to make this dynamic entry */}
                            <AnswerDisplay data={
                              {
                                type: 1,
                                question: initPayload?.engageStepQuestions,
                                answers: initPayload?.engageStepQuestionAnswers,
                                preData: containedPayload,
                                className: "",
                                getData: sendPayload,
                                reviewStatus: reviewStatus,
                                subtype: 0
                              }
                            } />

                            {/* include this for type 2 and 4 */}
                            {/* <AnswerDisplay data={{
                            type: 4,
                            payload: initPayload?.explainStepQuestions,
                            changeHandler: changeHandler,
                            containedPayload: containedPayload

                          }} /> */}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {/* {
                teacherCommentStats && <InstructorComment  data = {teacherComment}/>
              } */}

                <Row>
                  <FloatingButton name={reviewStatus ? 'Review' : 'Proceed To Explore'} onClick={reviewStatus ? ReviewFun : submitFun} />
                </Row>
              </div>
            </div>
          </div>
          {/* </Spin> */}
        </div>
      </Spin>
    </>
  );
}

export default Engage;
