import { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from "react-router-dom";

// reactstrap components
import {
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Table,
    CardSubtitle,
    CardText,
    CardImg,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";
import { Radio, Input, Form, Modal, Button, Tabs, Checkbox, Image } from "antd";
import "antd/dist/antd.css";
import img1 from "../../../../../../assets/images/explore.png";

import LabDescription from "../../../../../../components/Questions/LabDescription";

import LineChart from "../../../../../../components/Charts/LineChart";
import { UserData } from "../../../../../../components/Charts/Data";

function explain(props) {

    return (
        <>
            <div className="content explain-page ">
                <div className="card-chart p2 card card-grey no-border">
                    <div className="card-body">
                        <Row className="">
                            <Col xs="12" className="">
                                <h3 className="mt-2 mb-2">
                                    How do changes in motion affect graph slope ?
                                </h3>
                                <br />
                                <div class="lab-hypothesis ex-tab active">
                                    <strong>
                                        If the person moves faster, then the slope of the line does
                                        not change.
                                    </strong>
                                    <br />
                                </div>
                                <div class="lab-hypothesis ex-tab">
                                    If the person moves faster, then the slope of the line is
                                    steeper (higher).
                                    <br />
                                </div>
                                <div class="lab-hypothesis ex-tab">
                                    If the person moves faster, then the slope of the line is less
                                    step (lower).
                                    <br />
                                </div>
                                <br />
                            </Col>
                        </Row>

                        <Row className="pt-4">
                            <Col md="1"></Col>
                            <Col md="5">
                                <Row>
                                    <Col md="5">
                                        <div className="xy-table">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>X</th>
                                                        <th>Y</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>0.0</td>
                                                        <td>0.0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>0.0</td>
                                                        <td>0.0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>0.0</td>
                                                        <td>0.0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>0.0</td>
                                                        <td>0.0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>0.0</td>
                                                        <td>0.0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>0.0</td>
                                                        <td>0.0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>0.0</td>
                                                        <td>0.0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>0.0</td>
                                                        <td>0.0</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>

                                    <Col md="1"></Col>
                                    <Col md="5">
                                        <div className="filters">
                                            <div className="chk-all">
                                                <Checkbox><span className="all">Show All</span></Checkbox>
                                            </div>
                                            <br />

                                            <div className="chk-div">
                                                <Checkbox className="bold">Walk</Checkbox>
                                                <div className="walk-color"></div>
                                            </div>
                                            <div className="chk-div">
                                                <Checkbox className="bold">Jog</Checkbox>
                                                <div className="jog-color"></div>
                                            </div>
                                            <div className="chk-div">
                                                <Checkbox className="bold">Run</Checkbox>
                                                <div className="run-color"></div>
                                            </div>
                                            <div className="chk-div">
                                                <Checkbox className="bold">Skate</Checkbox>
                                                <div className="skate-color"></div>
                                            </div>
                                            <div className="chk-div">
                                                <Checkbox className="bold">Bike</Checkbox>
                                                <div className="bike-color"></div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br />
                    </div>
                </div>

                <div className="card-chart p2 card card-grey">
                    <div className="card-body">
                        <Row className="pt-4">
                            <Col xs="12">
                                <Card className="bg-evaluate border-0 rounded-0 no-border">
                                    <CardBody>
                                    <h4 className="pt-4"> Write about what you did.</h4>
                                        <Row>
                                            <Col xs="6" className="">
                                                <p className="py-2">What is the lab about?</p>
                                                <textarea
                                                    class="form-control tarea notes"
                                                    rows={8}
                                                    id="textarea"
                                                    name="textarea"
                                                ></textarea>
                                            </Col>
                                            <Col xs="6" className="">
                                                <p className="py-2">Teacher Comment</p>
                                                <textarea
                                                    class="form-control tarea notes text-primary"
                                                    rows={8}
                                                    id="textarea"
                                                    name="textarea"
                                                ></textarea>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="bg-evaluate border-0 rounded-0 no-border">
                                    <CardBody>
                                    <h4 className="pt-4"> Procedure</h4>
                                        <Row>
                                            <Col xs="6" className="">
                                                <p className="">What were the steps of the lab?</p>
                                                <p className="pb-2">What materials were used?</p>
                                                <textarea
                                                    class="form-control tarea notes"
                                                    rows={8}
                                                    id="textarea"
                                                    name="textarea"
                                                ></textarea>
                                            </Col>
                                            <Col xs="6" className="">
                                                <br />
                                                <p className="pb-2">Teachers comments</p>
                                                <textarea
                                                    class="form-control tarea notes text-primary"
                                                    rows={8}
                                                    id="textarea"
                                                    name="textarea"
                                                ></textarea>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="bg-evaluate border-0 rounded-0 no-border">
                                    <CardBody>
                                    <h4 className="pt-4"> Conclusion</h4>
                                        <Row>
                                            <Col xs="6" className="">
                                                <p className="py-2">Conclusion</p>
                                                <textarea
                                                    class="form-control tarea notes"
                                                    rows={8}
                                                    id="textarea"
                                                    name="textarea"
                                                ></textarea>
                                            </Col>
                                            <Col xs="6" className="">
                                                <p className="py-2">Teacher comments</p>
                                                <textarea
                                                    class="form-control tarea notes text-primary"
                                                    rows={8}
                                                    id="textarea"
                                                    name="textarea"
                                                ></textarea>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="button-center p2">
                            <button type="primary" class="btn btn-primary btn-lg mt-50">
                                Submit Answers
                            </button>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}

export default explain;
