import React, { useState, useEffect } from 'react'
import { Card, Input, FormFeedback, Row, Col, CardBody, Button } from "reactstrap";
import { Alert, Spin } from 'antd';
import RestService from '../../../../api/RestService';
import Engage from '../../../LessonLab2/Engage/index'
import Evaluate from '../../../LessonLab2/Evaluate/index'
import Explain from '../../../LessonLab2/Explain/index'
import Extend from '../../../LessonLab2/Extend/index'

function ChildDrawer(props) {

  const [inputValue, setInputValue] = useState("");
  const [click, setClick] = useState(false);
  const [error, setError] = useState(null);
  const [disabled,setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });

  const onMarkChange = (e) => {
    setDisabled(false)
    setInputValue(e.target.value)
  }

  useEffect(() => {
    setClick(false)
    if (props?.marks) {
      setDisabled(true)
      setInputValue(props.marks)
    }
    else {
      setDisabled(true)
      setInputValue("")
    }
  }, [props.marks])

  useEffect(() => {
    if (click) {
      isValid();
    }
  }, [click, inputValue])

  function isValid() {
    if (inputValue.length === 0 || inputValue === "") {
      setError("Score is required")
      return false;
    }
    if (isNaN(inputValue)) {
      setError("Invalid score")
      return false;
    }
    let marks = parseInt(inputValue);

    if (marks < 0) {
      setError("Score must be grater than 0")
      return false;
    }

    if (marks > 100) {
      setError("Score must be less than 100")
      return false;
    }
    setError(null);
    return true;
  }

  function showAlert(message, success) {
    const alert = { showAlert: true, message: message, type: success ? "success" : "error" }
    setAlertData(alert);
    setTimeout(function () {
      hideAlert();
    }, 2000);
  }
  function hideAlert() {
    var alert = { showAlert: false, message: "", type: "success" }
    setAlertData(alert);
  }

  const onSubmitAnswers = () => {
    setClick(true)
    let valid = isValid();
    if (!valid) {

    }
    else {
      setLoading(true)
      if (props?.dt === 1) {
        RestService.postEngageMarks(
          {
            "marks": parseInt(inputValue),
            "engage_step_id": props?.step,
            "username": props?.usernamest
          }
        ).then(res => {
          if (res?.status === 200) {
            showAlert("Score Saved Successfully", true)
            setClick(false)
            setDisabled(true)
            //props.onClose()
          }
          else {
            showAlert("Failed to Update the Score", false)
          }
        }).finally(() => {
          setLoading(false);
        });
      }
      else if (props?.dt === 2) {
        RestService.postEvaluateMarks(
          {
            "marks": parseInt(inputValue),
            "evaluate_step_id": props?.step,
            "username": props?.usernamest
          }
        ).then(res => {
          if (res?.status === 200) {
            showAlert("Score Saved Successfully", true)
            setClick(false)
            setDisabled(true)
            //props.onClose()
          }
          else {
            showAlert("Failed to Update the Score", false)
          }
        }).finally(() => {
          setLoading(false);
        });

      }
      else if (props?.dt === 3) {
        RestService.postExplainMarks(
          {
            "marks": parseInt(inputValue),
            "explain_step_id": props?.step,
            "username": props?.usernamest
          }
        ).then(res => {
          if (res?.status === 200) {
            showAlert("Score Saved Successfully", true)
            setClick(false)
            setDisabled(true)
            //props.onClose()
          }
          else {
            showAlert("Failed to Update the Score", false)
          }
        }).finally(() => {
          setLoading(false);
        });

      }
      else if (props?.dt === 4) {
        RestService.postExtendMarks(
          {
            "marks": parseInt(inputValue),
            "extend_step_id": props?.step,
            "username": props?.usernamest
          }
        ).then(res => {
          if (res?.status === 200) {
            showAlert("Score Saved Successfully", true)
            setClick(false)
            setDisabled(true)
            //props.onClose()
          }
          else {
            showAlert("Failed to Update the Score", false)
          }
        }).finally(() => {
          setLoading(false);
        });

      }
    }
  }
  return (
    <>
      {
        props?.dt === 1 && props?.dt !== 2 && props?.dt !== 3 && props?.dt !== 4 && <Engage engageId={props.step} username={props?.usernamest} disabled={false} />
      }
      {
        props?.dt === 2 && props?.dt !== 1 && props?.dt !== 3 && props?.dt !== 4 && <Evaluate evaluateId={props.step} username={props?.usernamest} exploreId={props?.explorestepId} disabled={false} />
      }
      {
        props?.dt === 3 && props?.dt !== 2 && props?.dt !== 1 && props?.dt !== 4 && <Explain explainId={props.step} username={props?.usernamest} exploreId={props?.explorestepId} disabled={false} />
      }
      {
        props?.dt === 4 && props?.dt !== 2 && props?.dt !== 3 && props?.dt !== 1 && <Extend extendId={props.step} username={props?.usernamest} exploreId={props?.explorestepId} disabled={false} />
      }
      <Spin tip="Loading..." spinning={loading} size="large">
        <Row>
          <Col lg='12' className="py-2 text-center">
            {alertData.showAlert ?
              <Alert message={alertData.message} type={alertData.type}
                action={
                  <a type="text" onClick={hideAlert}>
                    x
                  </a>
                } /> : ""}
          </Col>
        </Row>
        <Row>
          <Col lg="12" sm='12'>
            <Card className="border-0">
              <CardBody>
                <div className="form-outline mb-3">
                  <label className="form-label" htmlFor="form3Example4">
                    Score
                  </label>
                  <Input
                    id="form3Example4"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    min="0"
                    value={inputValue}
                    required={true}
                    type="number"
                    onChange={onMarkChange}
                    invalid={!!error}
                  />
                  <FormFeedback>{error} </FormFeedback>
                </div>
                <div className="center">
                  <Button color="warning" className="mr-15" onClick={props.onClose} >
                    Cancel
                  </Button>
                  <Button color="success" disabled={disabled} onClick={() => {
                    onSubmitAnswers();
                  }} >Save</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  )
}

export default ChildDrawer