import { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import { Card, CardText, CardImg, Row, Col, CardImgOverlay } from "reactstrap";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import Lesson from "./lesson";
//import CardImgs from "../../assets/images/CardImg.png"
//import CardImgs2 from "../../assets/images/CardImg2.png"

function AssignLesson(props) {
  const { TabPane } = Tabs;
  const onChange = (key) => {
    // console.log(key);
  };
  return (
    <>
      <div className="container">
        <div className="content">
          <Row>
            <Col className="text-center">
              <div className="mb-5">
                <h3 className="text-dark">Assign a Lesson</h3>
              </div>
              <hr />
              <br />
            </Col>
          </Row>
          <Lesson />
        </div>
      </div>
    </>
  );
}

export default AssignLesson;
