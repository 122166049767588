import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { HomeOutlined, TeamOutlined, AppstoreOutlined, ReadOutlined } from '@ant-design/icons';

export default function StatCard(props) {
    const [count, setCount] = useState(0);
    const [count2, setCount2] = useState(0);
    const statStyle = props.compired === 0 ? 'stat-equal' : props.compired > 0 ? 'stat-increase' : 'stat-decrease';
    const statType = props.compired === 0 ? '' : props.compired > 0 ? '↑' : '↓';
    const statIcon = props.title === "Total Students" ? <TeamOutlined height={45} width={45} color='#0f8bfe' className='stat-icon'/> : 
                     props.title === "Total Classes" ? <AppstoreOutlined height={45} width={45} color='#0f8bfe' className='stat-icon'/> : 
                     props.title === "Total Lessons" ? <ReadOutlined height={45} width={45} color='#0f8bfe' className='stat-icon'/> : 
                     <HomeOutlined height={45} width={45} color='#0f8bfe' className='stat-icon'/>;

    useEffect(() => {
        const end = props.value;
        const duration = 500; // Duration in milliseconds (1 second)
        const frameDuration = 500 / 60; // 60 frames per second
        const totalFrames = Math.round(duration / frameDuration);
        const stepSize = end / totalFrames;

        let frame = 0;
        const counter = setInterval(() => {
            frame++;
            const currentCount = Math.round(stepSize * frame);
            setCount(currentCount);

            if (frame === totalFrames) {
                clearInterval(counter);
                // Ensure the final value is set
                setCount(end);
            }
        }, frameDuration);

        return () => clearInterval(counter);
    }, [props.value]);

    useEffect(() => {
        const end = props.totalValue;
        const duration = 500; // Duration in milliseconds (1 second)
        const frameDuration = 500 / 60; // 60 frames per second
        const totalFrames = Math.round(duration / frameDuration);
        const stepSize = end / totalFrames;

        let frame = 0;
        const counter = setInterval(() => {
            frame++;
            const currentCount = Math.round(stepSize * frame);
            setCount2(currentCount);

            if (frame === totalFrames) {
                clearInterval(counter);
                // Ensure the final value is set
                setCount2(end);
            }
        }, frameDuration);

        return () => clearInterval(counter);
    }, [props.totalValue]);

    return (
        <Card className="border-0">
            <CardBody>
                <div className='stat-container'>
                    <div>
                        <text className='stat-title'>{props.title}</text>
                        <Row>
                            <Col>
                                <h5>{count} <span className='stat-count'>/ {count2}</span></h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <text className='stat'>
                                    <span className={statStyle}>{statType} {Math.abs(props.compired)}%</span> with Last Month
                                </text>
                            </Col>
                        </Row>
                    </div>
                    <div className='stat-icon-container'>
                        <div className='stat-icon-wrapper'>
                            {statIcon}
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}
