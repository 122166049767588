import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
      beginAtZero: true,
    },
  },
};

export function MultipleBarDynamic(props) {
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    if (props?.chartData?.type === 1) {
      setLabels(props?.chartData?.labels);
      let colorRange = []

      if( props?.chartData?.datasets[0]?.xyData){
        props?.chartData?.datasets[0]?.xyData.map(x=>{
          colorRange.push(x[0])
        })
      }
      setDatasets([
        {
          label: props?.chartData?.datasets[0]?.label,
          data: props?.chartData?.datasets[0]?.yData,
          backgroundColor: colorRange,
          borderColor: props?.chartData?.datasets[0]?.borderColor,
          borderWidth: 2,
        },
      ]);
    } else {
      if (props?.chartData) {
        const newLabels = [];
        const newDatasets = [];

        props.chartData.forEach((dataset) => {
          if (dataset?.categoryData) {
            dataset.categoryData.forEach((label) => {
              if (!newLabels.includes(label)) {
                newLabels.push(label);
              }
            });
          }

          if (dataset?.yData && dataset?.xData) {
            const tempData = new Array(newLabels.length).fill(null);

            dataset.xData.forEach((xValue, index) => {
              const labelIndex = newLabels.indexOf(dataset.categoryData[index]);
              if (labelIndex !== -1) {
                tempData[labelIndex] = dataset.yData[index];
              }
            });
            let colorRange2 = []

            if( dataset?.xyData){
              dataset?.xyData.map(x=>{
                colorRange2.push(x[0])
              })
            }
            newDatasets.push({
              label: dataset.labels,
              data: tempData,
              backgroundColor: colorRange2,
              borderColor: dataset.borderColor,
              borderWidth: dataset.borderWidth,
            });
          }
        });

        setLabels(newLabels);
        setDatasets(newDatasets);
      }
    }
  }, [props]);

  const data = {
    labels,
    datasets,
  };

  return <Bar key={JSON.stringify(props.chartData)} options={options} data={data} />;
}
