// admin/techer/getTeachersByOrgId/1

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Config from '../api/Config';


export const getTeachers = createAsyncThunk("post/getTeacher", async (value) => {
    let token = localStorage.getItem("access_token");
    const response = await axios.get(Config.APIURL + `admin/techer/getTeachersByOrgId/${value}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    return response.data

})

export const getTeachersPaged = createAsyncThunk("post/getTeacher", async (data) => {
    const [value, page, size] = data;
    let token = localStorage.getItem("access_token");
    const response = await axios.get(Config.APIURL + `admin/techer/getTeachersByOrgId_paged/${value}?page=${page}&size=${size}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    return response.data

})

const initialState = {

    teacherData: [],
    accountVal: {},
    loading: false,
    error: null,
}

const teacherSlice = createSlice({
    name: "teachers",
    initialState,

    reducers: {
        // addTerm: (state, action) => {
        //     state.term = (action.payload);

        // },
        addAccountVal: (state, action) => {
            state.accountVal = (action.payload);
        },

        updateTeacher: (state, action) => {
            state.teacherData.push(action.payload)
            // const term = action.payload.filter((x) => x.id === state);

        }

    },
    extraReducers: (builder) => {
        builder.addCase(getTeachers.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getTeachers.fulfilled, (state, action) => {
            state.loading = false;
            state.teacherData = [action.payload];
            state.error = ''
        })
        builder.addCase(getTeachers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })

    }
})

export const GetTeacherReducer = teacherSlice.reducer;

export const { updateTeacher, addAccountVal } = teacherSlice.actions;