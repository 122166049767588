import { useState, useEffect, useRef, useContext } from "react";
import { Card, CardText, FormFeedback, Row, Col, Input, Button } from "reactstrap";
import { Alert, notification, Select, Spin } from 'antd';
import AccountAdminService from "../../../../api/AccountAdminService"
import useCustFormValidator from "../../../../hooks/useCustFormValidator";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import SchoolService from "../../../../api/SchoolService";
import jsonToken from 'jsonwebtoken';
import CurriculumService from "../../../../api/CurriculumService";
import LessonService from "../../../../api/LessonService";
import validate from "./lessonValidations";
import AddExplorerImagesUpload from "../components/AddExplorerImagesUpload";
import { CommonContext } from "../components/CommonContext";
import { DisableContext } from "../components/DisableContext";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
function AdminCreate(props) {
  var obj = {
    id: 0,
    heading: "",
    code: "",
    image: "",
    status: -1,
    curriculamId: -1,
    description: "",
    shortDescription: ""
  }
  const [accounts, setAccounts] = useState([]);
  const [statsues, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lodeState, setLodeState] = useState(false);
  const [uneditedObj, setUneditedObj] = useState({});
  const [edited, setEdited] = useState(false);
  const { value, setValue } = useContext(CommonContext);
  const { disablevalue, setDisableValue } = useContext(DisableContext)
  const editor = useRef();
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageData, setImageData] = useState([]);
  const [shortdescription, setshortDescription] = useState("");
  const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));
  const [defaultSelectedcc, setDefaultSelectedCC] = useState({});
  const [deleted, setDeleted] = useState(false);

  const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(onSave, validate, obj);
  useEffect(() => {
    loadCurriculums()
    setStatuses([{ id: 0, label: "Draft", value: "Draft" }, { id: 1, label: "Published", value: "Published" }])
  }, []);

  useEffect(() => {
    if (values.curriculamId !== -1 && values.curriculamId !== null) {
      setDefaultSelectedCC({ value: values.curriculamId, label: accounts.filter(x => x.id == values.curriculamId).map(x => x.name)[0] });
    } else if (values.curriculamId === null) {
      setDefaultSelectedCC({ value: "-1", label: "Please Select a Curriculum" });
    }
  }, [values]);

  useEffect(() => {
    //debugger;
    let editData = props.editInfo;
    if (editData === null) {
      setFormData(obj)
      setUneditedObj(obj)
      setDisableValue({
        0: true,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      })
    }
    else {
      setFormData(props.editInfo)
      setUneditedObj(props.editInfo)
      setValue({
        lessonId: props.editInfo?.id,
        currId: props.editInfo?.curriculamId,
      })
      setDisableValue({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      })
    }
  }, [props.editInfo])

  useEffect(() => {
    setEdited(JSON.stringify(values) !== JSON.stringify(uneditedObj))
  }, [values]);

  function loadCurriculums() {
    setLoading(true);
    CurriculumService.getAll()
      .then((response) => {
        if (response?.status === 200) {
          setAccounts(response.data);
        }
      }).catch((err) => {
        console.log(err.message);
      }).finally(() => {
        setLoading(false);
      });
  }

  //   function onEmailChange(e) {
  //     e.persist();
  //     setFormData({ ...values, username: e.target.value, email: e.target.value })
  //   }

  //   function onEmailChange(e) {
  //     e.persist();
  //     setFormData({ ...values, username: e.target.value, email: e.target.value })
  //   }
  //   useEffect(() => {

  //     if (accessToken.roles[0] === "SCHOOL_ADMIN"
  //       && accounts.length === 1
  //     ) {

  //       setLodeState(true)

  //       setFormData({ ...values, accountId: accounts[0].id });
  //       setAccounts(accounts[0].id)
  //     }

  //   }, [lodeState])
  useEffect(() => {
    props.editedData(edited)
  }, [edited])

  function onSave() {
    setLoading(true);
    let submitPayload = {
      id: values.id ? values.id : 0,
      heading: values.heading,
      code: values.code,
      image: imageUrl,
      status: values.status,
      curriculamId: values.curriculamId,
      description: values.description,
      shortDescription: values.shortDescription
    }
    if (submitPayload.id === 0) {
      LessonService.addLesson(submitPayload)
        .then((response) => {
          if (response.data.status === 200) {
            //   var alert = { showAlert: true, message: response.data.message, type: "success" }
            //   setAlertData(alert);
            let messages = [{ type: 'success', text: response.data.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],
            });
            setFormData(response.data.data)
            setUneditedObj(response.data.data)
            props.setLessonInfor(response.data.data)
            //localStorage.setItem("lesson",JSON.stringify(response.data.data))
            setValue({
              lessonId: response.data.data.id,
              currId: response.data.data.curriculamId,
            });
            setDisableValue({
              0: false,
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
            })
          }
          else {
            // var alert = { showAlert: true, message: response.data.message, type: "error" }
            // setAlertData(alert);
            let messages = [{ type: 'error', text: response.data.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],
            });
            setDisableValue({
              0: true,
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
            })
          }
          clearForm2();
        }).catch((err) => {
          setDisableValue({
            0: true,
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
          })
          setFormData({ ...values })
          let messages = [{ type: 'error', text: err.message }]
          notification.open({
            placement: 'top',
            duration: "2",
            description: [
              messages.map((item) => {
                if (item.type === 'success') {
                  return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'error') {
                  return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'warning') {
                  return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
              })
            ],
          });
        }).finally(() => {
          setLoading(false);
        });

    }
    else {
      LessonService.updateLesson(submitPayload)
        .then((response) => {
          //Fix required from backend to handle response correctly
          if (response.data !== undefined) {
            if (response.data.status === 200) {
              let messages = [{ type: 'success', text: response.data.message }]
              notification.open({
                placement: 'top',
                duration: "2",
                description: [
                  messages.map((item) => {
                    if (item.type === 'success') {
                      return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if (item.type === 'error') {
                      return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if (item.type === 'warning') {
                      return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                  })
                ],
              });
              setFormData(response.data.data)
              setUneditedObj(response.data.data)
              setValue({
                lessonId: response.data.data.id,
                currId: response.data.data.curriculamId,
              });
              props.setLessonInfor(response.data.data)
            }
            else {
              let messages = [{ type: 'error', text: response.data.message }]
              notification.open({
                placement: 'top',
                duration: "2",
                description: [
                  messages.map((item) => {
                    if (item.type === 'success') {
                      return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if (item.type === 'error') {
                      return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if (item.type === 'warning') {
                      return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                  })
                ],
              });
            }
          } else {
            let messages = [{ type: 'error', text: response.response.data.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],
            });
          }
          clearForm2();
        }).catch((err) => {
          setFormData({ ...values })
          let messages = [{ type: 'error', text: err.message }]
          notification.open({
            placement: 'top',
            duration: "2",
            description: [
              messages.map((item) => {
                if (item.type === 'success') {
                  return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'error') {
                  return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'warning') {
                  return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
              })
            ],
          });
        }).finally(() => {
          setLoading(false);
        });
    }
  }

  function hideAlert() {
    var alert = { showAlert: false, message: "", type: "success" }
    setAlertData(alert);
  }

  function clearForm() {
    reset();
    hideAlert();
    setFormData(obj);
    props.setFlag(false);
  }

  function clearForm2() {
    //reset();
    //setFormData(obj);
    setTimeout(function () {
      hideAlert();
    }, 2000);
  }


  //   useEffect(() => {
  //     setLodeState(false);

  //     if(accessToken.roles[0] === "ACCOUNT_ADMIN" 
  //     && schools.length === 1

  //     ){
  //         setFormData({ ...values, organization_id: 2});
  //         setAccounts(2)  
  //     }
  // }, [schools ])

  //   useEffect(() => {
  //     if (values.accountId) {
  //       SchoolService.getSchoolsForAccount(values.accountId)
  //         .then(res => {
  //           if (res.status === 200) {
  //             setSchools(res.data)
  //           }
  //         })
  //     }
  //   }, [values.accountId]);

  useEffect(() => {
    if (description.length > 0 || shortdescription.length > 0) {
      setFormData({ ...values, description: description, shortDescription: shortdescription })

    }
    if (values.image !== "" && values.image !== undefined) {
      const split = values.image.split("/assets/")[1];

      if (split !== undefined) {
        const part = split.split("_SSA_");
        const data = [{
          uid: part[0],
          name: part[1],
          status: 'done',
          url: values.image,
        }]
        setImageData(data);
      }
      setImageUrl(values.image)
    }
  }, [description, shortdescription, values.heading])


  useEffect(() => {
    if (imageUrl != "") {
      handleChange(null, { key: imageUrl }, "image");
    } else {
      if (deleted) {
        handleChange(null, { key: "" }, "image");
        setDeleted(false);
      }
    }
  }, [imageUrl])

  const getSunEditorInstance = (e) => {
    editor.current = e;





  }
  const onChangeShortDescription = (e) => {
    setshortDescription(e)

  }

  const uploadImgUrl = (ulrData) => {
    setImageUrl(ulrData)
  }

  const deleteImgUrl = () => {
    setDeleted(true);
    setImageUrl("")
    setImageData("");

    if(values.id && values.id > 0){
      const payload = {
        "image":""
      }
      LessonService.updateLessonThumbnail(values.id, payload)
    }
  }
  const [visible, setVisible] = useState(false);

  const handleMouseLeave = () => {
    setVisible(false);
  };

  const handleSelectClick = () => {
    setVisible(!visible); // Toggle the visibility
  };


  return (
    <>
      <div className="container">
        <Spin tip="Loading..." spinning={loading} size="large">
          <Row>
            <Col md="3"></Col>
            <Col lg='6' className="py-2 text-center">
              {alertData.showAlert ?
                <Alert message={alertData.message} type={alertData.type}
                  action={
                    <a type="text" onClick={hideAlert}>
                      x
                    </a>
                  } /> : ""}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example2">
                    <span className="required-field">*</span>Title
                  </label>
                  <Input
                    type="text"
                    id="form3Example2"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.heading}
                    name="heading"
                    onChange={handleChange}
                    invalid={!!errors?.heading}
                  />
                  <FormFeedback>{errors?.heading}</FormFeedback>
                </div>

              </div>
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example3">
                    Description
                  </label>
                  <SunEditor

                    showToolbar={true}

                    setDefaultStyle="height: 150px"
                    setContents={values.description}
                    setOptions={{
                      "katex": "window.katex",
                      "imageUploadSizeLimit": "5000000",
                      buttonList: [
                        [
                          "undo",
                          "redo",
                          //"font",
                          "fontSize",
                          "formatBlock",
                          "paragraphStyle",
                          "blockquote",
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "hiliteColor",
                          "subscript",
                          "superscript",
                          "math",
                          "removeFormat",
                          "horizontalRule",
                          // "lineHeight",
                          //"strike",
                          "list",
                          "outdent",
                          "indent",
                          "align",
                          "table",
                          "image",
                          "codeView",
                          // "preview",
                          // "print",
                          //"save",
                          //"template",
                        ],
                      ]
                    }}
                    onChange={(e) => {
                      setDescription(e)
                    }}

                  />
                  <FormFeedback>{errors?.description}</FormFeedback>
                </div>

              </div>
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example3">
                    Short Description
                  </label>
                  <SunEditor

                    showToolbar={true}
                    setDefaultStyle="height: 150px"
                    setContents={values.shortDescription}
                    setOptions={{
                      "katex": "window.katex",
                      "imageUploadSizeLimit": "5000000",
                      buttonList: [
                        [
                          "undo",
                          "redo",
                          //"font",
                          "fontSize",
                          "formatBlock",
                          "paragraphStyle",
                          "blockquote",
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "hiliteColor",
                          "subscript",
                          "superscript",
                          "math",
                          "removeFormat",
                          "horizontalRule",
                          // "lineHeight",
                          //"strike",
                          "list",
                          "outdent",
                          "indent",
                          "align",
                          "table",
                          "image",
                          "codeView",
                          // "preview",
                          // "print",
                          //"save",
                          //"template",
                        ],
                      ]
                    }}
                    onChange={(e) => onChangeShortDescription(e)}

                  />
                  <FormFeedback>{errors?.shortDescription}</FormFeedback>
                </div>

              </div>
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12 override-FormFeedback">
                  <label className="form-label" htmlFor="form3Example1">
                    <span className="required-field">*</span>Curriculum
                  </label>
                  <Select
                    showSearch
                    open={visible}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleSelectClick}
                    onFocus={() => setVisible(true)}
                    onBlur={() => setVisible(false)}
                    // style={{width: 'auto'}}
                    placeholder="Select a Curriculum"
                    optionFilterProp="children"
                    size="large"
                    className="search-drop"
                    value={defaultSelectedcc}
                    onChange={(value, event) => handleChange(value, event, "curriculamId")}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {
                      <>
                        <option key="-1" value="-1">Please Select a Curriculum</option>
                        {
                          accounts.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                        }
                      </>


                    }
                  </Select>
                  <FormFeedback>{errors?.curriculamId}</FormFeedback>
                </div>

              </div>

              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12 override-FormFeedback">
                  <label className="form-label" htmlFor="form3Example2">
                    <span className="required-field">*</span>Thumbnail
                  </label>
                  {/* <Input
                    type="text"
                    id="form3Example2"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder="Image URL"
                    value={values.image}
                    name="image"
                    onChange={handleChange}
                    invalid={!!errors?.image}
                  /> */}
                  <div className="mt--18">
                    <AddExplorerImagesUpload type={"image1"} uploadFileData={uploadImgUrl} deletedFile={deleteImgUrl} existingData={imageData} />
                  </div>
                  <FormFeedback>{errors?.image}</FormFeedback>
                </div>

              </div>
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example3">
                    <span className="required-field">*</span>Code
                  </label>
                  <Input
                    type="text"
                    id="form3Example3"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.code}
                    name="code"
                    onChange={handleChange}
                    invalid={!!errors?.code}
                  />
                  <FormFeedback>{errors?.code}</FormFeedback>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <label className="form-label" htmlFor="form3Example1">
                    <span className="required-field">*</span>Status
                  </label>
                  <Input
                    id="form3Example4"
                    type="select"
                    className="form-control form-control-lg"
                    value={values.status}
                    name="status"
                    onChange={(e) => { handleChange(e) }}
                    invalid={!!errors?.status}
                  >
                    {
                      <>


                        <option key="-1" value="-1">Please Select a Status</option>


                        {
                          statsues?.map((obj) => <option key={obj.id} value={obj.id}>{obj.label}</option>)
                        }
                      </>
                    }
                  </Input>
                  <FormFeedback>{errors?.status}</FormFeedback>
                </div>

              </div>
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <div className="center mt-4">
                <Button color="warning" className="mr-15" onClick={clearForm}>Cancel</Button>
                <Button color="success" onClick={handleSubmit} disabled={!edited} >Save</Button>
              </div>
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
}

export default AdminCreate;
