import { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import { Alert, notification, Select, Spin, Transfer } from 'antd';
import "antd/dist/antd.css";
import ClassService from "../../../../api/ClassService"
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

function ClassesCreate(props) {
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [students, setStudents] = useState([]);
  const [assignStudentsToClass,setAssignStudentsToClass] = useState(false)

  const [classId,setClassId] = useState(null);

  const onClose = () => {
      props.closeDrawer();
  };

  useEffect(() => {
    getStudentsForClass(props.selectedClassId);
  }, [props.selectedClassId]);

  useEffect(() => {
    getSelectedStudentsForClass(classId)
  }, [classId])
  function getSelectedStudentsForClass(classId) {

    let earlyStudents = [];

    ClassService.getClassStudents(classId)
      .then((response) => {
        if (response.data) {
          selectedStudents.map( it =>{
            earlyStudents.push(students.find( i => i.id === it))
          })
          setStudents([...earlyStudents, ...response.data.assignedList])
          setSelectedStudents(selectedStudents)



        }
      }).catch((err) => {
        console.log(err)
      })
  }
  const filterOption = (inputValue, option) =>
    option.firstName.toLowerCase().includes(inputValue.toLowerCase()) || option.lastName.toLowerCase().includes(inputValue.toLowerCase())

  const handleChange = (newTargetKeys) => {
    setAssignStudentsToClass(true)
    setSelectedStudents(newTargetKeys);
  };

  const handleSearch = (dir, value) => {
    console.log("search:", dir, value);
  };

  function getStudentsForClass(classId) {
    setLoading(true);
    if (!classId) {
      return;
    }

    ClassService.getClassStudents(classId)
      .then((response) => {
        if (response.data) {
          // Extract the lists from the response
          const assignedList = response.data.assignedList;
          const unAssignList = response.data.unAssignList;

          // Combine both lists
          const combinedList = [...assignedList, ...unAssignList];

          // Create a Map to filter out duplicates, using the 'id' property as the key
          const uniqueCombinedList = [...new Map(combinedList.map(item => [item.id, item])).values()];

          // Update the state with the unique combined list
          setStudents(uniqueCombinedList);
          setSelectedStudents(response.data.assignedList.map(a => a.id))
        }
      }).catch((err) => {

      }).finally(() => {
        setLoading(false);
      });
  }

  function hideAlert() {
    var alert = { showAlert: false, message: "", type: "success" }
    setAlertData(alert);
  }

  function onSave() {
    let data = { classId: props.selectedClassId, userId: selectedStudents };
    setLoading(true);

    ClassService.assignStudentsToClass(data)
      .then((response) => {
        if (response.status == 200) {
          let messages = [{type:'success',text: response.message}]
              notification.open({
                placement: 'top',
                duration:"2",
                description: [
                    messages.map((item) => {
                        if(item.type === 'success') {
                            return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                        }
                        if(item.type === 'error') {
                            return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                        }
                        if(item.type === 'warning') {
                            return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                        }
                    })
                ],
        
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
        }else{
          let messages = [{type:'error',text: response.message}]
              notification.open({
                placement: 'top',
                duration:"2",
                description: [
                    messages.map((item) => {
                        if(item.type === 'success') {
                            return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                        }
                        if(item.type === 'error') {
                            return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                        }
                        if(item.type === 'warning') {
                            return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                        }
                    })
                ],
        
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
        }
      }).catch((err) => {
        let messages = [{type:'error',text: err.message}]
              notification.open({
                placement: 'top',
                duration:"2",
                description: [
                    messages.map((item) => {
                        if(item.type === 'success') {
                            return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                        }
                        if(item.type === 'error') {
                            return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                        }
                        if(item.type === 'warning') {
                            return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                        }
                    })
                ],
        
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
      }).finally(() => {
        setLoading(false);
      });


  }
  const onClassChange = (value) => {
    setClassId(value)

  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  return (
    <>
      <div >
        <Row>
          <Col lg='12' className="py-2">
            {alertData.showAlert ?
              <Alert message={alertData.message} type={alertData.type}
                action={
                  <a type="text" onClick={hideAlert}>
                    x
                  </a>
                } /> : ""}
          </Col>
        </Row>
        <Row>
          <Spin tip="Loading..." spinning={loading} size="large">
            <Col md="12 mt--15 ">
              <label className="form-label" htmlFor="form3Example4">
                Assign Students to Class - <mark>{props.selectedClassName ? props.selectedClassName : ""}</mark>
              </label>
              <Row>
                <Col md="12">
                  <Select
                    showSearch
                    placeholder="Select a Class"
                    optionFilterProp="children"
                    size="large"
                    className="search-drop"
                    onChange={onClassChange}
                    onSearch={onSearch}
                    value={classId}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {
                      props.fullClassList.filter(i => i.id !== props.selectedClassId).map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                    }
                  </Select>
                </Col>
              </Row>
              <Transfer
                className="mt-3"
                dataSource={students}
                showSearch
                titles={["Unassigned", "Assigned"]}
                filterOption={filterOption}
                targetKeys={selectedStudents}
                onChange={handleChange}
                onSearch={handleSearch}
                render={(item) => item.firstName + ' ' + item.lastName}
                rowKey={record => record.id}
                locale={{
                  itemUnit: "Students",
                  itemsUnit: "Students",
                  notFoundContent: "The list is empty",
                  searchPlaceholder: "Search here",
                }}
              />
              <div className="center mt-4">
                 <Button color="warning" onClick={onClose} className="mr-15">Cancel</Button>
                <Button onClick={onSave} disabled={students.length === 0 || assignStudentsToClass === false} color="success">Save</Button>
              </div>
            </Col>
          </Spin>
        </Row>
      </div>
    </>
  );
}

export default ClassesCreate;
