import React, { useState } from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Chart,Line } from 'react-chartjs-2';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);


function SinChart({ chartData, dataFromGraph }) {
    let max = 1

    
    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x:{display: false},
            y:{display: true},
                    
        },
        maintainAspectRatio: false,
        responsive:true,
    };
    
    if (chartData?.type === 1) {
        if(chartData?.datasets[0]?.yData.length !== 0){
            max = (chartData?.datasets[0]?.yData.reduce((a, b) => Math.max(a, b)))
        }
        const xData = [...Array(360).keys()];
        const yData = xData.map((x) => Math.sin(x * (Math.PI / 180)) * max);
        const data = ({
            labels: xData,
            datasets: [
              {
                fill: false,
                lineTension: 0.1,
                borderColor: chartData?.datasets[0]?.borderColor,
                // borderCapStyle: 'butt',
                // borderDash: [],
                // borderDashOffset: 0.0,
                // borderJoinStyle: 'miter',
                // pointBorderColor: 'rgba(75,192,192,1)',
                // pointBackgroundColor: '#fff',
                // pointBorderWidth: 1,
                pointHoverRadius: 0,
                pointHitRadius: 0,
                // pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                // pointHoverBorderColor: 'rgba(220,220,220,1)',
                // pointHoverBorderWidth: 2,
                pointRadius: 0,
                borderWidth: chartData?.datasets[0]?.borderWidth,
                // pointHitRadius: 10,
                data: yData
              },
            ],
          });
          console.log(data)
        return (
            <>
                <Line data={data} options={options} />
            </>
        );
    } else {

        const mixDataValue = [];
        const xData = [...Array(360).keys()];

        chartData.map((x) => {

                // for (let i = x.categoryData.length-1; i >= 0; i--) {

                    //     regressionData3.push({ x: xData, y: yData })
                    // }  
                    
                if(x.yData.length !== 0){
                    max = (x.yData.reduce((a, b) => Math.max(a, b)))
                }
                const yData = xData.map((x) => Math.sin(x * (Math.PI / 180)) * max);

                const data = 
                      {
                        fill: false,
                        lineTension: 0.1,
                        borderColor: x.borderColor,
                        // borderCapStyle: 'butt',
                        // borderDash: [],
                        // borderDashOffset: 0.0,
                        // borderJoinStyle: 'miter',
                        // pointBorderColor: 'rgba(75,192,192,1)',
                        // pointBackgroundColor: '#fff',
                        // pointBorderWidth: 1,
                        pointHoverRadius: 0,
                        pointHitRadius: 0,
                        // pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                        // pointHoverBorderColor: 'rgba(220,220,220,1)',
                        // pointHoverBorderWidth: 2,
                        pointRadius: 0,
                        borderWidth: x.borderWidth,
                        // pointHitRadius: 10,
                        data: yData
                      };

            mixDataValue.push(data)
        });
        const data = {
            labels: xData,
            datasets: mixDataValue,
        };


        return (
            <>
                {/* <Chart type='bar' data={data} options={options}  /> */}
                <Line data={data} options={options} />
            </>
        );
    }




}

export default SinChart;