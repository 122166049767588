import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap';
import Answers from './answers';
import Answers2 from './answers';
import FourthTypeQuestion from './fourthTypeQuestion';
import SecondTypeQuestion from './secondTypeQuestion';
import ThirdTypeQuestion from './ThirdTypeQuestion';

export default function AnswerDisplay(props) {
// console.log(props)
 let element = null;
 switch(props.data.type){

    case 1:
        element = 
        <Answers question ={props.data.question} answers = {props.data.answers}  preData = {props.data.preData} getData = {props.data.getData} className={props.className} reviewStatus={props.data.reviewStatus} subtype={props.data.subtype}/>
    break;
    case 2:
        
        element =
        <SecondTypeQuestion data = {props.data.payload} changeHandler ={props.data.changeHandler} containedPayload ={props.data.containedPayload}  />
    break;
    case 3:
      element =
        <div style={{paddingBottom:"4px"}}>{
           props.data.videoEnd &&

          <ThirdTypeQuestion onNextValue={props.onNextValue} data= {props.data} />
        }</div>
     break;
    case 4:

   
      element  = <FourthTypeQuestion data= {props.data.payload} changeHandler ={props.data.changeHandler} containedPayload ={props.data.containedPayload}  />
      break;
    
 }
  return (
    <div>
        {element}
    </div>
  )
}
