import moment from 'moment';

export default function validate(obj) {
  let errors = {};
  let values = {...obj};
  let blankspaces = /^(\S+$)/;
  let specialChar = /^((?![-_!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+).)*$/gm;

  let moreBlank =/^(\w+\s)*\w+$/g ;
  Object.keys(values).map(k => values[k] = trimObjectStrings(k));

  if (!values.firstName || values.firstName == '') {
    errors.firstName = 'First Name is required';
  } else if (values.firstName.length > 50) {
    errors.firstName = 'First Name must be less than 50 characters';
  }
  // else if(!values.firstName.match(specialChar)){
  //   errors.firstName = 'First Name can not have special characters';
  // }

  if (!values.lastName || values.lastName == '') {
    errors.lastName = 'Last Name is required';
  } else if (values.lastName.length > 50) {
    errors.lastName = 'Last Name must be less than 50 characters';
  }
  // else if(!values.lastName.match(specialChar)){
  //   errors.lastName = 'Last Name can not have special characters';
  // }

  var mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+(\.[A-Za-z]{2,4})+$/;

  if (!values.email || values.email == '') {
    errors.email = 'Email is required';
  }  else if (values.email.length > 50) {
    errors.email = 'Email must be less than 50 characters';}
  else if (!values.email.match(mailformat)) {
    errors.email = 'Email is invalid';
  }

  if (!values.username || values.username == '') {
    errors.username = 'Username is required';
  }
  // } else if (!values.username.match(mailformat)) {
  //   errors.username = 'Username is invalid';
  // }

  if (!values.accountId || values.accountId < 0) {
    errors.accountId = 'Account is required';
  }

  if (!values.password || values.password == "") {
    errors.password = 'Password is required';
  } else if (values.password.length < 4) {
    errors.password = 'Password must contain at least 4 characters';
  } else if (values.password.length > 15) {
    errors.password = 'Password must contain less than  15 characters';
  }else if (!values.password.match(blankspaces)) {
    errors.password = 'Password field cannot contain blank spaces';
  }

  if (!values.confirmPassword || values.confirmPassword == "") {
    errors.confirmPassword = 'Confirm Password is required';
  } else if (values.confirmPassword != values.password) {
    errors.confirmPassword = 'Passwords must match';
  } else if (values.confirmPassword.length < 4) {
    errors.confirmPassword = 'Confirm Password must contain at least 4 characters';
  } else if (values.confirmPassword.length > 15) {
    errors.confirmPassword = 'Confirm password must contain less than  15 characters';
  }else if (!values.confirmPassword.match(blankspaces)) {
    errors.confirmPassword = 'Confirm Password field cannot contain blank spaces';
  }
  
  function trimObjectStrings(key){
    if(typeof values[key] == 'string' && !key.toLowerCase().includes("password")){
      return values[key].trim();
    }
    return values[key];
  }
  
  function trimObjectStrings(key){
    if(typeof values[key] == 'string' && !key.toLowerCase().includes("password")){
      return values[key].trim();
    }
    return values[key];
  }

  return errors;
};