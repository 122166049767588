import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Table,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Radio, Input, Form, Modal, Button, Tabs, Spin, Breadcrumb } from "antd";
import "antd/dist/antd.css";
import img1 from "../../../assets/images/img1.jpg";
import x_axis from "../../../assets/images/x-axis.svg";
import y_axis from "../../../assets/images/y-axis.svg";
import graph from "../../../assets/images/graph.svg";
import Video from "../../../components/Video";
import jsonToken from 'jsonwebtoken';

import Answers from "./answers";
import RestService from "../../../api/RestService";
import AnswerDisplay from "../AnswerDisplay";
import FloatingButton from "../../../components/FloatingButton";

function Engage(props) {
  const { TabPane } = Tabs;

  const [payload, setPayload] = useState([]);
  const [containedPayload, setContainedPayload] = useState({});
  const [loading, setLoading] = useState(true);
  const [classLevelName,setClassLevelName] = useState("");
  const [lessonName, setLessonName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const TSdate = Date.now();
  const dateFormat= new Date(TSdate);
  const MonthYear = dateFormat.toLocaleString('default', { month: 'short',day: 'numeric',  year: 'numeric'});
  const HrsMinSec = dateFormat.toLocaleString('en-US', { hour: 'numeric',minute: 'numeric',second: 'numeric', hour12: true });
  const selectedClassID = localStorage.getItem("selectedClassID");
  const selectedLessonID = localStorage.getItem("selectedLessonID");
  
  const handleOk1 = () => {
    setReviewStatus(false)
    window.scrollTo(0, 0)
    setIsModalOpen(false)
  };
  const handleCancel1 = () => {
    
    setReviewStatus(true)
    window.scrollTo(0, 0)
    setIsModalOpen(false)
  };

  useEffect(()=>{
      if(props.complted === true || props.disabled !== undefined && props.disabled=== false ){
        setReviewStatus(false)

      }else{
        setReviewStatus(true)

      }
  },[props])

  const [initPayload, setInitPayload] = useState({
    description: "",
    engageStepQuestionAnswers: [],
    engageStepQuestions: [],
    videoUrl: "",
    id: "",
    lesson_id: "",
  });
  const [reviewStatus, setReviewStatus] = useState(props.disabled ?? true);
  const [lesson, setLesson] = useState(props.engageId);
  let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;

  }

  function convert(data) {
    return Array.isArray(data)
      ? data.reduce((obj, el, i) => (el + 1 && (obj[i] = convert(el)), obj), {})
      : data;
  }

  useEffect( () => {
    setLoading(true)
     RestService.getEngageStepInfoById(lesson)
      .then((response) => {

        setInitPayload(response?.data)
        setClassLevelName(response?.data?.className);
        setLessonName(response?.data?.lessonName
          );

        setLoading(false)
      })
  }, [setInitPayload])





  
  useEffect(() => {
    
    if(props.username !== undefined){
      setLoading(true)
      RestService.getEngageStepInfoById(lesson)
      .then((response) => {

        setInitPayload(response?.data)
        setClassLevelName(response?.data?.className);
        setLessonName(response?.data?.lessonName
          );

        setLoading(false)
      })
      RestService.getEngageStepByIdAndUserName(props.username  , lesson)
      .then((response) => {
        if (response) {
          setContainedPayload(convert(response?.data[0]?.response_json.answers))
          setLoading(false)
        }
      })
    }

  }, [props])



  const [isModalVisible, setIsModalVisible] = useState(false);

  const sendPayload = (value) => {
    setPayload(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const { TextArea } = Input;
  const [value, setValue] = useState(1);

  const onChange = (e) => {

    setValue(e.target.value);
  };

  const ReviewFun = () => {
    setIsModalOpen(true);
    
  };
  // console.log(initPayload?.description )
  const submitFun = async () => {
    // @todo username should be changed

    const submitPayload = {
      username: user,
      userId: initPayload.id,
      status: 2,
      response_json: {
        answers: Object.values(payload)
      },
      last_update_date: new Date().toISOString().slice(0, 10),
      engage_step_id: lesson,
    }


    // @todo should succesfully completed out here
    await RestService.postEngageStepUserResponses(submitPayload, selectedClassID, selectedLessonID)
      .then((response) => {
        if (response.status === 200) {
          props.activatingRS();
          window.scrollTo(0, 0)
          props.submitTrigger("2");
        }
      })
  }
  function dateDisplay(){
    const timeStamp= TSdate
    const dateFormat= new Date(timeStamp);

    const Month = dateFormat.toLocaleString('default', { month: 'short',day: 'numeric',  year: 'numeric'});
    const hrsMinSec = dateFormat.toLocaleString('en-US', { hour: 'numeric',minute: 'numeric',second: 'numeric', hour12: true });
    return(
      <p>
        {`${Month}, ${hrsMinSec} `}
      </p>
    )

  }
  return (
    <Spin tip="Loading..." spinning={loading} size="large">
      <div className="content engage-page tab-style scroll-tab">
        <div className="card-chart card border-0">
          <div className="card-body">
          <Modal title="" visible={isModalOpen} onOk={handleOk1} onCancel={handleCancel1}>
            <p>Your answer will be graded. Do you wish to continue? </p>
      </Modal>
            {/* <Row className="">
              <Col xs="12" className="">
                
              </Col>
            </Row> */}
            {/* <Row className="pt-2">
              <Col xs="12">
                <Card className="bg-engage border-0 rounded-0">
                  <CardBody className="ml-5 mt-5 mb-5 description-images-60">
                    <h2><center>Smart Science <sup>&reg;</sup></center></h2>
                    <p align="center" className="tf15" family="CarmenSans-Regular">

                    </p>
                    <p align="center" className="tf15" family="CarmenSans-Regular">
                      {`${props.username ?? user} - ${classLevelName}-${lessonName}`}
                    </p>
                    <p align="center" className="tf15" family="CarmenSans-Regular">
                      {`${MonthYear} - ${HrsMinSec} `}
                    </p>
                    <center>
                    <br/>
                      <p>
                        <strong>
                      Correct answers are marked in <span className="text-success">green</span>. Wrong answers are marked in <span className="text-danger">red</span>.<br></br>
The worked-out correct answer follows each question.
</strong>
<br/><br/>
                      </p>
                    </center>
                    <div dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div>

                  
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
            <br />
            <Row className="">
              <Col xs="12">
                <Card className="bg-engage border-0 rounded-0">
                  <CardBody className="ml-5 mt-5 mb-5">
                    <h5> Questions</h5>
                    <hr className="hr-dark" />
                    <Row>
                      <Col xs="12">
                        <AnswerDisplay data={
                          {
                            type: 1,
                            question: initPayload.engageStepQuestions,
                            answers: initPayload.engageStepQuestionAnswers,
                            preData: containedPayload,
                            className:"",
                            getData: sendPayload,
                            reviewStatus:false,
                            subtype:1
                          }
                        } />
                        {/* <Answers question={initPayload.engageStepQuestions} answers={initPayload.engageStepQuestionAnswers} preData={containedPayload} getData={sendPayload}/> */}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              <FloatingButton name ={!props.complted && reviewStatus ? 'Review':'Proceed To Explore'} onClick={reviewStatus ? ReviewFun : submitFun} />
            </Row> */}
            
            {/* <Row>
              <div className="unmarked-answer px-4 pt-1">
                  <label className="custom-radio-btn">
                    <Input type="radio" name="sample" checked />
                    <span className="checkmark"></span>
                  </label>
                <span className="p-proprty pl-1">Wrong Answer</span>
              </div>
            </Row>
            <br/>
            <Row>
              <div className="wrong-answer px-4 pt-1">
                  <label className="result-radio-btn">
                    <Input type="radio" name="sample" checked />
                    <span className="checkmark">X</span>
                  </label>
                <span className="p-proprty pl-1">Wrong Answer</span>
              </div>
            </Row>
            <br />
            <Row>
            <div className="success-answer px-4 pt-1">
              <label className="result-radio-btn ">
                <Input type="radio" name="sample2" Checked />
                <span className="checkmark">✓</span>
              </label>
              <span className="p-proprty pl-1">Wrong Answer</span>
              </div>
            </Row> */}
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default Engage;
