import React, { useContext } from 'react'

import { useState, useEffect } from "react";
import { Input, FormFeedback, Row, Col, Button } from "reactstrap";
import { Alert, notification, Select, Spin } from 'antd';
import "antd/dist/antd.css";
import CurriculumService from "../../../../api/CurriculumService"


import { AddNewAccount } from "../../../../api/AccountService";
import validate from './BasicInfoValidator';
import SunEditor from 'suneditor-react';
import useCustFormValidator from '../../../../hooks/useCustFormValidator';
import LessonService from '../../../../api/LessonService';
import { CommonContext } from './CommonContext';
import { DisableContext } from './DisableContext';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

function BasicCommonComponent(props) {

  const [loading, setLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [uneditedObj, setUneditedObj] = useState({});
  const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });
  const [desc, setDesc] = useState("");
  const { value, setValue } = useContext(CommonContext);
  const { disablevalue, setDisableValue } = useContext(DisableContext);
  const [guideline, setGuideline] = useState("");
  const [classLevel, setClassLevel] = useState(0);
  const [saved, setSaved] = useState(false);
  const [list, setList] = useState([]);
  var obj = {
    id: 0,
    labdescription: "",
    guideline: "",
    classLevel: -1
  }
  const [options, setOption] = useState([{
    value: 'Beginner',
    id: 1,

  },
  {
    value: 'Basic',
    id: 2,

  },
  {
    value: 'Middle',
    id: 3,

  },
  {
    value: 'Advanced',
    id: 4,

  },
  {
    value: 'Honors',
    id: 5,

  },
  ])

  const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(onSave, validate, obj);

  useEffect(() => {
    // getCurriculumFromId(props.selectedId);
    let inputObj = props.inputObj;
    // console.log('inputObj', inputObj)
    if (classLevel > 0) {
      inputObj.classLevel = classLevel;
    }
    setFormData(inputObj)
    setDesc(inputObj?.labdescription)
    setGuideline(inputObj?.guideline)
    setUneditedObj(inputObj)
    setEdited(false)
    setClassLevel(inputObj?.classLevel);
    // console.log('props.filterdValues', props.filterdValues);
    if (props.filterdValues.length == 0) {
      setList(options);
    } else {
      setList(props.filterdValues)
    }

    // if (saved && inputObj.guideline === "" && inputObj.labdescription === "") {
    //   var new_list = list.filter(x => {
    //     return x.id != classLevel;
    //   })
    //   setClassLevel(-1);
    //   setList(new_list)
    //   setSaved(false);
    // }
    // console.log("inputObj", inputObj)
  }, [props]);

  useEffect(() => {
    setLoading(props.loading)
  }, [props.loading]);

  useEffect(() => {
    setEdited(JSON.stringify(values) != JSON.stringify(uneditedObj))
  }, [values]);

  useEffect(() => {
    setFormData({ id: props.inputObj?.id ?? values.id, labdescription: desc, guideline: guideline, classLevel: classLevel });
  }, [desc, guideline, classLevel])

  // function getCurriculumFromId(id) {
  //   setLoading(true);
  //   CurriculumService.getById(id)
  //     .then((response) => {
  //       if (response.data) {
  //         setUneditedObj(response.data);
  //         setFormData(response.data);
  //       }
  //     }).catch((err) => {
  //       alert(err.message);
  //     }).finally(() => {
  //       setLoading(false);
  //     });
  // }
  const onChangeLabDescription = (editorState) => {
    setDesc(editorState)
  };
  const onChangeLabGuideline = (editorState) => {
    setGuideline(editorState)
  };
  function onSave() {
    if (props.step === 1) {
      setLoading(true);
      let submitPayload = {
        id: values.id,
        description: values.labdescription,
        lesson_id: value.lessonId,
        classLevel: values.classLevel,
        guideline: values.guideline,
        curriculamId: value.currId,
        videoUrl: "image.jpg",
        previouseClassLevel: props.previouseClassLevel
      }
      LessonService.addEngageBasicInfo(submitPayload)
        .then((response) => {
          if (response.status == 200) {
            setDisableValue({
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
            });
            let messages = [{ type: 'success', text: response.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
            setSaved(true);
            setFormData(response.data)
            props.setAddedClassLevel(classLevel);
            props.setPreviouseClassLevelFC(response.data.classLevelForFrontEnd);
            //onCancel();
          } else if (response.status == 500) {
            setDisableValue({
              1: true,
              2: false,
              3: false,
              4: false,
              5: false,
            });
            let messages = [{ type: 'error', text: response.message }]
            console.log('messages', messages)
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          } else if (response.response.data.status == 500) {
            setDisableValue({
              1: true,
              2: false,
              3: false,
              4: false,
              5: false,
            });
            let messages = [{ type: 'error', text: response.response.data.message }]
            console.log('messages', messages)
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          }
        }).catch((err) => {
          let messages = [{ type: 'error', text: err.message }]
          notification.open({
            placement: 'top',
            duration: "2",
            description: [
              messages.map((item) => {
                if (item.type === 'success') {
                  return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'error') {
                  return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'warning') {
                  return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
              })
            ],

            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
          setDisableValue({
            1: true,
            2: false,
            3: false,
            4: false,
            5: false,
          });
        }).finally(() => {
          setLoading(false);
        });

    } else if (props.step === 2) {
      setLoading(true);
      let submitPayload = {
        id: values.id,
        description: values.labdescription,
        lesson_id: value.lessonId,
        class_level: values.classLevel,
        guideline: values.guideline,
        curriculamId: value.currId,
        videoUrl: "image.jpg",
        previouseClassLevel: props.previouseClassLevel
      }
      LessonService.addExploreBasicInfo(submitPayload)
        .then((response) => {
          console.log('response', response)
          if (response.status == 200) {
            setDisableValue({
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
            });
            let messages = [{ type: 'success', text: response.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
            props.setAddedClassLevel(classLevel);
            props.setPreviouseClassLevelFC(response.data.classLevelForFrontEnd);
            // onCancel();
          } else if (response.status == 500) {
            setDisableValue({
              1: false,
              2: true,
              3: false,
              4: false,
              5: false,
            });
            let messages = [{ type: 'error', text: response.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          } else if (response.response.data.status == 500) {
            setDisableValue({
              1: true,
              2: false,
              3: false,
              4: false,
              5: false,
            });
            let messages = [{ type: 'error', text: response.response.data.message }]
            console.log('messages', messages)
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          }
        }).catch((err) => {
          let messages = [{ type: 'error', text: err.message }]
          notification.open({
            placement: 'top',
            duration: "2",
            description: [
              messages.map((item) => {
                if (item.type === 'success') {
                  return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'error') {
                  return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'warning') {
                  return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
              })
            ],

            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
          setDisableValue({
            1: false,
            2: true,
            3: false,
            4: false,
            5: false,
          });
        }).finally(() => {
          setLoading(false);
        });
    }
    else if (props.step === 3) {
      setLoading(true);
      let submitPayload = {
        id: values.id,
        description: values.labdescription,
        lesson_id: value.lessonId,
        classLevel: values.classLevel,
        guideline: values.guideline,
        curriculamId: value.currId,
        videoUrl: "image.jpg",
        previouseClassLevel: props.previouseClassLevel
      }
      LessonService.addEvaluateBasicInfo(submitPayload)
        .then((response) => {
          if (response.status == 200) {
            setDisableValue({
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
            });
            let messages = [{ type: 'success', text: response.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
            props.setPreviouseClassLevelFC(response.data.classLevelForFrontEnd);
            props.setAddedClassLevel(classLevel);
            //onCancel();
          } else if (response.status == 500) {
            setDisableValue({
              1: false,
              2: false,
              3: true,
              4: false,
              5: false,
            });
            let messages = [{ type: 'error', text: response.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          } else if (response.response.data.status == 500) {
            setDisableValue({
              1: true,
              2: false,
              3: false,
              4: false,
              5: false,
            });
            let messages = [{ type: 'error', text: response.response.data.message }]
            console.log('messages', messages)
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          }
        }).catch((err) => {
          let messages = [{ type: 'error', text: err.message }]
          notification.open({
            placement: 'top',
            duration: "2",
            description: [
              messages.map((item) => {
                if (item.type === 'success') {
                  return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'error') {
                  return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'warning') {
                  return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
              })
            ],

            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
          setDisableValue({
            1: false,
            2: false,
            3: true,
            4: false,
            5: false,
          });
        }).finally(() => {
          setLoading(false);
        });
    }
    else if (props.step === 4) {
      setLoading(true);
      let submitPayload = {
        id: values.id,
        description: values.labdescription,
        lesson_id: value.lessonId,
        classLevel: values.classLevel,
        guideline: values.guideline,
        curriculamId: value.currId,
        videoUrl: "image.jpg",
        previouseClassLevel: props.previouseClassLevel
      }
      LessonService.addExplainBasicInfo(submitPayload)
        .then((response) => {
          if (response.status == 200) {
            setDisableValue({
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
            });
            let messages = [{ type: 'success', text: response.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
            //onCancel();
            props.setPreviouseClassLevelFC(response.data.classLevelForFrontEnd);
            props.setAddedClassLevel(classLevel);
          } else if (response.status == 500) {
            setDisableValue({
              1: false,
              2: false,
              3: false,
              4: true,
              5: false,
            });
            let messages = [{ type: 'error', text: response.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          } else if (response.response.data.status == 500) {
            setDisableValue({
              1: true,
              2: false,
              3: false,
              4: false,
              5: false,
            });
            let messages = [{ type: 'error', text: response.response.data.message }]
            console.log('messages', messages)
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          }
        }).catch((err) => {
          let messages = [{ type: 'error', text: err.message }]
          notification.open({
            placement: 'top',
            duration: "2",
            description: [
              messages.map((item) => {
                if (item.type === 'success') {
                  return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'error') {
                  return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'warning') {
                  return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
              })
            ],

            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
          setDisableValue({
            1: false,
            2: false,
            3: false,
            4: true,
            5: false,
          });
        }).finally(() => {
          setLoading(false);
        });

    }
    else if (props.step === 5) {
      setLoading(true);
      let submitPayload = {
        id: values.id,
        description: values.labdescription,
        lesson_id: value.lessonId,
        classLevel: values.classLevel,
        guideline: values.guideline,
        curriculamId: value.currId,
        videoUrl: "image.jpg",
        previouseClassLevel: props.previouseClassLevel
      }
      LessonService.addExtendBasicInfo(submitPayload)
        .then((response) => {
          if (response.status == 200) {
            setDisableValue({
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
            });
            let messages = [{ type: 'success', text: response.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
            props.setAddedClassLevel(classLevel);
            props.setPreviouseClassLevelFC(response.data.classLevelForFrontEnd);
            //onCancel();
          } else if (response.status == 500) {
            setDisableValue({
              1: false,
              2: false,
              3: false,
              4: false,
              5: true,
            });
            let messages = [{ type: 'error', text: response.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          } else if (response.response.data.status == 500) {
            setDisableValue({
              1: true,
              2: false,
              3: false,
              4: false,
              5: false,
            });
            let messages = [{ type: 'error', text: response.response.data.message }]
            console.log('messages', messages)
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          }
        }).catch((err) => {
          let messages = [{ type: 'error', text: err.message }]
          notification.open({
            placement: 'top',
            duration: "2",
            description: [
              messages.map((item) => {
                if (item.type === 'success') {
                  return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'error') {
                  return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'warning') {
                  return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
              })
            ],

            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
          setDisableValue({
            1: false,
            2: false,
            3: false,
            4: false,
            5: true,
          });
        }).finally(() => {
          setLoading(false);
        });
    }
  }

  function setSelectedItems(classLevel) {
    setClassLevel(classLevel)
  }

  function onCancel() {
    reset();

    setTimeout(() => {

      hideAlert();
    }, 1000);

    props.back()
    // getCurriculumFromId(props.selectedId);
  }


  function hideAlert() {
    var alert = { showAlert: false, message: "", type: "success" }
    setAlertData(alert);
  }

  return (
    <>
      <Spin tip="Loading..." spinning={loading} size="large">
        <Row>
          <Col lg='12' className="py-2 text-center">
            {alertData.showAlert ?
              <Alert message={alertData.message} type={alertData.type}
                action={
                  <a type="text" onClick={hideAlert}>
                    x
                  </a>
                } /> : ""}
          </Col>
        </Row>
        <form  >
          <div>
            <Row>
              <Col lg="12">
                <div className="form-outline mb-3">
                  <label className="form-label" htmlFor="form3Example4">
                    <span className="required-field">*</span>Class Level
                  </label>
                  <Select
                    placeholder="Please Select a Level"
                    name="classLevel"
                    onChange={setSelectedItems}
                    disabled={props.type === 2}
                    value={values.classLevel}
                    style={{
                      width: '100%',
                    }}
                  >
                    <Select.Option key={-1} value={-1}>
                      {"Please Select a Level"}
                    </Select.Option>
                    {list.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.value}
                      </Select.Option>
                    ))}
                  </Select>
                  <div className='error-msg'>{errors?.classLevel}</div>
                </div>
                <div className="form-outline mb-3">
                  <label className="form-label" htmlFor="form3Example4">
                    <span className="required-field">*</span>Lab Description
                  </label>
                  <SunEditor
                    disable={props.type === 2}
                    setContents={values.labdescription}
                    showToolbar={true}
                    setDefaultStyle="height: 150px"
                    setOptions={{
                      "katex": "window.katex",
                      "imageUploadSizeLimit": "5000000",
                      buttonList: [
                        [
                          "undo",
                          "redo",
                          //"font",
                          "fontSize",
                          "formatBlock",
                          "paragraphStyle",
                          "blockquote",
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "hiliteColor",
                          "subscript",
                          "superscript",
                          "math",
                          "removeFormat",
                          "horizontalRule",
                          // "lineHeight",
                          //"strike",
                          "list",
                          "outdent",
                          "indent",
                          "align",
                          "table",
                          "image",
                          "codeView",
                          "video",
                          // "preview",
                          // "print",
                          //"save",
                          //"template",
                        ],
                      ]
                    }}
                    onChange={onChangeLabDescription}
                  />
                  <div className='error-msg'>{errors?.labdescription}</div>
                </div>
                <div className="form-outline mb-3">
                  <label className="form-label" htmlFor="form3Example4">
                    <span className="required-field">*</span>Guideline
                  </label>
                  <SunEditor
                    disable={props.type === 2}
                    setContents={values.guideline}
                    showToolbar={true}
                    setDefaultStyle="height: 150px"
                    setOptions={{
                      "katex": "window.katex",
                      "imageUploadSizeLimit": "5000000",
                      buttonList: [
                        [
                          "undo",
                          "redo",
                          //"font",
                          "fontSize",
                          "formatBlock",
                          "paragraphStyle",
                          "blockquote",
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "hiliteColor",
                          "subscript",
                          "superscript",
                          "math",
                          "removeFormat",
                          "horizontalRule",
                          // "lineHeight",
                          //"strike",
                          "list",
                          "outdent",
                          "indent",
                          "align",
                          "table",
                          "image",
                          "codeView",
                          "video",
                          // "preview",
                          // "print",
                          //"save",
                          //"template", 
                        ],
                      ]
                    }}
                    onChange={onChangeLabGuideline}
                  />
                  <div className='error-msg'>{errors?.guideline}</div>
                </div>
                <div>
                {props.type !== 2 && (
                  <Row>
                    <Col lg='12' className="center">
                      {/* <Button color="warning" className="mr-15" onClick={()=>{
                        onCancel()
                      }}>Back</Button> */}
                      <Button onClick={() => {
                          handleSubmit();
                      }} color="success" disabled={!edited}>Save</Button>


                    </Col>
                  </Row>
                )}
                  
                  <Row>
                    <Col md="6" className='pull-left'>
                      <button
                        className="mb10 btn btn-info"
                        style={{ padding: "14px 31px" }}
                        onClick={props.back}
                      >
                        {"Back"}
                      </button>
                    </Col>
                    <Col md="6" className='pull-right'>
                      <button
                        disabled={
                          values.id === 0
                        }
                        type="primary"
                        className="mb10 btn btn-info"
                        style={{ padding: "14px 31px" }}
                        onClick={(e) => {
                          props.createEngageStep(values.id);
                          props.GoToQuestionTab();
                        }}
                        size="md"
                      >
                        {"Continue"}
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </form>
      </Spin>
    </>
  );
}

export default BasicCommonComponent;

