import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Table,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { Radio, Input, Form, Tabs, Spin, Breadcrumb } from "antd";
import "antd/dist/antd.css";
import jsonToken from 'jsonwebtoken';
// import RestService from "../../../api/RestService";

import { getEnageStepByLessonIdAndLevel } from "../../../../../api/TeacherService";
import { Link, useHistory,useParams } from "react-router-dom";

import AnswerDisplay from "../AnswerDisplay";

function EngageChild(props) {
  const [payload, setPayload] = useState([]);
  const [containedPayload, setContainedPayload] = useState({});
  const [loading, setLoading] = useState(true);
  const [classLevelName,setClassLevelName] = useState("");
  const [lessonName, setLessonName] = useState("");
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");

  useEffect(()=>{
      if(props.complted === true || props.disabled !== undefined && props.disabled=== false ){
        setReviewStatus(false)

      }else{
        setReviewStatus(true)

      }
  },[props])

  const [initPayload, setInitPayload] = useState({
    description: "",
    engageStepQuestionAnswers: [],
    engageStepQuestions: [],
    videoUrl: "",
    id: "",
    lesson_id: "",
    guideline:"",
  });
  const [reviewStatus, setReviewStatus] = useState(props.disabled ?? true);
  let user = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;

  }
  useEffect( () => {
    setLoading(true)
    getEnageStepByLessonIdAndLevel(parseInt(props.data.lessonId), props.data.levelId)
      .then((response) => {
        if(response !== undefined){
          setInitPayload(response?.data)
          setClassLevelName(response?.data?.className);
          setLessonName(response?.data?.lessonName);
        }else{
          // setValidationMsg("Corrupted Data")
          // setIsModalOpen(true)
        }

        setLoading(false)
      })
  }, [props])

  const sendPayload = (value) => {
    setPayload(value);
  };
  let history = useHistory();
  let { id, lessonId } = useParams();
  const handleCancel1 = () => {
    window.scrollTo(0, 0);
    setIsModalOpen(false);
  };
  const handleOk1 = () => {
    history.push({
      pathname: `/Teacher/PlanLesson/${id}`
    })
  };
  useEffect(()=>{
    const childPayload ={
      classLevelName : classLevelName,
      lessonName : lessonName
    }
    props.subChildCall(childPayload)
  },[classLevelName, lessonName])


  return (
    <Spin tip="Loading..." spinning={loading} size="large">
       <Modal isOpen={isModalOpen} toggle={handleCancel1}>
                        <ModalBody>
                          <p>{validationMsg}</p>
                        </ModalBody>
                        <ModalFooter className="center">
                          <Button
                            color="success"
                            onClick={handleOk1}
                          >
                            Go to Lesson
                          </Button>
                          <Button color="warning" onClick={handleCancel1}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
      <div className="content engage-page tab-style">
        <div className="card-chart card border-0">
          <div className="card-body">
          
            <Row className="pt-2">
              <Col xs="12">
                <Card className="bg-engage border-0 rounded-0">
                  <CardBody className="ml-5 mt-5 mb-5 description-images-60">
                    <h2><center>Smart Science<sup>&reg;</sup> </center></h2>
                    <center>
                      <p>
                        <strong>
                        Correct answers are marked in <span className="text-success">green</span>. Wrong answers are marked in <span className="text-danger">red</span>.<br></br>
                        The worked-out correct answer follows each question.
                        </strong>
                      <br/><br/>
                      </p>
                    </center>
                    {
              initPayload?.description !== "" &&
                  <>
                  <h5> LAB DESCRIPTION </h5>
                    <hr className="hr-dark" />
                    <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.description }}></div>
                  </>
            }<br/>
            {
              initPayload?.guideline !== "" && 
                <>
                    <br/>
                    <h5> LAB GUIDELINE </h5>
                    <hr className="hr-dark" />
                    <div className="f18 guidelines-description-table" dangerouslySetInnerHTML={{ __html: initPayload?.guideline }}></div>

                </>
              
            }<br/>

                  
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <Row className="">
              <Col xs="12">
                <Card className="bg-engage border-0 rounded-0 guidelines-description-table">
                  <CardBody className="ml-5 mt-5 mb-5">
                    <h5> Questions</h5>
                    <hr className="hr-dark" />
                    <Row>
                      <Col xs="12">
                        <AnswerDisplay data={
                          {
                            type: 1,
                            question: initPayload?.engageStepQuestions,  
                            answers: initPayload?.engageStepQuestionAnswers,
                            preData: containedPayload,
                            className:"",
                            getData: sendPayload,
                            reviewStatus:reviewStatus,
                            subtype:0
                          }
                        } />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default EngageChild;
