import { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts


// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  Row,
  Col,
  CardImgOverlay,
  CardBody,
} from "reactstrap";
import { Input, Tabs } from "antd";
import "antd/dist/antd.css";
import Class from "./class"
import { SearchOutlined } from "@ant-design/icons";

//import CardImgs from "../../../assets/images/CardImg.png"
//import CardImgs2 from "../../../assets/images/CardImg2.png"


function ClassIndex(props) {
  const { TabPane } = Tabs;
  const onChange = (key) => {
    // console.log(key);
  };

  const [searchValue, setSetSearchValue] = useState("")
  return (
    <>
    <div className="container">
      <div className="content">
        <Row>
          <Col className="text-center">
            <div className="mb-4">
              <h3 className="text-dark">Choose your curriculum </h3>
            </div>
          </Col>
        </Row>
        <Row>
            <Col md="3"></Col>
            <Col lg="6" sm='12'>
              <CardBody className="">
                <Input
                  size="large"
                  placeholder="Search"
                  onChange={(e) => { 
                    // searchTable(e.target.value)
                    setSetSearchValue(e.target.value)
                    }}
                  prefix={<SearchOutlined className="mx-3" />}
                />
              </CardBody>
            </Col>
          </Row>
          <hr />
            <br/>
       <Class searchValue={searchValue}/>
      </div>
      </div>
    </>
  );
}

export default ClassIndex;
