
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Config from '../api/Config';

// import { getAllTerms } from '../api/TermService';


export const getTerms = createAsyncThunk("post/getTerm", async () => {
    let token = localStorage.getItem("access_token");
    const response = await axios.get(Config.APIURL +`admin/term/getAllTerms`, {
        headers:{
            "Authorization": `Bearer ${token}`
        }
    })
    return response.data
    
})
 
const initialState = {
    terms: [],
    termTable: [],
    editTermsTb: [],
    // post: [],
    loading: false,
    error: null,
}

const termSlice = createSlice({
    name: "term",
    initialState,

    reducers: {
        addTerm: (state, action) => {
            state.term = (action.payload);

        },
        addToTable: (state, action) => {
            state.termTable.push(action.payload)
        },

        editTerms: (state, action) => {
            state.editTermsTb = (action.payload) 
            
            // const term = action.payload.filter((x) => x.id === state);
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getTerms.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getTerms.fulfilled, (state, action) => {
            state.loading = false;
            state.terms = [action.payload];
            state.error = ''
        })
        builder.addCase(getTerms.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        // [getTerms.pending]: (state, action) => {
        //     state.loading = true;

        // },
        // [getTerms.fulfilled]: (state, action) => {
        //     state.loading = false;
        //     state.term = [action.payload];

        // },
        // [getTerms.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.error = action.payload;

        // }
    }
})

export const GetTermReducer = termSlice.reducer;

export const { addTerm, addToTable, editTerms } = termSlice.actions;