import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  CardSubtitle,
  CardText,
  CardImg,
  UncontrolledTooltip,
} from "reactstrap";
import { Radio, Input, Form, Modal, Button, Tabs, Table, Col, Row, Breadcrumb, Space } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
// import img1 from "../../../assets/images/img1.jpg";

import { dataSource } from './data'
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import { GetAllExplainStepInfos } from "../../../../../../api/TeacherService";
import jsonToken from 'jsonwebtoken';


function ChosenStudentName(props) {
  const { TabPane } = Tabs;
  const location = useLocation();
  const [dataSourse, setDataSourse] = useState([]);
  const [dataMap, setDataMap] = useState([]);

  const [dataPayload, setDataPayload] = useState([]);
  const { instructorId,classId, className,  id, lessId, level } = useParams();
  const name = localStorage.getItem('studentName');
  const [initPayload, setInitPayload] = useState([]);
  const [searchResult, setSearchResult] = useState();
  const [searchTerm, setSearchTerm] = useState();

  let user
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessToken = jsonToken.decode(token);
    user = accessToken.sub;
  }

  function toHoursAndMinutes(totalMinutes) {
    const minutes = parseInt(totalMinutes % 60);
    const hours = Math.floor(totalMinutes / 60);

    return `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`;
  }

  let Stname = useHistory();
  const columns = [
    {
      title: 'Lesson Name',
      dataIndex: 'lessonName',
      key: 'lessonName',
      width: 300,
      sorter: (a, b) => a.lessonName.localeCompare(b.lessonName),
    },
    {
      title: 'Total Score',
      dataIndex: 'totalScore',
      key: 'totalScore',
      sorter: (a, b) => a.totalScore - b.totalScore,
      onCell: (record, rowIndex) => ({
        style: {
          // backgroundColor: '#E1F1FF'
        }
      })
    },
    {
      title: 'Engage',
      dataIndex: 'engageStepMarks',
      key: 'engageStepMarks',
      sorter: (a, b) => a.totalScore - b.totalScore,
      onCell: (record, rowIndex) => ({
        style: {
          // backgroundColor: '#E1F1FF'
        }
      })
    },
    {
      title: 'Evaluate',
      dataIndex: 'evaluateStepMarks',
      key: 'evaluateStepMarks',
      sorter: (a, b) => a.totalScore - b.totalScore,
      onCell: (record, rowIndex) => ({
        style: {
          // backgroundColor: '#E1F1FF'
        }
      })
    },
    {
      title: 'Explain',
      dataIndex: 'explainStepMarks',
      key: 'explainStepMarks',
      sorter: (a, b) => a.totalScore - b.totalScore,
      onCell: (record, rowIndex) => ({
        style: {
          // backgroundColor: '#E1F1FF'
        }
      })
    },
    {
      title: 'Extend',
      dataIndex: 'extendStepMarks',
      key: 'extendStepMarks',
      sorter: (a, b) => a.totalScore - b.totalScore,
      onCell: (record, rowIndex) => ({
        style: {
          // backgroundColor: '#E1F1FF'
        }
      })
    },
    {
      title: 'No. of Retakes',
      dataIndex: 'retakesCount',
      key: 'noOfRetakes',
      sorter: (a, b) => a.retakesCount - b.retakesCount,
      onCell: (record, rowIndex) => ({
        style: {
          // backgroundColor: '#F1F0F0'
        }
      })
    },
    {
      title: 'Total Duration',
      dataIndex: 'totalDurtion',
      key: 'totalDuration',
      sorter: (a, b) => a.totalDurtion - b.totalDurtion,
      onCell: (record, rowIndex) => ({
        style: {
          // backgroundColor: '#E9FAEF'
        }
      }),
      render: (_, record) => (
        <Space size="middle">
          <small>{toHoursAndMinutes(record.totalDurtion !== null && record.totalDurtion.toFixed(1))}</small>
        </Space>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'image',
      key: 'image',
      render: (_, record) => (
        <Space size="middle">
          <span className="btn btn-sm btn-info" onClick={() => {
            let payload = {
              totalScore: record.totalScore,
              retakesCount: record.retakesCount,
              totalDurtion: record.totalDurtion,
              extendStepId: record.extendStepId,
              explainStepId: record.explainStepId,
              exploreStepId: record.exploreStepId,
              evaluateStepId: record.evaluateStepId,
              engageStepId: record.engageStepId,
              userName: record.userName,
              lessonStepLevel:record.lessonStepLevel,
              lessonName: record.lessonName,
              engageStepComment:record.engageStepComment,
              engageStepMarks:record.engageStepMarks,
              evaluateStepComment:record.evaluateStepComment,
              evaluateStepMarks :record.evaluateStepMarks,
              explainStepComment: record.explainStepComment,
              explainStepMarks:record.explainStepMarks,
              exploreStepComment :record.exploreStepComment,
              extendStepComment:record.extendStepComment,
              extendStepMarks: record.extendStepMarks,
              classId: lessId
            }
            localStorage.setItem("className", className)
            localStorage.setItem("stepIDs", JSON.stringify(payload))
            const encodedLessonName = encodeURIComponent(record.lessonName);
            Stname.push(`/Instructor/Student-Answer/${encodedLessonName}/${id}/${record.lessonStepLevel}/${record.lessonId}`)
          }} >View</span>
        </Space>
      ),
      onCell: (record, rowIndex) => ({
        style: {
          // backgroundColor: '#FFF8E8'
        }
      })
    },
  ];




  // useEffect(() => {
  //   initPayload.map((nm) => {
  //     const payload = {
  //       name: nm.userName,
  //       status: nm.status,
  //       last_update_date: nm.last_update_date,

  //     }
  //     console.log("payload ", payload);
  //     setDataPayload(prev => ([...prev, payload]))
  //   })


  // }, [])
  // useEffect(() => {

  //   ConsumeApi();
  // })

  useEffect(() => {
    GetAllExplainStepInfos(id,level)
      .then((response) => {
        console.log("you could have use this same function to child component", response)
        setInitPayload(response.data)
        //setDataPayload(response.data);
      })

  }, [props]);


  let displayData;
  let dataArray = [];
  // async function ConsumeApi() {
  //   // const responseData = await GetAllExplainStepInfos();
  //   // const response = await responseData.data;


  //   // if (response !== undefined || response !== null) {

  //     // setDataSourse(response);
  //     // console.log(dataRes);
  //     // const { firstName, lastName } = response;
  //     displayData = dataPayload.map((stlist) => {
  //       let dataObject = {
  //         // firstName: stlist.firstName,
  //         userName: stlist.exploreStepResponses?.userName,

  //       }
  //       dataArray.push(dataObject);
  //       console.log("array ", dataArray);
  //       return (
  //         <div key={stlist.id}>
  //           <h3>{stlist.userName} </h3>
  //         </div>
  //       )
  //     })
  //     setDataMap(dataArray);
  //     setDataSourse(displayData);
  //     // document.getElementById('StName').textContent = firstName;
  //   // } else {
  //   //   console.log("variable is NOT undefined or null");
  //   // }

  // }

  const getSearchTerm = (e) => {
    const currValue = e.target.value;
    setSearchTerm(currValue);
    console.log(currValue);
    if (searchTerm !== "") {
      const filteredData = initPayload.filter(entry =>
        entry.lessonName.toString().toLowerCase().includes(currValue.toLowerCase()))
      setSearchResult(filteredData);
    } else {
      setSearchResult(dataSource)
    }
  }

  // 
  // const submitFun = async() => {
  //   // @todo username should be changed

  //   const submitPayload = {
  //   username:user,
  //   userId:initPayload.id,
  //   status:2,
  //   response_json:{
  //     answers:Object.values(payload)
  //   },
  //   last_update_date:new Date().toISOString().slice(0, 10),
  //   engage_step_id:1,
  //   }

  //   console.log("submitPayload", submitPayload);
  //   // @todo should succesfully completed out here
  //   await RestService.postEngageStepUserResponses(submitPayload)
  //   .then((response) =>{
  //     if(response.status === 200) {
  //       props.submitTrigger("2");
  //     }
  //   })
  // }

  return (
    <>
      <div className="container-fluid">
        <Row>
          <Col span={12} offset={6}>
            <center>
              <h3>{name}</h3>
            </center>
          </Col>
        </Row>
        <Row className="px-5 py-3">
          <Col span={8}>
            <Button icon={<ArrowLeftOutlined />} onClick={(e) => {
              e.preventDefault();
              localStorage.removeItem("EvaluationStudentStatus");
              localStorage.removeItem("EvaluationLMSState");
              localStorage.removeItem('studentName')
              Stname.push(`/Instructor/${instructorId}/Classes/${classId}/Lesson/${className}/${lessId}/${level}`)
            }} className='border-0'></Button>
            Go Back
          </Col>
          <Col span={8}>
            <Input size="large" placeholder="Search" onChange={getSearchTerm} prefix={<SearchOutlined className="ml-3 mr-3" />} />
          </Col>
        </Row>
        <Row className="px-5 py-3">
          <Col span={24}>
            <Table dataSource={searchTerm?.length <= 1 || searchTerm?.length === undefined ? initPayload : searchResult}
              columns={columns}
              pagination={{ position: ["bottomCenter"], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
              className='thead-dark'
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ChosenStudentName;


