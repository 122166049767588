import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
    Card,
    CardText,
    CardImg,
    Row,
    Col,
    CardImgOverlay,
    Button,
    Badge,
    FormGroup,
    Label,
} from "reactstrap";
import {
    message,
    Steps,
    Form,
    Input,
    Space,
    Select,
    Checkbox,
    Radio,
    Divider,
    Alert,
    notification
} from "antd";
import "antd/dist/antd.css";

import { Editor } from "react-draft-wysiwyg";

import ExplorerImageCarosel from "./ExplorerImageCarosel";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import LessonService from "../../../../api/LessonService";
import AddExplorerMultipleImagesUpload from "./AddExplorerMultipleImagesUpload";

const { TextArea } = Input;
const options1 = ['Experiment Type ', 'Unit Type', 'Unit'];

const { Option } = Select;


const children = [];

for (let i = 0; i < 3; i++) {
    children.push(<Option key={i}>options if have [i]</Option>);
}


function AddExplorerImages(props) {
    const [imageUrls, setImageURLs] = useState([]);
    const [form] = Form.useForm();
    const [disabled, setDisbled] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [indexValue, setIndexValue] = useState(0);
    const [imgUrl, setImgUrl] = useState([]);
    const onFinish = (values) => {
        setDisbled(true)
        if(values.images && values.images?.length > 1){
            props.onSubmitImageData({...values,images:values.images?.map(x =>{
                return x.image_heading
            }),index:props.imageData?.index ?? indexValue})
        setImageURLs(values.images?.map(x =>{
            return x.image_heading
        }))
        setIndexValue(indexValue +1 )
        notification.open({
            placement:'top',
            duration: '2',
            description: [
                <li>
                    <CheckCircleOutlined className="text-success ant-icon-up" />
                    <span className="ml-4">Images upload successfully 1</span>
                </li>
            ]
        })
        }
        else{
            notification.open({
                placement:'top',
                duration: '2',
                description: [
                    <li>
                        <CheckCircleOutlined className="text-danger ant-icon-up" />
                        <span className="ml-4">Add at least one image for Experiment Image List</span>
                    </li>
                ]
            })
            setIndexValue(indexValue)
        }

    };
    const imageList = [];
    const [folderName, setFolderName] = useState();

    useEffect(()=>{
        setFileList({
            "folder_name":folderName,
            "url": imgUrl,
            "index": indexValue
        })
    },[imgUrl]);
 
    useEffect(()=>{
        setFolderName(undefined)
        setImgUrl([])
        
        if(props.imageData2 !== undefined){
            const imgInitialData = []
            props.imageData2.map(x=>{
                if(x?.url !== undefined){
                    imgInitialData.push(x.url)
                }
            })
            setImgUrl(imgInitialData)
        }

    },[props.imageData2])

    const imageUploadedData = (url,getFolderName,uid ) => {
            setFolderName(getFolderName)
            setImgUrl(pre=>[...pre,url])
    };

    const imageDeletedData = (url) => {
        const newArray = [...imgUrl];
        const index = newArray.indexOf(url);
        if (index !== -1) {
          newArray.splice(index, 1);
          setImgUrl(newArray);
          
          if(props.experiment_id && props.experiment_id > 0){
            const payload = {
                "image": (newArray).join(",")
              }
              LessonService.updatExploreImage(props.experiment_id, payload)
            }
        }        
    }

    useEffect(()=>{
            props.onSubmitImageData(fileList); 
            setImageURLs( fileList.url?.map(x => {
                return x
            }))
        
    },[fileList])

    useEffect(() => {

        if (props.imageData !== undefined) {
            form.setFieldsValue({
                folder_name: props.imageData?.folder_name,
                images: props.imageData?.images?.map(x => {
                    return {
                        image_heading: x
                    }
                })

            })
            setImageURLs(props.imageData?.images)
            setIndexValue(props.totalImages?.length)
            setDisbled(true)
        }
        else {
            setIndexValue(props.totalImages?.length)
            form.resetFields()
            setImageURLs([])
            setDisbled(true)
            
        }
        // if(props.imageData){
        //     form.setFieldValue(props.imageData.filter(x => x !== undefined));
        // setImageURLs(props.imageData.filter(x => x !== undefined)?.images)
        // }
    }, [props])

    return (

        <>
            <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off" form={form} onChange={(values) => {
                setDisbled(false)
            }} >
                {/* <Row>
                    <Col xs='12'>
                        <Form.Item name="folder_name" label="Assets Folder Name" rules={[{ required: true, message: 'Assets Folder Name is required' }]}>
                            <Input placeholder="Enter Folder Name" />
                        </Form.Item>
                        <Divider className="drawer-ant-divider-horizontal" />
                    </Col>
                </Row>
                <Form.List name="images" >

                        {(fields, { add, remove }) => (
                            <>
                                <Row className='pb-2'>
                                    <Col xs='6' className='' >
                                        <b>Add Explorer Image</b>
                                    </Col>
                                    <Col xs='6'>
                                        <div className='text-right'>
                                            <Badge color="dark" className='p-2' onClick={() => add()} >
                                                Add New
                                            </Badge>
                                        </div>
                                    </Col>
                                </Row>
                                <Divider className="drawer-ant-divider-horizontal" />
                              
                                

                                {fields.map(({ key, name, ...restField }) => (
                                    <>
                                        <Row className="mb-0">
                                            <Col xs='10'>
                                                <Form.Item name={[name, "image_heading"]} label="Image URL " rules={[{ required: true, message: 'Image URL is required' }]}>
                                                    <Input placeholder="Add Link" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs='2'>
                                                <Button color="danger" className='px-2 py-2  btn-block' onClick={() => {
                                                    remove(name)
                                                    setDisbled(false)
                                                }} >
                                                    Remove
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                                ))}
                            </>
                        )}
                    </Form.List>  */}
                <Row>
                    <Col md="3"></Col>
                    <Col md="6">
                        <div className="mb-3">
                            <AddExplorerMultipleImagesUpload disable={props.disabled} uploadFileData={imageUploadedData}  deletedFile={imageDeletedData} existingData={props.imageData2} type={props.type}/>
                        </div>
                    </Col>
                    <Col md="3"></Col>
                </Row>
                {/* <Row>
                    <Col xs='12'>
                        <button onClick={updatingImageList} disabled={disabled} class="btn btn-success btn-sm btn-block my-2" type="primary" htmlType="submit" block  >
                            Upload and Save
                        </button>
                    </Col>
                </Row> */}

            </Form>
            <Row>
                <Col xs='12'> 
                {/* here */}
                    <ExplorerImageCarosel disable={props.disabled} disabled={disabled} initialdata={props.initialData} initialdata2={props.initialData2}  initialFourPointData={props.initialFourPointData} initialBarOptionsValues={props.initialBarOptionsValues} initialFourPointOptionValue={props.initialFourPointOptionValue} imageUrls={imageUrls} submitFourPoints={(data)=> props.initialFourPoint(data)} submitBarOption={(data)=> props.initialBarOption(data)}  submitFourPointsOption={(data)=> props.initialFourPointOption(data)} submitInitialPoints={(data) => props.initialValuesUpdate(data)} submitInitialPointsMain={(data) => props.initialValuesUpdateMain(data)}/>
                </Col>
            </Row>
        </>




    );
}
 
export default AddExplorerImages;
