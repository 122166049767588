import { useState, useEffect } from "react";
import { Card, CardText, FormFeedback, Row, Col, Input, Button } from "reactstrap";
import { Alert, notification, Spin, Select  } from 'antd';
import SchoolService from "../../../../api/SchoolService"
import useCustFormValidator from "../../../../hooks/useCustFormValidator";
import validate from './schoolFormValidationRules';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { disableEnableDropdown } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableDropDown";
 
function SchoolEdit(props) {

    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [edited, setEdited] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [uneditedObj, setUneditedObj] = useState({});
    const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });
    const [defaultSelectedAccount, setDefaultSelectedAccount] = useState({});

    const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(saveSchool, validate, {});

    useEffect(() => {
        loadAccounts()
    }, []);

    useEffect(() => {
        getSchoolFromId(props.selectedSchoolId);
    }, [props.selectedSchoolId]);

    useEffect(() => {
        setEdited(JSON.stringify(values) != JSON.stringify(uneditedObj))
        if(values.account_id !== -1){
            setDefaultSelectedAccount({ value: values.account_id,label:accounts.filter(x => x.id == values.account_id).map(x => x.name)[0]});
        }else{
            setDefaultSelectedAccount({});
        }
    }, [values]);

    function loadAccounts() {
        setLoading(true);
        SchoolService.getAccountsForUser()
            .then((response) => {
                if (response?.data?.accountList) {
                    setAccounts(response.data.accountList);
                }

            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }
    useEffect(() => {
        if (accounts.length == 0) {
            setDisabled(true)
        }
    }, [props?.selectedSchoolId, props?.visible])

    function saveSchool() {
        setLoading(true);
        let submitPayload={
            id:props.selectedSchoolId,
            description: values.description,
            account_id: values.account_id,
            name: values.name,
            status: 1,
       
      
        }
        SchoolService.updateSchool(submitPayload)
            .then((response) => {
                if (response.status == 200) {
                    let messages = [{type:'success',text: response.message}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
                clearForm2();
                }
                else{
                    let messages = [{type:'error',text: response.message}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
                
                }
            }).catch((err) => {
                let messages = [{type:'error',text: err.message}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
            }).finally(() => {
                setLoading(false);
            });
    }

    function clearForm() {
        reset();
        hideAlert();
        getSchoolFromId(props.selectedSchoolId)
    }
    function clearForm2() {
        reset();
        getSchoolFromId(props.selectedSchoolId);
        setTimeout(function() {
            hideAlert();
          }, 2000);
    }

    function getSchoolFromId(id) {
        if (!id) {
            return;
        }
        setLoading(true);
        SchoolService.getSchoolById(id)
            .then((response) => {
                setUneditedObj(response.data);
                setFormData(response.data)
            }).catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    function hideAlert() {
        var alert = { showAlert: false, message: "", type: "success" }
        setAlertData(alert);
    }

    function onAccountChange(value, event) {
        setFormData({ ...values, account_id: value });
    }

    return (
        <>
            <div>
                <Row>
                    <Col lg='12' className="center  py-2 text-center">
                        {alertData.showAlert ?
                            <Alert message={alertData.message} type={alertData.type}
                                action={
                                    <a type="text" onClick={hideAlert}>
                                        x
                                    </a>
                                } /> : ""}
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <Spin tip="Loading..." spinning={loading} size="large">
                            <div className="form-outline mb-3">
                                <label className="form-label" htmlFor="form3Example4">
                                    <span className="required-field">*</span>School Name
                                </label>
                                <Input
                                    type="text"
                                    id="form3Example4"
                                    className="form-control form-control-lg"
                                    autocomplete="off"
                                    placeholder=""
                                    value={values.name}
                                    name="name"
                                    onChange={(e) => { handleChange(e) }}
                                    invalid={!!errors?.name}
                                />
                                <FormFeedback>{errors?.name}</FormFeedback>
                            </div>
                            <div className="form-outline mb-3">
                                <label className="form-label" htmlFor="form3Example4">
                                    <span className="required-field">*</span>Account
                                </label>
                                <Select
                                getPopupContainer={trigger => trigger.parentElement}
                                    showSearch
                                    placeholder="Select an account"
                                    optionFilterProp="children"
                                    size="large"
                                    className="search-drop"
                                    disabled={disableEnableDropdown("Account", props.user_roll)}
                                    value={defaultSelectedAccount}
                                    onChange={(value, event) => onAccountChange(value, event)}
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    <option key="-1" value="-1">Please Select an Account</option>
                                    {
                                        accounts.filter(item => item.name !== null).map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                                    }
                                </Select>
                                <FormFeedback className="override-FormFeedback">{errors?.account_id}</FormFeedback>
                            </div>
                            <div className="form-outline mb-3">
                                <label className="form-label" htmlFor="form3Example4">
                                    <span className="required-field">*</span>Description
                                </label>
                                <Input
                                    class="form-control"
                                    id="textarea"
                                    type="textarea"
                                    value={values.description}
                                    name="description"
                                    onChange={(e) => { handleChange(e) }}
                                    invalid={!!errors?.description}
                                ></Input>
                                <FormFeedback>{errors?.description}</FormFeedback>
                            </div>

                            <div className="center">
                                <Button onClick={props.onClose} color="warning" className="mr-15">Cancel</Button>
                                <Button onClick={handleSubmit} color="success" disabled={!edited}>Save</Button>
                            </div>
                        </Spin>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default SchoolEdit;
