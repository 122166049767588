import React, { useContext, useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts

// reactstrap components
import {
  Card,
  CardText,
  CardImg,
  Row,
  Col,
  CardImgOverlay,
  Button,
} from "reactstrap";
import { message, Modal, Spin, Steps, Form, Alert, notification } from "antd";
import "antd/dist/antd.css";
import axios from "axios";

import Engage from "./Engage";
import Explore from "./Explore";
import Extend from "./Extend";
import Explain from "./Explain";
import Evaluate from "./Evaluate";
import Info from "./Info";
import Config from "../../../api/Config";
import LessonService from "../../../api/LessonService";
import { CommonContext } from "./components/CommonContext";
import { object } from "prop-types";
import outputModals from "./components/modals";
import DisabledContext from "antd/lib/config-provider/DisabledContext";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { DisableContext } from "./components/DisableContext";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { useHistory, Link, useLocation } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

const { Step } = Steps;

function NewClass(props) {
  const [current, setCurrent] = useState(props.infoIndex);
  const [selectedLessonDetails, setSelectedLessonDetails] = useState();
  const [alertData, setAlertData] = useState({
    showAlert: false,
    message: "",
    type: "success",
  });
  const { value,setValue } = useContext(CommonContext);
  const [loading, setLoading] = useState(false);
  const [disable,setDisableValue] = useState(true)
  const uneditedObj = {
    id: 0,
    heading: "",
    code: "",
    shortDescription: "",
    description: "",
    curriculamId: null,
    image: "",
    status: null,
  };
  const [lessonInfor, setLessonInfor] = useState({
    id: 0,
    heading: "",
    code: "",
    shortDescription: "",
    description: "",
    curriculamId: null,
    image: "",
    status: null,
  });
  const [editData,setEditedData] = useState(false)
  const [status, setStatus] = useState();
  const [editorState2, setEditorstate2] = useState();
  const [editorState, setEditorstate] = useState();
  const [restriction, setRestriction] = useState({
    0: true,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });
   
//console.log("test",lessonInfor)
  
  useEffect(() => {
    if (props.editInfo === null) {
      //setLessonInfor({ id: 0, ...lessonInfor });
    } else {
      setLoading(true);
      setLessonInfor({
        id: props.editInfo?.id,
        heading: props.editInfo?.heading,
        code: props.editInfo.code,
        shortDescription: props.editInfo?.shortDescription,
        description: props.editInfo?.description,
        curriculamId: props.editInfo?.curriculamId,
        image: props.editInfo?.image,
        status: props.editInfo?.status ,
      });
      setEditorstate(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(props?.editInfo?.description.trim())
          )
        )
      );
      setEditorstate2(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(props?.editInfo?.shortDescription)
          )
        )
      );

      setLoading(false);
    }
  }, [props.editInfo]);
 // console.log("chk",props.editInfo,lessonInfor)
  // const save = (data) => {
  //   console.log(
  //     "Saving",
  //     data,
  //     lessonInfor,
  //     props.editInfo,
  //     JSON.stringify(lessonInfor) !== JSON.stringify(uneditedObj)
  //   );

  //   if (lessonInfor?.id === 0) {
  //     if (
  //       lessonInfor.code !== "" &&
  //       lessonInfor.heading !== "" &&
  //       lessonInfor.curriculamId !== null &&
  //       lessonInfor.image !== "" &&
  //       lessonInfor.status !== null && lessonInfor.heading?.length < 50  && lessonInfor.code.length <50
  //     ) {
  //       LessonService.addLesson({
  //         ...lessonInfor,
  //         status: lessonInfor.status === "Draft" ? 0 : 1,
  //       }).then((res) => {
  //         console.log(res);
  //         if (res?.data?.status === 200) {
  //           message.success(res?.data?.message);
  //           setSelectedLessonDetails({
  //             lessonId: res?.data?.data?.id,
  //             currId: res?.data?.data?.curriculamId,
  //           });
  //           setLessonInfor({ ...lessonInfor, id: res?.data?.data?.id });
  //           setDisableValue(true)
  //         } else if (res?.response?.data?.status === 500) {
  //           message.error(res?.response?.data?.message);
  //           setDisableValue(true)
  //         }
  //       });
  //     }
  //   } else {
  //     let obj = {
  //       id: props.editInfo?.id,
  //       heading: props.editInfo?.heading,
  //       code: props.editInfo?.code,
  //       shortDescription: props.editInfo?.shortDescription,
  //       description: props.editInfo?.description,
  //       curriculamId: props.editInfo?.curriculamId,
  //       image: props.editInfo?.image,
  //       status: props.editInfo?.status === 0 ? "Draft" : "Published",
  //     };
  //     if (JSON.stringify(lessonInfor) !== JSON.stringify(obj) && 
  //       lessonInfor.code !== "" &&
  //       lessonInfor.heading !== "" &&
  //       lessonInfor.curriculamId !== null &&
  //       lessonInfor.image !== "" &&
  //       lessonInfor.status !== null &&
  //       lessonInfor.heading?.length < 50 && lessonInfor.code.length <50
  //     ) {
  //       LessonService.updateLesson({
  //         ...lessonInfor,
  //         status: lessonInfor.status === "Draft" ? 0 : 1,
  //       }).then((res) => {
  //         if (res?.data?.status === 200) {
  //           message.success(res.data.message);
  //           setSelectedLessonDetails({
  //             lessonId: res?.data?.data?.id,
  //             currId: res?.data?.data?.curriculamId,
  //           });
  //           setDisableValue(true)
  //         } else if (res?.response?.data?.status === 500) {
  //           message.error(res?.response?.data?.message);
  //           setDisableValue(true)
  //         }
  //       });
  //     }
  //   }
  // };
  const next = (data) => {
    
   
      setCurrent(current+1)
  };
  
  const prev = () => {
     setLessonInfor( lessonInfor);
    setCurrent(current - 1);
    setDisableValue(true)
  };


  useEffect(()=>{
    if(lessonInfor.id === 0){
      setRestriction({
        0: true,
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
      })
    }
    else{
      setRestriction({
        0: false,
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
      })
    }
  },[lessonInfor])

  const [form] = Form.useForm();

  // const onLessontDropdownChange = (value, name) => {
  //   console.log("onLessontDropdownChange", value, name);
  //   setDisableValue(false)
  //   setLessonInfor((pv) => {
  //     return { ...pv, [name]: value };
  //   });
  // };
  // const onEditorDescription = (e) => {
  //   setEditorstate(e);
  //   setDisableValue(false)
  //   setLessonInfor((pv) => {
  //     return {
  //       ...pv,
  //       ["description"]: draftToHtml(
  //         convertToRaw(e.getCurrentContent())
  //       ).toString(),
  //     };
  //   });
  // };
  // const onEditorShortDescription = (e) => {
  //   setEditorstate2(e);
  //   setDisableValue(false)
  //   setLessonInfor((pv) => {
  //     return {
  //       ...pv,
  //       ["shortDescription"]: draftToHtml(
  //         convertToRaw(e.getCurrentContent())
  //       ).toString(),
  //     };
  //   });
  // };

  const steps = [
    {
      title: "Info",
      content: (
        <Info
        setFlag ={props.setFlag}
        setSelectedLessonDetails={setSelectedLessonDetails}
        editInfo={lessonInfor}
        setLessonInfor={setLessonInfor}
        editedData={(data) =>{
          setEditedData(data)
          props.editData(data)
        }}
        />
      ),
    },
    {
      title: "Engage",
      content: <Engage loading={loading} setLoading={setLoading} />,
    },
    {
      title: "Explore",
      content: <Explore />,
    },
    {
      title: "Evaluate",
      content: <Evaluate />,
    },
    {
      title: "Explain",
      content: <Explain />,
    },
    {
      title: "Extend",
      content: <Extend />,
    },
  ];

  function hideAlert() {
    var alert = { showAlert: false, message: "", type: "success" };
    setAlertData(alert);
  }

  const [isMenuOpen, handleMenu] = useState(false);
  const handleCloseMenu = () => {
    handleMenu(false);
  };
  const handleStateChange = (state) => {
    handleMenu(state.isOpen);
  };
  const history = useHistory();


  const handleClickConfirm = () => {
    confirmAlert({
      title: 'Next Step',
      message: 'Are you sure you want to switch to next step?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            //e.preventDefault()
            handleCloseMenu()
            next(steps[current].title)
          }
          },
        
        {
          label: 'No',
          onClick: () => {
            handleCloseMenu()
          },
        },
      ],
    });
  };

  return (
    <Spin tip="Loading..." spinning={loading} size="large">
      <Col lg="12" className="py-2 text-center">
        {alertData.showAlert ? (
          <Alert
            message={alertData.message}
            type={alertData.type}
            action={
              <a type="text" onClick={hideAlert}>
                x
              </a>
            }
          />
        ) : (
          ""
        )}
      </Col>

      <DisableContext.Provider
        value={{
          disablevalue: restriction,
          setDisableValue: setRestriction,
        }}
      >
        <CommonContext.Provider
          value={{
            value: selectedLessonDetails,
            setValue: setSelectedLessonDetails,
          }}
        >
          <div className=" my-4">
            <Form layout="vertical" form={form}>
              {props.editInfo ? <>
                <Steps onChange={e=>{
                 setCurrent(e)
              }} current={current}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              </> : 
              <>
              <Steps  current={current}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              </>}
              
              <div className="steps-content mt-4 ">
                {steps[current].content}
              </div>
              <div className="steps-action">
                {current > 0 && (
                  <button
                    type="primary"
                    class="btn btn-primary btn-lg"
                    style={{
                      marginRight: "8px",
                    }}
                    onClick={() => prev()}
                  >
                    Previous
                  </button>
                )}
               
                {current < steps.length - 1 && (
                  <button
                    type="primary"
                    disabled={
                      restriction[current] ? restriction[current] :   false
                    }
                    class="btn btn-primary btn-lg"
                    onClick={(e) => {
                          next(steps[current].title)
                        }}
                  >
                    Next
                  </button>
                )}
                {current === steps.length - 1 && (
                  <button
                    disabled={
                      restriction[current] ? restriction[current] : false
                    }
                    type="primary"
                    class="btn btn-primary btn-lg"
                    onClick={() => {
                      //message.success("Process finished successfully");
                      let messages = [{type:'success',text: "Lesson created successfully"}]
                      notification.open({
                        placement: 'top',
                        duration:"2",
                        description: [
                            messages.map((item) => {
                                if(item.type === 'success') {
                                    return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if(item.type === 'error') {
                                    return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if(item.type === 'warning') {
                                    return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],
                
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                      props.setFlag(false);
                    }}
                  >
                    Done
                  </button>
                )}
              </div>
            </Form>
          </div>
        </CommonContext.Provider>
      </DisableContext.Provider>
    </Spin>
  );
}

export default NewClass;
