import { useState, useEffect } from "react";
import { Card, CardText, FormFeedback, Row, Col, Input, Button, FormGroup } from "reactstrap";
import { Alert, notification, Spin, Select } from 'antd';
import AccountAdminService from "../../../../api/AccountAdminService"
import useCustFormValidator from "../../../../hooks/useCustFormValidator";
import validate from './editFormValidation';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

function AdminCreate(props) {
  var obj = {
    age: 0,
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    username: "",
    organization_id: -1,
    confirmPassword: '',
    status: 1,
    roleId: 1,
    accountId: -1
  }

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [uneditedObj, setUneditedObj] = useState({});
  const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });
  const [showPass, setShowPass] = useState(false);
  const [defaultSelectedAccount, setDefaultSelectedAccount] = useState({});

  const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(onSave, validate, obj);

  useEffect(() => {
    loadAccounts()
  }, []);

  useEffect(() => {
    getAdminFromId();
  }, [props.selectedId]);

  useEffect(() => {
    setEdited(JSON.stringify(values) !== JSON.stringify(uneditedObj));
    if(values.accountId !== -1){
      setDefaultSelectedAccount({ value: values.accountId,label:accounts.filter(x => x.id == values.accountId).map(x => x.name)[0]});
    }else{
      setDefaultSelectedAccount({});
    }
  }, [values]);

  function loadAccounts() {
    setLoading(true);
    AccountAdminService.getAccountsForUser()
      .then((response) => {
        if (response?.data?.accountList) {
          setAccounts(response.data.accountList);
        }
      }).catch((err) => {
        console.log(err.message);
      }).finally(() => {
        setLoading(false);
      });
  }

  function getAdminFromId() {
    if (!props.selectedId || props.selectedId <= 0) {
      return
    }
    setLoading(true);
    AccountAdminService.getAdminById(props.selectedId)
      .then((response) => {
        if (response?.data) {
          var data = response.data;
          data.password = '';
          data.confirmPassword = '';
          setUneditedObj(response.data);
          setFormData(data);
        }
      }).catch((err) => {
        console.log(err.message);
      }).finally(() => {
        setLoading(false);
      });
  }

  //   useEffect(() => {
  //     if(accounts.length > 0){

  //     }
  // }, [props?.selectedId])

  useEffect(() => {
    if (values?.accountId !== undefined) {
      setDisabled(true)
    }
    else {
      setDisabled(false)
    }
  }, [values?.accountId])

  function onShowPassChange(e) {
    console.log("Pass",e.target.checked)
    if (!e.target.checked) {
      setFormData({ ...values, password: '', confirmPassword: '',showPass:false })
    }else{
      setFormData({ ...values,showPass:true })
    }
    setShowPass(e.target.checked)
    
  }
  function onEmailChange(e) {
    e.persist();
    setFormData({ ...values, username: e.target.value, email: e.target.value });
  }
  function onSave() {
    setLoading(true);
    delete values.confirmPassword;
    setFormData({ ...values, organization_id: values.accountId, roleId: 4 })
    AccountAdminService.updateAdmin(values)
      .then((response) => {
         if(response?.status === 200){
          let messages = [{type:'success',text: response.message}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
         }
         if(response?.status === 500){
          let messages = [{type:'error',text: response.message}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
         }
      }).catch((err) => {
        let messages = [{type:'error',text: err.message}]
          notification.open({
            placement: 'top',
            duration:"2",
            description: [
                messages.map((item) => {
                    if(item.type === 'success') {
                        return(<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'error') {
                        return(<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                    if(item.type === 'warning') {
                        return(<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                    }
                })
            ],
    
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
      }).finally(() => {
        setLoading(false);
        getAdminFromId();
      });
  }

  function onCancel() {
    reset();
    props.onClose();
    // getAdminFromId();
  }

  function hideAlert() {
    var alert = { showAlert: false, message: "", type: "success" }
    setAlertData(alert);
  }

  function onAccountChange(value, event) {
    setFormData({ ...values, accountId: value });
  }

  return (
    <>
      <div>
        <Spin tip="Loading..." spinning={loading} size="large">
          <Row>
            <Col lg='12' className="py-2 text-center">
              {alertData.showAlert ?
                <Alert message={alertData.message} type={alertData.type}
                  action={
                    <a type="text" onClick={hideAlert}>
                      x
                    </a>
                  } /> : ""}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example1">
                    <span className="required-field">*</span>Account
                  </label>
                  <Select
                  getPopupContainer={trigger => trigger.parentElement}
                    showSearch
                    placeholder="Select an account"
                    optionFilterProp="children"
                    size="large"
                    className="search-drop"
                    value={defaultSelectedAccount}
                    onChange={(value, event) => onAccountChange(value, event)}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    <option key="-1" value="-1">Please Select an Account</option>
                    {
                      accounts.filter(item => item.name !== null).map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>)
                    }
                  </Select>
                  <FormFeedback className="override-FormFeedback">{errors?.accountId}</FormFeedback>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example2">
                    <span className="required-field">*</span>First Name
                  </label>
                  <Input
                    type="text"
                    id="form3Example2"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.firstName}
                    name="firstName"
                    onChange={handleChange}
                    invalid={!!errors?.firstName}
                  />
                  <FormFeedback>{errors?.firstName}</FormFeedback>
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example3">
                    <span className="required-field">*</span>Last Name
                  </label>
                  <Input
                    type="text"
                    id="form3Example3"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.lastName}
                    name="lastName"
                    onChange={handleChange}
                    invalid={!!errors?.lastName}
                  />
                  <FormFeedback>{errors?.lastName}</FormFeedback>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example4">
                    <span className="required-field">*</span>Email
                  </label>
                  <Input
                    type="text"
                    id="form3Example4"
                    className="form-control form-control-lg"
                    placeholder=""
                    value={values.email}
                    name="email"
                    autoComplete="new_mail"
                    onChange={(e) => {
                      onEmailChange(e);
                    }}
                    invalid={!!errors?.email}
                  />
                  <FormFeedback>{errors?.email}</FormFeedback>
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example5">
                    <span className="required-field">*</span>Username
                  </label>
                  <Input
                    type="text"
                    id="form3Example5"
                    className="form-control form-control-lg"
                    placeholder=""
                    value={values.username}
                    name="username"
                    autoComplete="off"
                    onChange={handleChange}
                    invalid={!!errors?.username}
                    disabled={true}
                  />
                  <FormFeedback>{errors?.username}</FormFeedback>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <FormGroup check inline >
                    <Input type="checkbox"
                      checked={values.showPass}
                      onChange={onShowPassChange}
                    />
                    <label check className="pt-6">
                      Change Password
                    </label>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row" hidden={!values.showPass}>
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example6">
                    Password
                  </label>
                  <Input
                    type="password"
                    id="form3Example6"
                    className="form-control form-control-lg"
                    placeholder=""
                    value={values.password}
                    name="password"
                    autoComplete="off"
                    onChange={handleChange}
                    invalid={!!errors?.password}
                  />
                  <FormFeedback>{errors?.password}</FormFeedback>
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example7">
                    Confirm Password
                  </label>
                  <Input
                    type="password"
                    id="form3Example7"
                    className="form-control form-control-lg"
                    autoComplete="off"
                    placeholder=""
                    value={values.confirmPassword}
                    name="confirmPassword"
                    onChange={handleChange}
                    invalid={!!errors?.confirmPassword}
                  />
                  <FormFeedback className="override-FormFeedback">{errors?.confirmPassword}</FormFeedback>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <div className="center mt-4">
                <Button color="warning" className="mr-15" onClick={onCancel}>Cancel</Button>
                <Button color="success" onClick={handleSubmit} disabled={!edited}>Save</Button>
              </div>
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
}

export default AdminCreate;
