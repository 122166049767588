import { useState, useEffect } from "react";
import { FormFeedback, Row, Col, Input, Button, FormGroup } from "reactstrap";
import { Alert, notification, Select, Spin } from 'antd';
import jsonToken from 'jsonwebtoken'
import useCustFormValidator from "../../../../hooks/useCustFormValidator";

import StudentService from "../../../../api/StudentServiceNew"

import { CreateNewTerm } from "../../../../api/TermService";
import moment from "moment";
import validate from "./createFormValidation";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';


function TermCreate(props) {
    var obj = {
        name: "",
        stdate: moment().format("yyyy-MM-DD"),
        endDate: moment().add(1, "years").format("yyyy-MM-DD"),
        status: 1,
        accountId: -1
    }
    const [loading, setLoading] = useState(false);
    const [edited, setEdited] = useState(false);
    // const [disabled, setDisabled] = useState(false);
    const [uneditedObj, setUneditedObj] = useState({});
    const [alertData, setAlertData] = useState({ showAlert: false, message: "", type: "success" });

    const { handleChange, handleSubmit, setFormData, reset, values, errors, } = useCustFormValidator(onSave, validate, { obj });

    let accessToken = jsonToken.decode(localStorage.getItem("access_token"));

    useEffect(() => {
        setFormData({ ...values, stdate: moment().format("yyyy-MM-DD"), endDate: moment().add(1, "years").format("yyyy-MM-DD") })
    }, [])

    useEffect(() => {
        if(accessToken.roles[0] === "SUPER_ADMIN"){
            setEdited(JSON.stringify(values) != JSON.stringify(uneditedObj))
        }
    }, [values]);


    function onSave() {
        setLoading(true);
        let createData = {
            id: 0,
            name: values.name,
            started_date: moment(values.stdate).format('yyyy-MM-DD'),
            end_date: moment(values.endDate).format('yyyy-MM-DD'),
            created_user_id: accessToken.userId,
            status: 1,
        }
        
        CreateNewTerm(createData)
            .then((response) => {
                if (response.status == 200) {
                    let messages = [{ type: 'success', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    setFormData(obj);
                }
                else {
                    let messages = [{ type: 'error', text: response.message }]
                    notification.open({
                        placement: 'top',
                        duration: "2",
                        description: [
                            messages.map((item) => {
                                if (item.type === 'success') {
                                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'error') {
                                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                                if (item.type === 'warning') {
                                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                                }
                            })
                        ],

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    // loadTeacher();
                    setFormData(obj);
                }

            }).catch((err) => {
                let messages = [{ type: 'error', text: err.message }]
                notification.open({
                    placement: 'top',
                    duration: "2",
                    description: [
                        messages.map((item) => {
                            if (item.type === 'success') {
                                return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'error') {
                                return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                            if (item.type === 'warning') {
                                return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                            }
                        })
                    ],

                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });
            }).finally(() => {
                setLoading(false);
            });
    }

    function hideAlert() {
        var alert = { showAlert: false, message: "", type: "success" }
        setAlertData(alert);
    }

    const clearForm = () => {
        reset();
        props.setFlag(false)
        setFormData(values);
        setTimeout(function () {
            hideAlert();
        }, 2000);
    }

    return (
        <>
            <div>
                <Spin tip="Loading..." spinning={loading} size="large">
                    <form>
                        <Row>
                            <Col lg="3"></Col>
                            <Col lg='6' className="py-2 text-center">
                                {alertData.showAlert ?
                                    <Alert message={alertData.message} type={alertData.type}
                                        action={
                                            <a type="text" onClick={hideAlert}>
                                                x
                                            </a>
                                        } /> : ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3"></Col>
                            <Col md="6">
                                <div className="form-row">
                                    <div className="form-group col-lg-12 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>Term Name
                                        </label>

                                        <Input
                                            type="text"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autocomplete="off"
                                            placeholder=""

                                            value={values.name}
                                            onChange={handleChange}
                                            name="name"
                                            invalid={!!errors?.name}
                                        />
                                        <FormFeedback>{errors?.name}</FormFeedback>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-lg-12 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>Start Date
                                        </label>
                                        <Input
                                            type="date"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autocomplete="off"
                                            placeholder=""
                                            name="stdate"
                                            value={values.stdate}
                                            onChange={handleChange}
                                            invalid={!!errors?.stdate}
                                        />
                                        <FormFeedback>{errors?.stdate}</FormFeedback>
                                    </div>
                                    {/* <div className="form-group col-lg-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>End Date
                                        </label>
                                        <Input
                                            type="date"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autocomplete="off"
                                            placeholder=""
                                            name="endDate"
                                            onChange={handleChange}
                                            value={values.endDate}
                                            invalid={!!errors?.endDate}
                                            
                                        />
                                        <FormFeedback>{errors?.endDate}</FormFeedback>
                                    </div> */}
                                </div>
                                <div className="form-row">
                                    {/* <div className="form-group col-lg-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>Start Date
                                        </label>
                                        <Input
                                            type="date"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autocomplete="off"
                                            placeholder=""
                                            name="stdate"
                                            value={values.stdate}
                                            onChange={handleChange }
                                            invalid={!!errors?.stdate}
                                        />
                                        <FormFeedback>{errors?.stdate}</FormFeedback>
                                    </div> */}
                                    <div className="form-group col-lg-12 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            <span className="required-field">*</span>End Date
                                        </label>
                                        <Input
                                            type="date"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autocomplete="off"
                                            placeholder=""
                                            name="endDate"
                                            onChange={handleChange}
                                            value={values.endDate}
                                            invalid={!!errors?.endDate}

                                        />
                                        <FormFeedback>{errors?.endDate}</FormFeedback>
                                    </div>
                                </div>
                                {/* <div className="form-row">
                                    <div className="form-group col-md-6 col-sm-12">
                                        <FormGroup check inline >
                                            <Input type="checkbox"
                                                checked={values
                                                    .showPass}
                                                onChange={onShowPassChange}
                                                className="checked-margin"
                                            />
                                            <label check className="pt-6">
                                                Change Password
                                            </label>
                                        </FormGroup>
                                    </div>
                                </div> */}
                                {/* <div className="form-row" >
                                    <div className="form-group col-md-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            Password
                                        </label>
                                        <Input
                                            type="password"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autocomplete="off"
                                            placeholder=""
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            invalid={!!errors?.password}
                                        />
                                        <FormFeedback>{errors?.password}</FormFeedback>
                                    </div>
                                    <div className="form-group col-md-6 col-sm-12">
                                        <label className="form-label" htmlFor="form3Example4">
                                            Confirm Password
                                        </label>
                                        <Input
                                            type="password"
                                            id="form3Example4"
                                            className="form-control form-control-lg"
                                            autocomplete="off"
                                            placeholder=""
                                            name="confirmPassword"
                                            value={values.confirmPassword}
                                            onChange={handleChange}
                                            invalid={!!errors?.confirmPassword}
                                        />
                                        <FormFeedback>{errors?.confirmPassword}</FormFeedback>
                                    </div>
                                </div> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md='12'>
                                <div className="center mt-4">
                                    <Button color="warning" className="mr-2 fixed-width-115" onClick={clearForm}>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleSubmit} color="success" className="fixed-width-115" disabled={!edited}>Save</Button>
                                </div>
                                <div className="center mt-4">
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Spin>
            </div>
        </>
    );
}

export default TermCreate;
