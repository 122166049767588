import React, { useState, useEffect } from 'react'
import './style.scss'
import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import 'animate.css';

function FloatingButton(props) {
    const [flag, setFlag] = useState(true);
    useEffect(() => {
        setFlag(false)

    }, [props])
    useEffect(() => {
        if (flag === false) {
            setStatus(true)
        }
    }, [flag]);
    const [status, setStatus] = useState(false)

    function handleClick() {
        setStatus(prevStats => !prevStats);
        setFlag(!flag)
    }

    function handleClick2() {
        props.resetFunction();
    }
    function checkStatus() {
        return status ?  'appear button-styling-display':'hidden';
    }

    function checkStatus2(){
        return props.subStatus ? status ? 'button-styling-disable' : 'hidden' : status ? 'appear button-styling-display' : 'hidden';
    }

    return (
        <div class="float-container btn-hide">
            <div class="wrapper">
                <div onClick={handleClick} className={status ? 'rotate button-styling-hidden animate__animated animate__bounce' : 'normal  button-styling-hidden animate__animated animate__bounce'}>
                    {flag ? <ArrowRightOutlined /> : <CloseOutlined /> }
                </div>

                {props.status ? (
                    <>
                    {props.name3 !== "" && props.name3 !== undefined &&
                    <div onClick={handleClick2} className={checkStatus()}>
                        {props.name3}
                    </div>
                    }
                    {
                        props.name2 &&
                        <div className={checkStatus()} onClick={props.back} >
                            {props.name2}
                        </div>
                    }
                    </>
                ) : (<>
                    {props.name3 !== "" && props.name3 !== undefined &&
                    <div onClick={handleClick2} className={checkStatus2()}>
                        {props.name3}
                    </div>
                    }
                    {
                        props.name2 &&
                        <div className={checkStatus2()} onClick={props.back} >
                            {props.name2}
                        </div>
                    }
                </>)}
               
                {props.name !== "" ? (
                    <div className={checkStatus()} onClick={props.onClick} >
                        {props.name}
                    </div>
                ) : ("")}

<div  className={checkStatus()} onClick={()=>props.tourStatus(true)}>
                    Take a Tour
                </div>

            </div>
        </div>

    )
}

export default FloatingButton