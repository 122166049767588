import React, { useState, useContext, useEffect } from 'react';
import "antd/dist/antd.css";
import "suneditor/dist/css/suneditor.min.css";
import { CommonContext } from '../components/CommonContext';
import LessonService from '../../../../api/LessonService';
import BasicCommonComponent from '../components/BasicCommonInfoComponent';

function BasicInfo(props) {
  const { value, setValue } = useContext(CommonContext);
  const [loading, setLoading] = useState(false)
  const [inputObj, setInputObj] = useState({
    id: 0,
    labdescription: "",
    guideline: "",
    classLevel: -1

  })

  useEffect(() => {
    setLoading(true);
    LessonService.getExplainBasicInfo(
      value.lessonId,
      props.currentClassLevel
    ).then((res) => {
      setInputObj({
        id: res.data.id,
        labdescription: res.data.description,
        guideline: res.data.guideline,
        classLevel: res.data.classLevel
      })
    }).catch((err) => {
      console.log(err.message);
    }).finally(()=>{
      setLoading(false);
    })
  }, [props]);

  return (
    <BasicCommonComponent loading={loading} setPreviouseClassLevelFC={(data) => props.setPreviouseClassLevelFC(data)} previouseClassLevel={props.previouseClassLevel} setAddedClassLevel={props.setAddedClassLevel} filterdValues={props.filterdValues} type={props.type} back={props.back} currentClassLevel={props.currentClassLevel} inputObj={inputObj} step={4} GoToQuestionTab={props.GoToQuestionTab} createEngageStep={props.createExplaineStep} />
  );
}

export default BasicInfo;
