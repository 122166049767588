import React, { useEffect, useState } from 'react';


import {
    CardBody,
    Row,
    Col,

} from "reactstrap";
import outputModals from '../components/modals'
import { Badge, Drawer, Popconfirm, Space, Spin, Table } from "antd";
import "antd/dist/antd.css";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Source1, dataSet1 } from "./data/data"
import ExpDrawer from './ExpDrawer';
import LessonService from '../../../../api/LessonService';
function cancel(e) {


}
function Experiment(props) {
    const [editData, setEditData] = useState();
    const [view, setView] = useState(false);
    const [hasChanges, setHasChanges] = useState(false); // New state for tracking changes
    const [loading, setLoading] = useState(false)
    const editExperiment = (data) => {
        LessonService.getSpecificExperiment(data).then(res => {
            setEditData(res.data)
        }).finally(() => {
            showLargeDrawer()
        })
    }
    const deleteExperiment = (data) => {
        setLoading(true)
        LessonService.deleteExperiment(data).then(res => {
            if (res.status == 200) {
                outputModals.success(res.message)
                setDataSource([])
                setVisible(false);
                LessonService.getAllExperiments(props.explore_id).then(res => {
                    setDataSource(res.data.map((item, i) => {
                        var html = item.name[0].value;
                        var div = document.createElement("div");
                        div.innerHTML = html;
                        var text = div.textContent || div.innerText || "";
                        return {
                            id: item.id,
                            mesurement: text,
                            system: item.description.folderName,
                            units: item.x !== null || item.y !== null ? [item.x.SI, item.y.SI] : undefined,
                            value: item.x !== null || item.y !== null ? [item.x.Value, item.y.Value] : undefined,
                            action: ""

                        }
                    }))
                })
            }
            else {
                outputModals.error(res.message)
            }
        }).finally(()=>{
            setLoading(false)
        })
    }

    const dataSet1 = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Experiment',
            dataIndex: 'mesurement',
            key: 'mesurement',
        },
        {
            title: ' Axis',
            dataIndex: 'system',
            key: 'system',
            render: (_, record) => (
                <Space size="middle">
                    {record?.value?.map(x => {
                        return <Badge className='bg-danger' size="sm">
                            <small>{x}</small>
                        </Badge>
                    })}

                </Space>
            ),
        },
        {
            title: ' Unit',
            dataIndex: 'unit',
            key: 'unit',
            render: (_, record) => (
                <Space size="middle">
                    {record?.units?.map(x => {
                        return <Badge className='bg-danger' size="sm">
                            <small>{x}</small>
                        </Badge>
                    })}
                </Space>
            ),
        },
        {
            title: ' Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {record.action}
                    <Badge className='bg-warning' size="sm" onClick={() => {
                        editExperiment(record.id)
                        setView(true)
                    }} >
                        <small>View</small>
                    </Badge>
                    {
                        props.type !== 2 &&
                        <><Badge className='bg-primary' size="sm" onClick={() => {
                            editExperiment(record.id)
                            setView(false)
                        }} >
                            <small>Edit</small>
                        </Badge>
                            <Popconfirm
                                title="Are you sure to delete this?"
                                onConfirm={(e) => deleteExperiment(record.id)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            ><Badge className='bg-danger' size="sm" >
                                    <small>Remove</small>
                                </Badge></Popconfirm></>
                    }

                </Space>
            ),
        },
    ];
    const [visible, setVisible] = useState(false);
    const [size, setSize] = useState();
    const [dataSource, setDataSource] = useState([])
    const [editing, setEditing] = useState(false)
    const showLargeDrawer = () => {
        setSize('large');
        setVisible(true);
        setHasChanges(false);
    };

    const handleClickConfirm = () => {
        confirmAlert({
            title: 'Save',
            message: 'Are you sure to leave this page? ',
            buttons: [
                {
                    label: 'Yes',
                    onClick: (e) => {
                        //e.preventDefault()
                        onClose()
                        //handleCloseMenu()
                        //handleButtonClick()
                    }
                },
                {
                    label: 'No',
                    onClick: (e) => {
                        // e.preventDefault()

                        //handleButtonClick()
                    }
                },


            ],
        });
    };

    const onClose = () => {
        setDataSource([])
        setVisible(false);
        setLoading(true)
        LessonService.getAllExperiments(props.explore_id).then(res => {
            setDataSource(res.data.map((item, i) => {
                var html = item.name[0].value;
                var div = document.createElement("div");
                div.innerHTML = html;
                var text = div.textContent || div.innerText || "";
                return {
                    id: item.id,
                    mesurement: text,
                    system: item.description.folderName,
                    units: item.x !== null || item.y !== null ? [item.x.SI, item.y.SI] : undefined,
                    value: item.x !== null || item.y !== null ? [item.x.Value, item.y.Value] : undefined,
                    action: ""

                }
            }))
        }).finally(()=>{
            setLoading(false)
        })
    };

    useEffect(() => {
        setLoading(true)
        LessonService.getAllExperiments(props.explore_id).then(res => {
            setDataSource(res.data.map((item, i) => {
                var html = item.name[0].value;
                var div = document.createElement("div");
                div.innerHTML = html;
                var text = div.textContent || div.innerText || "";
                return {
                    id: item.id,
                    mesurement: text,
                    system: item.description.folderName,
                    units: item.x !== null || item.y !== null ? [item.x.SI, item.y.SI] : undefined,
                    value: item.x !== null || item.y !== null ? [item.x.Value, item.y.Value] : undefined,
                    action: ""

                }
            }))
        }).finally(()=>{
            setLoading(false)
        })

    }, [props.explore_id])

    const handleChange = () => {
        setHasChanges(true);
      };

    const handleCloseDrawer = () => {
        // if it comes from view action
        if(view){
            onClose()
        }else{
            // if there is anyy changes from drawer
            if (hasChanges) {
                handleClickConfirm();
            } else {
                onClose();
            }
        }
    
    };

    return (
        <>
            <div className="container" >
                <Row>

                    <Col xs='12' className='pt-5'>
                        <div className='text-center'>
                            {
                                props.type !== 2 && <button class="btn btn-dark btn-sm" onClick={() => {
                                    setEditData(undefined)
                                    setView(false)
                                    showLargeDrawer()
                                }} >
                                    Add an Experiment
                                </button>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <CardBody>
                            <Row>
                                <Col xs='12' className="mb-5">
                                    <Table loading={{indicator: <Spin size='large' className='spinner-new' tip='Loading...' />, spinning: loading}} dataSource={dataSource} columns={dataSet1} pagination={false} bordered className="thead-dark space-py-20" />

                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                </Row>
                <Row>{
                    props.back
                }</Row>
                <Drawer
                    title={`Add new experiment`}
                    placement="right"
                    size={size}
                    onClose={handleCloseDrawer}
                    visible={visible}
                    destroyOnClose={true}
                    maskClosable={false}
                >
                    <ExpDrawer onClose={onClose} view={view} explore_id={props.explore_id} editData={editData} onChangeData={handleChange}/>

                </Drawer>

            </div>
        </>
    );
}

export default Experiment;
