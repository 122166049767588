import { useState, useEffect } from "react";
import {
  Card,
  CardText,
  FormFeedback,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";
import { Alert, notification, Spin, Select } from "antd";
import AccountAdminService from "../../../../api/AccountAdminService";
import useCustFormValidator from "../../../../hooks/useCustFormValidator";
import validate from "./formValidationRules";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

function AdminCreate(props) {
  var obj = {
    age: 20,
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    username: "",
    organization_id: -1,
    confirmPassword: "",
    status: 1,
    roleId: 4,
    accountId: -1,
  };
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState({
    showAlert: false,
    message: "",
    type: "success",
  });
  const [defaultSelectedAccount, setDefaultSelectedAccount] = useState({});

  const { handleChange, handleSubmit, setFormData, reset, values, errors } =
    useCustFormValidator(onSave, validate, obj);

  useEffect(() => {
    loadAccounts();
  }, []);

  useEffect(() => {
    if (values.accountId !== -1) {
      setDefaultSelectedAccount({ value: values.accountId, label: accounts.filter(x => x.id == values.accountId).map(x => x.name)[0] });
    } else {
      setDefaultSelectedAccount({ value: -1, label: "Please Select an Account" });
    }
  }, [values]);

  function loadAccounts() {
    setLoading(true);
    AccountAdminService.getAccountsForUser()
      .then((response) => {
        if (response?.data?.accountList) {
          setAccounts(response.data.accountList);
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onEmailChange(e) {
    e.persist();
    setFormData({ ...values, username: e.target.value, email: e.target.value });
  }

  function onSave() {
    if (values.confirmPassword) {
      setLoading(true);
      delete values.confirmPassword;
      let data = { ...values, organization_id: values.accountId };
      setFormData(data);
      AccountAdminService.insertNewAdmin(data)
        .then((response) => {
          if (response?.status === 200) {
            let messages = [{ type: 'success', text: response.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
            setFormData(obj);
          }
          if (response?.status === 500) {
            let messages = [{ type: 'error', text: response.message }]
            notification.open({
              placement: 'top',
              duration: "2",
              description: [
                messages.map((item) => {
                  if (item.type === 'success') {
                    return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'error') {
                    return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                  if (item.type === 'warning') {
                    return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                  }
                })
              ],

              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          }
        })
        .catch((err) => {
          setFormData({ ...values, password: "", confirmPassword: "" });
          let messages = [{ type: 'error', text: err.message }]
          notification.open({
            placement: 'top',
            duration: "2",
            description: [
              messages.map((item) => {
                if (item.type === 'success') {
                  return (<li><CheckCircleOutlined className='text-success ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'error') {
                  return (<li><CloseCircleOutlined className='text-danger ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
                if (item.type === 'warning') {
                  return (<li><InfoCircleOutlined className='text-warning ant-icon-up' /><span className='ml-4'>{item.text}</span></li>);
                }
              })
            ],

            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function hideAlert() {
    var alert = { showAlert: false, message: "", type: "success" };
    setAlertData(alert);
  }

  function clearForm() {
    //reset();
    setFormData(obj);
    props.setFlag(false);
  }

  return (
    <>
      <div>
        <Spin tip="Loading..." spinning={loading} size="large">
          <Row>
            <Col md="3"></Col>
            <Col lg="6" className="py-2 text-center">
              {alertData.showAlert ? (
                <Alert
                  message={alertData.message}
                  type={alertData.type}
                  action={
                    <a type="text" onClick={hideAlert}>
                      x
                    </a>
                  }
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example1">
                    <span className="required-field">*</span>Account
                  </label>
                  <Select
                  placement="bottomRight"
                  getPopupContainer={trigger => trigger.parentElement}
                    showSearch
                    placeholder="Select an account"
                    optionFilterProp="children"
                    size="large"
                    className="search-drop"
                    onChange={(value, event) => handleChange(value, event)}
                    value={defaultSelectedAccount}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    <option key="-1" value="-1">
                      Please Select an Account
                    </option>
                    {accounts.filter(item => item.name !== null).map((obj) => (
                      <option key={obj.id} value={obj.id}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
                  <FormFeedback className="override-FormFeedback">{errors?.accountId}</FormFeedback>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example2">
                    <span className="required-field">*</span>First Name
                  </label>
                  <Input
                    type="text"
                    id="form3Example2"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.firstName}
                    name="firstName"
                    onChange={handleChange}
                    invalid={!!errors?.firstName}
                  />
                  <FormFeedback>{errors?.firstName}</FormFeedback>
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example3">
                    <span className="required-field">*</span>Last Name
                  </label>
                  <Input
                    type="text"
                    id="form3Example3"
                    className="form-control form-control-lg"
                    autocomplete="off"
                    placeholder=""
                    value={values.lastName}
                    name="lastName"
                    onChange={handleChange}
                    invalid={!!errors?.lastName}
                  />
                  <FormFeedback>{errors?.lastName}</FormFeedback>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example4">
                    <span className="required-field">*</span>Email
                  </label>
                  <input type="text" id="hiddenField" style={{display: "none"}} autoComplete="email" />
                  <Input
                    type="text"
                    id="form3Example4"
                    className="form-control form-control-lg"
                    placeholder=""
                    value={values.email}
                    name="email"
                    autoComplete="new_mail"
                    onChange={(e) => {
                      onEmailChange(e);
                    }}
                    invalid={!!errors?.email}
                  />
                  <FormFeedback>{errors?.email}</FormFeedback>
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example5">
                    <span className="required-field">*</span>Username
                  </label>
                  <input type="text" id="hiddenField" style={{display: "none"}} autoComplete="username" />
                  <Input
                    type="text"
                    id="form3Example5"
                    className="form-control form-control-lg"
                    placeholder=""
                    disabled={true}
                    value={values.username}
                    name="username"
                    autoComplete="off"
                    onChange={handleChange}
                    invalid={!!errors?.username}
                  />
                  <FormFeedback>{errors?.username}</FormFeedback>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example6">
                    <span className="required-field">*</span>Password
                  </label>
                  <input type="password" id="hiddenPasswordField" style={{display: "none"}} autoComplete="current-password" />
                  <Input
                    type="password"
                    id="pass"
                    className="form-control form-control-lg"
                    placeholder=""
                    value={values.password}
                    // ref={passwordRef}
                    name="password"
                    autoComplete="off"
                    onChange={handleChange}
                    invalid={!!errors?.password}
                  />
                  <FormFeedback>{errors?.password}</FormFeedback>
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label className="form-label" htmlFor="form3Example7">
                    <span className="required-field">*</span> Confirm Password
                  </label>
                  <Input
                    type="password"
                    id="form3Example7"
                    className="form-control form-control-lg"
                    autoComplete="off"
                    placeholder=""
                    value={values.confirmPassword}
                    name="confirmPassword"
                    onChange={handleChange}
                    invalid={!!errors?.confirmPassword}
                  />
                  <FormFeedback>{errors?.confirmPassword}</FormFeedback>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="center mt-4">
                <Button color="warning" className="mr-2 fixed-width-115" onClick={clearForm}>
                  Cancel
                </Button>
                <Button color="success" className="fixed-width-115" onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
}

export default AdminCreate;
